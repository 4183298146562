import { useState, useEffect, useContext } from "react";
import "./investigation.scss";
import UserContext from "../../context/userContext";
import axios from "axios";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import SpeakerNotesOffIcon from "@mui/icons-material/SpeakerNotesOff";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import Tooltip from "@mui/material/Tooltip";
import Modal from "@mui/material/Modal";
import IndvNotes from "./modal/IndvNotes";
import VerifiedIcon from "@mui/icons-material/Verified";
import UnpublishedIcon from "@mui/icons-material/Unpublished";

export default function InvesDetails(props) {
  const { userData } = useContext(UserContext);
  const [details, setDetails] = useState([]);
  const [reload, setReload] = useState(false);
  const [txtDetails, setTxtDetails] = useState([]);
  const [notes, setNotes] = useState([]);
  const url="https://nitropos-backend.nitrosolutions.co.uk"
  // const url="http://10.0.0.174:4022"

  const [open, setOpen] = useState(false);
  const handleOpen = (notes) => {
    setNotes(notes);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setNotes([]);
  };

  useEffect(() => {
    console.log(props.discrepancy);
    setDetails(props.discrepancy);

    let data = {
      staffId: props.discrepancy.eposStaffId,
      date: props.month,
    };
    if (data.staffId) {
      axios
        .post(
          `${url}/discrepancies/getUserDiscrepancies`,
          data
        )
        .then((res) => {
          setTxtDetails(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [props, reload]);

  const handleAcceptDiscrepancy = async (id) => {
    let data = {
      headingId: id,
      accept: true,
    };
    await axios
      .post(
        `${url}/discrepancies/updateUserDiscrepancy`,
        data
      )
      .then((res) => {
        props.rel();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDennyDiscrepancy = (id) => {
    let data = {
      headingId: id,
      accept: false,
    };
    axios
      .post(
        `${url}/discrepancies/updateUserDiscrepancy`,
        data
      )
      .then((res) => {
        props.rel();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <section className="investigation__details">
      <h2>
        Total number of discrepancies <span>{txtDetails.length}</span> for{" "}
        <span>{details.name}</span>
      </h2>
      <table>
        <thead>
          <tr>
            <th>Cost</th>
            <th>Added By</th>
            <th>Notes</th>
            <th>Payroll</th>
          </tr>
        </thead>
        <tbody>
          {txtDetails.map(function (d, i) {
            return (
              <tr>
                <th>£ {parseFloat(d.cost).toFixed(2)}</th>
                <th>{d.user}</th>
                <th>
                  {d.investigationNotes.length === 0 ? (
                    <SpeakerNotesOffIcon sx={{ color: "#c9c9c9" }} />
                  ) : (
                    <Tooltip title="See Notes">
                      <SpeakerNotesIcon
                        color="primary"
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleOpen(d.investigationNotes)}
                      />
                    </Tooltip>
                  )}
                </th>
                <th>
                  {d.payrollConfirmed === null ? (
                    <>
                      <IconButton>
                        <DoneIcon
                          color="error"
                          onClick={() => handleAcceptDiscrepancy(d.headingId)}
                        />
                      </IconButton>
                      <IconButton>
                        <CloseIcon
                          color="success"
                          onClick={() => handleDennyDiscrepancy(d.headingId)}
                        />
                      </IconButton>
                    </>
                  ) : d.payrollConfirmed === true ? (
                    <VerifiedIcon
                      color="success"
                      onClick={() => handleDennyDiscrepancy(d.headingId)}
                    />
                  ) : (
                    <UnpublishedIcon
                      color="error"
                      onClick={() => handleAcceptDiscrepancy(d.headingId)}
                    />
                  )}
                </th>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={handleClose}
      >
        <IndvNotes notes={notes} />
      </Modal>
    </section>
  );
}
