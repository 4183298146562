import {useState} from 'react'

export default function Refund(props) {
    const [refundReason,setRefundReason] = useState(props.refundDat.reasonName)

    const changeReason = (e)=>{
        setRefundReason(e)
        props.handleRefundsData(e, props.key)
    }

    return (
        <>
            <td>{props.refundDat.productName}</td>
            <td>£{props.refundDat.unitPrice}</td>
            <td>£{props.refundDat.refundedValue}</td>
            <td>
                <select
                    value={refundReason}
                    onChange={(e) =>
                        changeReason(e.target.value)
                    }
                >
                    <option value="STAFF">Staff</option>
                    <option value="Faulty">Faulty</option>
                    <option value="ITEM ">Item</option>
                    <option value="Not Suitable">Not Suitable</option>
                </select>
            </td>
            <td>
                <input type="text" placeholder='Insert a note...'
                onChange={(e) => props.hadleRefundNote(props.uniqueId, e.target.value)}
                style={{width: "90%"}}/>
            </td>
        </>
    )
}