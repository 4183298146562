import './users.scss'
import {useState, useEffect} from 'react';
import axios from 'axios';
import IndvUser from './IndvUser';


export default function Users() {
    const [users, setUsers] = useState([])
    const [reload, setReload] = useState(false)
    const [holidayList,setHolidayList] = useState([])

    useEffect(() => {
        axios.get("https://nitropos-backend.nitrosolutions.co.uk/users/allUsers")
        .then((res) => {
            setUsers(res.data)
        }).catch((err) => {
            console.log(err)
        })

        axios.get("https://nitropos-backend.nitrosolutions.co.uk/users/holidayRoles")
        .then((res) => {
            setHolidayList(res.data)
        }).catch((err) => {
            console.log(err)
        })
    },[reload])

    const rel = () => {
        setReload(!reload)
    }

    return(
        <main className="users__dev">
            <h1>NitroPOS users</h1>
            <table>
                <thead>
                    <th>Email</th>
                    <th>Pin</th>
                    <th>Role</th>
                    <th>Site</th>
                    <th>Delete</th>
                </thead>
                <tbody>
                    {users.map(function(d,i){
                        return(
                            <IndvUser user={d} holidayList={holidayList} rel={rel}/>
                        )
                    })}
                </tbody>
            </table>
        </main>
    )
}