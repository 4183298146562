import { useEffect, useState, useRef } from "react";
import moment from "moment";
import "moment/min/locales";
import IndvWeek from "./IndvWeek";
import { Button } from "@mui/material";
import ConfirmDay from "./modal/ConfirmDay";
import Modal from "@mui/material/Modal";
import HeatMap from "./HeatMap";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import ArrowDropUpOutlinedIcon from "@mui/icons-material/ArrowDropUpOutlined";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

export default function Rota({
  site,
  siteName,
  fullDates,
  staffList,
  published,
  currentDate,
  rotaId,
  shortDates,
  user,
  reloadData,
  confirmedDates,
  lastEmployeeId,
  targetHours
}) {
  //Weekly Rota total hours Inc breaks
  const [totalWorkedHours, setTotalWorkedHours] = useState("");
  const [weeklyTotal, setWeeklyTotal] = useState("");

  //Weekly Rota total hours Excl breaks
  const [exclbreaktotal, setExclBreakTotal] = useState("");
  const [exclbreakDaily, setExclBreakDaily] = useState("");

  //Modal
  const [modalCheckDay, setModalCheckDay] = useState("");
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [selectedHeatMap, setSelecteHeatMap] = useState({});

  useEffect(() => {
    //Calculate the hours for day columns and full rota
    let totalWorked = {};
    let weekHoursTotal = 0;

    let _exclbreakDaily = {};
    let _exclBreakTotal = 0;

    for (let d = 0; d < fullDates.length; d++) {
      totalWorked[fullDates[d]] = 0;
      _exclbreakDaily[fullDates[d]] = 0;
    }

    let employees = staffList.filter(
      (staffMember) => staffMember.worked.length > 0
    );

    let shifts = [];

    for (let e = 0; e < employees.length; e++) {
      for (let s = 0; s < employees[e].worked.length; s++) {
        shifts.push(employees[e].worked[s]);
      }
    }

    //Remove shifts from shift array for time calculation if an the shift share the same day with sickness
    const shiftMap = new Map();

    shifts.forEach((shift) => {
      const shiftKey = `${shift.employeeDetailsId}-${shift.date}`;

      //If the id and date is not in the shiftMap then will be added.
      if (!shiftMap.has(shiftKey)) {
        shiftMap.set(shiftKey, shift);
      } else {
        //If the key and date is already in the shiftMap and one of the items had a leaveType of 3 (sickness) then remove it
        const existingShift = shiftMap.get(shiftKey);
        if (shift.leaveType === 3 || existingShift.leaveType === 3) {
          shiftMap.delete(shiftKey);
        }
      }
    });

    //Turn the shiftmap back into an array
    const newShiftArray = Array.from(shiftMap.values());

    //Will add up all hours worked and break times for shifts in same date column
    for (let sh = 0; sh < newShiftArray.length; sh++) {
      if (
        !newShiftArray[sh].leave &&
        newShiftArray[sh].hoursWorked &&
        !newShiftArray[sh].noShow
      ) {
        totalWorked[newShiftArray[sh].date] +=
          moment.duration(newShiftArray[sh].breakTime).asMilliseconds() +
          moment.duration(newShiftArray[sh].hoursWorked).asMilliseconds();

        _exclbreakDaily[newShiftArray[sh].date] += moment
          .duration(newShiftArray[sh].hoursWorked)
          .asMilliseconds();

        weekHoursTotal +=
          moment.duration(newShiftArray[sh].breakTime).asMilliseconds() +
          moment.duration(newShiftArray[sh].hoursWorked).asMilliseconds();

        _exclBreakTotal += moment
          .duration(newShiftArray[sh].hoursWorked)
          .asMilliseconds();
      }
    }

    //Loop through weekly date totals and convert to hour and mins string
    for (let h = 0; h < Object.values(totalWorked).length; h++) {
      let numOfMins = moment.duration(totalWorked[fullDates[h]]).asMinutes();
      let _hours = numOfMins / 60;
      let rhours = Math.floor(_hours);
      let minutes = (_hours - rhours) * 60;
      let rminutes = Math.round(minutes);

      totalWorked[fullDates[h]] = `${rhours}hr(s) ${rminutes}min(s)`;
    }

    for (let h = 0; h < Object.values(_exclbreakDaily).length; h++) {
      let numOfMins = moment
        .duration(_exclbreakDaily[fullDates[h]])
        .asMinutes();
      let _hours = numOfMins / 60;
      let rhours = Math.floor(_hours);
      let minutes = (_hours - rhours) * 60;
      let rminutes = Math.round(minutes);

      _exclbreakDaily[fullDates[h]] = `${rhours}hr(s) ${rminutes}min(s)`;
    }

    let numOfMinsWeekly = moment.duration(weekHoursTotal).asMinutes();
    let _Weeklyhours = numOfMinsWeekly / 60;
    let rWeeklyHours = Math.floor(_Weeklyhours);
    let weeklyMinutes = (_Weeklyhours - rWeeklyHours) * 60;
    let rWeeklyMinutes = Math.round(weeklyMinutes);

    let numOfMinsWeeklyEx = moment.duration(_exclBreakTotal).asMinutes();
    let _WeeklyhoursEx = numOfMinsWeeklyEx / 60;
    let rWeeklyHoursEx = Math.floor(_WeeklyhoursEx);
    let weeklyMinutesEx = (_WeeklyhoursEx - rWeeklyHoursEx) * 60;
    let rWeeklyMinutesEx = Math.round(weeklyMinutesEx);

    setTotalWorkedHours(totalWorked);
    setWeeklyTotal(`${rWeeklyHours}hr(s) ${rWeeklyMinutes}min(s)`);

    setExclBreakDaily(_exclbreakDaily);
    setExclBreakTotal(`${rWeeklyHoursEx}hr(s) ${rWeeklyMinutesEx}min(s)`);
  }, [staffList]);

  useEffect(() => {
    if (lastEmployeeId) {
      const scrollHere = `employee-row-${lastEmployeeId}`;
      const element = document.getElementById(scrollHere);

      if (element) {
        element.scrollIntoView({
          block: "center",
          behavior: "smooth",
        });
      }
    }
  }, [staffList, lastEmployeeId]);

  
  useEffect(()=>{
    const graph = heatMapRef.current
        const rect = graph?.getBoundingClientRect()
        console.log(rect, window.innerWidth)
        if(rect){
            if (rect?.right > window.innerWidth) {
                // graph.style.left = `${rect.right - window.innerWidth + 818}px`;
                graph.style.left = `-2350%`;
              }
              
        }
  }, [selectedHeatMap])
  const heatMapRef = useRef()

  const handleShowHeatMap = (idx) => {
    if (selectedHeatMap.hasOwnProperty(idx) && selectedHeatMap[idx] == idx) {
      return setSelecteHeatMap({});
    }
    setSelecteHeatMap((prev) => {
        
      return { [idx]: idx };
    });

  };


  const getTotalHours = (timeString) => {
    if (timeString) {
      const hoursMatch = timeString.match(/(\d+)hr/); // Extracts hours
      const minutesMatch = timeString.match(/(\d+)min/); // Extracts minutes

      const hours = hoursMatch ? parseInt(hoursMatch[1]) : 0;
      const minutes = minutesMatch ? parseInt(minutesMatch[1]) : 0;

      return hours + minutes / 60;
    } // Convert minutes to fraction of an hour
  };

  const getTargetCell = (data, day) => {
    const totalHours = getTotalHours(data);
    const dayOfWeek = moment(day).format("dddd")

    const difference = -targetHours[dayOfWeek] + totalHours;
    const RoundedOFFdifference = targetHours[dayOfWeek] - totalHours;
    
    if(!data) return null 



    if (RoundedOFFdifference > 0) {
      return (
        <>
          <span style={{color:'#07661d'}}>
            -{Math.abs(difference.toFixed(2))} hrs
            </span>
          <br></br>
          {`(Target ${targetHours[dayOfWeek]})`}
          
        </>
      );
    }
    else if(RoundedOFFdifference<0){
      return (
        <>
          <span style={{color: '#e32b2b'}}>
            +{Math.abs(difference.toFixed(2))} hrs
            </span>
          <br></br>
          {`(Target ${targetHours[dayOfWeek]})`}
        
        </>
      );
      
    }
    else{
      //   return (
        //     <div style={{padding:10.5}}>
        //       {'✅ Target Achieved!'}
        //     </div>
        //   );
        
        // }
        return (
          <>
        <span style={{color: targetHours[dayOfWeek] >  totalHours ? '#e32b2b' :'#07661d' }}>
          {/* {difference.toFixed(2)} hrs ✅ */}
          {difference.toFixed(2)} hrs 
          <HtmlTooltip
        title={
          <>
            <Typography color="inherit">Target Achieved </Typography>
            {`target was ${targetHours[dayOfWeek]} hrs and you have achieved ${totalHours?.toFixed(2)} hrs`}
            {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '}
            {"It's very engaging. Right?"} */}
          </>
        }
        >🏆</HtmlTooltip>
          </span>
        <br></br>
        {`(Target ${targetHours[dayOfWeek]})`}
        
      </>
    );
  };

  }

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#dbe866',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '2px solid rgb(248, 130, 33)',
    },
  }));



  return (
    <section className="rota_main">
      <table>
        <thead
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1000,
            backgroundColor: "#fff",
          }}
        >
          {/* <tr className="confirm_row">
                        <th colSpan={2} className="empty_cell"></th>
                        {fullDates.map(function (day, i) {
                            let dateDiff = moment().diff(
                                moment(day, "YYYY-MM-DD"),
                                "days"
                            );

                            return (
                                <>
                                    {" "}
                                    <th key={i}>
                                        {dateDiff > 0 &&
                                        confirmedDates[day] === false ? (
                                            <>
                                                {" "}
                                                <Button
                                                    color="success"
                                                    variant="outlined"
                                                    className="confirm_day_btn"
                                                    onClick={() => {
                                                        setConfirmationModal(
                                                            true
                                                        );
                                                        setModalCheckDay(day);
                                                    }}
                                                >
                                                    confirm day
                                                </Button>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </th> */}
          {/* Modal */}
          {/* <Modal
                                        open={confirmationModal}
                                        aria-labelledby="Confirmation Modal"
                                        aria-describedby="Confirmation Modal"
                                        sx={{
                                            "& .MuiBackdrop-root": {
                                                backgroundColor:
                                                    "rgba(0.4,0,0.2,0.1)",
                                            },
                                        }}
                                    >
                                        <ConfirmDay
                                            rotaId={rotaId}
                                            user={user}
                                            day={modalCheckDay}
                                            reloadData={reloadData}
                                            siteName={siteName}
                                            handleClose={setConfirmationModal}
                                        />
                                    </Modal>
                                </>
                            );
                        })}
                    </tr> */}
          <tr>
            <th colSpan={2}>Name</th>
            {shortDates.map(function (day, idx) {
              return (
                <th key={idx}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      zIndex: 1,
                      paddingleft: 20,
                      marginLeft: 40,
                    }}
                  >
                    <p>{day}</p>
                    {site != 2 && (
                      <div
                        style={{
                          width: "25px",
                          height: "25px",
                          // backgroundColor: '#110bbd',
                          // backgroundColor: '#689af7',
                          backgroundColor: "#6674de",
                          display: `${site != 2 ? "flex" : "none"}`,
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "50%",
                          cursor: "pointer",
                          fontWeight: "800px",
                          transition: "background-color 0.3s",
                          position: "relative",
                          fontSize: 10,
                          color: "white",
                        }}
                        onClick={(e) => {
                          handleShowHeatMap(idx);
                        }}
                        key={idx}
                      >
                        {" "}
                        {/* {(selectedHeatMap == idx && showHeatMap)? <>&#8595;</> : <>&#8593;</>} */}
                        {selectedHeatMap[idx] == idx ? (
                          <ArrowDropUpOutlinedIcon />
                        ) : (
                          <QueryStatsIcon />
                        )}
                        {selectedHeatMap[idx] == idx && (
                          <div
                            ref={heatMapRef}
                            style={{
                              position: "absolute",
                              top: "140%",
                              left: "60%",
                              // display: "flex",
                              // right: '600px',
                              padding: "5px",
                              // backgroundColor: '#5a8eed',
                              backgroundColor: "yellow",
                              border: "1px solid #ccc",
                              // transition: 'display 0.3s',
                              width: 450,
                              height:500,
                              zIndex: 1001,
                              boxShadow:
                                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                            }}
                          >
                            <HeatMap
                              rotaId={rotaId}
                              site={site}
                              currentDate={fullDates[idx]}
                            />
                          </div>
                        )}
                      </div>
                    )}
                    {/* <button onClick={(e, idx)=>{handleShowHeatMap(idx)}}> {showHeatMap? 'hide':'show'} </button> */}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {staffList.map(function (staffMember, i) {
            return (
              <IndvWeek
                key={i}
                staffMember={staffMember}
                fullDates={fullDates}
                currentDate={currentDate}
                site={site}
                siteName={siteName}
                rotaId={rotaId}
                user={user}
                published={published}
                shortDates={shortDates}
                reloadData={reloadData}
                confirmedDates={confirmedDates}
              />
            );
          })}
          <tr
            className="worked_totals"
            style={{
              position: "sticky",
              bottom: -1,
              zIndex: 100,
              backgroundColor: "#2c53a0",
              color: "#fff",
              border: "none",
            }}
          >
            <th colSpan={2}>
              <span>Week Total: </span>
              {weeklyTotal}
              <br />
              <span>Excl. Breaks: </span>
              {exclbreaktotal}
              {targetHours && <>
              <br></br>
              <span>± Target</span>
              </>
              }
            </th>
            {fullDates.map(function (day, i) {
              return (
                <th style={{border:'none'}} key={i}>
                  {totalWorkedHours[day]}
                  <br />
                  {exclbreakDaily[day]}
                  <br />
                  <div
                    style={{
                      backgroundColor: "#64c4e0",
                      marginTop: 2,
                      // borderRadius: 5,
                      fontWeight: 450,
                      fontSize: "14px",
                      letterSpacing: "1.5px",
                      color: 'black',
                      // textShadow:
                      //   "0px 2px 8px rgb(255, 255, 255), 0 0 20px rgba(0, 0, 0, 0.6), 0 0 30px rgb(27, 207, 231)",
                    }}
                  >
                    {targetHours && getTargetCell(exclbreakDaily[day], day)}
                  </div>
                </th>
              );
            })}
          </tr>
        </tbody>
      </table>
    </section>
  );
}
