import { useContext, useState, useEffect } from "react";
import axios from "axios";
import "./discrepancies.scss";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Tick from "../animations/Tick";
import { getCompanies, getEmployeesByCompany } from "../utils/Api";
import { decryptData } from "../utils/cryptoUtils";
import LoopIcon from "@mui/icons-material/Loop";

export default function ManualDisc() {
  const [siteList, setSiteList] = useState([]);
  const [staffNameList, setStaffNameList] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [showTick, setShowTick] = useState(false);
  const [token, setToken] = useState("")

  //Investigation Variables
  const [site, setSite] = useState("");
  const [reason, setReason] = useState("");
  const [cost, setCost] = useState();
  const [incidentDate, setIncidentDate] = useState("");
  const [user, setUser] = useState("");
  const [staffName, setStaffName] = useState("");
  const [note, setNote] = useState("");

  const url = "https://nitropos-backend.nitrosolutions.co.uk";
  // const url = "http://10.0.0.174:4022";


  useEffect(() => {
    let userD = localStorage.getItem("userDetails");
    let user = JSON.parse(userD);
    setUser(user.user.user);

    let getToken = localStorage.getItem("auth-token");
    setToken(getToken)

    getCompanies(getToken)
      .then((data) => {
        setSiteList(data);
      })
      .catch((err) => {
        console.log(`could not get sites: ${err}`);
      });
  }, []);

  const handleSite = async (site) => {
    setStaffNameList([]);
    for (let i = 0; i < siteList.length; i++) {
      if (siteList[i].eposId == site) {
        setSite(siteList[i].siteName);
        await getEmployeesByCompany(siteList[i].companyId, token)
          .then((employees) => {
            console.log(employees)
            for (let e = 0; e < employees.length; e++) {
              setStaffNameList((staffNameList) => [
                ...staffNameList,
                {
                  firstName: decryptData(
                    employees[e].firstName,
                    process.env.REACT_APP_EMP_KEY
                  ),
                  lastName: decryptData(
                    employees[e].lastName,
                    process.env.REACT_APP_EMP_KEY
                  ),
                  employeeDetailsId: employees[e].employeeDetailsId,
                },
              ]);
            }
          })
          .catch((err) => {
            console.log(`could not fetch employees: ${err}`);
          });
      }
    }
  };

  const submit = async () => {
    document.getElementById("create__btn").style.display = "none";

    if (
      site == "" ||
      site == "--Please Select a Site--" ||
      reason == "" ||
      reason == "--- SELECT ---" ||
      cost == 0 ||
      !cost ||
      staffName === "" ||
      !staffName ||
      incidentDate === "" ||
      !incidentDate ||
      note === "" ||
      !note
    ) {
      setMessage("Please make sure all fields are filled");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        setMessage("");
        document.getElementById("create__btn").style.display = "flex";
      }, 3500);
    } else if (parseInt(cost) < 0) {
      setMessage("Cost cannot be negative");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        setMessage("");
        document.getElementById("create__btn").style.display = "flex";
      }, 3500);
    } else {
      var data = {
        reason: reason,
        site: site,
        cost: parseFloat(cost),
        incidentDate: incidentDate,
        user: user,
        staffName: staffName,
        note: note,
      };

      axios
        .post(`${url}/discrepancies/createManualInvestigation`, data)
        .then((res) => {
          setShowTick(true);
          let inputs = document.querySelectorAll("select");
          for (let i = 0; i < inputs.length; i++) {
            inputs[i].selectedIndex = 0;
          }
          setCost("");
          setNote("");
          setIncidentDate("");
          setTimeout(() => {
            setShowTick(false);
            document.getElementById("create__btn").style.display = "flex";
          }, 3000);
        })
        .catch((err) => {
          console.log(err);
          setMessage("Something went wrong, please try again");
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
            setMessage("");
            document.getElementById("create__btn").style.display = "flex";
          }, 3500);
        });
    }
  };

  return (
    <main className="discrepancies">
      <h1>Create Manual Investigation</h1>

      <div className="manual__discrep">
        <label>
          Site<span>*</span>
        </label>
        <select onChange={(e) => handleSite(e.target.value)}>
          <option>--Please Select a Site--</option>
          {siteList.map(function (d, i) {
            return (
              <option key={i} value={d.eposId}>
                {d.siteName}
              </option>
            );
          })}
        </select>

        <label>
          Reason<span>*</span>
        </label>
        <select onChange={(e) => setReason(e.target.value)}>
          <option>--- SELECT ---</option>
          <option>Fake Note</option>
          <option>Staff Theft</option>
          <option>Float Adjust</option>
          <option>Lottery</option>
          <option>Wastage</option>
          <option>Delivery Issue</option>
          <option>Paid Advance</option>
          <option>Loan</option>
          <option>Other</option>
        </select>
        <label>
          Cost Amount<span>*</span>
        </label>
        <input
          type="number"
          onChange={(e) => setCost(e.target.value)}
          placeholder={0}
          min="0"
          value={cost}
        />
        <label>
          Incident Date<span>*</span>
        </label>
        <input
          type="date"
          onChange={(e) => setIncidentDate(e.target.value)}
          value={incidentDate}
        />
        <label>
          Staff Name<span>*</span>
        </label>
        {staffNameList.length === 0 ? (
          <LoopIcon fontSize="small" id="loading_icon"/>
        ) : (
          <select 
          onChange={(e) => setStaffName(e.target.value)}
          >
            <option>--Please Select Staff--</option>
            {staffNameList.map((staff, i) => {
              return (
                <option key={i} value={staff.employeeDetailsId}>
                  {staff.firstName} {staff.lastName}
                </option>
              );
            })}
          </select>
        )}
        <label>
          Note<span>*</span>
        </label>
        <textarea
          onChange={(e) => setNote(e.target.value)}
          cols={45}
          rows={5}
          value={note}
        />
      </div>

      <Button
        onClick={() => submit()}
        id="create__btn"
        variant="contained"
        color="success"
      >
        create
      </Button>
      {showAlert ? (
        <Alert severity="warning" sx={{ marginTop: "1%" }}>
          {message}
        </Alert>
      ) : (
        <></>
      )}
      {showTick ? <Tick /> : <></>}
    </main>
  );
}

