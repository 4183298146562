import { useEffect } from "react";
import NitroLogo from '../../../images/NITRO POS SQUARE.png'
import HikLogo from '../../../images/Hikvision Logo.png'
import './cctv.scss'

export default function Matrix() {

    useEffect(() => {
        var canvas = document.querySelector('canvas'),
            ctx = canvas.getContext('2d');

        // Setting the width and height of the canvas
        var width = window.innerWidth
        var height = window.innerHeight
        canvas.width = "1600";
        canvas.height = "755";

        // Setting up the letters
        var letters = '12345678900987654321234567890987654321ABCDEFGHIJKLMNOPQRSTUVXYZABCDEFGHIJKLMNOPQRSTUVXYZABCDEFGHIJKLMNOPQRSTUVXYZABCDEFGHIJKLMNOPQRSTUVXYZABCDEFGHIJKLMNOPQRSTUVXYZABCDEFGHIJKLMNOPQRSTUVXYZ';
        letters = letters.split('');

        // Setting up the columns
        var fontSize = 10,
            columns = width / fontSize;

        // Setting up the drops
        var drops = [];
        for (var i = 0; i < columns; i++) {
            drops[i] = 1;
        }

        // Setting up the draw function
        function draw() {
            ctx.fillStyle = 'rgba(44, 83, 160, 0.05)';
            ctx.fillRect(0, 0, width, height);
            for (var i = 0; i < drops.length; i++) {
                var text = letters[Math.floor(Math.random() * letters.length)];
                ctx.fillStyle = '#f4f4f4';
                ctx.fillText(text, i * fontSize, drops[i] * fontSize);
                drops[i]++;
                if (drops[i] * fontSize > height && Math.random() > .95) {
                    drops[i] = 0;
                }
            }
        }

        // Loop the animation
        setInterval(draw, 33);

    }, [])

    return (
        <>
            <canvas> </canvas>
            <div style={{ backgroundColor: "#f4f4f4", padding: "10%", borderRadius: "20px", position: "absolute", zIndex: "100", top: "33%" }}>
            <div style={{display:"flex",justifyItems:"space-between",position:"absolute",top:"5%", gap: "60%",marginLeft:"-20%"}}>
                    <img src={NitroLogo} width="200"/>
                    <img src={HikLogo} width="200"/>
                </div>
                <div class="loader2">
                    <div class="loader2__container">
                        <div class="loader2__film">
                            <img class="loader2__film-img" src="https://www.dropbox.com/s/o4p5i3nfw92rhfz/film.png?raw=1" alt="" />
                            <img class="loader2__film-img" src="https://www.dropbox.com/s/o4p5i3nfw92rhfz/film.png?raw=1" alt="" />
                        </div>
                        <img class="loader2__camera" src="https://www.dropbox.com/s/348z6yvtt9hbos2/camera.png?raw=1" alt="" />
                    </div>
                </div>
                <h2>WELCOME TO THE CCTV ZONE</h2>
                <div style={{display:"flex",justifyItems:"space-between",position:"absolute",bottom:"5%", gap: "60%",marginLeft:"-20%"}}>
                    <img src={HikLogo} width="200"/>
                    <img src={NitroLogo} width="200"/>
                </div>
            </div>
        </>
    )
}