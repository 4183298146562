import { useEffect, useState } from "react";
import axios from "axios";
import NewspaperIcon from '@mui/icons-material/Newspaper';
import Modal from '@mui/material/Modal';
import ClosedPO from "./modals/ClosedPO";

export default function Closed() {
  const [user, setUser] = useState("");
  const [invoices, setInvoices] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    let getUser = sessionStorage.getItem("user");
    setUser(getUser);

    //call to get latest invoices setInvoices(res.data)
  }, []);

  return (
    <main className="pending__po">
      <h1>Closed PO's</h1>
      <table>
        <thead>
            <tr>
                <th>PO Number/Order Number</th>
                <th>Site</th>
                <th>Date</th>
                <th>View Order</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>123456</td>
                <td>Autoport</td>
                <td>23/3/2023</td>
                <td><NewspaperIcon className="icon" onClick={handleOpen}/></td>
            </tr>
        </tbody>
      </table>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={handleClose}
      >
        <ClosedPO />
      </Modal>
    </main>
  );
}
