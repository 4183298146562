import DescriptionModal from "../../../devZone/cctv/modals/DescriptionModal";
import NotesModal from "../../../devZone/cctv/modals/NotesModal";
import StatusModal from "./modals/StatusModal";
import SpeakerNotesOffIcon from "@mui/icons-material/SpeakerNotesOff";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import DescriptionIcon from "@mui/icons-material/Description";
import ListIcon from "@mui/icons-material/List";
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import axios from "axios";

export default function CCTVIndividual({ request, rel, statusList }) {
    const url = process.env.REACT_APP_ANPR_URL;
    const [openNotes, setOpenNotes] = useState(false);
    const [openStatus, setOpenStatus] = useState(false);
    const [notes, setNotes] = useState([]);
    const [currentStatus, setCurrentStatus] = useState("");

    const [requestId, setRequestId] = useState("");

    useEffect(() => {
        for (let i = 0; i < statusList.length; i++) {
            if (statusList[i].statusId === request.statusId) {
                setCurrentStatus(statusList[i].name);
            }
        }
    }, [rel]);

    const handleOpenNotes = (notes, reqId) => {
        if (notes) {
            setNotes(notes);
            setRequestId(reqId);
        }
        setRequestId(reqId);
        setOpenNotes(true);
        let data = {
            requestId: reqId,
            user: "storezone",
        };
        axios
            .post(`${url}/cctv-system/add-log`, data)
            .then((res) => {})
            .catch((err) => {
                console.log(err);
            });
    };
    const handleCloseNotes = () => {
        setOpenNotes(false);
        setNotes([]);
    };

    return (
        <>
            <tr>
                <th>
                    {new Date(request.date).toLocaleDateString("en-GB")} -
                    {request.time}
                </th>
                <th>{request.staffName}</th>
                <th>{request.reason}</th>
                <th
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <ListIcon
                        color="primary"
                        onClick={() => setOpenStatus(true)}
                    />
                    {currentStatus}
                </th>
                <th>
                    {request.notes ? (
                        <SpeakerNotesIcon
                            color="primary"
                            onClick={() => {
                                handleOpenNotes(
                                    request.notes,
                                    request.requestId
                                );
                            }}
                        />
                    ) : (
                        <SpeakerNotesOffIcon
                            color="primary"
                            onClick={() =>
                                handleOpenNotes(
                                    request.notes,
                                    request.requestId
                                )
                            }
                        />
                    )}
                </th>
            </tr>

            <Modal open={openNotes}>
                <NotesModal
                    person={request.person}
                    incident={request.description}
                    images={request.images}
                    items={request.items}
                    allNotes={notes}
                    rel={rel}
                    handleCloseNotes={handleCloseNotes}
                    id={requestId}
                    time={request.time}
                    date={request.date}
                    site={request.site}
                    requestType={request.requestType}
                />
            </Modal>
            <Modal open={openStatus}>
                <StatusModal
                    statusList={statusList}
                    currentStatus={currentStatus}
                    reqId={request.requestId}
                    rel={rel}
                    handleCloseStatus={setOpenStatus}
                    statusId={request.statusId}
                />
            </Modal>
        </>
    );
}
