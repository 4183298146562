import { useState, useEffect } from "react";
import "../../styles/storeZone.scss";
import axios from "axios";
import IndStatus from "./IndStatus";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Loader from "../../../animations/Loader";

export default function RepairSystem() {
  const [repairData, setRepairData] = useState([]);
  const [reload, setReload] = useState(false);
  const [site, setSite] = useState("");
  const [notes, setNotes] = useState([]);
  const [successAlert, setSuccessAlert] = useState(false);
  const [name, setName] = useState("");
  const [machine, setMachine] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [issue, setIssue] = useState("");
  const [description, setDescription] = useState("");
  const [logComment, setLogComment] = useState("");
  const [image, setImage] = useState("");
  const [date, setDate] = useState("");
  const [make, setMake] = useState("");
  const [check, setCheck] = useState(false);
  const [logDate, setLogDate] = useState("");
  const [message, setMessage] = useState("");
  const [errorAlert, setErrorAlert] = useState(false);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    let getSite = localStorage.getItem("site");
    let localSite = getSite.replace(/['"]+/g, "");
    setSite(localSite);

    axios
      .get(
        `https://nitropos-backend.nitrosolutions.co.uk/maintenance/site?site=${localSite}`
      )
      .then((res) => {
        setRepairData(res.data);
        setNotes(res.data.notes);
        // console.log(res.data);
        setTimeout(() => {
          setShowLoader(false);
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reload]);

  const rel = () => {
    setReload(!reload);
  };

  const handleSuccess = () => {
    setSuccessAlert(true);
    setTimeout(() => {
      setSuccessAlert(false);
    });
  };

  const Send = () => {
    document.getElementById("button__hide").style.display = "none";

    if (
      site === "" ||
      date === "" ||
      name === "" ||
      machine === "" ||
      serialNumber === "" ||
      issue === "" ||
      description === ""
    ) {
      setMessage("Please fill all fields");
      setErrorAlert(true);
      setTimeout(() => {
        setErrorAlert(false);
      }, 2500);
      document.getElementById("button__hide").style.display = "block";
    } else if (image === "") {
      var data = {
        site: site,
        date: date,
        staffName: name,
        machineNumber: machine,
        serialNumber: serialNumber,
        machineMake: make,
        issue: issue,
        description: description,
        picture: image,
        logged: check,
        logDate: logDate,
        logComment: logComment,
      };
      axios
        .post(
          "https://nitropos-backend.nitrosolutions.co.uk/maintenance/new",
          data
        )
        .then((res) => {
          setSuccessAlert(true);
          setTimeout(() => {
            setSuccessAlert(false);
          }, 2500);
          rel();
          document.getElementById("button__hide").style.display = "block";
        })
        .catch((err) => {
          setMessage("Form could not be sent, please try again");
          setErrorAlert(true);
          setTimeout(() => {
            setErrorAlert(false);
          }, 2500);
          document.getElementById("button__hide").style.display = "block";
        });
    } else {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(image);
      fileReader.onload = () => {
        var data = {
          site: site,
          date: date,
          staffName: name,
          machineNumber: machine,
          serialNumber: serialNumber,
          machineMake: make,
          issue: issue,
          description: description,
          picture: fileReader.result,
          logged: check,
          logDate: logDate,
          logComment: logComment,
        };

        console.log(image);
        axios
          .post(
            "https://nitropos-backend.nitrosolutions.co.uk/maintenance/new",
            data
          )
          .then((res) => {
            setSuccessAlert(true);
            setTimeout(() => {
              setSuccessAlert(false);
            }, 2500);
            rel();
            document.getElementById("button__hide").style.display = "block";
          })
          .catch((err) => {
            setMessage("Form could not be sent, please try again");
            setErrorAlert(true);
            setTimeout(() => {
              setErrorAlert(false);
            }, 2500);
            document.getElementById("button__hide").style.display = "block";
          });
      };
    }
  };

  return (
    <main className="StaffHome">
      {/* <StoreNav /> */}
      {successAlert ? (
        <Alert
          variant="filled"
          severity="success"
          sx={{ position: "absolute", top: "30vh", width: "90vw", left: "5vw" }}
        >
          Repair updated
        </Alert>
      ) : (
        <></>
      )}
      {errorAlert ? (
        <Alert
          variant="filled"
          severity="error"
          sx={{ position: "absolute", top: "30vh", width: "90vw", left: "5vw" }}
        >
          {message}
        </Alert>
      ) : (
        <></>
      )}

      <h1>Welcome to the repair system</h1>
      <section className="repair__system">
        <div className="repair__form">
          <h6>Repair Form</h6>
          <div className="form">
            <label>
              Date<span>*</span>
            </label>
            <input type="date" onChange={(e) => setDate(e.target.value)} />
            <label>
              Your name<span>*</span>
            </label>
            <input
              type="text"
              onChange={(e) => setName(e.target.value)}
              required
            />
            <label>
              Machine Type<span>*</span>
            </label>
            <input
              type="text"
              onChange={(e) => setMachine(e.target.value)}
              required
            />
            <label>
              Serial Number<span>*</span>
            </label>
            <input
              type="text"
              onChange={(e) => setSerialNumber(e.target.value)}
              required
            />
            <label>
              Machine Make<span>*</span>
            </label>
            <select onChange={(e) => setMake(e.target.value)}>
              <option>--Select Make--</option>
              <option>Blue Ice</option>
              <option>Vertex</option>
              <option>Pelican Rouge</option>
              <option>Vending Sense</option>
              <option>Taylors</option>
              <option>A Cunningham & Company</option>
              <option>Abbey Services</option>
              <option>Air Serve</option>
              <option>Clyde Valley Lifts</option>
              <option>TSG</option>
              <option>TLM</option>
              <option>Gartec</option>
              <option>Stannah</option>
              <option>Bell Fire</option>
              <option>Fairbanks</option>
              <option>Photome</option>
              <option>Catering Appliance</option>
              <option>RT Maintenance</option>
              <option>Washing Machine</option>
            </select>
            <select onChange={(e) => setIssue(e.target.value)} required>
              <option>What's the issue</option>
              <option>Fault</option>
              <option>Damaged</option>
            </select>
            <label>
              Description<span>*</span>
            </label>
            <textarea
              type="text"
              onChange={(e) => setDescription(e.target.value)}
              cols="40"
              rows="5"
              required
            />
            <br />
            <div
              style={{
                border: "1px solid #2c53a0",
                borderRadius: "10px",
                padding: "10px",
                width: "80%",
              }}
            >
              <label>Click Here If You Have Logged This Issue</label>
              <input
                type="checkbox"
                value={check}
                onChange={(e) => {
                  setCheck(!check);
                  //   console.log(check);
                }}
              />
              {check ? (
                <div>
                  <hr />
                  <p>Date &amp; Time Logged</p>
                  <input
                    style={{ width: "100%" }}
                    type="datetime-local"
                    required
                    value={logDate}
                    onChange={(e) => setLogDate(e.target.value)}
                  />
                  <br />
                  <p>
                    Comment<span>*</span>
                  </p>
                  <textarea
                    style={{ width: "100%" }}
                    type="text"
                    onChange={(e) => setLogComment(e.target.value)}
                    cols="40"
                    rows="5"
                    required
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
            <br />
            <label>Upload Any Images (optional)</label>
            <input
              type="file"
              accepts="image/*"
              onChange={(e) => setImage(e.target.files[0])}
              style={{ border: "1px solid #2c53a0" }}
            />
            <br />

            <Button
              variant="outlined"
              color="primary"
              onClick={() => Send()}
              className="button"
              id="button__hide"
            >
              Send
            </Button>
          </div>
        </div>
        <div className="repair__form">
          <h6>Open repairs for {site}</h6>
          {showLoader ? (
            <Loader />
          ) : (
            <>
              {repairData.length != 0 ? (
                <table>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Issue</th>
                      <th>Status</th>
                      <th>Description</th>
                      <th>Notes</th>
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      {repairData.map(function (d, i) {
                        return (
                          <tr>
                            <IndStatus
                              d={d}
                              rel={rel}
                              success={handleSuccess}
                            />
                          </tr>
                        );
                      })}
                    </>
                  </tbody>
                </table>
              ) : (
                <p>No Open Repairs</p>
              )}
            </>
          )}
        </div>
      </section>
    </main>
  );
}
