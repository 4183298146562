import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Modal from "@mui/material/Modal";
import moment from "moment";
import ShiftModal from "./modal/ShiftModal";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import IndvShift from "./IndvShift";

export default function IndvDate({
    shifts,
    date,
    rotaId,
    employee,
    siteName,
    reloadData,
    confirmedDates,
}) {
    //Sets Actual shifts list
    const [actualShifts, setActualShifts] = useState([]);
    //Addtional Shift Locks
    const [lockAddShift, setLockAddShift] = useState(false);
    const [lockExtraShifts, setLockExtraShifts] = useState(false);

    //Add Shift Modal
    const [openShiftModal, setOpenShiftModal] = useState(false);
    const handleOpenShiftModal = () => {
        setOpenShiftModal(true);
    };
    const handleCloseShiftModal = () => setOpenShiftModal(false);

    useEffect(() => {
        //If all shifts in day confimed then lock extra shift button
        let filteredShifts = shifts.filter((shift) => shift.endTime != "");
        let allShiftsConfirmed = filteredShifts.every(
            (shift) => shift.confirmed
        );
        setLockExtraShifts(allShiftsConfirmed);

        //If whole day confirmed then will lock the ability to add new shifts
        if (confirmedDates[date]) {
            setLockAddShift(true);
            setLockExtraShifts(true);
        }
        let removedDuplicates = [];
        //TODO: FILTER OUT DUPLICATE LEAVE TYPES

        for (let s = 0; s < shifts.length; s++) {
            if (shifts[s].shiftId) {
                removedDuplicates.push(shifts[s]);
            } else {
                let duplicateCheck = removedDuplicates.findIndex(
                    (shift) => shift.leaveType === shifts[s].leaveType
                );

                if (duplicateCheck === -1) {
                    removedDuplicates.push(shifts[s]);
                }
            }
        }

        setActualShifts(removedDuplicates);
    }, []);

    return (
        <td
            className="shift_cell"
            style={
                date === moment().format("YYYY-MM-DD")
                    ? {
                          backgroundColor: "#ebf7f5",
                          borderRight: "1px solid #64c4e0",
                          borderLeft: "1px solid #64c4e0",
                      }
                    : lockAddShift
                    ? {
                          backgroundColor: "#edfcf0",
                      }
                    : null
            }
        >
            {/* IF NO SHIFTS */}
            {shifts.length === 0 ? (
                !lockAddShift ? (
                    <Button
                        variant="contained"
                        className="add_shift_btn"
                        onClick={handleOpenShiftModal}
                    >
                        <AddIcon />
                    </Button>
                ) : (
                    <DoNotDisturbIcon
                        fontSize="large"
                        sx={{
                            color: "rgba(44,83,160,0.1)",
                        }}
                    />
                )
            ) : (
                actualShifts.map((shift, i) => {
                    return (
                        <IndvShift
                            key={i}
                            shift={shift}
                            employee={employee}
                            rotaId={rotaId}
                            siteName={siteName}
                            date={date}
                            reloadData={reloadData}
                        />
                    );
                })
            )}
            {/* IF PERSON HAS SHIFT(S) THAT DAY */}
            {shifts.length > 0 ? (
                !lockExtraShifts ? (
                    <Button
                        variant="contained"
                        className="extra_shift_btn"
                        onClick={handleOpenShiftModal}
                    >
                        <AddIcon />
                    </Button>
                ) : null
            ) : null}
            <Modal
                open={openShiftModal}
                aria-labelledby="Add Shift Modal"
                aria-describedby="Add Shift Modal"
            >
                <ShiftModal
                    handleClose={handleCloseShiftModal}
                    rotaId={rotaId}
                    employee={employee}
                    site={siteName}
                    date={date}
                    reloadData={reloadData}
                    siteName={siteName}
                />
            </Modal>
        </td>
    );
}
