import SnusDaddyLogo from './images/snud_daddy-logo.png'
import "./styles/Brands.css";
import { useState,useEffect } from "react";
import Wrapper from "./SnusDaddyLabels/Wrapper";
import SnusSearch from "./SnusDaddyLabels/SnusSearch";
import StoreNav from "./StoreNav";

export default function SnusDaddy() {
  const [site, setSite] = useState("")

  useEffect(() => {
    const url2 = new URL(window.location.href);
    var s = url2.searchParams.get("site");
    setSite(s)
  }, [])

  return (
    <>
      <div className="brand">
        <StoreNav site={site} />
        <div className="brandsContent">
          <img style={{marginTop:"-10%",marginBottom:"-14%"}} src={SnusDaddyLogo} alt="Snus Daddy Logo" />
          <div className="labelGen">
            <Wrapper>
              <SnusSearch />
            </Wrapper>
          </div>
        </div>
      </div>
    </>
  );
}
