import axios from "axios";
const testUrl = "http://10.0.0.157:4022";
const liveUrl = process.env.REACT_APP_DEPPLOYED;

let token = localStorage.getItem("user");
let getToken, final;
if (token === null) {
  final = "";
} else {
  getToken = JSON.parse(token);
  final = getToken.token;
}

const config = {
  headers: { "Content-Type": "application/json", "x-auth-token": final },
};

const getSites = () => {
  return axios
    .get("https://nitrous.rt-itservices.co.uk/sites/data")
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return [];
    });
};

const getAllStores = () => {
  return axios
    .get(`${liveUrl}/eposSQL/getAllStores`)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return [];
    });
};

const getCompanies = (token) => {
  let localConfig;
  if (token) {
    localConfig = {
      headers: { "Content-Type": "application/json", "x-auth-token": token },
    };
  } else {
    localConfig = config;
  }

  return axios
    .get(`${process.env.REACT_APP_API_URL}/company`, localConfig)
    .then((res) => res.data)
    .catch((err) => {
      console.error(err);
      return [];
    });
};

const getEmployeesByCompany = (companyId, token) => {
  let localConfig;
  if (token) {
    localConfig = {
      headers: { "Content-Type": "application/json", "x-auth-token": token },
    };
  } else {
    localConfig = config;
  }

  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/employee/companyId/${companyId}`,
      localConfig
    )
    .then((res) => res.data)
    .catch((err) => {
      console.error(err);
      return [];
    });
};

const getSuppliers = () => {
  return axios
    .get(`${liveUrl}/eposSQL/getSuppliers`)
    .then((res) => res.data)
    .catch((err) => {
      console.log(`Could not get all suppliers: ${err}`);
      return [];
    });
};

const getLeaveTypes = (token) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/holiday/leaveTypes/new`, token)
    .then((res) => res.data)
    .catch((err) => {
      console.log(`Could not get leave types: ${err}`);
      return [];
    });
};

const getSitesByUser = (sites) => {
  return axios
    .post(`${liveUrl}/eposSQL/getSitesByName`, { sites: sites })
    .then((res) => res.data)
    .catch((err) => {
      console.log(`Could not get the sites by user: ${err}`);
      return [];
    });
};

const getSubwaySites = () => {
  return axios
    .get(`${liveUrl}/eos/subway/locations`)
    .then((res) => res.data)
    .catch((err) => {
      console.log(`Could not get subway sites: ${err}`);
      return [];
    });
};

const getCCTVStatus = () => {
  const url = process.env.REACT_APP_ANPR_URL;
  return axios
    .get(`${url}/cctv-system/statuses`)
    .then((res) => res.data)
    .catch((err) => {
      console.log(`Could not get CCTV statuses: ${err}`);
      return [];
    });
};

const getPriorityList = () => {
  return axios
    .get(`${liveUrl}/alerts/priority`)
    .then((res) => res.data)
    .catch((err) => {
      console.log(`Could not get priorities: ${err}`);
      return [];
    });
};

export {
  getSites,
  getAllStores,
  getEmployeesByCompany,
  getCompanies,
  getSuppliers,
  getLeaveTypes,
  getSubwaySites,
  getCCTVStatus,
  getSitesByUser,
  getPriorityList,
};
