import { useState, useEffect } from "react";
import axios from "axios";
import { getAllStores } from "../../utils/Api";
import { IconButton, Alert } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Loader from "../../animations/Loader";
import moment from "moment/moment";
import { object } from "prop-types";

export default function CigsStock() {
  //   const url = "http://10.0.0.157:4022";
  const url = "https://nitropos-backend.nitrosolutions.co.uk";

  const [siteList, setSiteList] = useState([]);
  const [site, setSite] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [cigData, setCigData] = useState([]);
  const [missingProduct, setMissingProduct] = useState({});
  //alerts
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  //loader
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    getAllStores()
      .then((data) => {
        //No cigs on HO
        for (let d = 0; d < data.length; d++) {
          if (data[d].siteName === "Head Office") {
            data.splice(d, 1);
          }
        }
        setSiteList(data);
      })
      .catch((err) => {
        console.log(`could not get sites: ${err}`);
      });
  }, []);

  const handleSearch = () => {
    setMissingProduct({});
    setCigData([]);
    if (site === "" || startDate === "" || endDate === "") {
      setMessage("Please select site, start and end date");
      setSeverity("warning");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
      //date the system started to save dates on db
    } else if (moment(startDate) < moment("2023-12-04")) {
      setMessage("Start date cannot be before 4/12/23");
      setSeverity("warning");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    } else {
      setShowLoader(true);
      let data = {
        site: parseInt(site),
        startDate: startDate,
        endDate: endDate,
      };
      axios
        .post(`${url}/stock/cigarettes/getAll`, data)
        .then((res) => {
          setShowLoader(false);
          setCigData(res.data);
        })
        .catch((err) => {
          setShowLoader(false);
          console.log(`Could not get cigarettes: ${err}`);
          setMessage(`Could not get stock: ${err}`);
          setSeverity("error");
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
          }, 3000);
        });
    }
  };

  const handleProduct = (productId) => {
    let data = {
      site: parseInt(site),
      startDate: startDate,
      endDate: endDate,
      productId: productId,
    };
    axios
      .post(`${url}/stock/cigarettes/getProduct`, data)
      .then((res) => {
        let result = res.data;
        let dates = [];
        let productName = "";
        for (let r = 0; r < result.length; r++) {
          if (r === 0) {
            productName = result[r].name;
          }
          dates.push({ date: result[r].dateMissing, stock: result[r].stock });
        }
        setMissingProduct({ dates: dates, name: productName });
      })
      .catch((err) => {
        console.log(
          `Could not get the number of missing stock between dates:${err}`
        );
        setMessage(
          `Could not get the number of missing stock between dates:${err}`
        );
        setSeverity("error");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      });
  };

  return (
    <main className="cigs_tab">
      <h1>Cigarette Stock report</h1>
      <section className="search_row">
        <select onChange={(e) => setSite(e.target.value)}>
          <option>--Select a site--</option>
          {siteList.map((site) => {
            return (
              <option key={site.eposId} value={site.eposId}>
                {site.siteName}
              </option>
            );
          })}
        </select>
        <label>Start date:</label>
        <input type="date" onChange={(e) => setStartDate(e.target.value)} />
        <label>End date:</label>
        <input type="date" onChange={(e) => setEndDate(e.target.value)} />
        <IconButton onClick={handleSearch}>
          <SearchIcon color="primary" fontSize="large" />
        </IconButton>
      </section>
      {showAlert ? (
        <Alert severity={severity}>{message}</Alert>
      ) : showLoader ? (
        <Loader />
      ) : null}
      {cigData && cigData.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Product</th>
              <th>Stock Missing</th>
              <th>Date Missing</th>
            </tr>
          </thead>
          <tbody>
            {cigData.map((product) => {
              return (
                <tr
                  key={product.id}
                  onClick={() => handleProduct(product.productId)}
                >
                  <td>{product.name}</td>
                  <td>{product.stock}</td>
                  <td>
                    {new Date(product.dateMissing).toLocaleDateString("en-GB")}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : null}
      {Object.keys(missingProduct).length > 0 ? (
        <section className="product__card">
          <strong>
            Name: <span>{missingProduct.name}</span>
          </strong>
          <strong>
            Total missing between{" "}
            {new Date(startDate).toLocaleDateString("en-GB")} and{" "}
            {new Date(endDate).toLocaleDateString("en-GB")}
          </strong>
          <ul>
            {missingProduct.dates.map((product, index) => {
              return (
                <li key={index}>
                  {new Date(product.date).toLocaleDateString("en-GB")}{" "}
                  <span>{product.stock}</span>
                </li>
              );
            })}
          </ul>
        </section>
      ) : null}
    </main>
  );
}
