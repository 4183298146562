import FtgNav from "./FtgNav";
import { useState, useEffect } from "react";
import "./styles/products.scss";
import AddProduct from "./Products/AddProduct";
import ModifyProduct from "./Products/ModifyProduct";
import {
    getAllHoagiesProducts,
    getCategories,
    getAllAllergens,
} from "./hoagiesUtils/Api";

export default function FtgHome() {
    const [value, setValue] = useState(0);
    const [categoryList, setCategoryList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [reload, setReload] = useState(false);
    const [allergenList, setAllergenList] = useState([]);

    useEffect(() => {
        //gets Hoagies categories
        getCategories().then((cats) => {
            setCategoryList(cats);
        });

        //gets all hoagies products
        getAllHoagiesProducts().then((products) => {
            setProductList(products);
        });

        //gets allergens
        getAllAllergens().then((allergens) => {
            setAllergenList(allergens);
        });
    }, [reload]);

    //handles forms
    const handleButtons = (val) => {
        if (val === value) {
            setValue(0);
        } else {
            setValue(val);
        }
    };

    //reload function
    const rel = () => {
        setReload(!reload);
    };

    return (
        <>
            <FtgNav />
            <main className="food_to_go main">
                <section className="products__section">
                    <h1>Hoagies Products</h1>
                    <div className="products__btns">
                        <button onClick={() => handleButtons(1)}>
                            Add Product
                        </button>
                        <button onClick={() => handleButtons(2)}>
                            Modify Product
                        </button>
                    </div>
                    {value === 1 ? (
                        <AddProduct
                            cats={categoryList}
                            rel={rel}
                            allergens={allergenList}
                        />
                    ) : value === 2 ? (
                        <ModifyProduct
                            products={productList}
                            cats={categoryList}
                            rel={rel}
                            allergens={allergenList}
                        />
                    ) : (
                        <></>
                    )}
                </section>
            </main>
        </>
    );
}
