import { useState } from "react";
import moment from "moment";
import { Modal } from "@mui/material";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import { Alert } from "@mui/material";
import axios from "axios";
import Loader from "../../../animations/Loader";
import { numberValidation } from "../../../utils/Validation";

//Styling
const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90vw",
    height: "80vh",
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
    overflowY: "scroll",
    overflowX: "hidden",
};
const stockModal = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "310px",
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
};

//Interface
interface Props {
    poData?: any;
    rel: () => void;
}

export default function PurchaseOrderHistoric({ poData, rel }: Props) {
    const [open, setOpen] = useState<boolean>(false);
    const [alert, setAlert] = useState<boolean>(false);
    const [loader, setLoader] = useState<boolean>(false);
    const [products, setProducts] = useState<any>([]);
    const [openStockTake, setOpenStockTake] = useState<boolean>(false);
    const [stockTakeId, setStockTakeId] = useState<number | null>(null);
    const [stockAlert, setStockAlert] = useState<boolean>(false);
    const [disable, setDisable] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [user, setUser] = useState<string>("");

    const handleOpen = async () => {
        setAlert(false);
        setOpen(true);
        setLoader(true);
        await axios
            .get(
                `${process.env.REACT_APP_DEPPLOYED}/purchaseOrder/getPoById/${poData.poId}`
            )
            .then((res) => {
                setProducts(res.data.products);
                setLoader(false);
            })
            .catch((err) => {
                setLoader(false);
                setAlert(true);
            });
    };

    const handleStockTakeIdSubmit = async () => {
        setDisable(true);
        let data = {
            user: user,
            poId: poData.poId,
            stockTakeId: stockTakeId,
        };

        await axios
            .post(
                `${process.env.REACT_APP_DEPPLOYED}/purchaseOrder/stockTake/add`,
                data
            )
            .then((res) => {
                setMessage("Stock take ID upload successful");
                setStockAlert(true);
                setTimeout(() => {
                    setStockAlert(false);
                    setMessage("");
                    setOpenStockTake(false);
                    rel();
                }, 5000);
                setDisable(false);
            })
            .catch((err) => {
                console.log(err);
                setMessage("Something went wrong - try again");
                setStockAlert(true);
                setTimeout(() => {
                    setStockAlert(false);
                    setMessage("");
                }, 5000);
                setDisable(false);
            });
    };

    const handleStockTakeIdValidation = () => {
        if (stockTakeId === null || isNaN(stockTakeId)) {
            setStockAlert(true);
            setMessage("Please enter a stock take ID");
            setTimeout(() => {
                setStockAlert(false);
                setMessage("");
            }, 5000);
        } else if (user === "") {
            setStockAlert(true);
            setMessage("Please enter your name");
            setTimeout(() => {
                setStockAlert(false);
                setMessage("");
            }, 5000);
        } else {
            handleStockTakeIdSubmit();
        }
    };

    return (
        <>
            <tr onClick={handleOpen}>
                <td>
                    {moment(poData.submissionDate).format("DD-MM-YYYY HH:mm")}
                </td>
                <td>{poData.staff ? poData.staff : "no user"}</td>
                <td>{poData.supplierName}</td>
                <td>{poData.statusName}</td>
                <td>
                    {poData.stockTakeId ? (
                        <Button
                            variant="contained"
                            color="success"
                            className="stock_btn"
                        >
                            {poData.stockTakeId}
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            color="warning"
                            onClick={(e) => {
                                e.stopPropagation();
                                setOpenStockTake(true);
                            }}
                            className="stock_btn"
                        >
                            Update ID
                        </Button>
                    )}
                </td>
            </tr>

            <Modal open={open} onClose={() => setOpen(false)}>
                <Box sx={style} className="order_history_table">
                    <h2>PO {poData.poId}</h2>
                    {!loader ? (
                        <>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Original Order</th>
                                        <th>Actual Order</th>
                                        <th>Current Stock</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {products.map(function (d: any, i: number) {
                                        return (
                                            <tr key={i}>
                                                <td>{d.name}</td>
                                                <td>{d.originalToOrder}</td>
                                                <td>{d.actualToOrder}</td>
                                                {d.storeStock ? (
                                                    <td>
                                                        {
                                                            d.storeStock
                                                                .currentStock
                                                        }
                                                    </td>
                                                ) : (
                                                    <td>N/A</td>
                                                )}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={() => setOpen(false)}
                            >
                                Close
                            </Button>
                        </>
                    ) : (
                        <Loader />
                    )}
                    {alert ? (
                        <Alert className="alert" severity={"error"}>
                            Something Went Wrong. Please Try Again.
                        </Alert>
                    ) : null}
                </Box>
            </Modal>
            <Modal open={openStockTake}>
                <Box sx={stockModal} className="stock_take_modal">
                    <h1>Enter Stock Take ID</h1>

                    <input
                        type="number"
                        min="0"
                        placeholder="Enter Stock Take ID"
                        onChange={(e) =>
                            setStockTakeId(parseInt(e.target.value))
                        }
                        onKeyDown={(e) => {
                            numberValidation(e);
                        }}
                    />
                    <input
                        type="text"
                        placeholder="Enter your name"
                        onChange={(e) => setUser(e.target.value)}
                    />
                    <section className="alert">
                        {" "}
                        {stockAlert ? <p>{message}</p> : null}
                    </section>

                    <Button
                        variant="contained"
                        onClick={handleStockTakeIdValidation}
                        disabled={disable}
                    >
                        Submit
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => setOpenStockTake(false)}
                        disabled={disable}
                    >
                        Close
                    </Button>
                </Box>
            </Modal>
        </>
    );
}
