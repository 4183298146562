import Chart from "react-apexcharts";
import ReportBreakdown from "../ReportBreakdown";
import Modal from "@mui/material/Modal";
import { useState, useEffect } from "react";

interface Props {
    series: any[];
    xAxis: string[];
    graph: string;
    breakdownURL?: string;
    start?: string;
    end?: string;
    selectedSites?: any;
    innerBreakdownURL?: string;
}

export function Bar({
    series,
    xAxis,
    graph,
    breakdownURL,
    start,
    end,
    selectedSites,
}: Props) {
    const [reportCategory, setReportCategory] = useState("");
    const [openBreakdown, setOpenBreakdown] = useState(false);

    const graphInfo: any = {
        series: series,
        options: {
            chart: {
                type: "bar",
                height: "auto",
                events: {
                    dataPointSelection: (
                        event: any,
                        chartContext: any,
                        config: any
                    ) => {
                        if (breakdownURL && breakdownURL != "") {
                            handleReportBreakdown(xAxis[config.dataPointIndex]);
                        }
                    },
                },
            },
            labels: xAxis,
            plotOptions: {
                bar: {
                    horizontal: graph === "hbar" ? true : false,
                    columnWidth: "55%",
                    endingShape: "rounded",
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ["transparent"],
            },
            xaxis: {
                categories: xAxis,
                labels: {
                    formatter: function (val: number) {
                        if (graph === "hbar") {
                            return (
                                "£ " +
                                val
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            );
                        } else {
                            return val;
                        }
                    },
                },
            },
            yaxis: {
                labels: {
                    formatter: function (val: number) {
                        if (graph === "bar") {
                            return (
                                "£ " +
                                val
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            );
                        } else {
                            return val;
                        }
                    },
                },
            },
            fill: {
                opacity: 1,
            },
            tooltip: {
                y: {
                    formatter: function (val: number) {
                        return (
                            "£ " +
                            val
                                .toFixed(2)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        );
                    },
                },
            },
        },
    };

    const handleReportBreakdown = (cat: string) => {
        setReportCategory(cat);
        setOpenBreakdown(true);
    };

    return (
        <>
            <Chart
                options={graphInfo.options}
                series={graphInfo.series}
                type="bar"
            />
            <Modal open={openBreakdown} onClose={() => setOpenBreakdown(false)}>
                <ReportBreakdown
                    reportCategory={reportCategory}
                    breakdownURL={breakdownURL}
                    start={start}
                    end={end}
                    selectedSites={selectedSites}
                />
            </Modal>
        </>
    );
}
