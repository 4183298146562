import NavBar from "../../NavBar";
import { getAllStores, getPriorityList } from "../../utils/Api";
import "./alerts.scss";
import { useState, useEffect } from "react";
import { Alert, Button } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import axios from "axios";
import IndvAlert from "./IndvAlert";
import { dataUriToBuffer } from "data-uri-to-buffer";
import CloseIcon from "@mui/icons-material/Close";

export default function CreateAlert() {
    const url = "https://nitropos-backend.nitrosolutions.co.uk";
    // const url = "http://10.0.0.13:4022";
    const [priorityList, setPriorityList] = useState([]);
    const [user, setUser] = useState("");
    const [userAlerts, setUserAlerts] = useState([]);
    const [priority, setPriority] = useState(0);
    const [description, setDescription] = useState("");
    const [siteList, setSiteList] = useState([]);
    const [showSites, setShowSites] = useState(false);
    const [allSites, setAllSites] = useState(false);
    const [selectedSites, setSelectedSites] = useState([]);
    const [disableButton, setDisableButton] = useState(false);
    const [images, setImages] = useState([]);

    //alerts
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [imageAlert, setImageAlert] = useState(false);

    useEffect(() => {
        let userD = localStorage.getItem("userDetails");
        let user = JSON.parse(userD);
        let currentUser = user.user.user;
        setUser(currentUser);

        getPriorityList()
            .then((priorities) => {
                setPriorityList(priorities);
            })
            .catch((err) => {
                console.log(`Could not get priorities: ${err}`);
            });

        getAllStores()
            .then((sites) => {
                setSiteList(sites);
            })
            .catch((err) => {
                console.log(`Could not get all sites: ${err}`);
            });

        //Get all users alerts
        axios
            .post(`${url}/alerts/userAlerts`, { user: currentUser })
            .then((res) => {
                setUserAlerts(res.data);
                console.log(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const handleShowSites = () => {
        setShowSites(!showSites);
        setSelectedSites([]);
    };

    const handleSites = (site) => {
        console.log(selectedSites);
        let selectSites = selectedSites;
        // checks that not all sites are selected before adding to array
        if (allSites) {
            setAllSites(false);
        }

        // checks if it exists on the local array
        let inx = selectSites.findIndex((p) => p === site);
        if (inx < 0) {
            selectSites.push(site);
        } else {
            selectSites.splice(inx, 1);
        }
        setSelectedSites(selectSites);
    };

    const handleAllSites = () => {
        let allSitesSelect = [];
        if (allSites) {
            allSitesSelect = [];
            setAllSites(false);
        } else {
            for (let s = 0; s < siteList.length; s++) {
                allSitesSelect.push(siteList[s].eposId);
            }
            setAllSites(true);
        }
        setSelectedSites(allSitesSelect);
    };

    const addImage = (image) => {
        if (image === "") {
            document.getElementById("image-btn").style.display = "none";
            setMessage("Please upload an image first");
            setSeverity("warning");
            setImageAlert(true);
            setTimeout(() => {
                setImageAlert(false);
                setSeverity("");
                setMessage("");
                document.getElementById("image-btn").style.display = "flex";
            }, 3000);
        } else {
            const img = new FileReader();
            img.readAsDataURL(image);
            img.onload = () => {
                if (images.includes(img.result)) {
                    document.getElementById("image-btn").style.display = "none";
                    setMessage("This image has already been added");
                    setSeverity("warning");
                    setImageAlert(true);
                    setTimeout(() => {
                        setImageAlert(false);
                        setMessage("");
                        setSeverity("");
                        document.getElementById("image-btn").style.display =
                            "flex";
                    }, 3000);
                } else {
                    setImages([...images, img.result]);
                }
            };

            document.getElementById("image-upload").value = null;
        }
    };

    const removeImage = (image) => {
        const filteredArray = images.filter((img) => img !== image);
        setImages(filteredArray);
    };

    const formatCheck = (check) => {
        let formatArr = [];

        for (let i = 0; i < check.length; i++) {
            let fileData = check[i].split(",");
            let type = fileData[0].replace("data:", "");
            type = type.replace(";base64", "");
            let imgFormat = type.replace("image/", "");
            formatArr.push(imgFormat);
        }

        return formatArr;
    };

    const sendAlert = () => {
        if (
            description === "" ||
            selectedSites.length === 0 ||
            priority === 0 ||
            priority === "--Please select one--"
        ) {
            setSeverity("warning");
            setMessage("Please fill all details");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
            }, 3000);
        } else {
            let localName = "";
            if (user.includes("ghsl")) {
                localName = "DIRECTORS";
            } else {
                localName = "HEAD OFFICE";
            }
            let imageFormats = formatCheck(images);
            let data = {
                name: localName,
                description: description,
                priority: priority,
                user: user,
                sites: selectedSites,
                images: imageFormats,
            };

            setDisableButton(true);

            axios
                .post(`${url}/alerts/new`, data)
                .then(async (res) => {
                    let s3Array = res.data;

                    for (let i = 0; i < s3Array.length; i++) {
                        let buffer = dataUriToBuffer(images[i]);

                        axios
                            .put(s3Array[i], buffer.buffer)
                            .then((res) => {})
                            .catch((err) => {
                                console.log(err);
                            });
                    }

                    setSeverity("success");
                    setMessage("Alert successfully submitted");
                    setShowAlert(true);
                    setTimeout(() => {
                        setShowAlert(false);
                        setSeverity("");
                        setMessage("");
                        document.getElementById("priority").selectedIndex = 0;
                        document.getElementById("description").value = "";
                        setDescription("");
                        setPriority(0);
                        handleShowSites();
                        setImages([]);
                        setDisableButton(false);
                    }, 3000);
                })
                .catch((err) => {
                    console.log(`Could not post the alert: ${err}`);
                    setSeverity("error");
                    setMessage("Could not send the alert, please try again");
                    setShowAlert(true);
                    setTimeout(() => {
                        setShowAlert(false);
                        setDisableButton(false);
                    }, 3000);
                });
        }
    };

    return (
        <>
            <NavBar />{" "}
            <main className="create_alert_main main">
                <h1>Create an alert for stores</h1>
                <div className="overall_section">
                    <section className="overall_left">
                        <h2>Create an Alert</h2>
                        <section className="create_alert">
                            <label>
                                Please select a priority level:<span>*</span>
                            </label>
                            <select
                                onChange={(e) => setPriority(e.target.value)}
                                id="priority"
                            >
                                <option>--Please select one--</option>
                                {priorityList.map((priority) => {
                                    return (
                                        <option
                                            key={priority.priorityId}
                                            value={priority.priorityId}
                                        >
                                            {priority.name}
                                        </option>
                                    );
                                })}
                            </select>
                            <label>
                                Alert description:<span>*</span>
                            </label>
                            <textarea
                                cols={40}
                                rows={5}
                                onChange={(e) => setDescription(e.target.value)}
                                id="description"
                            />{" "}
                            <label>Upload Image(s):</label>
                            <section className="image_upload_section">
                                <div className="upload_top">
                                    <div className="upload_left">
                                        <input
                                            type="file"
                                            id="image-upload"
                                            accept="image/*"
                                            onChange={(e) =>
                                                addImage(e.target.files[0])
                                            }
                                        />
                                    </div>
                                </div>
                                {imageAlert ? (
                                    <Alert severity={severity}>{message}</Alert>
                                ) : (
                                    <></>
                                )}
                                <div className="image__uploads">
                                    {images.length > 0 ? (
                                        images.map((image, i) => {
                                            return (
                                                <div
                                                    key={i}
                                                    className="image_container"
                                                >
                                                    <img src={image} />
                                                    <CloseIcon
                                                        className="icon-tag"
                                                        color="error"
                                                        onClick={() =>
                                                            removeImage(image)
                                                        }
                                                    />
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </section>
                            <label>
                                Select sites to be alerted: <span>*</span>
                            </label>
                            <div className="site_select">
                                {!showSites ? (
                                    <section className="all_sites_column">
                                        <span onClick={handleShowSites}>
                                            Show individual sites:{" "}
                                            <KeyboardArrowDownIcon color="primary" />
                                        </span>
                                        <FormControlLabel
                                            control={<Checkbox />}
                                            label="All Sites"
                                            onChange={(e) =>
                                                handleAllSites(e.target.value)
                                            }
                                            value="all sites"
                                        />
                                    </section>
                                ) : (
                                    <section className="all_sites_column">
                                        <span onClick={handleShowSites}>
                                            Select all sites:{" "}
                                            <KeyboardArrowDownIcon color="primary" />
                                        </span>

                                        <FormGroup
                                            sx={{ color: "#000" }}
                                            onChange={(e) =>
                                                handleSites(e.target.value)
                                            }
                                        >
                                            {siteList.map((site) => {
                                                return (
                                                    <FormControlLabel
                                                        control={<Checkbox />}
                                                        label={site.siteName}
                                                        value={site.eposId}
                                                        key={site.eposId}
                                                    />
                                                );
                                            })}
                                        </FormGroup>
                                    </section>
                                )}
                            </div>
                            {showAlert ? (
                                <Alert
                                    sx={{ margin: "10px 0" }}
                                    severity={severity}
                                >
                                    {message}
                                </Alert>
                            ) : (
                                <Button
                                    variant="outlined"
                                    onClick={sendAlert}
                                    disable={disableButton}
                                    sx={{ margin: "10px 0" }}
                                >
                                    Send Alert
                                </Button>
                            )}
                        </section>
                    </section>
                    <section className="alerts_table">
                        <h2>
                            Alerts for{" "}
                            <span style={{ color: "#2c53a0" }}>{user}</span>
                        </h2>
                        <table>
                            <thead>
                                <tr>
                                    <th>Site</th>
                                    <th>Alert</th>
                                    <th>Date</th>
                                    <th>Images</th>
                                    <th>Comments</th>
                                    <th>Actioned</th>
                                </tr>
                            </thead>
                            <tbody>
                                {userAlerts.map((alert, i) => {
                                    let siteName;
                                    for (let s = 0; s < siteList.length; s++) {
                                        if (
                                            alert.siteId === siteList[s].eposId
                                        ) {
                                            siteName = siteList[s].siteName;
                                        }
                                    }
                                    return (
                                        <IndvAlert
                                            key={i}
                                            alert={alert}
                                            siteName={siteName}
                                        />
                                    );
                                })}
                            </tbody>
                        </table>
                    </section>
                </div>
            </main>
        </>
    );
}
