import { useState, useEffect } from "react";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import Alert from "@mui/material/Alert";
import Loader from "../Fuel/loader/Loader";
import AlertTitle from "@mui/material/AlertTitle";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

//excel exports
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

export default function GlobalReport() {
  const [site, setSite] = useState("");
  const [date, setDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [siteList, setSiteList] = useState([]);
  const [alert, setAlert] = useState(false);
  const [loader, setLoader] = useState(false);
  const [infoExpand, setInfoExpand] = useState(false);
  const [salesInfo, setSalesInfo] = useState(false);
  const [message, setMessage] = useState("");
  const [eposNow, setEposNow] = useState();
  const [split, setSplit] = useState([]);
  const [csv, setCSV] = useState([]);
  const [user, setUser] = useState("")
  const [showDateRange, setShowDateRange] = useState(false)

  useEffect(() => {
    let email = sessionStorage.getItem("user")
    setUser(email)

    axios
      .get("https://nitrous.rt-itservices.co.uk/sites/data")
      .then((res) => {
        setSiteList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleExpand = () => {
    setInfoExpand(!infoExpand);
  };

  const exportExcel = () => {
    //export to excel
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const excelExportData = [];

    csv.forEach((obj) => {
      var newObj = {};

      Object.keys(obj).forEach((line) => {
        newObj[line] = obj[line];
      });
      excelExportData.push(newObj);
    });


    const exportToExcel = async () => {
      const ws = XLSX.utils.json_to_sheet(excelExportData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(
        data,
        `Card Transactions-${site}` + fileExtension
      );
    };

    exportToExcel();

  };

  const submit = (type) => {
    console.log(site,type)
    if (site === "--Select Site--" || site === "") {
      setMessage("Please Select a Site");
      setAlert(true);
      setTimeout(() => {
        setAlert(false);
      }, 2500);
    } else if (type == "dateRange" && (date == "" || endDate == "")) {
      setMessage("Please Select a Date Range");
      setAlert(true);
      setTimeout(() => {
        setAlert(false);
      }, 2500);
    } else {
      if(type!=="dateRange"){
        setShowDateRange(false)
      }
      setLoader(true);
      setInfoExpand(!infoExpand);

      let data = {
        site: site,
        type: type,
        start: date,
        end: endDate
      };
      axios
        .post(
          "https://nitropos-backend.nitrosolutions.co.uk/sales/global-payments/db",
          data
        )
         //.post("http://10.0.0.174:4022/sales/global-payments/db", data)
        .then((res) => {
          console.log(res.data);
          setEposNow(res.data.global);
          setLoader(false);
          setSalesInfo(true);
          setSplit(res.data.tillSplit);
          setCSV(res.data.csv);
        })
        .catch((err) => {
          console.log(err);
          setMessage("Something went wrong please try again");
          setLoader(false);
          setAlert(true);
          setTimeout(() => {
            setAlert(false);
          }, 3500);
        });
    }
  }

  return (
    <main className="global__report">
      <h1>Select a Site and Date to see Global Payment report</h1>
      {alert ? (
        <Alert
          severity="error"
          sx={{
            width: "80vw",
            left: "10vw",
            position: "absolute",
            top: "35vh",
            zIndex: "10",
          }}
        >
          <AlertTitle>Error</AlertTitle>
          {message}
        </Alert>
      ) : (
        <></>
      )}

      <section className="report__row"><select onChange={(e) => setSite(e.target.value)}>
        <option>--Select Site--</option>
        {siteList.map(function (d, i) {
          return <option>{d.name}</option>;
        })}
      </select>
        <button onClick={() => submit("yesterday")}>Yesterday</button>
        <button onClick={() => submit("today")}>Today</button>
        <button onClick={() => submit("lastWeek")}>Last Week</button>
        <button onClick={() => submit("thisWeek")}>This Week</button>
        <button onClick={() => submit("lastMonth")}>Last Month</button>
        <button onClick={() => submit("thisMonth")}>This Month</button>
        <button onClick={() => setShowDateRange(!showDateRange)}>Date Range</button>
      </section>
      {showDateRange ?
        <section className="report__row">
          <input type="date" onChange={(e) => setDate(e.target.value)} />
          <input type="date" onChange={(e) => setEndDate(e.target.value)} />
          <SearchIcon fontSize="large" color="primary" onClick={() => submit("dateRange")} />
        </section>
        : null
      }
      {loader ? (
        <div className="loader">
          <Loader />
        </div>
      ) : (
        <></>
      )}
      {salesInfo ? (
        <div className="info">
          <div className="info__card">
            <div className="card__till">
              <h2>EposNOW</h2>
              <h3>£{eposNow}</h3>
            </div>
            <div className="info__card__icon">
              <KeyboardArrowDownIcon
                color="primary"
                fontSize="large"
                sx={{ cursor: "pointer" }}
                onClick={() => setInfoExpand(!infoExpand)}
              />
            </div>
          </div>
          {infoExpand ? (
            <section className="info__expand">
              <table>
                <thead>
                  <tr>
                    <th>Till</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {split.map(function (d, i) {
                    return (
                      <tr>
                        <th>{d.name}</th>
                        <th>£{d.amount}</th>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </section>
          ) : (
            <></>
          )}
          {/* <button onClick={() => exportExcel()}>Export to CSV</button> */}
        </div>
      ) : (
        <></>
      )}
    </main>
  );
}
