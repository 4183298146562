import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import MiscModal from "./MiscModal";
import moment from "moment";
import PendingIcon from "@mui/icons-material/Pending";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

export default function IndvMiscItem({
    item,
    handleItemUpdates,
    updatedItems,
    handleRemove,
}) {
    //Input actuals
    const [actualProduct, setActualProduct] = useState("");
    const [actualQty, setActualQty] = useState("");
    //Modal
    const [openMiscModal, setOpenMiscModal] = useState(false);
    const handleOpenMiscModal = () => setOpenMiscModal(true);
    const handleCloseMiscModal = () => setOpenMiscModal(false);
    //Reload
    const [rel, setRel] = useState(false);

    //If actuals set then populate on table
    useEffect(() => {
        setActualProduct("");
        setActualQty("");
        for (let u = 0; u < updatedItems.length; u++) {
            if (updatedItems[u].mpId === item.mpId) {
                setActualQty(updatedItems[u].actualQty);
                setActualProduct(updatedItems[u].name);
            }
        }
    }, [rel, updatedItems]);

    //Reload
    const reload = () => {
        setRel(!rel);
    };

    return (
        <tr>
            <td>{item.name}</td>
            <td>£{item.price}</td>
            <td>{item.ingestQty}</td>
            <td>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenMiscModal}
                >
                    Open Search
                </Button>
            </td>
            <td>
                {actualProduct ? (
                    actualProduct
                ) : (
                    <PendingIcon fontSize="large" />
                )}
            </td>
            <td>{actualQty ? actualQty : <PendingIcon fontSize="large" />}</td>
            <td>{moment(item.transactionTime).format("DD-MM-YYYY - HH:mm")}</td>
            <td>
                {actualProduct ? (
                    <Button
                        onClick={() => {
                            handleRemove(item.mpId);
                            reload();
                        }}
                    >
                        <RestartAltIcon color="error" />
                    </Button>
                ) : (
                    <p>-</p>
                )}
            </td>

            {/* MODAL */}
            <Modal
                open={openMiscModal}
                aria-labelledby="Misc Item Modal"
                aria-describedby="Misc Item Modal"
            >
                <MiscModal
                    handleClose={handleCloseMiscModal}
                    item={item}
                    handleItemUpdates={handleItemUpdates}
                />
            </Modal>
        </tr>
    );
}
