import { useEffect, useState } from "react";
import { Button, Alert } from "@mui/material";
import IndvVal from "./IndvVal";

export default function ConfirmVals(props) {
  const [shifts, setShifts] = useState([]);
  //alerts
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  useEffect(() => {
    setShifts(props.groupedShifts);
  }, []);

  const handleDiff = (value, index) => {
    let changedShifts = shifts
    changedShifts[index].actual = value
    changedShifts[index].difference = parseFloat(value) - parseFloat(shifts[index].value)
    setShifts(changedShifts)
  }

  const handleNext = () => {
    let completed = true
    for (let i = 0; i < shifts.length; i++){
        if (!shifts[i].actual || shifts[i].actual === ""){
            completed = false
        }
    }
    if (!completed){
        setMessage("Please fill in all the actual values")
        setSeverity("warning")
        setShowAlert(true)
        setTimeout(() => {
            setShowAlert(false)
        },2500)
    } else{
        props.handleValues(shifts)
    }
  }

  return (
    <section className="subway_section">
      <h4>Confirm the values on each bag</h4>
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Expected</th>
            <th>Actual</th>
          </tr>
        </thead>
        <tbody>
          {shifts && shifts.length > 0 ? (
            shifts.map((shift, index) => {
              return (
                <IndvVal data={shift} index={index} handleDiff={handleDiff}/>
              );
            })
          ) : (
            <tr>
              <td colSpan={4}>No shifts to show</td>
            </tr>
          )}
        </tbody>
      </table>
      {showAlert ? (
        <Alert severity={severity} sx={{ width: "60%", margin: "1% auto" }}>
          {message}
        </Alert>
      ) : (
        <Button variant="outlined" color="success" onClick={handleNext}>
          Next Step
        </Button>
      )}
    </section>
  );
}
