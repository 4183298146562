import {useEffect, useState} from 'react';
import './modals.scss';

export default function Transaction(props){
    const [epos, setEpos] = useState([])
    const [prism, setPrism] = useState([])
    const [details, setDetails] = useState([])

    useEffect(() => {
        setEpos(props.epos)
        setPrism(props.prism)
        setDetails(props.details)
    },[props])
    
    return(
        <section className="transaction__box">
            <div className="transaction__row">
                <div className="row__column">
                    <h4>Discrepancy Value</h4>
                    <p>£{parseFloat(details.cost).toFixed(2)}</p>
                </div>
                <div className="row__column">
                    <h4>Date</h4>
                    <p>{new Date(details.transactionDate).toLocaleDateString()}</p>
                </div>
                <div className="row__column">
                    <h4>Reason</h4>
                    <p>{details.reason}</p>
                </div>
                <div className="row__column">
                    <h4>Submitted By</h4>
                    <p>{details.user}</p>
                </div>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Staff</th>
                        <th>Price</th>
                        <th>System</th>
                    </tr>
                </thead>
                <tbody>
                    {epos.length > 0 ? (
                        epos.map(function(d,i){
                            return(
                                <tr>
                                    <th>{d.staffId}</th>
                                    <th>{d.unitPrice}</th>
                                    <th>EposNow</th>
                                </tr>
                            )
                        })
                    ) : prism.length > 0 ?(
                        prism.map(function(d,i){
                            return(
                                <tr>
                                    <th>{d.pos}</th>
                                    <th>{d.fuelValue}</th>
                                    <th>Prism</th>
                                </tr>
                            )
                        })
                    ) : null}
                </tbody>
            </table>
        </section>
    )
}