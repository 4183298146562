import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
  maxHeight: "70vh",
  overflowY: "scroll",
  "@media(max-width: 690px)": {
    width: "90vw",
    maxHeight: "90vh",
    p: 1,
  },
};

export default function Personal({ details, setShowDetails }) {
  const modStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    flexWrap: "wrap",
  };

  return (
    <Box sx={style} className="all_modal">
      <Typography id="notes_header" variant="h6" component="h1">
        PERSONAL DETAILS
      </Typography>
      <Typography variant="body1" component="h4" sx={modStyle}>
        Phone Number: {details.phone}
      </Typography>
      <Typography variant="body1" component="h4" sx={modStyle}>
        Address: {details.addressLine1}, {details.addressLine2}
      </Typography>
      <Typography variant="body1" component="h4" sx={modStyle}>
        Postcode: {details.postcode}
      </Typography>
      <Typography variant="body1" component="h4" sx={modStyle}>
        Email: {details.email}
      </Typography>
      <Button
        color="error"
        variant="contained"
        onClick={() => setShowDetails(false)}
      >
        close
      </Button>
    </Box>
  );
}
