//when adding a new item please remind to add it to the mobile version too

import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./styles/navbar.scss";
import Logo from "../images/logo.png";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import LogoutIcon from "@mui/icons-material/Logout";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import ReportIcon from "@mui/icons-material/Report";
import CodeIcon from "@mui/icons-material/Code";
import VapingRoomsIcon from "@mui/icons-material/VapingRooms";
import StorefrontIcon from "@mui/icons-material/Storefront";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import axios from "axios";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import VideoCameraFrontIcon from "@mui/icons-material/VideoCameraFront";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';

export default function NavBar(props) {
    const navigate = useNavigate();
    const [role, setRole] = useState("");
    const [user, setUser] = useState("");
    const [isMobile, setIsMobile] = useState(false);
    const [requests, setRequests] = useState(0);
    const [poAuth, setPoAuth] = useState(false);

    useEffect(() => {
        let userD = localStorage.getItem("userDetails");
        let user = JSON.parse(userD);
        setUser(user.user.user);
        setPoAuth(user.user.poAdmin);
        setRole(user.user.role);
        let getToken = localStorage.getItem("auth-token");

        if (getToken === "" || getToken === null || getToken === undefined) {
            Logout();
        }

        if (window.innerWidth < 690) {
            setIsMobile(true);
        }

        // if user has access to holidays checks if there is pending requests
        axios
            .post(
                "https://hr-be.racetrackpitstop.co.uk/holiday/admin/pending/new",
                { user: user.user.user },
                { headers: { "x-auth-token": getToken } }
            )
            .then((res) => {
                if (res.data.length > 0) {
                    setRequests(res.data.length);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [window.innerWidth]);

    const Logout = () => {
        localStorage.setItem("auth-token", "");
        localStorage.setItem("role", "");
        localStorage.setItem("userDetails", "");
        localStorage.removeItem("alert-shown");
        navigate("/");
    };
    return (
        <nav id="navbar">
            <img
                src={Logo}
                alt="logo"
                onClick={() => {
                    navigate("/home");
                }}
            />
            <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                    <React.Fragment>
                        <MenuOpenIcon
                            {...bindTrigger(popupState)}
                            fontSize="large"
                        />
                        <Menu {...bindMenu(popupState)}>
                            {role === "Developer" || user === "guna@ghsl.uk" ? (
                                <MenuItem
                                    onClick={() =>
                                        navigate("/warehouse/v4u-disposables")
                                    }
                                >
                                    <span className="mobile__icons">
                                        <VapingRoomsIcon />
                                        V4U
                                    </span>
                                </MenuItem>
                            ) : null}
                            {role === "Developer" ||
                            user === "hrithik@ghsl.uk" ? (
                                <MenuItem
                                    onClick={() => navigate("/epos-users")}
                                >
                                    <span className="mobile__icons">
                                        <PointOfSaleIcon />
                                        EPOS Users
                                    </span>
                                </MenuItem>
                            ) : null}

                            {role === "Developer" ||
                            user === "vikas@ghsl.uk" ? (
                                <MenuItem
                                    onClick={() => navigate("/food-to-go")}
                                >
                                    <span className="mobile__icons">
                                        <FastfoodIcon />
                                        Hoagies
                                    </span>
                                </MenuItem>
                            ) : null}
                            {role === "Developer" ||
                            user === "vikas@ghsl.uk" ||
                            user === "shamly@ghsl.uk" ||
                            user === "hrithik@ghsl.uk" ||
                            user === "guna@ghsl.uk" ? (
                                <MenuItem
                                    onClick={() =>
                                        navigate("/staff-management")
                                    }
                                >
                                    <span className="mobile__icons">
                                        <Diversity3Icon />
                                        Staff Management
                                        {requests > 0 ? (
                                            <span id="hols">{requests}</span>
                                        ) : (
                                            <></>
                                        )}
                                    </span>
                                </MenuItem>
                            ) : null}
                            {role === "Developer" ? (
                                <MenuItem onClick={() => navigate("/dev-zone")}>
                                    <span className="mobile__icons">
                                        <CodeIcon />
                                        Dev Zone
                                    </span>
                                </MenuItem>
                            ) : (
                                <></>
                            )}

                            {role === "HR" ? (
                                <MenuItem
                                    onClick={() => navigate("/dev-zone/cctv")}
                                >
                                    <span className="mobile__icons">
                                        <VideoCameraFrontIcon />
                                        CCTV
                                    </span>
                                </MenuItem>
                            ) : (
                                <></>
                            )}

                            {/* {role === "Developer" ||
                                role === "HR" ||
                                user === "shamly@ghsl.uk" ||
                                user === "diane.davies@racetrackpitstop.co.uk" ? (
                                    <MenuItem onClick={() => navigate("/hr-area")}>
                                    <span className="mobile__icons">
                                        <BadgeIcon />
                                        HR
                                    </span>
                                    </MenuItem>
                                ) : null} */}
                            <MenuItem
                                onClick={() =>
                                    navigate("/purchase-order-admin")
                                }
                                disabled={!poAuth}
                            >
                                <span className="mobile__icons">
                                    <ShoppingBasketIcon /> PO Admin{" "}
                                    {poAuth ? null : (
                                        <span
                                            style={{
                                                fontSize: "0.8rem",
                                                color: "red",
                                            }}
                                        >
                                            (No Access)
                                        </span>
                                    )}
                                </span>
                            </MenuItem>
                            {role === "Developer" || role === "Manager" ? (
                                <MenuItem
                                    onClick={() => navigate("/managerZone")}
                                >
                                    <span className="mobile__icons">
                                        <StorefrontIcon />
                                        Manager Zone
                                    </span>
                                </MenuItem>
                            ) : null}
                            <MenuItem
                                onClick={() => navigate("/create-alerts")}
                            >
                                <span className="mobile__icons">
                                    <AddAlertIcon />
                                    Add Store Alert
                                </span>
                            </MenuItem>

                            <MenuItem onClick={() => navigate("/cctv-status")}>
                                <span className="mobile__icons">
                                    <QrCodeScannerIcon />
                                    CCTV Status Change
                                </span>
                            </MenuItem>
                            <MenuItem
                                onClick={() => navigate("/dev-zone/cctv")}
                            >
                                <span className="mobile__icons">
                                    <VideoCameraFrontIcon />
                                    CCTV
                                </span>
                            </MenuItem>
                            <MenuItem onClick={() => navigate("/reports")}>
                                <span className="mobile__icons">
                                    <FlagCircleIcon />
                                    HO Reports
                                </span>
                            </MenuItem>
                            <MenuItem
                                onClick={() => navigate("/discrepancy-reports")}
                            >
                                <span className="mobile__icons">
                                    <ReportIcon />
                                    Discrepancies
                                </span>
                            </MenuItem>
                            <MenuItem onClick={() => navigate("/fuel")}>
                                <span className="mobile__icons">
                                    <LocalGasStationIcon />
                                    Fuel
                                </span>
                            </MenuItem>
                            <MenuItem onClick={() => navigate("/managerZone/pos-explorer")}>
                                <span className="mobile__icons">
                                    <TravelExploreIcon />
                                    POS Explorer
                                </span>
                            </MenuItem>
                            <MenuItem
                                onClick={() => navigate("/refund-reports")}
                            >
                                <span className="mobile__icons">
                                    <CurrencyExchangeIcon />
                                    Refunds & Discounts
                                </span>
                            </MenuItem>
                            <MenuItem
                                onClick={() => navigate("/business-dashboard")}
                            >
                                <span className="mobile__icons">
                                    <SpaceDashboardIcon />
                                    Business Dashboard
                                </span>
                            </MenuItem>

                            <MenuItem onClick={() => navigate("/storezone")}>
                                <span className="mobile__icons">
                                    <CoPresentIcon />
                                    Store Zone
                                </span>
                            </MenuItem>
                            <MenuItem onClick={() => Logout()}>
                                <span className="mobile__icons">
                                    <LogoutIcon />
                                    Logout
                                </span>
                            </MenuItem>
                        </Menu>
                    </React.Fragment>
                )}
            </PopupState>
        </nav>
    );
}
