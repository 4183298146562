import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";
import "./styles/invoices.scss";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
  "@media(max-width: 600px)": {
    width: "90vw",
    maxHeight: "80vh",
    overflowY: "scroll",
  },
  "@media(min-width: 750px) and (max-width: 900px)": {
    width: "80vw",
    maxHeight: "60vh",
  },
};

export default function ModalClaims(props) {
  const [claims, setClaims] = useState(props.claims);

  useEffect(() => {
    // console.log(props.claims)
  }, [claims]);

  const handleReason = (reason, index) => {
    const copyArray = [...claims];
    copyArray[index] = { ...copyArray[index], reason: reason };
    let array = claims;
    array = copyArray;

    setClaims(array);
    console.log(copyArray);
  };

  const handleStock = (stock, index) => {
    const copyArray = [...claims];
    copyArray[index] = { ...copyArray[index], inStock: parseInt(stock) };
    copyArray[index] = { ...copyArray[index], toClaim: copyArray[index].toClaim - parseInt(stock)};
    let array = claims;
    array = copyArray;

    setClaims(array);
    console.log(copyArray);
  };

  const handleImage = (image, index) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(image);
    fileReader.onload = () => {
        const copyArray = [...claims];
        copyArray[index] = { ...copyArray[index], image:  fileReader.result};
        let array = claims;
        array = copyArray;

        setClaims(array);
        console.log(copyArray);
    };

  }

  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Items to claim
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <div className="claims__modal__all">
        <table className="claims__modal">
          <thead>
            <td>Product</td>
            <td>Reason</td>
            <td>Pack Qty</td>
            <td>In Stock</td>
            <td>Image</td>
          </thead>
          <tbody>
            {claims && claims.length > 0 ? (
              claims.map(function (d, i) {
                return (
                  <tr>
                    <td>{d.name}</td>
                    <td>
                      <select onChange={(e) => handleReason(e.target.value, i)}>
                        <option>--select--</option>
                        <option>Not arrived</option>
                        <option>Damaged</option>
                        <option>Short dated</option>
                        <option>Out Of Date</option>
                      </select>
                    </td>
                    <td>{d.packageQty}</td>
                    {d.reason === "Not arrived" ||
                    d.reason === "Short dated" ||
                    d.reason === "Out Of Date" ? (
                      <td>0</td>
                    ) : d.reason === "Damaged" ? (
                      <td>
                        <input
                          type="number"
                          placeholder="amount to put in stock"
                          pattern="[0-9]*"
                          onChange={(e) => handleStock(e.target.value, i)}
                        />
                      </td>
                    ) : (
                      <td>Select a reason</td>
                    )}
                    {d.reason === "Damaged" ? (
                      <td>
                        <input type="file"
                        accepts="image/*"
                        onChange={(e) => handleImage(e.target.files[0], i)} />
                      </td>
                    ) : (
                      <td>
                        <ImageNotSupportedIcon sx={{ color: "#262626" }} />
                      </td>
                    )}
                  </tr>
                );
              })
            ) : (
              <></>
            )}
          </tbody>
        </table>
        <br />
        <Button variant="contained" color="success" onClick={() => props.submitClaims(claims)}>Submit</Button>
        </div>
      </Typography>
    </Box>
  );
}
