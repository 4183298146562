import { useState, useEffect } from "react";
import "./investigation.scss";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton } from "@mui/material";
import axios from "axios";
import InvesList from "./InvesList";
import InvesDetails from "./InvesDetails";
import Button from "@mui/material/Button";
import moment from "moment";
import Tick from "../../animations/Tick";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import DiscrepModal from "./modal/DiscrepModal";
import HrDiscrepModal from "./modal/HrDiscrepModal";
import { getAllStores } from "../../utils/Api";
import { decryptData } from "../../utils/cryptoUtils";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40vw",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
};

export default function Investigation() {
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState(false);
  const [siteList, setSiteList] = useState([]);
  const [reload, setReload] = useState(false);
  const [user, setUser] = useState("");
  const [role, setRole] = useState("");
  const [site, setSite] = useState("");
  const [month, setMonth] = useState("");
  const [investigationData, setInvestigationData] = useState([]);
  const [showInvestigations, setShowInvestigations] = useState(false);
  const [discrepancy, setDiscrepancy] = useState({});
  const [submitAlert, setSubmitAlert] = useState(false);
  const [severity, setSeverity] = useState("");
  const [showTick, setShowTick] = useState(false);
  const [discreps, setDiscreps] = useState([]);
  const [finalised, setFinalised] = useState(false);
  const url = "https://nitropos-backend.nitrosolutions.co.uk";
  // const url = "http://10.0.0.157:4022";
  const [token, setToken] = useState("")


  //modal for confirm finalise
  const [confirmOpen, setConfirmOpen] = useState(false);
  const handleConfirmOpen = () => setConfirmOpen(true);
  const handleConfirmClose = () => setConfirmOpen(false);

  //modal if there is info to check
  const [discrepOpen, setDiscrepOpen] = useState(false);
  const handleDiscrepOpen = () => setDiscrepOpen(true);
  const handleDiscrepClose = () => setDiscrepOpen(false);

  //modal if there is HR Discreps open
  const [hrDiscreoOpen, setHRDiscrepOpen] = useState(false);
  const handleHrDiscrepOpen = () => setHRDiscrepOpen(true);
  const handleHrDiscrepClose = () => setHRDiscrepOpen(false);

  useEffect(() => {
    let userD = localStorage.getItem("userDetails");
    let user = JSON.parse(userD);
    setUser(user.user.user);
    setRole(user.user.role);
    let getToken = localStorage.getItem("auth-token");
    setToken(getToken)


    getAllStores()
      .then((sites) => {
        setSiteList(sites);
      })
      .catch((err) => {
        console.log(`Could not get all sites: ${err}`);
      });
  }, [reload]);

  const handleSearch = () => {
    setInvestigationData([])
    setFinalised(false);
    let data = {
      date: month,
      site: site,
      token: token,
    };
    axios
      .post(`${url}/discrepancies/getDiscrepancies`, data)
      .then((res) => {
        if (res.data.discrepancies.length === 0) {
          setMessage(`No Investigations pending on ${month}`);
          setAlert(true);
          setTimeout(() => {
            setAlert(false);
          }, 3500);
        } else {
          if (res.data.finalised) {
            setFinalised(true);
          }
          for (let d = 0; d<res.data.discrepancies.length; d++) {
            //checks if it's epos id or staff id
            if (String(res.data.discrepancies[d].eposStaffId).length < 5) {
              setInvestigationData((investigationData) => [
                ...investigationData,
                {
                  eposStaffId: res.data.discrepancies[d].eposStaffId,
                  mainCompanyId: res.data.discrepancies[d].mainCompanyId,
                  name: decryptData(
                    res.data.discrepancies[d].name,
                    process.env.REACT_APP_EMP_KEY
                  ),
                  sum: res.data.discrepancies[d].sum,
                },
              ]);
            } else {
              setInvestigationData((investigationData) => [
                ...investigationData,
                res.data.discrepancies[d],
              ]);
            }
          }
          setShowInvestigations(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDiscrepancy = (discrepancy) => {
    setDiscrepancy(discrepancy);
  };

  const rel = () => {
    setReload(!reload);
  };

  const handleFinalise = () => {
    document.getElementById("finalise_btn").style.display = "none";
    let currentMonth = moment().format("M");
    let selectedMonth = moment(month).format("M");
    //checks that the month is not the current month for finalising
    if (currentMonth === selectedMonth) {
      setMessage("You cannot finalise the current month");
      setSeverity("warning");
      setSubmitAlert(true);
      setTimeout(() => {
        setMessage("");
        setSeverity("");
        setSubmitAlert(false);
        document.getElementById("finalise_btn").style.display = "flex";
      }, 3500);
    } else {
      let data = {
        month: month,
        site: site,
      };
      axios
        .post(`${url}/hr/checkMonthFinalise`, data)
        .then((res) => {
          //if all investigations and fuel has been completed
          if (res.data.complete === true) {
            document.getElementById("finalise_btn").style.display = "flex";
            handleConfirmOpen();
          } else if (
            res.data.complete === false &&
            res.data.discrepancies === 0
          ) {
            //if there is no HR discrepancies
            setDiscreps(res.data);
            document.getElementById("finalise_btn").style.display = "flex";
            handleDiscrepOpen();
          } else if (
            res.data.complete === false &&
            res.data.discrepancies != 0
          ) {
            //if there is HR discrepancies
            setDiscreps(res.data.discrepDetails);
            document.getElementById("finalise_btn").style.display = "flex";
            handleHrDiscrepOpen();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  //if day can be finalised on confim
  const confirmFinalise = () => {
    document.getElementById("confirm_finalise").style.display = "none";
    let data = {
      site: site,
      month: month,
      user: user,
      discrepancies: [],
    };
    axios
      .post(`${url}/hr/finaliseMonth`, data)
      .then(async (res) => {
        console.log(res.data);
        setShowTick(true);
        await handleConfirmClose();
        setTimeout(() => {
          setShowTick(false);
          document.getElementById("confirm_finalise").style.display = "flex";
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
        setSeverity("warning");
        setMessage("Something went wrong, please try again");
        setSubmitAlert(true);
        setTimeout(() => {
          setSubmitAlert(false);
          setSeverity("");
          setMessage("");
          document.getElementById("confirm_finalise").style.display = "flex";
        }, 3500);
      });
  };

  return (
    <main className="investigation__main">
      <h1>Discrepancy Report for Payroll</h1>
      {alert ? (
        <Alert
          severity="error"
          sx={{
            width: "80vw",
            left: "15vw",
            position: "absolute",
            top: "22vh",
            zIndex: "10",
          }}
        >
          <AlertTitle>Error</AlertTitle>
          {message}
        </Alert>
      ) : (
        <></>
      )}
      <section className="investigation__row">
        <select onChange={(e) => setSite(e.target.value)}>
          <option>--Please Select a Site--</option>
          {siteList.map(function (d, i) {
            return <option key={i}>{d.siteName}</option>;
          })}
        </select>
        <input
          type="month"
          min="2023-01"
          onChange={(e) => setMonth(e.target.value)}
        />
        <IconButton>
          <SearchIcon
            color="primary"
            fontSize="large"
            onClick={() => handleSearch()}
          />
        </IconButton>
      </section>
      {showInvestigations ? (
        <section className="show_investigations_main">
          <section className="show__investigations">
            <InvesList
              rel={rel}
              handleDiscrepancy={handleDiscrepancy}
              investigationData={investigationData}
            />
            <InvesDetails rel={rel} discrepancy={discrepancy} month={month} />
          </section>
          {finalised ? (
            <h1 style={{ color: "#F29422" }}>This month has been finalised</h1>
          ) : (
            <Button
              variant="contained"
              color="success"
              id="finalise_btn"
              onClick={() => handleFinalise()}
            >
              Finalise
            </Button>
          )}
          {submitAlert ? <Alert severity={severity}>{message}</Alert> : <></>}
          <Modal
            open={confirmOpen}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                This month can be finalised, are you sure?
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <div className="confirm_finalise_modal" id="confirm_finalise">
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleConfirmClose()}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => confirmFinalise()}
                  >
                    Confirm
                  </Button>
                </div>
                {showTick ? <Tick /> : <></>}
                {submitAlert ? (
                  <Alert severity={severity}>{message}</Alert>
                ) : (
                  <></>
                )}
              </Typography>
            </Box>
          </Modal>
          <Modal
            open={discrepOpen}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <DiscrepModal
              close={handleDiscrepClose}
              data={discreps}
              rel={rel}
            />
          </Modal>

          <Modal
            open={hrDiscreoOpen}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <HrDiscrepModal
              close={handleHrDiscrepClose}
              data={discreps}
              rel={rel}
              site={site}
              month={month}
            />
          </Modal>
        </section>
      ) : (
        <></>
      )}
    </main>
  );
}
