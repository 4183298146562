import { useState } from "react";
import axios from "axios";
//MUI
import Box from "@mui/material/Box";
import { Button, Alert } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
//Utils
import { numberValidation } from "../../utils/Validation";

export default function AddExtraItemsModal({
    setOpenExtraItems,
    handleAddExtraProducts,
    supplierName,
    existingAdditionalItems,
}) {
    const url = process.env.REACT_APP_DEPPLOYED;
    const [additionalItems, setAdditionalItems] = useState([]);
    const [multipleProducts, setMultipleProducts] = useState([]);
    const [searchedBarcode, setSearchedBarcode] = useState(null);
    //Alerts
    const [alert, setAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState(false);
    const [severity, setSeverity] = useState("");
    const [disable, setDisable] = useState(false);

    const handleAlert = (message, severity, close = false, duration = 4000) => {
        setAlertMessage(message);
        setSeverity(severity);
        setAlert(true);
        setTimeout(() => {
            setAlert(false);
            setAlertMessage("");
            setSeverity("");
            setDisable(false);
            if (close) {
                setOpenExtraItems(false);
            }
        }, duration);
    };

    const handleItemChecks = async () => {
        //Clear multiple items table & temp disable add button
        setDisable(true);
        setMultipleProducts([]);

        //Product lookup
        await axios
            .post(`${url}/edn/products`, {
                barcode: searchedBarcode,
                supplierName: supplierName,
            })
            .then((res) => {
                let result = res.data;
                let resultLength = result.length;
                
                // 0 item - Warning message
                // 1 item - Will auto add to additional items array
                // 1 > & < =10 - Will Display options in table an allow for correct one to be added to additional items array
                // 10 > - Warning message advising user to enter specific barcode
                if (resultLength === 0) {
                    handleAlert("No Products Found", "warning");
                } else if (resultLength === 1) {
                    handleAddItem(result[0]);
                } else if (resultLength > 1 && resultLength <= 10) {
                    setMultipleProducts(result);
                    handleAlert(
                        "Multiple Products Found - Please select the correct one",
                        "warning"
                    );
                } else if (resultLength > 10) {
                    handleAlert(
                        "Too Many Products Found - Use accurate barcode",
                        "warning"
                    );
                }
            })
            .catch((err) => {
                console.log(err);
                handleAlert(err?.message, "error");
                setDisable(false);
            });
        //Clear the search input
        setSearchedBarcode("");
    };

    const handleRemoveItem = (barcode) => {
        let additionalItemsCopy = additionalItems;
        const productIndex = additionalItems.findIndex((item) => {
            return item.Barcode == barcode;
        });
        additionalItemsCopy.splice(productIndex, 1);
        setAdditionalItems([...additionalItemsCopy]);
    };

    const handleAddItem = (product) => {
        //Add selected product to main additional items array if not already in it
        const productIndex = additionalItems.findIndex((item) => {
            return item.Barcode == product.barcode;
        });
        const productIndexTwo = existingAdditionalItems.findIndex((item) => {
            return item.Barcode == product.barcode;
        });

        if (productIndex > -1 || productIndexTwo > -1) {
            handleAlert("Already selected", "warning");
        } else {
            setAdditionalItems([
                ...additionalItems,
                {
                    Name: product.productName,
                    Barcode: product.barcode,
                    Sku: product.orderCode,
                    qtyOrdered: 0,
                    caseQty: 0,
                    packQty: 1,
                    qtyReceived: 0,
                    image: "",
                },
            ]);
            handleAlert("Product Added", "success");
        }
        //Clear multiple products array
        setMultipleProducts([]);
    };

    return (
        <Box className="add-extra-items-modal">
            <h1>Add Extra Items</h1>

            <section className="search-items">
                <input
                    type="number"
                    placeholder="Search item by barcode"
                    onChange={(e) => {
                        setSearchedBarcode(e.target.value);
                    }}
                    onKeyDown={(e) => numberValidation(e)}
                    min="0"
                    value={searchedBarcode}
                />
                <Button
                    variant="contained"
                    onClick={handleItemChecks}
                    disabled={
                        disable ||
                        searchedBarcode === null ||
                        searchedBarcode === ""
                    }
                >
                    <AddIcon />
                </Button>
            </section>
            <section className="alert-section">
                {alert ? (
                    <Alert severity={severity}>{alertMessage}</Alert>
                ) : null}
            </section>
            <section className="product-tables">
                {multipleProducts.length > 0 ? (
                    <section className="multiple-items-table-container">
                        <p>Multiple Items found, please add the correct one.</p>
                        <table className="multiple-items-table">
                            <thead>
                                <tr>
                                    <th>Product</th>
                                    <th>Add</th>
                                </tr>
                            </thead>
                            <tbody>
                                {multipleProducts.map((product, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{product.productName}</td>
                                            <td>
                                                <Button
                                                    variant="contained"
                                                    onClick={(e) => {
                                                        handleAddItem(product);
                                                    }}
                                                >
                                                    <AddIcon />
                                                </Button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </section>
                ) : null}
                {additionalItems.length > 0 ? (
                    <section className="additional-items-table-container">
                        <p>The table below is the items you will be adding.</p>
                        <table className="additional-items-table">
                            <thead>
                                <tr>
                                    <th>Product</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {additionalItems.map((product, i) => {
                                    
                                    return (
                                        <tr key={i}>
                                            <td>{product.Name}</td>
                                            <td>
                                                <Button
                                                    variant="contained"
                                                    color="error"
                                                    onClick={(e) =>
                                                        handleRemoveItem(
                                                            product.Barcode
                                                        )
                                                    }
                                                >
                                                    <DeleteIcon />
                                                </Button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </section>
                ) : null}
            </section>
            <section className="btn-section">
                <Button
                    variant="contained"
                    disabled={disable || additionalItems.length === 0}
                    onClick={() => {
                        handleAddExtraProducts(additionalItems);
                    }}
                >
                    Add Items
                </Button>

                <Button
                    variant="contained"
                    onClick={() => setOpenExtraItems(false)}
                    color="error"
                >
                    Close
                </Button>
            </section>
        </Box>
    );
}
