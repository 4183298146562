import { useEffect, useState } from "react"

export default function HolidayRole(props) {

    const [checked, setChecked] = useState(false)

    useEffect(()=>{
        var check = false;
        if(props.userHols){
            let idx=props.userHols.findIndex((p)=>p==props.name);
            if(idx>-1){
                check = true
            }else{
                check=false
            }
        }
        
        if(!check){
            if(props.holidays){
                let idx=props.holidays.findIndex((p)=>p==props.name);
                if(idx>-1){
                    check = true
                }else{
                    check=false
                }
            }
        }
        setChecked(check)
    },[props.update])

    return (
        <>
            <input
                type="checkbox"
                name={props.name}
                value={props.name}
                onChange={(e) => props.handleHolidayRoles(e.target.value)}
                id={props.roleId}
                checked={checked}
            />
            <label for={props.name}>{props.name}</label>
        </>
    )
}