import logo from "../../../images/logo.png";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, Fragment } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import StorefrontIcon from "@mui/icons-material/Storefront";
import PeopleIcon from "@mui/icons-material/People";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import VideoCameraFrontIcon from "@mui/icons-material/VideoCameraFront";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import axios from "axios";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import DiscountIcon from '@mui/icons-material/Discount';

export default function DevNav(props) {
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [pendingAlerts, setPendingAlerts] = useState(0);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
        if (window.innerWidth < 690) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
        let getToken = localStorage.getItem("auth-token");
        const config = {
            headers: {
                "Content-Type": "application/json",
                "x-auth-token": getToken,
            },
        };

        axios
            .get(
                `${process.env.REACT_APP_API_URL}/onBoard/pending/managers`,
                config
            )
            .then((res) => {
                setPendingAlerts(res.data.length);
            })
            .catch((err) => {
                console.log(err);
            });

        if (getToken === "" || getToken === null || getToken === undefined) {
            Logout();
        }
    }, [window.innerWidth, windowWidth]);

    const Logout = () => {
        localStorage.setItem("userDetails", "");
        navigate("/");
    };

    return (
        <nav
            style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
            }}
        >
            <img
                src={logo}
                alt="NitroPOS logo"
                onClick={() => navigate("/dev-zone")}
            />
            <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                    <Fragment>
                        <MenuOpenIcon
                            {...bindTrigger(popupState)}
                            fontSize="large"
                        />
                        <Menu {...bindMenu(popupState)}>
                            <MenuItem
                                onClick={() => navigate("/dev-zone/cctv")}
                            >
                                <span className="mobile__icons">
                                    <VideoCameraFrontIcon />
                                    CCTV
                                </span>
                            </MenuItem>
                            <MenuItem onClick={() => navigate("/cctv-status")}>
                                <span className="mobile__icons">
                                    <QrCodeScannerIcon />
                                    CCTV Status Change
                                </span>
                            </MenuItem>
                            <MenuItem
                                onClick={() =>
                                    navigate("/dev-zone/failedTransactions")
                                }
                            >
                                <span className="mobile__icons">
                                    <PriorityHighIcon />
                                    Failed Transactions
                                </span>
                            </MenuItem>

                            <MenuItem onClick={() => navigate("/dev-zone/gs1")}>
                                <span className="mobile__icons">
                                    <PlaylistAddIcon />
                                    GS1 Barcodes
                                </span>
                            </MenuItem>

                            <MenuItem
                                onClick={() => navigate("/dev-zone/users")}
                            >
                                <span className="mobile__icons">
                                    <PeopleIcon />
                                    Users
                                </span>
                            </MenuItem>

                            <MenuItem onClick={() => navigate("/home")}>
                                <span className="mobile__icons">
                                    <AdminPanelSettingsIcon />
                                    Admin Area
                                </span>
                            </MenuItem>

                            <MenuItem onClick={() => navigate("/managerZone")}>
                                <span className="mobile__icons">
                                    <StorefrontIcon />
                                    Manager Zone
                                </span>
                            </MenuItem>
                            <MenuItem
                                onClick={() => navigate("/dev-zone/promotions")}
                            >
                                <span className="mobile__icons">
                                    <DiscountIcon />
                                    Booker Promotions
                                </span>
                            </MenuItem>

                            <MenuItem onClick={() => Logout()}>
                                <span className="mobile__icons">
                                    <LogoutIcon />
                                    Logout
                                </span>
                            </MenuItem>
                        </Menu>
                    </Fragment>
                )}
            </PopupState>
        </nav>
    );
}
