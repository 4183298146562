import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {useState} from 'react';
import '../investigation.scss';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40vw",
    maxHeight: "80vh",
    overflowY: "scroll",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
};
  

export default function DiscrepModal(props) {
    const [data, setData] = useState(props.data)

    return(
        <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          You cannot finalise this month as the following discrepancies are still open
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <div className="confirm_dicreps_modal" >
            <p>Admin investigation: <strong>{data.adminInvestigations}</strong></p>
            <p>Store Investigations: <strong>{data.storeInvestigations}</strong></p>
            <p>Shift to reconcile: <strong>{data.shiftsAwaiting}</strong></p>
            {data.fuelDates.length > 0 ? (
                <>
                <p>Fuel Dates:</p>
                <ul>
                {data.fuelDates.map(function(d,i){
                    <li>{new Date(d.date).toLocaleDateString()}</li>
                })}
                </ul>
                </>
            ):(
                <p>Fuel Dates: <strong>0</strong></p>
            )}
            <Button variant="outlined" color="error" onClick={() => props.close()}>Cancel</Button>
          </div>
        </Typography>
      </Box>
    )
}