import { Button, Alert } from "@mui/material";
// import IndvDiscrep from "./IndvDiscrep";
import SwapVerticalCircleIcon from '@mui/icons-material/SwapVerticalCircle';
import { useState, useEffect } from "react";
import IndvDiscrep from "./IndvDiscrep";

const alertStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
};

export default function UserDiscreps(props) {
  const discrepancies = props.discrepancies;
  const [showTable, setShowTable] = useState(false);


  const handleTable = () => {
    setShowTable(!showTable);
  };

  return (
    <section className="discrepancy">
      <div className="discrepancy_header">
        <h4>
          Discrepancies for <span>{props.name}</span>
        </h4>
        <h4>
          Total: <span>£{props.total}</span>
        </h4>
        <SwapVerticalCircleIcon color="primary" onClick={handleTable} sx={{cursor: "pointer"}}/>
      </div>
      {showTable ? (
        <table>
          <thead>
            <tr>
              <th>Reason</th>
              <th>Notes</th>
              <th>Total Amount</th>
              <th>Paid</th>
              <th>Owed</th>
              <th>This Month Payment</th>
              <th>Transaction Date</th>
            </tr>
          </thead>
          <tbody>
            {discrepancies.map((discrep) => {
              return (
                <IndvDiscrep
                  discrepancy={discrep}
                  month={props.month}
                />
              );
            })}
          </tbody>
        </table>
      ) : null}
    </section>
  );
}
