import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button } from "@mui/material";

export default function EposProductTableRow({
    product,
    bookerBarcode,
    handleModelAlert
}) {

    const eposUrl = `https://www.eposnowhq.com/products/${product.eposId}/edit`

    const copyToClip = (element) => {
        navigator.clipboard.writeText(element);
        handleModelAlert("COPIED TO CLIP BOARD", "success")
    };

    return (
        <tr>
            <td
                onClick={() => copyToClip(product.prodName)}
            >
                {product.prodName}
            </td>
            <td
                onClick={() => copyToClip(product.barcode)}
            >
                {product.barcode.includes(",") ? `${product.barcode.split(",").at(0)}...` : product.barcode}
            </td>
            <td
                onClick={() => copyToClip(product.orderCode)}
            >
                {product.orderCode}
            </td>
            <td
                onClick={() => copyToClip(product.catName)}
            >
                {product.catName == "" || product.catName == null ? "Top Level" : product.catName}
            </td>
            <td
                onClick={() => copyToClip(product.salePrice)}
            >
                {product.salePrice.includes("£") ? product.salePrice : `£${product.salePrice}`}
            </td>

            <td>
                <Button
                    variant="contained"
                    color="primary"
                    href={eposUrl}
                    target="_blank"
                    rel="noreferrer"
                >
                    Product 
                </Button>
            </td>
        </tr>
    )

}