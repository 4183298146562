import { useEffect, useState } from "react";
import { getEmployeesByCompany, getCompanies } from "../../utils/Api";
import TinyLoader from "../../animations/TinyLoader";
import { decryptData } from "../../utils/cryptoUtils";
import { Button, Alert } from "@mui/material";
import axios from "axios";
import Tick from "../../animations/Tick";

export default function AddEposUser() {
  const url = "https://hr-be.racetrackpitstop.co.uk";
  const [siteList, setSiteList] = useState([]);
  const [token, setToken] = useState("");
  const [selectedSites, setSelectedSites] = useState([]);
  const [staffNameList, setStaffNameList] = useState([]);
  const [staff, setStaff] = useState({});
  //alerts
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [showTick, setShowTick] = useState(false);
  const config = {
    headers: { "Content-Type": "application/json", "x-auth-token": token },
  };

  useEffect(() => {
    let getToken = localStorage.getItem("auth-token");
    setToken(getToken);
    getCompanies(getToken)
      .then((sites) => {
        let localSites = sites;
        // TODO: add also HO id to get off the list
        for (let s = 0; s < localSites.length; s++) {
          if (localSites[s].companyId === 1) {
            localSites.splice(s, 1);
          }
        }
        setSiteList(localSites);
      })
      .catch((err) => {
        console.log(`Could not get sites: ${err}`);
      });
  }, []);

  const handleSelectSite = async (siteId) => {
    setStaffNameList([]);
    setSelectedSites([]);
    setStaff({});
    if (siteId === "" || siteId === "--Select site--") {
      setMessage("Please select a site");
      setSeverity("warning");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 2500);
    } else {
      try {
        console.log(siteId);
        axios
          .get(`${url}/employee/epos/staff/site/${siteId}`, config)
          .then((res) => {
            let employees = res.data;
            for (let e = 0; e < employees.length; e++) {
              setStaffNameList((staffNameList) => [
                ...staffNameList,
                {
                  firstName: decryptData(
                    employees[e].firstName,
                    process.env.REACT_APP_EMP_KEY
                  ),
                  lastName: decryptData(
                    employees[e].lastName,
                    process.env.REACT_APP_EMP_KEY
                  ),
                  employeeDetailsId: employees[e].employeeDetailsId,
                },
              ]);
            }
          })
          .catch((err) => {
            console.log(`Could not get employees: ${err}`);
          });
      } catch (err) {
        console.log(`Could not fetch employees: ${err}`);
      }
    }
  };

  const handleStaffName = (staffId) => {
    if (staffId === "" || staffId === "--Select Staff Name--") {
      setMessage("Please select a member of staff");
      setSeverity("warning");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 2500);
    } else {
      for (let s = 0; s < staffNameList.length; s++) {
        if (staffId == staffNameList[s].employeeDetailsId) {
          setStaff({
            tillName: `${staffNameList[s].firstName} ${staffNameList[s].lastName}`,
            employeeDetailsId: parseInt(staffId),
            roleName: "",
          });
        }
      }
    }
  };

  const handleStaffSites = (id) => {
    let index = selectedSites.findIndex((s) => s == id);
    if (index < 0) {
      setSelectedSites((selectedSites) => [...selectedSites, parseInt(id)]);
    } else {
      selectedSites.splice(index, 1);
    }
  };

  const handleSubmit = () => {
    if (selectedSites.length < 1) {
      setMessage("You must select at least one site");
      setSeverity("warning");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 2500);
    } else {
      staff.sites = selectedSites;
      // console.log(staff);
      try {
        axios
          .post(`${url}/employee/epos/staff/add`, staff, config)
          .then((res) => {
            // console.log(res.data);
            setShowTick(true);
            setTimeout(() => {
              setShowTick(false);
              setStaff({});
              setStaffNameList([]);
              let inputs = document.querySelectorAll("select");
              for (let i = 0; i < inputs.length; i++) {
                inputs[i].selectedIndex = 0;
              }
            }, 3500);
          })
          .catch((err) => {
            console.log(`Could not set employee: ${err}`);
          });
      } catch (err) {
        setMessage(`Could not set employee: ${err}`);
        setSeverity("error");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 2500);
      }
    }
  };

  return (
    <main className="add_user">
      <h1>add user</h1>
      <section className="user_row">
        <label>Select Site:</label>
        <select
          onChange={(e) => handleSelectSite(e.target.value)}
          id="site_select"
        >
          <option>--Select site--</option>
          {siteList.map((site) => {
            return (
              <option value={site.eposId} key={site.eposId}>
                {site.siteName}
              </option>
            );
          })}
        </select>
      </section>
      <section className="user_row">
        {staffNameList.length < 1 ? (
          <TinyLoader />
        ) : (
          <>
            <label>Select staff member:</label>
            <select onChange={(e) => handleStaffName(e.target.value)}>
              <option>--Select Staff Name--</option>
              {staffNameList.map((staff) => {
                return (
                  <option
                    value={staff.employeeDetailsId}
                    key={staff.employeeDetailsId}
                  >
                    {staff.firstName} {staff.lastName}
                  </option>
                );
              })}
            </select>
          </>
        )}
      </section>
      {Object.keys(staff).length > 0 ? (
        <section className="sites_selection">
          <strong>
            Staff: <span>{staff.tillName}</span>
          </strong>
          <strong>Select sites for this staff member</strong>
          <table>
            <thead>
              <tr>
                <th>Site</th>
                <th>Select</th>
              </tr>
            </thead>
            <tbody>
              {siteList.map((sites) => {
                return (
                  <tr key={sites.eposId}>
                    <td>{sites.siteName}</td>
                    <td>
                      <input
                        type="checkbox"
                        value={sites.eposId}
                        onClick={(e) => handleStaffSites(e.target.value)}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </section>
      ) : null}
      {showAlert ? (
        <Alert severity={severity} sx={{ width: "60%", margin: "1% auto" }}>
          {message}
        </Alert>
      ) : selectedSites.length > 0 ? (
        <Button
          color="success"
          variant="outlined"
          onClick={handleSubmit}
          sx={{ margin: "1% auto" }}
        >
          Submit
        </Button>
      ) : showTick ? (
        <Tick />
      ) : null}
    </main>
  );
}
