import { useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import PrintIcon from "@mui/icons-material/Print";
import Button from "@mui/material/Button";
import ReactToPrint from "react-to-print";
import logo from "../../../images/rt-logo.png";
import moment from "moment";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60vw",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
    maxHeight: "70vh",
    overflowY: "scroll",
    "@media(max-width: 690px)": {
        width: "90vw",
        maxHeight: "90vh",
        p: 1,
    },
};

export default function Contract({ siteName, setShowContractModal, request }) {
    const componentRef = useRef();
    const myRefname = useRef(null);

    const handleClick = () => {
        myRefname.current.click();
    };

    const linkToPrint = () => {
        return (
            <Button
                variant="contained"
                color="warning"
                ref={myRefname}
                style={{ display: "none" }}
            >
                Print PDF
            </Button>
        );
    };

    return (
        <Box sx={style}>
            <section ref={componentRef}>
                <Typography>
                    <img src={logo} alt="logo" width="25%" />
                </Typography>
                <Typography id="modal-modal-title" variant="h6" component="h4">
                    No Means Of Payment Agreement
                </Typography>
                <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2, paddingLeft: "60px", paddingRight: "60px" }}
                >
                    <p>
                        I, <b>{request.name}</b>, have to exit the premises at{" "}
                        <b>{siteName}</b> without paying for fuel to the sum of{" "}
                        <b>£{request.amount}</b> which is{" "}
                        <b>{request.litres}</b> litres of {request.fuelType}, on
                        pump number: <b>{request.pump}</b> on{" "}
                        <b>
                            {moment(request.submissionDate).format(
                                "MMMM Do YYYY hh:mm a"
                            )}
                        </b>{" "}
                        as I have no means of payment.
                        <br />
                        <br />I <b>{request.name}</b> , agree to pay the balance
                        in within 72 hours (3 days) from the moment of
                        signature, and I am aware that if I do not adhere to
                        this agreement, the matter will be passed to the police
                        and reported as a theft.
                        <br />
                        <br />
                        If I have left an item to counter act the balance
                        remainining until I return to pay for the goods, I agree
                        that RaceTrack Pitstop take no responsibility for the
                        loss or damage to my item.
                        <br />
                        <br />I am aware that any of my personal information
                        provided will be passed to the police in case payment is
                        not made in 72 hours. I agree to RaceTrack Pitstop
                        storing and sharing my personal data with the Police and
                        for <b>GDPR</b> purposes I consent to this.
                    </p>
                    <Stack>
                        <label>Signed: </label>
                        <img
                            src={request.signatureImg}
                            alt="image of drivers signature"
                            width="20%"
                        />
                        <p>{request.name}</p>
                        <label>Staff Name:</label>
                        <p
                            style={{
                                fontFamily: "Schoolbell",
                                fontSize: "2rem",
                            }}
                        >
                            {request.staffName}
                        </p>
                    </Stack>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => setShowContractModal(false)}
                        >
                            close
                        </Button>
                        <Button
                            startIcon={<PrintIcon />}
                            color="warning"
                            variant="outlined"
                            onClick={() => handleClick()}
                        >
                            Print
                        </Button>
                        {/* //TODO */}
                        <ReactToPrint
                            documentTitle={`${request.name}_${siteName}-nmop`}
                            trigger={linkToPrint}
                            content={() => componentRef.current}
                        />
                    </div>
                </Typography>
            </section>
        </Box>
    );
}
