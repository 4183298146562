import './nitroLoader.scss'
import logo from './images/nitroLoader.webp'

export default function NitroLoader(){

    return(
        <section className="loader">
            <img src={logo} alt="loader logo" width="75%"/>
            <h6>
                SOLUTIONS
            </h6>
            <span></span>
        </section>
    )
}