import React from "react";
import { useState } from "react";
import "../../styles/tiktok.scss";
import axios from "axios";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";

const ReturnForm = () => {
    const [tiktokAccount, setTiktokAccount] = useState("");
    const [orderNumber, setOrderNumber] = useState("");
    const [trackingNumber, setTrackingNumber] = useState("");
    const [trackingStatus, setTrackingStatus] = useState("");
    const [pickListImage, setPickListImage] = useState("");
    const [refundApproved, setRefundApproved] = useState(false);
    const [refundDate, setRefundDate] = useState("");
    const [ticketNumber, setTicketNumber] = useState("");
    const [refundAmount, setRefundAmount] = useState("");
    const [itemReturned, setItemReturned] = useState(false);
    const [itemDamaged, setItemDamaged] = useState(false);
    //Alerts
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");

    const handleItemReturned = (e) => {
        setItemReturned(e.target.value === "yes");
    };

    // Submit Form
    const handleFormSubmit = (e) => {
        e.preventDefault();
        document.getElementById("submit-btn").style.display = "none";
        // Check all fields are filled.
        if (
            tiktokAccount === "Please select:" ||
            tiktokAccount === "" ||
            orderNumber === "" ||
            trackingNumber === "" ||
            trackingStatus === "Please select:" ||
            trackingStatus === "" ||
            pickListImage === "" ||
            ticketNumber === ""
        ) {
            setMessage("Please ensure all fields are complete.");
            setSeverity("warning");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
                setSeverity("");
                setMessage("");
                document.getElementById("submit-btn").style = `display: flex;
                text-align: center`;
            }, 3000);
        } else if (
            (refundApproved && refundDate === "") ||
            (refundApproved && refundAmount === "")
        ) {
            setMessage("Please ensure refund date and amount is input.");
            setSeverity("warning");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
                setSeverity("");
                setMessage("");
                document.getElementById("submit-btn").style = `display: flex;
                text-align: center`;
            }, 3000);
        } else {
            setTiktokAccount("");
            setOrderNumber("");
            setTrackingNumber("");
            setTrackingStatus("");
            setPickListImage("");
            setRefundApproved(false);
            setTicketNumber("");
            setItemReturned(false);
            setRefundDate("");
            setRefundAmount("");
            setItemDamaged(false);

            const img = new FileReader();
            img.readAsDataURL(pickListImage);
            img.onload = () => {
                let data = {
                    tiktokAccount: tiktokAccount,
                    orderNumber: orderNumber,
                    trackingNumber: trackingNumber,
                    trackingStatus: trackingStatus,
                    pickListImage: img.result,
                    refundApproved: refundApproved,
                    refundDate: refundDate,
                    refundAmount: refundAmount,
                    ticketNumber: ticketNumber,
                    ticketStatus: "Reimbursement Requested",
                    itemReturned: itemReturned,
                    itemDamaged: itemDamaged,
                };
                axios
                    .post(
                        "https://nitropos-backend.nitrosolutions.co.uk/edn/tiktok/submit",
                        {
                            data,
                        }
                    )
                    .then((res) => {
                        setMessage(
                            "Succesfully submitted. Status: " + res.status
                        );
                        setSeverity("success");
                        setShowAlert(true);
                        setTimeout(() => {
                            setShowAlert(false);
                            setSeverity("");
                            setMessage("");
                            document.getElementById(
                                "submit-btn"
                            ).style = `display: flex;
                            text-align: center`;
                            document.getElementById("tiktokForm").reset();
                        }, 3000);
                    })
                    .catch((err) => {
                        console.log(err);
                        setMessage("Unsuccessful. Error code:" + err.status);
                        setSeverity("error");
                        setShowAlert(true);
                        setTimeout(() => {
                            setShowAlert(false);
                            setSeverity("");
                            setMessage("");
                            document.getElementById(
                                "submit-btn"
                            ).style = `display: flex;
                            text-align: center`;
                        }, 3000);
                    });
            };
        }
    };

    return (
        <div className="tikTok_Page">
            <section className="tiktok__form">
                <h1>TikTok Parcel Return Form</h1>
                <form
                    className="form"
                    id="tiktokForm"
                    onSubmit={handleFormSubmit}
                >
                    <label htmlFor="tiktok-account">TikTok Account:</label>
                    <select
                        onChange={(e) => setTiktokAccount(e.target.value)}
                        value={tiktokAccount}
                    >
                        <option>Please select:</option>
                        <option>Tubbees Dessert Lab</option>
                        <option>Wholesale Deals</option>
                    </select>
                    <label htmlFor="order-number">Order Number:</label>
                    <input
                        type="number"
                        pattern="[0-9]*"
                        id="order-number"
                        name="order-number"
                        onChange={(e) => setOrderNumber(e.target.value)}
                        value={orderNumber}
                    />
                    <label htmlFor="tracking-number">Tracking Number:</label>
                    <input
                        type="text"
                        id="tracking-number"
                        name="tracking-number"
                        onChange={(e) => setTrackingNumber(e.target.value)}
                        value={trackingNumber}
                    />
                    <label>Tracking Status:</label>
                    <select
                        onChange={(e) => setTrackingStatus(e.target.value)}
                        value={trackingStatus}
                    >
                        <option>Please select:</option>
                        <option>Returning</option>
                        <option>Damaged</option>
                        <option>Stuck in transit</option>
                    </select>
                    <label htmlFor="pick-list-picture">Upload Pick List:</label>
                    <input
                        type="file"
                        id="pick-list-picture"
                        name="pick-list-picture"
                        accept="image/*"
                        onChange={(e) => {
                            setPickListImage(e.target.files[0]);
                        }}
                    />

                    <div className="form_line">
                        <fieldset>
                            <legend>Refund approved?</legend>
                            <div className="fieldset">
                                <div>
                                    <input
                                        type="radio"
                                        id="yes-refund"
                                        name="refund"
                                        value="yes"
                                        onChange={(e) =>
                                            setRefundApproved(
                                                e.target.value === "yes"
                                            )
                                        }
                                        required
                                    />
                                    <label htmlFor="yes-refund">Yes</label>
                                </div>
                                <div className="no-radio-button">
                                    <input
                                        type="radio"
                                        id="no-refund"
                                        name="refund"
                                        value="no"
                                        onChange={(e) =>
                                            setRefundApproved(
                                                e.target.value === "yes"
                                            )
                                        }
                                        required
                                    />
                                    <label htmlFor="no-refund">No</label>
                                </div>
                            </div>
                        </fieldset>
                    </div>

                    <div>
                        {refundApproved ? (
                            <div>
                                <label htmlFor="refund-date">
                                    Date of refund:
                                </label>
                                <input
                                    type="date"
                                    id="refund-date"
                                    name="refund-date"
                                    onChange={(e) =>
                                        setRefundDate(e.target.value)
                                    }
                                />
                                <label htmlFor="refund-amount">
                                    Refund amount (£):
                                </label>
                                <input
                                    type="number"
                                    pattern="[0-9]*"
                                    id="refund-amount"
                                    name="refund-amount"
                                    onChange={(e) =>
                                        setRefundAmount(e.target.value)
                                    }
                                    value={refundAmount}
                                />
                            </div>
                        ) : null}
                        <label htmlFor="ticket-number">Ticket Number:</label>
                        <input
                            type="number"
                            pattern="[0-9]*"
                            id="ticket-number"
                            name="ticket-number"
                            onChange={(e) => setTicketNumber(e.target.value)}
                            value={ticketNumber}
                        />

                        <div>
                            <div className="form_line">
                                <fieldset>
                                    <legend>Item returned?</legend>
                                    <div className="fieldset">
                                        <div>
                                            <input
                                                type="radio"
                                                id="yes-returned"
                                                name="returned"
                                                value="yes"
                                                onChange={handleItemReturned}
                                                required
                                            />
                                            <label htmlFor="yes-returned">
                                                Yes
                                            </label>
                                        </div>
                                        <div>
                                            <input
                                                type="radio"
                                                id="not-returned"
                                                name="returned"
                                                value="no"
                                                onChange={handleItemReturned}
                                                required
                                            />
                                            <label htmlFor="no-refund">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>

                            {itemReturned ? (
                                <div className="form_line">
                                    <fieldset>
                                        <legend>Any damages?</legend>
                                        <div className="fieldset">
                                            <div>
                                                <input
                                                    type="radio"
                                                    id="yes-damaged"
                                                    name="damages"
                                                    value="yes"
                                                    onChange={(e) => {
                                                        setItemDamaged(true);
                                                    }}
                                                    required
                                                />
                                                <label htmlFor="yes-returned">
                                                    Yes
                                                </label>
                                            </div>
                                            <div>
                                                <input
                                                    type="radio"
                                                    id="not-damaged"
                                                    name="damages"
                                                    value="no"
                                                    onChange={(e) => {
                                                        setItemDamaged(false);
                                                    }}
                                                    required
                                                />
                                                <label htmlFor="no-refund">
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    {showAlert ? (
                        <Alert severity={severity} sx={{ marginTop: "1%" }}>
                            {message}
                        </Alert>
                    ) : (
                        <></>
                    )}
                    <Button type="submit" id="submit-btn">
                        Submit
                    </Button>
                </form>
            </section>
        </div>
    );
};

export default ReturnForm;
