import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40vw",
    maxHeight: "80vh",
    overflowY: "scroll",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
};

export default function CrimeRefModal({ setCrimeRefOpen, finalSubmit }) {
    return (
        <Box sx={style}>
            <h2 style={{ textAlign: "center", color: "#FF0000" }}>WARNING</h2>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                You have not provided a crime reference number. Please confirm
                you will call the police to get a crime reference number and
                update this CCTV request with the new crime referenece.
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <Stack direction="row" spacing={10} sx={{ margin: "1% 30%" }}>
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={() => setCrimeRefOpen(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={() => {
                            finalSubmit();
                            setCrimeRefOpen(false);
                        }}
                    >
                        Confirm
                    </Button>
                </Stack>
            </Typography>
        </Box>
    );
}
