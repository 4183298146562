//React
import { useEffect, useState } from "react";
//Mui
import FiberNewIcon from "@mui/icons-material/FiberNew";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

//Props
interface Props {
    ol: any;
    index: number;
    handleUpdateOrderLines: (
        product: number,
        actual: number,
        units: string
    ) => void;
    isSiteLogin: boolean;
    handleAlert: (message: string, severity: string, duration: number) => void;
    handleUnsave: (product: number) => void;
    adminSide?: boolean;
    approvalPo?: boolean;
}

export default function PurchaseOrderRow({
    ol,
    index,
    handleUpdateOrderLines,
    isSiteLogin,
    handleAlert,
    handleUnsave,
    adminSide,
    approvalPo,
}: Props) {
    //Inputs
    const [actual, setActual] = useState<number>(
        ol.caseSize != null
            ? Math.ceil(ol.actualToOrder / ol.caseSize)
            : ol.actualToOrder
    );
    const [units, setUnits] = useState<string>(
        ol.caseSize != null ? "Case" : "Single"
    );
    //Stock
    const [storeStock, setStoreStock] = useState<any>({});
    //Validation
    const invalidNumberChars = ["-", "+", "e", "E", "."];
    const [isDone, setIsDone] = useState(ol.saved);
    //Dialog
    const [onOrderCheck, setOnOrderCheck] = useState<boolean>(false);

    useEffect(() => {
        let _storeStock = ol.storeStock;
        if (storeStock) {
            setStoreStock(_storeStock);
        }
    }, []);

    //validates number fields
    const numberValidation = (e: any) => {
        if (invalidNumberChars.includes(e.key)) {
            e.preventDefault();
        }
    };

    //Validates that row inputs are satisfactory
    const handleRowValidation = (onOrder: number) => {
        if (onOrder > 0) {
            if (actual === 0 || isNaN(actual)) {
                setActual(0);
                setIsDone(true);
                handleUpdateOrderLines(ol.productId, actual, units);
            } else {
                setOnOrderCheck(true);
            }
        } else {
            if (actual > 0 || (ol.forcedItem == false && ol.newItem == false)) {
                setIsDone(true);
                handleUpdateOrderLines(ol.productId, actual, units);
            } else {
                handleAlert("Actual must be greater than 0", "warning", 4000);
            }
        }
    };
    return (
        <tr
            style={
                isDone
                    ? { backgroundColor: "#a3ffbc" }
                    : ol.forcedItem
                    ? { backgroundColor: "#ff938a" }
                    : { backgroundColor: "#FFF" }
            }
        >
            <td className="name_cell">
                {ol.name}{" "}
                {ol.newItem ? (
                    <FiberNewIcon color="primary" fontSize="small" />
                ) : null}
                {ol.onOrder > 0 ? (
                    <Tooltip
                        title={`${ol.onOrder} already on order`}
                        placement="top-start"
                        style={{ cursor: "pointer" }}
                    >
                        <ErrorOutlineIcon color="warning" fontSize="small" />
                    </Tooltip>
                ) : null}
            </td>

            <td>{ol.sales}</td>
            <td>{ol.wastage}</td>

            {!adminSide ? (
                <td>
                    {storeStock ? (
                        <Tooltip
                            title={`Correct at: ${storeStock?.dateTime}`}
                            placement="top-start"
                            style={{ cursor: "pointer" }}
                        >
                            {storeStock.currentStock}
                        </Tooltip>
                    ) : (
                        "None"
                    )}
                </td>
            ) : (
                <>
                    <td>
                        {storeStock ? (
                            <Tooltip
                                title={`Correct at: ${storeStock?.dateTime}`}
                                placement="top-start"
                                style={{ cursor: "pointer" }}
                            >
                                {storeStock.currentStock}
                            </Tooltip>
                        ) : (
                            "None"
                        )}
                    </td>
                    <td>
                        {ol.warehouseStock ?
                            <Tooltip title={`Correct at: ${ol.warehouseStock?.dateTime}`}
                                placement="top-start" style={{ cursor: "pointer" }} >
                                <>
                                    {ol.warehouseStock?.currentStock}
                                    {ol.warehouseStockLive && ol.warehouseStockLive.currentStock != ol.warehouseStock?.currentStock ?
                                        <> ({ol.warehouseStockLive.currentStock})</>
                                        : null
                                    }
                                </>
                            </Tooltip>
                            : 0
                        }
                    </td>
                </>
            )}
            <td>{ol.toOrder}</td>
            <td>
                <input
                    type="number"
                    onChange={(e) => {
                        setActual(parseInt(e.target.value));
                        handleUpdateOrderLines(
                            ol.productId,
                            parseInt(e.target.value),
                            units
                        );
                    }}
                    onKeyDown={(e) => {
                        numberValidation(e);
                    }}
                    min="0"
                    value={actual}
                />
            </td>
            <td>
                {ol.caseSize != null ? (
                    <>
                        CASE (<b>{ol.caseSize}</b>)
                    </>
                ) : (
                    <>
                        Single (<b>{1}</b>)
                    </>
                )}
                {/* <select
                    onChange={(e) => {
                        setIsDone(false);
                        setUnits(e.target.value);
                        handleUnsave(ol.eposId);
                    }}
                    disabled={true}
                >
                    <option>Single</option>
                    <option selected={ol.caseSize != null}>Case</option>
                </select> */}
            </td>
            {/* {approvalPo ? (
                <td className="done_cell">
                    <CheckBoxIcon
                        style={isDone ? { color: "green" } : { color: "grey" }}
                        className="done_btn"
                        onClick={() => {
                            if (isDone) {
                                setIsDone(false);
                                handleUnsave(ol.eposId);
                            } else {
                                handleRowValidation(ol.onOrder);
                            }
                        }}
                        fontSize="large"
                    />
                </td>
            ) : null} */}
            {/* DIALOGS */}
            <Dialog open={onOrderCheck} className="on_order_dialog">
                <h2>
                    This order item already has a quanity of {ol.onOrder} on
                    order. Are you sure you have entered the correct amount
                    before procedding?
                </h2>
                <section className="btn_section">
                    <Button
                        variant="contained"
                        onClick={() => {
                            {
                                setIsDone(true);
                                handleUpdateOrderLines(
                                    ol.productId,
                                    actual,
                                    units
                                );
                                setOnOrderCheck(false);
                            }
                        }}
                    >
                        Yes, I'm Sure
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => setOnOrderCheck(false)}
                    >
                        No, go back
                    </Button>
                </section>
            </Dialog>
        </tr>
    );
}
