import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { numberValidation } from "../../utils/Validation";
//MUI
import { Box } from "@mui/material";
import { Button } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Alert } from "@mui/material";
//Styling
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    height: "85vh",
};
//interfaces
interface Props {
    setOpenForm: any;
    user: any;
    sites: any[];
    reloadRequestData: () => void;
}

export default function CCTVAdminForm({
    setOpenForm,
    user,
    sites,
    reloadRequestData,
}: Props) {
    //Toggle Form
    const [typeOfForm, setTypeOfForm] = useState<string | null>("cctv");
    //Form type & Common fields
    const [selectedSite, setSelectedSite] = useState<number>(0);
    const [date, setDate] = useState<string>("");
    const [time, setTime] = useState<string>("");
    const [crimeRef, setCrimeRef] = useState<string>("");
    const [otherNotes, setOtherNotes] = useState<string>("");
    //CCTV Form
    const [reason, setReason] = useState<string>("");
    const [descriptionOfEvent, setDescriptionOfEvent] = useState<string>("");
    const [descriptionOfPerson, setDescriptionOfPerson] = useState<string>("");
    const [stolenItems, setStolenItems] = useState<string>("");
    //Drive Off Form
    const [registrationNumber, setRegistrationNumber] = useState<string>("");
    const [pumpNumber, setPumpNumber] = useState<string>("");
    const [selectedFuelType, setSelectedFuelType] = useState<string>("");
    const [cost, setCost] = useState<string>("");
    const [litres, setLitres] = useState<string>("");
    //Alerts
    const [alert, setAlert] = useState<boolean>(false);
    const [disable, setDisable] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [severity, setSeverity] = useState<any>("");

    const handleTypeOfForm = (
        event: React.MouseEvent<HTMLElement>,
        newType: string | null
    ) => {
        if (newType !== null) {
            setTypeOfForm(newType);
            handleClearForm();
        }
    };

    const handleAlert = (
        message: string,
        severity: any,
        close: boolean,
        duration: number = 4000
    ): void => {
        setMessage(message);
        setSeverity(severity);
        setAlert(true);
        setTimeout(() => {
            setAlert(false);
            setSeverity("");
            setMessage("");
            setDisable(false);
            if (close) {
                reloadRequestData();
                setOpenForm(false);
            }
        }, duration);
    };

    //This will only clear fields that are depenent on form type
    const handleClearForm = (): void => {
        setReason("");
        setDescriptionOfEvent("");
        setDescriptionOfPerson("");
        setStolenItems("");
        setRegistrationNumber("");
        setPumpNumber("");
        setSelectedFuelType("");
        setCost("");
        setLitres("");
    };
    const handleFormValidation = (): void => {
        //Validation first establish common required fields and then form type specific fields before allowing submission
        setDisable(true);

        if (selectedSite === 0) {
            handleAlert("You must select a site", "warning", false);
        } else if (
            date === "" ||
            time === "" ||
            moment(date).format("YYYY-MM-DD") > moment().format("YYYY-MM-DD")
        ) {
            handleAlert(
                "Please enter a date and a time before now",
                "warning",
                false
            );
        } else {
            if (typeOfForm === "cctv") {
                if (reason === "") {
                    handleAlert(
                        "You must select a reason for the CCTV request",
                        "warning",
                        false
                    );
                } else if (reason === "Theft" && stolenItems.length === 0) {
                    handleAlert(
                        "Please enter what was stolen",
                        "warning",
                        false
                    );
                } else if (
                    descriptionOfEvent === "" ||
                    descriptionOfEvent.length < 25
                ) {
                    handleAlert(
                        "Please enter a description at least 25 characters long",
                        "warning",
                        false
                    );
                } else if (descriptionOfPerson.length === 0) {
                    handleAlert(
                        "Please enter a description of those involved",
                        "warning",
                        false
                    );
                } else {
                    handleFromSubmission();
                }
            } else {
                if (registrationNumber === "") {
                    handleAlert(
                        "Please enter a registration number",
                        "warning",
                        false
                    );
                } else if (
                    pumpNumber === "" ||
                    parseInt(pumpNumber) < 1 ||
                    parseInt(pumpNumber) > 8
                ) {
                    handleAlert(
                        "Pump number must be between 1 - 8",
                        "warning",
                        false
                    );
                } else if (selectedFuelType === "") {
                    handleAlert("Please select a fuel type", "warning", false);
                } else if (cost === "") {
                    handleAlert(
                        "Please enter the cost of fuel taken",
                        "warning",
                        false
                    );
                } else if (litres === "") {
                    handleAlert(
                        "Please enter the amount of litres of fuel taken",
                        "warning",
                        false
                    );
                } else {
                    handleFromSubmission();
                }
            }
        }
    };

    const handleFromSubmission = (): void => {
        //Setting the sitename and request type as they are not readily available
        let _siteName: string = "";
        let _requestType: number = typeOfForm === "cctv" ? 2 : 1;

        for (let i = 0; i < sites.length; i++) {
            if (sites[i].epos === selectedSite) {
                _siteName = sites[i].name;
            }
        }

        let reqBody = {
            staffName: user.user.user,
            siteId: selectedSite,
            site: _siteName,
            date: date,
            time: time,
            requestType: _requestType,
            reason: reason,
            crimeReference: crimeRef,
            items: stolenItems,
            description: descriptionOfEvent,
            person: descriptionOfPerson,
            note: otherNotes,
            images: [],
            plate: registrationNumber,
            pump: pumpNumber,
            pumpType: selectedFuelType,
            amount: cost,
            litres: litres,
        };

        axios
            .post(
                `${process.env.REACT_APP_ANPR_URL}/cctv-system/submit`,
                reqBody
            )
            .then((res) => {
                console.log(res);
                handleAlert("Form successfully submitted", "success", true);
            })
            .catch((err) => {
                console.log(err);
                handleAlert(
                    "Form did not submit - please try again",
                    "error",
                    false
                );
            });
    };
    return (
        <Box sx={style} className="cctv-admin-form-modal">
            <ToggleButtonGroup
                value={typeOfForm}
                exclusive
                onChange={handleTypeOfForm}
                aria-label="Type of cctv form"
                className="toggle-btns"
                color="primary"
            >
                <ToggleButton
                    value="cctv"
                    aria-label="left aligned"
                    className="toggle-btn"
                >
                    CCTV
                </ToggleButton>
                <ToggleButton
                    value="drive-off"
                    aria-label="centered"
                    className="toggle-btn"
                >
                    Drive-off
                </ToggleButton>
            </ToggleButtonGroup>
            <h1>New Request Form</h1>
            <section className="cctv-admin-form">
                <div className="form-row">
                    <div className="form-col">
                        <label>Site event occured?</label>
                        <select
                            onChange={(e) =>
                                setSelectedSite(parseInt(e.target.value))
                            }
                            value={selectedSite}
                        >
                            <option value={0}>Please select</option>
                            {sites && sites.length > 0
                                ? sites.map((site: any, i: number) => {
                                      return (
                                          <option key={i} value={site.epos}>
                                              {site.name}
                                          </option>
                                      );
                                  })
                                : null}
                        </select>
                    </div>
                    <div className="form-col">
                        <label>Date of event?</label>
                        <input
                            type="date"
                            onChange={(e) => setDate(e.target.value)}
                            value={date}
                            max={moment().format("YYYY-MM-DD")}
                        />
                    </div>
                    <div className="form-col">
                        <label>Time of event?</label>
                        <input
                            type="time"
                            onChange={(e) => setTime(e.target.value)}
                            value={time}
                        />
                    </div>
                </div>

                {typeOfForm === "cctv" ? (
                    <div className="form-type">
                        <div className="form-row">
                            <div className="form-col">
                                <label>
                                    What is the reason for this request?
                                </label>
                                <select
                                    onChange={(e) => setReason(e.target.value)}
                                    value={reason}
                                >
                                    <option value={""}>Please select</option>
                                    <option>Theft</option>
                                    <option>Attempted Theft</option>
                                    <option>Assault</option>
                                    <option>Vandalism</option>
                                    <option>Loitering</option>
                                    <option>Other</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-col">
                                <label>
                                    Please enter a description of the event
                                </label>
                                <textarea
                                    placeholder="Description of event..."
                                    onChange={(e) =>
                                        setDescriptionOfEvent(e.target.value)
                                    }
                                    value={descriptionOfEvent}
                                />
                            </div>

                            <div className="form-col">
                                <label>
                                    Please enter a description of the people
                                    involved
                                </label>
                                <textarea
                                    placeholder="Description of people..."
                                    onChange={(e) =>
                                        setDescriptionOfPerson(e.target.value)
                                    }
                                    value={descriptionOfPerson}
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-col">
                                <label>
                                    If items were stolen what were they?
                                </label>
                                <input
                                    placeholder="Item(s) stolen..."
                                    onChange={(e) =>
                                        setStolenItems(e.target.value)
                                    }
                                    value={stolenItems}
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="form-type">
                        <div className="form-row">
                            <div className="form-col">
                                <label>
                                    What is the vehicle registration number?
                                </label>
                                <input
                                    placeholder="Vehicle Registration..."
                                    onChange={(e) =>
                                        setRegistrationNumber(e.target.value)
                                    }
                                    value={registrationNumber}
                                />
                            </div>

                            <div className="form-col">
                                <label>What pump number was used?</label>
                                <input
                                    type="number"
                                    placeholder="Pump Number..."
                                    onChange={(e) =>
                                        setPumpNumber(e.target.value)
                                    }
                                    value={pumpNumber}
                                    onKeyDown={(e) => {
                                        numberValidation(e);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-col">
                                <label>What fuel type was taken?</label>
                                <select
                                    onChange={(e) =>
                                        setSelectedFuelType(e.target.value)
                                    }
                                    value={selectedFuelType}
                                >
                                    <option value={""}>Please select</option>
                                    <option>Unleaded</option>
                                    <option>Diesel</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-col">
                                <label>
                                    What was the cost value of the fuel taken?
                                </label>
                                <input
                                    type="number"
                                    placeholder="Cost..."
                                    onChange={(e) => setCost(e.target.value)}
                                    value={cost}
                                />
                            </div>

                            <div className="form-col">
                                <label>
                                    How much litres of fuel was taken?
                                </label>
                                <input
                                    type="number"
                                    placeholder="Litres..."
                                    onChange={(e) => setLitres(e.target.value)}
                                    value={litres}
                                />
                            </div>
                        </div>
                    </div>
                )}

                <div className="form-row">
                    <div className="form-col">
                        <label>
                            What is the crime referenece number if available?{" "}
                        </label>
                        <input
                            type="text"
                            placeholder="Crime reference number..."
                            onChange={(e) => setCrimeRef(e.target.value)}
                            value={crimeRef}
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-col">
                        <label>
                            Please add any other relevant notes you may have?{" "}
                        </label>
                        <textarea
                            placeholder="Please add any other notes..."
                            onChange={(e) => setOtherNotes(e.target.value)}
                            value={otherNotes}
                        />
                    </div>
                </div>
            </section>
            <section className="alert-section">
                {alert ? <Alert severity={severity}>{message}</Alert> : null}
            </section>
            <section className="btn-section">
                <Button
                    variant="contained"
                    onClick={() => setOpenForm(false)}
                    color="error"
                    disabled={disable}
                >
                    close
                </Button>
                <Button
                    variant="contained"
                    color="success"
                    onClick={handleFormValidation}
                    disabled={disable}
                >
                    Submit
                </Button>
            </section>
        </Box>
    );
}
