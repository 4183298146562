import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";
import "../eod.scss";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Stack from "@mui/material/Stack";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40vw",
  heigth: "20vh",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
  "@media(max-width: 600px)": {
    width: "90vw",
    maxHeight: "70vh",
  },
};

export default function StatusModal(props) {
  const [details, setDetails] = useState({});
  const [user, setUser] = useState("");
  const [value, setValue] = useState("");

  useEffect(() => {
    setDetails(props.details);
    let userD = localStorage.getItem("userDetails");
    let user = JSON.parse(userD);
    setUser(user.user.user);
    setValue(props.details.name)
  }, [props]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleStatus = () => {
    document.getElementById("btn__mod").style.display = "none"
    let data = {
      user: user,
      status: value,
      id: details.eodId
    };
    axios.post(`https://nitropos-backend.nitrosolutions.co.uk/eos/statuses`,data)
    .then((res) => {
      props.close()
      props.rel()
    }).catch((err) => {
      console.log(err)
      document.getElementById("btn__mod").style.display = "inline"
    })
  };

  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Currrent Status: {details.name}
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">Status</FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="Current Status"
            name="radio-buttons-group"
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel
              value="1"
              control={<Radio sx={{ marginTop: "-15%" }} />}
              label="Awaiting"
              on
            />
            <FormControlLabel
              value="2"
              control={<Radio sx={{ marginTop: "-15%" }} />}
              label="In Transit"
            />
            <FormControlLabel
              value="3"
              control={<Radio sx={{ marginTop: "-15%" }} />}
              label="Deposited"
            />
          </RadioGroup>
        </FormControl>
        <Stack spacing={2} direction="row" sx={{width: "30%", margin: "2% auto"}} id="btn__mod">
          <Button variant="outlined" color="error" onClick={() => props.close()}>Cancel</Button>
          <Button variant="contained" color="success" onClick={() => handleStatus()}>Send</Button>
        </Stack>
      </Typography>
    </Box>
  );
}
