import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import QrReader from "react-web-qr-reader";

//Styling
const QRStyle = {
    height: 240,
    width: 300,
};

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40vw",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 2,
    maxHeight: "70vh",
    overflowY: "scroll",
    "@media(max-width: 690px)": {
        width: "90vw",
        maxHeight: "90vh",
    },
};
const StatusModal = ({
    statusList,
    currentStatus,
    reqId,
    rel,
    handleCloseStatus,
    statusId,
}) => {
    const url = process.env.REACT_APP_ANPR_URL;
    const [status, setStatus] = useState("");
    const [staff, setStaff] = useState("");
    const [alert, setAlert] = useState(false);
    const [severity, setSeverity] = useState("");
    const [message, setMessage] = useState("");
    const [statusInfo, setStatusInfo] = useState({});
    const [mainAlert, setMainAlert] = useState(false);
    const [showQR, setShowQR] = useState(false);
    const [scanned, setScanned] = useState(false);
    const [reburn, setReburn] = useState(true);

    useEffect(() => {
        if (
            statusId === 1 ||
            statusId === 2 ||
            statusId === 5 ||
            statusId === 7 ||
            statusId === 8 ||
            statusId === 9 ||
            statusId === 10 ||
            statusId === 11 ||
            statusId === 12 ||
            statusId === 13 ||
            statusId === 14
        ) {
            setMainAlert(true);
        }
    }, []);

    //QR
    const handleQRError = (error) => {
        console.log(error);
    };
    const handleScan = (result) => {
        setShowQR(false);
        setScanned(true);
        // searchId(result.data);
    };

    const searchId = (id) => {
        axios
            .get(`${url}/cctv-system/submissions/details/${id}`)
            .then((res) => {
                setStatusInfo({
                    id: res.data.requestId,
                    site: res.data.site,
                    date: new Date(res.data.date).toLocaleDateString("en-GB"),
                    status: res.data.statusName,
                });
            })
            .catch((err) => {
                console.log(`could not find that request:${err}`);
                if (err.response.status === 500) {
                    setMessage("Could not find CCTV request, please try again");
                    setSeverity("warning");
                    setAlert(true);
                    setTimeout(() => {
                        setAlert(false);
                    }, 2500);
                }
            });
    };
    const handleStatusChange = async () => {
        if (staff === "" || status === "") {
            setMessage("Please enter your name");
            setSeverity("warning");
            setAlert(true);
            setTimeout(() => {
                setAlert(false);
                setSeverity("");
                setMessage("");
            }, 3000);
        } else {
            let newStatusId = "";
            for (let i = 0; i < statusList.length; i++) {
                if (statusList[i].name === status) {
                    newStatusId = statusList[i].statusId;
                }
            }

            let data = {
                requestId: reqId,
                status: newStatusId,
                user: staff,
            };

            await axios
                .post(`${url}/cctv-system/submission/update/status`, data)
                .then((res) => {
                    setMessage("Status successfully updated.");
                    setSeverity("success");
                    setAlert(true);
                    setTimeout(() => {
                        setAlert(false);
                        setSeverity("");
                        setMessage("");
                        setStatus("");
                        setStaff("");
                        rel();
                        handleCloseStatus(false);
                    }, 3000);
                })
                .catch((err) => {
                    console.log(err);

                    setMessage("Error occurred. Please try again.");
                    setSeverity("error");
                    setAlert(true);
                    setTimeout(() => {
                        setAlert(false);
                        setSeverity("");
                        setMessage("");
                    }, 3000);
                });
        }
    };
    return (
        <Box sx={style} className="all_modal">
            <Typography id="modal-modal-title" variant="h6" component="h2">
                <h3 style={{ textAlign: "center", width: "100%" }}>
                    Current Status: {currentStatus}
                </h3>
            </Typography>{" "}
            {!mainAlert ? (
                <div>
                    {reburn ? (
                        <>
                            <p style={{ textAlign: "center" }}>
                                Does the CCTV footage need a reburn?
                            </p>
                            <section
                                style={{
                                    display: "flex",
                                    justifyContent: "space-evenly",
                                }}
                            >
                                <Button
                                    variant={
                                        status === "Reburn"
                                            ? "contained"
                                            : "outlined"
                                    }
                                    color="primary"
                                    onClick={() => {
                                        setShowQR(false);
                                        setStatus("Reburn");
                                    }}
                                    sx={{ width: "30%" }}
                                >
                                    Yes
                                </Button>
                                <Button
                                    variant={
                                        status === "noReburn"
                                            ? "contained"
                                            : "outlined"
                                    }
                                    color="error"
                                    onClick={() => {
                                        setShowQR(true);
                                        setStatus("noReburn");
                                        setReburn(false);
                                    }}
                                    sx={{ width: "30%" }}
                                >
                                    no
                                </Button>
                            </section>
                        </>
                    ) : null}

                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {showQR ? (
                            <>
                                <section style={{ height: "400px" }}>
                                    <h4
                                        style={{
                                            textAlign: "center",
                                            width: "100%",
                                        }}
                                    >
                                        Scan the QR code on the CCTV envelope
                                    </h4>
                                    <QrReader
                                        delay={500}
                                        style={QRStyle}
                                        onError={handleQRError}
                                        onScan={handleScan}
                                    />
                                </section>
                                <Button
                                    style={{
                                        display: "flex",
                                        margin: "0 auto",
                                    }}
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => {
                                        setShowQR(false);
                                        setScanned(true);
                                        setStatus("Footage At Store");
                                    }}
                                >
                                    Unable to scan? - Log mannually
                                </Button>
                            </>
                        ) : null}

                        {scanned ? (
                            <h1 style={{ textAlign: "center" }}>Accepted ✔</h1>
                        ) : null}
                        {scanned || status === "Reburn" ? (
                            <div
                                style={{ color: "#4d4d4d", marginTop: "100px" }}
                            >
                                <h4>Please enter your full name:</h4>

                                <input
                                    type="text"
                                    onChange={(e) => setStaff(e.target.value)}
                                    value={staff}
                                />
                                <br />
                                {alert ? (
                                    <Alert severity={severity}>{message}</Alert>
                                ) : (
                                    <></>
                                )}
                            </div>
                        ) : null}
                    </Typography>
                </div>
            ) : (
                <div>
                    <Alert severity="warning">
                        WARNING! - You do not have permissions to change the
                        current status.
                    </Alert>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            marginTop: "3%",
                        }}
                    ></div>
                </div>
            )}
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "3%",
                }}
            >
                {scanned || status === "Reburn" ? (
                    <Button
                        variant="contained"
                        color="primary"
                        className="button"
                        onClick={handleStatusChange}
                    >
                        Send
                    </Button>
                ) : null}
                <Button
                    variant="contained"
                    color="warning"
                    onClick={() => handleCloseStatus(false)}
                >
                    Close
                </Button>
            </div>
        </Box>
    );
};

export default StatusModal;
