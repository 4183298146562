import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useState, useEffect} from 'react'

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
  '@media(max-width: 600px)': {
    width: "90vw",
    maxHeight: "70vh",
    overflowX: "scrollable"
  }
};

export default function IndNotes(props) {
    const [notes, setNotes] = useState([])

    useEffect(() => {
        console.log(props.notes)
        setNotes(props.notes)
    },[props])
  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Notes
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <table style={{color: "#4d4d4d", border: "1px solid #4d4d4d", borderCollapse: "collapse"}}>
            <thead style={{backgroundColor: "#2c53a0", color: "#fff"}}>
                <tr>
                    <th>Added By</th>
                    <th>Date</th>
                    <th>Note</th>
                </tr>
            </thead>
            <tbody style={{border: "1px solid #4d4d4d"}}>
                {notes.map(function(d,i){
                    return(
                        <tr style={{border: "1px solid #4d4d4d"}}>
                            <th style={{border: "1px solid #4d4d4d"}}>{d.addedBy}</th>
                            <th style={{border: "1px solid #4d4d4d"}}>{new Date(d.addedDate).toLocaleDateString()}</th>
                            <th style={{border: "1px solid #4d4d4d"}}>{d.note}</th>
                        </tr>
                    )
                })}
            </tbody>
        </table>
      </Typography>
    </Box>
  );
}
