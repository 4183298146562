import "./notfound.scss";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import logo from "../../images/logo.png";
import {useNavigate} from 'react-router-dom';

export default function NotFound() {
    const navigate = useNavigate()
  return (
    <section className="mainbox">
      <img src={logo} alt="NitroPOS log" />
      <div className="main__error">
        <div className="error__row">
          <div className="err">4</div>
          <HelpOutlineIcon className="icon" />
          <div className="err2">4</div>
        </div>
        <div className="msg">
          Maybe this page moved? Got deleted? Is hiding out in quarantine? Never
          existed in the first place?
          <p>
            Let's go <a onClick={() => navigate("/")}>home</a> and try from there.
          </p>
        </div>
      </div>
    </section>
  );
}
