import { useEffect, useState, useRef } from "react"
import axios from "axios"
import { Box, Button, Modal } from "@mui/material";
import PrintIcon from '@mui/icons-material/Print';
import ReactToPrint from "react-to-print";
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import { Alert } from "@mui/material";

const style = {
    position: "absolute",
    top: "5%",
    left: "25%",
    width: "45vw",
    maxHeight: "80vh",
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: 24,
    overflowY: "scroll",
    p: 4,
    "@media(max-width: 600px)": {
        width: "95vw",
    },
};

export default function Manufacturing() { 
    const [lists, setLists] = useState<any>([])
    const [products, setProducts] = useState<any>([]);
    const [listData, setListData] = useState<any>({})
    const [showProducts, setShowProducts] = useState<boolean>(false)
    const [listError, setListError] = useState<boolean>(false)
    const [submissionAlert, setSubmissionAlert] = useState<boolean>(false)
    const [alertType,setAlertType] = useState<any>("")
    const [alertText,setAlertText] = useState<any>("")
    const [reload,setReload]=useState<boolean>(false)
    const [showSubmit,setShowSubmit]=useState<boolean>(true)
    const componentRef = useRef<any>();
    const myRefname = useRef<any>(null);

    const url = process.env.REACT_APP_DEPPLOYED;

    useEffect(() => {
        axios.get(`${url}/purchaseOrder/manufacturing`).then((resp) => {
            setLists(resp.data)
        }).catch((err) => {
            console.log(err)
            setListError(true)
            setTimeout(()=>{
                setListError(false)
            },2000)
        })
    }, [reload])

    const selectList = async (data: any) => {
        setListData(data)
        await axios.get(`${url}/purchaseOrder/manufacturing/lists/${data.manufactureId}`).then((resp) => {
            setProducts(resp.data)
            setShowProducts(true)
        }).catch((err) => {
            console.log(err)
            setListError(true)
            setTimeout(()=>{
                setListError(false)
            },2000)
        })
    }

    const handleClick = () => {
        myRefname.current.click()!;
    };

    const linkToPrint = () => {
        return (
            <Button
                variant="contained"
                color="warning"
                ref={myRefname}
                style={{ display: "none" }}
            >
                Print PDF
            </Button>
        );
    };

    const createPicklist = async () => {
        setShowSubmit(false)
        await axios.post(`${url}/purchaseOrder/manufacturing/complete`, { id: listData.manufactureId }).then((resp) => {
            setAlertType("success")
            setAlertText("Successfully Created Picklist!")
            setSubmissionAlert(true)
            setTimeout(()=>{
                setSubmissionAlert(false)
                setAlertType("")
                setReload(!reload)
                setShowProducts(false)
                setListData({})
            },2000)
        }).catch((err) => {
            console.log(err)
            setAlertType("error")
            setAlertText("Could Not Submit List. Please Try Again!")
            setSubmissionAlert(true)
            setTimeout(()=>{
                setSubmissionAlert(false)
                setAlertType("")
                setShowSubmit(true)
            },2000)
        })
    }

    return (
        <>
            <h1>Manufacturing</h1>
            <table className="man_table">
                <thead>
                    <tr>
                        <th>List ID</th>
                        <th>Week</th>
                        <th>Submission</th>
                    </tr>
                </thead>
                <tbody style={{ textAlign: "center" }}>
                    {lists.map(function (d: any, i: number) {
                        var bgColor = d.complete ? "#a3ffbc" : "";
                        return (
                            <tr key={i} style={{ backgroundColor: bgColor }} onClick={() => selectList(d)}>
                                <td>{d.manufactureId}</td>
                                <td>{d.startDate} - {d.endDate}</td>
                                <td>{d.createdBy} - {d.createdOn}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <Modal open={showProducts} onClose={() => setShowProducts(false)}>
                <Box sx={style}  >
                    {products.length > 0 ?
                        <> 
                            <div ref={componentRef} style={{ padding: "10px" }}>
                                <div style={{display:"flex", justifyContent:"space-around", fontWeight:"bold"}}>
                                    <h2>{listData.startDate}</h2>
                                    <h2>-</h2>
                                    <h2>{listData.endDate}</h2>
                                </div>
                                <table style={{ margin: "0 auto", width: "80%" }}>
                                    <thead >
                                        <tr style={{ textAlign: "center", backgroundColor: "#2c53a0", color: "#ffff" }}>
                                            <th>Name</th>
                                            <th>Quantity</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ textAlign: "center" }}>
                                        {products.map(function (d: any, i: number) {
                                            return (
                                                <>
                                                    <tr>
                                                        <td>{d.name}</td>
                                                        <td style={{ border: "2px solid #4d4d4d", borderRadius: "10px" }}>{d.qty}</td>
                                                    </tr>
                                                    <hr />
                                                </>
                                            )
                                        })}
                                    </tbody>
                                </table>

                            </div>
                            <div style={{ position: "sticky", display: "flex", bottom: "0", justifyContent: "space-between" }}>
                                <Button variant="contained" onClick={handleClick}><PrintIcon fontSize="medium" /></Button>
                               {showSubmit?
                               <Button color="success" variant="contained" onClick={createPicklist} disabled={listData.complete}><PlaylistAddCheckIcon fontSize="medium" /></Button>
                               :null}
                            </div>

                            <ReactToPrint
                                documentTitle={`ManufacturingList`}
                                trigger={linkToPrint}
                                content={() => componentRef.current}
                            />
                        </> :
                        <h2>No products found for this list</h2>}
                    {submissionAlert ?
                        <Alert className="alert" severity={alertType}>
                            {alertText}
                        </Alert>
                        : null}
                </Box>
            </Modal>
            {listError ?
                <Alert className="alert" severity="error">
                    Could Not Load List Details!
                </Alert>
                : null}
        </>
    )
}