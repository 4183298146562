import { useState, useEffect } from "react";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import ModeNightIcon from "@mui/icons-material/ModeNight";
import ShiftModal from "./modal/ShiftModal";
import { Button } from "@mui/material";
import moment from "moment";
import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import StrollerIcon from "@mui/icons-material/Stroller";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import Tooltip from "@mui/material/Tooltip";
import Modal from "@mui/material/Modal";
import HolidayModal from "./modal/HolidayModal";

export default function IndvShift({
    shift,
    employee,
    rotaId,
    siteName,
    date,
    reloadData,
}) {
    //Organise number and types of shift
    const [onLeave, setOnLeave] = useState(false);
    const [leaveType, setLeaveType] = useState(0);
    const [leaveStatus, setLeaveStatus] = useState(0);
    const [holidayId, setHolidayId] = useState(0);
    const [shiftColour, setShiftColour] = useState("");

    //Existing Shift Modal
    const [openExistingShiftModal, setOpenExistingShiftModal] = useState(false);
    const handleOpenExistingShiftModal = () => {
        setOpenExistingShiftModal(true);
    };
    const handleCloseExistingShiftModal = () =>
        setOpenExistingShiftModal(false);


    const [openHolidayModal, setOpenHolidayModal] = useState(false);
    const handleHolidayModal = () => {
        setOpenHolidayModal(true);
    };
    const handleCloseHolidayModal = () =>
        setOpenHolidayModal(false);
    useEffect(() => {
        //Check shift numbers and if there is any leave
        if (shift.leave) {
            setOnLeave(true);
            setLeaveType(shift.leaveType);
            setLeaveStatus(shift.status);
            if (shift.holidayId) {
                setHolidayId(shift.holidayId)
            }
        }

        //Change shift background dependant on stage
        if (shift.differentSite) {
            setShiftColour("#f2f277");
        } else if (shift.noShow) {
            setShiftColour("#f44b48");
        } else if (shift.confirmed) {
            setShiftColour("#41f2b1");
        } else if (shift.published) {
            setShiftColour("#64c4e0");
        } else {
            setShiftColour("#dfe6f0");
        }
    }, []);

    return (
        <>
            {!onLeave ? (
                <Tooltip
                    title={
                        shift.differentSite
                            ? "Shift at " + shift.siteName
                            : null
                    }
                >
                    <Button
                        onClick={handleOpenExistingShiftModal}
                        variant="outlined"
                        className="shift_time"
                        sx={{
                            backgroundColor: shiftColour,
                            width: "95%",
                        }}
                        startIcon={
                            shift.notes != null ? <SpeakerNotesIcon /> : null
                        }
                        endIcon={
                            moment(shift.endTime).format("YYYY-MM-DD") !=
                                moment(shift.startTime).format("YYYY-MM-DD") ? (
                                <ModeNightIcon
                                    color="warning"
                                    fontSize="small"
                                />
                            ) : null
                        }
                    >
                        {/* Display actual start and end if the shift if confirmed */}
                        {shift.actualStart === null
                            ? moment(shift.startTime).format("HH:mm")
                            : moment(shift.actualStart).format("HH:mm")}{" "}
                        -{" "}
                        {shift.actualEnd === null
                            ? moment(shift.endTime).format("HH:mm")
                            : moment(shift.actualEnd).format("HH:mm")}
                    </Button>
                </Tooltip>
            ) : onLeave && leaveType == 1 && leaveStatus == 2 ? (
                <Tooltip title="Holiday" onClick={handleHolidayModal}>
                    <BeachAccessIcon
                        color="success"
                        fontSize="large"
                        sx={{
                            width: "95%",
                        }}
                    />
                </Tooltip>
            ) : onLeave && leaveType == 2 && leaveStatus == 2 ? (
                <Tooltip title="Holiday Unpaid" onClick={handleHolidayModal}>
                    <BeachAccessIcon
                        color="secondary"
                        fontSize="large"
                        sx={{
                            width: "95%",
                        }}
                    />
                </Tooltip>
            ) : onLeave &&
                (leaveType == 1 || leaveType == 2) &&
                leaveStatus == 1 ? (
                <Tooltip title="Holiday Pending Approval">
                    <PauseCircleFilledIcon
                        color="warning"
                        fontSize="large"
                        sx={{
                            width: "95%",
                        }}
                    />
                </Tooltip>
            ) : onLeave && leaveType == 4 ? (
                <Tooltip title="Mat/Pat leave">
                    <StrollerIcon
                        color="primary"
                        fontSize="large"
                        sx={{
                            width: "95%",
                        }}
                    />
                </Tooltip>
            ) : onLeave && leaveType == 3 ? (
                <Tooltip title="Sick">
                    <VaccinesIcon
                        color="error"
                        fontSize="large"
                        sx={{
                            width: "95%",
                        }}
                    />
                </Tooltip>
            ) : onLeave && leaveType == 5 ? (
                <Tooltip title="Awol">
                    <PsychologyAltIcon
                        color="warning"
                        fontSize="large"
                        sx={{
                            width: "95%",
                        }}
                    />
                </Tooltip>
            ) : onLeave && leaveType == 6 ? (
                <Tooltip title="Other">
                    <NewReleasesIcon
                        color="primary"
                        fontSize="large"
                        sx={{
                            width: "95%",
                        }}
                    />
                </Tooltip>
            ) : null}
            {/* SHIFT MODAL */}
            <Modal
                open={openExistingShiftModal}
                aria-labelledby="Existing Shift Modal"
                aria-describedby="Existing Shift Modal"
            >
                <ShiftModal
                    handleClose={handleCloseExistingShiftModal}
                    rotaId={rotaId}
                    employee={employee}
                    site={siteName}
                    _shiftId={shift.shiftId}
                    date={date}
                    reloadData={reloadData}
                    otherSite={shift.differentSite}
                />
            </Modal>

            <Modal
                open={openHolidayModal}
                onClose={handleCloseHolidayModal}
                aria-labelledby="Existing Shift Modal"
                aria-describedby="Existing Shift Modal"
            >
                <HolidayModal holidayId={holidayId} />
            </Modal>

        </>
    );
}
