import { useState } from "react";
import axios from "axios";
//MUI
import { Box } from "@mui/material";
import { Button } from "@mui/material";
import { Alert } from "@mui/material";
//Styling
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};
//Inferfaces
interface Props {
    user: any;
    setOpenStatus: any;
    request: any;
    statuses: any;
    reloadRequestData: () => void;
}

export default function CCTVAdminStatusModal({
    user,
    request,
    setOpenStatus,
    statuses,
    reloadRequestData,
}: Props) {
    const [existingStatus, setExistingStatus] = useState<number>(
        request.statusId
    );
    const [selectedStatus, setSelectedStaus] = useState<number>(
        request.statusId
    );
    //Alerts
    const [alert, setAlert] = useState<boolean>(false);
    const [disable, setDisable] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [severity, setSeverity] = useState<any>("");

    const handleAlert = (
        message: string,
        severity: any,
        close: boolean,
        duration = 4000
    ): void => {
        setMessage(message);
        setSeverity(severity);
        setAlert(true);
        setTimeout(() => {
            setAlert(false);
            setMessage("");
            setSeverity("");
            if (close) {
                setOpenStatus(false);
                reloadRequestData();
            }
            setDisable(false);
        }, duration);
    };

    const handleStatusChange = (): void => {
        setDisable(true);

        let reqBody = {
            requestId: request.requestId,
            status: selectedStatus,
            user: user.user.user,
        };

        if (selectedStatus === existingStatus) {
            handleAlert("This is already the status", "warning", false);
        } else {
            axios
                .post(
                    `${process.env.REACT_APP_ANPR_URL}/cctv-system/submission/update/status`,
                    reqBody
                )
                .then((res) => {
                    handleAlert("Successfully Changed", "success", true);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };
    return (
        <Box className="cctv-status-modal" sx={style}>
            <h1>Status Change</h1>
            <p>The current status of this request is</p>
            <p className="existing-status">{request.statusName}</p>{" "}
            <p>
                {" "}
                Select a new one below and submit to change the status of this
                request.
            </p>
            <select
                value={selectedStatus}
                onChange={(e) => setSelectedStaus(parseInt(e.target.value))}
            >
                {statuses && statuses.length > 0
                    ? statuses.map((status: any, i: number) => {
                          return (
                              <option key={i} value={status.statusId}>
                                  {status.name}
                              </option>
                          );
                      })
                    : null}
            </select>
            <section className="alert-section">
                {alert ? <Alert severity={severity}>{message}</Alert> : null}
            </section>
            <section className="btn-section">
                <Button
                    variant="contained"
                    color="error"
                    onClick={() => setOpenStatus(false)}
                    disabled={disable}
                >
                    Close
                </Button>
                <Button
                    variant="contained"
                    color="success"
                    onClick={handleStatusChange}
                    disabled={disable}
                >
                    Submit
                </Button>
            </section>
        </Box>
    );
}
