import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";
// import axios from 'axios';
import test from '../../imageTest/test.jpeg'

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70vw",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
  maxHeight: "80vh",
  overflowY: "scroll",
};

export default function OpenImage(props) {
  const [invoice, setInvoice] = useState([]);
  const [user, setUser] = useState("")

  useEffect(() => {
    let getUser = sessionStorage.getItem("user");
    setUser(getUser);
    //setInvoice to props
  }, []);

  const close = () => {
    props.close()
  }

  const submit = () => {
    props.close()
    //request to send the PO to the back end, close the modal, reload the next screen
  }

  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Image for Invoice number: 12654 for Supplier: Sutherlands
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <img src={test} alt="invoice image" />
      </Typography>
    </Box>
  );
}
