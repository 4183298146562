//on adding items to the nav bar remember to add to the mobile menu too

import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./styles/navbar.scss";
import Logo from "../../images/logo.png";
import LogoutIcon from "@mui/icons-material/Logout";
import UserContext from "../context/userContext";
import axios from "axios";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import SellIcon from "@mui/icons-material/Sell";
import StoreIcon from "@mui/icons-material/Store";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";

export default function StoreNav(props) {
    const { userData, setUserData } = useContext(UserContext);
    const navigate = useNavigate();
    const [icons, setIcons] = useState(false);
    const [navigation, setNavigation] = useState(false);
    const [satoLabel, setSatoLabel] = useState(false);
    const [siteName, setSiteName] = useState("");
    const [rel, setRel] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        let site = localStorage.getItem("site");

        if (site) {
            setIcons(true);
            setNavigation(true);
            setSiteName(JSON.parse(site));
            if (site.length > 0) {
                //check for sato file
                axios
                    .get(
                        `https://backend.hoagies.co.uk/food/sato/checked/status/${JSON.parse(
                            site
                        )}`
                    )
                    .then((res) => {
                        if (res.data === false) {
                            setSatoLabel(true);
                        } else {
                            setSatoLabel(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        } else {
            navigate("/storezone");
        }
        if (window.innerWidth < 690) {
            setIsMobile(true);
        }
    }, [rel, window.innerWidth]);

    <a
        href="https://nitropos-backend.nitrosolutions.co.uk/hoagiesSato/latest"
        download
        target="_blank"
        onClick={() => {
            updateStatus();
        }}
    >
        {satoLabel ? (
            <div
                style={{
                    backgroundColor: "red",
                    borderRadius: "50%",
                    position: "absolute",
                    padding: "10px",
                    marginTop: "-1%",
                    left: "9%",
                }}
            ></div>
        ) : (
            <></>
        )}
        <ReceiptLongIcon sx={{ color: "#fff" }} fontsize="medium" />
        <span>Sato</span>
    </a>;
    const Logout = () => {
        localStorage.clear();
        navigate("/");
    };

    const handleNavigation = (navTo) => {
        if (navigation) {
            navigate(`/storezone/${navTo}`);
        } else {
            navigate("/storezone");
        }
    };

    const updateStatus = async () => {
        axios
            .get(
                `https://backend.hoagies.co.uk/food/sato/checked/update/${siteName}`
            )
            .then((res) => {
                console.log(res.data);
                setRel(!rel);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <nav
            style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100vw",
            }}
        >
            <img
                src={Logo}
                alt="logo"
                onClick={() => {
                    navigate("/storezone/productManagement");
                }}
            />
            {!isMobile ? (
                <>
                    {icons ? (
                        <div className="icons">
                            <p
                                onClick={() => {
                                    handleNavigation("invoices");
                                }}
                            >
                                <ReceiptIcon
                                    sx={{ color: "#fff" }}
                                    fontsize="medium"
                                />
                                <span>Invoices</span>
                            </p>
                            <p
                                onClick={() => {
                                    handleNavigation("productManagement");
                                }}
                            >
                                <SellIcon
                                    sx={{ color: "#fff" }}
                                    fontsize="medium"
                                />
                                <span>Product Management</span>
                            </p>
                            {/* To be added when staff pages added */}
                            {/* <p
                                onClick={() => {
                                    handleNavigation("staffManagement");
                                }}
                            >
                                <BadgeIcon
                                    sx={{ color: "#fff" }}
                                    fontsize="medium"
                                />
                                <span>Staff Management</span>
                            </p> */}
                            <p
                                onClick={() => {
                                    handleNavigation("storeManagement");
                                }}
                            >
                                <StoreIcon
                                    sx={{ color: "#fff" }}
                                    fontsize="medium"
                                />
                                <span>Store Management</span>
                            </p>
                            <p
                                onClick={() => {
                                    Logout();
                                }}
                            >
                                <LogoutIcon
                                    sx={{ color: "#fff" }}
                                    fontsize="medium"
                                />
                                <span>Logout</span>
                            </p>
                        </div>
                    ) : (
                        <></>
                    )}
                </>
            ) : (
                <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                        <React.Fragment>
                            <MenuOpenIcon
                                {...bindTrigger(popupState)}
                                fontSize="large"
                                sx={{ color: "#fff" }}
                            />
                            <Menu {...bindMenu(popupState)}>
                            <MenuItem
                                    onClick={() =>
                                        handleNavigation("invoices")
                                    }
                                >
                                    <span className="mobile__icons">
                                        <ReceiptIcon />
                                        Invoices
                                    </span>
                                </MenuItem>
                                <MenuItem
                                    onClick={() =>
                                        handleNavigation("ProductManagement")
                                    }
                                >
                                    <span className="mobile__icons">
                                        <SellIcon />
                                        Product Management
                                    </span>
                                </MenuItem>
                                {/* To be added when staff pages added */}
                                {/* <MenuItem
                                    onClick={() =>
                                        handleNavigation("StaffManagement")
                                    }
                                >
                                    <span className="mobile__icons">
                                        <BadgeIcon />
                                        Staff Management
                                    </span>
                                </MenuItem> */}
                                <MenuItem
                                    onClick={() =>
                                        handleNavigation("StoreManagement")
                                    }
                                >
                                    <span className="mobile__icons">
                                        <StoreIcon />
                                        Store Management
                                    </span>
                                </MenuItem>
                                <MenuItem onClick={() => Logout()}>
                                    <span className="mobile__icons">
                                        <LogoutIcon />
                                        Logout
                                    </span>
                                </MenuItem>
                            </Menu>
                        </React.Fragment>
                    )}
                </PopupState>
            )}
        </nav>
    );
}
