import { useState, useEffect } from "react";
import SpeakerNotesOffIcon from "@mui/icons-material/SpeakerNotesOff";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import DescriptionIcon from "@mui/icons-material/Description";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";

const descriptionStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "20vw",
  height: "30vh",
  overflowY: "scroll",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  borderRadius: "1rem",
};

const statusStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40vw",
  height: "45vh",
  overflowY: "scroll",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  borderRadius: "1rem",
};

const notesStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50vw",
    height: "80vh",
    overflowY: "scroll",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    p: 4,
    borderRadius: "1rem",
  };
  

export default function IndStatus(props) {
  const [notes, setNotes] = useState([]);
  const [reload, setReload] = useState(false);
  const [status, setStatus] = useState("");
  const [staff, setStaff] = useState("");
  const [errorAlert, setErrorAlert] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [note, setNote] = useState("");
  const url="https://nitropos-backend.nitrosolutions.co.uk"
  // const url = "http://10.0.0.157:4022"

  const [openDescription, setOpenDescription] = useState(false);
  const handleOpenDescription = () => setOpenDescription(true);
  const handleCloseDescription = () => setOpenDescription(false);

  const [openStatus, setOpenStatus] = useState(false);
  const handleOpenStatus = () => setOpenStatus(true);
  const handleCloseStatus = () => setOpenStatus(false);

  const [openNotes, setOpenNotes] = useState(false);
  const handleOpenNotes = () => setOpenNotes(true);
  const handleCloseNotes = () => setOpenNotes(false);

  useEffect(() => {
    axios
      .get(
        `${url}/maintenance/search?search=${props.d.issueID}`
      )
      .then((res) => {
        setNotes(res.data[0].notes);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.d.issueID, reload]);

  async function statusUpdate(id) {
    document.getElementById("hideButton").style.display = "none";
    if (staff === "" && status === "") {
      setMessage("Please insert your name and new status");
      setErrorAlert(true);
      setTimeout(() => {
        setErrorAlert(false);
      }, 2500);
      document.getElementById("hideButton").style.display = "block";
    } else {
      var data = {
        id: id,
        staffName: staff,
        status: status,
      };
      await axios
        .post(
          `${url}/maintenance/update`,
          data
        )
        .then((res) => {
          setMessage("Status updated");
          setSeverity("success")
          setErrorAlert(true);
          setTimeout(() => {
            setErrorAlert(false);
            props.rel();
            props.result();
            setReload(!reload);
            handleCloseStatus();
            props.success();
          }, 2500);

        })
        .catch((err) => {
          setMessage("Could not update, please try again");
          setSeverity("error")
          setErrorAlert(true);
          setTimeout(() => {
            setErrorAlert(false);
          }, 2500);
          document.getElementById("hideButton").style.display = "block";
        });
    }
  }

  const addNote = async () => {
    document.getElementById("hideButton").style.display = "none";

    if (note == "" || staff == "") {
      setMessage("Please insert your name and new status");
      setErrorAlert(true);
      setTimeout(() => {
        setErrorAlert(false);
      }, 2500);
      document.getElementById("hideButton").style.display = "block";
    } else {
      var noteData = { id: props.d.issueID, note: note, staffName: staff };
      await axios
        .post(
          `${url}/maintenance/addNote`,
          noteData
        )
        .then((res) => {
          setReload(!reload);
          props.rel();
          handleCloseNotes();
          props.success();
        })
        .catch((err) => {
          setMessage("Could not update, please try again");
          setErrorAlert(true);
          setTimeout(() => {
            setErrorAlert(false);
          }, 2500);
          document.getElementById("hideButton").style.display = "block";
        });
    }
  };

  return (
    <>
      <th>{props.d.date}</th>
      <th>{props.d.issue}</th>
      <th>
        {props.d.status}
        <Tooltip title="Update Status">
          <PlaylistAddCheckIcon onClick={handleOpenStatus} />
        </Tooltip>
      </th>
      <th>
        <Tooltip
          title="See Description"
          onClick={() => handleOpenDescription()}
        >
          <DescriptionIcon />
        </Tooltip>
        <Modal
          open={openStatus}
          onClose={handleCloseStatus}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={statusStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <h3 style={{ textAlign: "center", width: "100%" }}>
                Current Status: {props.d.status}
              </h3>
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {errorAlert ? (
                <Alert variant="filled" severity="error">
                  {message}
                </Alert>
              ) : (
                <></>
              )}
              <div style={{ color: "#4d4d4d" }}>
                <label>New Status:</label>
                <br />
                <input
                  name="radStatus"
                  type="radio"
                  value="Investigation Started"
                  onChange={(e) => setStatus(e.target.value)}
                />
                <label>Investigation Started</label>
                <br />
                <input
                  name="radStatus"
                  type="radio"
                  value="Repair Arranged"
                  onChange={(e) => setStatus(e.target.value)}
                />
                <label>Repair Arranged</label>
                <br />
                <input
                  name="radStatus"
                  type="radio"
                  value="Waiting On Parts"
                  onChange={(e) => setStatus(e.target.value)}
                />
                <label>Waiting On Parts</label>
                <br />
                <input
                  name="radStatus"
                  type="radio"
                  value="Parts Arrived"
                  onChange={(e) => setStatus(e.target.value)}
                />
                <label>Parts Arrived</label>
                <br />
                <input
                  name="radStatus"
                  type="radio"
                  value="Repair Complete"
                  onChange={(e) => setStatus(e.target.value)}
                />

                <label>Repair Complete</label>
                <br />
                <label>Staff Name:</label>
                <br />
                <input type="text" onChange={(e) => setStaff(e.target.value)} />
                <br />
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => statusUpdate(props.d.issueID)}
                  className="button"
                  id="hideButton"
                  style={{ marginTop: "3%" }}
                >
                  Send
                </Button>
              </div>
            </Typography>
          </Box>
        </Modal>
      </th>
      <Modal
        open={openDescription}
        onClose={handleCloseDescription}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={descriptionStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <h3 style={{ textAlign: "center" }}>Description</h3>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <p style={{ color: "#4d4d4d" }}>{props.d.description}</p>
          </Typography>
        </Box>
      </Modal>
      {notes.length > 0 ? (
        <th>
          <SpeakerNotesIcon onClick={() => handleOpenNotes()} />
          <Modal
            open={openNotes}
            onClose={handleCloseNotes}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={notesStyle}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                <h3 style={{ textAlign: "center" }}>Notes</h3>
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2, color: "#4d4d4d" }}>
                {errorAlert ? (
                  <Alert variant="filled" severity={severity}>
                    {message}
                  </Alert>
                ) : null}
                {notes.length > 0 ? (
                  <table>
                    <tr style={{ backgroundColor: "#be1a1a", color: "white" }}>
                      <th>Note</th>
                      <th>Added By</th>
                      <th>Date</th>
                    </tr>
                    {notes.map(function (d, i) {
                      return (
                        <tr style={{ border: "1px solid black" }}>
                          <td style={{ border: "1px solid black" }}>
                            {d.note}
                          </td>
                          <td style={{ border: "1px solid black" }}>
                            {d.addedBy}
                          </td>
                          <td style={{ border: "1px solid black" }}>
                            {new Date(d.addedDate).toUTCString()}
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                ) : (
                  <p>No Notes Have Been Added</p>
                )}
                <hr />
                <div>
                  <h4>
                    <u>Add Note</u>
                  </h4>
                  <label>Note: </label>
                  <input
                    style={{ width: "100%", padding: "5px", margin: "10px" }}
                    type="text"
                    placeholder="Enter Note..."
                    onChange={(e) => setNote(e.target.value)}
                    required
                  />
                  <br />
                  <label>Your Name: </label>
                  <input
                    style={{ width: "100%", padding: "5px", margin: "10px" }}
                    type="text"
                    placeholder="Enter Your Name..."
                    onChange={(e) => setStaff(e.target.value)}
                    required
                  />
                  <Button
                    variant="outlined"
                    color="primary"
                    className="button"
                    id="hideButton"
                    style={{ marginTop: "3%" }}
                    onClick={() => addNote()}
                  >
                    Add Note
                  </Button>
                </div>
              </Typography>
            </Box>
          </Modal>
        </th>
      ) : (
        <th>
          <SpeakerNotesOffIcon onClick={() => handleOpenNotes()}/>
        </th>
      )}
    </>
  );
}
