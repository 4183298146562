import { useEffect, useState, useContext } from "react";
import axios from "axios";
import DevNav from "./nav/DevNav";
import "./devZone.scss";
import LoginTest from "../LoginTest";
import userContext from "../context/userContext";

//excel exports
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

export default function FailedTransaction(props) {
    const [failedTransactions, setFailedTransactions] = useState([]);
    const { userData } = useContext(userContext);

    useEffect(async () => {
        axios
            .get(
                "https://nitropos-backend.nitrosolutions.co.uk/eposTransactions"
            )
            .then((res) => {
                // console.log(res.data.length);
                setFailedTransactions(res.data);

                var amount = 0;

                for (let index = 0; index < res.data.length; index++) {
                    const element = res.data[index];
                    amount += parseFloat(element.amount);
                }
                // console.log(amount);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [userData]);

    const exportExcel = () => {
        //export to excel
        const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";

        const excelExportData = [];

        failedTransactions.forEach((obj) => {
            var newObj = {};

            Object.keys(obj).forEach((line) => {
                newObj[line] = obj[line];
            });
            excelExportData.push(newObj);
        });

        const exportToExcel = async () => {
            const ws = XLSX.utils.json_to_sheet(excelExportData);
            const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
            const excelBuffer = XLSX.write(wb, {
                bookType: "xlsx",
                type: "array",
            });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(
                data,
                `Failed Transactions Global Payments` + fileExtension
            );
        };

        exportToExcel();
    };

    return (
        <>
            {userData.user && userData.user.role === "Developer" ? (
                <section className="devZone">
                    <DevNav />
                    <section className="devzone__main">
                        <table>
                            <thead>
                                <tr>
                                    <th>Site</th>
                                    <th>Transaction Id</th>
                                    <th>Device</th>
                                    <th>Date / Time</th>
                                    <th>Barcode</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {failedTransactions.length > 0 ? (
                                    failedTransactions.map(function (d, i) {
                                        return (
                                            <tr>
                                                <td>{d.site}</td>
                                                <td>{d.id}</td>
                                                <td>{d.device}</td>
                                                <td>{d.date}</td>
                                                <td>{d.barcode}</td>
                                                <td>{d.amount}</td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <p>NOTHING TO SHOW</p>
                                )}
                            </tbody>
                        </table>
                        <button onClick={() => exportExcel()}>
                            Export to CSV
                        </button>
                    </section>
                </section>
            ) : (
                <LoginTest />
            )}
        </>
    );
}
