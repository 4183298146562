import "./staffManagement.scss";
import NavBar from "../NavBar";
import { useState, useEffect, Component } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link, Navigate } from "react-router-dom";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import Requests from "./Requests";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import ManagerNav from "../managerZone/ManagerNav";
import WareNav from "../warehouse/WareNav";
import HolCalendar from "../HR/holidays/calendar/HolCalendar";
import InterviewCalendar from "./interview/InterviewCalendar";
import TodayIcon from "@mui/icons-material/Today";
import RotaHome from "./rotas/RotaHome";
import FtgNav from "../FTG/FtgNav";
import VerifiedIcon from "@mui/icons-material/Verified";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import StaffAttendance from "./rotas/StaffAttendance";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import RotaSettings from "./rotas/RotaSettings";
import ReviewRota from "./rotas/review_rotas/review_rota";

const drawerWidth = "12vw";
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div>
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: "pre-wrap" }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
          <h3>An Alert has been sent to IT</h3>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default function HolidaysHome(props) {
  const [role, setRole] = useState("");
  const [user, setUser] = useState("");
  const [value, setValue] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [tabs, setTabs] = useState([]);
  const [managerInfo, setManagerInfo] = useState(null);

  useEffect(() => {
    let userD = localStorage.getItem("userDetails");
    let user = JSON.parse(userD);
    setUser(user.user.user);
    setRole(user.user.role);
    setManagerInfo(user);
    console.log(user);
    let tab = [
      "Rotas",
      "Holiday Approval",
      "Holiday Calendar",
      "Interview Calendar",
    ];
    if (user.user.seniorManagement) {
      tab.push("Rota reviews");
      tab.push("Rota settings");
    }
    setTabs(tab);

    if (window.innerWidth < 690) {
      setIsMobile(true);
    }
  }, []);

  const handleChange = (index) => {
    setValue(index);
  };

  return (
    <main style={{ overflowX: "hidden" }}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            padding: "0",
          }}
        >
          <Toolbar
            sx={{
              width: "100vw",
              backgroundColor: "#2c53a0",
            }}
          >
            {role === "Developer" || role === "Manager" ? (
              <NavBar />
            ) : role === "Store Manager" || role === "Supervisor" ? (
              <ManagerNav />
            ) : role === "Warehouse Manager" ? (
              <WareNav />
            ) : role === "Food Manager" ? (
              <FtgNav />
            ) : null}
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
            },
            ["@media(max-width: 600px)"]: {
              width: "11vw",
            },
          }}
        >
          <Toolbar />
          <Box sx={{ overflow: "hidden", marginTop: "12vh" }}>
            <List>
              {tabs.map((text, index) => (
                <ListItem
                  key={text}
                  disablePadding
                  onClick={() => handleChange(index)}
                >
                  <Link
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        {index === 0 ? (
                          <CalendarMonthIcon />
                        ) : index === 1 ? (
                          <BeachAccessIcon />
                        ) : index === 2 ? (
                          <PermContactCalendarIcon />
                        ) : index === 3 ? (
                          <TodayIcon />
                        ) : index === 4 ? (
                          // <VerifiedIcon />
                          <CoPresentIcon />
                        ) : index === 5 ? (
                          <ManageHistoryIcon />
                        ) : null}
                      </ListItemIcon>
                      {!isMobile ? <ListItemText primary={text} /> : <></>}
                    </ListItemButton>
                  </Link>
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: "10vh" }}>
          <Toolbar />
          {/* {value === 0 ? (
                        <ErrorBoundary>
                            <Requests />
                        </ErrorBoundary>
                    ) : value === 1 ? (
                        <ErrorBoundary>
                            <HolCalendar />
                        </ErrorBoundary>
                    ) : value === 2 ? (
                        <ErrorBoundary>
                            <InterviewCalendar managerInfo={managerInfo} />
                        </ErrorBoundary> */}
          {/* // ) : value === 3 ? (
                    //     <ErrorBoundary>
                    //         <RotaHome managerInfo={managerInfo} />
                    //     </ErrorBoundary>
                    // ) : value === 4 ? (
                    //     <ErrorBoundary>
                    //         <ConfirmRota managerInfo={managerInfo} />
                    //     </ErrorBoundary>
                    // ) : value === 5 ? (
                    //     <ErrorBoundary>
                    //         <StaffAttendance managerInfo={managerInfo} />
                    //     </ErrorBoundary> */}
          {value === 0 ? (
            <ErrorBoundary>
              {managerInfo != null ? (
                <RotaHome managerInfo={managerInfo} />
              ) : null}
            </ErrorBoundary>
          ) : value === 1 ? (
            <ErrorBoundary>
              <Requests />
            </ErrorBoundary>
          ) : value === 2 ? (
            <ErrorBoundary>
              <HolCalendar />
            </ErrorBoundary>
          ) : value === 3 ? (
            <ErrorBoundary>
              {managerInfo != null ? (
                <InterviewCalendar managerInfo={managerInfo} />
              ) : null}
            </ErrorBoundary>
          ) : value === 4 ? (
            <ErrorBoundary>
              {managerInfo != null ? (
                <ReviewRota managerInfo={managerInfo} />
              ) : null}
            </ErrorBoundary>
          ): value === 5 ? (
            <ErrorBoundary>
              {managerInfo != null ? (
                <RotaSettings managerInfo={managerInfo} />
              ) : null}
            </ErrorBoundary>
          ): null}
        </Box>
      </Box>
    </main>
  );
}
