import { useEffect, useState } from "react";
import axios from "axios";
import CCTVIndividual from "./CCTVIndividual";

const CCTVDetails = ({ siteName, rel, statusList, site }) => {
    const url = process.env.REACT_APP_ANPR_URL;
    const [requests, setRequests] = useState([]);

    useEffect(() => {
        axios
            .get(`${url}/cctv-system/submissions/details/site/${site}`)
            .then((res) => {
                setRequests(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [rel]);

    return (
        <main className="cctv_cards">
            <h3>Open CCTV requests for {siteName}</h3>
            {requests.length > 0 ? (
                <table>
                    <thead>
                        <tr>
                            <th>Date/Time</th>
                            <th>Requester</th>
                            <th>Reason</th>
                            <th>Status</th>
                            <th>Notes</th>
                        </tr>
                    </thead>
                    <tbody>
                        {requests.map((request, i) => {
                            return (
                                <CCTVIndividual
                                    key={i}
                                    request={request}
                                    rel={rel}
                                    statusList={statusList}
                                />
                            );
                        })}
                    </tbody>
                </table>
            ) : (
                <p className="no_cctv">No CCTV Requests</p>
            )}
        </main>
    );
};

export default CCTVDetails;
