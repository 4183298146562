import { useState, useEffect } from "react"

export default function IndProduct(props) {
    const [product, setProduct] = useState(props.product)
    const [negative, setNegative] = useState("")

    useEffect(() => {
        if (props.negative === "negative"){
            setNegative("negative")
        }
    })

    return(
        <tr className={negative}>
        <th>{product.name}</th>
        <th>{product.stock}</th>
        <th>{product.sales}</th>
        <th>{product.amountNeeded}</th>
        <th>
          <input type="number" onChange={(e) => props.handleChange(e.target.value, props.index)}/>
        </th>
      </tr>

    )
}