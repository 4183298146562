//MUI
import { Box } from "@mui/material";
import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";
import RefreshIcon from "@mui/icons-material/Refresh";
//Styling
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    height: "60vh",
    maxHeight: "60vh",
};
//Inferfaces
interface Props {
    setOpenHelp: any;
}

export default function CCTVAdminHelp({ setOpenHelp }: Props) {
    return (
        <Box className="cctv-admin-help-modal" sx={style}>
            <h1>How to use</h1>
            <section className="help-main-content">
                <h2>Overview</h2>
                <p>
                    This page shows the last 100 CCTV requests, be it for theft
                    at a store, a drive off, or a No Means of Payment (NMOP). To
                    view older requests you gave the ability to search and
                    filter according to find the relevant request.
                </p>
                <h2>Accessing request information</h2>
                <p>
                    To access more information about a request, click on the{" "}
                    <span>
                        <InfoIcon color="primary" fontSize="small" />
                    </span>{" "}
                    icon on the relevant row.
                </p>
                <h2>Information Pop-up</h2>
                <p>
                    Within the information pop-up for the request you will see
                    general informtion.
                    <br />
                    <br />
                    Here you can also view the store and dev teams notes, you
                    can view you own admin notes, you can creat a new admin
                    note, you can create or update a crime referenece number for
                    a request. <br />
                    <br />
                    If the request is for a NMOP, the you
                    will also be able to view the customer information here.
                </p>
                <h2>Resetting to all requests</h2>
                <p>
                    To reset to the last 100 request, use the button with the{" "}
                    <span>
                        <RefreshIcon fontSize="small" color="warning" />
                    </span>{" "}
                    icon.
                </p>
                <h2>Filtering and searching for requests</h2>
                <p>
                    You can filter and search requests using the fields at the
                    top of the page.
                    <br />
                    <br />
                    You can filter by type of request (for example if it's a
                    CCTV or a Drive off), by site the request was submitted for
                    or by what status the request is currently at.
                    <br />
                    <br />
                    You can search by car registration, by crime reference
                    number and by a date range.
                    <br />
                    <br />
                    Please note that using the filters will clear any searches
                    and vice versa. To reset to all requests read how to reset
                    requests above.
                </p>
                <h2>Changing status of request</h2>
                <p>
                    You can change the status of the request by clicking the{" "}
                    <span>
                        <EditIcon fontSize="small" color="success" />
                    </span>{" "}
                    icon on the status cell of the request you wish to change
                    the status for.
                </p>
                <h2>Unactioned Requests</h2>
                <p>
                    Any requests which have not been progressed in 7 or more
                    days will appear in the following color:{" "}
                    <div className="unactioned-color"></div>
                </p>
                <h2>Add New Request</h2>
                <p>
                    You can submit a new CCTV request using the button with icon{" "}
                    <span>
                        <AddIcon fontSize="small" color="primary" />
                    </span>
                </p>{" "}
            </section>
            <Button
                variant="contained"
                color="error"
                onClick={() => setOpenHelp(false)}
            >
                close
            </Button>
        </Box>
    );
}
