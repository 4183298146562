import "./wastage.scss";
import { useState, useEffect } from "react";
import axios from "axios";
import { Button } from "@mui/material";
import {
    getAllEposProducts,
    getAllHoagiesProducts,
    getAllTubbeesProducts,
    getTopWasted,
} from "../../../FTG/hoagiesUtils/Api";
import WastageComp from "./WastageComp";
import FreshWastage from "./FreshWastage";

export default function WastageV2() {
    const [type, setType] = useState("");
    const [products, setProducts] = useState([]);

    const updateType = async (selection) => {
        console.log(`Looking for ${selection} products`);
        if (selection === "search") {
            await getTopWasted()
                .then((topProducts) => {
                    setProducts(topProducts);
                })
                .catch((err) => {
                    console.log(`Could not get top wasted items: ${err}`);
                });
        } else if (selection === "tubbees") {
            await getAllTubbeesProducts()
                .then((tubbeesProducts) => {
                    console.log(tubbeesProducts);
                    setProducts(tubbeesProducts);
                })
                .catch((err) => {
                    console.log(`Could not get Tubbees products ${err}`);
                });
        } else if (selection === "hoagies") {
            await getAllHoagiesProducts()
                .then((hoagiesProducts) => {
                    setProducts(hoagiesProducts);
                })
                .catch((err) => {
                    console.log(`Could not get Hoagies products: ${err}`);
                });
        } else if (selection === "") {
            setProducts([]);
        }
        setType(selection);
    };

    const handleBack = () => {
        setType("");
        setProducts([]);
    };

    return (
        <section className="wastage_main">
            {type === "" ? (
                <section className="wastage_select">
                    <h1>Damaged &amp; wasted goods form</h1>
                    <Button
                        variant="contained"
                        color="success"
                        className="select_buttons"
                        onClick={() => updateType("barcode")}
                    >
                        Search by barcode
                    </Button>
                    <Button
                        variant="contained"
                        color="success"
                        className="select_buttons"
                        onClick={() => updateType("search")}
                    >
                        Search by name
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ backgroundColor: "#f2a2c4" }}
                        className="select_buttons"
                        onClick={() => updateType("tubbees")}
                    >
                        Tubbees Products
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ backgroundColor: "#F9D505", color: "#000" }}
                        className="select_buttons"
                        onClick={() => updateType("hoagies")}
                    >
                        Hoagies
                    </Button>
                </section>
            ) : type === "hoagies" ? (
                <FreshWastage type={type} handleBack={handleBack} />
            ) : (
                <WastageComp
                    type={type}
                    products={products}
                    handleBack={handleBack}
                />
            )}
        </section>
    );
}
