import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60vw",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
    maxHeight: "70vh",
    overflowY: "scroll",
    "@media(max-width: 690px)": {
        width: "90vw",
        maxHeight: "90vh",
        p: 1,
    },
};

const DescriptionModal = ({
    person,
    incident,
    images,
    items,
    handleCloseDescription,
}) => {
   
    return (
        <Box sx={style} className="all_modal">
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Description of Incident
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <table className="modal__tables" style={{ width: "100%" }}>
                    <thead
                        style={{ backgroundColor: "#2c53a0", color: "#fff" }}
                    >
                        <tr>
                            <th>Person(s) details</th>
                            <th>Incident details</th>
                            <th>Stolen items</th>
                        </tr>
                    </thead>
                    <tbody style={{ border: "1px solid #4d4d4d" }}>
                        <tr>
                            <td>{person ? person : <h3>No description</h3>}</td>
                            <td
                                style={{
                                    wordWrap: "break-word",
                                }}
                            >
                                {incident}
                            </td>
                            <td>{items ? items : <h3>No items</h3>}</td>
                        </tr>
                    </tbody>
                </table>
                <Typography
                    id="notes_header"
                    variant="h6"
                    component="h2"
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                        flexWrap: "wrap",
                    }}
                >
                    {images.map((image, i) => {
                        return (
                            <img
                                src={image}
                                width="200"
                                key={i}
                                style={{ margin: "2px" }}
                            />
                        );
                    })}
                </Typography>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-evely",
                        marginTop: "3%",
                    }}
                >
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => handleCloseDescription(false)}
                    >
                        Close
                    </Button>
                </div>
            </Typography>
        </Box>
    );
};

export default DescriptionModal;
