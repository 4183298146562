import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import UndoIcon from "@mui/icons-material/Undo";
import TinyLoder from "../../../animations/TinyLoader";
import axios from "axios";
import Alert from "@mui/material/Alert";
import IndvWastage from "./IndvWastage";
import imageCompression from "browser-image-compression";
import SendIcon from "@mui/icons-material/Send";
import Modal from "@mui/material/Modal";
import CheckItems from "./modals/CheckItems";
import MobileItem from "./MobileItem";

export default function WastageComp(props) {
    const type = props.type;
    const [products, setProducts] = useState([]);
    const url = "https://nitropos-backend.nitrosolutions.co.uk";
    //   const url = "http://10.0.0.34:4022";
    const [showTiny, setShowTiny] = useState(false);
    const [disableNext, setDisableNext] = useState(false);
    //alerts
    const [severity, setSeverity] = useState("");
    const [message, setMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [showSubmitAlert, setShowSubmitAlert] = useState(false);
    //holds wastage items
    const [wastageItems, setWastageItems] = useState([]);
    //modals
    const [openCheckItems, setOpenCheckItems] = useState(false);
    const handleOpenCheckItems = () => {
        let open = true;
        for (let p = 0; p < wastageItems.length; p++) {
            setDisableNext(true);
            if (
                wastageItems[p].wasted >= 0.25 &&
                wastageItems[p].reason != "" &&
                wastageItems[p].reason != "--Select a reason--"
            ) {
                setDisableNext(false);
            } else {
                open = false;
                console.log(wastageItems[p].wasted);
                setMessage(
                    `Item ${wastageItems[p].name} is missing image, quantity or reason`
                );
                setSeverity("warning");
                setShowSubmitAlert(true);
                setTimeout(() => {
                    setShowSubmitAlert(false);

                    setDisableNext(false);
                }, 3000);
            }
        }
        setOpenCheckItems(open);
    };
    const handleCloseCheckItems = () => setOpenCheckItems(false);

    useEffect(() => {
        setProducts(props.products);
    }, [type, products]);

    //searches the product
    const searchProduct = async (item) => {
        setShowTiny(true);
        //only if they have selected barcode
        if (props.type === "barcode") {
            setProducts([]);
            if (item.length > 4) {
                axios
                    .post(`${url}/claims/products/barcode`, { barcode: item })
                    .then((res) => {
                        console.log(res.data);
                        let result = res.data;
                        //if there is any products
                        if (result.length > 0 && result.length < 2) {
                            setProducts(result);
                            for (let p = 0; p < result.length; p++) {
                                let product = result[p];

                                //check if the product exists on the wasted items
                                let findIndex = wastageItems.findIndex(
                                    (a) => a.eposId === product.eposId
                                );

                                if (findIndex < 0) {
                                    let barcodes = [];
                                    let bcode;
                                    if (product.barcode != null) {
                                        barcodes = product.barcode.split(",");
                                        bcode =
                                            String(
                                                barcodes[barcodes.length - 1]
                                            ) + "...";
                                    } else {
                                        bcode = "No Barcode";
                                    }

                                    setWastageItems((wastageItems) => [
                                        ...wastageItems,
                                        {
                                            name: product.name,
                                            barcode: bcode,
                                            eposId: product.eposId,
                                            wasted: 0,
                                            supplierId: product.supplierId,
                                            supplierName: product.supplierName,
                                            reason: "",
                                            image: "",
                                            fixable: null,
                                            fresh: product.fresh,
                                        },
                                    ]);
                                    console.log(wastageItems);
                                } else if (findIndex >= 0) {
                                    setShowTiny(false);
                                    setMessage(
                                        "This product has already been added, please change wasted quantity"
                                    );
                                    setSeverity("warning");
                                    setShowAlert(true);
                                    setTimeout(() => {
                                        setShowAlert(false);
                                    }, 3000);
                                }
                                document.getElementById("search_input").value =
                                    "";
                                setShowTiny(false);
                            }
                        } else if (result.length === 0) {
                            setShowTiny(false);
                            document.getElementById(
                                "back_button"
                            ).style.display = "none";
                            setMessage(
                                "Could not find any products with that barcode"
                            );
                            setSeverity("warning");
                            setShowAlert(true);
                            setTimeout(() => {
                                setShowAlert(false);
                                document.getElementById(
                                    "back_button"
                                ).style.display = "flex";
                            }, 3000);
                        }
                    })
                    .catch((err) => {
                        setShowTiny(false);
                        console.log(`Could not get the products: ${err}`);
                        document.getElementById("back_button").style.display =
                            "none";
                        setMessage("Something went wrong, please try again");
                        setSeverity("error");
                        setShowAlert(true);
                        setTimeout(() => {
                            setShowAlert(false);
                            document.getElementById(
                                "back_button"
                            ).style.display = "flex";
                        }, 3000);
                    });
            }
        } else if (props.type === "search") {
            // if they have selected name
            let result = false;
            for (let p = 0; p < products.length; p++) {
                if (products[p].name === item) {
                    let findIndex = wastageItems.findIndex(
                        (a) => a.eposId === products[p].eposId
                    );

                    if (findIndex < 0) {
                        setWastageItems((wasted) => [
                            ...wasted,
                            {
                                name: products[p].name,
                                barcode: products[p].barcode,
                                eposId: products[p].eposId,
                                wasted: 0,
                                supplierId: products[p].supplierId,
                                supplierName: products[p].supplierName,
                                reason: "",
                                image: "",
                                fixable: null,
                                fresh: products[p].fresh,
                            },
                        ]);
                        setShowTiny(false);
                        result = true;
                    } else if (findIndex >= 0) {
                        setShowTiny(false);

                        setDisableNext(true);
                        setMessage(
                            "This product has already been added, please change wasted quantity"
                        );
                        setSeverity("warning");
                        setShowAlert(true);
                        setTimeout(() => {
                            setShowAlert(false);

                            setDisableNext(false);
                            document.getElementById("search_input").value = "";
                        }, 3000);
                        result = true;
                    }
                }
                // if the product is not on the top 20
            }
            if (!result) {
                axios
                    .post(`${url}/claims/products/name`, { name: item })
                    .then((res) => {
                        setShowTiny(false);
                        if (res.data.length === 1) {
                            let findIndex = wastageItems.findIndex(
                                (a) => a.eposId === res.data[0].eposId
                            );
                            if (findIndex < 0) {
                                setWastageItems((wasted) => [
                                    ...wasted,
                                    {
                                        name: res.data[0].name,
                                        barcode: res.data[0].barcode,
                                        eposId: res.data[0].eposId,
                                        wasted: 0,
                                        supplierId: res.data[0].supplierId,
                                        supplierName: res.data[0].supplierName,
                                        reason: "",
                                        image: "",
                                        fixable: null,
                                        fresh: res.data[0].fresh,
                                    },
                                ]);
                            } else if (findIndex >= 0) {
                                setDisableNext(true);
                                setMessage(
                                    "This product has already been added, please change wasted quantity"
                                );
                                setSeverity("warning");
                                setShowAlert(true);
                                setTimeout(() => {
                                    setShowAlert(false);

                                    setDisableNext(false);
                                    document.getElementById(
                                        "search_input"
                                    ).value = "";
                                }, 3000);
                            }
                        } else {
                            setDisableNext(true);
                            setMessage(
                                "There is more than one product with this name, please search for the exact epos name"
                            );
                            setSeverity("warning");
                            setShowAlert(true);
                            setTimeout(() => {
                                setShowAlert(false);
                                setDisableNext(false);
                                document.getElementById("search_input").value =
                                    "";
                            }, 3000);
                        }
                    })
                    .catch((err) => {
                        setShowTiny(false);
                        console.log(`Could not find the product: ${err}`);

                        setDisableNext(true);
                        setMessage(`Could not find the product: ${err}`);
                        setSeverity("error");
                        setShowAlert(true);
                        setTimeout(() => {
                            setShowAlert(false);

                            setDisableNext(false);
                            document.getElementById("search_input").value = "";
                        }, 3000);
                    });
            }
        } else {
            if (item.length > 4) {
                for (let p = 0; p < products.length; p++) {
                    if (
                        products[p].barcode == item ||
                        products[p].name.trim() == item
                    ) {
                        let barcodes = [];
                        let barcode;
                        if (products[p].barcode != null) {
                            barcodes = products[p].barcode.split(",");
                            barcode =
                                String(barcodes[barcodes.length - 1]) + "...";
                        } else {
                            barcode = "No Barcode";
                        }

                        //check if the product exists on the wasted items
                        let findIndex = wastageItems.findIndex(
                            (a) => a.eposId === products[p].eposId
                        );

                        if (findIndex < 0) {
                            setWastageItems((wasted) => [
                                ...wasted,
                                {
                                    name: products[p].name,
                                    barcode: barcode,
                                    eposId: products[p].eposId,
                                    wasted: 0,
                                    supplierId: products[p].supplierId,
                                    supplierName: products[p].supplierName,
                                    reason: "",
                                    image: "",
                                    fixable: null,
                                    fresh: products[p].fresh,
                                },
                            ]);
                        } else if (findIndex >= 0) {
                            setShowTiny(false);

                            setDisableNext(true);
                            setMessage(
                                "This product has already been added, please change wasted quantity"
                            );
                            setSeverity("warning");
                            setShowAlert(true);
                            setTimeout(() => {
                                setShowAlert(false);

                                setDisableNext(false);
                                document.getElementById("search_input").value =
                                    "";
                            }, 3000);
                        }

                        document.getElementById("search_input").value = "";
                        setShowTiny(false);
                    }
                }
            } else if (item == "") {
                setShowTiny(false);
                document.getElementById("back_button").style.display = "none";
                setMessage("You need to search a product");
                setSeverity("warning");
                setShowAlert(true);
                setTimeout(() => {
                    setShowAlert(false);
                    document.getElementById("back_button").style.display =
                        "flex";
                }, 3000);
            }
        }
    };

    //   WASTED ITEMS ARRAY FUNCTIONS PASSED ON PROPS
    const handleWastedUnits = (units, index) => {
        if (units < 0) {
            document.getElementById("back_button").style.display = "none";
            setMessage("Quantity cannot be smaller than 0");
            setSeverity("warning");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
                document.getElementById("back_button").style.display = "flex";
                document.getElementById("units_input").value = "";
                wastageItems[index].wasted = 0;
            }, 3000);
        } else {
            wastageItems[index].wasted = parseInt(units);
        }
    };

    const handleReason = (reason, index) => {
        if (reason === "--Select a reason--") {
            document.getElementById("back_button").style.display = "none";
            setMessage("Please select a reason");
            setSeverity("warning");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
                document.getElementById("back_button").style.display = "flex";
            }, 3000);
        } else {
            wastageItems[index].reason = reason;
        }
    };

    const addingImages = async (image, index) => {
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 100,
        };
        const compressedFile = await imageCompression(image, options);
        const fileReader = new FileReader();
        fileReader.readAsDataURL(compressedFile);
        fileReader.onload = () => {
            wastageItems[index].image = fileReader.result;
        };
    };

    const handleRemoveWastage = (i) => {
        let removeItem = wastageItems.filter((val, index) => index !== i);
        setWastageItems(removeItem);
    };
    // END OF WASTED ITEMS ARRAY FUNCTIONS

    return (
        <section className="wastage">
            <h1>Wasted goods from {type}</h1>
            <section className="search_section">
                <input
                    list="products_ID"
                    placeholder="Select Product"
                    id="search_input"
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            searchProduct(e.target.value);
                        }
                    }}
                />
                <datalist id="products_ID">
                    {products.map(function (d, i) {
                        return (
                            <option value={d.barcode} id={i} key={i}>
                                {String(d.name)}
                            </option>
                        );
                    })}
                </datalist>
                {showTiny ? (
                    <TinyLoder />
                ) : showAlert ? (
                    <Alert severity={severity} sx={{ marginTop: "2%" }}>
                        {message}
                    </Alert>
                ) : null}
            </section>
            {wastageItems.length > 0 ? (
                <table>
                    <thead>
                        <tr>
                            <th>Barcode</th>
                            <th>Product Name</th>
                            <th>Supplier</th>
                            <th>Units Wasted/Damaged</th>
                            <th>Reason</th>
                            <th>Fresh</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {wastageItems.map((product, i) => {
                            return (
                                <IndvWastage
                                    product={product}
                                    i={i}
                                    handleWastedUnits={handleWastedUnits}
                                    handleReason={handleReason}
                                    addingImages={addingImages}
                                    handleRemoveWastage={handleRemoveWastage}
                                />
                            );
                        })}
                    </tbody>
                </table>
            ) : null}
            {wastageItems.length === 0 ? (
                <Button
                    variant="contained"
                    onClick={props.handleBack}
                    color="error"
                    startIcon={<UndoIcon />}
                    sx={{ margin: "2% auto" }}
                    id="back_button"
                >
                    Back
                </Button>
            ) : (
                <Button
                    variant="contained"
                    color="success"
                    sx={{ margin: "2% auto" }}
                    endIcon={<SendIcon />}
                    id="next_button"
                    onClick={handleOpenCheckItems}
                    disable={disableNext}
                >
                    Next
                </Button>
            )}
            {showSubmitAlert ? (
                <Alert severity={severity} sx={{ marginTop: "2%" }}>
                    {message}
                </Alert>
            ) : null}
            {/* MODALS */}
            <Modal
                open={openCheckItems}
                // onClose={handleCloseCheckItems}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <CheckItems
                    wastedItems={wastageItems}
                    handleClose={handleCloseCheckItems}
                    type={props.type}
                />
            </Modal>
        </section>
    );
}
