import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import "./posExplorer.scss";
import { Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Alert } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";

export default function Fuel({
    site,
    selectedShift,
    selectedShiftStart,
    selectedShiftEnd,
}) {
    const url = "https://nitropos-backend.nitrosolutions.co.uk";
    // const url = "http://10.0.0.34:4022";
    const [fuelData, setFuelData] = useState([]);
    const [allFuelData, setAllFuelData] = useState([]);
    const [selectedPump, setSelectedPump] = useState("");
    const [priceRangeStart, setPriceRangeStart] = useState("");
    const [priceRangeEnd, setPriceRangeEnd] = useState("");

    //ALERTS
    const [alert, setAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("");

    useEffect(() => {
        let data = {
            siteId: site,
            startDate: selectedShiftStart,
            endDate: selectedShiftEnd,
        };

        axios
            .post(`${url}/posExplorer/prism/transactions`, data)
            .then((res) => {
                setFuelData(res.data);
                setAllFuelData(res.data);
                if (res.data.length === 0) {
                    setAlertMessage("No fuel transactions found");
                    setAlertSeverity("warning");
                    setAlert(true);
                }
            })
            .catch((err) => {
                console.log(err);
                setAlertMessage("Something went wrong - Please try again");
                setAlertSeverity("error");
                setAlert(true);
            });
    }, [selectedShift]);

    //FILTER FUNCTIONS
    const handlePumpSearch = () => {
        if (selectedPump >= 1 && selectedPump <= 8) {
            let filteredData = allFuelData.filter((f, i) => {
                return f.FuelItems[0].pumpNo == selectedPump;
            });

            setFuelData(filteredData);
        } else {
            setFuelData(allFuelData);
            setSelectedPump("");
            setAlertSeverity("warning");
            setAlertMessage("Pump Number must be between 1 and 8");
            setAlert(true);
            setTimeout(() => {
                setAlert(false);
                setAlertMessage("");
                setAlertSeverity("");
            }, 3500);
        }
    };

    const handleTypeFilter = (type) => {
        if (type === "all" || type === "--Filter by Fuel Type--") {
            setFuelData(allFuelData);
        } else {
            let filteredData = allFuelData.filter((f, i) => {
                let toLower = f.FuelItems[0].fuelType.toLowerCase();
                return toLower.includes(type);
            });

            setFuelData(filteredData);
        }
    };

    const handlePriceFilter = () => {
        if (priceRangeEnd === "" || priceRangeStart === "") {
            setFuelData(allFuelData);
            setPriceRangeStart("");
            setPriceRangeEnd("");
            setSelectedPump("");
            setAlertSeverity("warning");
            setAlertMessage("Both prices must be entered");
            setAlert(true);
            setTimeout(() => {
                setAlert(false);
                setAlertMessage("");
                setAlertSeverity("");
            }, 3500);
        } else if (priceRangeEnd < priceRangeStart) {
            setFuelData(allFuelData);
            setPriceRangeStart("");
            setPriceRangeEnd("");
            setSelectedPump("");
            setAlertSeverity("warning");
            setAlertMessage(
                "Price range end can not be less than price range start"
            );
            setAlert(true);
            setTimeout(() => {
                setAlert(false);
                setAlertMessage("");
                setAlertSeverity("");
            }, 3500);
        } else {
            let filteredData = allFuelData.filter((f, i) => {
                let parsedAmount = f.totalAmount.replace("£", "");
                parsedAmount = parseFloat(parsedAmount);

                return (
                    parsedAmount >= priceRangeStart &&
                    parsedAmount <= priceRangeEnd
                );
            });
            setFuelData(filteredData);
        }
    };

    return (
        <div>
            {" "}
            <div id="searchBar">
                <div>
                    <h2 style={{ color: "#ffff" }}>FUEL</h2>
                </div>
            </div>
            <div className="filters">
                <select
                    className="type_filter"
                    onChange={(e) => {
                        handleTypeFilter(e.target.value);
                    }}
                >
                    <option>--Filter by Fuel Type--</option>
                    <option value="all">All</option>
                    <option value="unleaded">Unleaded</option>
                    <option value="diesel">Diesel</option>
                </select>
                <div className="pump_filter">
                    <input
                        type="number"
                        value={selectedPump}
                        onChange={(e) => setSelectedPump(e.target.value)}
                        placeholder="Search by Pump Number"
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                handlePumpSearch();
                            }
                        }}
                    />
                    <Button variant="contained" onClick={handlePumpSearch}>
                        <SearchIcon />
                    </Button>
                </div>
                <div className="price_filter">
                    <input
                        type="number"
                        value={priceRangeStart}
                        onChange={(e) => setPriceRangeStart(e.target.value)}
                        placeholder="Price range start"
                    />
                    <input
                        type="number"
                        value={priceRangeEnd}
                        onChange={(e) => setPriceRangeEnd(e.target.value)}
                        placeholder="Price range end"
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                handlePriceFilter();
                            }
                        }}
                    />
                    <Button variant="contained" onClick={handlePriceFilter}>
                        <SearchIcon />
                    </Button>
                </div>
            </div>
            <div className="alert_area">
                {alert ? (
                    <Alert severity={alertSeverity}>{alertMessage}</Alert>
                ) : null}
            </div>
            {fuelData.length > 0 ? (
                fuelData.map((d, i) => {
                    let fuelItems = d.FuelItems;
                    let fuelTender = d.FuelTender;
                    let saleItems = d.SaleItems;
                    return (
                        <Accordion className="fuel_table">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                className="fuel_accordian"
                            >
                                <p>
                                    Date/Time:&nbsp;
                                    <span>
                                        {" "}
                                        {moment(d.startTime).format(
                                            "DD-MM-YYYY HH:mm"
                                        )}
                                    </span>
                                </p>
                                <p>
                                    Total Amount:&nbsp;{" "}
                                    <span> {d.totalAmount}</span>
                                </p>
                                <p
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    Suspended:{" "}
                                    <span>
                                        {d.suspended && d.unSuspendedId ? (
                                            <CheckBoxIcon color="success" />
                                        ) : (
                                            <DisabledByDefaultIcon color="error" />
                                        )}
                                    </span>{" "}
                                    Voided:{" "}
                                    <span>
                                        {d.voided ? (
                                            <CheckBoxIcon color="success" />
                                        ) : (
                                            <DisabledByDefaultIcon color="error" />
                                        )}
                                    </span>
                                </p>
                            </AccordionSummary>
                            <AccordionDetails>
                                {/* Fuel Items Table */}
                                <h3>Fuel Info</h3>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Amount</th>
                                            <th>Litres</th>
                                            <th>Pump/Type</th>
                                            <th>Current Fuel Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fuelItems &&
                                            fuelItems.map((d3, i3) => {
                                                return (
                                                    <tr>
                                                        <td>{d3.fuelValue}</td>
                                                        <td>
                                                            {d3.fuelQuantity}
                                                        </td>
                                                        <td>{d3.fuelType}</td>
                                                        <td>
                                                            {
                                                                d3.fuelCurrentPrice
                                                            }
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                                {/* Fuel Tender Table */}
                                <h3>Tender Info</h3>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Reg</th>
                                            <th>Tender Type</th>
                                            <th>Tender Amount (£)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fuelTender &&
                                            fuelTender.map((d3, i3) => {
                                                return (
                                                    <tr>
                                                        <td>
                                                            {d3.reg
                                                                ? d3.reg
                                                                : "No reg"}
                                                        </td>
                                                        <td>{d3.tenderType}</td>
                                                        <td>{d3.amount}</td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                                {/* Sales Items Table */}
                                {saleItems[0] != null ? (
                                    <>
                                        <h3>Sales Info</h3>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Barcode</th>
                                                    <th>quantity</th>
                                                    <th>Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {saleItems.map((d3, i3) => {
                                                    return (
                                                        <tr>
                                                            <td>{d3.name}</td>
                                                            <td>
                                                                {d3.barcode}
                                                            </td>
                                                            <td>
                                                                {d3.quantity}
                                                            </td>
                                                            <td>{d3.amount}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </>
                                ) : null}
                            </AccordionDetails>
                        </Accordion>
                    );
                })
            ) : (
                <h3 style={{ color: "black", textAlign: "center" }}>
                    No records
                </h3>
            )}
        </div>
    );
}
