import "./discrepancies.scss";
import { useState, useEffect } from "react";

export default function ListSide(props) {
  const [discrepancies, setDiscrepancies] = useState(props.discrepData);
  const [storeCount, setStoreCount] = useState(0);

  useEffect(() => {
    setDiscrepancies(props.discrepData);
    setStoreCount(props.storeCount);
  });
  return (
    <main className="discrepancy__list">
      <h3 className="store__count">
        Active Store Investigations : {storeCount}
      </h3>

      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Amount</th>
            <th>Source</th>
          </tr>
        </thead>
        <tbody>
          {discrepancies.map(function (d, i) {
            return (
              <tr onClick={() => props.handleDiscrepancy(d)}>
                <th>{new Date(d.transactionDate).toLocaleDateString()}</th>
                <th>£{parseFloat(d.cost).toFixed(2)}</th>
                <th>{d.sourceName}</th>
              </tr>
            );
          })}
        </tbody>
      </table>
    </main>
  );
}
