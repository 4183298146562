import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";
import "../styles/allergensModal.scss";
import ChildModal from "./ChildModal";
import ParentModal from "./ParentModal";
import Alert from "@mui/material/Alert";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  height: "60vh",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
};

export default function AllergenModal(props) {
  const [allergenList, setAllergenList] = useState(props.allergens);
  const [children, setChildren] = useState(props.children);
  const [allergens, setAllergens] = useState(props.productAllergens);
  const [product, setProduct] = useState(props.product);
  //child allergens for parent component
  const [childAllergens, setChildAllergens] = useState([]);
  //alerts
  const [showAlert, setShowAlert] = useState(false);
  const [showTick, setShowTick] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  useEffect(() => {
    children.forEach((child) => {
      if (child.allergens) {
        for (let i = 0; i < child.allergens.length; i++) {
          childAllergens.push(child.allergens[i].allergenId)
        }
      }
    });
  }, []);

  const handleAlert = (severity, message) => {
    setSeverity(severity)
    setMessage(message)
    setShowAlert(true)
    setTimeout(() => {
        setSeverity("")
        setMessage("")
        setShowAlert(false)    
    },2000)
  }

  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        See allergens in product
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <section className="allergens__modal">
          <table>
            <thead>
              <tr>
                <th>{product.name}</th>
                {allergenList.map(function (allergen, idx) {
                  return <th>{allergen.name}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {children.map(function (child, idx) {
                return (
                  <ChildModal child={child} allergenList={props.allergens} />
                );
              })}
              <ParentModal
                product={product}
                allergenList={props.allergens}
                childAllergens={childAllergens}
                handleAlert={handleAlert}
              />
            </tbody>
          </table>
          {showAlert ? (
            <Alert severity={severity} sx={{ marginTop: "2%" }}>
              {message}
            </Alert>
          ) : (
            <></>
          )}
        </section>
      </Typography>
    </Box>
  );
}
