//use for all routes that all HO users can access

import { Navigate } from "react-router-dom";

const HoProtect = ({ children }) => {
  let userD = localStorage.getItem("userDetails");
  if (!userD) {
    window.location.replace("/");
  }
  let user = JSON.parse(userD);
  if (
    user.user.role !== "Developer" &&
    user.user.role !== "Manager" &&
    user.user.role !== "Admin" &&
    user.user.role !== "HR"
  ) {
    return <Navigate to="/not-allowed" replace />;
  }
  return children;
};

export { HoProtect };
