import { useEffect, useState } from "react";
import axios from "axios";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import Modal from "@mui/material/Modal";
import PendingPO from "./modals/PendingPO";

export default function Pending() {
  const [user, setUser] = useState("");
  const [invoices, setInvoices] = useState([]);
  const [reload, setReload] = useState(false);
  const [heading, setHeading] = useState("");
  const [invoice, setInvoice] = useState("");
  const [site, setSite] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = (id, invoiceNo, site) => {
    setHeading(id)
    setInvoice(invoiceNo)
    setSite(site)
    setOpen(true)
  };
  const handleClose = () => setOpen(false);

  useEffect(() => {
    let getUser = sessionStorage.getItem("user");
    setUser(getUser);

    axios
      .get("https://nitropos-backend.nitrosolutions.co.uk/purchaseOrder/pending")
      .then((res) => {
        // console.log(res.data);
        setInvoices(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reload]);

  const rel = () => {
    setReload(!reload);
  };

  return (
    <main className="pending__po">
      <h1>Pending PO's</h1>
      {invoices.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>PO Number/Order Number</th>
              <th>Site</th>
              <th>Date</th>
              <th>Supplier</th>
              <th>View Order</th>
            </tr>
          </thead>
          <tbody>
            {invoices.map(function (d, i) {
              return (
                <tr>
                  <td>{d.invoiceNumber}</td>
                  <td>{d.siteName}</td>
                  <td>{new Date(d.dateCreated).toLocaleDateString("en-GB")}</td>
                  <td>{d.supplierName}</td>
                  <td>
                    <NewspaperIcon
                      className="icon"
                      onClick={() => handleOpen(d.headingId, d.invoiceNumber, d.siteName)}
                      rel={rel}
                    />
                  </td>
                </tr>
              );
            })}
            {/* <tr>
              <td>123456</td>
              <td>Autoport</td>
              <td>23/3/2023</td>
              <td>
                <NewspaperIcon
                  className="icon"
                  onClick={handleOpen}
                  rel={rel}
                />
              </td>
            </tr> */}
          </tbody>
        </table>
      ) : (
        <h1>No Invoices Pending</h1>
      )}
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <PendingPO close={handleClose} id={heading} site={site} invoice={invoice}/>
      </Modal>
    </main>
  );
}
