import { useState, useEffect } from "react";
import TuneIcon from "@mui/icons-material/Tune";
import { getSuppliers } from "../../utils/Api";
import SearchIcon from "@mui/icons-material/Search";
import Alert from "@mui/material/Alert";
import axios from "axios";
import Loader from "../../animations/Loader";

export default function ProductReport() {
  const url = "https://nitropos-backend.nitrosolutions.co.uk";
  // const url = "http://10.0.0.174:4022";

  const [showFilters, setShowFilters] = useState(false);
  // const [supplierList, setSupplierList] = useState([]);
  const [search, setSearch] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [latestProducts, setLatestProducts] = useState([]);

  //alert
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  // useEffect(() => {
  //   getSuppliers().then((data) => {
  //     setSupplierList(data);
  //   });
  // }, []);

  const handleFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleEnter = (e) => {
    if (e.keyCode === 13) {
      handleSearch();
    }
  };

  const handleSearch = () => {
    setShowLoader(true);
    setLatestProducts([]);

    if (search === "") {
      setMessage("Search box cannot be empty");
      setSeverity("warning");
      setShowLoader(false);
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 2500);
    } else {
      let data = {
        name: search,
      };
      axios
        .post(`${url}/dashboard/searchProductName`, data)
        .then((res) => {
          if (res.data === "No products found") {
            setMessage(res.data + " ordered on the last month");
            setSeverity("warning");
            setShowLoader(false);
            setShowAlert(true);
            setTimeout(() => {
              setShowAlert(false);
            }, 3500);
          } else if (res.data === "No invoices found") {
            setMessage(res.data + " on the last month for this product");
            setSeverity("warning");
            setShowLoader(false);
            setShowAlert(true);
            setTimeout(() => {
              setShowAlert(false);
            }, 3500);
          } else {
            console.log(res.data);
            setLatestProducts(res.data);
            setShowLoader(false);
          }
        })
        .catch((err) => {
          console.log(`could not get the products: ${err}`);
          setMessage("Something went wrong, please try again");
          setSeverity("error");
          setShowLoader(false);
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
          }, 3000);
        });
    }
  };

  const handleBarcode = (barcode) => {
    setShowLoader(true);
    setLatestProducts([]);

    let data = {
      barcode: barcode,
    };
    axios
      .post(`${url}/dashboard/searchProductName`, data)
      .then((res) => {
        if (res.data === "No products found") {
          setMessage(res.data + " ordered on the last month");
          setSeverity("warning");
          setShowLoader(false);
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
          }, 3500);
        } else if (res.data === "No invoices found") {
          setMessage(res.data + " on the last month for this product");
          setSeverity("warning");
          setShowLoader(false);
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
          }, 3500);
        } else {
          console.log(res.data);
          setLatestProducts(res.data);
          setShowLoader(false);
        }
      })
      .catch((err) => {
        console.log(`could not get the products: ${err}`);
        setMessage("Something went wrong, please try again");
        setSeverity("error");
        setShowLoader(false);
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      });
  };

  //this searches for both the barcode and the invoice number
  const handleInvoiceSearch = (invoice) => {
    setShowLoader(true);
    setLatestProducts([]);
    let data = {
      invoice: invoice,
    };
    axios
      .post(`${url}/dashboard/searchInvoice`, data)
      .then((res) => {
        if (res.data.length === 0) {
          setMessage("No invoice found");
          setSeverity("warning");
          setShowLoader(false);
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
          }, 3500);
        } else {
          console.log(res.data);
          setLatestProducts(res.data)
          setShowLoader(false);
        }
      })
      .catch((err) => {
        console.log(`could not get the invoice: ${err}`);
        setMessage("Something went wrong, please try again");
        setSeverity("error");
        setShowLoader(false);
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      });
  };

  return (
    <section className="product_tab">
      <h1>Product report</h1>
      <section className="search_card">
        <h3>Search products</h3>
        <div className="search_row">
          <div className="search_button">
            <input
              type="text"
              placeholder="Search by product name"
              onChange={(e) => setSearch(e.target.value)}
              onKeyDown={handleEnter}
            />
            <SearchIcon color="primary" onClick={handleSearch} />
          </div>
          <TuneIcon color="primary" onClick={handleFilters} />
        </div>
        {showFilters ? (
          <div className="filters__shown">
            {/* <div className="filters__column">
              <label>Search By Supplier</label>
              <select
                // onChange={(e) => searchBySupplier(e.target.value)}
                id="supplier__select"
              >
                {supplierList.map(function (d, i) {
                  return <option value={d.eposId}>{d.name}</option>;
                })}
              </select>
            </div> */}
            <div className="filters__column">
              <label>Search By Barcode</label>
              <input
                type="text"
                onChange={(e) => handleInvoiceSearch(e.target.value)}
              />
            </div>
            <div className="filters__column">
              <label>Search By Invoice Number</label>
              <input
                type="text"
                onChange={(e) => handleInvoiceSearch(e.target.value)}
              />
            </div>
          </div>
        ) : null}
      </section>
      {showAlert ? (
        <Alert severity={severity} sx={{ margin: "2% auto" }}>
          {message}
        </Alert>
      ) : showLoader ? (
        <Loader />
      ) : null}
      {latestProducts.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Product</th>
              <th>Invoice Number</th>
              <th>Invoice Date</th>
              <th>Qty Ordered</th>
              <th>Qty Received</th>
              <th>Site</th>
            </tr>
          </thead>
          <tbody>
            {latestProducts.map((product, index) => {
              let received =
                parseInt(product.product.packQty) *
                parseInt(product.product.qtyReceived);
              return (
                <tr key={{ index }}>
                  <td>{product.product.Name}</td>
                  <td>{product.invoice}</td>
                  <td>{product.invoiceDate}</td>
                  <td>{product.product.qtyOrdered}</td>
                  <td>{received}</td>
                  <td>{product.site}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : null}
    </section>
  );
}
