import { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import NavBar from "../../../NavBar";
import ManagerNav from "../../../managerZone/ManagerNav";
import WareNav from "../../../warehouse/WareNav";
import FtgNav from "../../../FTG/FtgNav";

import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import {
  Alert,
  AlertTitle,
  Button,
  Chip,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Modal,
  Select,
  Snackbar,
  Typography,
} from "@mui/material";
import { Link, useLoaderData, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import SingleRotaModel from "../modal/SingleRotaModel";
import moment from "moment";
import { addTimes } from "../../../utils/add_two_times";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
const HR_URL = process.env.REACT_APP_API_URL;
const drawerWidth = "12vw";

const ReviewRota = ({ managerInfo }) => {
  const [role, setRole] = useState("");
  const [user, setUser] = useState("");
  const [managerInf, setManagerInf] = useState(null);

  //error handling states
  const [Loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  //rota states
  const [rotas, setRotas] = useState([]);
  const [singleRota, setSingleRota] = useState([]);
  const [singleRotaModel, setSingleRotaModel] = useState(false);

  //sites or Location Names
  const [sites, setSites] = useState([]);

  //date range from url
  const { from } = useParams();
  const { to } = useParams();
  // calculation of current week start day and end day

  let firstDayOfWeek = moment().locale("en-gb").startOf("week");

  firstDayOfWeek = moment(firstDayOfWeek)
    .subtract(2, "days")
    .format("YYYY-MM-DD")
    .toString();

  let lastDayOfWeek = moment().locale("en-gb").endOf("week");

  lastDayOfWeek = moment(lastDayOfWeek)
    .subtract(2, "day")
    .format("YYYY-MM-DD")
    .toString();
  //end calculation

  const [fromDate, setFromDate] = useState(from || firstDayOfWeek);
  const [toDate, setToDate] = useState(to || lastDayOfWeek);

  let navigate = useNavigate();

  useEffect(() => {
    let userD = localStorage.getItem("userDetails");
    let user = JSON.parse(userD);
    if (user) {
      setUser(user.user.user);
      setRole(user.user.role);
      setManagerInf(user);
    } else {
      navigate("/", { replace: true });
    }

    load_review_rotas();
  }, [fromDate, toDate]);

  const load_review_rotas = async () => {
    setLoading(true);
    try {
      const result = await axios.get(
        `${HR_URL}/rota/reviews/pending?from=${fromDate}&to=${toDate}`
      );
      setRotas(result.data);
      let updatedRotas = [];
      result?.data.forEach((element) => {
        let totalHours = "00:00:00";
        element.staff.forEach((staff) => {
          staff.shifts.forEach((shift) => {
            totalHours = addTimes(totalHours, shift.hoursWorked);
          });
        });
        element.totalStaff = element.staff.length;
        element.totalHours = totalHours;
        updatedRotas = [...updatedRotas, element];
      });
    } catch (error) {
      console.log(error);
      setErrorMessage(error.message);
    }
    setLoading(false);
  };

  const columns = [
    {
      field: "siteName",
      headerName: "Location Name",
      width: 180,
      editable: false,
      headerAlign: "left",
    },
    {
      field: "rotaId",
      headerName: "Rota ID",
      width: 100,
      editable: false,
      headerAlign: "left",
    },

    {
      field: "totalHours",
      headerName: "Total Hours",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      headerAlign: "left",
      width: 100,
    },
    {
      field: "totalStaff",
      headerName: "Total Staff",
      type: "number",
      width: 100,
      editable: false,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "startDate",
      headerName: "Rota Date",
      type: "string",
      width: 300,
      editable: false,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => {
        return (
          <>
            <span>
              {moment(params.row.startDate).format("DD-MM-YYYY") || ""}
            </span>
            <span
              style={{
                fontSize: 15,
                fontWeight: "bold",
                marginLeft: "5px",
                marginRight: "5px",
              }}
            >
              to
            </span>
            <span>{moment(params.row.endDate).format("DD-MM-YYYY") || ""}</span>
          </>
        );
      },
    },
    {
      field: "published",
      headerName: "Status",
      type: "string",
      width: 150,
      editable: false,
      headerAlign: "left",
      renderCell: (params) => {
        return (
          <Chip
            label={params.value ? "Published" : "Under Review"}
            color={params.value ? "success" : "default"}
          />
        );
      },
    },
  ];

  return (
    <main style={{ overflowX: "hidden" }}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <AppBar
          position="fixed"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            padding: "0",
          }}
        >
          <Toolbar
            sx={{
              width: "100vw",
              backgroundColor: "#2c53a0",
            }}
          >
            {role === "Developer" || role === "Manager" ? (
              <NavBar />
            ) : role === "Store Manager" || role === "Supervisor" ? (
              <ManagerNav />
            ) : role === "Warehouse Manager" ? (
              <WareNav />
            ) : role === "Food Manager" ? (
              <FtgNav />
            ) : null}
          </Toolbar>
        </AppBar>

        <Box
          component="main"
          sx={managerInfo?{flexGrow: 1,
            p: 3,
            // marginTop: "15vh",
            marginBottom: "15vh",
            maxWidth: "1200px",}:
            {flexGrow: 1,
            p: 3,
            marginTop: "15vh",
            marginBottom: "15vh",
            maxWidth: "1200px"
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h4"
            marginBottom={3}
            component="h2"
          >
            Rota list to publish
          </Typography>

          <Typography variant="h4" marginBottom={1}>
            <div style={{ display: "flex", margin: 0 }}>
              <p style={{}}>
                <span>
                  {moment(fromDate).format("DD-MM-YYYY")}
                  <span
                    style={{
                      display: "block",
                      fontSize: "20px",
                      textAlign: "center",
                    }}
                  >
                    ({moment(fromDate).format("dddd")})
                  </span>
                </span>
              </p>
              <p style={{ marginLeft: 50, marginRight: 50 }}>to</p>
              <p>
                <span>{moment(toDate).format("DD-MM-YYYY")}</span>
                <span
                  style={{
                    display: "block",
                    fontSize: "20px",
                    textAlign: "center",
                  }}
                >
                  ({moment(toDate).format("dddd")})
                </span>
              </p>
            </div>
          </Typography>

          {errorMessage && (
            <Alert severity="error" sx={{ marginBottom: "5px" }}>
              <AlertTitle>Error</AlertTitle>
              {errorMessage}
            </Alert>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              marginTop: "10px",
              marginBottom: "10px",
              height: "60px",
            }}
          >
            <Button
              variant="outlined"
              startIcon={<ArrowBackIosIcon />}
              onClick={() => {
                setFromDate(
                  moment(fromDate).subtract(7, "days").format("YYYY-MM-DD")
                );
                setToDate(
                  moment(toDate).subtract(7, "days").format("YYYY-MM-DD")
                );
              }}
            >
              Previous week
            </Button>
            <Button
              variant="outlined"
              endIcon={<ArrowForwardIosIcon />}
              onClick={() => {
                setFromDate(moment(toDate).add(1, "day").format("YYYY-MM-DD"));
                setToDate(moment(toDate).add(7, "days").format("YYYY-MM-DD"));
              }}
            >
              Next week
            </Button>
          </div>

          <TableContainer component={Paper}>
            <DataGrid
              rows={rotas}
              getRowId={(row) => row.rotaId}
              columns={columns}
              sx={{ padding: 1 }}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 50, page: 0 },
                },
                sorting: {
                  sortModel: [{ field: "Status", sort: "desc" }],
                },
              }}
              // pageSizeOptions={[25, 10]}
              checkboxSelection={false}
              disableRowSelectionOnClick
              filterMode="client"
              onRowClick={(params, event, details) => {
                setSingleRota(params);
                setSingleRotaModel(true);
              }}
              density="comfortable"
              slots={{
                toolbar: GridToolbar,
                noRowsOverlay: CustomNoRowsOverlay,
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
              disableDensitySelector={true}
              disableColumnSelector={true}
              loading={Loading}
            />
          </TableContainer>
        </Box>

        {/* Single Rota details Model */}

        <Modal open={singleRotaModel}>
          <SingleRotaModel
            setSingleRotaModel={setSingleRotaModel}
            singleRota={singleRota.row}
            user={user}
            load_review_rotas={load_review_rotas}
          />
        </Modal>
      </Box>
    </main>
  );
}

export default ReviewRota;

const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .no-rows-primary": {
    fill: "#3D4751",
    ...theme.applyStyles("light", {
      fill: "#AEB8C2",
    }),
  },
  "& .no-rows-secondary": {
    fill: "#1D2126",
    ...theme.applyStyles("light", {
      fill: "#E8EAED",
    }),
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        width={96}
        viewBox="0 0 452 257"
        aria-hidden
        focusable="false"
      >
        <path
          className="no-rows-primary"
          d="M348 69c-46.392 0-84 37.608-84 84s37.608 84 84 84 84-37.608 84-84-37.608-84-84-84Zm-104 84c0-57.438 46.562-104 104-104s104 46.562 104 104-46.562 104-104 104-104-46.562-104-104Z"
        />
        <path
          className="no-rows-primary"
          d="M308.929 113.929c3.905-3.905 10.237-3.905 14.142 0l63.64 63.64c3.905 3.905 3.905 10.236 0 14.142-3.906 3.905-10.237 3.905-14.142 0l-63.64-63.64c-3.905-3.905-3.905-10.237 0-14.142Z"
        />
        <path
          className="no-rows-primary"
          d="M308.929 191.711c-3.905-3.906-3.905-10.237 0-14.142l63.64-63.64c3.905-3.905 10.236-3.905 14.142 0 3.905 3.905 3.905 10.237 0 14.142l-63.64 63.64c-3.905 3.905-10.237 3.905-14.142 0Z"
        />
        <path
          className="no-rows-secondary"
          d="M0 10C0 4.477 4.477 0 10 0h380c5.523 0 10 4.477 10 10s-4.477 10-10 10H10C4.477 20 0 15.523 0 10ZM0 59c0-5.523 4.477-10 10-10h231c5.523 0 10 4.477 10 10s-4.477 10-10 10H10C4.477 69 0 64.523 0 59ZM0 106c0-5.523 4.477-10 10-10h203c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 153c0-5.523 4.477-10 10-10h195.5c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 200c0-5.523 4.477-10 10-10h203c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 247c0-5.523 4.477-10 10-10h231c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10Z"
        />
      </svg>
      <Box sx={{ mt: 2 }}>No rows</Box>
    </StyledGridOverlay>
  );
}
