import Footer from "../../Footer";
import FtgNav from "../FtgNav";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import "../styles/reports.scss";
import ReportCard from "./ReportCard";
import axios from "axios";

export default function Reporting() {
  const [sales, setSales] = useState("");
  const [siteSales, setSiteSales] = useState([]);

  useEffect(() => {
    //gets the sales report
    axios
      .get(
        "https://backend.hoagies.co.uk/food/reporting/reporting/sales/overall"
      )
      .then((res) => {
        setSales(res.data.total);
        setSiteSales(res.data.sales);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <section className="reports__main">
      <h1>Hoagies sales report</h1>
      <h2>
        Sales over the last 7 days: <span>£{parseFloat(sales).toFixed(2)}</span>
      </h2>
      <div className="reports__cards">
        {siteSales.map(function (site, i) {
          return <ReportCard site={site} />;
        })}
      </div>
    </section>
  );
}
