import { useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import IndvBanking from "./IndvBanking";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import Alert from "@mui/material/Alert";
import axios from "axios";

const HistorySearchModal = (props) => {
    const url = process.env.REACT_APP_DEPPLOYED;
    // const url = "http://10.0.0.34:4022";

    const [alert, setAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");
    const [showFilters, setShowFilters] = useState(false);
    const [bagId, setBagId] = useState("");
    const [showResults, setShowResults] = useState(false)
    const [searchedBagId, setSearchedBagId] = useState([]);

    const style = {
        position: "absolute",
        top: "55%",
        left: "55%",
        transform: "translate(-50%, -50%)",
        width: "70vw",
        minHeight: "10vw",
        maxHeight: "35vw",
        bgcolor: "background.paper",
        border: "none",
        borderRadius: "1rem",
        boxShadow: 24,
        p: 4,
        overflowY: "scroll",
        zIndex: "100",
    };

    useEffect(() => {
        console.log("Historic")
        console.log(props.bankedHistorical);
    }, [props]);

    //Filter By Bag ID
    const handleFilterByBagId = async () => {
        setShowResults(false)
        await axios
            .post(`${url}/eos/search/bagId`, { bagId: bagId })
            .then((res) => {

                setSearchedBagId(res.data);
                setShowResults(true)
                if (res.data.length == 0) {
                    setTimeout(() => {
                        setShowResults(false)
                    }, 3000)
                }

            })
            .catch((err) => {
                setMessage("Error:" + err);
                setSeverity("warning");
                setAlert(true);
                setTimeout(() => {
                    setAlert(false);
                    setSeverity("");
                    setMessage("");
                }, 2500);
            });
    };

    const handleEnter = async (event) => {
        if (event.key === "Enter") {
            await handleFilterByBagId();
        }
    }

    const searchByDate = async()=>{
        setSearchedBagId([]);
        setBagId("");
        props.searchDates();
    }

    return (
        <Box sx={style} className="history__eod">
            <section className="epos__search">
                <div className="epos__filters">
                    {alert ? (
                        <Alert severity={severity}>{message}</Alert>
                    ) : (
                        <div className="filters__search">
                            <input
                                type="text"
                                placeholder="Search records by Bag ID..."
                                id="bag-id-search"
                                onChange={(e) => { setBagId(e.target.value) }}
                                onKeyDown={handleEnter}
                            />
                            {!showFilters ?
                                <SearchIcon
                                    color="primary"
                                    fontSize="large"
                                    sx={{ marginTop: "0" }}
                                    onClick={handleFilterByBagId}
                                />
                                : null}
                        </div>
                    )}

                    <div className="filters__open">
                        <TuneIcon
                            color="primary"
                            fontSize="large"
                            sx={{ marginTop: "0" }}
                            onClick={() => setShowFilters(!showFilters)}
                        />
                    </div>
                    {showFilters ? (
                        <div>
                            <h2>{props.dateRange}</h2>
                            <div className="filters__shown">
                                <div
                                    style={{
                                        display: "flex",
                                        textAlign: "center",
                                    }}
                                >
                                    <input
                                        type="date"
                                        value={props.startDate}
                                        onChange={(e) =>
                                            props.setStartDate(e.target.value)
                                        }
                                    />
                                    <span
                                        style={{
                                            color: "#000",
                                            margin: "0 5px",
                                        }}
                                    >
                                        {" "}
                                        To{" "}
                                    </span>
                                    <input
                                        type="date"
                                        value={props.endDate}
                                        onChange={(e) =>
                                            props.setEndDate(e.target.value)
                                        }
                                    />
                                    <Button
                                        className="date-search-btn"
                                        variant="contained"
                                        style={{ marginLeft: "10px" }}
                                        onClick={searchByDate}
                                    >
                                        SEARCH
                                    </Button>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </section>

            <Typography id="modal-modal-description">
                <div className="disc__modal">
                    {searchedBagId.length > 0 ? (
                        <>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Site</th>
                                        <th>EOD Start</th>
                                        <th>EOD End</th>
                                        <th>Reference</th>
                                        <th>Pay In Slip</th>
                                        <th>Amount</th>
                                        <th>Deposited By</th>
                                        <th>Date Deposited</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {searchedBagId.map(function (d, i) {
                                        var user;
                                        user = d.user.split("@");
                                        return (
                                            <IndvBanking data={d} user={user} />
                                        );
                                    })}
                                </tbody>
                            </table>
                        </>
                    ) : searchedBagId.length == 0 && showResults ?
                        <h2>No Results Found</h2>
                        : null
                    }
                    {props.bankedHistorical.length > 0 &&
                        searchedBagId.length === 0 ? (
                        <>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Site</th>
                                        <th>EOD Start</th>
                                        <th>EOD End</th>
                                        <th>Reference</th>
                                        <th>Pay In Slip</th>
                                        <th>Amount</th>
                                        <th>Deposited By</th>
                                        <th>Date Deposited</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.bankedHistorical.map(function (
                                        d,
                                        i
                                    ) {
                                        var user;
                                        user = d.user.split("@");
                                        return (
                                            <IndvBanking data={d} user={user} />
                                        );
                                    })}
                                </tbody>
                            </table>
                        </>
                    ) : searchedBagId.length === 0 ? (
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                        >
                            <br />
                            {props.message} <b>{props.dateRange}</b>....
                        </Typography>
                    ) : null}
                </div>
            </Typography>
        </Box>
    );
};

export default HistorySearchModal;
