import axios from "axios";

let getToken;
let page = document.URL

if (page.includes("localhost") || page === "https://nitropos.co.uk/"){
  getToken = ""
} else{
  console.log("any other page")
  getToken = localStorage.getItem("auth-token");
}

const config = {
  headers: { "Content-Type": "application/json", "x-auth-token": getToken },
};

const getCompanies = (token) => {
  let localConfig;
  if (token){
    localConfig = {
      headers: { "Content-Type": "application/json", "x-auth-token": token },
    }
  } else {
    localConfig = config
  }

  return axios
    .get(`${process.env.REACT_APP_API_URL}/company`, localConfig)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return [];
    });
};

const getPositions = (token) => {
  let localConfig;
  if (token){
    localConfig = {
      headers: { "Content-Type": "application/json", "x-auth-token": token },
    }
  } else {
    localConfig = config
  }
  return axios
    .get(`${process.env.REACT_APP_API_URL}/roles`, localConfig)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return [];
    });
};

const getInterviewDetails = (id) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/interview/${id}`, config)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return null;
    });
};

const submitInterview = async (interviewData, cv, configForm) => {
  const formData = new FormData();
  formData.append("file", cv);
  formData.append("interview", JSON.stringify(interviewData));

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/interview/new`,
      formData,
      configForm
    );

    if (response.status === 200) {
      return true;
    }
  } catch (error) {
    console.error("Error submitting the interview:", error);
    throw error;
  }
};

const getPendingBanking = (token) => {
  let localConfig;
  if (token){
    localConfig = {
      headers: { "Content-Type": "application/json", "x-auth-token": token },
    }
  } else {
    localConfig = config
  }
  return axios.get(`${process.env.REACT_APP_API_URL}/banking/pending`, localConfig)
  .then((res) => res.data)
  .catch((err) => {
      console.log(`error getting pending details: ${err}`)
      return []
  })
}

const getEmployeeDetailsById = async (employeeId, token) => {
  let localConfig;
  if (token){
    localConfig = {
      headers: { "Content-Type": "application/json", "x-auth-token": token },
    }
  } else {
    localConfig = config
  }

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/employee/single/${employeeId}`, localConfig);
    return response.data;
  } catch (error) {
    console.error("Error fetching employee details:", error);
    throw error;
  }
};


export { getCompanies, getPositions, getInterviewDetails, submitInterview, getPendingBanking, getEmployeeDetailsById };
