import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60vw",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
    "@media(max-width: 600px)": {
        width: "90vw",
        maxHeight: "70vh",
        overflowX: "scrollable",
    },
};
const StatusModal = ({
    statusList,
    currentStatus,
    reqId,
    rel,
    handleCloseStatus,
    isDev,
}) => {

    const url = process.env.REACT_APP_ANPR_URL;
    //const url = "http://10.0.0.34:4024"

    const [status, setStatus] = useState("");
    const [staff, setStaff] = useState("");
    const [alert, setAlert] = useState(false);
    const [severity, setSeverity] = useState("");
    const [message, setMessage] = useState("");
    const [user, setUser] = useState("");

    useEffect(() => {
        let userD = localStorage.getItem("userDetails");
        let currentUser = JSON.parse(userD);
        setUser(currentUser.user.user);
    }, []);

    const handleStatusChange = async () => {
        let statusId = "";
        for (let i = 0; i < statusList.length; i++) {
            if (statusList[i].name === status) {
                statusId = statusList[i].statusId;
            }
        }

        if (status === "") {
            setSeverity("warning");
            setMessage("Please select a status");
            setAlert(true);
            setTimeout(() => {
                setAlert(false);
                setSeverity("");
                setMessage("");
            }, 3500);
        } else if (!isDev && staff === "") {
            setSeverity("warning");
            setMessage("Please provide your name");
            setAlert(true);
            setTimeout(() => {
                setAlert(false);
                setSeverity("");
                setMessage("");
            }, 3500);
        } else {
            let data = {
                requestId: reqId,
                status: statusId,
                user: isDev ? user : staff,
            };


            console.log(data);
            await axios
                .post(`${url}/cctv-system/submission/update/status`, data)
                .then((res) => {
                    console.log('success!');
                    setSeverity("success");
                    setMessage("Status successfully updated");
                    setAlert(true);
                    setTimeout(() => {
                        setAlert(false);
                        setSeverity("");
                        setMessage("");
                        rel();
                        handleCloseStatus(false);
                    }, 3500);
                    handleCloseStatus(false);
                })
                .catch((err) => {
                    console.log(err);
                    setSeverity("error");
                    setMessage("Something went wrong, please try again");
                    setAlert(true);
                    setTimeout(() => {
                        setAlert(false);
                        setSeverity("");
                        setMessage("");
                    }, 3500);
                });
        }
    };
    return (
        <Box sx={style} className="all_modals">
            <Typography id="modal-modal-title" variant="h6" component="h2">
                <h3 style={{ textAlign: "center", width: "100%" }}>
                    Current Status: {currentStatus}
                </h3>
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <div style={{ color: "#4d4d4d" }}>
                    <label>New Status:</label>
                    <br />
                    {statusList ? (
                        statusList.map((status) => {
                            return (
                                <>
                                    <input
                                        name="cctvStatus"
                                        type="radio"
                                        value={status.name}
                                        onChange={(e) =>
                                            setStatus(e.target.value)
                                        }
                                    />
                                    <label>{status.name}</label>
                                    <br />
                                </>
                            );
                        })
                    ) : (
                        <></>
                    )}
                    <hr />
                    {!isDev ? (
                        <div>
                            <label>Please provide your name:</label>
                            <input
                                type="text"
                                onChange={(e) => setStaff(e.target.value)}
                                value={staff}
                            />
                        </div>
                    ) : null}

                    {alert ? (
                        <Alert severity={severity}>{message}</Alert>
                    ) : (
                        <></>
                    )}
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "3%",
                        }}
                    >
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => handleCloseStatus(false)}
                        >
                            Close
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className="button"
                            id="status-btn"
                            onClick={handleStatusChange}
                        >
                            Send
                        </Button>
                    </div>
                </div>
            </Typography>
        </Box>
    );
};

export default StatusModal;
