import {useEffect, useState, } from 'react';
import './investigation.scss';


export default function InvesList(props){
    const [invesData, setInvesData] = useState([])


    useEffect(() => {
        console.log(props.investigationData)
        setInvesData(props.investigationData)
    },[props])

    return(
        <section className="investigation__list">
            <table>
                <thead>
                    <tr>
                        <th>Staff Name</th>
                        <th>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {invesData.map(function(d,i){
                        return(
                            <tr onClick={()=>props.handleDiscrepancy(d)}>
                                <th>{d.name}</th>
                                <th>£{parseFloat(d.sum).toFixed(2)}</th>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </section>
    )
}