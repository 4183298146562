import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "../eod.scss";
import Button from "@mui/material/Button";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import IconButton from "@mui/material/IconButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40vw",
  minHeight: "12vw",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
};


export default function WrongDiscrep(props) {

  const resetDay = () => {
    props.close()
    props.managersAreUseless()
  }
  
  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Discrepancy cannot exist between shift reconciliation and money counted
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <div className="disc__modal">
          <h4>
            Actual you counted: £<span>{props.actual.toFixed(2)}</span>
          </h4>
          <h4>
            Expected from Shift reconciliation: £<span>{props.expected}</span>
          </h4>
          <div className="disc__modal__row">
            <Button
              variant="outlined"
              color="error"
              onClick={() => props.close()}
            >
              Cancel
            </Button>
            <Button variant="contained" color="error" onClick={() => resetDay()}>Reset Day</Button>
          </div>
        </div>
      </Typography>
    </Box>
  );
}
