import "../../styles/costa.scss";
import packagingData from "./packaging.json";
import { useState, useEffect } from "react";
import PackagingItem from "./PackagingItem";
import axios from "axios";
import Button from "@mui/material/Button";
import { Alert } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import moment from "moment";

export default function PackagingOrder() {

    let timer;
    // const url = "http://10.0.0.13:4022";
    const url = process.env.REACT_APP_DEPPLOYED;
    const [products, setProducts] = useState([]);
    const [updatedProducts, setUpdatedProducts] = useState([]);
    const [siteName, setSiteName] = useState("");
    const [user, setUser] = useState("");
    const [alert, setAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
    const [orderDate,setOrderDate] = useState("");
    const [lastOrder,setLastOrder] = useState(null);

    useEffect(() => {
        const getSiteName = JSON.parse(localStorage.getItem("site"));

        if (getSiteName) {
            setSiteName(getSiteName);
        }
        setProducts(packagingData);
        var weekDayToFind = moment().day('Sunday').weekday();
        var searchDate = moment();
        while (searchDate.weekday() !== weekDayToFind) {
            searchDate.add(1, 'day');
        }
        setOrderDate(searchDate.format("DD/MM/YYYY"));
        axios.get(`${url}/edn/packaging/last-order?site=${getSiteName}`).then((res)=>{
            if(res.data.found){
                setLastOrder(res.data.orderDate)
            }
        }).catch((err)=>{
            console.log(err)
        })
    }, []);

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleCloseConfirm = () => {
        setOpenDialogConfirm(false);
    };

    const debounce = (mainFunction, i) => {
        clearTimeout(timer);
        if (i) {
            timer = setTimeout(() => {
                mainFunction(true);
            }, 4000);
        }
    };

    const handleInputQuantity = (product, quantity) => {
        debounce(null, false);
        let parsedQuantity = Number(quantity);
        let newOrder = updatedProducts;
        const productIndex = newOrder.findIndex((p) => p.product === product);
        if (parsedQuantity === 0) {
            debounce(setOpenDialog, true);
        } else {
            clearTimeout(timer);
        }

        if (productIndex < 0) {
            newOrder.push({ product: product, quantity: parsedQuantity });
        } else {
            if (parsedQuantity === 0 || parsedQuantity === "") {
                newOrder.splice(productIndex, 1);
            } else {
                newOrder[productIndex].quantity = parsedQuantity;
            }
        }

        setUpdatedProducts(newOrder);
    };

    const handleSubmit = () => {
        let validationError = false;
        let errorProduct = {};

        for (let i = 0; i < updatedProducts.length; i++) {
            if (
                String(updatedProducts[i].quantity).includes("-") ||
                String(updatedProducts[i].quantity).includes(".") ||
                String(updatedProducts[i].quantity).includes("e")
            ) {
                validationError = true;
                errorProduct = updatedProducts[i].product;
            }
        }

        if (validationError) {
            setMessage(
                `All actual fields must be completed. Error at product: ${errorProduct}`
            );
            setSeverity("warning");
            setAlert(true);
            setTimeout(() => {
                setAlert(false);
                setMessage("");
                setSeverity("");
            }, 4000);
        } else if (updatedProducts.length === 0) {
            setMessage("No products ordered");
            setSeverity("warning");
            setAlert(true);
            setTimeout(() => {
                setAlert(false);
                setMessage("");
                setSeverity("");
            }, 4000);
        } else if (user === "") {
            setMessage("Please provide your name");
            setSeverity("warning");
            setAlert(true);
            setTimeout(() => {
                setAlert(false);
                setMessage("");
                setSeverity("");
            }, 4000);
        } else {
            setOpenDialogConfirm(true);
        }
    };

    const handleFinaliseOrder = () => {
        let data = {
            site: siteName,
            products: updatedProducts,
            user: user,
        };
        console.log(data)
        axios
            .post(`${url}/edn/packaging/submit`, data)
            .then((res) => {
                setOpenDialogConfirm(false);
                setMessage("Order succesfully submitted");
                setSeverity("success");
                setAlert(true);
                setTimeout(() => {
                    setAlert(false);
                    setMessage("");
                    setSeverity("");
                    window.location.reload();
                }, 4000);
            })
            .catch((err) => {
                console.log(err.response);
                setOpenDialogConfirm(false);
                setMessage(
                    `Error - ${err.response.data.error}, please contact IT`
                );
                setSeverity("error");
                setAlert(true);
                setTimeout(() => {
                    setAlert(false);
                    setMessage("");
                    setSeverity("");
                }, 4000);
            });
    };

    return (
        <main className="costa_orders">
            <h1>Packaging Orders </h1>{" "}
            <p>Deadline For Next Order - <b>{orderDate} PM</b></p>
            {lastOrder?
            <p>Last Order Placed - <b>{lastOrder}</b></p>
            :<></>}
            <p>
                Click the drop down and input the order quantity you wish to
                order. You only have to complete for products you are ordering.
            </p>
            {products.map((product, i) => {
                return (
                    <PackagingItem
                        key={i}
                        product={product}
                        handleInputQuantity={handleInputQuantity}
                    />
                );
            })}
            <label>Your Name:</label>
            <input onChange={(e) => setUser(e.target.value)} />
            <br />
            {!alert ? (
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                    color="success"
                    sx={{ width: "40%" }}
                >
                    Submit
                </Button>
            ) : (
                <Alert variant="filled" severity={severity}>
                    {message}
                </Alert>
            )}
            <Dialog onClose={handleClose} open={openDialog}>
                <Alert severity="warning" variant="filled">
                    You have entered a zero amount for a product, please make
                    sure you are happy with this.
                </Alert>
                <Button color="error" onClick={handleClose}>
                    close
                </Button>
            </Dialog>
            <Dialog
                className="costa_confirm_table"
                onClose={handleCloseConfirm}
                open={openDialogConfirm}
            >
                <table className="confirm_table">
                    <thead>
                        <tr>
                            <th>Product </th>
                            <th>Order Quantity</th>
                        </tr>
                    </thead>
                    <tbody>
                        {updatedProducts.map((product, i) => {
                            return (
                                <tr key={i}>
                                    <td>{product.product}</td>
                                    <td>{product.quantity}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <h4>Are you happy with this order?</h4>
                <div className="btn_section">
                    <Button
                        color="error"
                        variant="contained"
                        onClick={handleCloseConfirm}
                    >
                        close
                    </Button>
                    <Button
                        color="success"
                        variant="contained"
                        onClick={handleFinaliseOrder}
                    >
                        Finalise
                    </Button>
                </div>
            </Dialog>
        </main>
    )
}