import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Button } from "@mui/material";

export default function IndvWastage(props) {
    const product = props.product;

    return (
        <tr key={product.barcode}>
            <td>{product.barcode}</td>
            <td>{product.name}</td>
            <td>{product.supplierName}</td>
            <td>
                <input
                    type="number"
                    onChange={(e) =>
                        props.handleWastedUnits(e.target.value, props.i)
                    }
                    placeholder={product.wasted}
                    min={0}
                    pattern="[0-9]"
                    id="units_input"
                />
            </td>
            <td>
                <select
                    onChange={(e) =>
                        props.handleReason(e.target.value, props.i)
                    }
                >
                    <option>--Select a reason--</option>
                    <option>Damaged</option>
                    <option>Out of Date</option>
                    <option>Out of Temperature</option>
                    <option>Return to supplier</option>
                </select>
            </td>
            <td>{product.fresh ? <>Yes</> : <>No</>}</td>

            <td>
                <Button
                    variant="contained"
                    color="error"
                    onClick={() => props.handleRemoveWastage(props.i)}
                >
                    <DeleteForeverIcon />
                </Button>
            </td>
        </tr>
    );
}
