import {useState, useEffect} from 'react';
import axios from 'axios';
import './eod.scss';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Modal from '@mui/material/Modal';
import StatusModal from './modal/StatusModal';
import moment from 'moment'


export default function Banking() {
    const [showTable, setShowTable] = useState(false)
    const [siteData, setSiteData] = useState([])
    const [reload, setReload] = useState(false)
    const [open, setOpen] = useState(false);
    const handleOpen = (d) => {
        setDetails(d)
        setOpen(true)};
    const handleClose = () => setOpen(false);
    const [details, setDetails] = useState({})
    const [user, setUser] = useState("");
    const [token, setToken] = useState("")
    const [tableData, setTableData] = useState([])
  

    useEffect(() => {
        let userD = localStorage.getItem("userDetails");
        let user = JSON.parse(userD);
        setUser(user.user.user);

        let token = localStorage.getItem("auth-token");
        setToken(token)
        axios.get("https://nitropos-backend.nitrosolutions.co.uk/eos/awaitingBank")
        .then((res) => {
            setSiteData(res.data)
        }).catch((err) => {
            console.log(err)
        })
    },[reload])

    const rel = () => {
        setReload(!reload)
        setShowTable(!showTable)
    }

    const handleTable = (site) => {
        setShowTable(!showTable)
        // axios.post(`http://10.0.0.34:4022/eos/awaitingBank/${site}`, {token: token})

        axios.post(`https://nitropos-backend.nitrosolutions.co.uk/eos/awaitingBank/${site}`, {token: token})

        .then((res) => {
            console.log(res.data)
            setTableData(res.data)
            setShowTable(true)
        }).catch((err) => {
            console.log(err)
        })
    }

  return (
    <section className="manager__banking">
      <h1>Banking</h1>
      <section className="banking__row">
        {siteData ? (
            siteData.map(function(d,i){
                let statuses = []
                if (d.statuses.length > 0){
                    statuses = d.statuses
                }
                return(
                    <div className="row__btn" onClick={() => handleTable(d.siteId)}>
                        {d.siteName}
                        {statuses.map(function(s,index){
                            return(
                                <p>{s.name} - {s.amount}</p>
                            )
                        })}
                        </div>
                )
            })
        ):(
            <h1>No Bags waiting to be collected</h1>
        )}
      </section>
        {showTable ? (
            <table>
                <thead>
                    <tr>
                        <th>Site</th>
                        <th>EOD Done on</th>
                        <th>Start Shift</th>
                        <th>End Shift</th>
                        <th>Amount</th>
                        <th>Amount (if different to EOD)</th>
                        <th>Reference</th>
                        <th>Pay In Slip</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {tableData.map(function(d,i){
                        let error = ""
                        if (moment().diff(moment(d.dateTime), "days") > 3){
                            error = "length__alert"
                        } else{
                            error = ""
                        }
                        return(
                            <tr className={error}>
                                <td>{d.siteName}</td>
                                <td>{new Date(d.dateTime).toDateString()}</td>
                                <td>{new Date(d.startTime).toDateString()}</td>
                                <td>{new Date(d.endTime).toDateString()}</td>
                                <td>£{parseFloat(d.amount).toFixed(2)}</td>
                                <td>
                                    {d.bagNote === "" || !d.bagNote ? (
                                        <p>{d.amount}</p>
                                    ):(
                                        <p>{d.bagNote}</p>
                                    )}
                                </td>
                                <td>{d.bagId}</td>
                                <td>{d.payInSlip}</td>
                                <td>{d.name} <MoreHorizIcon sx={{marginTop: "0", cursor: "pointer"}} onClick={() => handleOpen(d)} /></td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        ):(
            <></>
        )}
        <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <StatusModal details={details} close={handleClose} rel={rel}/>
      </Modal>
    </section>
  );
}
