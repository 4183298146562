import "./devZone.scss";
import DevNav from "./nav/DevNav";
import Footer from "../Footer";
import { useEffect, useState, useContext } from "react";
import axios from "axios";
import userContext from "../context/userContext";
import { useNavigate, Navigate } from "react-router-dom";

export default function DevHome(props) {
  const { userData, setUserData } = useContext(userContext);

  const [user, setUser] = useState("");
  useEffect(() => {
    var username = "";
    var split = userData.user.user.split("@");
    username = split[0].replace(".", " ");
    setUser(username);
  }, []);

  return (
    <main className="devZone">
      <DevNav />
      <section className="devzone__main">
        <h1>Welcome to the dev zone {user}</h1>
      </section>
    </main>
  );
}
