import { useState, useEffect } from "react";
import { Button } from "@mui/material";
import axios from "axios";
import { numberValidation } from "../../../utils/Validation";
import { Alert } from "@mui/material";

interface Props {
    site: number;
    setSelectedSupplier: any;
    orderSource: any;
}

export default function PurchaseOrderChangeForm({
    site,
    setSelectedSupplier,
    orderSource,
}: Props) {
    const [formData, setFormData] = useState<any[]>([]);
    const [order, setOrder] = useState<any[]>([]);
    const [user, setUser] = useState<string>("");
    const [orderTotalAmount, setOrderTotalAmount] = useState<number>(0);

    //Alerts
    const [message, setMessage] = useState<string>("");
    const [severity, setSeverity] = useState<any>("");
    const [alert, setAlert] = useState<Boolean>(false);
    const [disable, setDisable] = useState<any>(false);

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_DEPPLOYED}/change/products`)
            .then((res) => {
                setFormData(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    //Update Total on order change
    useEffect(() => {
        let _orderTotalAmount = 0;

        for (let i = 0; i < order.length; i++) {
            console.log(order[i].costPrice);
            console.log(order[i].qty);
            _orderTotalAmount += order[i].costPrice * order[i].qty;
        }
        console.log(_orderTotalAmount);
        setOrderTotalAmount(_orderTotalAmount);
    }, [order]);

    const handleAlert = (
        message: string,
        severity: any,
        close: any,
        duration = 4000
    ) => {
        setDisable(true);
        setMessage(message);
        setSeverity(severity);
        setAlert(true);
        setTimeout(() => {
            setAlert(false);
            setMessage("");
            setSeverity("");
            setDisable(false);
            if (close) {
                setSelectedSupplier(0);
            }
        }, duration);
    };

    const handleOrderUpdate = (
        prodId: number,
        amount: number,
        costPrice: number,
        name: string
    ) => {
        let inOrderAlready = order.findIndex(
            (item, i) => item.productId === prodId
        );

        if (inOrderAlready === -1) {
            if (amount > 0) {
                setOrder([
                    ...order,
                    {
                        productId: prodId,
                        qty: amount,
                        costPrice: costPrice,
                        name: name,
                    },
                ]);
            }
        } else {
            if (amount === 0 || isNaN(amount)) {
                let _newOrder = order;
                _newOrder.splice(inOrderAlready, 1);
                setOrder([..._newOrder]);
            } else {
                let _newOrder = order.map((item, i) => {
                    if (item.productId === prodId) {
                        return {
                            productId: prodId,
                            qty: amount,
                            costPrice: costPrice,
                            name: name,
                        };
                    } else {
                        return item;
                    }
                });
                setOrder([..._newOrder]);
            }
        }
    };

    const handleVal = () => {
        if (order.length === 0) {
            handleAlert(
                "You have not added anything to the order",
                "warning",
                false
            );
        } else if (user === "") {
            handleAlert("Please enter your full name", "warning", false);
        } else {
            handleSubmit();
        }
    };

    const handleSubmit = () => {
        setDisable(true);
        let reqBody = {
            siteId: site,
            staff: user,
            products: order,
            orderSource: orderSource,
        };
        axios
            .post(
                `${process.env.REACT_APP_DEPPLOYED}/change/order/submit`,
                reqBody
            )
            .then((res) => {
                handleAlert("Order successful", "success", true);
            })
            .catch((err) => {
                console.log(err);
                handleAlert(
                    "Order did not submit - please try again",
                    "error",
                    false
                );
            });
    };

    return (
        <section className="purchase-order-change-form">
            <table className="purchase-order-change-table">
                <thead>
                    <th>Coin</th>
                    <th>Single Bag</th>
                    <th>Order Qty (Bags)</th>
                </thead>
                <tbody>
                    {formData &&
                        formData.map((row: any, i) => {
                            return (
                                <tr key={i}>
                                    <td>{row.name}</td>
                                    <td>£{row.costPrice}</td>
                                    <td>
                                        <input
                                            type="number"
                                            min="0"
                                            onKeyDown={(e) => {
                                                numberValidation(e);
                                            }}
                                            onChange={(e) =>
                                                handleOrderUpdate(
                                                    row.productId,
                                                    parseInt(e.target.value),
                                                    parseInt(row.costPrice),
                                                    row.name
                                                )
                                            }
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    <tr className="total-row">
                        <th>Total:</th>
                        <th colSpan={2}>£{orderTotalAmount}</th>
                    </tr>
                </tbody>
            </table>
            <section className="name-section">
                <label htmlFor="user-name">Please enter your full name</label>
                <input
                    type="text"
                    name="user-name"
                    onChange={(e) => setUser(e.target.value)}
                />
            </section>
            <section className="alert-section">
                {alert ? <Alert severity={severity}>{message}</Alert> : null}
            </section>
            <Button
                variant="contained"
                onClick={handleVal}
                color="success"
                disabled={disable}
            >
                Submit
            </Button>
        </section>
    );
}
