import { useState, useEffect, Component, useRef } from "react";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import Alert from "@mui/material/Alert";
import Loader from "../animations/Loader";
import AlertTitle from "@mui/material/AlertTitle";
import html2pdf from "html2pdf.js";
import { useReactToPrint } from "react-to-print";
import ErrorBoundary from "../error/ErrorBoundary";
import moment from "moment";
import EodImage from "./modals/EodImage";
import { Modal } from "@mui/material";

export default function DiscrepanciesRep(props) {
    const [site, setSite] = useState("");
    const [siteName, setSiteName] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [siteList, setSiteList] = useState([]);
    const [eodData, setEodData] = useState([]);
    const [alert, setAlert] = useState(false);
    const [loader, setLoader] = useState(false);
    const [infoExpand, setInfoExpand] = useState(false);
    const [message, setMessage] = useState("");
    const [image, setImage] = useState("")
    const [showImage, setShowImage] = useState(false)
    const [user, setUser] = useState("");
    const [fromInvestigation, setFromInvestigation] = useState(false);
    const ref = useRef(null);
    const url = "https://nitropos-backend.nitrosolutions.co.uk";
    //const url = "http://10.0.0.174:4022"

    useEffect(async () => {
        // let email = sessionStorage.getItem("user")
        // setUser(email)
        if (props.investigationId) {
            setFromInvestigation(true);
            handleEodReport(props.investigationId);
        } else {
            setFromInvestigation(false);
            var finalSites = [];
            await axios
                .get("https://nitrous.rt-itservices.co.uk/sites/data")
                .then((res) => {
                    finalSites = res.data;
                })
                .catch((err) => {
                    console.log(err);
                });

            if (props.managerZone) {
                if (!props.sites.includes("HQ")) {
                    for (let s = 0; s < finalSites.length; s++) {
                        if (!props.sites.includes(finalSites[s].name)) {
                            finalSites.splice(s, 1);
                        }
                    }
                }
            }
            setSiteList(finalSites);
        }
    }, [props]);

    const openImage = () => setShowImage(true);
    const closeImage = () => setShowImage(false);

    const handleEodReport = async (investigationId) => {
        if (investigationId) {
            await axios
                .post(`${url}/eos/admin/eods`, {
                    investigationId: investigationId,
                })
                .then(async (res) => {
                    // console.log(res.data.allEodData);
                    setSiteName(res.data.siteName);
                    setEodData(res.data.allEodData);
                    setLoader(false);
                })
                .catch((err) => {
                    console.log(err);
                    setMessage("Something went wrong please try again");
                    setLoader(false);
                    setAlert(true);
                    setTimeout(() => {
                        setAlert(false);
                    }, 3500);
                });
        } else {
            if (
                site === "--Select Site--" ||
                startDate === "" ||
                endDate === "" ||
                site === ""
            ) {
                setMessage("Please Select a Site and a Date");
                setAlert(true);
                setTimeout(() => {
                    setAlert(false);
                }, 2500);
            } else {
                setLoader(true);
                setInfoExpand(!infoExpand);
                let data = {
                    siteId: parseInt(site),
                    startDate: startDate,
                    endDate: endDate,
                };
                await axios
                    .post(`${url}/eos/admin/eods`, data)
                    .then(async (res) => {
                        setLoader(false);
                        if (res.data.allEodData.length < 1) {
                            setMessage("No data to show for this period");
                            setAlert(true);
                            setTimeout(() => {
                                setAlert(false);
                                setMessage("No data to show for this period");
                            }, 3000);
                        }
                        let eod = res.data.allEodData;
                        for (let e = 0; e < eod.length; e++) {
                            let total = 0;
                            let shifts = eod[e].shifts;
                            for (let s = 0; s < shifts.length; s++) {
                                total += parseFloat(
                                    eod[e].shifts[s].eposAmount
                                );
                            }
                            eod[e].eposTotal = total;
                        }
                        setSiteName(res.data.siteName);
                        setEodData(eod);
                    })
                    .catch((err) => {
                        console.log(err);
                        setMessage("Something went wrong please try again");
                        setLoader(false);
                        setAlert(true);
                        setTimeout(() => {
                            setAlert(false);
                        }, 3500);
                    });
            }
        }
    };

    const printHandler = () => {
        var element = document.getElementById("reportingCapture");
        // Had to adjust the margin here otherwise it would have an unexplained margin
        var opt = {
            html2canvas: { scale: 2 },
            margin: [1.4, 0.4, 1.7, 0.7], //10.5, 1, 26.5, 0 //Top, Left, Bottom, Right
            jsPDF: { unit: "cm", format: "a4", orientation: "portrait" },
            pagebreak: { mode: "avoid-all", before: ".endEod" },
            filename: `${siteName}-EODs-${startDate}_To_${endDate}`,
        };
        html2pdf(element, opt);
    };

    const handlePrint = useReactToPrint({
        content: () => ref.current,
        documentTitle: `${siteName}-EODs-${startDate}-${endDate}`,
    });

    const seeImage = (src)=>{
        setImage(src);
        openImage()
    }

    return (
        <main className="global__report">
            {alert ? (
                <Alert
                    severity="error"
                    sx={{
                        width: "80vw",
                        left: "10vw",
                        position: "absolute",
                        top: "35vh",
                        zIndex: "10",
                    }}
                >
                    <AlertTitle>Error</AlertTitle>
                    {message}
                </Alert>
            ) : (
                <></>
            )}
            <ErrorBoundary>
                {!fromInvestigation ? (
                    <>
                        <h1>Select a Site and Day to see EOD report</h1>
                        <section className="report__row">
                            <select onChange={(e) => setSite(e.target.value)}>
                                <option>--Select Site--</option>
                                {siteList.map(function (d, i) {
                                    return (
                                        <option value={d.epos}>{d.name}</option>
                                    );
                                })}
                            </select>
                            <input
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                            <span style={{ color: "#000" }}>To</span>
                            <input
                                type="date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                            <SearchIcon
                                fontSize="large"
                                color="primary"
                                onClick={() => handleEodReport()}
                            />
                        </section>
                    </>
                ) : (
                    <></>
                )}
            </ErrorBoundary>
            {loader ? <Loader /> : null}

            {eodData.length > 0 ? (
                <div style={{ width: "60%" }}>
                    <ErrorBoundary>
                        <div
                            ref={ref}
                            id="reportingCapture"
                            style={{ color: "#4d4d4d", marginTop: "3%" }}
                        >
                            <h3 style={{ textAlign: "center" }}>
                                EOD REPORT FOR {siteName}
                            </h3>
                            <div id="reportingCapture">
                                {eodData.map(function (d, i) {
                                    return (
                                        <div
                                            style={{
                                                marginLeft: "3%",
                                                marginTop: "3%",
                                            }}
                                        >
                                            <table
                                                style={{
                                                    tableLayout: "fixed",
                                                    border: "1px solid #4d4d4d",
                                                    borderCollapse: "collapse",
                                                    width: "900px",
                                                    maxHeight: "840px",
                                                    color: "#4d4d4d",
                                                }}
                                            >
                                                <>
                                                    <th
                                                        style={{
                                                            width: "100%",
                                                            backgroundColor:
                                                                "#2c53a0",
                                                            color: "#fff",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        OVERVIEW
                                                    </th>
                                                    <tr
                                                        style={{
                                                            border: "1px solid #4d4d4d",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent:
                                                                    "space-evenly",
                                                            }}
                                                        >
                                                            <td>
                                                                <b>
                                                                    Submitted:
                                                                </b>{" "}
                                                                {d.heading.user}
                                                            </td>
                                                            <td>
                                                                <b>Start:</b>{" "}
                                                                {new Date(
                                                                    d.heading.startTime
                                                                ).toLocaleDateString()}
                                                            </td>
                                                            <td>
                                                                <b>End:</b>{" "}
                                                                {new Date(
                                                                    d.heading.endTime
                                                                ).toLocaleDateString()}
                                                            </td>
                                                            <td>
                                                                <b>ID:</b>{" "}
                                                                {d.eodId}
                                                            </td>
                                                        </div>
                                                    </tr>
                                                </>

                                                <>
                                                    <th
                                                        style={{
                                                            width: "100%",
                                                            backgroundColor:
                                                                "#2c53a0",
                                                            color: "#fff",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        BANKED
                                                    </th>
                                                    <tr
                                                        style={{
                                                            border: "1px solid #4d4d4d",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent:
                                                                    "space-evenly",
                                                            }}
                                                        >
                                                            <td>
                                                                <b>
                                                                    Expected:{" "}
                                                                </b>
                                                                £
                                                                {parseFloat(
                                                                    d.eposTotal
                                                                ).toFixed(2)}
                                                            </td>
                                                            <td>
                                                                <b>Cash:</b> £
                                                                {parseFloat(
                                                                    d.safe
                                                                        .amount
                                                                ).toFixed(2)}
                                                            </td>
                                                            <td>
                                                                <b>ID:</b>{" "}
                                                                {d.safe.bagId}
                                                            </td>
                                                            <td>
                                                                <b>
                                                                    Pay In Ref:
                                                                </b>{" "}
                                                                {
                                                                    d.safe
                                                                        .payInSlip
                                                                }
                                                            </td>
                                                        </div>
                                                    </tr>
                                                </>

                                                <>
                                                    <th
                                                        style={{
                                                            width: "100%",
                                                            backgroundColor:
                                                                "#2c53a0",
                                                            color: "#fff",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        SHIFTS
                                                    </th>
                                                    {d.shifts.map(function (
                                                        s,
                                                        i
                                                    ) {
                                                        var disc =
                                                            parseFloat(
                                                                s.actual
                                                            ).toFixed(2) -
                                                            parseFloat(
                                                                s.eposAmount
                                                            ).toFixed(2);
                                                        var cl;
                                                        disc < 0
                                                            ? (cl = "red")
                                                            : (cl = "green");
                                                        return (
                                                            <tr
                                                                style={{
                                                                    border: "1px solid #4d4d4d",
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        display:
                                                                            "flex",
                                                                        justifyContent:
                                                                            "space-evenly",
                                                                    }}
                                                                >
                                                                    {" "}
                                                                    <td>
                                                                        <b>
                                                                            Date:
                                                                        </b>
                                                                        <span>
                                                                            {moment(
                                                                                s.startTime,
                                                                                "YYYY-MM-DD HH:mm:ss"
                                                                            ).format(
                                                                                "DD-MM-YYYY"
                                                                            )}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <b>
                                                                            Staff:
                                                                        </b>{" "}
                                                                        {
                                                                            s.closedBy
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        <b>
                                                                            Till:
                                                                        </b>{" "}
                                                                        {
                                                                            s.device
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        <b>
                                                                            Expected:
                                                                        </b>{" "}
                                                                        £
                                                                        {parseFloat(
                                                                            s.eposAmount
                                                                        ).toFixed(
                                                                            2
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        <b>
                                                                            Actual:
                                                                        </b>{" "}
                                                                        £
                                                                        {parseFloat(
                                                                            s.actual
                                                                        ).toFixed(
                                                                            2
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        <b>
                                                                            Discp:
                                                                        </b>
                                                                        <span
                                                                            style={{
                                                                                color: cl,
                                                                            }}
                                                                        >
                                                                            {" "}
                                                                            £
                                                                            {parseFloat(
                                                                                disc
                                                                            ).toFixed(
                                                                                2
                                                                            )}
                                                                        </span>
                                                                    </td>
                                                                </div>
                                                            </tr>
                                                        );
                                                    })}
                                                </>

                                                <>
                                                    {d.discounts.length > 0 ? (
                                                        <>
                                                            <th
                                                                style={{
                                                                    width: "100%",
                                                                    backgroundColor:
                                                                        "#2c53a0",
                                                                    color: "#fff",
                                                                    textAlign:
                                                                        "center",
                                                                }}
                                                            >
                                                                DISCOUNTS
                                                            </th>
                                                            {d.discounts.map(
                                                                function (
                                                                    dis,
                                                                    i
                                                                ) {
                                                                    return (
                                                                        <tr
                                                                            style={{
                                                                                border: "1px solid #4d4d4d",
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    display:
                                                                                        "flex",
                                                                                    justifyContent:
                                                                                        "space-evenly",
                                                                                }}
                                                                            >
                                                                                <td>
                                                                                    <b>
                                                                                        Reason:
                                                                                    </b>{" "}
                                                                                    {
                                                                                        dis.reason
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    <b>
                                                                                        Value:
                                                                                    </b>{" "}
                                                                                    £
                                                                                    {parseFloat(
                                                                                        dis.value
                                                                                    ).toFixed(
                                                                                        2
                                                                                    )}
                                                                                </td>
                                                                                <td>
                                                                                    <b>
                                                                                        Note:
                                                                                    </b>
                                                                                    {dis.note ===
                                                                                        "undefined" ? (
                                                                                        <p
                                                                                            style={{
                                                                                                fontSize:
                                                                                                    ".9rem",
                                                                                            }}
                                                                                        >
                                                                                            No
                                                                                            Note
                                                                                            left
                                                                                        </p>
                                                                                    ) : (
                                                                                        <p>
                                                                                            {
                                                                                                dis.note
                                                                                            }
                                                                                        </p>
                                                                                    )}
                                                                                </td>
                                                                            </div>
                                                                        </tr>
                                                                    );
                                                                }
                                                            )}
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </>

                                                <>
                                                    {d.miscItems.length > 0 ? (
                                                        <>
                                                            <th
                                                                style={{
                                                                    width: "100%",
                                                                    backgroundColor:
                                                                        "#2c53a0",
                                                                    color: "#fff",
                                                                    textAlign:
                                                                        "center",
                                                                }}
                                                            >
                                                                Misc Items
                                                            </th>
                                                            {d.miscItems.map(
                                                                function (
                                                                    misc,
                                                                    i
                                                                ) {
                                                                    return (
                                                                        <tr
                                                                            style={{
                                                                                border: "1px solid #4d4d4d",
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    display:
                                                                                        "flex",
                                                                                    justifyContent:
                                                                                        "space-evenly",
                                                                                }}
                                                                            >
                                                                                <td>
                                                                                    <b>
                                                                                        Till
                                                                                        Product:
                                                                                    </b>{" "}
                                                                                    {
                                                                                        misc.name
                                                                                    }{" "}
                                                                                </td>
                                                                                <td>
                                                                                    <b>
                                                                                        Till
                                                                                        Qty:
                                                                                    </b>{" "}
                                                                                    {
                                                                                        misc.ingestQty
                                                                                    }{" "}
                                                                                </td>
                                                                                <td>
                                                                                    <b>
                                                                                        Till
                                                                                        Price:
                                                                                    </b>{" "}
                                                                                    £
                                                                                    {parseFloat(
                                                                                        misc.price
                                                                                    ).toFixed(
                                                                                        2
                                                                                    )}{" "}
                                                                                </td>
                                                                                <td>
                                                                                    <b>
                                                                                        Epos
                                                                                        Product:
                                                                                    </b>{" "}
                                                                                    {
                                                                                        misc.eposName
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    <b>
                                                                                        Actual
                                                                                        Qty:
                                                                                    </b>{" "}
                                                                                    {
                                                                                        misc.actualQty
                                                                                    }
                                                                                </td>
                                                                            </div>
                                                                        </tr>
                                                                    );
                                                                }
                                                            )}
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </>

                                                <>
                                                    {d.refunds.length > 0 ? (
                                                        <>
                                                            <th
                                                                style={{
                                                                    width: "100%",
                                                                    backgroundColor:
                                                                        "#2c53a0",
                                                                    color: "#fff",
                                                                    textAlign:
                                                                        "center",
                                                                }}
                                                            >
                                                                REFUNDS
                                                            </th>
                                                            {d.refunds.map(
                                                                function (
                                                                    r,
                                                                    i
                                                                ) {
                                                                    return (
                                                                        <tr
                                                                            style={{
                                                                                border: "1px solid #4d4d4d",
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    display:
                                                                                        "flex",
                                                                                    justifyContent:
                                                                                        "space-evenly",
                                                                                }}
                                                                            >
                                                                                <td>
                                                                                    <b>
                                                                                        Reason:
                                                                                    </b>{" "}
                                                                                    {
                                                                                        r.reason
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    <b>
                                                                                        Value:
                                                                                    </b>{" "}
                                                                                    £
                                                                                    {parseFloat(
                                                                                        r.value
                                                                                    ).toFixed(
                                                                                        2
                                                                                    )}
                                                                                </td>
                                                                                <td>
                                                                                    <b>
                                                                                        Note:
                                                                                    </b>{" "}
                                                                                    {
                                                                                        r.note
                                                                                    }
                                                                                </td>
                                                                            </div>
                                                                        </tr>
                                                                    );
                                                                }
                                                            )}
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </>

                                                <>
                                                    {d.payouts.length > 0 ? (
                                                        <>
                                                            <th
                                                                style={{
                                                                    width: "100%",
                                                                    backgroundColor:
                                                                        "#2c53a0",
                                                                    color: "#fff",
                                                                    textAlign:
                                                                        "center",
                                                                }}
                                                            >
                                                                PAYOUTS
                                                            </th>
                                                            {d.payouts.map(
                                                                function (
                                                                    p,
                                                                    i
                                                                ) {
                                                                    var disc =
                                                                        parseFloat(
                                                                            p.actual
                                                                        ).toFixed(
                                                                            2
                                                                        ) -
                                                                        parseFloat(
                                                                            p.expected
                                                                        ).toFixed(
                                                                            2
                                                                        );
                                                                    var cl;
                                                                    disc < 0
                                                                        ? (cl =
                                                                            "red")
                                                                        : (cl =
                                                                            "green");
                                                                    return (
                                                                        <tr
                                                                            style={{
                                                                                border: "1px solid #4d4d4d",
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    display:
                                                                                        "flex",
                                                                                    justifyContent:
                                                                                        "space-evenly",
                                                                                }}
                                                                            >
                                                                                <td>
                                                                                    <b>
                                                                                        Type:
                                                                                    </b>{" "}
                                                                                    {
                                                                                        p.type
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    <b>
                                                                                        Expected:
                                                                                    </b>{" "}
                                                                                    £
                                                                                    {parseFloat(
                                                                                        p.expected
                                                                                    ).toFixed(
                                                                                        2
                                                                                    )}
                                                                                </td>
                                                                                <td>
                                                                                    <b>
                                                                                        Actual:
                                                                                    </b>{" "}
                                                                                    £
                                                                                    {parseFloat(
                                                                                        p.actual
                                                                                    ).toFixed(
                                                                                        2
                                                                                    )}
                                                                                </td>
                                                                                <td>
                                                                                    <b>
                                                                                        Discp:
                                                                                    </b>{" "}
                                                                                    <span
                                                                                        style={{
                                                                                            color: cl,
                                                                                        }}
                                                                                    >
                                                                                        {" "}
                                                                                        £
                                                                                        {parseFloat(
                                                                                            disc
                                                                                        ).toFixed(
                                                                                            2
                                                                                        )}
                                                                                    </span>
                                                                                </td>
                                                                            </div>
                                                                        </tr>
                                                                    );
                                                                }
                                                            )}
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </>

                                                {/* <>
                                                    {d.payouts.length > 0 ? (
                                                        <>
                                                            <th
                                                                style={{
                                                                    width: "100%",
                                                                    backgroundColor:
                                                                        "#2c53a0",
                                                                    color: "#fff",
                                                                    textAlign:
                                                                        "center",
                                                                }}
                                                            >
                                                                PAYPOINT
                                                            </th>
                                                            {d.paypoint.map(
                                                                function (
                                                                    p,
                                                                    i
                                                                ) {
                                                                    var disc =
                                                                        parseFloat(
                                                                            p.actual
                                                                        ).toFixed(
                                                                            2
                                                                        ) -
                                                                        parseFloat(
                                                                            p.expected
                                                                        ).toFixed(
                                                                            2
                                                                        );
                                                                    var cl;
                                                                    disc < 0
                                                                        ? (cl =
                                                                              "red")
                                                                        : (cl =
                                                                              "green");
                                                                    return (
                                                                        <tr
                                                                            style={{
                                                                                border: "1px solid #4d4d4d",
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    display:
                                                                                        "flex",
                                                                                    justifyContent:
                                                                                        "space-evenly",
                                                                                }}
                                                                            >
                                                                                <td>
                                                                                    <b>
                                                                                        Type:
                                                                                    </b>{" "}
                                                                                    {
                                                                                        p.type
                                                                                    }
                                                                                </td>
                                                                                {!p.type.includes(
                                                                                    "SALES"
                                                                                ) ? (
                                                                                    <>
                                                                                        <td>
                                                                                            <b>
                                                                                                Expected:
                                                                                            </b>{" "}
                                                                                            £
                                                                                            {parseFloat(
                                                                                                p.actual
                                                                                            ).toFixed(
                                                                                                2
                                                                                            )}
                                                                                        </td>
                                                                                        <td>
                                                                                            <b>
                                                                                                Actual:
                                                                                            </b>{" "}
                                                                                            £
                                                                                            {parseFloat(
                                                                                                p.expected
                                                                                            ).toFixed(
                                                                                                2
                                                                                            )}
                                                                                        </td>
                                                                                        <td>
                                                                                            <b>
                                                                                                Discp:
                                                                                            </b>{" "}
                                                                                            <span
                                                                                                style={{
                                                                                                    color: cl,
                                                                                                }}
                                                                                            >
                                                                                                {" "}
                                                                                                £
                                                                                                {parseFloat(
                                                                                                    disc
                                                                                                ).toFixed(
                                                                                                    2
                                                                                                )}
                                                                                            </span>
                                                                                        </td>
                                                                                    </>
                                                                                ) : (
                                                                                    <td>
                                                                                        <b>
                                                                                            Amount:
                                                                                        </b>{" "}
                                                                                        £
                                                                                        {parseFloat(
                                                                                            p.actual
                                                                                        ).toFixed(
                                                                                            2
                                                                                        )}
                                                                                    </td>
                                                                                )}
                                                                            </div>
                                                                        </tr>
                                                                    );
                                                                }
                                                            )}
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </> */}

                                                <>
                                                    {d.keyFuels.expected !=
                                                        null ? (
                                                        <>
                                                            <th
                                                                style={{
                                                                    width: "100%",
                                                                    backgroundColor:
                                                                        "#2c53a0",
                                                                    color: "#fff",
                                                                    textAlign:
                                                                        "center",
                                                                }}
                                                            >
                                                                FUEL
                                                            </th>
                                                            {d.keyFuels.fuelTypes.map(
                                                                function (
                                                                    f,
                                                                    i
                                                                ) {
                                                                    var disc =
                                                                        parseFloat(
                                                                            d
                                                                                .keyFuels
                                                                                .actual
                                                                        ).toFixed(
                                                                            2
                                                                        ) -
                                                                        parseFloat(
                                                                            d
                                                                                .keyFuels
                                                                                .expected
                                                                        ).toFixed(
                                                                            2
                                                                        );
                                                                    var cl;
                                                                    disc < 0
                                                                        ? (cl =
                                                                            "red")
                                                                        : (cl =
                                                                            "green");
                                                                    return (
                                                                        <tr
                                                                            style={{
                                                                                border: "1px solid #4d4d4d",
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    display:
                                                                                        "flex",
                                                                                    justifyContent:
                                                                                        "space-evenly",
                                                                                }}
                                                                            >
                                                                                <td>
                                                                                    <b>
                                                                                        Type:
                                                                                    </b>{" "}
                                                                                    {
                                                                                        f.paymentType
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    <b>
                                                                                        Prism:
                                                                                    </b>{" "}
                                                                                    £
                                                                                    {parseFloat(
                                                                                        f.sum
                                                                                    ).toFixed(
                                                                                        2
                                                                                    )}
                                                                                </td>
                                                                                {f.paymentType ==
                                                                                    "KEY FUELS" ? (
                                                                                    <>
                                                                                        <td>
                                                                                            <b>
                                                                                                Actual:
                                                                                            </b>{" "}
                                                                                            £
                                                                                            {parseFloat(
                                                                                                d
                                                                                                    .keyFuels
                                                                                                    .actual
                                                                                            ).toFixed(
                                                                                                2
                                                                                            )}
                                                                                        </td>
                                                                                        <td>
                                                                                            <b>
                                                                                                Discp:
                                                                                            </b>{" "}
                                                                                            <span
                                                                                                style={{
                                                                                                    color: cl,
                                                                                                }}
                                                                                            >
                                                                                                {" "}
                                                                                                £
                                                                                                {parseFloat(
                                                                                                    disc
                                                                                                ).toFixed(
                                                                                                    2
                                                                                                )}
                                                                                            </span>
                                                                                        </td>
                                                                                    </>
                                                                                ) : (
                                                                                    <>

                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                        </tr>
                                                                    );
                                                                }
                                                            )}
                                                            <tr
                                                                style={{
                                                                    border: "1px solid #4d4d4d",
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        display:
                                                                            "flex",
                                                                        justifyContent:
                                                                            "space-evenly",
                                                                    }}
                                                                >
                                                                    <td>
                                                                        <b>
                                                                            Type:
                                                                        </b>{" "}
                                                                        EPOS
                                                                        Transactions
                                                                    </td>
                                                                    <td>
                                                                        <b>
                                                                            Amount:
                                                                        </b>{" "}
                                                                        £
                                                                        {parseFloat(
                                                                            d.eposFuel
                                                                        ).toFixed(
                                                                            2
                                                                        )}
                                                                    </td>
                                                                </div>
                                                            </tr>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </>

                                                <>
                                                    {d.driveOff.length > 0 ? (
                                                        <>
                                                            <th
                                                                style={{
                                                                    width: "100%",
                                                                    backgroundColor:
                                                                        "#2c53a0",
                                                                    color: "#fff",
                                                                    textAlign:
                                                                        "center",
                                                                }}
                                                            >
                                                                DRIVEOFFS
                                                            </th>
                                                            {d.driveOff.map(
                                                                function (
                                                                    dri,
                                                                    i
                                                                ) {
                                                                    return (
                                                                        <tr
                                                                            style={{
                                                                                border: "1px solid #4d4d4d",
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    display:
                                                                                        "flex",
                                                                                    justifyContent:
                                                                                        "space-evenly",
                                                                                }}
                                                                            >
                                                                                <td>
                                                                                    <b>
                                                                                        Reg:
                                                                                    </b>{" "}
                                                                                    {
                                                                                        dri.plate
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    <b>
                                                                                        Amount:
                                                                                    </b>{" "}
                                                                                    £
                                                                                    {parseFloat(
                                                                                        dri.amount
                                                                                    ).toFixed(
                                                                                        2
                                                                                    )}
                                                                                </td>
                                                                            </div>
                                                                        </tr>
                                                                    );
                                                                }
                                                            )}
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </>

                                                {d.images ? (
                                                    <>
                                                        <th
                                                            style={{
                                                                width: "100%",
                                                                backgroundColor:
                                                                    "#2c53a0",
                                                                color: "#fff",
                                                                textAlign:
                                                                    "center",
                                                            }}
                                                        >
                                                            IMAGES
                                                        </th>
                                                        <tr
                                                            style={{
                                                                border: "1px solid #4d4d4d",
                                                            }}
                                                        >
                                                            {d.images.images.map(
                                                                function (
                                                                    img,
                                                                    i
                                                                ) {
                                                                    return (
                                                                        <img
                                                                            src={
                                                                                img.image
                                                                            }
                                                                            width="300px"
                                                                            style={{
                                                                                padding:
                                                                                    "10px",
                                                                            }}
                                                                            onClick={()=>seeImage(img.image)}
                                                                        />
                                                                    );
                                                                }
                                                            )}
                                                        </tr>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                                {d.notes ? (
                                                    <>
                                                        <th
                                                            style={{
                                                                width: "100%",
                                                                backgroundColor:
                                                                    "#2c53a0",
                                                                color: "#fff",
                                                                textAlign:
                                                                    "center",
                                                            }}
                                                        >
                                                            Notes
                                                        </th>
                                                        {d.notes.notes.map(
                                                            function (n, i) {
                                                                return (
                                                                    <tr
                                                                        style={{
                                                                            border: "1px solid #4d4d4d",
                                                                        }}
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                display:
                                                                                    "flex",
                                                                                justifyContent:
                                                                                    "space-evenly",
                                                                                padding:
                                                                                    "10px",
                                                                            }}
                                                                        >
                                                                            <td>
                                                                                <b>
                                                                                    Note:
                                                                                </b>{" "}
                                                                                {
                                                                                    n
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                <b>
                                                                                    User:
                                                                                </b>{" "}
                                                                                {
                                                                                    d
                                                                                        .notes
                                                                                        .user
                                                                                }
                                                                            </td>
                                                                        </div>
                                                                    </tr>
                                                                );
                                                            }
                                                        )}
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </table>
                                            <br className="endEod" />
                                            <div
                                                style={{
                                                    pageBreakBefore: "always",
                                                }}
                                            >
                                                {" "}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </ErrorBoundary>
                    {!fromInvestigation ? (
                        <button className="pdfBtn" onClick={handlePrint}>
                            Generate PDF
                        </button>
                    ) : (
                        <></>
                    )}
                </div>
            ) : (
                <></>
            )}
            <Modal
                open={showImage}
                onClose={closeImage}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <EodImage image={image} />
            </Modal>
        </main>
    );
}
