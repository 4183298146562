import { useEffect, useState } from "react";
import { Box, Button, Alert } from "@mui/material";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import Modal from "@mui/material/Modal";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40vw",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
    maxHeight: "70vh",
};

export default function MiscModal({ handleClose, item, handleItemUpdates }) {
    //Main - Admin
    const url = "https://nitropos-backend.nitrosolutions.co.uk";
    //const url = "http://10.0.0.174:4022";
    //Product list
    const [productList, setProductList] = useState([]);
    //Actual Misc info
    const [productInput, setProductInput] = useState("");
    const [actualProduct, setActualProduct] = useState("");
    const [actualQuantity, setActualQuantity] = useState(0);
    const [quantityCheckDone, setQuantityCheckDone] = useState(false);

    //Modal
    const [openCheckModal, setOpenCheckModal] = useState(false);

    //Alerts
    const [alert, setAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");
    const [disable, setDisable] = useState(false);

    //If product input is changed then the product list clears
    useEffect(() => {
        setProductList([]);
        setActualQuantity(0);
    }, [productInput]);

    //Show Alert
    const showAlert = (message, severity, duration = 3000) => {
        setDisable(true);
        setMessage(message);
        setSeverity(severity);
        setAlert(true);
        setTimeout(() => {
            setDisable(false);
            setAlert(false);
            setMessage("");
            setSeverity("");
        }, duration);
    };

    //Narrow the search for the product
    const handleNarrowSearch = () => {
        let data = {
            name: productInput,
        };
        if (productInput === "") {
            showAlert("Please type in a product name", "warning");
        } else {
            axios
                .post(`${url}/claims/products/name`, data)
                .then((res) => {
                    if (res.data.length > 100) {
                        showAlert(
                            "Please be more specific, too many products returned",
                            "warning"
                        );
                    } else if (res.data.length === 0) {
                        showAlert("No items returned", "warning");
                    } else {
                        if (res.data.length === 1) {
                            setActualProduct(
                                `${res.data[0].name} - ${res.data[0].eposId}`
                            );
                        }
                        setProductList(res.data);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    //Adds actual item info to updated Item array
    const handleConfirm = () => {
        //Split eposID out of actual product
        let productSplit = actualProduct.split(" ");
        let actualEposId = parseInt(productSplit[productSplit.length - 1]);

        if (productInput === "") {
            showAlert("Please enter the product name", "warning");
        } else if (
            actualProduct === "" ||
            actualQuantity === "" ||
            actualQuantity === 0
        ) {
            showAlert(
                "Please select actual product name and quantity",
                "warning"
            );
        } else if (String(actualQuantity).includes("-")) {
            showAlert("Quantity can not be negative", "warning");
        } else if (actualQuantity != item.ingestQty && !quantityCheckDone) {
            setOpenCheckModal(true);
            setQuantityCheckDone(true);
        } else {
            handleItemUpdates(
                item.mpId,
                actualEposId,
                actualProduct,
                actualQuantity
            );
            handleClose();
        }
    };

    return (
        <Box sx={style} className="misc_item_modal">
            <h2>Search Misc Item</h2>

            <div className="item_info">
                <p>
                    <b>Misc Item:</b> {item.name}
                </p>
                <p>
                    <b>Misc Price:</b> £{item.price}
                </p>
                <p>
                    <b>Misc Quantity:</b> {item.ingestQty}
                </p>
            </div>

            <div className="search_section">
                <input
                    type="text"
                    onChange={(e) => setProductInput(e.target.value)}
                    placeholder="Type product name"
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            handleNarrowSearch();
                        }
                    }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNarrowSearch}
                    disable={disable}
                >
                    <SearchIcon />
                </Button>
            </div>
            {productList.length > 1 ? (
                <div className="select_section">
                    <input
                        list="products_search"
                        placeholder="Search actual product"
                        id="data_search"
                        onChange={(e) => setActualProduct(e.target.value)}
                    />
                    <datalist id="products_search">
                        {productList.map((product, i) => {
                            return (
                                <option
                                    value={`${product.name} - ${product.eposId}`}
                                    id={i}
                                    key={i}
                                >
                                    {product.name} - {product.barcode}
                                </option>
                            );
                        })}
                    </datalist>
                    <input
                        type="number"
                        min="0"
                        pattern="[0-9]*"
                        onChange={(e) => setActualQuantity(e.target.value)}
                        value={actualQuantity}
                        placeholder="Qty"
                    />
                </div>
            ) : productList.length == 1 ? (
                <div className="select_section">
                    <input
                        list="products_search"
                        placeholder="Search actual product"
                        id="data_search"
                        disabled={true}
                        value={`${productList[0].name} - ${productList[0].eposId}`}
                    />
                    <input
                        type="number"
                        min="0"
                        pattern="[0-9]*"
                        onChange={(e) => setActualQuantity(e.target.value)}
                        value={actualQuantity}
                    />
                </div>
            ) : null}
            <div className="btns_section">
                {!alert ? (
                    <>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={handleClose}
                            disable="disable"
                        >
                            close
                        </Button>
                        <Button
                            variant="contained"
                            color="success"
                            onClick={handleConfirm}
                            disable={disable}
                        >
                            confirm
                        </Button>
                    </>
                ) : (
                    <Alert className="modal_alert" severity={severity}>
                        {message}
                    </Alert>
                )}
            </div>
            {/* MODAL - WARNING IF DIFF QUANTITY ENTERED */}
            <Modal
                open={openCheckModal}
                aria-labelledby="Quantity Check Modal"
                aria-describedby="Quantity Check Modal"
            >
                <Box sx={style} className="misc_item_modal">
                    <h2>Quantity Warning</h2>

                    <p>
                        The Quantity you have entered <b>({actualQuantity})</b>{" "}
                        is different from the Misc quantity entered at time of
                        scan <b>({item.ingestQty})</b>. Please make sure you are
                        happy with this before proceeding.
                    </p>

                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => setOpenCheckModal(false)}
                    >
                        close
                    </Button>
                </Box>
            </Modal>
        </Box>
    );
}
