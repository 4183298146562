
import { useEffect, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import { IconButton } from "@mui/material";
import "../App.scss";
import Tanks from "./Tanks";
import axios from 'axios'
import "./test.css"

export default function FuelLevel(){
    const [site, setSite] = useState("")
    const [showSite, setShowSite] = useState(false)
    const [allSites, setAllSites] = useState([])

    useEffect(() => {
        axios.get("https://nitropos-backend.nitrosolutions.co.uk/fuel/all").then((res) => {
            setAllSites(res.data)
        }).catch((err) => {
            console.log(err)
        })
        const queryParams = new URLSearchParams(window.location.search);
        const siteParam = queryParams.get('site');
        if (siteParam) {
            setSite(siteParam)
            setShowSite(true)
        } else {
            setSite("")
            setShowSite(false)
        }
    }, [])

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            submit();
        }
    }

    function submit() {
        console.log(site)
        const queryParams = new URLSearchParams(window.location.search);
        const siteParam = queryParams.get('site');
        if (siteParam) {
            queryParams.delete("site")
        }
        setShowSite(true)
    }

    return(
        <main>
            <section className="level">
            <h2>Select Site:</h2>
            <div className="row">
                <select onChange={(e) => setSite(e.target.value)} onKeyDown={handleKeyDown}>
                    <option>--Select Site--</option>
                    <option>Autoport</option>
                    <option>Bearsden</option>
                    <option>Braeside</option>
                    <option>Bridgeton</option>
                    <option>Crowwood</option>
                    <option>Rutherglen</option>
                </select>
                <IconButton>
                    <SearchIcon fontSize="large" color="primary" onClick={() => submit()} />
                </IconButton>
            </div>
            {showSite ? (
                <div className="showSite">
                    <Tanks site={site} />
                </div>
            ) : (
                <>
                    <h2>No Data To Show</h2>
                </>
            )}
            </section>
        </main>
    )
}