import Chart from "react-apexcharts";

export default function PriceHistory({ prices, dates }) {
    let chart = {
        options: {
            chart: {
                background: "#fff",
            },
            xaxis: {
                categories: dates,
            },
            stroke: { curve: "smooth" },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 250,
                            height: 250,
                        },
                        legend: {
                            position: "bottom",
                        },
                    },
                },
            ],
        },
        series: [
            {
                name: "RRP",
                data: prices,
            },
        ],
    };

    return (
        <section className="details">
            <div className="price_history">
                <Chart
                    options={chart.options}
                    series={chart.series}
                    type="line"
                    width="350"
                />
            </div>
        </section>
    );
}
