import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";

export default function PackagingItem({ product, handleInputQuantity }){
    const [quantity, setQuantity] = useState("");

    return(
       <Accordion sx={{ width: "70%",marginBottom:"5px" }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{ fontSize: "1.2rem"}}
            >
                {product}
            </AccordionSummary>
            <AccordionDetails sx={{ borderTop: "2px dashed black " }}>
                Please input an order quantity for <b>{product}:</b>
                <br />
                <input
                    onChange={(e) => {
                        setQuantity(e.target.value);
                        handleInputQuantity(product, e.target.value);
                    }}
                    type="number"
                    pattern="[0-9]*"
                    value={quantity}
                />
            </AccordionDetails>
        </Accordion>
    )
}