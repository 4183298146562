//use for store managers and supervisors only routes

import { Navigate } from "react-router-dom";

const StoreManagerProtect = ({ children }) => {
  let userD = localStorage.getItem("userDetails");
  if (!userD) {
    window.location.replace("/");
  }
  let user = JSON.parse(userD);
  if (
    user.user.role !== "Developer" &&
    user.user.role !== "Manager" &&
    user.user.role !== "Store Manager" &&
    user.user.role !== "Food Manager" &&
    user.user.role !== "Supervisor"
  ) {
    return <Navigate to="/not-allowed" replace />;
  }
  return children;
};

export { StoreManagerProtect };
