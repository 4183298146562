import axios from 'axios';
import { useState, useEffect, useRef } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Modal, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Alert } from '@mui/material';


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40vw",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
    "@media(max-width: 900px)": {
        width: "150vw",
        maxHeight: "70vh",
        overflowX: "scrollable",
        overflowY: "scrollable",
    },
};

export default function CCTVModal({ handleClose, request, fuelData, rel }) {

    const url = process.env.REACT_APP_ANPR_URL;
    // const url = "http://10.0.0.34:4024"

    const [filteredData, setFilteredData] = useState([]);
    const [selectedFuelId, setSelectedFuelId] = useState(null);
    const [alert, setAlert] = useState({ message: "", severity: "" });

    useEffect(() => {

        fuelData.map((data, i) => {
            let filtered = fuelData.filter(data => data.investigationId === request.siteId);
            setFilteredData(filtered);
        })

        setFilteredData(fuelData.filter((fuel) => fuel.locationId == request.siteId))

    }, []);


    const submitData = async () => {
        if (!selectedFuelId) {
            setAlert({ message: "Please select a row first!", severity: "warning" });
        }

        const data = {
            requestedId: request.requestId,
            fuelId: selectedFuelId,
        };

        try {
            const response = await axios.post(`${url}/cctv-system/FuelMatch`, data);
            setAlert({ message: "Data submitted successfully!", severity: "success" });
            handleClose();
            rel();

        } catch (error) {
            setAlert({ message: "Failed to submit data.", severity: "error" });
        }
    };

    return (
        <Box sx={style} className="all_modals">
            <h2>CCTV Drive-off Details</h2>
            <Typography>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Site</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Pump No</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell>Staff</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableCell>{request.site}</TableCell>
                            <TableCell>{new Date(request.date).toLocaleDateString("en-GB")}{" "}{request.time}</TableCell>
                            <TableCell>{request.pump}</TableCell>
                            <TableCell>£{request.amount}</TableCell>
                            <TableCell>{request.staffName}</TableCell>

                        </TableBody>
                    </Table>
                </TableContainer>

                <br />
                <h2>Pending Drive Offs</h2>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Site</TableCell>
                                <TableCell>Payment Time</TableCell>
                                <TableCell>Pump No</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell>FuelType</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {/* MAKE DISPLAY DATA */}
                            {filteredData.map((data) => (

                                <TableRow
                                    key={data.fuelId}
                                    onClick={() => setSelectedFuelId(data.fuelId)}
                                    style={{
                                        cursor: "pointer",
                                        backgroundColor: selectedFuelId === data.fuelId ? 'yellow' : 'white',
                                    }}>
                                    <TableCell>{data.siteName}</TableCell>
                                    <TableCell>{new Date(data.paymentTime).toLocaleDateString("en-GB")}{" "}{request.time}</TableCell>
                                    <TableCell>{data.pumpNo}</TableCell>
                                    <TableCell>£{data.fuelValue}</TableCell>
                                    <TableCell>{data.fuelType}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Typography>
            <br />

            {alert.message && <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>}

            <br />
            <section
                style={{ display: "flex", justifyContent: "space-around" }}
            >
                <Button variant="contained" color="error" onClick={handleClose}>Close</Button>
                <Button variant="contained" color="warning" onClick={submitData}>Submit</Button>
            </section>



        </Box>
    );


}
