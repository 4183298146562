// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tick__svg {
  width: 100px;
  display: block;
  margin: 40px auto 0;
}
.tick__svg .path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}
.tick__svg .path.circle {
  animation: dash 1s ease-in-out;
}
.tick__svg .path.check {
  stroke-dashoffset: -100;
  animation: dash-check 1s 0.35s ease-in-out forwards;
}
.tick__svg .path.line {
  stroke-dashoffset: 1000;
  animation: dash 0.9s 0.35s ease-in-out forwards;
}
@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/animations/animations.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,cAAA;EACA,mBAAA;AACJ;AAAI;EACI,sBAAA;EACA,oBAAA;AAER;AADQ;EAEE,8BAAA;AAGV;AADQ;EACI,uBAAA;EAEA,mDAAA;AAGZ;AADQ;EACI,uBAAA;EAEA,+CAAA;AAGZ;AAWA;EACI;IACE,uBAAA;EAAJ;EAEE;IACE,oBAAA;EAAJ;AACF;AAYA;EACI;IACE,uBAAA;EAFJ;EAIE;IACE,sBAAA;EAFJ;AACF","sourcesContent":[".tick__svg{\n    width: 100px;\n    display: block;\n    margin: 40px auto 0;\n    .path {\n        stroke-dasharray: 1000;\n        stroke-dashoffset: 0;\n        &.circle {\n          -webkit-animation: dash 1s ease-in-out;\n          animation: dash 1s ease-in-out;\n        }\n        &.check {\n            stroke-dashoffset: -100;\n            -webkit-animation: dash-check 1s .35s ease-in-out forwards;\n            animation: dash-check 1s .35s ease-in-out forwards;\n        }\n        &.line {\n            stroke-dashoffset: 1000;\n            -webkit-animation: dash .9s .35s ease-in-out forwards;\n            animation: dash .9s .35s ease-in-out forwards;\n        }\n    }\n}    \n\n@-webkit-keyframes dash {\n    0% {\n      stroke-dashoffset: 1000;\n    }\n    100% {\n      stroke-dashoffset: 0;\n    }\n}\n  \n@keyframes dash {\n    0% {\n      stroke-dashoffset: 1000;\n    }\n    100% {\n      stroke-dashoffset: 0;\n    }\n}\n  \n@-webkit-keyframes dash-check {\n    0% {\n      stroke-dashoffset: -100;\n    }\n    100% {\n      stroke-dashoffset: 900;\n    }\n}\n  \n@keyframes dash-check {\n    0% {\n      stroke-dashoffset: -100;\n    }\n    100% {\n      stroke-dashoffset: 900;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
