import React, { useState, Component } from "react";
import Box from "@mui/material/Box";
import StoreNav from "../StoreNav";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PersonIcon from "@mui/icons-material/Person";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo,
        });
        // You can also log error messages to an error reporting service here
    }

    render() {
        if (this.state.errorInfo) {
            // Error path
            return (
                <div>
                    <h2>Something went wrong.</h2>
                    <details style={{ whiteSpace: "pre-wrap" }}>
                        {this.state.error && this.state.error.toString()}
                        <br />
                        {this.state.errorInfo.componentStack}
                    </details>
                    <h3>An Alert has been sent to IT</h3>
                </div>
            );
        }
        // Normally, just render children
        return this.props.children;
    }
}

const StaffDrawer = () => {
    const [comingSoon, setComingSoon] = useState(true);
    const [openDrawer, setOpenDrawer] = useState(false);
    const drawerMargin = "12vh";
    const mobileDrawerMargin = "6vh";

    const toggleDrawer = () => (event) => {
        if (
            event &&
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setOpenDrawer(!openDrawer);
    };

    const handleChange = (key) => {
        if (key === "Coming Soon") {
            setComingSoon(true);
        }
    };
    return (
        <>
            <Box sx={{ display: "flex" }}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    sx={{
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                        padding: "0",
                    }}
                >
                    <Toolbar
                        sx={{ width: "100vw", backgroundColor: "#2c53a0" }}
                    >
                        <StoreNav />
                    </Toolbar>
                </AppBar>

                <Button
                    endIcon={<ChevronRightIcon />}
                    onClick={toggleDrawer(true)}
                    sx={{
                        backgroundColor: "#fff",
                        height: "5vh",
                        marginTop: "20vh",
                        position: "absolute",
                        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        ["@media(max-width: 600px)"]: {
                            marginTop: "13vh",
                        },
                    }}
                >
                    Menu
                </Button>

                <SwipeableDrawer
                    open={openDrawer}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                >
                    <Toolbar
                        sx={{
                            marginTop: drawerMargin,
                            ["@media(max-width: 600px)"]: {
                                marginTop: mobileDrawerMargin,
                            },
                        }}
                    />
                    <Box
                        onClick={toggleDrawer(false)}
                        onKeyDown={toggleDrawer(false)}
                    >
                        <List>
                            <ListItem
                                disablePadding
                                onClick={() => handleChange("Coming Soon")}
                            >
                                <ListItemButton>
                                    <ListItemIcon>
                                        <PersonIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"Coming Soon"} />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Box>
                </SwipeableDrawer>
                <Box
                    component="main"
                    sx={{ flexGrow: 1, p: 3, marginTop: "18vh" }}
                >
                    {comingSoon ? (
                        <ErrorBoundary>
                            <h1>Coming Soon!</h1>
                        </ErrorBoundary>
                    ) : (
                        <h1>You don't have permissions for this</h1>
                    )}
                </Box>
            </Box>
        </>
    );
};

export default StaffDrawer;
