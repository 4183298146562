import { useState, useEffect } from "react";
import './v4u.scss';
import axios from 'axios';
import Order from "./Order";
import Button from '@mui/material/Button';
import Tick from "../../animations/Tick";
import Alert from '@mui/material/Alert';
import "./v4u.scss";

export default function ReviewOrder() {
    const [orderId, setOrderId] = useState("")
    const [data, setData] = useState([])
    const [site, setSite] = useState("")
    const [showTick, setShowtick] = useState(false)
    const [showErrorAlert, setShowErrorAlert] = useState(false)
    const [message, setMessage] = useState("")

    useEffect(() => {
        const url = new URL(window.location.href)
        let id = url.searchParams.get("id")
        setOrderId(id)
        //axios call with id to get details
        axios.get(`https://nitropos-backend.nitrosolutions.co.uk/edn/v4u/GetById/${id}`)
        .then((res) => {
            setData(res.data.products)
            setSite(res.data.site)
        }).catch((err) => {
            console.log(err)
        })
    },[])

    //changes the stock ordered
    const changeQty = (row, index) => {
        let dataCopy = data;
        dataCopy[index] = row
        setData(dataCopy)
    }

    //sends the order with the changes to the BE
    const handleOrder = () => {
        document.getElementById("order_but").style.display = "none"
        let body = {
            id: orderId,
            products: data,
        }
        axios.post("https://nitropos-backend.nitrosolutions.co.uk/edn/v4u/order/reviewed", body)
        .then((res) => {
            console.log(res.data)
            if(res.data === true){
                setShowtick(true)
                setTimeout(() => {
                    setShowtick(false)
                    document.getElementById("order_but").style.display = "flex"
                },2500)    
            } else{
                setMessage("Ooops, something went wrong, please try again")
                setShowErrorAlert(true)
                setTimeout(() => {
                    setShowErrorAlert(false)
                    setMessage("")
                    document.getElementById("order_but").style.display = "flex"
                },3000)    
            }
        }).catch((err) => {
            console.log(err)
            setMessage("Ooops, something went wrong, please try again")
            setShowErrorAlert(true)
            setTimeout(() => {
                setShowErrorAlert(false)
                setMessage("")
                document.getElementById("order_but").style.display = "flex"
            },3000)
        })
    }

  return (
    <section className="review__order">
      <h1>This is a review page for the order</h1>
      <h2>Site: {site}</h2>
      <table>
        <thead>
            <th>Product</th>
            <th>Current Stock</th>
            <th>Quantity Ordered</th>
        </thead>
        <tbody>
           {data && data.length > 0 ? (
            data.map(function(d,i){
                return(
                    <Order changeQty={changeQty} data={d} index={i}/>
                )
            })
           ):(
            <tr>
                <td colspan={3}>No Data To Show</td>
            </tr>
           )} 
        </tbody>
      </table>
      <Button variant="contained" color="success" onClick={() => handleOrder()} id="order_but">Accept Order</Button>
      {showTick ? <Tick />:<></>}
      {showErrorAlert ? <Alert severity="warning">{message}</Alert>:<></>}
    </section>
  );
}
