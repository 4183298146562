import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import axios from "axios";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90vw",
    minHeight: "20vw",
    maxHeight: "80vw",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
};

export default function IndvBanking(props) {
  const [data, setData] = useState(props.data);
  const [denominations, setDenominations] = useState([]);
  const [travelData, setTravelData] = useState([]);
  //modal function
  const [openDenominations, setOpenDenominations] = useState(false);
  const handleOpenDenominations = () => {
    axios
      .get(`https://nitropos-backend.nitrosolutions.co.uk/eos/safeDrops/history/${props.data.eodId}`)
      .then((res) => {
        setOpenDenominations(!openDenominations);
        setDenominations(res.data.denominations);
        setTravelData(res.data.pickedUp);
      })
      .catch((err) => {
        console.log(err);
      });
  };

    const handleCloseDenominations = () => {
        setOpenDenominations(false);
    };

    useEffect(() => {
        setData(props.data);
    }, [props]);

    return (
        <>
            <tr onClick={handleOpenDenominations} style={{ cursor: "pointer" }}>
                <td>{data.siteName}</td>
                <td>{new Date(data.eodStart).toLocaleDateString()}</td>
                <td>{new Date(data.eodEnd).toLocaleDateString()}</td>
                <td>{data.bagId}</td>
                <td>{data.payInSlip}</td>
                <td>£{parseFloat(data.amount).toFixed(2)}</td>
                <td>{props.user[0].toUpperCase()}</td>
                <td>{new Date(data.depositedDate).toLocaleDateString()}</td>
                {/* <td>{data.bagNote}</td> */}
            </tr>
            {openDenominations ? (
                <div id="test" onClick={() => handleCloseDenominations()}>
                    <h3>
                        Denominations for {data.siteName} EOD
                        {new Date(data.eodStart).toLocaleDateString()} -
                        {new Date(data.eodEnd).toLocaleDateString()}
                    </h3>
                    <section className="transit__modal">
                        <div className="modal__denominations">
                            <h3>Denominations in Bag</h3>
                            <table>
                                <thead>
                                    <th>Denomination</th>
                                    <th>Value</th>
                                </thead>
                                <tbody>
                                    {denominations.length > 0 ? (
                                        denominations.map(function (d, i) {
                                            return (
                                                <tr>
                                                    <td>{d.name}</td>
                                                    <td>{d.value}</td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan={2}>
                                                No denominations
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div className="modal__denominations">
                            <h3>Transit Information</h3>
                            <table>
                                <thead>
                                    <th>Date</th>
                                    <th>Transit</th>
                                    <th>By</th>
                                </thead>
                                <tbody>
                                    {travelData.length > 0 ? (
                                        travelData.map(function (t, i) {
                                            return (
                                                <tr>
                                                    <td>
                                                        {new Date(
                                                            t.dateTime
                                                        ).toLocaleDateString(
                                                            "en-GB"
                                                        )}
                                                    </td>
                                                    <td>{t.name}</td>
                                                    <td
                                                        style={{
                                                            wordBreak:
                                                                "break-all",
                                                        }}
                                                    >
                                                        {t.user}
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan={2}>
                                                No denominations
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </section>
                </div>
            ) : (
                <></>
            )}
        </>
    );
}
