//use for devs and admin parts of NitroPOS

import { Navigate } from "react-router-dom";

const DevProtected = ({ children }) => {
  let userD = localStorage.getItem("userDetails");
  if (!userD) {
    window.location.replace("/");
  }
  let user = JSON.parse(userD);
  if (user && user.user.role !== "Developer" && user.user.role !== "HR") {
    return <Navigate to="/not-allowed" replace />;
  }
  return children;
};

export { DevProtected };
