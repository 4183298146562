import { useState, useEffect } from "react";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";

export default function IndvHols(props) {
  const employee = props.employee;
  const holidays = props.employee.holidays;
  const month = props.month;
  const [holidayArray, setHolidayArray] = useState([]);

  useEffect(() => {
    var finalArr = [];
    for (let p = 0; p < props.month.length; p++) {
      var arrIdx = finalArr.findIndex((b) => b.days == props.month[p] + 1);
      if (arrIdx < 0) {
        var idx = props.employee.holidays.findIndex(
          (x) => moment(x.startDate).format("D") == props.month[p] + 1
        );
        if (idx < 0) {
          finalArr.push({ className: "", days: props.month[p] + 1, taken: "" });
        } else {
          const daysInBetween = enumerateDaysBetweenDates(
            props.employee.holidays[idx].startDate,
            props.employee.holidays[idx].endDate
          );
          const startOfMonth = moment(`${props.currentMonth}/${props.currentYear}`, "MMMM/YYYY")
            .startOf("month")
            .format("DD/MM/YYYY");
          for (let d = 0; d < daysInBetween.length; d++) {
            let taken;
            var leave = "";
            var isInCurrentMonth = moment(
              daysInBetween[d],
              "DD/MM/YYYY"
            ).isSame(moment(startOfMonth, "DD/MM/YYYY"), "month");
            if (isInCurrentMonth) {
              taken = props.employee.holidays[idx].daysTaken
              if (props.employee.holidays[idx].leaveId == 1) {
                leave = "holiday";
              } else if (props.employee.holidays[idx].leaveId == 2) {
                leave = "unpaid";
              } else if (props.employee.holidays[idx].leaveId == 3) {
                leave = "sick";
              } else if (props.employee.holidays[idx].leaveId == 4) {
                leave = "maternity";
              } else if (props.employee.holidays[idx].leaveId == 5) {
                leave = "awol";
              } else if (props.employee.holidays[idx].leaveId == 6) {
                leave = "other";
              }
              if (daysInBetween.length == 1) {
                finalArr.push({ className: leave, days: props.month[p] + 1, taken: taken });
              } else {
                var day1 = moment(daysInBetween[d], "DD/MM/YYYY").format("D");
                var findIdx = finalArr.findIndex((a) => a.days == day1);
                if (findIdx >= 0) {
                  finalArr[findIdx].className = leave;
                  finalArr[findIdx].taken = taken;
                } else {
                  finalArr.push({
                    className: leave,
                    days: day1,
                    taken: taken,
                  });
                }
              }
            } else {
              var checkIdx = finalArr.findIndex(
                (a) => a.days == props.month[p] + 1
              );
              if (checkIdx < 0) {
                finalArr.push({
                  className: "",
                  days: props.month[p] + 1,
                  taken: "",
                });
              }
            }
          }
        }
      }
    }
    var sortedArr = finalArr.sort((a, b) => a.days - b.days);
    setHolidayArray(sortedArr);
  }, [props]);

  const enumerateDaysBetweenDates = function (startDate, endDate) {
    var now = moment(startDate).clone(),
      dates = [];
    var end = moment(endDate);
    while (now.isSameOrBefore(end)) {
      dates.push(now.format("DD/MM/YYYY"));
      now.add(1, "days");
    }
    return dates;
  };

  return (
    <tr>
      <td colspan={4}>{employee.staffName}</td>
      {holidayArray.map(function (d, i) {
        var dayNo = moment().format("D");
        var month = moment().format("MMMM")
        var year = moment().format("YYYY")
        var bg; (dayNo == (d.days) && month == props.currentMonth && year == props.currentYear) ? bg = "lightblue" : bg = "";
        return (
          <Tooltip title={`holiday: ${d.className} - days taken: ${d.taken}`}>
            <td style={{ backgroundColor: bg }} className={`${d.className}`}>{d.days}</td>
          </Tooltip>
        );
      })}
    </tr>
  );
}
