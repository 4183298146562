import { useState, useEffect } from "react";
import axios from "axios";
import IndvRequest from "./IndvRequest";
import Alert from "@mui/material/Alert";
import { getEmployeeDetailsById } from "../utils/apiUtils";
import { decryptData } from "../utils/cryptoUtils";

export default function Requests() {
    const [user, setUser] = useState("");
    const [reload, setReload] = useState(false);
    const [token, setToken] = useState("");
    //request
    const [requests, setRequests] = useState([]);
    const [isMobile, setIsMobile] = useState(false);
    //alerts
    const [showAlert, setShowAlert] = useState(false);
    const [severity, setSeverity] = useState("");
    const [message, setMessage] = useState("");

    useEffect(() => {
        let userD = localStorage.getItem("userDetails");
        let user = JSON.parse(userD);
        setUser(user.user.user);
        //checks the token for the HR BE
        let getToken = localStorage.getItem("auth-token");
        const config = {
            headers: { "x-auth-token": getToken },
        };

        axios
            .post(
                "https://hr-be.racetrackpitstop.co.uk/holiday/admin/pending/new",
                {
                    user: user.user.user,
                },
                config
            )
            .then(async (res) => {
                // console.log(res.data)
                let requests = res.data;
                for (let r = 0; r < requests.length; r++) {
                    await getEmployeeDetailsById(
                        requests[r].employeeDetailsId,
                        getToken
                    )
                        .then((response) => {
                            requests[r].firstName = decryptData(
                                response.firstName,
                                process.env.REACT_APP_EMP_KEY
                            );
                            requests[r].lastName = decryptData(
                                response.lastName,
                                process.env.REACT_APP_EMP_KEY
                            );
                        })
                        .catch((err) => {
                            console.log(
                                `Error getting employee details: ${err}`
                            );
                        });
                }
                setRequests(requests);
            })
            .catch((err) => {
                console.log(err);
                if (err.response.data.error) {
                    setMessage(err.response.data.error);
                    setSeverity("error");
                    setShowAlert(true);
                    setTimeout(() => {
                        setShowAlert(false);
                        setSeverity("");
                        setMessage("");
                    }, 4000);
                }
            });

        //checks if it is a mobile for the change of style
        if (window.innerWidth < 690) {
            setIsMobile(true);
        }
    }, [reload, window.innerWidth]);

    const rel = () => {
        setReload(!reload);
    };

    return (
        <section className="staff__management">
            <h1>Review your requests</h1>
            {!isMobile ? (
                <table className="hol_app_table">
                    <thead>
                        <th>Staff Name</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Total Days</th>
                        <th>Reason</th>
                        <th>Paid</th>
                        <th>Approve</th>
                        <th>Deny</th>
                    </thead>
                    <tbody>
                        {requests.length > 0 ? (
                            requests.map(function (request, i) {
                                return (
                                    <IndvRequest
                                        request={request}
                                        user={user}
                                        rel={rel}
                                        key={i}
                                    />
                                );
                            })
                        ) : (
                            <tr>
                                <td colspan={8}>No requests to approve</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            ) : showAlert ? (
                <Alert severity={severity} sx={{ marginTop: "2%" }}>
                    {message}, please log out and log back in again
                </Alert>
            ) : (
                <>
                    {requests.length > 0 ? (
                        requests.map(function (request, i) {
                            return (
                                <IndvRequest
                                    request={request}
                                    user={user}
                                    rel={rel}
                                />
                            );
                        })
                    ) : (
                        <h1>No requests to approve</h1>
                    )}
                </>
            )}
        </section>
    );
}
