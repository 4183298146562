import {useState, useEffect} from 'react'

export default function IndvRecon(props){
    const [data, setData] = useState({})
    const [discrepancy, setDiscrepancy] = useState(0)

    useEffect(() => {
        setData(props.data)
    },[])

    const checkDiscrepancy = (id, e) => {
        let result = 0
        result =  e - data.sum
        setDiscrepancy(parseFloat(result).toFixed(2))
        props.handleShiftReconIndv(id, e)
    }

    return(
        <tr>
        <td>{data.deviceName}</td>
        <td>{data.openedBy}</td>
        <td>{new Date(data.startTime).toUTCString()}</td>
        <td>{data.sum}</td>
        <td>
          £
          <input
            type="number"
            min={0}
            onChange={(e) =>
                checkDiscrepancy(data.eosId, e.target.value)
            }
          />
        </td>
        <td>{discrepancy}</td>
      </tr>

    )
}