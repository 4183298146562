import React, { useState, useEffect } from "react";
import axios from "axios";
import Labels from "./Labels";
import styles from "./styles/Main.module.css";
import html2pdf from "html2pdf.js";
import Info from "./Info";
import Button from "@mui/material/Button";
import useSound from "use-sound";
import warningAlert from "../alerts/warning.mp3";
import foundAlert from "../alerts/found.mp3";
import Alert from '@mui/material/Alert';


export default function Search() {
  const [playbackRate, setPlaybackRate] = useState(0.75);
  const [results, setResults] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(0);
  const [update, setUpdate] = useState(false);
  const [message, setMessage] = useState("");
  const [site, setSite] = useState("");
  const [errorMessage, setErrorMessage] = useState(false)
  const brand = "tubbees";

  const [play, { stop }] = useSound(warningAlert, {
    playbackRate,
    volume: 0.8,
  });
  const [playFound, { stopFound }] = useSound(foundAlert, {
    playbackRate,
    volume: 0.8,
  });

  function handleWarning() {
    document.getElementById("warningBtn").click();
  }

  function playWarningAlert() {
    setPlaybackRate(playbackRate + 0.1);
    play();
  }

  function playFoundAlert() {
    setPlaybackRate(playbackRate + 0);
    playFound();
  }

  function handleFound() {
    document.getElementById("foundBtn").click();
  }

  useEffect(async() => {
    const url2 = new URL(window.location.href);
    var s = url2.searchParams.get("site");
    setSite(s);
    await axios
      .get(`https://nitropos-backend.nitrosolutions.co.uk/labels/${s}/${brand}`)
      .then((res) => {
        if (res.data.barcodes.length > 0) {
          setMessage("Loading Pre-Saved Barcode....");
          const url2 = new URL(window.location.href);
          url2.searchParams.set("barcodes", res.data.barcodes);
          window.history.replaceState(null, null, url2);
        } else {
          setMessage("");
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setTimeout(async() => {
      var url = new URL(window.location.href);
      var barcodes = url.searchParams.get("barcodes");
      if (barcodes) {
        var array = barcodes.replace(/[\[\]']+/g, "");
        const myArray = array.split(",");
        for (let i = 0; i < myArray.length; i++) {
          await preFilled(myArray[i]);
          await pageNumber();
        }
        setMessage("");
      }
    }, 1500);
  }, [update]);

  const pageNumber = async() => {
    if (results.length > 0 && results.length < 29) {
      setPage(1);
    } else if (results.length > 28 && results.length < 57) {
      setPage(2);
    } else if (results.length > 56 && results.length < 85) {
      setPage(3);
    } else if (results.length > 84 && results.length < 113) {
      setPage(4);
    } else if (results.length > 112) {
      setPage(5);
    } else {
      setPage(0);
    }
  };

  const preFilled = async (val) => {
    if (results.length < 140) {
      // setUpdate(!update)
      // Search Tubbees-Products database for the search value (Needs to be either the barcode or name exactly)
      await axios
        .get(`https://nitropos-backend.nitrosolutions.co.uk/labelgen/findone/${val}`)
        .then(async(res) => {
          // Add the result to the current results
          setResults((results) => [...results, res.data]);
        })
        .catch((e) => {
          console.log(e);
          // Tell the user that there were no results
          alert(`Unable to find ${val}`);
        });
    } else {
      alert("Maximum Limit Reached. Generate New PDF Or Remove Some Labels.");
    }
    setSearchValue("");
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSubmit = () => {
    if (results.length < 140) {
      // Search Tubbees-Products database for the search value (Needs to be either the barcode or name exactly)
      axios
        .get(
          `https://nitropos-backend.nitrosolutions.co.uk/labelgen/findone/${searchValue}`
        )
        .then((res) => {
          // Add the result to the current results
          setResults((results) => [...results, res.data]);
          pageNumber();
          handleFound()
          //console.log(res.data.Barcode)
        })
        .catch((e) => {
          console.log(e);
          // Tell the user that there were no results
          handleWarning()
          setErrorMessage(true)
          setTimeout(() => {
            setErrorMessage(false)
          },2000)

          // alert(`Unable to find ${searchValue}`);
        });
    } else {
      alert("Maximum Limit Reached. Generate New PDF Or Remove Some Labels.");
    }
    setSearchValue("");
  };

  const handleKeyDown = (e) => {
    // If the key pressed is enter
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  const handleClick = (item) => {
    // Copy the current value of the state to make changes to it
    let stateCopy = Object.assign([], results);
    // Find the position of the current label in the array
    let position = stateCopy.indexOf(item);
    // Remove that label
    stateCopy.splice(position, 1);
    // Set the state to the changed version
    setResults(stateCopy);
  };

  const printHandler = () => {
    var element = document.getElementById("capture");
    // Had to adjust the margin here otherwise it would have an unexplained margin
    var opt = {
      html2canvas: { scale: 2 },
      margin: [1.4, 0.4, 1.7, 0.7], //10.5, 1, 26.5, 0 //Top, Left, Bottom, Right
      jsPDF: {unit:'cm', format: 'a4', orientation: 'portrait'},
      pagebreak: { mode:'legacy'}
    };
    
    html2pdf(element, opt).then(() => {
      var barcodes = [];
      for (let i = 0; i < results.length; i++) {
        barcodes.push(results[i].Barcode);
      }
      const data = { barcodes: barcodes, date: new Date() };
      axios
        .post(
          `https://nitropos-backend.nitrosolutions.co.uk/labels/${site}/${brand}/generate`,
          data
        )
        .then((res) => {
          const url2 = new URL(window.location.href);
          url2.searchParams.delete("barcodes", res.data.barcodes);
          window.history.replaceState(null, null, url2);
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const saveProgress = () => {
    var barcodes = [];
    for (let i = 0; i < results.length; i++) {
      barcodes.push(results[i].Barcode);
    }
    const data = { barcodes: barcodes, date: new Date() };
    axios
      .post(
        `https://nitropos-backend.nitrosolutions.co.uk/labels/${site}/${brand}/edit`,
        data
      )
      .then((res) => {
        alert("Progress Saved!");
        axios
          .get(
            `https://nitropos-backend.nitrosolutions.co.uk/labels/${site}/${brand}`
          )
          .then((res) => {
            if (res.data.barcodes.length > 0) {
              const url2 = new URL(window.location.href);
              url2.searchParams.set("barcodes", res.data.barcodes);
              window.history.replaceState(null, null, url2);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const clearSheet = () => {
    axios
      .post(
        `https://nitropos-backend.nitrosolutions.co.uk/labels/${site}/${brand}/clear`
      )
      .then((res) => {
        alert("Sheet Cleared!");
        const url2 = new URL(window.location.href);
        url2.searchParams.delete("barcodes", res.data.barcodes);
        window.history.replaceState(null, null, url2);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const lastSheet = () => {
    axios
      .get(
        `https://nitropos-backend.nitrosolutions.co.uk/labels/${site}/${brand}/history`
      )
      .then((res) => {
        if (res.data.barcodes.length > 0) {
          setMessage("Fetching Last Sheet...");
          const url2 = new URL(window.location.href);
          url2.searchParams.set("barcodes", res.data.barcodes);
          window.history.replaceState(null, null, url2);
          // for (let i = 0; i < myArray.length; i++) {
          //   preFilled(myArray[i])
          //   pageNumber()
          // }
          window.location.reload();
        } else {
          alert("No Sheets In History!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={`${styles.search}`}>
          {errorMessage ? (
        <Alert variant="filled" severity="error" sx={{width: "42vw",zIndex: "100", position: "absolute", top: "20vw"}}>
        Unable to find {searchValue}
      </Alert>
      ):(
        <></>
      )}
      <button
        id="warningBtn"
        onClick={() => playWarningAlert()}
        style={{ display: "none" }}
      >
        Play
      </button>
      <button
        id="foundBtn"
        onClick={() => playFoundAlert()}
        style={{ display: "none" }}
      >
        Play 2
      </button>

      <div className={`${styles.searchBar}`} style={{ marginTop: "-5%" }}>
        <Info />
        <hr />
        <div
          style={{
            margin: "auto 0",
            width: "100%",
            display: "inline-block",
            textAlign: "center",
          }}
        >
          <span
            style={{ marginLeft: "1%", marginRight: "1%" }}
            className="ms-2 me-2"
          >
            Search Barcode/Name &amp; Press Enter
          </span>
          <input
            placeholder="SWEDISH FISH ASSORTED 99G"
            type="text"
            value={searchValue}
            onChange={handleSearchChange}
            onKeyDown={handleKeyDown}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <button
            style={{
              display: "inline-block",
              margin: "2%",
              width: "14%",
              backgroundColor: "red",
              color: "white",
              borderRadius: "10px",
              padding: "10px",
            }}
            onClick={clearSheet}
          >
            Clear
          </button>
          <button
            style={{
              display: "inline-block",
              margin: "2%",
              width: "14%",
              backgroundColor: "orange",
              color: "white",
              borderRadius: "10px",
              padding: "10px",
            }}
            onClick={saveProgress}
          >
            Save
          </button>
          <button
            style={{
              display: "inline-block",
              margin: "2%",
              width: "14%",
              backgroundColor: "dodgerblue",
              color: "white",
              borderRadius: "10px",
              padding: "10px",
            }}
            onClick={lastSheet}
          >
            Last PDF
          </button>
          <button
            style={{
              display: "inline-block",
              margin: "2%",
              width: "14%",
              backgroundColor: "green",
              color: "white",
              borderRadius: "10px",
              padding: "10px",
            }}
            onClick={printHandler}
          >
            Generate PDF
          </button>
        </div>
      </div>
      <div className={`row ${styles.labelCount}`} style={{display:"flex",justifyContent:"space-around"}}>
        <span className="col">Current Page - {page}/5</span>
        <span className="col">Labels Added - {results.length}/140</span>
      </div>
      <p
        style={{
          backgroundColor: "transparent",
          border: "none",
          color: "black",
        }}
      >
        {message}
      </p>

      <Labels results={results} clicked={handleClick} />
    </div>
  );
}
