import "./dash.scss";
import { useState, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import NoFoodIcon from "@mui/icons-material/NoFood";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import Wastage from "./tabs/Wastage";
import DriveOff from "./tabs/DriveOff";
import ErrorBoundary from "../error/ErrorBoundary";
import NavBar from "../NavBar";
import QrCodeIcon from "@mui/icons-material/QrCode";
import ProductReport from "./tabs/ProductReport";
import SmokingRoomsIcon from "@mui/icons-material/SmokingRooms";
import CigsStock from "./tabs/CigsStock";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import HrDash from "../HR/dash/HrDash";
import Overview from "./Overview.tsx";
import DashboardIcon from "@mui/icons-material/Dashboard";

const drawerWidth = "12vw";

export default function MainDashHome() {
    const [value, setValue] = useState(0);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (window.innerWidth < 690) {
            setIsMobile(true);
        }
    }, [window.innerWidth]);

    const handleChange = (index) => {
        setValue(index);
    };

    return (
        <main className="business_dash">
            <Box sx={{ display: "flex" }}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    sx={{
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                        padding: "0",
                    }}
                >
                    <Toolbar
                        sx={{ width: "100vw", backgroundColor: "#2c53a0" }}
                    >
                        <NavBar />
                    </Toolbar>
                </AppBar>

                <Drawer
                    variant="permanent"
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: {
                            width: drawerWidth,
                            boxSizing: "border-box",
                        },
                        ["@media(max-width: 600px)"]: {
                            width: "10vw",
                        },
                    }}
                >
                    <Toolbar />
                    <Box sx={{ overflow: "hidden", marginTop: "12vh" }}>
                        <List>
                            {/* add tab names here */}
                            {[
                                "Dashboard",
                                "Wastage",
                                "EOD Dash",
                                "Product report",
                                "Cigarettes report",
                            ].map((text, index) => (
                                <ListItem
                                    key={text}
                                    disablePadding
                                    onClick={() => handleChange(index)}
                                >
                                    <ListItemButton>
                                        <ListItemIcon>
                                            {index === 0 ? (
                                                <DashboardIcon />
                                            ) : index === 1 ? (
                                                <NoFoodIcon />
                                            ) : index === 2 ? (
                                                <PointOfSaleIcon />
                                            ) : index === 3 ? (
                                                <QrCodeIcon />
                                            ) : index === 4 ? (
                                                <SmokingRoomsIcon />
                                            ) : null}
                                        </ListItemIcon>
                                        {!isMobile ? (
                                            <ListItemText primary={text} />
                                        ) : (
                                            <></>
                                        )}
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </Drawer>
                <Box
                    component="main"
                    sx={{ flexGrow: 1, p: 3, marginTop: "10vh" }}
                >
                    <Toolbar />
                    {value === 0 ? (
                        <ErrorBoundary>
                            <Overview />
                        </ErrorBoundary>
                    ) : value === 1 ? (
                        <ErrorBoundary>
                            <Wastage />
                        </ErrorBoundary>
                    ) : value === 2 ? (
                        <ErrorBoundary>
                            <HrDash />
                        </ErrorBoundary>
                    ) : value === 3 ? (
                        <ErrorBoundary>
                            <ProductReport />
                        </ErrorBoundary>
                    ) : value === 4 ? (
                        <ErrorBoundary>
                            <CigsStock />
                        </ErrorBoundary>
                    ) : null}
                </Box>
            </Box>
        </main>
    );
}
