import axios from "axios";

const url = "https://nitropos-backend.nitrosolutions.co.uk";

const getIngredients = () => {
  return axios
    .get(`${url}/hoagies/ingredients/all`)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return [];
    });
};

const getUnitTypes = () => {
  return axios
    .get(`${url}/hoagies/products/unitTypes`)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return [];
    });
};

const getCategories = () => {
  return axios
    .get(`${url}/hoagies/products/categories`)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return [];
    });
};

const getAllHoagiesProducts = () => {
  return axios
    .get(`${url}/hoagies/products/all`)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return [];
    });
};

const getAllAllergens = () => {
  return axios
    .get(`${url}/hoagies/allergens`)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return [];
    });
};

const getAllEposProducts = () => {
  return axios
    .get(`${url}/claims/products/search`)
    .then((res) => res.data)
    .catch((err) => {
      console.log(`could not get all products ${err}`);
      return [];
    });
};

const getAllTubbeesProducts = () => {
  return axios
    .get(`${url}/claims/products/tubbees`)
    .then((res) => res.data)
    .catch((err) => {
      console.log(`Could not get Tubbees products: ${err}`);
      return [];
    });
};

const getTopWasted = () => {
  return axios
    .get(`${url}/claims/products/top`)
    .then((res) => res.data)
    .catch((err) => {
      console.log(`Could not get the top wasted products: ${err}`);
      return [];
    });
};

export {
  getIngredients,
  getUnitTypes,
  getCategories,
  getAllHoagiesProducts,
  getAllAllergens,
  getAllEposProducts,
  getAllTubbeesProducts,
  getTopWasted,
};
