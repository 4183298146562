import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";
import "./modal.scss";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  bgcolor: "background.paper",
  borderRadius: "1rem",
  backgroundColor: "#fff",
  boxShadow: 24,
  p: 4,
  color: "#000",
  minHeight: "25vh",
};

export default function IndvNotes(props) {
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    setNotes(props.notes);
  }, [props]);

  return (
    <Box sx={style}>
      <Typography id="inv__notes__modal" variant="h6" component="h2">
        <h2 style={{ textAlign: "center" }}>Investigation Notes</h2>
      </Typography>
      <Typography id="inv__notes__modal" sx={{ mt: 2 }}>
        <table>
          <thead>
            <tr>
              <th>Added on</th>
              <th>Added By</th>
              <th>Note</th>
            </tr>
          </thead>
          <tbody>
            {notes.map(function (d, i) {
              return (
                <tr>
                  <th>{new Date(d.dateTimeAdded).toLocaleDateString()}</th>
                  <th>{d.userId}</th>
                  <th>{d.note}</th>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Typography>
    </Box>
  );
}
