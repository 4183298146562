import { useState, useRef } from "react";
import { dataUriToBuffer } from "data-uri-to-buffer";
import Button from "@mui/material/Button";
import axios from "axios";
import Alert from "@mui/material/Alert";
import Modal from "@mui/material/Modal";
import WarningIcon from "@mui/icons-material/Warning";
import SignatureCanvas from "react-signature-canvas";
import { phone } from "phone";
import moment from "moment";
import DVLAMatch from "../modals/DVLAMatch";
import RedAlert from "../modals/RedAlert";
import EmailCheck from "../modals/EmailCheck";
import AdminCode from "../modals/AdminCode";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";

export default function NMOPForm({ site, siteName, rel }) {
    const url = process.env.REACT_APP_ANPR_URL;
    const date = new Date();
    const signRef = useRef(null);
    const [reg, setReg] = useState("");
    const [carDetails, setCarDetails] = useState([]);
    const [form, setForm] = useState(false);
    const [name, setName] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [firstAddress, setFirstAddress] = useState("");
    const [secondAddress, setSecondAddress] = useState("");
    const [postcode, setPostcode] = useState("");
    const [email, setEmail] = useState("");
    const [fuelType, setFuelType] = useState("");
    const [litres, setLitres] = useState("");
    const [pounds, setPounds] = useState("");
    const [emailCheck, setEmailCheck] = useState("");
    const [pump, setPump] = useState("");
    const [identification, setIdentification] = useState(false);
    const [driver, setDriver] = useState("");
    const [item, setItem] = useState("");
    const [documents, setDocuments] = useState(false);
    const [signatureCustomer, setSignatureCustomer] = useState("");
    const [staffName, setStaffName] = useState("");
    const [emailVerified, setEmailVerified] = useState(false);
    const [driverPhoto, setDriverPhoto] = useState("");
    const [phonePhoto, setPhonePhoto] = useState("");
    const [driverPhotoSrc, setDriverPhotoSrc] = useState("");
    const [itemPhotoSrc, setItemPhotoSrc] = useState("");
    const [verCode, setVerCode] = useState("");
    const [adminVerCode, setAdminVerCode] = useState("");
    const [adminAlert, setAdminAlert] = useState(false);
    const [open, setOpen] = useState(false);
    const [alertText, setAlertText] = useState("");
    const [message, setMessage] = useState("");
    const [errorAlert, setErrorAlert] = useState(false);
    const [dvlaAlert, setDvlaAlert] = useState(false);
    const [dvlaMessage, setDvlaMessage] = useState("");
    const [dvlaSeverity, setDvlaSeverity] = useState("");
    const [openRedAlert, setOpenRedAlert] = useState(false);
    const [phoneAlert, setPhoneAlert] = useState(false);
    const [phoneMessage, setPhoneMessage] = useState("");
    const [phoneSeverity, setPhoneSeverity] = useState("");
    const [emailAlert, setEmailAlert] = useState(false);
    const [emailMessage, setEmailMessage] = useState("");
    const [emailSeverity, setEmailSeverity] = useState("");
    const [openEmailCheck, setOpenEmailCheck] = useState(false);
    const [openAdminCode, setOpenAdminCode] = useState(false);
    const [identificationAlert, setIdentificationAlert] = useState(false);
    const [identificationMessage, setIdentificationMessage] = useState("");
    const [signAlert, setSignAlert] = useState(false);
    const [signMessage, setSignMessage] = useState("");
    const [signSeverity, setSignSeverity] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);

    //regex
    //const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const postcodeRegex =
        /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/;

    const handleDriverPhoto = (inFile) => {
        setDriver(inFile);
        var fr = new FileReader();
        fr.onload = function () {
            setDriverPhotoSrc(fr.result);
        };

        fr.readAsDataURL(inFile);
    };

    const handleItemPhoto = (inFile) => {
        setItem(inFile);
        var fr = new FileReader();
        fr.onload = function () {
            setItemPhotoSrc(fr.result);
        };
        fr.readAsDataURL(inFile);
    };

    const matchedDetails = () => {
        setOpen(false);
        setForm(true);
    };
    const noDetailsMatched = () => {
        setOpen(false);
        setOpenRedAlert(true);
    };

    function handleDVLA() {
        var data = {
            plate: reg,
        };

        if (reg === "") {
            setDvlaMessage("Please enter a registration number");
            setDvlaSeverity("warning");
            setDvlaAlert(true);
            setTimeout(() => {
                setDvlaAlert(false);
                setDvlaMessage("");
                setDvlaSeverity("");
            }, 3000);
        } else {
            axios
                .post(
                    "https://nitrocam-back.nitrosolutions.co.uk/nmop/dvla",
                    data
                )
                .then((res) => {
                    if (
                        res.data.motStatus === "Not valid" ||
                        res.data.fuelType === "Electricity" ||
                        res.data.taxStatus != "Taxed" ||
                        res.data.length === 0
                    ) {
                        setOpenRedAlert(true);
                    } else {
                        setCarDetails(res.data);
                        setOpen(true);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    function fuelAlert() {
        const x = window.confirm(
            "Please confirm that DVLA Fuel Type and fuel taken does not match"
        );
        if (x === true) {
            setOpenRedAlert(true);
        }
    }

    function handleForm() {
        if (
            name === "" ||
            pump === "" ||
            fuelType === "" ||
            fuelType === "Please select" ||
            pounds === "" ||
            firstAddress === "" ||
            postcode === "" ||
            date === ""
        ) {
            setMessage("Please complete all required fields");
            setErrorAlert(true);
            setTimeout(() => {
                setMessage("");
                setErrorAlert(false);
            }, 3000);
        } else {
            axios
                .get(`https://api.postcodes.io/postcodes/${postcode}`)
                .then((res) => {
                    if (res.status === 200) {
                        if (!emailVerified) {
                            setMessage(
                                "Please make sure the email has been verified!"
                            );
                            setErrorAlert(true);
                            setTimeout(() => {
                                setMessage("");
                                setErrorAlert(false);
                            }, 3000);
                        } else if (emailVerified) {
                            setForm(false);
                            setIdentification(true);
                        }
                    }
                })
                .catch((err) => {
                    setMessage("Please make sure Postcode is valid");
                    setErrorAlert(true);
                    setTimeout(() => {
                        setMessage("");
                        setErrorAlert(false);
                    }, 3000);
                });
        }
    }
    function handleIdentification() {
        if (driver === "" || item === "") {
            setIdentificationMessage(
                "Please provide an image of the driver and item they have left"
            );
            setIdentificationAlert(true);
            setTimeout(() => {
                setIdentificationAlert(false);
                setIdentificationMessage("");
            }, 4000);
        } else {
            const driverB64 = new FileReader();
            driverB64.readAsDataURL(driver);
            driverB64.onload = () => setDriverPhoto(driverB64.result);

            const phoneB64 = new FileReader();
            phoneB64.readAsDataURL(item);
            phoneB64.onload = () => setPhonePhoto(phoneB64.result);

            setIdentification(false);
            setDocuments(true);
        }
    }

    const formatCheck = (check) => {
        let fileData = check.split(",");
        let type = fileData[0].replace("data:", "");
        type = type.replace(";base64", "");
        let imgFormat = type.replace("image/", "");

        return imgFormat;
    };

    const sign = () => {
        const signature = signatureCustomer
            .getTrimmedCanvas()
            .toDataURL("image/png");

        let newDate = moment(date).format("DD-MM-YY");
        let time = moment(date).format("HH:mm");

        const driverPhotoFormat = formatCheck(driverPhoto);
        const itemPhoneFormat = formatCheck(phonePhoto);
        const signatureFormat = formatCheck(signature);

        let data = {
            staffName: staffName,
            siteId: site,
            site: siteName,
            date: newDate,
            time: time,
            requestType: 3,
            reason: "",
            crimeReference: "",
            items: "",
            description: "",
            person: "",
            note: "",
            images: "",
            plate: reg,
            pumpType: fuelType,
            pump: pump,
            amount: pounds,
            litres: litres,
            personalDetails: {
                name: name,
                email: email,
                phone: phoneNo,
                addressLine1: firstAddress,
                addressLine2: secondAddress,
                postcode: postcode,
                driver: driverPhotoFormat,
                itemLeft: itemPhoneFormat,
                signature: signatureFormat,
            },
        };

        let base64Array = {
            driver: driverPhoto,
            itemLeft: phonePhoto,
            signature: signature,
        };

        if (!signatureCustomer.isEmpty() === false) {
            setSignMessage("Please provide a customer signature");
            setSignSeverity("warning");
            setSignAlert(true);
            setTimeout(() => {
                setSignAlert(false);
                setSignMessage("");
                setSignSeverity("");
            }, 4000);
        } else if (staffName === "") {
            setSignMessage("Please provide a staff name");
            setSignSeverity("warning");
            setSignAlert(true);
            setTimeout(() => {
                setSignAlert(false);
                setSignMessage("");
                setSignSeverity("");
            }, 4000);
        } else {
            setDisableSubmit(true);
            axios
                .post(`${url}/cctv-system/submit`, data)
                .then((res) => {
                    setSignMessage("NMOP successfully submitted ✔");
                    setSignSeverity("success");
                    setSignAlert(true);
                    setDisableSubmit(true);
                    setTimeout(() => {
                        setSubmitted(true);
                        setDocuments(false);
                        let s3Array = res.data.s3Links;
                        for (let i = 0; i < s3Array.length; i++) {
                            let buffer = dataUriToBuffer(
                                base64Array[s3Array[i].variable]
                            );
                            axios
                                .put(s3Array[i].url, buffer.buffer)
                                .then((res) => {})
                                .catch((err) => {
                                    console.log(err);
                                });
                        }
                        setDisableSubmit(false);
                        rel();
                    }, 4000);
                })
                .catch((err) => {
                    console.log(err);
                    setSignMessage("Issue uploading NMOP, please try again");
                    setSignSeverity("error");
                    setSignAlert(true);
                    setTimeout(() => {
                        setSignAlert(false);
                        setSignMessage("");
                        setSignSeverity("");
                        setDisableSubmit(false);
                        rel();
                    }, 4000);
                });
        }
    };

    const verifyEmail = async () => {
        if (!postcode.match(postcodeRegex)) {
            setEmailMessage("Please enter a valid Postcode");
            setEmailSeverity("warning");
            setEmailAlert(true);
            setTimeout(() => {
                setEmailAlert(false);
                setEmailSeverity("");
                setEmailMessage("");
            }, 3000);
        } else if (!email.match(emailRegex)) {
            setEmailMessage("Please enter a valid Email");
            setEmailSeverity("warning");
            setEmailAlert(true);
            setTimeout(() => {
                setEmailAlert(false);
                setEmailSeverity("");
                setEmailMessage("");
            }, 3000);
        } else if (email != emailCheck) {
            setEmailMessage("Please ensure emails match");
            setEmailSeverity("warning");
            setEmailAlert(true);
            setTimeout(() => {
                setEmailAlert(false);
                setEmailSeverity("");
                setEmailMessage("");
            }, 3000);
        } else {
            await axios
                .post(`${url}/cctv-system/verify`, {
                    email: email,
                })
                .then(async (res) => {
                    setOpenEmailCheck(true);
                })
                .catch((err) => {
                    setEmailMessage("Something went wrong, please try again");
                    setEmailSeverity("error");
                    setEmailAlert(true);
                    setTimeout(() => {
                        setEmailAlert(false);
                        setEmailSeverity("");
                        setEmailMessage("");
                    }, 3000);
                });
        }
    };

    const adminPasscode = async () => {
        var data = {
            user: staffName,
            code: adminVerCode,
        };

        await axios.post(`${url}/nmop/admincode`, data).then(async (res) => {
            if (res.data.accepted === false) {
                if (res.data.reason === "Used") {
                    setAdminAlert(true);
                    setAlertText(
                        "Admin Code has been used, contact Nitro Solutions on 0141 530 2480"
                    );
                }

                if (res.data.reason === "Wrong") {
                    setAdminAlert(true);
                    setAlertText(
                        "Incorrect Admin Code has been used, contact Nitro Solutions on 0141 530 2480"
                    );
                }
            } else {
                setEmailVerified(true);
                setOpenAdminCode(false);
                setAdminAlert(false);
            }
        });
    };

    const checkVerEmail = async () => {
        if (verCode !== "") {
            await axios
                .post(`${url}/cctv-system/verify/code`, {
                    email: email,
                    code: parseInt(verCode),
                })
                .then((res) => {
                    setEmailVerified(true);
                    setOpenEmailCheck(false);
                    setEmailMessage("Email Verification Complete ✔");
                    setEmailSeverity("success");
                    setEmailAlert(true);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const verifyPhone = () => {
        const resp = phone(phoneNo, {
            country: "GB",
            strictDetection: true,
            validateMobilePrefix: false,
        });

        if (phoneNo === "" || resp.isValid === false) {
            setPhoneMessage("Please enter a valid phone number");
            setPhoneSeverity("warning");
            setPhoneAlert(true);
            setTimeout(() => {
                setPhoneAlert(false);
                setPhoneSeverity("");
                setPhoneMessage("");
            }, 3000);
        } else {
            setPhoneMessage("Phone Number Verification Complete ✔");
            setPhoneSeverity("success");
            setPhoneAlert(true);
        }
    };

    const handlePump = (e) => {
        if (parseFloat(e) < 1 || parseFloat(e) > 8) {
            document.getElementById("pump_alert_nmop").style.display =
                "inline-block";
            document.getElementById("pump_nmop").style.display = "none";
            setTimeout(() => {
                document.getElementById("pump_alert_nmop").style.display =
                    "none";
                document.getElementById("pump_nmop").style.display =
                    "inline-block";
                setPump("");
            }, 2500);
        } else {
            setPump(e);
        }
    };

    const handleFields = (e, alert, label, setter) => {
        if (parseFloat(e) < 0) {
            document.getElementById(alert).style.display = "inline-block";
            document.getElementById(label).style.display = "none";
            setTimeout(() => {
                document.getElementById(alert).style.display = "none";
                document.getElementById(label).style.display = "inline-block";
                setter("");
            }, 2500);
        } else {
            setter(e);
        }
    };

    return (
        <main className="driveoff_form">
            {!identification && !documents ? (
                <>
                    <h4>Please enter the car registration number:</h4>
                    <input
                        type="text"
                        onChange={(e) => setReg(e.target.value)}
                        className="margin"
                    />

                    {!dvlaAlert ? (
                        <Button
                            variant="contained"
                            onClick={() => handleDVLA()}
                            endIcon={<DirectionsCarIcon />}
                            sx={{ margin: "15px 0" }}
                        >
                            Check with DVLA
                        </Button>
                    ) : (
                        <Alert severity={dvlaSeverity}>{dvlaMessage}</Alert>
                    )}
                </>
            ) : null}

            {form ? (
                <>
                    <label>Make:</label>
                    <input type="text" value={carDetails.make} required />
                    <label>Colour:</label>
                    <input type="text" value={carDetails.colour} required />

                    <label id="pump_nmop">Pump</label>
                    <label id="pump_alert_nmop">Please enter between 1-8</label>
                    <input
                        type="number"
                        pattern="[0-9]*"
                        onChange={(e) => handlePump(e.target.value)}
                        value={pump}
                    />
                    <label>Select the fuel type.</label>
                    <select onChange={(e) => setFuelType(e.target.value)}>
                        <option>Please select</option>
                        <option>Diesel</option>
                        <option>Unleaded</option>
                    </select>
                    <label>Full Name:</label>
                    <input
                        type="text"
                        onChange={(e) => setName(e.target.value)}
                        required
                        maxlength={50}
                    />
                    <label>Phone Number:</label>
                    <input
                        type="tel"
                        onChange={(e) => setPhoneNo(e.target.value)}
                        required
                        minLength={11}
                        maxLength={13}
                    />
                    <br />
                    {phoneAlert ? (
                        <Alert severity={phoneSeverity}>{phoneMessage}</Alert>
                    ) : (
                        <Button
                            onClick={() => {
                                verifyPhone();
                            }}
                            color="warning"
                            variant="contained"
                        >
                            Verify Phone Number
                        </Button>
                    )}
                    <br />
                    <label>First Line of Address:</label>
                    <input
                        type="text"
                        onChange={(e) => setFirstAddress(e.target.value)}
                        required
                        maxLength={60}
                    />
                    <label>Second Line of Address:</label>
                    <input
                        type="text"
                        onChange={(e) => setSecondAddress(e.target.value)}
                        required
                        maxLength={60}
                    />
                    <label>Postcode:</label>
                    <input
                        type="text"
                        onChange={(e) => setPostcode(e.target.value)}
                        required
                    />
                    <label>E-Mail:</label>
                    <input
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <label>Confirm E-Mail:</label>
                    <input
                        type="email"
                        onChange={(e) => setEmailCheck(e.target.value)}
                        required
                    />
                    <br />
                    {emailAlert ? (
                        <Alert severity={emailSeverity}>{emailMessage}</Alert>
                    ) : (
                        <div className="verifyButtons">
                            <Button
                                onClick={() => {
                                    verifyEmail();
                                }}
                                color="warning"
                                variant="contained"
                            >
                                Verify Email
                            </Button>
                        </div>
                    )}
                    <br />
                    <Button
                        variant="contained"
                        color="warning"
                        startIcon={<WarningIcon />}
                        onClick={fuelAlert}
                        sx={{ marginBottom: "15px" }}
                    >
                        Fuel used not {carDetails.fuelType}?
                    </Button>

                    <label id="litres_nmop">Litres of fuel:</label>
                    <label id="litres_alert_nmop">
                        Please enter a postive amount
                    </label>
                    <input
                        type="number"
                        onChange={(e) =>
                            handleFields(
                                e.target.value,
                                "litres_alert_nmop",
                                "litres_nmop",
                                setLitres
                            )
                        }
                        className="number"
                        required
                        value={litres}
                    />

                    <label id="pounds_nmop">Cost of fuel (£):</label>
                    <label id="pounds_alert_nmop">
                        Please enter a postive amount
                    </label>
                    <input
                        type="number"
                        onChange={(e) =>
                            handleFields(
                                e.target.value,
                                "pounds_alert_nmop",
                                "pounds_nmop",
                                setPounds
                            )
                        }
                        className="number"
                        required
                        value={pounds}
                    />

                    {errorAlert ? (
                        <Alert severity="warning">{message}</Alert>
                    ) : (
                        <Button
                            variant="contained"
                            color="success"
                            onClick={() => handleForm()}
                        >
                            Continue
                        </Button>
                    )}
                </>
            ) : null}
            {identification ? (
                <section>
                    <h4>
                        In order to process this <b>No Means Of Payment</b> we
                        will now obtain an image for identification purposes.
                    </h4>
                    <h5>Please take an image of the driver of the vehicle:</h5>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                            setDriver(e.target.files[0]);
                            handleDriverPhoto(e.target.files[0]);
                        }}
                        capture
                    />
                    {driverPhotoSrc !== "" ? (
                        <img
                            src={driverPhotoSrc}
                            alt="image of driver"
                            width="200"
                        />
                    ) : null}

                    <h5>Please take a photo of the customers phone</h5>

                    <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                            setItem(e.target.files[0]);
                            handleItemPhoto(e.target.files[0]);
                        }}
                        capture
                    />
                    {itemPhotoSrc !== "" ? (
                        <img
                            src={itemPhotoSrc}
                            alt="image of item"
                            width="200"
                        />
                    ) : null}

                    {!identificationAlert ? (
                        <Button
                            variant="contained"
                            color="success"
                            sx={{
                                margin: "2% auto",
                                display: "flex",
                            }}
                            onClick={() => handleIdentification()}
                        >
                            Continue
                        </Button>
                    ) : (
                        <Alert severity="warning">
                            {identificationMessage}
                        </Alert>
                    )}
                </section>
            ) : (
                <></>
            )}
            {documents ? (
                <section className="document">
                    <div className="agreement">
                        <h2 style={{ color: "#FF0000" }}>
                            No Means Of Payment Agreement
                        </h2>
                        <p>
                            I <b>{name}</b> have to exit the premises at {site}{" "}
                            without paying for fuel to the sum of £{pounds}{" "}
                            which is {litres}litres of {carDetails.fuelType}, on
                            pump number: {pump} on{" "}
                            {new Date().toLocaleDateString()} as I have no means
                            of payment.
                            <br />
                            <br />I <b>{name}</b> agree to pay the balance in
                            within 72 hours (3 days) from the moment of
                            signature, and I am aware that if I do not adhere to
                            this agreement, the matter will be passed to the
                            police and reported as a theft.
                            <br />
                            <br /> If I have left and item to counter act the
                            balance remainining until I return to pay for the
                            goods, I agree that RaceTrack Pitstop take no
                            responsibility for the loss or damage to my item.
                            <br />
                            <br />I am aware that any of my personal information
                            provided will be passed to the police in case
                            payment is not made in 72 hours. I agree to
                            RaceTrack Pitstop storing and sharing my personal
                            data with the Police and for <b>GDPR</b> purposes I
                            consent to this.
                        </p>
                        <h4>Signature: </h4>
                        <div className="signaturePad" ref={signRef}>
                            <SignatureCanvas
                                penColor="black"
                                canvasProps={{
                                    className: "sigCanvas",
                                    width: 300,
                                    height: 80,
                                }}
                                ref={(ref) => {
                                    setSignatureCustomer(ref);
                                }}
                            />
                        </div>
                        <Button
                            onClick={() => signatureCustomer.clear()}
                            color="error"
                        >
                            clear signature
                        </Button>
                        <h5>Date: {new Date().toLocaleDateString()}</h5>
                        <label>Printed Name:</label>
                        <input type="text" value={name} />
                        <br />
                        <label>Staff name:</label>
                        <input
                            type="text"
                            onChange={(e) => setStaffName(e.target.value)}
                        />
                        {!signAlert ? (
                            <Button
                                variant="contained"
                                color="success"
                                sx={{
                                    margin: "2% auto",
                                    display: "flex",
                                    width: "50%",
                                }}
                                onClick={sign}
                                disabled={disableSubmit}
                            >
                                Sign
                            </Button>
                        ) : (
                            <Alert severity={signSeverity}>{signMessage}</Alert>
                        )}
                        {}
                    </div>
                </section>
            ) : (
                <></>
            )}
            {submitted ? (
                <Alert severity="success">NMOP Successfully Submitted ✔</Alert>
            ) : null}
            <Modal
                open={open}
                aria-labelledby="DVLA Match modal"
                aria-describedby="DVLA Match modal"
            >
                <DVLAMatch
                    carDetails={carDetails}
                    matchedDetails={matchedDetails}
                    noDetailsMatched={noDetailsMatched}
                />
            </Modal>
            <Modal
                open={openRedAlert}
                aria-labelledby="Red Alert modal"
                aria-describedby="Red Alert modal"
            >
                <RedAlert handleClose={setOpenRedAlert} />
            </Modal>
            <Modal
                open={openEmailCheck}
                aria-labelledby="Email Check modal"
                aria-describedby="Email Check modal"
            >
                <EmailCheck
                    handleAdminDialogOpen={setOpenAdminCode}
                    email={email}
                    checkVerEmail={checkVerEmail}
                    setVerCode={setVerCode}
                    handleDialogClose={setOpenEmailCheck}
                />
            </Modal>
            <Modal
                open={openAdminCode}
                aria-labelledby="Admin Code modal"
                aria-describedby="Admin Code modal"
            >
                <AdminCode
                    handleAdminDialogClose={setOpenAdminCode}
                    adminAlert={adminAlert}
                    alertText={alertText}
                    setAdminAlert={setAdminAlert}
                    setStaffName={setStaffName}
                    setAdminVerCode={setAdminVerCode}
                    adminPasscode={adminPasscode}
                />
            </Modal>
        </main>
    );
}
