import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

//STYLING
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40vw",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
    maxHeight: "70vh",
    overflowY: "scroll",
    "@media(max-width: 690px)": {
        width: "90vw",
        maxHeight: "90vh",
        p: 2,
    },
};

export default function EmailCheck({
    handleDialogClose,
    checkVerEmail,
    email,
    handleAdminDialogOpen,
    setVerCode,
}) {
    return (
        <Box sx={style} className="all_modal">
            <Typography id="modal-modal-title" variant="h4" component="h2">
                Email Confirmation
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <h3> Please Enter the 4 Digit Code sent to - {email}</h3>
                <input
                    type="number"
                    onChange={(e) => setVerCode(e.target.value)}
                    minLength={4}
                    maxLength={4}
                    placeholder="4 Digit Code Here"
                />

                <HelpOutlineIcon
                    onClick={() => {
                        handleAdminDialogOpen(true);
                        handleDialogClose(false);
                    }}
                    className="Help"
                >
                    Email Not Available
                </HelpOutlineIcon>
                <section
                    style={{ display: "flex", justifyContent: "flex-end" }}
                >
                    <Button
                        onClick={() => handleDialogClose(false)}
                        color="error"
                    >
                        Cancel
                    </Button>
                    <Button onClick={checkVerEmail}>Accept</Button>
                </section>
            </Typography>
        </Box>
    );
}
