import { useState, useEffect } from "react";
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import Modal from '@mui/material/Modal';
import IndNotes from "./IndNotes";

export default function ITdetails(props) {
  const [tickets, setTickets] = useState([]);
  const [notes, setNotes] = useState([])
  const [open, setOpen] = useState(false);
  const handleOpen = (notes) => {
    setNotes(notes)
    setOpen(true)};
  const handleClose = () => setOpen(false);

  useEffect(() => {
    setTickets(props.tickets);
  }, [props]);

  return (
    <main className="it__cards">
      <h3>Open tickets for {props.site}</h3>
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Issue</th>
            <th>Status</th>
            <th>Notes</th>
          </tr>
        </thead>
        <tbody>
            {tickets.length > 0 ? (
                tickets.map(function (d,i){
                    return(
                        <tr>
                            <th>{new Date(d.date).toLocaleDateString()}</th>
                            <th>{d.issue}</th>
                            <th>{d.status}</th>
                            {d.notes.length > 0 ? (
                                <SpeakerNotesIcon onClick={() => handleOpen(d.notes)}/>
                            ):(
                                <SpeakerNotesOffIcon />
                            )}
                        </tr>
                    )
                })
            ):(
                <tr>
                    <th colSpan={4}>No Open Tickets</th>
                </tr>
            )}
        </tbody>
      </table>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <IndNotes notes={notes} />
      </Modal>
    </main>
  );
}
