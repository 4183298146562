//React
import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
//MUI
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";

//Styling
import "./shiftModal.scss";
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: "8px",
    p: 4,
    height: "80%",
    overflowY: "auto",
};

export default function Attendance({
    closeModal,
    shiftId,
    user,
    date,
    reload,
    handleClose,
    confirmShift,
    shiftData
}) {
    const config = {
        headers: {
            "Content-Type": "application/json",
            "x-auth-token": localStorage.getItem("auth-token"),
        },
    };

    //URLs
    // const url = "http://10.0.0.34:4030";s
    const url = process.env.REACT_APP_API_URL;
    //Form
    const [attendanceType, setAttendanceType] = useState(0);
    const [contactMethod, setContactMethod] = useState("");
    const [managerContacted, setManagerContacted] = useState("");
    const [timeContactedManger, setTimeContactedManger] = useState("");
    const [sicknessPattern, setSicknessPattern] = useState(false);
    const [note, setNote] = useState("");
    const [timeContactedEmployee, setTimeContactedEmployee] = useState("");
    const [messageAsWellAsPhone, setMessageAsWellAsPhone] = useState(false);
    const [responseFromContact, setResponseFromContact] = useState(false);

    const [sentHome, setSentHome] = useState("");
    const [sentHomeReason, setSentHomeReason] = useState("")
    const [nightShift, setNightShift] = useState(false)
    const [actualStartTime, setActualStartTime] = useState(shiftData.startTime)
    const [actualEndTime, setActualEndTime] = useState(shiftData.endTime)
    const [breakTime, setBreakTime] = useState(shiftData.breakTime)
    //Day Conditional
    const [shiftInFuture, setShiftInFuture] = useState(false);
    //Alerts
    const [alert, setAlert] = useState(false);
    const [severity, setSeverity] = useState("");
    const [message, setMessage] = useState("");
    const [disable, setDisable] = useState(true);

    useEffect(() => {
        //Determine if shift is in future
        if (moment(date, "YYYY-MM-DD").format() > moment().format()) {
            setShiftInFuture(true);
            setAttendanceType(1);
            setDisable(false);
        }

        if (
            moment(actualStartTime).format("YYYY-MM-DD") !=
            moment(actualEndTime).format("YYYY-MM-DD")
        ) {
            setNightShift(true);
        } else {
            setNightShift(false);
        }
    }, []);

    const handleAlert = (message, severity, duration) => {
        setDisable(true);
        setMessage(message);
        setSeverity(severity);
        setAlert(true);
        setTimeout(() => {
            setAlert(false);
            setMessage("");
            setSeverity("");
            setDisable(false);
        }, duration);
    };

    const handleClearForm = () => {
        setContactMethod("");
        setTimeContactedManger("");
        setManagerContacted("");
        setSicknessPattern(false);
        setNote("");
        setTimeContactedEmployee("");
        setMessageAsWellAsPhone(false);
        setResponseFromContact(false);
    };

    const handleValidation = () => {
        if (
            attendanceType === 1 && sentHome == "No" &&
            (contactMethod === "" ||
                timeContactedManger === "" ||
                managerContacted === "" ||
                note === "")
        ) {
            handleAlert(
                "Please complete all fields including adding a note",
                "warning",
                5000
            );
        } else if (attendanceType === 1 && sentHome == "Yes" && (actualStartTime == "" || actualEndTime == "" || sentHomeReason == "" || note === "")) {
            handleAlert(
                "Please complete all fields including adding a note",
                "warning",
                5000
            );
        } else if (
            attendanceType === 2 &&
            (timeContactedEmployee === "" || note === "")
        ) {
            handleAlert(
                "Please complete all fields including adding a note",
                "warning",
                5000
            );
        } else {
            handleSaveAttendance();
        }
    };

    const handleSaveAttendance = async () => {
        let data = {
            shiftId: shiftId,
            user: user,
            attendanceType: attendanceType, //Sickness or no show
            info: {
                contactMethod: contactMethod, //String
                managerContacted: managerContacted, //String
                timeContactedManger: timeContactedManger, //String
                sicknessPattern: sicknessPattern, //Boolean
                note: note, //string
                timeContactedEmployee: timeContactedEmployee, //String
                messageAsWellAsPhone: messageAsWellAsPhone, //Boolean
                responseFromContact: responseFromContact, //Boolean
                sentHome: sentHome, //String
            },
        };

        if (sentHome == "Yes") {
            data.info.sentHomeReason = sentHomeReason;
            data.info.actualStartTime = actualStartTime;
            data.info.actualEndTime = actualEndTime;
            if (breakTime !== shiftData.breakTime) {
                data.breakTime = breakTime
            }
        }
        await axios
            .post(`${url}/rota/employee/update`, data, config)
            .then(async (res) => {
                handleAlert("Successfully submitted", "success", 3000);
                if (sentHome == "Yes") {
                    await confirmShift()
                }
                reload()
                setTimeout(() => {
                    closeModal(true);
                    handleClose();
                }, 3000);
            })
            .catch((err) => {
                console.log(err);
                handleAlert(
                    "Something has went wrong - Please try again",
                    "error",
                    5000
                );
            });
    };

    const handleStartDateTime = async (time, _endTime) => {
        let nStartTime = ""
        if (nightShift) {
            nStartTime = moment(time).format("YYYY-MM-DD HH:mm");
        } else {
            nStartTime = `${moment(actualStartTime).format("YYYY-MM-DD")} ${time}`;
            console.log(nStartTime)
        }
        if (_endTime !== "") {
            if (moment(nStartTime).isBefore(_endTime)) {
                setActualStartTime(nStartTime);
            } else {
                handleAlert(
                    `The start time must be before ${moment(_endTime).format(
                        "HH:mm"
                    )}`,
                    "error", 5000
                );
            }
        } else {
            setActualStartTime(nStartTime);
        }
    }

    const handleEndDateTime = async (time, _startTime) => {
        let nEndTime = ""
        if (nightShift) {
            nEndTime = moment(time).format("YYYY-MM-DD HH:mm");
        } else {
            nEndTime = `${moment(actualEndTime).format("YYYY-MM-DD")} ${time}`;
        }
        if (_startTime !== "") {
            if (moment(nEndTime).isAfter(_startTime)) {
                setActualEndTime(nEndTime);
            } else {
                handleAlert(
                    `The end time must be after ${moment(
                        _startTime
                    ).format("DD-MM-YYYY HH:mm")}`,
                    "error", 5000
                );
            }
        } else {
            setActualEndTime(nEndTime);
        }
    }

    const handleBreakEdit = async (time) => {
        setBreakTime(time);
    }

    return (
        <Box sx={style} className="attendance_modal">
            <section>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Attendance
                </Typography>
                <section className="attendance_form">
                    {shiftInFuture ? (
                        <h4>Log Sickness Absense</h4>
                    ) : (
                        <select
                            onChange={(e) => {
                                handleClearForm();
                                setAttendanceType(parseInt(e.target.value));
                                if (parseInt(e.target.value) > 0) {
                                    setDisable(false);
                                } else {
                                    setDisable(true);
                                }
                            }}
                        >
                            <option value={0}>
                                Please select an attendance type
                            </option>
                            <option value={1}>Sickness</option>
                            <option value={2}>No Show</option>
                        </select>
                    )}
                    {attendanceType === 1 ? (
                        <section className="sickness_form">
                            <div>
                                <label>
                                    Was the employee sent home sick?
                                </label>
                                <select onChange={(e) => setSentHome(e.target.value)}>
                                    <option value="">--- SELECT ANSWER ---</option>
                                    <option>No</option>
                                    <option>Yes</option>
                                </select>
                                {sentHome == "Yes" ?
                                    <div>
                                        {nightShift ? (
                                            <>
                                                <label>Actual Start :</label>
                                                <input type={"datetime-local"} value={moment.utc(actualStartTime).format("YYYY-MM-DD HH:mm")} onChange={(e) => handleStartDateTime(e.target.value, actualEndTime)} />
                                                <label>Actual End :</label>
                                                <input type={"datetime-local"} value={moment.utc(actualEndTime).format("YYYY-MM-DD HH:mm")} onChange={(e) => handleEndDateTime(e.target.value, actualStartTime)} />
                                            </>
                                        ) : (
                                            <>
                                                <label>Actual Start :</label>
                                                <input type={"time"} value={moment.utc(actualStartTime).format("HH:mm")} onChange={(e) => handleStartDateTime(e.target.value, actualEndTime)} />
                                                <label>Actual End :</label>
                                                <input type={"time"} value={moment.utc(actualEndTime).format("HH:mm")} onChange={(e) => handleEndDateTime(e.target.value, actualStartTime)} />
                                            </>
                                        )}

                                        <div>
                                            <label>Break:</label>
                                            <input
                                                type={"time"}
                                                value={moment
                                                    .utc(breakTime, "HH:mm")
                                                    .format("HH:mm")}
                                                onChange={(e) =>
                                                    handleBreakEdit(e.target.value)
                                                }
                                            />
                                        </div>

                                        <label>Reason for sending home </label>
                                        <input type="text" placeholder="Enter Reason..." value={sentHomeReason} onChange={(e) => setSentHomeReason(e.target.value)} />
                                    </div>
                                    : sentHome == "No" ? <><label>
                                        How did the staff member contact you? e.g.
                                        Phone call, Text Message.
                                    </label>
                                        <input
                                            placeholder="Contact Method"
                                            onChange={(e) =>
                                                setContactMethod(e.target.value)
                                            }
                                        />

                                        <div>
                                            <label>Who did they contact?</label>
                                            <input
                                                onChange={(e) =>
                                                    setManagerContacted(e.target.value)
                                                }
                                                placeholder="Person contacted"
                                            />
                                        </div>
                                        <div>
                                            <label>Date & Time they made contact</label>
                                            <input
                                                type="datetime-local"
                                                onChange={(e) =>
                                                    setTimeContactedManger(e.target.value)
                                                }
                                            />
                                        </div>
                                        <div>
                                            <label>
                                                Is this sickness part of a pattern?
                                            </label>
                                            <select
                                                onChange={(e) =>
                                                    setSicknessPattern(e.target.value)
                                                }
                                            >
                                                <option value={false}>No</option>
                                                <option value={true}>Yes</option>
                                            </select>
                                        </div></>
                                        : null
                                }
                            </div>

                            <div>
                                <label>Any notes?</label>
                                <textarea
                                    placeholder="Please leave any notes you have here"
                                    onChange={(e) => setNote(e.target.value)}
                                />
                            </div>
                        </section>
                    ) : attendanceType === 2 ? (
                        <section className="no_show_form">
                            <div>
                                <label>
                                    Date & Time you attempted contact with
                                    employee
                                </label>
                                <input
                                    type="datetime-local"
                                    onChange={(e) =>
                                        setTimeContactedEmployee(e.target.value)
                                    }
                                />
                            </div>
                            <div>
                                <label>
                                    Did you message employee as well as phone?
                                </label>
                                <select
                                    onChange={(e) =>
                                        setMessageAsWellAsPhone(e.target.value)
                                    }
                                >
                                    <option value={false}>No</option>
                                    <option value={true}>Yes</option>
                                </select>
                            </div>
                            <div>
                                <label>
                                    Did you get a response from employee?
                                </label>
                                <select
                                    onChange={(e) =>
                                        setResponseFromContact(e.target.value)
                                    }
                                >
                                    <option value={false}>No</option>
                                    <option value={true}>Yes</option>
                                </select>
                            </div>
                            <div>
                                <label>Any notes?</label>
                                <textarea
                                    placeholder="Please leave any notes you have here"
                                    onChange={(e) => setNote(e.target.value)}
                                />
                            </div>
                        </section>
                    ) : null}
                </section>
                <section className="alert_section">
                    {alert ? (
                        <Alert severity={severity}>{message}</Alert>
                    ) : null}
                </section>
            </section>
            <section className="btn_section">
                <Button
                    variant="contained"
                    color="error"
                    onClick={() => closeModal(false)}
                >
                    Close
                </Button>
                <Button
                    variant="contained"
                    color="success"
                    onClick={() => handleValidation()}
                    disabled={disable}
                >
                    Save
                </Button>
            </section>
        </Box>
    );
}
