export default function AuditLog({ logs }) {
    return (
        <section className="details" style={{overflowY:"scroll", maxHeight:"50vh"}}>
            <table className="audit_logs_table">
                <thead>
                    <tr>
                        <th>User</th>
                        <th>Change</th>
                        <th>New Value</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {logs.length > 0 ? (
                        logs.map(function (d, i) {
                            let changeReason = d.variable.split(/(?=[A-Z])/);
                            let user = d.user;
                            if (user.includes("@")) {
                                user = user.split("@");

                                user = user[0];
                            }
                            return (
                                <tr key={i}>
                                    <td data-label="User">{user}</td>
                                    <td data-label="Change">
                                        <p
                                            style={{
                                                textTransform: "capitalize",
                                                margin: "0",
                                            }}
                                        >
                                            {changeReason}
                                        </p>
                                    </td>
                                    <td data-label="New Value">{d.newValue}</td>
                                    <td data-label="Date">
                                        {new Date(
                                            d.changeDate
                                        ).toLocaleDateString()}
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        <></>
                    )}
                </tbody>
            </table>
        </section>
    );
}
