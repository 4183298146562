import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { IconButton } from "@mui/material";
import './styles/invoices.scss'

export default function IndvHistory(props) {
  const invoice = props.invoice;
  const [showInvoice, setShowInvoice] = useState(false);

  const handleInvoice = () => {
    setShowInvoice(!showInvoice)
  }

  return (
    <section className="invoice_header">
      <div className="header2" onClick={handleInvoice}>
        <h2>
          Invoice Date: <span>{invoice.invoiceDate}</span>
        </h2>
        <h2>
          Invoice Number: <span>{invoice.invoiceNumber}</span>
        </h2>
      </div>
      <IconButton className="icon" onClick={handleInvoice}>
        <KeyboardArrowDownIcon color="primary" />
      </IconButton>
      {showInvoice ? (
        <section className="invoice2">
          <table>
            <thead>
              <tr>
                <th></th>
                <th>Product Name</th>
                <th>Qty Ordered</th>
                <th>Qty Received</th>
                <th>Added To Store Stock</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {invoice.productsOrdered.map((product) => {
                var received = (product.packQty) * (product.qtyReceived)
                var rowCol;received!==product.qtyOrdered?rowCol="orange":rowCol="";
                return (
                  <tr style={{backgroundColor:rowCol}} key={product.Barcode}>
                    <td></td>
                    <td>{product.Name}</td>
                    <td>{product.qtyOrdered}</td>
                    <td>{received}</td>
                    <td>{product.eposAdjusted!==undefined?
                      <>{product.eposAdjusted?<>YES</>:<>NO</>}</>
                      :<>N/A</>}
                    </td>
                    <td></td>
                  </tr>
                )
              })}
              <br/>
              <tr>
                <td colSpan={6}>Note: <strong>{invoice.note}</strong></td>
              </tr>
              <tr>
                <td colSpan={6}>Processed by: <strong>{invoice.staffName}</strong> @ {new Date(invoice.submittedDate).toLocaleString()}</td>
              </tr>
            </tbody>
          </table>
        </section>
      ) : null}
    </section>
  );
}
