import { useState } from "react";
import axios from "axios";
import Logo from "../images/logo.png";
import "./styles/login.scss";

export default function PasswordReset() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");

  const reset = async (e) => {
    e.preventDefault();
    if (newPassword == confirmPassword && newPassword !== "" && email !== "") {
      const url = new URL(window.location.href);
      var id = url.searchParams.get("id");
      await axios
        .post("https://nitropos-backend.nitrosolutions.co.uk/users/reset", {
          id: id,
          password: newPassword,
          email: email,
        })
        .then((res) => {
          if (res.data === true) {
            alert("Password Changed");
            window.location.href = "/";
          } else {
            alert("Wrong Email Address");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      alert("Passwords Do Not Match");
    }
  };

  return (
    <section className="login">
      <img src={Logo} />
      <h1>Password Reset</h1>
      <form onSubmit={reset}>
        <input
          type="email"
          placeholder="Enter Your Email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Enter Password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <br />
        <input
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <br />
        <input type="submit" value="Reset" />
      </form>
    </section>
  );
}
