import "./wastageReport.scss";
import NavBar from "../ManagerNav";
import GetAppIcon from "@mui/icons-material/GetApp";
import { useEffect, useState } from "react";
import axios from "axios";
import { Alert, Button } from "@mui/material";
import Loader from "../../animations/Loader";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

export default function WastageReportHome() {
    const url = "https://nitropos-backend.nitrosolutions.co.uk";
    // const url = "http://10.0.0.174:4022";

    const [siteList, setSiteList] = useState([]);
    const [site, setSite] = useState("");
    const [toDate, setToDate] = useState("");
    const [fromDate, setFromDate] = useState("");
    const [selectedDate, setSelectedDate] = useState("");
    const [showDashboard, setShowDashboard] = useState(false);
    const [wasteCategory, setWasteCategory] = useState([]);
    const [catWasteSplit, setCatWasteSplit] = useState([]);
    const [catCostSplit, setCatCostSplit] = useState([]);
    const [categoryNames, setCategoryNames] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [expandedRows, setExpandedRows] = useState([]);
    const [expandedProductRows, setExpandedProductRows] = useState([]);
    const [totalWastedProducts, setTotalWastedProducts] = useState(0);
    const [totalCostProducts, setTotalCostProducts] = useState(0);
    //alerts
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    //loader
    const [showLoader, setShowLoader] = useState(false);

    //manager side
    const [user, setUser] = useState("");
    const [role, setRole] = useState("");
    const [siteName, setSiteName] = useState("");
    const [hasOneStore, setHasOneStore] = useState(false);

    //Get wasted totals
    const getWastedTotals = () => {
        let totalCost = 0;
        let totalWaste = 0;
        for (let i = 0; i < catCostSplit.length; i++) {
            totalCost += catCostSplit[i];
        }
        for (let i = 0; i < catWasteSplit.length; i++) {
            totalWaste += catWasteSplit[i];
        }
        setTotalCostProducts(totalCost);
        setTotalWastedProducts(totalWaste);
    };
    useEffect(() => {
        getWastedTotals();
    }, [wasteCategory]);

    //Get Sites on page load
    useEffect(() => {
        let userD = localStorage.getItem("userDetails");
        let user = JSON.parse(userD);
        setUser(user.user.user);
        setRole(user.user.role);
        var managerSites = user.user.sites;
        if (user.user.user == "louise@tubbees.co.uk") {
            managerSites = ["HQ"];
        }
        axios
            .post(`${url}/eposSQL/getSitesByName`, {
                sites: managerSites,
            })
            .then(async (res) => {
                //managers with only one site
                // if (res.data.length === 1) {
                //   let data = {
                //     siteId: res.data[0].eposId,
                //   };
                //   setSite(res.data[0].eposId);
                //   setSiteName(res.data[0].siteName);
                //   setHasOneStore(true);
                //   await axios
                //     .post(`${url}/claims/report`, data)
                //     .then((res) => {
                //       console.log(res.data);
                //     })
                //     .catch((err) => {
                //       console.log(err);
                //     });
                // } else {
                setSiteList(res.data);
                // }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    //Get store/month dependant waste report
    const handleGetWastageReport = () => {
        setShowLoader(true);
        if (
            site === "--Please select a site--" ||
            toDate === "" ||
            fromDate === ""
        ) {
            setShowLoader(false);
            setMessage("Please select site and dates");
            setSeverity("warning");
            setShowAlert(true);
            setWasteCategory([]);
            setTimeout(() => {
                setShowAlert(false);
                setMessage("");
                setSeverity("");
            }, 3000);
        } else {
            let data = {
                siteId: site,
                start: fromDate,
                end: toDate,
            };
            axios
                .post(`${url}/claims/report`, data)
                .then((res) => {
                    //   console.log(res.data);
                    let catNames = [];
                    let wasteSplit = [];
                    let costSplit = [];
                    for (let i = 0; i < res.data.wasteCat.length; i++) {
                        let formattedTotalWaste = parseFloat(
                            res.data.wasteCat[i].totalWasted
                        );
                        let formattedTotalCost = parseFloat(
                            res.data.wasteCat[i].totalCost
                                .replace("£", "")
                                .replace(",", "")
                        );

                        catNames.push(res.data.wasteCat[i].name);
                        wasteSplit.push(formattedTotalWaste);
                        costSplit.push(formattedTotalCost);
                    }

                    setCategoryNames(catNames);
                    setCatWasteSplit(wasteSplit);
                    setCatCostSplit(costSplit);
                    setWasteCategory(res.data.wasteCat);
                    setExpandedRows([]);
                    setShowLoader(false);
                    setShowDashboard(true);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    //Get category specific products
    const handleCategory = (id) => {
        let data = {
            siteId: site,
            start: fromDate,
            end: toDate,
            categoryId: id,
        };
        axios
            .post(`${url}/claims/report/category`, data)
            .then((res) => {
                console.log(res.data);
                setSelectedCategory(res.data.products);
            })
            .catch((err) => console.log(err));
    };

    //Toggle specific category prodcuts
    const toggleRow = (rowId) => {
        if (expandedRows.includes(rowId)) {
            setExpandedRows([]);
        } else {
            setExpandedRows([rowId]);
        }
    };

    //Toggle specific Product breakdown
    const toggleProductRow = (rowId) => {
        if (expandedProductRows.includes(rowId)) {
            setExpandedProductRows([]);
        } else {
            setExpandedProductRows([rowId]);
        }
    };

    return (
        <>
            <NavBar />{" "}
            <main className="wastageReport__main main">
                <section className="dashboard_tab">
                    <h1>Wastage report</h1>
                    <section className="dashboar_selection_row">
                        <select onChange={(e) => setSite(e.target.value)}>
                            <option>--Please select a site--</option>
                            {siteList.map(function (site) {
                                return (
                                    <option value={site.eposId}>
                                        {site.siteName}
                                    </option>
                                );
                            })}
                        </select>
                        <div className="date_selection">
                            <label>FROM:</label>
                            <input
                                type="date"
                                onChange={(e) => setFromDate(e.target.value)}
                            />
                        </div>
                        <div className="date_selection">
                            <label>TO:</label>
                            <input
                                type="date"
                                onChange={(e) => setToDate(e.target.value)}
                            />
                        </div>
                        <Button
                            variant="contained"
                            endIcon={<GetAppIcon />}
                            onClick={handleGetWastageReport}
                            sx={{
                                width: "200px",
                                padding: "8px",
                                borderRadius: "15px",
                            }}
                        >
                            Submit
                        </Button>
                    </section>
                    {showAlert ? (
                        <Alert severity={severity} sx={{ marginTop: "2%" }}>
                            {message}
                        </Alert>
                    ) : showLoader ? (
                        <Loader />
                    ) : showDashboard ? (
                        <>
                            {wasteCategory.length >= 1 ? (
                                <section className="dashboard_main">
                                    {wasteCategory.length > 1 ? (
                                        <div className="table_container">
                                            <h2>Totals</h2>
                                            <table className="totals_table">
                                                <thead>
                                                    <tr>
                                                        <td>Overall Wasted</td>
                                                        <td>Overall Cost</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            {
                                                                totalWastedProducts
                                                            }{" "}
                                                            items
                                                        </td>
                                                        <td>
                                                            £
                                                            {totalCostProducts.toFixed(
                                                                2
                                                            )}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <h2>Categories</h2>
                                            <table className="waste_category_table">
                                                <thead>
                                                    <th>Category</th>

                                                    <th>Total Wasted</th>
                                                    <th>Total cost</th>
                                                </thead>
                                                <tbody>
                                                    {wasteCategory.map(
                                                        (product) => {
                                                            return (
                                                                <>
                                                                    <tr>
                                                                        <td>
                                                                            {
                                                                                product.name
                                                                            }
                                                                        </td>

                                                                        <td>
                                                                            {
                                                                                product.totalWasted
                                                                            }
                                                                        </td>
                                                                        <td
                                                                            style={{
                                                                                display:
                                                                                    "flex",
                                                                                justifyContent:
                                                                                    "space-between",
                                                                            }}
                                                                        >
                                                                            {
                                                                                product.totalCost
                                                                            }
                                                                            {!expandedRows.includes(
                                                                                product.eposId
                                                                            ) ? (
                                                                                <ExpandMoreIcon
                                                                                    onClick={() => {
                                                                                        handleCategory(
                                                                                            product.eposId
                                                                                        );
                                                                                        toggleRow(
                                                                                            product.eposId
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            ) : (
                                                                                <ExpandLessIcon
                                                                                    onClick={() => {
                                                                                        handleCategory(
                                                                                            product.eposId
                                                                                        );
                                                                                        toggleRow(
                                                                                            product.eposId
                                                                                        );
                                                                                    }}
                                                                                    sx={{
                                                                                        background:
                                                                                            "white",
                                                                                        color: "black",
                                                                                        borderRadius:
                                                                                            "4px",
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                    <tr
                                                                        className="category_row"
                                                                        id={`row-${product.eposId}`}
                                                                    >
                                                                        {expandedRows.includes(
                                                                            product.eposId
                                                                        ) ? (
                                                                            <td
                                                                                colSpan={
                                                                                    3
                                                                                }
                                                                            >
                                                                                <table
                                                                                    className="product_table"
                                                                                    style={{
                                                                                        width: "100%",
                                                                                    }}
                                                                                >
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>
                                                                                                Product
                                                                                            </th>
                                                                                            <th>
                                                                                                Total
                                                                                                Wasted
                                                                                            </th>
                                                                                            <th>
                                                                                                Total
                                                                                                Cost
                                                                                            </th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    {selectedCategory.map(
                                                                                        (
                                                                                            product
                                                                                        ) => {
                                                                                            return (
                                                                                                <>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            {
                                                                                                                product.name
                                                                                                            }
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {
                                                                                                                product.total
                                                                                                            }
                                                                                                        </td>
                                                                                                        <td
                                                                                                            style={{
                                                                                                                display:
                                                                                                                    "flex",
                                                                                                                justifyContent:
                                                                                                                    "space-between",
                                                                                                            }}
                                                                                                        >
                                                                                                            {
                                                                                                                product.totalCost
                                                                                                            }
                                                                                                            {!expandedProductRows.includes(
                                                                                                                99999 -
                                                                                                                    product.eposId
                                                                                                            ) ? (
                                                                                                                <ExpandMoreIcon
                                                                                                                    onClick={() => {
                                                                                                                        toggleProductRow(
                                                                                                                            99999 -
                                                                                                                                product.eposId
                                                                                                                        );
                                                                                                                    }}
                                                                                                                />
                                                                                                            ) : (
                                                                                                                <ExpandLessIcon
                                                                                                                    onClick={() => {
                                                                                                                        toggleProductRow(
                                                                                                                            99999 -
                                                                                                                                product.eposId
                                                                                                                        );
                                                                                                                    }}
                                                                                                                    sx={{
                                                                                                                        background:
                                                                                                                            "black",
                                                                                                                        color: "white",
                                                                                                                        borderRadius:
                                                                                                                            "4px",
                                                                                                                    }}
                                                                                                                />
                                                                                                            )}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        {expandedProductRows.includes(
                                                                                                            99999 -
                                                                                                                product.eposId
                                                                                                        ) ? (
                                                                                                            <td
                                                                                                                colSpan={
                                                                                                                    3
                                                                                                                }
                                                                                                            >
                                                                                                                <table
                                                                                                                    className="damage_table"
                                                                                                                    style={{
                                                                                                                        width: "100%",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <thead>
                                                                                                                        <tr>
                                                                                                                            <th>
                                                                                                                                Damaged
                                                                                                                            </th>
                                                                                                                            <th>
                                                                                                                                Out
                                                                                                                                of
                                                                                                                                Date
                                                                                                                            </th>
                                                                                                                            <th>
                                                                                                                                Out
                                                                                                                                of
                                                                                                                                Temperature
                                                                                                                            </th>
                                                                                                                            <th>
                                                                                                                                Returned
                                                                                                                                To
                                                                                                                                Supplier
                                                                                                                            </th>
                                                                                                                        </tr>
                                                                                                                    </thead>
                                                                                                                    <tbody>
                                                                                                                        <tr>
                                                                                                                            <td>
                                                                                                                                {
                                                                                                                                    product.totalDamaged
                                                                                                                                }
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                {
                                                                                                                                    product.totalOutOfDate
                                                                                                                                }
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                {
                                                                                                                                    product.totalOutOfTemp
                                                                                                                                }
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                {
                                                                                                                                    product.totalReturnedToSupplier
                                                                                                                                }
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                    </tbody>
                                                                                                                </table>
                                                                                                            </td>
                                                                                                        ) : null}
                                                                                                    </tr>
                                                                                                </>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </table>
                                                                            </td>
                                                                        ) : null}
                                                                    </tr>
                                                                </>
                                                            );
                                                        }
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    ) : null}
                                </section>
                            ) : (
                                <h2>
                                    There is no information for this store for
                                    this period
                                </h2>
                            )}
                        </>
                    ) : null}
                </section>
            </main>
        </>
    );
}
