import "../styles/invoices.scss";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Box, Modal, Stack, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import Tick from "../../animations/Tick";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  getAllEposProducts,
  getAllHoagiesProducts,
  getAllTubbeesProducts,
} from "../../FTG/hoagiesUtils/Api";
import Loader from "../../animations/Loader";
import imageCompression from "browser-image-compression";
// //sentry playback
import * as Sentry from "@sentry/browser";

Sentry.init({
  dsn: "https://0892e3e583544098a8203610648c8929@sentry.rt-itservices.co.uk/20",

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 1.0,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  integrations: [
    new Sentry.Replay({
      // Additional SDK configuration goes in here, for example:
      maskAllText: false,
      blockAllMedia: true,
    }),
  ],
});

// replay.start();

const url = "https://nitropos-backend.nitrosolutions.co.uk";
 // https://nitropos-backend.nitrosolutions.co.uk
// const url= "http://10.0.0.34:4022"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40vw",
  minHeight: "40vh",
  maxHeight: "85vh",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
  color: "black",
  overflowY: "scroll",
  alignItems: "space-evenly",
  "@media(max-width: 600px)": {
    width: "95vw",
    maxHeight: "80vh",
    overflowY: "scroll",
  },
  "@media(min-width: 601px) and (max-width: 900px)": {
    width: "80vw",
    maxHeight: "80vh",
    overflowY: "scroll",
  },
};

const theme = createTheme({
  palette: {
    hoagies: {
      main: "#f9d505",
      darker: "#111111 ",
    },
    tubbees: {
      main: "#ef98c0",
      darker: "#69c7c5",
    },
  },
});

export default function WastageForm() {
  const navigate = useNavigate();
  const [site, setSite] = useState("");
  const [open, setOpen] = useState(false);
  const [barcode, setBarcode] = useState("");
  const [supplier, setSupplier] = useState("");
  const [staffName, setStaffName] = useState("");
  const [products, setProducts] = useState([]);
  const [wastageItems, setWastageItems] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [summary, setSummary] = useState(false);
  const [resellable, setResellable] = useState([]);
  const [binnedItems, setBinnedItems] = useState([]);
  const [collectionItems, setCollectionItems] = useState([]);
  const [message, setMessage] = useState("");
  const [warningAlert, setWarningAlert] = useState(false);
  const [showTick, setShowTick] = useState(false);
  const [staffOptions, setStaffOptions] = useState(false);
  const [binImg, setBinImg] = useState("");
  const [mobile, setMobile] = useState(false);
  const [type, setType] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  const client = Sentry.getCurrentHub().getClient();
  const replay = client?.getIntegration(Sentry.Replay);

  useEffect(() => {
    let getSite = localStorage.getItem("site");
    let localSite = getSite.replace(/['"]+/g, "");
    setSite(localSite);

    let width = window.innerWidth;
    let height = window.innerHeight;

    if (width < height) {
      setMobile(true);
    }

    if (client) {
      replay.flush()
    }
  }, []);

  const updateType = (type) => {
    setType(type);

    if (type === "search") {
      getAllEposProducts()
        .then((allProducts) => {
          setProducts(allProducts);
        })
        .catch((err) => {
          console.log(`Could not get all products: ${err}`);
        });
    } else if (type === "tubbees") {
      getAllTubbeesProducts()
        .then((tubbeesProducts) => {
          setProducts(tubbeesProducts);
        })
        .catch((err) => {
          console.log(`Could not get Tubbees products ${err}`);
        });
    } else if (type === "hoagies") {
      getAllHoagiesProducts()
        .then((allProducts) => {
          setProducts(allProducts);
        })
        .catch((err) => {
          console.log(`Could not get Hoagies products: ${err}`);
        });
    } else if (type === "") {
      setProducts([]);
      setWastageItems([]);
    }
  };

  const openModal = async () => {
    document.getElementById("wastage_opn_modal").style.display = "none";
    var open = true;
    for (let i = 0; i < wastageItems.length; i++) {
      if (
        wastageItems[i].wasted != "0" &&
        wastageItems[i].wasted != "" &&
        wastageItems[i].wasted > 0 &&
        wastageItems[i].reason != "" &&
        wastageItems[i].reason != "--Select a reason--" &&
        wastageItems[i].image != ""
      ) {
        document.getElementById("wastage_opn_modal").style.display = "flex";
        replay.stop();
      } else {
        open = false;
        setMessage(
          "Please instert quantity, reason and image before continuing"
        );

        setWarningAlert(true);
        setTimeout(() => {
          setWarningAlert(false);
          setMessage("");
          document.getElementById("wastage_opn_modal").style.display = "flex";
        }, 2500);
      }
    }
    setOpen(open);
  };

  const searchProduct = async (item) => {
    if (type === "barcode") {
      setProducts([]);
      if (item.length > 4) {
        axios
          .post(`${url}/claims/products/barcode`, { barcode: item })
          .then((res) => {
            if (res.data) {
              if (res.data.length >= 0 && res.data.length < 2) {
                setProducts(res.data);
                const prods = res.data;

                for (let index = 0; index < prods.length; index++) {
                  const product = prods[index];

                  var findIndex = wastageItems.findIndex(
                    (a) => a.eposId === product.eposId
                  );

                  if (findIndex === -1) {
                    var barcodes = [];
                    var bcode = "";
                    if (product.barcode != null) {
                      barcodes = product.barcode.split(",");
                      bcode = String(barcodes[barcodes.length - 1]) + "...";
                    } else {
                      bcode = "No Barcode";
                    }

                    setWastageItems((wasted) => [
                      ...wasted,
                      {
                        name: product.name,
                        barcode: bcode,
                        eposId: product.eposId,
                        wasted: 0,
                        supplierId: product.supplierId,
                        supplierName: product.supplierName,
                        reason: "",
                        image: "",
                        fixable: null,
                        fresh: product.fresh,
                      },
                    ]);
                    document.getElementById("search_input").value = "";
                  }
                }
              } else if (res.data.length == 0) {
                setMessage("No Barcode Found");
                setWarningAlert(true);
                setTimeout(() => {
                  setWarningAlert(false);
                  setMessage("");
                  document.getElementById("submit_wastage").style.display =
                    "flex";
                }, 5000);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      if (item.length > 4) {
        for (let p = 0; p < products.length; p++) {
          if (products[p].barcode == item || products[p].name.trim() == item) {
            let barcodes = [];
            let barcode;
            if (products[p].barcode != null) {
              barcodes = products[p].barcode.split(",");
              barcode = String(barcodes[barcodes.length - 1]) + "...";
            } else {
              barcode = "No Barcode";
            }

            setWastageItems((wasted) => [
              ...wasted,
              {
                name: products[p].name,
                barcode: barcode,
                eposId: products[p].eposId,
                wasted: 0,
                supplierId: products[p].supplierId,
                supplierName: products[p].supplierName,
                reason: "",
                image: "",
                fixable: null,
                fresh: products[p].fresh,
              },
            ]);
            document.getElementById("search_input").value = "";
          }
        }
      }
    }
  };

  const inputReceived = (index, amount) => {
    const updatedItems = wastageItems.map((item, i) => {
      if (i != index) {
        return item;
      } else {
        return { ...item, wasted: amount };
      }
    });
    setWastageItems(updatedItems);
  };

  const updateReason = (index, reason) => {
    const updatedItems = wastageItems.map((item, i) => {
      if (i != index) {
        return item;
      } else {
        return { ...item, reason: reason };
      }
    });
    setWastageItems(updatedItems);
  };

  const addImage = async (index, image) => {
    //compression of the image for upload
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 100,
    };
    const compressedFile = await imageCompression(image, options);
    const fileReader = new FileReader();
    fileReader.readAsDataURL(compressedFile);
    fileReader.onload = () => {
      wastageItems[index].image = fileReader.result;
    };
  };

  const handleRemove = (i) => {
    const removeItem = wastageItems.filter((val, index) => index !== i);
    setWastageItems(removeItem);
  };

  const updateStat = (index, type, val) => {
    console.log(wastageItems[index]);
    console.log(index, type, val);
    const updatedItems = wastageItems.map((item, i) => {
      if (i != index) {
        console.log("Not Found");
        return item;
      } else {
        console.log("Update");
        return { ...item, [type]: val };
      }
    });
    setWastageItems(updatedItems);

    if (type == "fresh") {
      setStaffOptions(true);
    }
  };

  const binImage = async (img) => {
    //compression of the image for upload
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 100,
    };
    const compressedFile = await imageCompression(img, options);
    const fileReader = new FileReader();
    fileReader.readAsDataURL(compressedFile);
    fileReader.onload = () => {
      setBinImg(fileReader.result);
    };
  };

  const nextItem = () => {
    setCurrentIndex(currentIndex + 1);
  };

  const showSummary = () => {
    for (let index = 0; index < wastageItems.length; index++) {
      const item = wastageItems[index];

      if (item.fresh == true) {
        binnedItems.push(item);
        wastageItems[index]["end"] = "Bin";
      } else if (item.fixable == false && item.fresh == false) {
        collectionItems.push(item);
        wastageItems[index]["end"] = "Collection";
      } else if (item.fixable == true) {
        resellable.push(item);
        wastageItems[index]["end"] = "Resellable";
      }
    }

    setSummary(true);
  };

  const submitForm = async () => {
    document.getElementById("submit_wastage").style.display = "none";
    setShowLoader(true);
    if (staffName === "") {
      setMessage("Please insert your name");
      setWarningAlert(true);
      setTimeout(() => {
        setWarningAlert(false);
        setMessage("");
        setShowLoader(false);
        document.getElementById("submit_wastage").style.display = "flex";
      }, 3500);
    } else if (binnedItems.length > 0 && binImg == "") {
      setMessage("Please insert a bin image");
      setWarningAlert(true);
      setTimeout(() => {
        setWarningAlert(false);
        setMessage("");
        setShowLoader(false);
        document.getElementById("submit_wastage").style.display = "flex";
      }, 3500);
    } else {
      await replay.flush();

      var date = new Date();
      var wastageId = "";

      await axios
        .post(`${url}/claims/submit/wastage`, {
          site: site,
          staffName: staffName,
          date: date,
          // binImg: "",
          source: type,
        })
        .then((res) => {
          wastageId = res.data;
        })
        .catch((err) => {
          console.log(err);
        });

      if (wastageId != "") {
        for (let i = 0; i < wastageItems.length; i++) {
          await axios
            .post(`${url}/claims/submit/items/${wastageId}`, wastageItems[i])
            .then((resp) => {
              console.log("Added -", wastageItems[i].name);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
      setShowLoader(false);
      setShowTick(true);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };

  return (
    <section className="invoices">
      <div className="invoiceTable">
        <h1>Damaged & Wasted Goods Form {type}</h1>

        {type != "" ? (
          <div className="wasteForm">
            <input
              list="products_ID"
              placeholder="Select Product"
              id="search_input"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  searchProduct(e.target.value);
                }
              }}
            />
            <datalist
              id="products_ID"
              onChange={(e) => {
                console.log("CHANGE");
              }}
            >
              {products.map(function (d, i) {
                return (
                  <option value={d.barcode} id={i}>
                    {String(d.name)}
                  </option>
                );
              })}
            </datalist>
            {wastageItems.length >= 1 ? (
              <>
                {mobile ? (
                  <>
                    {wastageItems.map(function (d, i) {
                      return (
                        <div className="mobile__box" key={i}>
                          <div className="mobile_box_head">
                            <h6>{d.barcode}</h6>
                            <h6>{d.name}</h6>
                          </div>
                          <div className="mobile_box_body">
                            <div className="item_body">
                              <p>
                                Supplier:{" "}
                                {d.supplierName ? (
                                  <span>{d.supplierName}</span>
                                ) : (
                                  <span>No supplier</span>
                                )}
                              </p>
                              <p>
                                Fresh:{" "}
                                {d.fresh ? <span>Yes</span> : <span>No</span>}
                              </p>
                            </div>
                            <div className="body_right">
                              <select
                                onChange={(e) =>
                                  updateReason(i, e.target.value)
                                }
                                value={d.reason}
                              >
                                <option>--Select a reason--</option>
                                <option>Damaged</option>
                                <option>Out of Date</option>
                                <option>Out of Temperature</option>
                                <option>Return to supplier</option>
                              </select>
                              <input
                                type="file"
                                accept="image/*;capture=camera"
                                onChange={(e) => addImage(i, e.target.files[0])}
                              />
                            </div>
                          </div>
                          <div className="mobile_box_body">
                            <input
                              type="number"
                              onChange={(e) => inputReceived(i, e.target.value)}
                              placeholder={d.wasted}
                              min={0}
                              value={d.wasted}
                              pattern="[0-9]"
                            />
                            <RemoveCircleOutlineIcon
                              fontSize="medium"
                              color="primary"
                              onClick={() => handleRemove(i)}
                            />
                          </div>
                        </div>
                      );
                    })}
                    <Button
                      variant="outlined"
                      color="success"
                      endIcon={<SendIcon />}
                      onClick={() => openModal()}
                      style={{ width: "20%", margin: "auto" }}
                      id="wastage_opn_modal"
                    >
                      Next
                    </Button>
                    {warningAlert ? (
                      <Alert severity="warning">{message}</Alert>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <>
                    <table>
                      <thead>
                        <tr>
                          <th>Barcode</th>
                          <th>Product Name</th>
                          <th>Supplier</th>
                          <th>Units Damaged/Wasted</th>
                          <th>Reason</th>
                          <th>Fresh</th>
                          <th>Image Upload</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {wastageItems.map(function (d, i) {
                          return (
                            <tr key={d.barcode}>
                              <td>{d.barcode}</td>
                              <td>{d.name}</td>
                              <td>{d.supplierName}</td>
                              <td>
                                <input
                                  type="number"
                                  onChange={(e) =>
                                    inputReceived(i, e.target.value)
                                  }
                                  placeholder={d.wasted}
                                  min={0}
                                  value={d.wasted}
                                  pattern="[0-9]"
                                />
                              </td>
                              <td>
                                <select
                                  onChange={(e) =>
                                    updateReason(i, e.target.value)
                                  }
                                  value={d.reason}
                                >
                                  <option>--Select a reason--</option>
                                  <option>Damaged</option>
                                  <option>Out of Date</option>
                                  <option>Out of Temperature</option>
                                  <option>Return to supplier</option>
                                </select>
                              </td>
                              <td>{d.fresh ? <>Yes</> : <>No</>}</td>
                              <td>
                                <input
                                  type="file"
                                  onChange={(e) =>
                                    addImage(i, e.target.files[0])
                                  }
                                />
                              </td>
                              <td>
                                <RemoveCircleOutlineIcon
                                  fontSize="small"
                                  color="primary"
                                  onClick={() => handleRemove(i)}
                                  sx={{ margin: "0" }}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <Button
                      variant="outlined"
                      color="success"
                      endIcon={<SendIcon />}
                      onClick={() => openModal()}
                      style={{ width: "20%", margin: "auto" }}
                      id="wastage_opn_modal"
                    >
                      Next
                    </Button>
                    {warningAlert ? (
                      <Alert severity="warning">{message}</Alert>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </>
            ) : (
              <></>
            )}

            <br />
            <Button
              variant="outlined"
              color="error"
              endIcon={<SendIcon />}
              onClick={() => updateType("")}
              style={{ width: "20%", margin: "auto" }}
              id="wastage_opn_modal"
            >
              Back
            </Button>

            {wastageItems.length > 0 ? (
              <Modal
                open={open}
                // onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                overflow-y="scroll"
              >
                <Box sx={style}>
                  {summary ? (
                    <div className="invoiceTable">
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        Summary
                      </Typography>
                      {binnedItems.length > 0 ? (
                        <div>
                          <h2>BINNED ITEMS</h2>
                          <table className="modal__table__wastage">
                            <thead>
                              <tr>
                                <th>Product Name</th>
                                <th>Units Damaged/Wasted</th>
                                <th>Reason</th>
                              </tr>
                            </thead>
                            <tbody>
                              {binnedItems.map(function (d, i) {
                                return (
                                  <tr key={i}>
                                    <td>{d.name}</td>
                                    <td>{d.wasted}</td>
                                    <td>{d.reason}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                          {binImg == "" ? (
                            <>
                              <p>
                                Please upload an image of these goods in the bin
                              </p>
                              <input
                                type="file"
                                onChange={(e) => binImage(e.target.files[0])}
                              />
                            </>
                          ) : (
                            <></>
                          )}
                          <hr />
                        </div>
                      ) : (
                        <></>
                      )}
                      {collectionItems.length > 0 ? (
                        <div>
                          <h2>COLLECTION ITEMS</h2>
                          <table className="modal__table__wastage">
                            <thead>
                              <tr>
                                <th>Product Name</th>
                                <th>Units Damaged/Wasted</th>
                                <th>Reason</th>
                              </tr>
                            </thead>
                            <tbody>
                              {collectionItems.map(function (d, i) {
                                return (
                                  <tr>
                                    <td>{d.name}</td>
                                    <td>{d.wasted}</td>
                                    <td>{d.reason}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                          <hr />
                        </div>
                      ) : (
                        <></>
                      )}
                      {resellable.length > 0 ? (
                        <div>
                          <h2>RESELLABLE ITEMS</h2>
                          <table className="modal__table__wastage">
                            <thead>
                              <tr>
                                <th>Product Name</th>
                                <th>Units Damaged/Wasted</th>
                                <th>Reason</th>
                              </tr>
                            </thead>
                            <tbody>
                              {resellable.map(function (d, i) {
                                return (
                                  <tr key={i}>
                                    <td>{d.name}</td>
                                    <td>{d.wasted}</td>
                                    <td>{d.reason}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                          <hr />
                        </div>
                      ) : (
                        <></>
                      )}
                      <div>
                        <span>Your Name: </span>
                        <input
                          type="text"
                          placeholder="Enter Your Name"
                          value={staffName}
                          onChange={(e) => {
                            setStaffName(e.target.value);
                          }}
                        />
                      </div>
                      <>
                        <Button
                          variant="contained"
                          color="success"
                          onClick={() => {
                            submitForm();
                          }}
                          id="submit_wastage"
                          sx={{ marginTop: "1%" }}
                        >
                          Submit Form
                        </Button>
                        {warningAlert ? (
                          <Alert severity="warning">{message}</Alert>
                        ) : (
                          <></>
                        )}
                        {showTick ? <Tick /> : <></>}
                        {showLoader ? <Loader /> : <></>}
                      </>
                    </div>
                  ) : (
                    <>
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        Please Complete The Next Steps
                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <Stack spacing={2} alignItems="center">
                          <h3>{wastageItems[currentIndex].name}</h3>
                          <img
                            src={wastageItems[currentIndex].image}
                            height="200px"
                          />
                          <p>
                            Is This Item Sellable/Fixable?
                            {wastageItems[currentIndex].fixable != true &&
                            wastageItems[currentIndex].fixable != false ? (
                              <>
                                <button
                                  onClick={() => {
                                    updateStat(currentIndex, "fixable", true);
                                  }}
                                  id="yes__btn"
                                >
                                  Yes
                                </button>
                                <button
                                  onClick={() => {
                                    updateStat(currentIndex, "fixable", false);
                                  }}
                                  id="no__btn"
                                >
                                  No
                                </button>
                              </>
                            ) : (
                              <>
                                {" "}
                                <b>
                                  {wastageItems[currentIndex].fixable
                                    ? "yes"
                                    : "no"}
                                </b>
                              </>
                            )}
                          </p>
                          {wastageItems[currentIndex].fixable == false ? (
                            <p>
                              Is This Item Fresh?
                              {!staffOptions ? (
                                <>
                                  <button
                                    onClick={() => {
                                      updateStat(currentIndex, "fresh", true);
                                    }}
                                    id="yes__btn"
                                  >
                                    Yes
                                  </button>
                                  <button
                                    onClick={() => {
                                      updateStat(currentIndex, "fresh", false);
                                    }}
                                    id="no__btn"
                                  >
                                    No
                                  </button>
                                </>
                              ) : (
                                <b>
                                  {wastageItems[currentIndex].fresh ? (
                                    <> Yes</>
                                  ) : (
                                    <> No</>
                                  )}
                                </b>
                              )}
                            </p>
                          ) : (
                            <></>
                          )}
                          {wastageItems[currentIndex].fresh == false &&
                          staffOptions ? (
                            <p>
                              {/* If No2 - show user where to place stock, deduct stock, add to manager zone*/}
                              Please place this item in the in-store collection
                              area
                            </p>
                          ) : (
                            <></>
                          )}
                        </Stack>
                        <Stack direction="row" spacing={2}>
                          {currentIndex < wastageItems.length - 1 ? (
                            <>
                              <Button
                                variant="contained"
                                color="success"
                                onClick={() => {
                                  nextItem();
                                  setStaffOptions(false);
                                }}
                              >
                                Next Item
                              </Button>
                              <Button
                                variant="outlined"
                                color="error"
                                onClick={() => {
                                  setOpen(false);
                                }}
                              >
                                Cancel
                              </Button>
                            </>
                          ) : (
                            <Button
                              variant="contained"
                              color="success"
                              onClick={() => {
                                showSummary();
                              }}
                            >
                              See Summary
                            </Button>
                          )}
                        </Stack>
                      </Typography>
                    </>
                  )}
                </Box>
              </Modal>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <div className="wasteForm">
            <Button
              variant="outlined"
              color="success"
              onClick={() => {
                updateType("barcode");
              }}
            >
              Search By Barcode
            </Button>

            <Button
              variant="outlined"
              color="success"
              onClick={() => {
                updateType("search");
              }}
            >
              Search By Name
            </Button>
            {/* hoagies */}
            <ThemeProvider theme={theme}>
              <Button
                variant="outlined"
                color="tubbees"
                onClick={() => {
                  updateType("tubbees");
                }}
              >
                Tubbees
              </Button>

              <Button
                variant="outlined"
                color="hoagies"
                onClick={() => {
                  updateType("hoagies");
                }}
              >
                Hoagies
              </Button>
            </ThemeProvider>
          </div>
        )}
      </div>
    </section>
  );
}
