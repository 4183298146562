import { useState, useEffect } from "react";
import moment from "moment"
import axios from 'axios'
import './posExplorer.scss'
import ExploreOffIcon from '@mui/icons-material/ExploreOff';
import SearchIcon from "@mui/icons-material/Search";
import Loader from "../../animations/Loader";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';

export default function Summary(props) {
    const [tempItems, setTempItems] = useState([])
    const [tempCategories, setTempCategories] = useState([])
    const [items, setItems] = useState([])
    const [categories, setCategories] = useState([])
    const [tenders, setTenders] = useState([])
    const [showLoader, setShowLoader] = useState(false)
    const [itemSearchVal, setItemSearchVal] = useState("")
    const [categorySearchVal, setCategorySearchVal] = useState("")
    const url = "https://nitropos-backend.nitrosolutions.co.uk";
    useEffect(() => {

        setTempItems([])
        setTempCategories([])
        setItems([])
        setCategories([])
        setTenders([])
        setItemSearchVal("")
        setCategorySearchVal("")
        if (props.selectedShift) {
            setShowLoader(true)
            axios.get(`${url}/posExplorer/summary/shift/${props.selectedShift}`).then((resp) => {
                setItems(resp.data.items)
                setCategories(resp.data.categories)
                setTempItems(resp.data.items)
                setTempCategories(resp.data.categories)
                setTenders(resp.data.tenders)
                setShowLoader(false)
            }).catch((err) => {
                console.log(err)
            })

        }
    }, [props.selectedShift])

    const searchItem = () => {
        if (itemSearchVal != "") {
            var temp = items;
            var data = temp.filter((i) => i.name.toLowerCase().includes(itemSearchVal.toLowerCase()))
            // for (let d = 0; d < temp.length; d++) {
            //     var idx = data.findIndex((t) => t.productId == temp[d].productId)
            //     if (idx >= 0) {
            //         temp[d].bgColour = "#2c53a0"
            //     } else {
            //         temp[d].bgColour = ""
            //     }
            // }
            // temp.sort((a, b) => b.bgColour.localeCompare(a.bgColour))
            setTempItems([])
            setTimeout(() => {
                setTempItems(data)
                //setItemSearchVal("")
            }, 500)
        }
    }

    const searchCategory = () => {
        if (categorySearchVal != "") {
            var temp = categories;
            var data = temp.filter((i) => i.name.toLowerCase().includes(categorySearchVal.toLowerCase()))
            // for (let d = 0; d < temp.length; d++) {
            //     var idx = data.findIndex((t) => t.name == temp[d].name)
            //     if (idx >= 0) {
            //         temp[d].bgColour = "#2c53a0"
            //     } else {
            //         temp[d].bgColour = ""
            //     }
            // }
            //temp.sort((a, b) => b.bgColour.localeCompare(a.bgColour))
            setTempCategories([])
            setTimeout(() => {
                setTempCategories(data)
                //setCategorySearchVal("")
            }, 500)
        }
    }

    const resetItem = () => {
        setTempItems([])
        setItemSearchVal("")
        setShowLoader(true)

        var newTrans = items;
        newTrans.forEach(function (t) { delete t.bgColour });

        const sortedArray = newTrans.sort((a, b) =>
            parseFloat(b.totalSold) - parseFloat(a.totalSold)
        )

        setTimeout(() => {
            setTempItems(sortedArray)
            setShowLoader(false)
        }, 500)
    }

    const resetCategory = () => {
        setTempCategories([])
        setCategorySearchVal("")
        setShowLoader(true)

        var newTrans = categories;
        newTrans.forEach(function (t) { delete t.bgColour });

        const sortedArray = newTrans.sort((a, b) =>
            parseFloat(b.value) - parseFloat(a.value)
        )

        setTimeout(() => {
            setTempCategories(sortedArray)
            setShowLoader(false)
        }, 100)
    }

    const handleEnterItem = (e) => {
        if (e.keyCode === 13) {
            searchItem();
        }
    };
    const handleEnterCategory = (e) => {
        if (e.keyCode === 13) {
            searchCategory();
        }
    };

    return (
        <div>
            <div id="searchBar">
                <div><h2 style={{ color: "#ffff", marginBottom: "-7%" }}>SUMMARY</h2></div>
            </div>

            {showLoader ?
                <div style={{ marginTop: "20vh" }}><Loader /></div> :
                <div className="itemTable summary">
                    <div style={{ borderRight: "2px solid #2c53a0", height: "55.5vh" }}>
                        <p>Items</p>
                        {tempItems.length > 0 ?
                            <>
                                <div style={{ maxHeight: "45vh", overflow: "auto", paddingBottom: "2%" }}>
                                    <table >
                                        <thead style={{ borderBottom: "2px solid #64c4e0" }}>
                                            <tr>
                                                <th>Name</th>
                                                <th>Qty</th>
                                                <th>Value</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tempItems.map(function (d, idx) {
                                                var textCol; d.bgColour == "" || !d.bgColour ? textCol = "black" : textCol = "white"
                                                var newCol = false
                                                if (tempItems[idx + 1] && d.bgColour) {
                                                    d.bgColour != tempItems[idx + 1].bgColour ? newCol = true : newCol = false;
                                                }
                                                return (
                                                    <>
                                                        <tr key={idx} style={{ color: textCol, backgroundColor: d.bgColour, borderBottom: "1px solid", borderBottomColor: textCol }}>
                                                            <td>{d.name}</td>
                                                            <td>{d.quantitySold}</td>
                                                            <td>£{d.totalSold}</td>
                                                        </tr>
                                                        {newCol ?
                                                            <tr>
                                                            </tr>
                                                            : null}
                                                    </>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div style={{ textAlign: "center", position: "absolute", bottom: "9%", left: "33%" }} id="itemSearch">
                                    <hr />
                                    <input style={{ border: "2px solid #2c53a0", borderRadius: "5px" }} type="text"
                                        placeholder="Search for a product..." value={itemSearchVal}
                                        onChange={(e) => setItemSearchVal(e.target.value)}
                                        onKeyDown={handleEnterItem} />
                                    <SearchIcon
                                        fontSize="small"
                                        color="primary"
                                        onClick={() => searchItem()}
                                    />
                                    {itemSearchVal != "" ?
                                        <HighlightOffIcon
                                            fontSize="medium"
                                            color="error"
                                            onClick={() => resetItem()}
                                        /> : null}
                                </div>
                            </>
                            :
                            <div style={{ textAlign: "center", marginTop: "15%", color: "#000", width: "21.5vw" }}>
                                <ExploreOffIcon fontSize="large" color="primary" />
                                <p>No Items Matching The Parameters Could Be Found For This Shift</p>
                                <div style={{paddingTop:"5%"}} onClick={() => resetItem()} >
                                    <ReplayCircleFilledIcon fontSize="large" color="warning" />
                                    <p>RESET</p>
                                </div>

                            </div>
                        }
                    </div>
                    <div style={{ borderRight: "2px solid #2c53a0", height: "55.5vh" }}>
                        <p>Categories</p>
                        {tempCategories.length > 0 ?
                            <>
                                <div style={{ maxHeight: "45vh", overflow: "auto", paddingBottom: "2%" }}>
                                    <table>
                                        <thead style={{ borderBottom: "2px solid #64c4e0" }}>
                                            <tr>
                                                <th>Name</th>
                                                <th>Qty</th>
                                                <th>Value</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tempCategories.map(function (d, idx) {
                                                var textCol; d.bgColour == "" || !d.bgColour ? textCol = "black" : textCol = "white"
                                                var newCol = false
                                                if (tempCategories[idx + 1] && d.bgColour) {
                                                    d.bgColour != tempCategories[idx + 1].bgColour ? newCol = true : newCol = false;
                                                }
                                                return (
                                                    <>
                                                        <tr key={idx} style={{ color: textCol, backgroundColor: d.bgColour, borderBottom: "1px solid", borderBottomColor: textCol }}>
                                                            <td>{d.name}</td>
                                                            <td>{d.qty}</td>
                                                            <td>£{d.value}</td>
                                                        </tr>
                                                        {newCol ?
                                                            <tr></tr>
                                                            : null}
                                                    </>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>

                                <div style={{ textAlign: "center", position: "absolute", bottom: "9%", left: "55%" }} id="catSearch">
                                    <hr />
                                    <input style={{ border: "2px solid #2c53a0", borderRadius: "5px" }} type="text"
                                        placeholder="Search for a category..." value={categorySearchVal}
                                        onChange={(e) => setCategorySearchVal(e.target.value)}
                                        onKeyDown={handleEnterCategory} />
                                    <SearchIcon
                                        fontSize="small"
                                        color="primary"
                                        onClick={() => searchCategory()}
                                    />

                                    {categorySearchVal != "" ?
                                        <HighlightOffIcon
                                            fontSize="medium"
                                            color="error"
                                            onClick={() => resetCategory()}
                                        /> : null}
                                </div>
                            </>
                            :
                            <div style={{ textAlign: "center", marginTop: "15%", color: "#000", width:"21.5vw" }}>
                                <ExploreOffIcon fontSize="large" color="primary" />
                                <p>No Categories Matching The Parameters Could Be Found For This Shift</p>
                                <div style={{paddingTop:"5%"}} onClick={() => resetCategory()} >
                                    <ReplayCircleFilledIcon fontSize="large" color="warning" />
                                    <p>RESET</p>
                                </div>
                            </div>}
                    </div>
                    <div style={{ maxHeight: "55vh", overflow: "auto", paddingBottom: "2%" }}>
                        <p>Tenders</p>
                        {tenders.length > 0 ?
                            <>
                                <table>
                                    <thead style={{ borderBottom: "2px solid #64c4e0" }}>
                                        <tr>
                                            <th>Name</th>
                                            <th>Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tenders.map(function (d, idx) {
                                            return (
                                                <tr key={idx}>
                                                    <td>{d.tenderName!=="SNAPPY DELIVERY"&& d.tenderName!=="Cash"?
                                                            d.name
                                                            :
                                                            d.tenderName
                                                        }
                                                    </td>
                                                    <td>£{d.totalIntake}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </>
                            :
                            <div style={{ textAlign: "center", marginTop: "15%", color: "#000" }}>
                                <ExploreOffIcon fontSize="large" color="primary" />
                                <p>No Tenders Could Be Found For This Shift</p>
                            </div>}
                    </div>
                </div>
            }

        </div>
    )
}