import { useEffect, useState } from "react";
import DevNav from "../../devZone/nav/DevNav";
import ManagerNav from "../../managerZone/ManagerNav";
import FtgNav from "../../FTG/FtgNav";
import WareNav from "../../warehouse/WareNav";
import NavBar from "../../NavBar";
import "./statuses.scss";
import { Button, Alert } from "@mui/material";
import QrReader from "react-web-qr-reader";
import axios from "axios";
import Tick from "../../animations/Tick";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

export default function CctvStatus() {
    const url = process.env.REACT_APP_ANPR_URL;
    const [role, setRole] = useState("");
    const [user, setUser] = useState("");
    const [idList, setIdList] = useState([]);
    const [code, setCode] = useState("");
    const [showManualInput, setShowManualInput] = useState(false);
    const [cctvId, setCctvId] = useState("");
    //   alerts
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");
    const [showTick, setShowTick] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const previewStyle = {
        height: 240,
        width: 320,
    };

    useEffect(() => {
        let userD = localStorage.getItem("userDetails");
        let user = JSON.parse(userD);
        setRole(user.user.role);
        setUser(user.user.user);
    }, []);

    const handleError = (error) => {
        console.log(error);
    };

    const searchId = (id) => {
        let index = idList.findIndex((i) => i.id == id);

        if (index < 0) {
            axios
                .get(`${url}/cctv-system/submissions/details/${id}`)
                .then((res) => {
                    setIdList((idList) => [
                        ...idList,
                        {
                            id: res.data.requestId,
                            site: res.data.site,
                            date: new Date(res.data.date).toLocaleDateString(
                                "en-GB"
                            ),
                            status: res.data.statusName,
                        },
                    ]);
                    setCctvId("");
                })
                .catch((err) => {
                    console.log(`could not find that request:${err}`);
                    if (err.response.status === 500) {
                        setMessage(
                            "Could not find CCTV request, please try again"
                        );
                        setSeverity("warning");
                        setShowAlert(true);
                        setTimeout(() => {
                            setShowAlert(false);
                        }, 3000);
                    }
                    setCctvId("");
                });
        } else {
            setMessage("DVD already scanned");
            setSeverity("warning");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
            }, 3000);
        }
    };

    const updateIds = () => {
        setDisabled(true);
        let idArray = [];

        for (let i = 0; i < idList.length; i++) {
            idArray.push(parseInt(idList[i].id));
        }
        axios
            .post(`${url}/cctv-system/QR/next`, {
                requestIds: idArray,
                user: user,
            })
            .then((res) => {
                const response = res.data;
                for (let r = 0; r < response.length; r++) {
                    let index = idList.findIndex(
                        (i) => i.requestId == response[r].requestId
                    );
                    if (response[r].updated === true) {
                        idList.splice(index, 1);
                    } else {
                        idList[index].status = "Can not be updated";
                    }
                }
                if (idList.length === 0) {
                    setShowTick(true);
                    setTimeout(() => {
                        setDisabled(true);
                        setShowTick(false);
                    }, 3000);
                } else {
                    setMessage(
                        `Following CCTV's could not be updated, please speak to the Developer team`
                    );
                    setSeverity("warning");
                    setShowAlert(true);
                }
            })
            .catch((err) => {
                console.log(`Could not update the requests: ${err}`);
                setMessage(`Could not update the requests: ${err}`);
                setSeverity("error");
                setShowAlert(true);
                setTimeout(() => {
                    setShowAlert(false);
                    setDisabled(false);
                }, 3000);
            });
    };

    const handleScan = async (result) => {
        searchId(result.data);
    };

    const handleEnter = (e) => {
        if (e.keyCode === 13) {
            searchId(code);
        }
    };

    return (
        <main className="cctv_status">
            {role === "developer" ? (
                <DevNav />
            ) : role === "Store Manager" ? (
                <ManagerNav />
            ) : role === "Food Manager" ? (
                <FtgNav />
            ) : role === "Warehouse Manager" ? (
                <WareNav />
            ) : (
                <NavBar />
            )}
            <h1>cctv status</h1>
            {showAlert ? (
                <Alert
                    severity={severity}
                    sx={{ width: "80%", margin: "auto" }}
                >
                    {message}
                </Alert>
            ) : (
                <QrReader
                    delay={500}
                    style={previewStyle}
                    onError={handleError}
                    onScan={handleScan}
                />
            )}{" "}
            <Button
                id="manual_btn"
                variant="outlined"
                color="primary"
                onClick={() => setShowManualInput(!showManualInput)}
            >
                Unable to scan? - Log mannually
            </Button>
            {showManualInput ? (
                <>
                    <div id="manual_input">
                        <input
                            type="text"
                            onChange={(e) => setCctvId(e.target.value)}
                            placeholder="Enter CCTV request ID"
                            value={cctvId}
                        />
                        <Button onClick={() => searchId(cctvId)}>
                            <AddCircleOutlineIcon />
                        </Button>
                    </div>
                </>
            ) : null}
            {/* Testing input */}
            {/* <input
        type="text"
        onChange={(e) => setCode(e.target.value)}
        onKeyDown={handleEnter}
        style={{ marginTop: "8%", width: "50%" }}
      />  */}
            {idList.length > 0 ? (
                <>
                    <table>
                        <thead>
                            <tr>
                                <th>Site</th>
                                <th>Date</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {idList.map((id, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{id.site}</td>
                                        <td>{id.date}</td>
                                        <td>{id.status}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <Button
                        variant="outlined"
                        disable={disabled}
                        onClick={updateIds}
                    >
                        update
                    </Button>
                </>
            ) : null}
            {showTick ? <Tick /> : null}
        </main>
    );
}
