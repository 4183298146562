import { useState, useEffect } from 'react';
import axios from 'axios';
import List from './discrepancies/List';
import Investigation from './discrepancies/Investigation';
import './discrepancies/discrepancies.scss';

export default function Discrepancies() {
  const [user, setUser] = useState("");
  const [message, setMessage] = useState("")
  const [siteName, setSiteName] = useState("")
  const [sites, setSites] = useState([])
  const [site, setSite] = useState("")
  const [data, setData] = useState([])
  const [reload, setReload] = useState(false)
  const [discrepancy, setDiscrepancy] = useState()

  useEffect(() => {
    let userD = localStorage.getItem("userDetails");
    let user = JSON.parse(userD);
    setUser(user.user.user);
    axios
      .post(
        `https://nitropos-backend.nitrosolutions.co.uk/eposSQL/getSitesByName`,
        { sites: user.user.sites }
      )
      .then((res) => {
        if (res.data.length > 1) {
          setSites(res.data)
        } else if (res.data.length === 1) {
          setSiteName(res.data[0].siteName)
          setSite(res.data[0].eposId);
          axios.post(`https://nitropos-backend.nitrosolutions.co.uk/discrepancies/storeInvestigation`, { site: res.data[0].siteName })
            .then((res) => {
              setData(res.data)
            }).catch((err) => {
              console.log(err)
            })
        }
      }).catch((err) => {
        console.log(err)
      })
  }, [reload])

  const rel = () => {
    setReload(!reload)
    console.log("test")
    handleSite(siteName)
    setDiscrepancy({})
  }

  const handleSite = async(sn) => {
    setSiteName(sn)
    setSite(sn)
    await axios.post(`https://nitropos-backend.nitrosolutions.co.uk/discrepancies/storeInvestigation`, { site: sn })
      .then((res) => {
        setData(res.data)
      }).catch((err) => {
        console.log(err)
      })
  }

  const handleInvestigation = (id) => {
    axios.get(`https://nitropos-backend.nitrosolutions.co.uk/discrepancies/storeInvestigation/${id}`)
      .then((res) => {
        console.log(res.data)
        setDiscrepancy(res.data)
      }).catch((err) => {
        console.log(err)
      })
  }

  return (
    <section className="manager__eod" >
      <h1>Discrepancies</h1>
      <section className="eod" >
        {sites.length > 1 ? (
          <select onChange={(e) => handleSite(e.target.value)}>
            <option>--Select Site--</option>
            {sites.map(function (d, i) {
              return (
                <option value={d.siteName}>{d.siteName}</option>
              )
            })}
          </select>
        ) : (
          <h2>Discrepancies for: <span style={{ color: "#2c53a0" }}>{siteName}</span></h2>
        )}
      </section>
      <section className="investigation__row">
        <List data={data} handleInvestigation={handleInvestigation} />
        <Investigation discrepancy={discrepancy} rel={rel} />
      </section>
    </section>
  );
}
