import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Alert } from "@mui/material";
import axios from "axios";

//Styling
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "550px",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
};

export default function Overtime({
    shiftId,
    user,
    config,
    handleClose,
    actualStart,
    actualEnd,
    hoursWorked,
    reload,
    afterEdit,
}) {
    const url = process.env.REACT_APP_API_URL;
    const [overtimeReasons, setOvertimeReasons] = useState([]);
    const [selectedReason, setSelectedReason] = useState(0);
    const [approved, setApproved] = useState("");
    const [approver, setApprover] = useState("");
    const [note, setNote] = useState("");

    //Alert
    const [alert, setAlert] = useState(false);
    const [severity, setSeverity] = useState("");
    const [message, setMessage] = useState("");
    const [disable, setDisable] = useState(false);

    useEffect(() => {
        axios
            .get(`${url}/rota/reasons/overtime`, config)
            .then((res) => {
                setOvertimeReasons(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const handleAlert = (message, severity) => {
        setDisable(true);
        setMessage(message);
        setSeverity(severity);
        setAlert(true);
        setTimeout(() => {
            setAlert(false);
            setMessage("");
            setSeverity("");
            setDisable(false);
        }, 4000);
    };

    const handleClearState = () => {
        setApprover("");
        setSelectedReason(0);
        setNote("");
    };

    const handleOTVal = () => {
        if (approved === "") {
            handleAlert(
                "Please select if the overtime is approved or not",
                "warning"
            );
        } else if (approved === "Yes" && approver === "") {
            handleAlert("Please select who approved the overtime", "warning");
        } else if (selectedReason === 0) {
            handleAlert("Please select the reason for the overtime", "warning");
        } else {
            handleOTSubmit();
        }
    };

    const handleOTSubmit = () => {
        setDisable(true);
        let data = {
            user: user,
            shiftId: shiftId,
            reasonId: selectedReason,
            actualStart: actualStart,
            actualEnd: actualEnd,
            approvedBy: approver,
            note: note,
            hoursWorked: hoursWorked,
        };

        axios
            .post(`${url}/rota/shift/overtime`, data, config)
            .then((res) => {
                handleAlert("Submitted successfully", "success");
                setTimeout(() => {
                    reload();
                    handleClose(false);
                }, 4000);
            })
            .catch((err) => {
                console.log(err);
                handleAlert("Something went wrong - Please try again", "error");
            });
    };
    return (
        <Box className="edit_shift_modal" sx={style}>
            {" "}
            <Typography id="modal-modal-title" variant="h4" component="h2">
                Overtime
            </Typography>
            <p>
                You are increasing the hours worked for this employee's shift
                resulting in overtime. Please complete the fields below and
                submit.
            </p>
            <section className="input_section">
                <label>Has this overtime been approved?</label>
                <select
                    onChange={(e) => {
                        setApproved(e.target.value);
                        handleClearState();
                    }}
                >
                    <option value="">Please select</option>
                    <option>No</option>
                    <option>Yes</option>
                </select>

                {approved === "Yes" ? (
                    <>
                        <label>Who approved the overtime?</label>
                        <input
                            type="text"
                            onChange={(e) => setApprover(e.target.value)}
                            placeholder="Enter name"
                        />
                    </>
                ) : null}
                {approved !== "" ? (
                    <>
                        <label>What is the reason for the overtime?</label>
                        <select
                            onChange={(e) => setSelectedReason(e.target.value)}
                            value={selectedReason}
                        >
                            <option value={0}>Please select</option>
                            {overtimeReasons.length > 0
                                ? overtimeReasons.map((reason, i) => {
                                      return (
                                          <option
                                              key={i}
                                              value={reason.reasonId}
                                          >
                                              {reason.name}
                                          </option>
                                      );
                                  })
                                : null}
                        </select>
                        <label>
                            Anything else to note? <span>(optional)</span>
                        </label>
                        <textarea
                            onChange={(e) => setNote(e.target.value)}
                            placeholder="Enter note"
                        />
                    </>
                ) : null}
            </section>
            <section className="alert_section">
                {alert ? <Alert severity={severity}>{message}</Alert> : null}
            </section>
            <section className="btn_section">
                <Button
                    variant="contained"
                    color="success"
                    onClick={handleOTVal}
                    disabled={disable}
                >
                    Submit
                </Button>
                {!afterEdit ? (
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => handleClose(false)}
                        disabled={disable}
                    >
                        Close
                    </Button>
                ) : null}
            </section>
        </Box>
    );
}
