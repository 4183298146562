// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.brandsContent {
    display: flex;
    flex-direction: column;
    margin: auto;
    min-height: 100vh;
}

.brandsContent img {
    width: 40%;
    margin: 3% auto;
}

.brandsContent button {
    width: 35%;
    margin: 1% auto;
}
/* .brandsContent button:active {
    box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #fff;
} */

.labelGen {
    width: 75%;
    margin: 2% auto;
}
.pickList {
    width: 55%;
    margin: 2% auto;
    background: linear-gradient(95deg, #2d53a1 0%, #65c5e1 100%);
}

@media only screen and (min-width: 300px) and (max-width: 749px) {
    .labelGen {
        width: 95%;
    }
    .pickList {
        width: 95%;
    }

}`, "",{"version":3,"sources":["webpack://./src/components/StoreZone/styles/Brands.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,UAAU;IACV,eAAe;AACnB;;AAEA;IACI,UAAU;IACV,eAAe;AACnB;AACA;;GAEG;;AAEH;IACI,UAAU;IACV,eAAe;AACnB;AACA;IACI,UAAU;IACV,eAAe;IACf,4DAA4D;AAChE;;AAEA;IACI;QACI,UAAU;IACd;IACA;QACI,UAAU;IACd;;AAEJ","sourcesContent":[".brandsContent {\n    display: flex;\n    flex-direction: column;\n    margin: auto;\n    min-height: 100vh;\n}\n\n.brandsContent img {\n    width: 40%;\n    margin: 3% auto;\n}\n\n.brandsContent button {\n    width: 35%;\n    margin: 1% auto;\n}\n/* .brandsContent button:active {\n    box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #fff;\n} */\n\n.labelGen {\n    width: 75%;\n    margin: 2% auto;\n}\n.pickList {\n    width: 55%;\n    margin: 2% auto;\n    background: linear-gradient(95deg, #2d53a1 0%, #65c5e1 100%);\n}\n\n@media only screen and (min-width: 300px) and (max-width: 749px) {\n    .labelGen {\n        width: 95%;\n    }\n    .pickList {\n        width: 95%;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
