import { Box } from "@mui/material";
import DiscrepanciesRep from "../DiscrepanciesRep"

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80vw",
    height:"90vh",
    bgcolor: "background.paper",
    borderRadius: "1rem",
    backgroundColor: "#fff",
    boxShadow: 24,
    p: 4,
    color: "#000",
    overflowY:"scroll"
  };

export default function EodModal(props) {
    
    return (
        <Box sx={style}>
            <DiscrepanciesRep investigationId={props.investigationId}/>
        </Box>
    )
}