import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import { useState, useEffect } from "react";
import axios from "axios"
import moment from "moment";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35vw",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
    "@media(max-width: 600px)": {
        width: "90vw",
        maxHeight: "70vh",
        overflowX: "scrollable",
    },
};

export default function InvestigationModal({ request, rel, closeModal }) {

    const [staffName, setStaffName] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");

    useEffect(() => {
        console.log(request)
    }, [])

    const submitInvestigation = async () => {
        document.getElementById("sub-btn").style.display = "none";
        if (staffName == "") {
            setMessage("Staff name cannot be empty!");
            setSeverity("warning");
            setShowAlert(true);

            setTimeout(() => {
                setShowAlert(false);
                setSeverity("");
                setMessage("");
                document.getElementById("sub-btn").style.display = "flex";
            }, 2500);
        } else {
            const user = localStorage.getItem("userDetails");
            const currentUser = JSON.parse(user).user.user;
            let data = {
                user: currentUser,
                requestId: request.requestId,
                amount: request.amount,
                siteName: request.site,
                incidentDate: moment(request.date).format("YYYY-MM-DD"),
                staff: staffName
            }
            await axios.post(`${process.env.REACT_APP_DEPPLOYED}/discrepancies/add/investigation/cctv`, data).then((resp) => {
                setMessage("Investigation has been successfully created!");
                setSeverity("success");
                setShowAlert(true);

                setTimeout(() => {
                    setShowAlert(false);
                    setSeverity("");
                    setMessage("");
                    document.getElementById("sub-btn").style.display = "flex";
                    closeModal();
                    rel();
                }, 2500);
            }).catch((err) => {
                setMessage("Something went wrong, please try again.");
                setSeverity("error");
                setShowAlert(true);

                setTimeout(() => {
                    setShowAlert(false);
                    setSeverity("");
                    setMessage("");
                    document.getElementById("sub-btn").style.display = "flex";
                }, 2500);
            })
        }
    }

    return (
        <Box sx={style} className="all_modals">
            <Typography id="modal-modal-title" variant="h6" component="h2" >
                <h4>Driveoff Internal Investigation</h4>
            </Typography>
            {!request.hasInvestigation ?
                <>
                    <p>Please enter name of the staff member you believe should be investigated?</p>
                    <section style={{ display: "flex", justifyContent: "space-evenly" }}>
                        <input placeholder="Staff Name..." type="text" onChange={(e) => setStaffName(e.target.value)} />
                        <Button id="sub-btn" variant="contained" color="success" onClick={submitInvestigation}>Submit</Button>
                    </section>
                    {showAlert ? (
                        <Alert severity={severity}>{message}</Alert>
                    ) : (
                        <></>
                    )}
                </>
                :
                <p>An Investigation Has Already Been Created For This Driveoff</p>
            }
        </Box>
    )
}