import { useState, useEffect } from "react";
import ManagerNav from "../../managerZone/ManagerNav";
import "./wastagecollection.scss";
import axios from "axios";
import { getSites } from "../../utils/Api";
import WastageIndv from "./WastageIndv";
import { Button } from "@mui/material";
import { Alert } from "@mui/material";
import WasteAlert from "./modals/WasteAlert";
import Modal from "@mui/material/Modal";

export default function WastageCollection({ id }) {
    const url = "https://nitropos-backend.nitrosolutions.co.uk";
    const [collection, setCollection] = useState([]);
    const [siteName, setSiteName] = useState("");
    const [updatedCollection, setUpdatedCollection] = useState([]);
    const [alert, setAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");
    const [reload, setReload] = useState(false);
    const [openWasteModal, setOpenWasteModal] = useState(false);

    useEffect(async () => {
        const sites = await getSites();
        let newCollection = [];
        for (let i = 0; i < sites.length; i++) {
            if (sites[i].epos == id) {
                setSiteName(sites[i].name);
            }
        }

        axios
            .get(`${url}/claims/collection/awaiting/${id}`)
            .then((res) => {
                let wastageCollection = res.data;
                let collectionObj = {};
                setCollection(wastageCollection);
                for (let i = 0; i < wastageCollection.length; i++) {
                    collectionObj = {
                        name: wastageCollection[i].name,
                        eposId: wastageCollection[i].eposId,
                        expected: wastageCollection[i].quantity,
                        actual: wastageCollection[i].quantity,
                        cpIds: wastageCollection[i].cpIds,
                    };
                    newCollection.push(collectionObj);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        setUpdatedCollection(newCollection);
    }, [reload]);

    const rel = () => {
        setReload(!reload);
    };

    const handleQuantityCollected = (id, quantity) => {
        let newCollection = [...updatedCollection];
        for (let i = 0; i < updatedCollection.length; i++) {
            if (id == updatedCollection[i].eposId) {
                newCollection[i].actual = quantity;
            }
        }
        setUpdatedCollection(newCollection);
    };

    const handleSubmit = () => {
        setOpenWasteModal(false);
        let checkDone = false;
        let validationError = false;
        let errorProduct = {};

        for (let i = 0; i < updatedCollection.length; i++) {
            if (
                updatedCollection[i].actual.includes("-") ||
                updatedCollection[i].actual.includes(".") ||
                updatedCollection[i].actual.includes("e") ||
                updatedCollection[i].actual === ""
            ) {
                validationError = true;
                errorProduct = updatedCollection[i].name;
            }

            if (
                updatedCollection[i].actual !== updatedCollection[i].expected &&
                !checkDone &&
                !openWasteModal
            ) {
                checkDone = true;
            }
        }

        let data = {
            collection: updatedCollection,
        };

        if (validationError) {
            setMessage(
                `All actual fields must be completed. Error at product: ${errorProduct}`
            );
            setSeverity("warning");
            setAlert(true);
            setTimeout(() => {
                setAlert(false);
                setMessage("");
                setSeverity("");
            }, 4000);
        } else if (checkDone) {
            setOpenWasteModal(true);
        } else {
            axios
                .post(`${url}/claims/collection/submit`, data)
                .then((res) => {
                    setMessage("Wastage collection successful");
                    setSeverity("success");
                    setAlert(true);
                    setTimeout(() => {
                        rel();
                    }, 4000);
                })
                .catch((err) => {
                    console.log(err);
                    setMessage("Something went wrong - Please try again");
                    setSeverity("error");
                    setAlert(true);
                    setTimeout(() => {
                        setAlert(false);
                        setMessage("");
                        setSeverity("");
                    }, 4000);
                });
        }
    };
    return (
        <main className="wastage_collection">
            <ManagerNav />
            <h1>Wastage collection for {siteName}</h1>
            {collection.length > 0 ? (
                <>
                    <h3>
                        If not accurate, please amend the quantity collected
                        from the store accordingly. Submit once all quanities
                        have been checked.
                    </h3>
                    <br />
                    <table className="collection_table">
                        <thead>
                            <tr>
                                <th>Product</th>
                                <th>Barcode</th>
                                <th className="table_right_border">
                                    Expected Quantity
                                </th>
                                <th className="table_right_border">
                                    Actual Quantity
                                </th>
                                <th>Return to Supplier</th>
                            </tr>
                        </thead>
                        <tbody>
                            {collection.map((row, i) => {
                                return (
                                    <WastageIndv
                                        key={i}
                                        row={row}
                                        handleQuantityCollected={
                                            handleQuantityCollected
                                        }
                                    />
                                );
                            })}
                        </tbody>
                    </table>
                    {!alert ? (
                        <Button
                            id="submit_btn"
                            variant="contained"
                            color="success"
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    ) : (
                        <Alert id="alert" variant="filled" severity={severity}>
                            {message}
                        </Alert>
                    )}
                </>
            ) : (
                <h3>No Wastage due for collection</h3>
            )}
            <Modal open={openWasteModal}>
                <WasteAlert
                    setOpenWasteModal={setOpenWasteModal}
                    handleSubmit={handleSubmit}
                    alert={alert}
                    severity={severity}
                    message={message}
                />
            </Modal>
        </main>
    );
}
