import { useState, useEffect } from "react";
import DescriptionModal from "./modals/DescriptionModal";
import NotesModal from "./modals/NotesModal";
import StatusModal from "./modals/StatusModal";
import StorageModal from "./modals/StorageModal";
import Modal from "@mui/material/Modal";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import SdStorageIcon from "@mui/icons-material/SdStorage";
import axios from "axios";
import Tooltip from "@mui/material/Tooltip";
import QRCode from "qrcode";
import QRPrint from "./modals/QRPrint";
import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

const CCTVIndividual = ({ request, statusList, rel }) => {
    const url = process.env.REACT_APP_ANPR_URL;
    const [notes, setNotes] = useState([]);
    const [openDescription, setOpenDescription] = useState(false);
    const [openNotes, setOpenNotes] = useState(false);
    const [openStatus, setOpenStatus] = useState(false);
    const [openStorage, setOpenStorage] = useState(false);
    const [requestId, setRequestId] = useState("");
    const [qrCode, setQrCode] = useState("");
    const [openQR, setOpenQR] = useState(false);
    const [currentStatus, setCurrentStatus] = useState("");
    const [currentStatusId, setCurrentStatusId] = useState(null);

    useEffect(() => {
        for (let i = 0; i < statusList.length; i++) {
            if (statusList[i].statusId === request.statusId) {
                setCurrentStatus(statusList[i].name);
                setCurrentStatusId(statusList[i].statusId);
            }
        }
        //Create QR code
        QRCode.toDataURL(String(request.requestId))
            .then((url) => {
                setQrCode(url);
            })
            .catch((err) => {
                console.error(err);
            });
    }, [rel]);

    const handleOpenNotes = (notes, id) => {
        const user = localStorage.getItem("userDetails");
        const currentUser = JSON.parse(user).user.user;
        if (notes) {
            setNotes(notes);
            setRequestId(id);
        }
        setRequestId(id);
        setOpenNotes(true);
        let data = {
            requestId: id,
            user: currentUser,
        };
        axios
            .post(`${url}/cctv-system/add-log`, data)
            .then((res) => {})
            .catch((err) => {
                console.log(err);
            });
    };
    const handleCloseNotes = () => {
        setOpenNotes(false);
        setNotes([]);
    };

    return (
        <>
            <tr>
                <td>{request.staffName}</td>
                <td>
                    {new Date(request.date).toLocaleDateString("en-GB")}-
                    {request.time}
                </td>
                <td>{request.site}</td>
                <td>{request.reason}</td>
                <td className="status-cell">
                    {currentStatus}
                    <Button onClick={() => setOpenStatus(true)}>
                        <EditIcon />
                    </Button>
                </td>
                <td>
                    {" "}
                    {!request.storageLocation ? (
                        <SdStorageIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                setOpenStorage(true);
                            }}
                        />
                    ) : (
                        <Tooltip title="Click to copy">
                            <SdStorageIcon
                                style={{ cursor: "pointer" }}
                                color="success"
                                onClick={() => {
                                    navigator.clipboard.writeText(
                                        request.storageLocation
                                    );
                                }}
                            />
                        </Tooltip>
                    )}
                </td>
                <td>
                    <SpeakerNotesIcon
                        style={{ cursor: "pointer" }}
                        color="primary"
                        onClick={() => {
                            handleOpenNotes(request.notes, request.requestId);
                        }}
                    />
                </td>
                <td>
                    <img
                        onClick={() => setOpenQR(true)}
                        src={qrCode}
                        style={{ cursor: "pointer" }}
                        width="60"
                    />
                </td>
            </tr>
            <Modal open={openQR}>
                <QRPrint
                    qrCode={qrCode}
                    setOpenQR={setOpenQR}
                    request={request}
                />
            </Modal>
            <Modal open={openDescription}>
                <DescriptionModal
                    person={request.person}
                    incident={request.description}
                    images={request.images}
                    items={request.items}
                    handleCloseDescription={setOpenDescription}
                />
            </Modal>
            <Modal open={openNotes}>
                <NotesModal
                    person={request.person}
                    incident={request.description}
                    images={request.images}
                    items={request.items}
                    allNotes={notes}
                    rel={rel}
                    handleCloseNotes={handleCloseNotes}
                    id={requestId}
                    isDev={true}
                    time={request.time}
                    date={request.date}
                    site={request.site}
                    requestType={request.requestType}
                />
            </Modal>
            <Modal open={openStatus}>
                <StatusModal
                    statusList={statusList}
                    currentStatus={currentStatus}
                    reqId={request.requestId}
                    rel={rel}
                    handleCloseStatus={setOpenStatus}
                    isDev={true}
                    currentStatusId={currentStatusId}
                    siteName={request.site}
                    siteId={request.siteId}
                />
            </Modal>
            <Modal open={openStorage}>
                <StorageModal
                    handleCloseStorage={setOpenStorage}
                    request={request}
                    rel={rel}
                />
            </Modal>
        </>
    );
};

export default CCTVIndividual;
