import { useState, useEffect } from "react";
import axios from "axios";
import NMOPIndiviudal from "./NMOPIndividual";

export default function NMOPRequests({ statusList }) {
    const url = process.env.REACT_APP_ANPR_URL;
    const [requests, setRequests] = useState();
    const [reload, setReload] = useState(false);
    const [sorted, setSorted] = useState(false);

    useEffect(() => {
        axios
            .get(`${url}/cctv-system/submissions/details/allActive?nmop=true`)
            .then((res) => {
                setRequests(res.data.cctv);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [reload]);

    const rel = () => {
        setReload(!reload);
    };

    //Filters
    const sortSite = () => {
        setSorted(!sorted);
        let siteData = requests.sort((a, b) =>
            a.siteName.localeCompare(b.siteName)
        );
        setRequests(siteData);
    };

    const sortDate = () => {
        setSorted(!sorted);
        let dateData = requests.sort(
            (a, b) => new Date(a.date) - new Date(b.date)
        );
        setRequests(dateData);
    };

    const sortStatus = () => {
        setSorted(!sorted);
        let statusData = requests.sort((a, b) => {
            return a.statusId - b.statusId;
        });
        setRequests(statusData);
    };

    return (
        <main className="cctv__requests">
            <h3>Open NMOP requests</h3>
            <table className="cctv__table">
                <thead>
                    <tr>
                        <th className="filter_heading" onclick={sortSite}>
                            Site
                        </th>
                        <th className="filter_heading" onClick={sortStatus}>
                            Date
                        </th>
                        <th>Pump</th>
                        <th>Registration</th>
                        <th>Status</th>
                        <th>See Contract</th>
                        <th>Phone Number</th>
                        <th>Time to Drive Off</th>
                        <th>Storage</th>
                        <th>Photos</th>
                        <th>Convert to Drive Off</th>
                        <th>Notes</th>
                    </tr>
                </thead>
                <tbody>
                    {requests && requests.length > 0 ? (
                        requests.map((request, i) => {
                            let currentStatus = "";
                            for (let i = 0; i < statusList.length; i++) {
                                if (
                                    statusList[i].statusId === request.statusId
                                ) {
                                    currentStatus = statusList[i].name;
                                }
                            }
                            return (
                                <NMOPIndiviudal
                                    request={request}
                                    currentStatus={currentStatus}
                                    statusList={statusList}
                                    rel={rel}
                                    key={i}
                                />
                            );
                        })
                    ) : (
                        <tr>
                            <th colSpan={11}>No open requests</th>
                        </tr>
                    )}
                </tbody>
            </table>
        </main>
    );
}
