import "./autoOrder.scss";
import { useState, useEffect } from "react";
import WareNav from "../WareNav";
import OrderIndv from "./OrderIndv";
import { Alert } from "@mui/material";
import { Button } from "@mui/material";
import axios from "axios";

export default function OrderCheck({ user, id }) {
    //const url = "http://10.0.0.174:4022";
    const url = "https://nitropos-backend.nitrosolutions.co.uk"
    const [siteName, setSiteName] = useState("");
    const [type, setType] = useState("")
    const [products, setProducts] = useState([]);
    const [alert, setAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");
    const [reload, setReload] = useState(false);

    const [disableButton, setDisableButton] = useState(false);

    useEffect(async () => {
        axios
            .get(`${url}/edn/v4u/GetById/${id}`)
            .then((res) => {
                console.log(res.data);
                setSiteName(res.data.site);
                setProducts(res.data.products);
                setType(res.data.type)
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const rel = () => {
        setReload(!reload);
    };

    const handleConfirmOrderQuantity = (id, quantity) => {
        for (let i = 0; i < products.length; i++) {
            if (id == products[i].eposId) {
                products[i].ordered = Number(quantity);
            }
        }
    };

    const handleSubmit = async () => {
        document.getElementById("submit_btn").style.display = "none";
        setDisableButton(true);
        let validationError = false;
        let errorProduct = "";
        for (let i = 0; i < products.length; i++) {
            let ordered = String(products[i].ordered);
            if (
                ordered.includes("-") ||
                ordered.includes(".") ||
                ordered.includes("e") ||
                ordered === ""
            ) {
                validationError = true;
                errorProduct = products[i].name;
            }
        }

        let data = {
            id: id,
            products: products,
            customerNumber: type
        };
        console.log(errorProduct);
        console.log(validationError);
        console.log(data);

        if (validationError) {
            setMessage(
                `All confirm fields must be completed. Error at product: ${errorProduct}`
            );
            setSeverity("warning");
            setAlert(true);
            setTimeout(() => {
                setAlert(false);
                setMessage("");
                setSeverity("");
                document.getElementById("submit_btn").style.display = "flex";
                setDisableButton(false);
            }, 4000);
        } else {
            await axios
                .post(`${url}/edn/v4u/order/reviewed`, data)
                .then((res) => {
                    console.log(res.data);
                    setMessage("Order submission successful");
                    setSeverity("success");
                    setAlert(true);
                    setTimeout(() => {
                        setAlert(false);
                        setMessage("");
                        setSeverity("");
                        window.location.href = "/"
                        setDisableButton(false);
                        rel();
                    }, 4000);
                })
                .catch((err) => {
                    console.log(err);
                    setMessage("Something went wrong - Please try again");
                    setSeverity("error");
                    setAlert(true);
                    setTimeout(() => {
                        setAlert(false);
                        setMessage("");
                        setSeverity("");
                        setDisableButton(false);
                        document.getElementById("submit_btn").style.display = "flex";
                        rel();
                    }, 4000);
                });
        }
    };

    return (
        <main className="auto_order">
            <WareNav />
            <h1>Auto ordering system for order id: {id}</h1>
            {products.length > 0 ? (
                <>
                    <h3>
                        If you wish to amend the system order amount please
                        amend the confirm quanity from the store accordingly.
                        Submit once all quanities have been checked.
                    </h3>
                    <br />
                    <table className="order_table">
                        <thead>
                            <tr className="table_header">
                                <th>Product</th>
                                <th>Current</th>
                                <th>7-Day Sales</th>
                                <th>Warehouse</th>
                                <th className="table_right_border">
                                    System Order
                                </th>
                                <th>Confirm Order</th>
                            </tr>
                        </thead>
                        <tbody>
                            {products.map((row, i) => {
                                return (
                                    <>
                                        {products[i + 1] ?
                                            <>
                                                {products[i + 1].catId !== products[i].catId ?
                                                    <tr style={{ backgroundColor: "#2c53a0", color: "white" }}>
                                                        <td><br /><br /></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    :
                                                    <></>
                                                }
                                            </>
                                            :
                                            <></>
                                        }
                                        <OrderIndv
                                            key={i}
                                            row={row}
                                            type={type}
                                            handleConfirmOrderQuantity={
                                                handleConfirmOrderQuantity
                                            }
                                        />
                                    </>
                                );
                            })}
                        </tbody>
                    </table>
                    {!alert ? (
                        <Button
                            id="submit_btn"
                            variant="contained"
                            color="success"
                            onClick={handleSubmit}
                            disabled={disableButton}
                        >
                            Submit
                        </Button>
                    ) : (
                        <Alert id="alert" variant="filled" severity={severity}>
                            {message}
                        </Alert>
                    )}
                </>
            ) : (
                <h3>No Order</h3>
            )}
        </main>
    );
}
