import { useEffect, useState } from "react";
import axios from "axios";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import IndvStockProduct from "./IndvStockProduct";
import { Alert, Modal, Box, Typography } from "@mui/material";
import ZoneCheckModal from "../modals/ZoneCheckModal";
import FinalCheckModal from "../modals/FinalCheckModal";

export default function StockCount(props) {
  const site = props.site;
  // const url = "http://10.0.0.34:4022";
  const reload = props.reload;
  const url = "https://nitropos-backend.nitrosolutions.co.uk";
  const [freezerOpen, setFreezerOpen] = useState(false);
  const [fridgeOpen, setFridgeOpen] = useState(false);
  const [shopOpen, setShopOpen] = useState(false);
  const [dryOpen, setDryOpen] = useState(false);
  const [fridgeProdOpen, setFridgeProdOpen] = useState(false);
  const [freezerProdOpen, setFreezerProdOpen] = useState(false);
  const [shopProdOpen, setShopProdOpen] = useState(false);
  const [dryProdOpen, setDryProdOpen] = useState(false);
  const handleFreezerOpen = () => setFreezerOpen(!freezerOpen);
  const handleFridgeOpen = () => setFridgeOpen(!fridgeOpen);
  const handleShopOpen = () => setShopOpen(!shopOpen);
  const handleDryOpen = () => setDryOpen(!dryOpen);
  const handleFridgeProdOpen = () => setFridgeProdOpen(!fridgeProdOpen);
  const handleFreezerProdOpen = () => setFreezerProdOpen(!freezerProdOpen);
  const handleShopProdOpen = () => setShopProdOpen(!shopProdOpen);
  const handleDryProdOpen = () => setDryProdOpen(!dryProdOpen);
  const [zone, setZone] = useState("");
  // const [reload, setReload] = useState(false);

  //Alerts
  const [showFridgeAlert, setShowFridgeAlert] = useState(false);
  const [showFreezerAlert, setShowFreezerAlert] = useState(false);
  const [showShopAlert, setShowShopAlert] = useState(false);
  const [showDryAlert, setShowDryAlert] = useState(false);
  const [showFinalStockAlert, setShowFinalStockAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  //hoagies products
  const [products, setProducts] = useState([]);
  const [newProductZone, setNewProductZone] = useState([]);

  //arrays
  const [fridgeItems, setFridgeItems] = useState([]);
  const [freezerItems, setFreezerItems] = useState([]);
  const [shopItems, setShopItems] = useState([]);
  const [dryItems, setDryItems] = useState([]);

  //Modals
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openZoneCheck, setOpenZoneCheck] = useState(false);
  const handleOpenZoneCheck = () => {
    setOpenZoneCheck(true);
  };
  const handleCloseZoneCheck = () => setOpenZoneCheck(false);
  const [isDoubleChecked, setIsDoubleChecked] = useState(false);

  useEffect(() => {
    //checks the saved progress on first load

    //gets all hoagies products
    axios
      .get(`${url}/stock/Hoagies/GetStockedItems/${site}`)
      .then(async function (res) {
        setProducts(res.data);
        setFridgeItems([]);
        setFreezerItems([]);
        setShopItems([]);
        setDryItems([]);
        let localFridge = [];
        let localFreezer = [];
        let localShop = [];
        let localDry = [];

        for (let i = 0; i < res.data.length; i++) {
          if (
            res.data[i].tempClosingCountObj != null &&
            res.data[i].isStockedInStore
          ) {
            if (res.data[i].tempClosingCountObj.hasOwnProperty("fridge")) {
              localFridge.push({
                name: res.data[i].name,
                productCode: res.data[i].eposId,
                barcode: res.data[i].barcode,
                isStockedInStore: res.data[i].isStockedInStore,
                quantity: res.data[i].tempClosingCountObj.fridge,
              });
            }
            if (res.data[i].tempClosingCountObj.hasOwnProperty("freezer")) {
              localFreezer.push({
                name: res.data[i].name,
                productCode: res.data[i].eposId,
                barcode: res.data[i].barcode,
                isStockedInStore: res.data[i].isStockedInStore,
                quantity: res.data[i].tempClosingCountObj.freezer,
              });
            }
            if (res.data[i].tempClosingCountObj.hasOwnProperty("shop")) {
              localShop.push({
                name: res.data[i].name,
                productCode: res.data[i].eposId,
                barcode: res.data[i].barcode,
                isStockedInStore: res.data[i].isStockedInStore,
                quantity: res.data[i].tempClosingCountObj.shop,
              });
            }
            if (res.data[i].tempClosingCountObj.hasOwnProperty("dry")) {
              localDry.push({
                name: res.data[i].name,
                productCode: res.data[i].eposId,
                barcode: res.data[i].barcode,
                isStockedInStore: res.data[i].isStockedInStore,
                quantity: res.data[i].tempClosingCountObj.dry,
              });
            }
          }
        }
        setFridgeItems(localFridge);
        setFreezerItems(localFreezer);
        setShopItems(localShop);
        setDryItems(localDry);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props]);

  const finaliseChecks = () => {
    let quantityCheck = 0;
    console.log(fridgeItems)
    console.log(freezerItems)

    for (let i = 0; i < fridgeItems.length; i++) {
      if (fridgeItems[i].quantity === "") {
        quantityCheck++;
      }
    }
    for (let i = 0; i < freezerItems.length; i++) {
      if (freezerItems[i].quantity === "") {
        quantityCheck++;
      }
    }
    for (let i = 0; i < shopItems.length; i++) {
      if (shopItems[i].quantity === "") {
        quantityCheck++;
      }
    }
    for (let i = 0; i < dryItems.length; i++) {
      if (dryItems[i].quantity === "") {
        quantityCheck++;
      }
    }
    if (quantityCheck > 0) {
      document.getElementById("final_stock_btn").style.display = "none";
      setMessage(
        "Please ensure all stocked items have a quantity and try again"
      );
      setSeverity("warning");
      setShowFinalStockAlert(true);
      setTimeout(() => {
        setShowFinalStockAlert(false);
        setSeverity("");
        setMessage("");
        document.getElementById("final_stock_btn").style.display = "flex";
      }, 3000);
    } else if (
      fridgeItems.length === 0 ||
      freezerItems.length === 0 ||
      shopItems.length === 0 ||
      dryItems.length === 0
    ) {
      handleOpen();
    } else {
      finaliseStock();
    }
  };

  //Save final stock take
  const finaliseStock = () => {
    handleClose();
    let data = {
      siteId: site,
      fridgeStock: fridgeItems,
      freezerStock: freezerItems,
      shopFloorStock: shopItems,
      dryStock: dryItems,
    };
    axios
      .post(`${url}/stock/Hoagies/FinalizeStorage`, data)
      .then((res) => {
        document.getElementById("final_stock_btn").style.display = "none";
        setMessage("Successful stock submission");
        setSeverity("success");
        setShowFinalStockAlert(true);
        setTimeout(() => {
          setShowFinalStockAlert(false);
          setSeverity("");
          setMessage("");
          setFreezerOpen(false);
          setFridgeOpen(false);
          setDryOpen(false);
          setShopOpen(false);
          document.getElementById("final_stock_btn").style.display = "flex";
        }, 3000);
      })
      .catch((err) => {
        document.getElementById("final_stock_btn").style.display = "none";
        setMessage("Unsuccessful. Error code:" + err);
        setSeverity("error");
        setShowFinalStockAlert(true);
        setTimeout(() => {
          setShowFinalStockAlert(false);
          setSeverity("");
          setMessage("");
          document.getElementById("final_stock_btn").style.display = "flex";
        }, 3000);
      });
  };
  //saves progress in one zone
  const save = (zone) => {
    let array = [];
    let setArray, alert;
    let noQuantity = false;
    if (zone === "fridge") {
      array = fridgeItems;
      setArray = setFridgeItems;
      alert = setShowFridgeAlert;
    } else if (zone === "freezer") {
      array = freezerItems;
      setArray = setFreezerItems;
      alert = setShowFreezerAlert;
    } else if (zone === "shop") {
      array = shopItems;
      setArray = setShopItems;
      alert = setShowShopAlert;
    } else if (zone === "dry") {
      array = dryItems;
      setArray = setDryItems;
      alert = setShowDryAlert;
    }
    for (let i = 0; i < array.length; i++) {
      if (array[i].quantity == "") {
        noQuantity = true;
      }
    }

    if (array.length === 0 && !isDoubleChecked) {
      setZone(zone);
      handleOpenZoneCheck();
    } else if (noQuantity) {
      document.getElementById(`${zone}_save_btn`).style.display = "none";
      setMessage("Please ensure product quantity fields are completed");
      setSeverity("warning");
      alert(true);
      setTimeout(() => {
        alert(false);
        setSeverity("");
        setMessage("");
        document.getElementById(`${zone}_save_btn`).style.display = "flex";
      }, 3000);
    } else {
      setIsDoubleChecked(false);
      let data = { siteId: site, zone: zone, products: array };
      document.getElementById(`${zone}_save_btn`).style.display = "none";

      axios
        .post(`${url}/stock/Hoagies/SaveStorage`, data)
        .then((res) => {
          setMessage("Successfully saved");
          setSeverity("success");
          alert(true);
          setTimeout(() => {
            alert(false);
            setSeverity("");
            setMessage("");
            document.getElementById(`${zone}_save_btn`).style.display = "flex";
          }, 3000);
        })
        .catch((err) => {
          console.log(err);
          setMessage("Unsuccessful. " + err);
          setSeverity("error");
          alert(true);
          setTimeout(() => {
            alert(false);
            setSeverity("");
            setMessage("");
            document.getElementById(`${zone}_save_btn`).style.display = "flex";
          }, 3000);
        });
    }
  };

  //Get product quantity
  async function getProductQuantity(quantity, product, zone) {
    if (zone === "fridge") {
      for (let i in fridgeItems) {
        if (fridgeItems[i].barcode === product) {
          fridgeItems[i].quantity = quantity;
        }
      }
    } else if (zone === "freezer") {
      for (let i in freezerItems) {
        if (freezerItems[i].barcode === product) {
          freezerItems[i].quantity = quantity;
        }
      }
    } else if (zone === "shop") {
      for (let i in shopItems) {
        if (shopItems[i].barcode === product) {
          shopItems[i].quantity = quantity;
        }
      }
    } else if (zone === "dry") {
      for (let i in dryItems) {
        if (dryItems[i].barcode === product) {
          dryItems[i].quantity = quantity;
        }
      }
    }
  }

  //Clear inputs
  const clearInputs = () => {
    const search_inputs = document.getElementsByClassName("search_input");
    for (let i = 0; i < search_inputs.length; i++) {
      let item = search_inputs[i];
      item.value = "";
    }
  };

  //search of product on the datalist
  async function searchProduct(
    item,
    array,
    setArray,
    setAlert,
    zone,
    quantity
  ) {
    console.log(item)
    let duplicateProduct = false;
    if (item.length > 3) {
      products.forEach((product) => {
        for (let i = 0; i < array.length; i++) {
          if (array[i].barcode === item  ) {
            duplicateProduct = true;
          }
        }
        if (duplicateProduct) {
          document.getElementById(`${zone}_save_btn`).style.display =
            "none";
          setMessage("This product has already been added");
          setSeverity("warning");
          setAlert(true);
          clearInputs();
          setTimeout(() => {
            setAlert(false);
            setSeverity("");
            setMessage("");
            document.getElementById(`${zone}_save_btn`).style.display =
              "flex";
          }, 3000);
        } else if ((product.barcode === item) || (product.name.toLowerCase() === item.toLowerCase())) {
          if (product.isStockedInStore && product.barcode) {
            setArray([
              ...array,
              {
                name: product.name,
                productCode: product.eposId,
                barcode: product.barcode,
                isStockedInStore: product.isStockedInStore,
                quantity: String(quantity),
              },
            ]);
            clearInputs();
          } else {
            document.getElementById(`${zone}_save_btn`).style.display = "none";
            setMessage("This product is not stocked in store.");
            setSeverity("warning");
            setAlert(true);
            clearInputs();
            setTimeout(() => {
              setAlert(false);
              setSeverity("");
              setMessage("");
              document.getElementById(`${zone}_save_btn`).style.display =
                "flex";
            }, 3000);
          }
        }
      });
    }
  }

  const deleteFromArray = (zone, productId) => {
    let array, filteredArray;
    if (zone === "fridge") {
      array = fridgeItems;
      filteredArray = array.filter((item) => {
        return item.productCode !== productId;
      });
      setFridgeItems(filteredArray);
    } else if (zone === "freezer") {
      array = freezerItems;
      filteredArray = array.filter((item) => {
        return item.productCode !== productId;
      });
      setFreezerItems(filteredArray);
    } else if (zone === "shop") {
      array = shopItems;
      filteredArray = array.filter((item) => {
        return item.productCode !== productId;
      });
      setShopItems(filteredArray);
    } else if (zone === "dry") {
      array = dryItems;
      filteredArray = array.filter((item) => {
        return item.productCode !== productId;
      });
      setDryItems(filteredArray);
    }
  };

  return (
    <section className="stock_tab_main">
      <div className="area_card">
        <h2>Fridge</h2>
        {!fridgeOpen ? (
          <KeyboardArrowDownIcon
            onClick={handleFridgeOpen}
            className="card_icon"
            color="primary"
            fontSize="large"
          />
        ) : (
          <>
            <KeyboardArrowUpIcon
              onClick={handleFridgeOpen}
              className="card_icon"
              color="primary"
              fontSize="large"
            />
            {fridgeItems && fridgeItems.length > 0 ? (
              fridgeItems.map(function (item, index) {
                return (
                  <IndvStockProduct
                    product={item}
                    index={index}
                    zone="fridge"
                    deleteFromArray={deleteFromArray}
                    getProductQuantity={getProductQuantity}
                    site={site}
                  />
                );
              })
            ) : (
              <h4>No Products in this area</h4>
            )}
            <div className="area_card_btn_row">
              <Button
                variant="outlined"
                id="fridge_save_btn"
                color="warning"
                startIcon={<SaveIcon />}
                onClick={() => {
                  save("fridge");
                }}
              >
                Save
              </Button>
              {showFridgeAlert ? (
                <Alert severity={severity} sx={{ width: "fit-content" }}>
                  {message}
                </Alert>
              ) : null}
              <Button
                variant="outlined"
                id="fridge_prod_btn"
                color="primary"
                startIcon={<AddCircleIcon />}
                onClick={handleFridgeProdOpen}
              >
                Add product to area
              </Button>
            </div>
            {fridgeProdOpen ? (
              <div className="area_card_add">
                <input
                  list="products"
                  onChange={(e) =>
                    searchProduct(
                      e.target.value,
                      fridgeItems,
                      setFridgeItems,
                      setShowFridgeAlert,
                      "fridge",
                      0
                    )
                  }
                  placeholder="Select Product"
                  className="search_input"
                />
                <datalist id="products">
                  {products.map(function (product, idx) {
                    return (
                      <option value={product.barcode}>{product.name}</option>
                    );
                  })}
                </datalist>
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
      <div className="area_card">
        <h2>Freezer</h2>
        {!freezerOpen ? (
          <KeyboardArrowDownIcon
            onClick={handleFreezerOpen}
            className="card_icon"
            color="primary"
            fontSize="large"
          />
        ) : (
          <>
            <KeyboardArrowUpIcon
              onClick={handleFreezerOpen}
              className="card_icon"
              color="primary"
              fontSize="large"
            />

            {freezerItems && freezerItems.length > 0 ? (
              freezerItems.map(function (item, index) {
                return (
                  <IndvStockProduct
                    product={item}
                    index={index}
                    zone="freezer"
                    deleteFromArray={deleteFromArray}
                    getProductQuantity={getProductQuantity}
                    site={site}
                  />
                );
              })
            ) : (
              <h4>No Products in this area</h4>
            )}
            <div className="area_card_btn_row">
              <Button
                variant="outlined"
                id="freezer_save_btn"
                color="warning"
                startIcon={<SaveIcon />}
                onClick={() => {
                  save("freezer");
                }}
              >
                Save
              </Button>
              {showFreezerAlert ? (
                <Alert severity={severity} sx={{ width: "fit-content" }}>
                  {message}
                </Alert>
              ) : null}
              <Button
                variant="outlined"
                id="freezer_prod_btn"
                color="primary"
                startIcon={<AddCircleIcon />}
                onClick={handleFreezerProdOpen}
              >
                Add product to area
              </Button>
            </div>
            {freezerProdOpen ? (
              <div className="area_card_add">
                <input
                  list="products"
                  onChange={(e) =>
                    searchProduct(
                      e.target.value,
                      freezerItems,
                      setFreezerItems,
                      setShowFreezerAlert,
                      "freezer",
                      0
                    )
                  }
                  placeholder="Select Product"
                  className="search_input"
                />
                <datalist id="products">
                  {products.map(function (product, idx) {
                    return (
                      <option >{product.name}</option>
                    );
                  })}
                </datalist>
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
      <div className="area_card">
        <h2>Shop Floor</h2>
        {!shopOpen ? (
          <KeyboardArrowDownIcon
            onClick={handleShopOpen}
            className="card_icon"
            color="primary"
            fontSize="large"
          />
        ) : (
          <>
            <KeyboardArrowUpIcon
              onClick={handleShopOpen}
              className="card_icon"
              color="primary"
              fontSize="large"
            />
            {shopItems && shopItems.length > 0 ? (
              shopItems.map(function (item, index) {
                return (
                  <IndvStockProduct
                    product={item}
                    index={index}
                    zone="shop"
                    deleteFromArray={deleteFromArray}
                    getProductQuantity={getProductQuantity}
                    site={site}
                  />
                );
              })
            ) : (
              <h4>No Products in this area</h4>
            )}
            <div className="area_card_btn_row">
              <Button
                variant="outlined"
                id="shop_save_btn"
                color="warning"
                startIcon={<SaveIcon />}
                onClick={() => {
                  save("shop");
                }}
              >
                Save
              </Button>
              {showShopAlert ? (
                <Alert severity={severity} sx={{ width: "fit-content" }}>
                  {message}
                </Alert>
              ) : null}
              <Button
                variant="outlined"
                id="shop_prod_btn"
                color="primary"
                startIcon={<AddCircleIcon />}
                onClick={handleShopProdOpen}
              >
                Add product to area
              </Button>
            </div>
            {shopProdOpen ? (
              <div className="area_card_add">
                <input
                  list="products"
                  onChange={(e) =>
                    searchProduct(
                      e.target.value,
                      shopItems,
                      setShopItems,
                      setShowShopAlert,
                      "shop",
                      0
                    )
                  }
                  placeholder="Select Product"
                  className="search_input"
                />
                <datalist id="products">
                  {products.map(function (product, idx) {
                    return (
                      <option value={product.barcode}>{product.name}</option>
                    );
                  })}
                </datalist>
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
      <div className="area_card">
        <h2>Dry Storage</h2>
        {!dryOpen ? (
          <KeyboardArrowDownIcon
            onClick={handleDryOpen}
            className="card_icon"
            color="primary"
            fontSize="large"
          />
        ) : (
          <>
            <KeyboardArrowUpIcon
              onClick={handleDryOpen}
              className="card_icon"
              color="primary"
              fontSize="large"
            />
            {dryItems && dryItems.length > 0 ? (
              dryItems.map(function (item, index) {
                return (
                  <IndvStockProduct
                    product={item}
                    index={index}
                    zone="dry"
                    deleteFromArray={deleteFromArray}
                    getProductQuantity={getProductQuantity}
                    site={site}
                  />
                );
              })
            ) : (
              <h4>No Products in this area</h4>
            )}
            <div className="area_card_btn_row">
              <Button
                variant="outlined"
                id="dry_save_btn"
                color="warning"
                startIcon={<SaveIcon />}
                onClick={() => {
                  save("dry");
                }}
              >
                Save
              </Button>
              {showDryAlert ? (
                <Alert severity={severity} sx={{ width: "fit-content" }}>
                  {message}
                </Alert>
              ) : null}
              <Button
                variant="outlined"
                id="dry_prod_btn"
                color="primary"
                startIcon={<AddCircleIcon />}
                onClick={handleDryProdOpen}
              >
                Add product to area
              </Button>
            </div>
            {dryProdOpen ? (
              <div className="area_card_add">
                <input
                  list="products"
                  onChange={(e) =>
                    searchProduct(
                      e.target.value,
                      dryItems,
                      setDryItems,
                      setShowDryAlert,
                      "dry",
                      0
                    )
                  }
                  placeholder="Select Product"
                  className="search_input"
                />
                <datalist id="products">
                  {products.map(function (product, idx) {
                    return (
                      <option value={product.barcode}>{product.name}</option>
                    );
                  })}
                </datalist>
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
      <div className="finalise_button">
        {freezerItems.length === 0 &&
        fridgeItems.length === 0 &&
        shopItems.length === 0 &&
        dryItems.length === 0 ? null : (
          <Button
            variant="contained"
            color="success"
            startIcon={<SaveIcon />}
            size="large"
            onClick={finaliseChecks}
            id="final_stock_btn"
          >
            Finalise
          </Button>
        )}
        {showFinalStockAlert ? (
          <Alert severity={severity} sx={{ width: "fit-content" }}>
            {message}
          </Alert>
        ) : null}
      </div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <FinalCheckModal
          handleClose={handleClose}
          finaliseStock={finaliseStock}
        />
      </Modal>

      <Modal
        open={openZoneCheck}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ZoneCheckModal
          handleClose={handleCloseZoneCheck}
          setIsDoubleChecked={setIsDoubleChecked}
          save={save}
          zone={zone}
        />
      </Modal>
    </section>
  );
}
