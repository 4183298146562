import { useEffect, useState } from "react";
import DiscrepanciesRep from "../../discrepancies/DiscrepanciesRep";

export default function EodReports() {
  const [sites, setSites] = useState([]);

  useEffect(() => {
    var userD = localStorage.getItem("userDetails");
    let user = JSON.parse(userD);
    setSites(user.sites);
  }, []);

  return <DiscrepanciesRep managerZone={true} sites={sites} />;
}
