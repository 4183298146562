//use for FTG parts of NitroPOS

import { Navigate } from "react-router-dom";

const FtgProtect = ({ children }) => {
  let userD = localStorage.getItem("userDetails");
  if (!userD) {
    window.location.replace("/");
  }
  let user = JSON.parse(userD);
  if (
    user.user.role != "Food Manager" &&
    user.user.role != "Developer" &&
    user.user.role != "Manager"
  ) {
    return <Navigate to="/not-allowed" replace />;
  }
  return children;
};

export { FtgProtect };
