import { useState, useEffect, useContext } from "react";
import axios from "axios";
import AccordionSection from "./Accordion";
import { Button } from "@mui/material";

import PromotionConfirm from "./PromotionConfirm";
import Modal from "@mui/material/Modal";

export default function Deal({
    dealId,
    url,
    mainPromotion,
    handleModelAlert,
    user,
    handleReload
}) {

    const [heading, setHeading] = useState({});
    const [products, setProducts] = useState([]);
    const [matchDeals, setMatchDeals] = useState([]);

    const [dealName, setDealName] = useState("");

    const [modalOpen, setModalOpen] = useState(false);

    const handleOpenModal = () => {
        setModalOpen(true);
    };
    const handleCloseModal = () => {
        setModalOpen(false);
        handleReload();
    };

    useEffect(() => {

        axios.get(`${url}/promotions/deal/${dealId}`)
            .then((res) => {

                setHeading(res.data.dealHeading)
                setProducts(res.data.dealProducts)

                let _matches = res.data.dealMatches.sort((a, b) => {
                    // parseFloat(a.matchPercent) - parseFloat(b.matchPercent)
                    if(parseFloat(a.matchPercent) > parseFloat(b.matchPercent)){
                        return -1;
                    }else if(parseFloat(a.matchPercent) < parseFloat(b.matchPercent)){
                        return 1
                    }
                    return 0
                })

                setMatchDeals(_matches)

                setDealName(`${mainPromotion} - ${res.data.dealHeading.consumerDeal}`)

            }).catch((err) => {
                console.log(err)
            })

    }, [dealId]);

    const copyToClip = (element) => {
        navigator.clipboard.writeText(element);
        handleModelAlert("COPIED", "success")
    };

    return (
        <div className="selected_deal">
            <div className="deal_heading">
                <h3
                    onClick={() => copyToClip(dealName)}

                >Deal Name : {dealName}</h3>
            </div>
            <hr />
            <div className="deal_heading">
                <h4>SQL DealId: {heading.dealId}</h4>
                <h4>Booker Deal: {heading.consumerDeal}</h4>
                <h4>Booker Id: {heading.bookerId}</h4>
                <h4>Section: {heading.sectionName}</h4>

            </div>

            <div className="deal_heading">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenModal()}
                >
                    {heading.confirmedPromotionId == null ? "Mark As Complete" : "Update Promotion"}

                </Button>
            </div>


            <hr />
            <div className="accordion_sections">
                <div className="accordion_section">
                    <h3>BOOKER PRODUCTS</h3>
                    {products.length > 0 ? (
                        <>
                            {products.map(function (prod, i) {

                                let details = []

                                if (prod.barcode != null) {
                                    details.push({ name: "Barcode", value: prod.barcode })
                                }

                                if (prod.orderCode != null) {
                                    details.push({ name: "Order Code", value: prod.orderCode })
                                }

                                if (prod.rrp != null) {
                                    details.push({ name: "RRP", value: prod.rrp })
                                }

                                return (
                                    <AccordionSection
                                        title={prod.productName}
                                        details={details}
                                        eposProducts={prod.eposLinks}
                                        promotionLink={null}
                                        handleModelAlert={handleModelAlert}
                                    />
                                )
                            })}
                        </>
                    ) : (
                        <p>No Booker Products Ingested</p>
                    )}
                </div>

                <div className="accordion_section">
                    <h3>EPOS DEALS</h3>
                    {matchDeals.length > 0 ? (
                        <>
                            {
                                matchDeals.map(function (deal, i) {

                                    let details = []
                                    //     { name: "Amount", value: deal.amount },
                                    //     { name: "Match ", value: `${parseFloat(deal.matchPercent).toFixed(0)}%` },
                                    // ]

                                    if (deal.amount) {
                                        details.push({ name: "Amount", value: deal.amount })
                                    }

                                    if (deal.matchPercent) {
                                        details.push({ name: "Match ", value: `${parseFloat(deal.matchPercent).toFixed(0)}%` })
                                    }

                                    return (
                                        <AccordionSection
                                            title={deal.name}
                                            details={details}
                                            eposProducts={deal.products}
                                            promotionLink={`https://www.eposnowhq.com/Pages/BackOffice/PromotionDetails.aspx?PromotionID=${deal.eposPromotionId}`}
                                            handleModelAlert={handleModelAlert}
                                        />
                                    )
                                })
                            }
                        </>
                    ) : (
                        <p>No Linking Epos Deals</p>
                    )}
                </div>
            </div>

            <Modal
                open={modalOpen}
                aria-labelledby="Existing Shift Modal"
                aria-describedby="Existing Shift Modal"
            >
                <PromotionConfirm
                    handleClose={handleCloseModal}
                    dealId={dealId}
                    user={user}
                    url={url}
                />
            </Modal>


        </div >

    )


}