import FtgNav from "./FtgNav";
import "./styles/products.scss";
import { useState, useEffect } from "react";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import moment from "moment";

export default function Sato() {
    const [date, setDate] = useState("");

    useEffect(() => {
        setDate(moment().format("DD-MM-yyyy"));
    }, []);

    return (
        <main>
            <FtgNav />
            <section className="sato__main">
                <h1>Download latest version here</h1>
                <a
                    className="download__btn"
                    href="https://backend.hoagies.co.uk/food/sato/latest"
                    download={`Sato file-${date}`}
                >
                    <CloudDownloadIcon className="btn_icon" />
                    Download
                </a>
            </section>
        </main>
    );
}
