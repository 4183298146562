import { useState, useEffect } from "react";

export default function Order(props) {
    const [data, setData] = useState({})
    const [valueOrdered, setValueOrdered] = useState(props.data.ordered)

    useEffect(() => {
        setData(props.data)
    },[])

    const handleQty = (amount) => {
        setValueOrdered(amount);
        let dataCopy = data;
        dataCopy.ordered = amount;
        props.changeQty(dataCopy, props.index);
    }


  return (
    <tr>
      <td>{data.name}</td>
      <td>{data.current}</td>
      <td>
        <input
          type="number"
          onChange={(e) => handleQty(e.target.value)}
          value={valueOrdered}
        />
      </td>
    </tr>
  );
}
