import { useState, useEffect } from "react";
import axios from "axios";
import { Button, Alert } from "@mui/material";
import Tick from "../../animations/Tick";

export default function PoliceRequest() {
  const url = process.env.REACT_APP_ANPR_URL;
  const [registration, setRegistration] = useState("");
  const [tick, setTick] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [user, setUser] = useState("");
  const [note, setNote] = useState("");
  //alerts
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  useEffect(() => {
    let userD = localStorage.getItem("userDetails");
    let user = JSON.parse(userD);
    setUser(user.user.user);
  }, []);

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      submitPoliceAlert();
    }
  };

  const submitPoliceAlert = () => {
    setDisabled(true);
    if (registration === "") {
      setMessage("Please insert the registration number");
      setSeverity("warning");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    } else if (user === "") {
      setMessage(
        "Could not get your user, can you please log out and log back in"
      );
      setSeverity("warning");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    } else {
      const data = {
        user: user,
        plate: registration,
        note: note,
      };
      axios.post(`${url}/cctv-system/police-check`, data)
      .then((res) => {
        setTick(true)
        setTimeout(() => {
           document.getElementById("note").value=""
           document.getElementById("reg").value=""
           setDisabled(false) 
           setTick(false)
        }, 3500);
      }).catch((err) => {
        setMessage("Something went wrong, please try again");
        setSeverity("error");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
  
      })
    }
  };

  return (
    <main className="cctv__requests">
      <h1>Police Alert</h1>
      <strong>
        If police has given a registration to be aware of, please insert it here
      </strong>
      <div className="cctv_row">
        <label>Registration number:</label>
        <input
          type="text"
          maxLength={7}
          onChange={(e) => setRegistration(e.target.value)}
          onKeyDown={handleKeyDown}
          id="reg"
        />
      </div>
      <div className="cctv_row">
        <label>Note:</label>
        <textarea
          onChange={(e) => setNote(e.target.value)}
          maxLength={200}
          onKeyDown={handleKeyDown}
          cols={40}
          rows={5}
          it="note"
        />
      </div>

      <Button
        disabled={disabled}
        variant="contained"
        color="success"
        onClick={submitPoliceAlert}
        sx={{ margin: "2% auto" }}
      >
        Submit
      </Button>
      {tick ? (
        <Tick />
      ) : showAlert ? (
        <Alert severity={severity} sx={{ width: "60%", margin: "1% auto" }}>
          {message}
        </Alert>
      ) : null}
    </main>
  );
}
