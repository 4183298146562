import { useState, useEffect, useContext } from "react";
import DevNav from "../nav/DevNav";
import axios from "axios";
import LoginTest from "../../LoginTest";
import userContext from "../../context/userContext";
import HeadingDetails from "./HeadingDetails";
import Deal from "./Deal";
import ScanModel from "./ScanModel";

import { Button } from "@mui/material";
import Alert from "@mui/material/Alert";
import { Modal } from "@mui/material";

import { useParams } from "react-router-dom";

import "./bookerPromotions.scss";

import moment from "moment";

export default function Promotions() {
    const url = process.env.REACT_APP_DEPPLOYED;
    // const url = "http://10.0.0.34:4022";

    // const { userData } = useContext(userContext);
    const { id } = useParams();

    const userData = JSON.parse(localStorage.getItem("userDetails"));

    const [headingDetails, setHeadingDetails] = useState({});
    const [promotionHeadings, setPromotionHeadings] = useState([]);
    const [activeHeadings, setActiveHeadings] = useState([]);
    const [selectedDeal, setSelectedDeal] = useState(null);

    const [openScanModel, setOpenScanModel] = useState(false);

    const [searchText, setSearchText] = useState("");

    // Alert
    const [alert, setAlert] = useState(false);
    const [alertText, setAlertText] = useState("");

    const [reload, setReload] = useState(false);

    useEffect(() => {
        setOpenScanModel(false);
        setAlert(false);
        setAlertText("");
        setReload(false);

        axios
            .get(`${url}/promotions/${id}`)
            .then((res) => {
                setHeadingDetails(res.data.heading);

                for (let index = 0; index < res.data.headings.length; index++) {
                    const heading = res.data.headings[index];
                    delete res.data.headings[index].min;
                    let deals = res.data.promotions.filter(
                        (prom) => prom.sectionName == heading.sectionName
                    );
                    res.data.headings[index]["deals"] = deals.sort(
                        (a, b) =>
                            parseFloat(a.bookerId) - parseFloat(b.bookerId)
                    );
                    res.data.headings[index]["complete"] =
                        deals.filter(
                            (prom) => prom.confirmedPromotionId == null
                        ).length == 0;
                }

                setPromotionHeadings(res.data.headings);
                setActiveHeadings(res.data.headings);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [reload]);

    const handleReload = () => {
        console.log("RELOAD");
        setReload(true);
    };

    const handleDealSelected = (dealId) => {
        if (selectedDeal !== dealId) {
            const newDealElement = document.getElementById(
                `accordion_heading_details-${dealId}`
            );

            if (newDealElement != null) {
                document.getElementById(
                    `accordion_heading_details-${dealId}`
                ).style.backgroundColor = "#64c4e0";
            }

            const currElement = document.getElementById(
                `accordion_heading_details-${selectedDeal}`
            );

            if (currElement != null) {
                if (
                    document
                        .getElementById(
                            `accordion_heading_details-${selectedDeal}`
                        )
                        .className.includes("deal")
                ) {
                    document.getElementById(
                        `accordion_heading_details-${selectedDeal}`
                    ).style.backgroundColor = "rgba(50, 168, 82, 0.25)";
                } else {
                    document.getElementById(
                        `accordion_heading_details-${selectedDeal}`
                    ).style.backgroundColor = "#ffffff";
                }
            }

            setSelectedDeal(dealId);
        }
    };

    // ALERT FUNCTION
    const handleModelAlert = async (text, level) => {
        setAlert(true);
        setAlertText(text);

        setTimeout(() => {
            setAlert(false);
            setAlertText("");
        }, 5000);
    };

    const handleSearch = async (text) => {
        console.log("==============================");

        let _text = text.trim().toLowerCase();

        let filteredArray = [];

        for (let phIndex = 0; phIndex < promotionHeadings.length; phIndex++) {
            const ph = promotionHeadings[phIndex];

            let dealArray = [];

            for (let dealIndex = 0; dealIndex < ph.deals.length; dealIndex++) {
                const deal = ph.deals[dealIndex];

                let push = false;

                if (deal.consumerDeal.toLowerCase().includes(_text)) {
                    push = true;
                }

                if (deal.sectionName.toLowerCase().includes(_text)) {
                    push = true;
                }

                if (deal.confirmedUser != null) {
                    if (deal.confirmedUser.toLowerCase().includes(_text)) {
                        push = true;
                    }
                }

                if (deal.DealProducts != null) {
                    for (
                        let prodIndex = 0;
                        prodIndex < deal.DealProducts.length;
                        prodIndex++
                    ) {
                        const product = deal.DealProducts[prodIndex];

                        if (product.barcode.toLowerCase().includes(_text)) {
                            push = true;
                        }

                        if (product.orderCode.toLowerCase().includes(_text)) {
                            push = true;
                        }

                        if (product.productName.toLowerCase().includes(_text)) {
                            push = true;
                        }

                        if (product.rrp) {
                            if (product.rrp.toLowerCase().includes(_text)) {
                                push = true;
                            }
                        }
                    }
                }

                if (deal.DealMatches != null) {
                    for (
                        let matchDealIndex = 0;
                        matchDealIndex < deal.DealMatches.length;
                        matchDealIndex++
                    ) {
                        const matchDeal = deal.DealMatches[matchDealIndex];

                        if (matchDeal.amount.toLowerCase().includes(_text)) {
                            push = true;
                        }

                        if (matchDeal.name.toLowerCase().includes(_text)) {
                            push = true;
                        }

                        if (matchDeal.name.toLowerCase().includes(_text)) {
                            push = true;
                        }
                    }
                }

                if (push) {
                    let dealFindIndex = dealArray.findIndex(
                        (dfx) => dfx.dealId == deal.dealId
                    );

                    if (dealFindIndex < 0) {
                        dealArray.push(deal);
                    }
                }
            }

            if (dealArray.length > 0) {
                filteredArray.push({
                    sectionName: ph.sectionName,
                    deals: dealArray,
                    complete: ph.complete,
                });
            }
        }

        if (filteredArray.length > 0) {
            setSelectedDeal(null);
            handleDealSelected(null);
            setActiveHeadings(filteredArray);
        }
    };

    return (
        <>
            {userData.user && userData.user.role === "Developer" ? (
                <main className="booker__promos">
                    <DevNav />
                    <div className="promos">
                        <div className="heading">
                            <div className="titles">
                                <h3>{headingDetails.name}</h3>
                                <h3>{headingDetails.duration}</h3>
                                <h3>
                                    {moment
                                        .utc(
                                            headingDetails.sellingDateStart,
                                            "YYYY-MM-DD"
                                        )
                                        .subtract(1, "day")
                                        .format("DD-MM-YYYY")}
                                    {" - "}
                                    {moment
                                        .utc(
                                            headingDetails.sellingDateEnd,
                                            "YYYY-MM-DD"
                                        )
                                        .format("DD-MM-YYYY")}
                                </h3>
                            </div>
                            <div className="buttons">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    href="https://www.eposnowhq.com/products/create"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    NEW EPOS PRODUCT
                                </Button>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    href="https://www.eposnowhq.com/Pages/BackOffice/PromotionAdd.aspx"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    NEW EPOS PROMOTION
                                </Button>

                                <Button
                                    variant="contained"
                                    color="success"
                                    target="_blank"
                                    rel="noreferrer"
                                    onClick={() => setOpenScanModel(true)}
                                >
                                    START SCANNING
                                </Button>
                            </div>
                        </div>

                        {/* ERROR */}
                        {alert ? (
                            <Alert
                                severity="success"
                                color="info"
                                sx={{
                                    position: "absolute",
                                    zIndex: "10",
                                    top: "30%",
                                    left: "40%",
                                }}
                            >
                                <strong>{alertText}</strong>
                            </Alert>
                        ) : null}

                        <div className="promotion_body">
                            <div className="promo_details">
                                <div className="sticky">
                                    <p>SEARCH</p>
                                    <input
                                        type="text"
                                        value={searchText}
                                        onChange={(e) =>
                                            setSearchText(e.target.value)
                                        }
                                    />

                                    <div className="buttons">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() =>
                                                setActiveHeadings(
                                                    promotionHeadings
                                                )
                                            }
                                        >
                                            Clear / Reset
                                        </Button>

                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() =>
                                                handleSearch(searchText)
                                            }
                                        >
                                            Search
                                        </Button>
                                    </div>
                                </div>
                                <div className="details">
                                    {activeHeadings.map(function (d, i) {
                                        return (
                                            <HeadingDetails
                                                name={d.sectionName}
                                                deals={d.deals}
                                                handleDealSelected={
                                                    handleDealSelected
                                                }
                                                complete={d.complete}
                                            />
                                        );
                                    })}
                                </div>
                            </div>

                            {selectedDeal == null ? (
                                <div className="non_selected_deal">
                                    <h3>PICK A DEAL</h3>
                                </div>
                            ) : (
                                <Deal
                                    dealId={selectedDeal}
                                    url={url}
                                    mainPromotion={headingDetails.name}
                                    handleModelAlert={handleModelAlert}
                                    user={userData.user.user}
                                    handleReload={handleReload}
                                />
                            )}
                        </div>
                    </div>
                    {openScanModel ? (
                        <Modal
                            open={openScanModel}
                            onClose={() => setOpenScanModel(false)}
                        >
                            <ScanModel
                                user={userData.user.user}
                                url={url}
                                close={() => {
                                    setOpenScanModel(false);
                                }}
                            />
                        </Modal>
                    ) : null}
                </main>
            ) : (
                <LoginTest />
            )}
        </>
    );
}
