import {useEffect} from "react"

export default function ClaimProduct(props){
    useEffect(()=>{
        console.log("Props - ")
        console.log(props)
    },[props])
    return(
        <tr>
            <td>{props.product.Name}</td>
        </tr>
    )
}