// Tells the user how to use the program

import React from "react";
import './styles/Main.module.css'

export default function Info() {
  return (
    <div className="shelfedge">
      <h3 style={{textAlign:"center", fontFamily: "'Roboto', sans-serif"}}>Shelf Edge Label Generator</h3>
      <hr />
      <ul style={{ listStyleType: "none", margin: "auto", fontFamily: "'Roboto', sans-serif" }}>
        {/* <li>Maximum Labels Generated is 28</li> */}
        <li>Search has to be exact Barcode or Name (Case Sensitive)</li>
        <li>Click on labels you would like to remove</li>
        <li>Press Generate PDF when selection is final</li>
      </ul>
    </div>
  );
}
