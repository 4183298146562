import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60vw",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
    maxHeight: "70vh",
    overflowY: "scroll",
    "@media(max-width: 690px)": {
        width: "90vw",
        maxHeight: "90vh",
    },
};

const NotesModal = ({
    allNotes,
    rel,
    handleCloseNotes,
    id,
    isDev,
    person,
    incident,
    images,
    items,
    time,
    date,
    site,
    requestType,
}) => {
    const url = process.env.REACT_APP_ANPR_URL;
    const [notes, setNotes] = useState([]);
    const [note, setNote] = useState("");
    const [staff, setStaff] = useState("");
    const [user, setUser] = useState("");

    //Alerts
    const [alert, setAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");
    const [disable, setDisable] = useState(false);

    useEffect(() => {
        if (allNotes) {
            setNotes(allNotes);
        }
        if (isDev) {
            let userD = localStorage.getItem("userDetails");
            let currentUser = JSON.parse(userD);
            setUser(currentUser.user.user);
        }
    }, [allNotes]);

    const handleAlert = (message, severity, close, duration = 3000) => {
        setMessage(message);
        setSeverity(severity);
        setAlert(true);
        setTimeout(() => {
            setAlert(false);
            setMessage("");
            setSeverity("");
            setDisable(true);
            if (close) {
                reloadNotes();
                setStaff("");
                setNote("");
                rel();
                handleCloseNotes(false);
            }
        }, duration);
    };

    const addNote = async () => {
        setDisable(true);
        if (note === "") {
            handleAlert("Please insert a note", "warning", false);
        } else if (!isDev && staff === "") {
            handleAlert(
                "Please insert a note and your name.",
                "warning",
                false
            );
        } else {
            let data = {
                requestId: id,
                note: note,
                user: isDev ? user : staff,
            };
            await axios
                .post(`${url}/cctv-system/add-note`, data)
                .then((res) => {
                    handleAlert("Note successfully saved", "success", true);
                })
                .catch((err) => {
                    console.log(err);
                    handleAlert(
                        "Could not add note, please try again",
                        "error",
                        false
                    );
                });
        }
    };

    const reloadNotes = () => {
        axios
            .get(`${url}/cctv-system/submissions/notes/${id}`)
            .then((res) => {
                setNotes(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <Box sx={style}>
            {requestType === 2 ? (
                <>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        DESCRIPTION OF THE INCIDENT -{" "}
                        {new Date(date).toLocaleDateString("en-GB")}-{time} (
                        {site})
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <table
                            className="modal__tables description_table"
                            style={{ width: "100%" }}
                        >
                            <thead
                                style={{
                                    backgroundColor: "#2c53a0",
                                    color: "#fff",
                                }}
                            >
                                <tr>
                                    <th>Person(s) details</th>
                                    <th>Incident details</th>
                                    <th>Stolen items</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="row">
                                    <th>
                                        {person ? (
                                            person
                                        ) : (
                                            <h3>No description</h3>
                                        )}
                                    </th>
                                    <th
                                        style={{
                                            wordWrap: "break-word",
                                        }}
                                    >
                                        {incident}
                                    </th>
                                    <th>{items ? items : <h3>No items</h3>}</th>
                                </tr>
                            </tbody>
                        </table>
                        <Typography
                            id="notes_header"
                            variant="h6"
                            component="h2"
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "10px",
                                flexWrap: "wrap",
                            }}
                        >
                            {images &&
                                images.map((image, i) => {
                                    console.log(image);
                                    return (
                                        <img
                                            src={image}
                                            width="200"
                                            key={i}
                                            style={{ margin: "2px" }}
                                        />
                                    );
                                })}
                        </Typography>
                    </Typography>
                </>
            ) : null}
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Notes
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <table
                    className="modal__tables notes_table"
                    style={{ width: "100%" }}
                >
                    <thead
                        style={{ backgroundColor: "#2c53a0", color: "#fff" }}
                    >
                        <tr>
                            <th>Note</th>
                            <th>Added by</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {notes && notes.length > 0 ? (
                            notes.map((note, i) => {
                                return (
                                    <tr className="row" key={i}>
                                        <th>{note.note}</th>
                                        <th>{note.user}</th>
                                        <th>
                                            {new Date(
                                                note.noteDate
                                            ).toLocaleDateString("en-GB")}
                                        </th>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <th colspan={3} style={{ textAlign: "center" }}>
                                    No notes
                                </th>
                            </tr>
                        )}
                    </tbody>
                </table>
                <hr />
                <div>
                    <h4>
                        <u>Add note</u>
                    </h4>
                    <label>Note:</label>
                    <input
                        style={{
                            width: "100%",
                            padding: "5px",
                            margin: "10px",
                            borderRadius: "8px",
                            border: "1px solid #2c53a0",
                        }}
                        type="text"
                        placeholder="Enter Note..."
                        onChange={(e) => setNote(e.target.value)}
                        value={note}
                    />
                    <br />
                    {!isDev ? (
                        <>
                            <label>Your Name: </label>
                            <input
                                style={{
                                    width: "100%",
                                    padding: "5px",
                                    margin: "10px",
                                    borderRadius: "8px",
                                    border: "1px solid #2c53a0",
                                }}
                                type="text"
                                placeholder="Enter Your Name..."
                                onChange={(e) => setStaff(e.target.value)}
                                value={staff}
                            />
                        </>
                    ) : null}
                    {alert ? (
                        <Alert severity={severity}>{message}</Alert>
                    ) : (
                        <></>
                    )}
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "3%",
                        }}
                    >
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => handleCloseNotes(false)}
                            disabled={disable}
                        >
                            Close
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            id="addNote-btn"
                            onClick={() => addNote()}
                            disabled={disable}
                        >
                            Add Note
                        </Button>
                    </div>
                </div>
            </Typography>
        </Box>
    );
};

export default NotesModal;
