import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { Button } from "@mui/material";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60vw",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
    maxHeight: "70vh",
    overflowY: "scroll",
    "@media(max-width: 690px)": {
        width: "90vw",
        maxHeight: "90vh",
    },
};

export default function CommentsModal({ handleClose, comments }) {
    return (
        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Comments
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <table className="modal__tables" style={{ width: "100%" }}>
                    <thead
                        style={{ backgroundColor: "#2c53a0", color: "#fff" }}
                    >
                        <tr>
                            <th>Added by</th>
                            <th>Comment</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody style={{ border: "1px solid #4d4d4d" }}>
                        {comments && comments.length > 0 ? (
                            comments.map((comment, i) => {
                                return (
                                    <tr key={i}>
                                        <td
                                            style={{
                                                maxWidth: "100px",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            {comment.addedBy}
                                        </td>
                                        <td>{comment.comment}</td>
                                        <td>
                                            {new Date(
                                                comment.generatedOn
                                            ).toLocaleDateString("en-GB")}
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <th colspan={3} style={{ textAlign: "center" }}>
                                    No Comments
                                </th>
                            </tr>
                        )}
                    </tbody>
                </table>
                <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleClose(false)}
                    sx={{ marginTop: "8px" }}
                >
                    close
                </Button>
            </Typography>
        </Box>
    );
}
