import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";

//STYLING
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60vw",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
    maxHeight: "70vh",
    overflowY: "scroll",
    "@media(max-width: 690px)": {
        width: "90vw",
        maxHeight: "90vh",
        p: 1,
    },
};
export default function RedAlert({ handleClose }) {
    return (
        <Box sx={style}>
            <Alert variant="filled" severity="error">
                WARNING - Car does not have MOT or Taxed or the fuel type is
                Electricity, please call the police on 101 and do not allow the
                customer to leave the premises
            </Alert>
            <Button
                onClick={() => handleClose(false)}
                variant="contained"
                color="warning"
                sx={{ marginTop: "10px" }}
            >
                close
            </Button>
        </Box>
    );
}
