import { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import ErrorIcon from '@mui/icons-material/Error';
export default function OrderIndv({ row, type, handleConfirmOrderQuantity }) {
    const [confirmOrderQuantity, setConfirmOrderQuantity] = useState(
        row.ordered
    );

    return (
        <tr>
            <th>{row.name}</th>
            <th>{row.current}</th>
            <th>{row.weekSales}</th>
            {type == "KEG" ?
                <th>{row.warehouse} ({row.updatedStock})</th>
                :
                <th>N/A</th>
            }
            <th className="table_right_border">{row.ordered}</th>
            <th>
                <input
                    onChange={(e) => {
                        setConfirmOrderQuantity(e.target.value);
                        handleConfirmOrderQuantity(row.eposId, e.target.value);
                    }}
                    type="number"
                    pattern="[0-9]*"
                    id="confirm_order_input"
                    min="0"
                    value={confirmOrderQuantity}
                />
                {type == "KEG" ?
                    <>
                        {confirmOrderQuantity > row.updatedStock ?
                            <Tooltip title="Not Enough Stock @ Warehouse">
                                <ErrorIcon color="warning" fontSize="small" />
                            </Tooltip>
                            :
                            <></>
                        }
                    </>
                    :
                    <></>
                }
            </th>
        </tr>
    );
}
