import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";

//STYLING
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "45vw",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
    maxHeight: "70vh",
    overflowY: "scroll",
    "@media(max-width: 690px)": {
        width: "90vw",
        maxHeight: "90vh",
        p: 1,
    },
};
export default function AdminCode({
    handleAdminDialogClose,
    adminAlert,
    alertText,
    setAdminAlert,
    setStaffName,
    setAdminVerCode,
    adminPasscode,
}) {
    return (
        <Box sx={style} className="all_modal">
            <Typography id="modal-modal-title" variant="h4" component="h2">
                Verify Admin Code
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {adminAlert ? (
                    <Alert
                        severity="error"
                        variant="filled"
                        onClose={() => setAdminAlert(false)}
                    >
                        {alertText}
                    </Alert>
                ) : (
                    <></>
                )}
                <h3>Please contact IT / Your manager for this code</h3>
                <br />
                <input
                    typeof="text"
                    onChange={(e) => setStaffName(e.target.value)}
                    placeholder="Your Full Name Here"
                />
                <input
                    typeof="number"
                    onChange={(e) => setAdminVerCode(e.target.value)}
                    minLength={12}
                    maxLength={12}
                    placeholder="Admin Passcode Here"
                />
                <section
                    style={{ display: "flex", justifyContent: "flex-end" }}
                >
                    <Button
                        onClick={() => handleAdminDialogClose(false)}
                        color="error"
                    >
                        Cancel
                    </Button>
                    <Button onClick={adminPasscode}>Accept</Button>
                </section>
            </Typography>
        </Box>
    );
}
