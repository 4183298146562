import { useState, useEffect } from "react";
import "./modals.scss";

export default function Notes(props) {
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    setNotes(props.notes);
  }, [props]);

  return (
    <section className="notes">
      <h2>Notes</h2>
      {notes.length === 0 ? (
        <h4 style={{color: "#4d4d4d"}}>NO NOTES TO SHOW</h4>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Source</th>
              <th>Added on</th>
              <th>Added By</th>
              <th>Note</th>
            </tr>
          </thead>
          <tbody>
            {notes.map(function (d, i) {
              return (
                <tr>
                  <th>{d.source}</th>
                  <th>{new Date(d.dateTime).toLocaleDateString()}</th>
                  <th>{d.user}</th>
                  <th>{d.note}</th>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </section>
  );
}
