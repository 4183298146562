import {useEffect, useState} from 'react';

export default function IndvVal(props){

    useEffect(() => {
        console.log(props.data.difference)
    },[props.data])

    return(
        <tr>
            <td>{new Date(props.data.date).toLocaleDateString("en-GB")}</td>
            <td>£{props.data.value}</td>
            <td><input type="number" pattern="[0-9]*" onChange={(e) => props.handleDiff(e.target.value, props.index)}/></td>
        </tr>
    )
}