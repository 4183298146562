import axios from "axios";
import React, { useState, useEffect } from "react";
import "./styles/register.scss";
import { Button } from "@mui/material";
import Alert from "@mui/material/Alert";
import Tick from './animations/Tick';

function AddUser(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userRole, setUserRole] = useState("");
  const [error, setError] = useState();
  const [role, setRole] = useState("");
  const [user, setUser] = useState("");
  const [sites, setSites] = useState([]);
  const [siteList, setSiteList] = useState([]);
  const [message, setMessage] = useState("");
  const [errorAlert, setErrorAlert] = useState(false);
  const [showTick, setShowTick] = useState(false);

  useEffect(() => {
    let userD = localStorage.getItem("userDetails");
    let user = JSON.parse(userD);
    setUser(user.user.user);
    setRole(user.user.role);

    axios
      .get("https://nitrous.rt-itservices.co.uk/sites/data")
      .then((res) => {
        setSiteList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleSites = (site) => {
    let inx = sites.findIndex((p) => p === site);
    if (inx === -1) {
      sites.push(site);
    } else {
      sites.splice(inx, 1);
    }
  };

  const submit = () => {
    // document.getElementById("register__btn").style.display = "none";
    if (password.length < 6) {
      setMessage("Password must be at least 6 characters, please try again");
      setErrorAlert(true);
      setTimeout(() => {
        setErrorAlert(false);
        setMessage("");
        document.getElementById("register__btn").style.display = "inline";
      }, 3000);
    } else if (
      sites.length === 0 &&
      (userRole === "Store Manager" || userRole === "Supervisor")
    ) {
      setMessage("At least one site must be selected, please try again");
      setErrorAlert(true);
      setTimeout(() => {
        setErrorAlert(false);
        setMessage("");
        document.getElementById("register__btn").style.display = "inline";
      }, 3000);
    } else {
      let newUser = {};
      if (
        sites.length === siteList.length ||
        userRole === "Manager" ||
        userRole === "Admin" ||
        userRole === "Warehouse Manager" ||
        userRole === "Developer" ||
        userRole === "HR" ||
        userRole === "Food Manager" ||
        userRole === "V4U Manager"
      ) {
        newUser = { email, password, userRole, sites: ["HQ"] };
      } else if (userRole === "Store Manager" || userRole === "Supervisor") {
        console.log("checking sites")
        let inx = sites.findIndex((p) => p === "Head Office");
        console.log(inx)
        if (inx === -1){
          newUser = { email, password, userRole, sites };
        } else {
          newUser = { email, password, userRole, sites: ["HQ"] };
        }
      }
      axios
        .post(
          "https://nitropos-backend.nitrosolutions.co.uk/users/register",
          // "http://10.0.0.34:4022/users/register",
          newUser
        )
        .then((res) => {
          // console.log(res.data);
          setShowTick(true)
          setTimeout(() => {
            setShowTick(false)
            setEmail("");
            setPassword("");
            setRole("");
            document.getElementById("register__btn").style.display = "inline";
          },4000)
        })
        .catch((err) => {
          console.log(err);
          if (
            err.response.data.msg ===
            "An account with this user already exists."
          ) {
            setMessage("An account for this user already exists");
            setErrorAlert(true);
            setTimeout(() => {
              setErrorAlert(false);
              setMessage("");
              document.getElementById("register__btn").style.display = "inline";
            }, 4000);
          } else {
            setMessage("Something went wrong, please try again");
            setErrorAlert(true);
            setTimeout(() => {
              setErrorAlert(false);
              setMessage("");
              document.getElementById("register__btn").style.display = "inline";
            }, 3000);
          }
        });
    }
  };

  return (
        <section className="dashboard">
          <form className="form" onSubmit={submit} autoComplete="new-password">
            <h2>Register User</h2>
            <label htmlFor="register-email" style={{ color: "#4d4d4d" }}>
              Email
            </label>
            <input
              id="register-email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="off"
            />
            <label htmlFor="register-password" style={{ color: "#4d4d4d" }}>
              Password
            </label>
            <input
              id="register-password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="new-password"
            />

            <label htmlFor="register-display-name" style={{ color: "#4d4d4d" }}>
              User Role
            </label>
            <select
              id="register-display-name"
              value={userRole}
              onChange={(e) => setUserRole(e.target.value)}
            >
              <option>--- Select Role ---</option>
              <option value="Manager">
                Manager - used for directors or higher management
              </option>
              <option value="Admin">Admin - for office staff</option>
              <option value="HR">HR - for HR staff</option>
              <option value="Store Manager">Store Manager</option>
              <option value="Supervisor">
                Supervisor - used for Supervisors and TL's
              </option>
              <option value="Warehouse Manager">Warehouse Manager</option>
              <option value="Food Manager">Food Manager</option>
              <option value="V4U Manager">V4U Manager</option>
              <option value="Developer">Developer</option>
            </select>
            {userRole === "Store Manager" || userRole === "Supervisor" ? (
              siteList.map(function (d, i) {
                return (
                  <div className="form_line">
                    <input
                      type="checkbox"
                      name={d.name}
                      value={d.name}
                      onChange={(e) => handleSites(e.target.value)}
                      id={i}
                    />
                    <label for={d.name}>{d.name}</label>
                  </div>
                );
              })
            ) : (
              <></>
            )}
            <Button
              onClick={() => submit()}
              variant="contained"
              color="success"
              id="register__btn"
            >
              Submit
            </Button>
            {errorAlert ? <Alert severity="warning">{message}</Alert> : <></>}
            {showTick ? <Tick /> : <></>}
          </form>
        </section>
  );
}

export default AddUser;
