import React from "react";
import {useState, useEffect} from 'react'
import axios from 'axios'

const url = `${process.env.REACT_APP_API_URL}/rota/heatmap`

const HeatMap = ({currentDate, rotaId, site}) => {
  const [loading, setLoading] = useState(true)
  const [data, setdata]  = useState(null)
  const [error, setError] = useState('')
  const abortController = new AbortController()
  
  useEffect( ()=>{
    getPrevWeekData(url)
    // console.log('site is here',site)
    // abort request todo
    return ()=> abortController.abort()
  }, [])
  
  const getPrevWeekData = async (url)=>{
    let body =  { "date": currentDate, "siteId": site, "rotaId": rotaId };
    const res =  await axios.post(url, body, {signal:abortController.signal})
    .then(res=>res.data)
    .catch((err=>{console.error(err)
      setError(err)
    }))
    setdata(res)
    setLoading(false)
    // return res
  }


  const getHeadings = (data) => {
    return (
      <tr>
        <td
          style={{
            border: "1px solid black",
            borderCollapse: "collapse",
          }}
        ></td>
        {data?.map((e) => {
          return (
            <td
              style={{
                border: "1px solid black",
                borderCollapse: "collapse",
              }}
            >
              {e.date}
            </td>
          );
        })}
      </tr>
    );
  };

  const getDynamicColor = (value, minValue=20, maxValue=500) => {
    

    // Normalize the value to a range [0, 1]
    const normalize = (value) => (value - minValue) / (maxValue - minValue);
    const normalizedValue = normalize(value);
    const red = Math.round(255 * normalizedValue) *20;
    const green = Math.round(255 * (1 - normalizedValue)) ;
    const blue = 0; // Keep blue constant for a gradient effect
    return `rgb(${red}, ${green}, ${blue})`;
  };

  const makeTableData = (data) => {
    let minValue = 0
    let maxValue = 0
    if (!data) throw "Data not found";
    let newData = [];
    data.map((el, i) => {
      let col = [];
      el.times.map((val) => {
        col.push(val.totalValue);
        if (val.totalValue < minValue) {
          minValue = val.totalValue;
        }
        if (val.totalValue > maxValue) {
          maxValue = val.totalValue;
        }
      });
      newData.push(col);
    });
    return {newData, maxValue, minValue};
  };

  const borderStyle = { border: "1px solid black", borderCollapse: "collapse" };

  const getTableBody = (data) => {
    let {newData, minValue, maxValue} = makeTableData(data);
    return (
      <>
        {newData[0]?.map((e, idx) => {
          return (
            <tr
              style={{
                border: "1px solid black",
                borderCollapse: "collapse",
              }}
            >
              <td
                style={{
                  border: "1px solid black",
                  borderCollapse: "collapse",
                  ...borderStyle,
                }}
              >
                {data[0]?.times[idx]?.hour}
              </td>
              <td
                style={{
                  ...borderStyle,
                  backgroundColor: getDynamicColor(newData[0][idx], minValue,maxValue),
                  height: "20px",
                }}
              >£{newData[0][idx]}</td>
              <td
                style={{
                  ...borderStyle,
                  backgroundColor: getDynamicColor(
                    newData[1][idx],
                    minValue,maxValue
                  ),
                }}
              >£{newData[1][idx]}</td>
              <td
                style={{
                  ...borderStyle,
                  backgroundColor: getDynamicColor(
                    newData[2][idx],
                    minValue,maxValue
                  ),
                }}
              >£{newData[2][idx]}</td>
              <td
                style={{
                  ...borderStyle,
                  backgroundColor: getDynamicColor(
                    newData[3][idx], minValue,maxValue
                  ),
                }}
              >£{newData[3][idx]}</td>
            </tr>
          );
        })}
      </>
    );
  };

  if(error){
    return <div
    style={{padding:40, display:'flex', justifyContent:'center'}}>{error}</div>
  }

  return (
    <div style={{ opacity: "95%" , height:'100%'}}>
      {loading? <div>Loading...</div>:
      <table style={{height:'100%' , fontSize:14}}>
        <thead>{getHeadings(data)}</thead>
        <tbody>{getTableBody(data)}</tbody>
      </table>}
    </div>
  );
};



export default HeatMap;
