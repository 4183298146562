import { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import ErrorIcon from '@mui/icons-material/Error';


export default function DispOrder(props) {
  const [data, setdata] = useState({});
  // const [orderVal, setOrderVal] = useState(props.data.toOrder);
  const [orderVal, setOrderVal] = useState(0);
  const [errorAlert, setErrorAlert] = useState(false);

  useEffect(() => {
    setdata(props.data);
  }, []);

  const changeAmount = (amount) => {
    // if(amount>data.available){
    //   setErrorAlert(true)
    //   setTimeout(()=>{
    //     setErrorAlert(false)
    //   },2000)
    // }else{
    setOrderVal(amount);
    let dataCopy = data;
    dataCopy.toOrder = amount;
    props.changeQty(dataCopy, props.index);
    // }
  };

  return (
    <>
    <tr>
      <td>{data.name}</td>
      <td>{data.store}</td>
      <td>
        <input
          type="number"
          onChange={(e) => changeAmount(e.target.value)}
          value={orderVal}
        />
        {data.available >= 0 && (orderVal > data.available) ?
            <Tooltip title="Not Enough Stock @ Warehouse">
              <ErrorIcon color="warning" fontSize="small" />
            </Tooltip>
            :
            <></>
          }
      </td>
      <td>
        {data.warehouse!=data.available?
        <>{data.warehouse} ({data.available})</>
        :<>{data.warehouse}</>}
      </td>
      <td>{data.sales}</td>
    </tr>
    {errorAlert ? <Alert severity="warning" sx={{width: "1370px",padding:"10px"}}>Cannot Order More Than What You Have In Stock!</Alert> : <></>}
    </>
  );
}
