import moment from "moment";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import CloseIcon from "@mui/icons-material/Close";
import { Modal } from "@mui/material";
import CommentsModal from "./CommentsModal";
import SpeakerNotesOffIcon from "@mui/icons-material/SpeakerNotesOff";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import { useState } from "react";
import ImagesModal from "./ImagesModal";
import ImageIcon from "@mui/icons-material/Image";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";

export default function IndvAlert({ alert, siteName }) {
    const [openComments, setOpenComments] = useState(false);
    const [openImages, setOpenImages] = useState(false);
    return (
        <tr>
            <th>{siteName && siteName}</th>

            <th className="alert_description">
                <b>{alert.name}</b> - {alert.description}
            </th>
            <th>{moment(alert.dateAdded).format("DD/MM/YYYY")}</th>
            
            <th>
                {alert.images && alert.images.length > 0 ? (
                    <ImageIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => setOpenImages(true)}
                        color="primary"
                    />
                ) : (
                    <ImageNotSupportedIcon style={{ cursor: "pointer" }} />
                )}
            </th>
            <th>
                {alert.comments && alert.comments.length > 0 ? (
                    <SpeakerNotesIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => setOpenComments(true)}
                        color="primary"
                    />
                ) : (
                    <SpeakerNotesOffIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => setOpenComments(true)}
                    />
                )}
            </th>
            <th>
                {alert.alertRead ? (
                    <DoneOutlineIcon color="success" />
                ) : (
                    <CloseIcon color="error" />
                )}
            </th>
            <Modal open={openComments}>
                <CommentsModal
                    handleClose={setOpenComments}
                    comments={alert.comments}
                />
            </Modal>
            <Modal open={openImages}>
                <ImagesModal
                    handleClose={setOpenImages}
                    images={alert.images}
                />
            </Modal>
        </tr>
    );
}
