import { useEffect, useState } from "react";
import CCTVIndividual from "./CCTVIndividual";
import axios from "axios";
import Loader from "../../animations/Loader";

export default function CCTVRequests({ statusList }) {
    const url = process.env.REACT_APP_ANPR_URL;
    const [requests, setRequests] = useState([]);
    const [reload, setReload] = useState(false);
    const [sorted, setSorted] = useState(false);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        setLoader(true);
        axios
            .get(`${url}/cctv-system/submissions/details/allActive`)
            .then((res) => {
               
                setRequests(res.data.cctv);
                setLoader(false);
            })
            .catch((err) => {
                console.log(err);
                setLoader(false);
            });
    }, [reload]);

    const rel = () => {
        setReload(!reload);
    };

    //Filters
    const sortSite = () => {
        setSorted(!sorted);
        let siteData = requests.sort((a, b) =>
            a.siteName.localeCompare(b.siteName)
        );
        setRequests(siteData);
    };

    const sortDate = () => {
        setSorted(!sorted);
        let dateData = requests.sort(
            (a, b) => new Date(a.date) - new Date(b.date)
        );
        setRequests(dateData);
    };

    const sortStatus = () => {
        setSorted(!sorted);
        let statusData = requests.sort((a, b) => {
            return a.statusId - b.statusId;
        });

        setRequests(statusData);
    };

    return (
        <main className="cctv__requests">
            <h1 className="cctv-header">CCTV - Dev Area</h1>
            {!loader ? (
                requests?.length > 0 ? (
                    <section className="cctv-dev-table-container">
                        <table className="cctv__table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th
                                        className="filter_heading"
                                        onClick={sortDate}
                                    >
                                        Date
                                    </th>
                                    <th
                                        className="filter_heading"
                                        onClick={sortSite}
                                    >
                                        Site
                                    </th>
                                    <th>Reason</th>
                                    <th
                                        className="filter_heading"
                                        onClick={sortStatus}
                                    >
                                        Status
                                    </th>
                                    <th>Storage</th>
                                    <th>Notes</th>
                                    <th>QR</th>
                                </tr>
                            </thead>
                            <tbody>
                                {requests.length > 0 ? (
                                    requests.map((request, i) => {
                                        return (
                                            <CCTVIndividual
                                                request={request}
                                                statusList={statusList}
                                                rel={rel}
                                            />
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <th colSpan={9}>No open requests</th>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </section>
                ) : (
                    <h2>No CCTV Requests</h2>
                )
            ) : (
                <Loader />
            )}
        </main>
    );
}
