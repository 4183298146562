import { useState, useEffect } from "react";
import { dataUriToBuffer } from "data-uri-to-buffer";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import Loader from "../../../animations/Loader";
import Alert from "@mui/material/Alert";
import axios from "axios";
import { getAllStores } from "../../../utils/Api";
import Modal from "@mui/material/Modal";
import CrimeRefModal from "./modals/CrimeRefModal";
import moment from "moment";

const CCTVFormInput = (props) => {
    const url = process.env.REACT_APP_ANPR_URL;
    const [site, setSite] = useState("");
    const [siteName, setSiteName] = useState("");
    const [name, setName] = useState("");
    const [reason, setReason] = useState("");
    const [date, setDate] = useState("");
    const [time, setTime] = useState("");
    const [notes, setNotes] = useState("");
    const [stolenItems, setStolenItems] = useState("");
    const [personDescription, setPersonDescription] = useState("");
    const [incidentDescription, setIncidentDescription] = useState("");
    const [crimeRef, setCrimeRef] = useState("");
    const [image, setImage] = useState("");
    const [images, setImages] = useState([]);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");
    const [alert, setAlert] = useState(false);
    const [imageAlert, setImageAlert] = useState(false);
    const [showSendButton, setShowSendButton] = useState(true);
    const [showLoader, setShowLoader] = useState(false);
    const [crimeRefOpen, setCrimeRefOpen] = useState(false);

    useEffect(() => {
        let site = localStorage.getItem("site");

        let parsedSite = JSON.parse(site);
        setSiteName(parsedSite);
        getAllStores()
            .then((data) => {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].siteName == parsedSite) {
                        setSite(data[i].eposId);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const addImage = () => {
        if (image === "") {
            document.getElementById("image-btn").style.display = "none";
            setMessage("Please upload an image first");
            setSeverity("warning");
            setImageAlert(true);
            setTimeout(() => {
                setImageAlert(false);
                setSeverity("");
                setMessage("");
                document.getElementById("image-btn").style.display = "flex";
            }, 3000);
        } else {
            const img = new FileReader();
            img.readAsDataURL(image);
            img.onload = () => {
                if (images.includes(img.result)) {
                    document.getElementById("image-btn").style.display = "none";
                    setMessage("This image has already been added");
                    setSeverity("warning");
                    setImageAlert(true);
                    setTimeout(() => {
                        setImageAlert(false);
                        setMessage("");
                        setSeverity("");
                        document.getElementById("image-btn").style.display =
                            "flex";
                    }, 3000);
                } else {
                    setImages([...images, img.result]);
                }
            };
            setImage("");
            document.getElementById("image-upload").value = null;
        }
    };

    const removeImage = (image) => {
        const filteredArray = images.filter((img) => img !== image);
        setImages(filteredArray);
    };

    const formatCheck = (check) => {
        let formatArr = [];

        for (let i = 0; i < check.length; i++) {
            let fileData = check[i].split(",");
            let type = fileData[0].replace("data:", "");
            type = type.replace(";base64", "");
            let imgFormat = type.replace("image/", "");
            formatArr.push(imgFormat);
        }

        return formatArr;
    };

    const submit = async () => {
        if (
            name === "" ||
            date === "" ||
            time === "" ||
            reason === "---Please select---" ||
            reason === ""
        ) {
            setMessage("All required fields must be filled, please try again.");
            setSeverity("warning");
            setAlert(true);
            setTimeout(() => {
                setAlert(false);
                setSeverity("");
                setMessage("");
            }, 3000);
        } else if (site === "") {
            document.getElementById("submit-btn").style.display = "none";
            setMessage("Site id is not being sent, please try again");
            setSeverity("warning");
            setAlert(true);
            setTimeout(() => {
                setAlert(false);
                setSeverity("");
                setMessage("");
                document.getElementById("submit-btn").style.display = "flex";
            }, 3000);
        } else if (incidentDescription.length < 25) {
            document.getElementById("submit-btn").style.display = "none";
            setMessage("Please expand your description of the incident");
            setSeverity("warning");
            setAlert(true);
            setTimeout(() => {
                setAlert(false);
                setSeverity("");
                setMessage("");
                document.getElementById("submit-btn").style.display = "flex";
            }, 3000);
        }
        // else if (crimeRef === "") {
        //     setCrimeRefOpen(true);
        // }
        else {
            finalSubmit();
        }
    };

    const finalSubmit = async () => {
        setShowSendButton(false);
        setShowLoader(true);

        let imageFormats = formatCheck(images);

        let data = {
            staffName: name,
            siteId: site,
            site: siteName,
            date: date,
            time: time,
            requestType: 2,
            reason: reason,
            crimeReference: crimeRef,
            items: stolenItems,
            description: incidentDescription,
            person: personDescription,
            note: notes,
            images: imageFormats,
            plate: "",
            pump: "",
            pumpType: "",
            amount: "",
            litres: "",
        };

        console.log(data);

        await axios
            .post(`${url}/cctv-system/submit`, data)
            .then((res) => {
                let s3Array = res.data.s3Links;

                for (let i = 0; i < s3Array.length; i++) {
                    let buffer = dataUriToBuffer(images[i]);

                    axios
                        .put(s3Array[i].url, buffer.buffer)
                        .then((res) => {})
                        .catch((err) => {
                            console.log(err);
                        });
                }

                setShowLoader(false);
                setMessage("CCTV requst sent");
                setSeverity("success");
                setAlert(true);
                setTimeout(() => {
                    setAlert(false);
                    setMessage("");
                    setSeverity("");
                    setShowSendButton(true);
                }, 3000);
                setName("");
                setDate("");
                setTime("");
                setReason("");
                setStolenItems("");
                setPersonDescription("");
                setIncidentDescription("");
                setNotes("");
                setCrimeRef("");
                setImages([]);
                document.getElementById("reason-input").value =
                    "---Please select---";
                document.getElementById("image-upload").value = null;
                props.rel();
            })
            .catch((err) => {
                console.log(err);
                setMessage("Could not complete request, please try again.");
                setSeverity("error");
                setAlert(true);
                setTimeout(() => {
                    setAlert(false);
                    setSeverity("");
                    setMessage("");
                }, 3000);
                setShowSendButton(true);
            });
    };

    return (
        <main className="cctv_cards">
            <label>
                Your name<span>*</span>
            </label>
            <input
                type="text"
                onChange={(e) => setName(e.target.value)}
                required
                value={name}
            />
            <label>
                Date<span>*</span>
            </label>
            <input
                type="date"
                onChange={(e) => setDate(e.target.value)}
                value={date}
                max={moment().format("YYYY-MM-DD")}
            />
            <label>
                Time<span>*</span>
            </label>
            <input
                type="time"
                onChange={(e) => setTime(e.target.value)}
                value={time}
            />
            <label>
                Reason for CCTV request<span>*</span>
            </label>
            <select
                id="reason-input"
                onChange={(e) => setReason(e.target.value)}
            >
                <option>---Please select---</option>
                <option>Theft</option>
                <option>Attempted Theft</option>
                <option>Assault</option>
                <option>Vandalism</option>
                <option>Loitering</option>
                <option>Other</option>
            </select>
            {reason === "Theft" ? (
                <>
                    <label>If known, what item(s) were stolen?</label>

                    <textarea
                        rows="2"
                        cols="40"
                        onChange={(e) => setStolenItems(e.target.value)}
                        value={stolenItems}
                    />
                </>
            ) : (
                <></>
            )}
            <label>Description of person(s) involved</label>
            <textarea
                rows="4"
                cols="35"
                onChange={(e) => setPersonDescription(e.target.value)}
                value={personDescription}
            />
            <label>
                Description of incident<span>*</span>
            </label>
            <textarea
                rows="4"
                cols="35"
                onChange={(e) => setIncidentDescription(e.target.value)}
                value={incidentDescription}
            />
            <label>Any other notes?</label>
            <textarea
                rows="4"
                cols="35"
                onChange={(e) => setNotes(e.target.value)}
                value={notes}
            />
            <label>Crime Reference Number (if known)</label>
            <input
                type="text"
                onChange={(e) => setCrimeRef(e.target.value)}
                value={crimeRef}
            />
            <label>Upload images</label>

            <input
                type="file"
                id="image-upload"
                accept="image/*"
                onChange={(e) => setImage(e.target.files[0])}
            />
            <Button id="image-btn" variant="outlined" onClick={addImage}>
                Add Image
            </Button>
            {imageAlert ? <Alert severity={severity}>{message}</Alert> : <></>}

            <div className="image__uploads">
                {images.length > 0 ? (
                    images.map((image) => {
                        return (
                            <div className="image_container">
                                <img src={image} />
                                <Button
                                    color="error"
                                    onClick={() => removeImage(image)}
                                >
                                    Remove
                                </Button>
                            </div>
                        );
                    })
                ) : (
                    <></>
                )}
            </div>

            {alert ? (
                <Alert variant="filled" severity={severity}>
                    {message}
                </Alert>
            ) : (
                <></>
            )}
            {showSendButton ? (
                <Button
                    id="submit-btn"
                    variant="contained"
                    endIcon={<SendIcon sx={{ margin: "0" }} />}
                    onClick={() => submit()}
                >
                    Send
                </Button>
            ) : showLoader ? (
                <Loader />
            ) : null}
        </main>
    );
};

export default CCTVFormInput;
