import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

//STYLING
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60vw",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
    maxHeight: "70vh",
    overflowY: "scroll",
    "@media(max-width: 690px)": {
        width: "90vw",
        maxHeight: "90vh",
        p: 1,
    },
};

export default function PhotosModal({ images, setShowImagesModal }) {
    return (
        <Box sx={style} className="all_modal">
            <Typography id="notes_header" variant="h5" component="h1">
                Drive Off Images
            </Typography>
            <Typography
                id="notes_header"
                variant="h6"
                component="h2"
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    flexWrap: "wrap",
                }}
            >
                {images.map((image, i) => {
                    return <img width="200" key={i} SRC={image} />;
                })}
            </Typography>
            <Button
                color="error"
                variant="contained"
                onClick={() => setShowImagesModal(false)}
            >
                close
            </Button>
        </Box>
    );
}
