import { useState, useEffect, useRef } from "react";
import ListIcon from "@mui/icons-material/List";
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import StorageModal from "./modals/StorageModal";
import StatusModal from "./modals/StatusModal";
import SdStorageIcon from "@mui/icons-material/SdStorage";
import Tooltip from "@mui/material/Tooltip";
import Modal from "@mui/material/Modal";
import SpeakerNotesOffIcon from "@mui/icons-material/SpeakerNotesOff";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import NotesModal from "./modals/NotesModal";
import VehicleDetails from "../../StoreZone/storeManagement/driveoff/modals/VehicleDetails";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import PhotosModal from "./modals/PhotosModal";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import QRCode from "qrcode";
import QRPrint from "./modals/QRPrint";
import CCTVModal from "./modals/CCTVModal";
import InvestigationModal from "./modals/InvetsigationModal";

export default function DriveOffIndividual({ request, statusList, rel, fuelData }) {
    const [status, setStatus] = useState("");
    const [showStatusModal, setShowStatusModal] = useState(false);
    const [showStorageModal, setShowStorageModal] = useState(false);
    const [showNotesModal, setShowNotesModal] = useState(false);
    const [showVehicleDetailModal, setShowVehicleDetailModal] = useState(false);
    const [showImagesModal, setShowImagesModal] = useState(false);
    const [photosArr, setPhotosArr] = useState([]);
    const [showCrimeRef, setShowCrimeRef] = useState(false);
    const [qrCode, setQrCode] = useState("");
    const [openQR, setOpenQR] = useState(false);
    const [showCCTVModal, setShowCCTVModal] = useState(false);
    const [showInvetigationModal,setShowInvestigationModal] =useState(false)
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        let statuses = statusList;
        for (let i = 0; i < statuses.length; i++) {
            if (statuses[i].statusId == request.statusId) {
                setStatus(statuses[i].name);
            }
        }
        const photoArr = [
            request.driverImg,
            request.itemLeftImg,
            request.signatureImg,
        ];
        setPhotosArr(photoArr);

        //Create QR code
        QRCode.toDataURL(String(request.requestId))
            .then((url) => {
                setQrCode(url);
            })
            .catch((err) => {
                console.error(err);
            });
        setFilteredData(fuelData.filter((fuel) => fuel.locationId == request.siteId))
    }, [rel]);

    return (
        <>
            {" "}
            <tr>
                <th>{request.site}</th>
                <th>
                    {new Date(request.date).toLocaleDateString("en-GB")}-
                    {request.time}
                </th>
                <th>{request.pump}</th>

                <th onClick={() => setShowVehicleDetailModal(true)}>
                    {request.plate}
                </th>
                <th>£{request.amount}</th>
                <th>{request.staffName}</th>
                <th
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        border: "none",
                        marginTop: "2%",
                    }}
                >
                    {status}
                    <ListIcon
                        color="primary"
                        style={{ marginRight: "10px", cursor: "pointer" }}
                        onClick={() => setShowStatusModal(true)}
                    />
                </th>

                <th>
                    {showCrimeRef ? (
                        <span onClick={() => setShowCrimeRef(!showCrimeRef)}>
                            {request.crimeReference}
                        </span>
                    ) : request.crimeReference ? (
                        <RemoveRedEyeIcon
                            color="primary"
                            onClick={() => setShowCrimeRef(!showCrimeRef)}
                        />
                    ) : (
                        <VisibilityOffIcon />
                    )}
                </th>
                <th>
                    {" "}
                    {!request.storageLocation ? (
                        <SdStorageIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                setShowStorageModal(true);
                            }}
                        />
                    ) : (
                        <Tooltip title="Click to copy">
                            <SdStorageIcon
                                style={{ cursor: "pointer" }}
                                color="success"
                                onClick={() => {
                                    navigator.clipboard.writeText(
                                        request.storageLocation
                                    );
                                }}
                            />
                        </Tooltip>
                    )}
                </th>
                <th>
                    {(request.images && request.images.length > 0) ||
                        request.signatureImg ? (
                        <CameraAltIcon
                            style={{ cursor: "pointer" }}
                            color="primary"
                            onClick={(e) => setShowImagesModal(true)}
                        />
                    ) : (
                        <CameraAltIcon />
                    )}
                </th>
                <th>
                    {" "}
                    {request.notes.length > 0 ? (
                        <SpeakerNotesIcon
                            color="primary"
                            style={{ cursor: "pointer" }}
                            onClick={() => setShowNotesModal(true)}
                        />
                    ) : (
                        <SpeakerNotesOffIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => setShowNotesModal(true)}
                        />
                    )}
                </th>
                <th>
                    <img
                        onClick={() => setOpenQR(true)}
                        src={qrCode}
                        style={{ cursor: "pointer" }}
                        width="60"
                    />
                </th>
                <th>
                    {filteredData.length > 0 && request.hasCCTVLink == false ? (
                        <Tooltip title="Click to Match Fuel">
                            <LocalGasStationIcon
                                onClick={() => setShowCCTVModal(true)}
                                color="primary"
                                style={{ cursor: "pointer", fontSize: "30px" }}
                            />
                        </Tooltip>
                    ) : (

                        <Tooltip title={request.hasCCTVLink ? "Fuel Has Been Matched" : "No Fuel Data Found"}>
                            <LocalGasStationIcon
                                color={request.hasCCTVLink ? "success" : "error"}
                                style={{ cursor: "pointer", fontSize: "30px" }}

                            />
                        </Tooltip>
                    )}

                </th>

                <th><Tooltip title="Investigation">
                            <PersonSearchIcon onClick={() => setShowInvestigationModal(true)}
                                style={{ cursor: "pointer", fontSize: "30px" }} color={request.hasInvestigation?"primary":""}
                            />
                        </Tooltip>
                </th>
            </tr >
            <Modal open={openQR}>
                <QRPrint
                    qrCode={qrCode}
                    setOpenQR={setOpenQR}
                    request={request}
                />
            </Modal>
            <Modal
                aria-labelledby="Status Change Modal"
                aria-describedby="Status Change Modal"
                open={showStatusModal}
            >
                <StatusModal
                    handleCloseStatus={setShowStatusModal}
                    cStatus={request.statusId}
                    reqId={request.requestId}
                    rel={rel}
                    statusList={statusList}
                    isDev={true}
                />
            </Modal>
            <Modal
                aria-labelledby="Storage Modal"
                aria-describedby="Storage Modal"
                open={showStorageModal}
            >
                <StorageModal
                    handleCloseStorage={setShowStorageModal}
                    cStatus={request.statusId}
                    id={request.requestId}
                    rel={rel}
                    request={request}
                />
            </Modal>
            <Modal
                aria-labelledby="Storage Modal"
                aria-describedby="Storage Modal"
                open={showNotesModal}
            >
                <NotesModal
                    handleCloseNotes={setShowNotesModal}
                    allNotes={request.notes}
                    id={request.requestId}
                    rel={rel}
                    isDev={true}
                    requestType={request.requestType}
                />
            </Modal>
            <Modal
                aria-labelledby="Vehicles Details Modal"
                aria-describedby="Vehicles Details Modal"
                open={showVehicleDetailModal}
            >
                <VehicleDetails
                    setShowVehicleDetailModal={setShowVehicleDetailModal}
                    registration={request.plate}
                    id={request.requestId}
                />
            </Modal>
            <Modal open={showImagesModal}>
                <PhotosModal
                    setShowImagesModal={setShowImagesModal}
                    images={request.signatureImg ? photosArr : request.images}
                />
            </Modal>
            <Modal open={showCCTVModal} >
                <CCTVModal
                    handleClose={() => setShowCCTVModal(false)}
                    request={request}
                    fuelData={fuelData}
                    rel={rel}
                />
            </Modal>
            <Modal open={showInvetigationModal} onClose={() => setShowInvestigationModal(false)}>
                    <InvestigationModal request={request} rel={rel} closeModal={()=>setShowInvestigationModal(false)}/>
            </Modal>
        </>
    );
}
