import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import "../eod.scss";
import Button from "@mui/material/Button";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40vw",
  height: "12vw",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
};

export default function DriveOffMod(props) {
  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Please confirm
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <div className="disc__modal">
            <h4>Are you sure there is no drive off?</h4>
          <Stack
            direction="row"
            spacing={2}
            sx={{ width: "25%", margin: "auto" }}
          >
            <Button
              variant="outlined"
              color="error"
              onClick={() => props.close()}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={() => props.handleNoDrive()}
            >
              Send
            </Button>
          </Stack>
        </div>
      </Typography>
    </Box>
  );
}
