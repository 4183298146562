//React
import { useState, useEffect } from "react";
import axios from "axios";
//Mui
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Alert, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
//Utils
import { decryptData } from "../../utils/cryptoUtils";
//Styling & images
import "./packers.scss";
import logo from "../../../images/logo.png";
 
export default function PackersLogin() {
    //Main
    const url = process.env.REACT_APP_API_URL;
    const nitroURL = process.env.REACT_APP_DEPPLOYED;
    //Packers
    const [packersList, setPackersList] = useState([]);
    const [selectedPacker, setSelectedPacker] = useState("");
    //Pin Code
    const [pinCode, setPinCode] = useState("");
    //Alerts
    const [alert, setAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");
    const [disable, setDisable] = useState(false);

    useEffect(() => {
        setPackersList([]);
        console.log(`${url}/roles/getPackers`);
        axios
            .get(`${url}/roles/getPackers`)
            .then((res) => {
                const packers = res.data.rows;
                for (let p = 0; p < packers.length; p++) {
                    let name = decryptData(
                        packers[p].firstName,
                        process.env.REACT_APP_EMP_KEY
                    );
                    let last = decryptData(
                        packers[p].lastName,
                        process.env.REACT_APP_EMP_KEY
                    );
                    setPackersList((packersList) => [
                        ...packersList,
                        { name: `${name} ${last}` },
                    ]);
                }
            })
            .catch((err) => {
                handleAlert(
                    "Could Not retrieve packer list - Please refresh",
                    "error",
                    5000
                );
                console.log(`Could not get the packers: ${err}`);
            });
    }, []);

    const handleAlert = (message, severity, duration = 4000) => {
        setDisable(true);
        setMessage(message);
        setSeverity(severity);
        setAlert(true);
        setTimeout(() => {
            setAlert(false);
            setMessage("");
            setSeverity("");
            setDisable(false);
        }, duration);
    };

    const handlePackerLogin = () => {
        if (selectedPacker === "") {
            handleAlert("Please select your name", "warning", 4000);
        } else if (selectedPacker === "") {
            handleAlert("Please enter the pin code", "warning", 4000);
        } else {
            let data = {
                name: selectedPacker,
                pin: parseInt(pinCode),
            };

            axios
                .post(`${nitroURL}/users/packers/login`, data)
                .then((res) => {
                    //Set user info to localstorage
                    let user = {
                        user: {
                            user: selectedPacker,
                            role: "Warehouse Packer",
                            sites: ["Hillington"],
                        },
                    };
                    localStorage.setItem("userDetails", JSON.stringify(user));
                    //Navigate to picklist
                    window.location.href = `https://warehouse.nitropos.co.uk/?token=${res.data.token}&user=${selectedPacker}&role=Packer&suppliers=${JSON.stringify(res.data.suppliers)}`;
                })
                .catch((err) => {
                    console.log(err);

                    if (err.response.data.msg === "Invalid credentials.") {
                    } else {
                        handleAlert(
                            "Something went wrong - Please try again",
                            "error",
                            5000
                        );
                    }
                });
        }
    };

    return (
        <main>
            <section className="packers_main">
                <img src={logo} alt="NitroPOS logo" />
                <h1>Packers login</h1>
                <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                        <InputLabel id="packer_select">
                            Select your name
                        </InputLabel>
                        <Select
                            id="packer_select"
                            onChange={(e) => setSelectedPacker(e.target.value)}
                            sx={{ width: "50vw", backgroundColor: "#fff" }}
                        >
                            {packersList &&
                                packersList.map((user, i) => {
                                    return (
                                        <MenuItem key={i} value={user.name}>
                                            {user.name}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                        <TextField
                            id="outlined-basic"
                            label="Enter Pin Code"
                            variant="outlined"
                            className="pin_input"
                            onChange={(e) => setPinCode(e.target.value)}
                            value={pinCode}
                        />
                        <div className="alert_section">
                            {alert ? (
                                <Alert severity={severity}>{message}</Alert>
                            ) : null}
                        </div>
                        <Button
                            variant="contained"
                            onClick={handlePackerLogin}
                            disabled={disable}
                            className="login_btn"
                        >
                            Login
                        </Button>
                    </FormControl>
                </Box>
            </section>
        </main>
    );
}
