import "./driveoff.scss";
import { useEffect, useState, useMemo } from "react";
import DriveOffForm from "./DriveoffForm";
import NMOPForm from "./nmop/NMOPForm";
import DriveOffDetails from "./DriveOffDetails";
import NMOPDetails from "./nmop/NMOPDetails";
import { getAllStores } from "../../../utils/Api";
import axios from "axios";

export default function DriveOff() {
    const url = process.env.REACT_APP_ANPR_URL;
    const [isDriveOff, setIsDriveOff] = useState(false);
    const [isNMOP, setIsNMOP] = useState(false);
    const [site, setSite] = useState("");
    const [siteName, setSiteName] = useState("");
    const [requests, setRequests] = useState([]);
    const [reload, setReload] = useState(false);
    const [statusList, setStatusList] = useState([]);
    const [driveOff, setDriveOff] = useState([]);
    const [nmop, setNmop] = useState([]);

    useMemo(() => {
        // TODO: ADD THIS TO UTILS
        axios
            .get(`${url}/cctv-system/statuses`)
            .then((res) => {
                setStatusList(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(async () => {
        let getSite = localStorage.getItem("site");
        let localSite = JSON.parse(getSite);
        setSiteName(localSite);
        let siteEposId;
        await getAllStores()
            .then((data) => {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].siteName == localSite) {
                        siteEposId = data[i].eposId;
                        setSite(siteEposId);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });

        axios
            .get(
                `${url}/cctv-system/submissions/details/site/${siteEposId}?both=true`
            )
            .then((res) => {
                // setRequests(res.data);
                setDriveOff(res.data.driveoff);
                setNmop(res.data.nmop);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [reload]);

    const handleType = (type) => {
        if (type === "NMOP") {
            setIsNMOP(true);
            setIsDriveOff(false);
        } else if (type === "DRIVE OFF") {
            setIsNMOP(false);
            setIsDriveOff(true);
        } else if (type === "Please select") {
            setIsNMOP(false);
            setIsDriveOff(false);
        }
    };

    const rel = () => {
        setReload(!reload);
    };

    return (
        <main className="driveoff_container">
            <section className="driveoff">
                <h1>DRIVE OFF REQUESTS</h1>
                <h2>
                    Please complete this form if a Drive off/NMOP has happened
                    at {siteName}
                </h2>

                <section className="tables_container">
                    <section className="form_options">
                        <label>
                            Is this for a Drive off or a No Means Of Payment
                            (NMOP)?
                        </label>
                        <select onChange={(e) => handleType(e.target.value)}>
                            <option>Please select</option>
                            <option>DRIVE OFF</option>
                            <option>NMOP</option>
                        </select>

                        {isDriveOff ? (
                            <DriveOffForm
                                site={site}
                                rel={rel}
                                siteName={siteName}
                            />
                        ) : isNMOP ? (
                            <NMOPForm
                                site={site}
                                rel={rel}
                                siteName={siteName}
                            />
                        ) : null}
                    </section>
                    <section className="tables_driveOff">
                        {driveOff.length > 0 ? (
                            <DriveOffDetails
                                site={site}
                                siteName={siteName}
                                rel={rel}
                                statusList={statusList}
                                driveoff={driveOff}
                            />
                        ) : (
                            <h1>No Drive Off to show</h1>
                        )}
                        {nmop.length > 0 ? (
                            <NMOPDetails
                                site={site}
                                siteName={siteName}
                                rel={rel}
                                requests={requests}
                                statusList={statusList}
                                nmop={nmop}
                            />
                        ) : (
                            <h1>No NMOP to show</h1>
                        )}
                    </section>
                </section>
            </section>
        </main>
    );
}
