import React from "react";
import { Box, Typography, Button } from "@mui/material";

const FinalCheckModal = (props) => {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 300,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
        borderRadius: "10px",
        
    };

    const closeModal = () => {
        props.handleClose();
    };
    return (
        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                WARNING
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                One of your store zones does not have any products in it. Are
                you sure you want to proceed?
            </Typography>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    marginTop: "12px",
                }}
            >
                <Button
                    variant="contained"
                    onClick={() => props.finaliseStock()}
                >
                    Yes
                </Button>
                <Button
                    variant="contained"
                    color="error"
                    onClick={() => closeModal()}
                >
                    No
                </Button>
            </div>
        </Box>
    );
};

export default FinalCheckModal;
