import "../staffManagement.scss";
import { useState, useEffect } from "react";
import axios from "axios";
import { getEmployeesByCompany, getCompanies } from "../../utils/Api"
import { decryptData } from "../../utils/cryptoUtils"
import moment from "moment";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import DatePicker from "react-date-picker";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Tick from '../../animations/Tick'
import Cross from "../../animations/Cross";
import Loader from "../../animations/Loader";


// const url = "http://10.0.0.174:4030"
const url = process.env.REACT_APP_API_URL;
export default function StaffAttendance(props) {

    const { sites } = props.managerInfo;

    const [managedSites, setManagedSites] = useState([]);
    const [staff, setStaff] = useState([]);
    const [shifts, setShifts] = useState([]);

    const [siteName, setSiteName] = useState("");
    const [site, setSite] = useState({});
    const [token, setToken] = useState("");

    const [selectedStaff, setSelectedStaff] = useState(null);

    const [openShift, setOpenShift] = useState(false);

    const [alert, setAlert] = useState(false);
    const [alertText, setAlertText] = useState("");
    const [selectedShift, setSelectedShift] = useState({})

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [currEmpIndex, setCurrEmpIndex] = useState(-1);


    useEffect(async () => {

        let getToken = localStorage.getItem("auth-token");
        setToken(getToken);

        const getSites = async () => {

            const comp = await getCompanies(getToken);

            if (props.managerInfo.sites == "HQ") {
                setManagedSites(comp);
            } else {
                var sites = [];
                for (let index = 0; index < props.managerInfo.sites.length; index++) {
                    const site = props.managerInfo.sites[index];

                    const fIndex = comp.findIndex((sComp) => sComp.siteName.toLowerCase() == site.toLowerCase());
                    if (fIndex >= 0) {
                        sites.push(comp[fIndex])
                    }

                }
                setManagedSites(sites);
            }
        }

        getSites();
    }, []);

    const handleSiteSelection = async (id) => {

        var findIndex = managedSites.findIndex((fSite) => fSite.eposId == id)

        if (findIndex != -1) {

            const foundSite = managedSites[findIndex];

            setSite(foundSite);
            setSiteName(foundSite.siteName);

        }
    };


    const handleStaffSelection = (id) => {

        var findIndex = staff.findIndex((fStaff) => fStaff.employeeId == id);
        if (findIndex != -1) {
            setCurrEmpIndex(findIndex);
            setSelectedStaff(staff[findIndex]);

            // GET SHIFTS FOR PERSON
            setShifts(staff[findIndex].shifts);

            if (staff[findIndex].shifts.length == 1) {
                //setSelectedShift(staff[findIndex].shifts[0])
                setSelectedShift(staff[findIndex].shifts[0]);
                setOpenShift(true);

            }
        } else {
            setShifts([]);
        }
    }

    const handleDateSelect = async (date) => {

        setStaff([]);
        setShifts([]);
        if (!moment().isAfter(date)) {
            handleHeaderAlert("You cannot select dates in the future")

        } else {
            setSelectedDate(date);
            const data = {
                companyId: site.companyId,
                date: moment(date).format("YYYY-MM-DD"),
            }

            await axios.post(`${url}/rota/published/employees`, data, { headers: { "Content-Type": "application/json", "x-auth-token": token } })
                .then((res) => {

                    if (res.data.length == 0) {
                        handleHeaderAlert("No Uncomplete shifts found");
                    } else {
                        let staff = [];
                        for (let index = 0; index < res.data.length; index++) {
                            const staffEnc = res.data[index];

                            var staffObj = {
                                name: "",
                                employeeId: staffEnc.employeeDetailsId,
                                shifts: staffEnc.shifts
                            }

                            let fName = decryptData(staffEnc.firstName,
                                process.env.REACT_APP_EMP_KEY
                            )

                            let lName = decryptData(staffEnc.lastName,
                                process.env.REACT_APP_EMP_KEY
                            )

                            staffObj.name = fName + " " + lName;
                            staff.push(staffObj);
                        }

                        let sortedStaff = staff.sort((a, b) => {
                            if (a.name < b.name) {
                                return -1
                            }
                            if (a.name > b.name) {
                                return 1
                            }
                            return 0
                        })

                        setStaff(sortedStaff);

                    }

                }).catch((err) => {
                    handleHeaderAlert("An error has occured trying to get the employees and shifts, please try again later");
                })

        }

    }

    const handleShiftSelect = async (id) => {
        setSelectedShift(shifts[id]);
        setOpenShift(true);
    }

    const handleClose = async () => {

        setSelectedShift({});
        setOpenShift(false);
        handleDateSelect(selectedDate);

    }

    const handleHeaderAlert = async (text) => {
        setAlert(true);
        setAlertText(text);

        setTimeout(() => {
            setAlert(false);
            setAlertText("")
        }, 2500);
    }

    return (
        <section className="staff__management">

            {siteName != "" ? (
                <>
                    <h1>
                        Staff Attendance for: <span>{siteName}</span>
                    </h1>

                    {alert ? (
                        <Alert
                            severity="error"
                            sx={{
                                width: "80vw",
                                left: "10vw",
                                position: "absolute",
                                top: "35vh",
                                zIndex: "10",
                            }}
                        >
                            <AlertTitle>Error</AlertTitle>
                            <strong>{alertText}</strong>
                        </Alert>
                    ) : (
                        null
                    )}

                    <DatePicker
                        onChange={(e) => handleDateSelect(e)}
                        selected={selectedDate}
                        value={selectedDate}
                        maxDate={new Date()}
                        popperPlacement="bottom"

                        style={{zIndex:"-11"}}
                    />

                    {!!staff.length > 0 ? (

                        <select onChange={(e) => handleStaffSelection(e.target.value)}
                            style={{ margin: "2vh 0 0 0" }}>
                            <option>--Select Staff--</option>
                            {staff.map(function (staff, index) {
                                return (
                                    <option value={staff.employeeId}>{staff.name}</option>
                                );
                            })}
                        </select>
                    ) : (
                        null
                    )}

                    {shifts.length > 0 ? (
                        <>
                            <p>Shifts for : <span>{selectedStaff.name}</span>
                            </p>
                            <table>
                                <tr style={{ background: "#f2f2f2" }}>
                                    <th>Start Time</th>
                                    <th>End Time</th>
                                    <th>Edit Shift</th>
                                </tr>

                                {shifts.map(function (shift, index) {

                                    var bgColour = "#fff"
                                    if (index % 2) {
                                        bgColour = "#f2f2f2"
                                    }

                                    return (
                                        <tr onClick={() => handleShiftSelect(index)} style={{ textAlign: "center", background: bgColour }}>
                                            <td>{shift.actualStart != null ? moment(shift.actualStart).format("DD-MM-YYYY HH:mm:ss") : moment(shift.startTime).format("DD-MM-YYYY HH:mm:ss")}</td>
                                            <td>{shift.actualEnd != null ? moment(shift.actualEnd).format("DD-MM-YYYY HH:mm:ss") : moment(shift.endTime).format("DD-MM-YYYY HH:mm:ss")}</td>
                                            <td><ModeEditIcon /></td>
                                        </tr>
                                    );
                                })}
                            </table>

                            <Modal open={openShift}>
                                <AttendanceModel
                                    shift={selectedShift}
                                    employee={selectedStaff}
                                    close={handleClose}
                                    user={props.managerInfo.user.user}
                                    token={token}
                                />
                            </Modal>
                        </>
                    ) : (
                        null
                    )}

                </>
            ) : (
                <>
                    <h1>Staff Attendance</h1>
                    <select onChange={(e) => handleSiteSelection(e.target.value)}>
                        <option>--Select Site--</option>
                        {managedSites.map(function (site, index) {
                            return (
                                <option value={site.eposId}>{site.siteName}</option>
                            );
                        })}
                    </select>
                </>

            )}

        </section>
    )

}



const AttendanceModel = ({ shift, employee, close, user, token }) => {

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "70vw",
        bgcolor: "background.paper",
        border: "none",
        borderRadius: "1rem",
        boxShadow: 24,
        p: 4,
        maxHeight: "80vh",
        overflowY: "scroll",
        "@media(max-width: 690px)": {
            width: "90vw",
            maxHeight: "90vh",
            p: 1,
        },
    };

    const [late, setLate] = useState(false);
    const [early, setEarly] = useState(false);
    const [absent, setAbsent] = useState(false);
    const [sick, setSick] = useState(false);
    const [noShow, setNoShow] = useState(false);

    const [alert, setAlert] = useState(false);
    const [alertText, setAlertText] = useState("");

    const [selectedDate, setSelectedDate] = useState("");
    const [validated, setValidated] = useState(false);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [failed, setFailed] = useState(false);



    const HandleBack = () => {

        const rest = async () => {
            setAbsent(false);
            setLate(false);
            setEarly(false);
            setSick(false);
            setNoShow(false);
            setValidated(false);

            setSelectedDate("");
        }
        // style={{ alignSelf: "center" }}
        return (
            <Button onClick={() => { rest() }} style={{ margin: "auto" }} >BACK?</Button>
        )
    }

    const HandleConfirm = () => {

        return (
            <Button
                onClick={() => { setValidated(true) }}
                style={{ alignContent: "center", flexWrap: "nowrap", maxHeight: "40px", padding: "5vh" }}
                variant="contained"
                color="error"

            >
                ARE YOU SURE {employee.name.toUpperCase()} {sick ? ("IS SICK") : ("HASNT SHOWN UP FOR THEIR SHIFT")}
            </Button>
        )
    }

    const handleModelAlert = async (text) => {
        setAlert(true);
        setAlertText(text);

        setTimeout(() => {
            setAlert(false);
            setAlertText("")
        }, 5000);
    }

    const handleResponse = async (resp) => {
        if (resp) {
            setSuccess(true);
            setTimeout(() => {
                setSuccess(false);
                close()
            }, 2500);

        } else {
            setFailed(true);
            setTimeout(() => {
                setFailed(false);
            }, 2500);
        }

    }

    const handleChangeDate = (date) => {


        if (late) {

            if (moment(date).isAfter(shift.startTime)) {
                if (moment(date).isBefore(shift.endTime)) {
                    setSelectedDate(date);
                    setValidated(true);
                } else {
                    handleModelAlert(`The time selected must be before ${moment(shift.endTime).format("DD-MM-YYYY HH:mm")}`)
                }
            } else {
                handleModelAlert(`The time selected must be after ${moment(shift.startTime).format("DD-MM-YYYY HH:mm")}`)
                setSelectedDate(shift.startTime);
                setValidated(false);
            }
        } else if (early) {
            if (moment(date).isBefore(shift.endTime)) {
                if (moment(date).isAfter(shift.startTime)) {
                    setSelectedDate(date);
                    setValidated(true);;
                } else {
                    handleModelAlert(`The time selected must be after ${moment(shift.startTime).format("DD-MM-YYYY HH:mm")}`)
                }
            } else {
                handleModelAlert(`The time selected must be before ${moment(shift.endTime).format("DD-MM-YYYY HH:mm")}`)
                setSelectedDate(shift.endTime);
                setValidated(false);
            }
        }
    }


    const handleSubmit = async () => {

        setLoading(true);

        let data = {
            shiftId: shift.shiftId,
            actualStart: null,
            actualEnd: null,
            user: user,
            leaveType: null,
            reason: null,
            noShow: null
        }

        if (late) {
            data.actualStart = moment(selectedDate).format("YYYY-MM-DD HH:mm:ss");
        } else if (early) {
            data.actualEnd = moment(selectedDate).format("YYYY-MM-DD HH:mm:ss");
        } else if (absent) {

            data.noShow = true;

            if (sick) {
                data.leaveType = 3
                data.reason = "Employee marked as sick through Staff Attendance"
            }

        }

        await axios.post(`${url}/rota/employee/update`, data, { headers: { "Content-Type": "application/json", "x-auth-token": token } })
            .then((res) => {
                setLoading(false);
                if (res.data) {
                    handleResponse(true);
                } else {
                    handleResponse(false);
                }
            })
            .catch((err) => {
                setLoading(false);
                handleResponse(false);
            })

    }





    return (

        <Box sx={style} className="all_modal">
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Selected Shift
            </Typography>
            <div style={{ justifyContent: "space-evenly", display: "flex", flexWrap: "wrap", margin: "2vh 0 2vh 0" }}>
                <p style={{ margin: "1vh 0 1vh 0" }}>{employee.name}</p>
                <p style={{ margin: "1vh 0 1vh 0" }}>Start Time : {moment(shift.startTime).format("DD-MM-YYYY HH:mm:ss")}</p>
                <p style={{ margin: "1vh 0 1vh 0" }}>End Time: {moment(shift.endTime).format("DD-MM-YYYY HH:mm:ss")}</p>
            </div>
            {alert ? (
                <Alert
                    severity="error"
                    sx={{
                        //width: "60vw",
                        left: "10vw",
                        //position: "absolute",
                        top: "35vh",
                        zIndex: "10",
                        alignContent: "center"
                    }}
                    style={{ alignSelf: "center" }}
                >
                    <AlertTitle>Error</AlertTitle>
                    <strong>{alertText}</strong>
                </Alert>
            ) : (
                null
            )}



            {!absent && !late && !early ? (

                <>
                    <p style={{ textAlign: "center" }}>Was the Employee :</p>
                    <div style={{ textAlign: "center", justifyContent: "space-evenly", alignItems: "center", display: "flex", flexWrap: "wrap" }}>

                        <Button
                            onClick={() => { setAbsent(true); }}
                            variant="contained"
                            style={{ maxHeight: "40px" }}
                            color="info"
                        >
                            Absent
                        </Button>
                        <Button
                            onClick={() => { setLate(true); setSelectedDate(shift.startTime); }}
                            variant="contained"
                            style={{ maxHeight: "40px" }}
                            color="info"
                        >
                            Late Arrival
                        </Button>
                        <Button
                            onClick={() => { setEarly(true); setSelectedDate(shift.endTime) }}
                            variant="contained"
                            style={{ maxHeight: "40px" }}
                            color="info"
                        >
                            Leave Early
                        </Button>
                    </div>
                </>
            ) : (
                <>
                    {absent ? (
                        <>



                            {!sick && !noShow ? (
                                <>
                                    <p style={{ textAlign: "center" }}>What kind of leave did the person have?</p>
                                    <div style={{ textAlign: "center", justifyContent: "space-evenly", alignItems: "center", display: "flex", flexWrap: "wrap" }}>

                                        <Button
                                            onClick={() => { setSick(true); }}
                                            variant="contained"
                                            style={{ maxHeight: "40px" }}
                                            color="info"
                                        >
                                            Sick
                                        </Button>

                                        <Button
                                            onClick={() => { setNoShow(true) }}
                                            variant="contained"
                                            style={{ maxHeight: "40px" }}
                                            color="info"
                                        >
                                            No Show
                                        </Button>

                                    </div>
                                </>
                            ) : (
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <HandleConfirm />
                                    <br />
                                </div>
                            )}


                        </>
                    ) : (
                        null
                    )}

                    {late ? (
                        <>

                            <table style={{ width: "100%", textAlign: "center", alignSelf: "center", margin: "2vh 0 2vh 0" }}>
                                <tr><th>Actual Start Time</th><th>Time Employee Arrived</th></tr>
                                <tr><td>{moment(shift.startTime).format("YYYY-MM-DD HH:mm")}</td><td><input type={"datetime-local"} value={selectedDate} onChange={(e) => handleChangeDate(e.target.value)} style={{ margin: "0%" }} maxDate={new Date()} /></td></tr>
                            </table>
                        </>
                    ) : (
                        null
                    )}

                    {early ? (
                        <>
                            <table style={{ width: "100%", textAlign: "center", alignSelf: "center", margin: "2vh 0 2vh 0" }}>
                                <tr><th>Actual End Time</th><th>Time Employee Left</th></tr>
                                <tr>
                                    <td>{moment(shift.endTime).format("YYYY-MM-DD HH:mm")}</td>
                                    <td>
                                        <input
                                            type={"datetime-local"}
                                            value={selectedDate} onChange={(e) => handleChangeDate(e.target.value)}
                                            style={{ margin: "0%" }}
                                            maxDate={new Date()}
                                        />
                                    </td>
                                </tr>
                            </table>
                        </>
                    ) : (
                        null
                    )}

                </>
            )}

            {loading ? (
                <Loader />
            ) : (
                null
            )}

            {success ? (
                <Tick />
            ) : (null)}

            {failed ? (
                <Cross />
            ) : (null)}

            <div style={{ display: "flex", flexDirection: "row", margin: "3vh 0 1vh 0" }}>

                <Button
                    variant="contained"
                    color="error"
                    onClick={() => close(false)}
                    style={{ display: "flex", alignSelf: "flex-end", justifyContent: "space-evenly" }}
                >
                    Close
                </Button>

                {absent || late || early ? (
                    <>
                        <HandleBack />
                        {validated ? (
                            <Button
                                variant="contained"
                                color="success"
                                onClick={() => handleSubmit()}
                                style={{ justifyItem: "flex-end" }}

                            >
                                Confirm
                            </Button>
                        ) : (
                            <div style={{ width: "96px" }}>
                            </div>
                        )}

                    </>
                ) : (
                    null
                )}
            </div>

        </Box >

    )



}