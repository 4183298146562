export default function ProductHistory({ productHistory }) {
    return (
        <section className="details">
            <div className="product_hist">
                <table className="prod_hist_table">
                    <thead>
                        <tr>
                            <th>Site</th>
                            <th>Received</th>
                            <th>Wasted</th>
                            <th>Current Stock</th>
                            <th>Sales</th>
                            <th>Refunds</th>
                        </tr>
                    </thead>
                    <tbody>
                        {productHistory.map((product) => {
                            return (
                                <tr key={product.siteId}>
                                    <td data-label="Site">
                                        {product.siteName}
                                    </td>
                                    <td data-label="Received">
                                        {product.endData.totalReceived}
                                    </td>
                                    <td data-label="Wasted">
                                        {product.wastageAmount === null ? (
                                            <>0</>
                                        ) : (
                                            product.wastageAmount
                                        )}
                                    </td>
                                    <td data-label="Current">
                                        {product.currentStock}
                                    </td>
                                    <td data-label="Sales">TO BE ADDED</td>
                                    <td data-label="Refudns">TO BE ADDED</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>
    );
}
