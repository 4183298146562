import { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Alert from "@mui/material/Alert";
import { Margin } from "@mui/icons-material";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
};



export default function ScanModel({
    user,
    url,
    close
}) {

    const [barcode, setBarcode] = useState("");
    const [warming, setWarning] = useState(false)
    const [message, setMessage] = useState("")
    const [level, setLevel] = useState("")

    function onSubmit() {

        if (barcode.length > 5) {
            let user = JSON.parse(localStorage.getItem("userDetails"))
            console.log("USER: ", user.user.user)
            const data = {
                user: user.user.user,
                barcode: barcode
            }

            console.log(data)
            console.log(`${url}/scan`)

            axios.post(`${url}/promotions/scan`, data)
                .then((res) => {
                    if (res.data) {
                        setBarcode("")

                        setWarning(true);
                        setMessage("Uploaded");
                        setLevel("success")

                        setTimeout(() => {
                            setWarning(false);
                            setMessage("");
                            setLevel("")
                        }, 3000);

                    } else {
                        console.log("NOT SCANNED")

                        setWarning(true);
                        setMessage("Not Uploaded");
                        setLevel("error")

                        setTimeout(() => {
                            setWarning(false);
                            setMessage("");
                            setLevel("")
                        }, 3000);
                    }
                }).catch((err) => {
                    console.log(err)

                    setWarning(true);
                    setMessage("Not Uploaded");
                    setLevel("error")

                    setTimeout(() => {
                        setWarning(false);
                        setMessage("");
                    }, 3000);

                })
        }



    }

    return (
        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h4">
                <h2>Start Scanning</h2>
            </Typography>
            <div style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "column",
                padding: "10px",
            }}>
                <input
                    style={{
                        margin: "10px 0",
                        border: "1px solid black",
                        padding: "10px"
                    }}
                    type="text"
                    autoFocus={true}
                    placeholder="Ready.. Steady.. Scan!"
                    onChange={(e) => setBarcode(e.target.value)}
                    value={barcode}
                    onKeyDown={(e) => { if (e.key === "Enter") { onSubmit() } }}
                />

                <Button
                    style={{ margin: "10px 0" }}
                    onClick={() => onSubmit()}
                    color="success"
                    variant="contained"
                >
                    Save
                </Button>

                <Button
                    style={{ margin: "10px 0" }}
                    onClick={() => close()}
                    color="error"
                    variant="contained"
                >
                    Close
                </Button>

                {warming ?
                    <Alert severity={level}>{message}</Alert> : <></>
                }

            </div>

        </Box>
    )
}