import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70vw",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
  maxHeight: "70vh",
  overflowY: "scroll",
  "@media(max-width: 690px)": {
    width: "90vw",
    maxHeight: "90vh",
  },
};
function ContractedHoursUsersModel({
  overOrLessContractedHoursStaff,
  setShowContractedHours,
  setPublishModal,
}) {
  return (
    <>
      <Box sx={style} className="edit_shift_modal">
        <Typography id="modal-modal-title" variant="h4" component="h2">
          Over or Less Contracted hours staff(s) list
        </Typography>
        <p>
          There are some over or less contracted hours staff(s) in this rota,
          are you sure you want to continue?
        </p>
        <div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">First Name</TableCell>
                  <TableCell align="left">Last Name</TableCell>
                  <TableCell align="left">Position </TableCell>
                  <TableCell align="left">Company</TableCell>
                  <TableCell align="left">Contracted Hours</TableCell>
                  <TableCell align="left">Hours scheduled </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {setShowContractedHours &&
                  overOrLessContractedHoursStaff?.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="left">{row.firstName}</TableCell>
                      <TableCell align="left">{row.lastName}</TableCell>
                      <TableCell align="left">{row.position}</TableCell>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="left">{row.contractedHours}</TableCell>
                      <TableCell align="left">{row.TotalHoursWorked}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "3%",
          }}
        >
          <Button
            variant="contained"
            color="error"
            onClick={() => setShowContractedHours(false)}
            disable={true}
          >
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            id="addNote-btn"
            onClick={() => {
              setShowContractedHours(false);
              setPublishModal(true);
            }}
          >
            Send for review
          </Button>
        </div>
      </Box>
    </>
  );
}

export default ContractedHoursUsersModel;
