//Styling
import "./purchase_orders.scss";
//Mui
import { Button } from "@mui/material";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import SaveIcon from "@mui/icons-material/Save";

interface Props {
    handleSaveForLater: () => void;
    handleValidation: () => void;
    handleCancelOrder: () => void;
    setCancelDialog: React.Dispatch<React.SetStateAction<boolean>>;
    handleSubmit: () => any;
    disable: boolean;
    isSiteLogin: boolean;
    approvalPo?: any;
    adminSide?: boolean;
}

export default function PurchaseOrderBtns({
    handleSaveForLater,
    handleValidation,
    setCancelDialog,
    handleSubmit,
    disable,
    isSiteLogin,
    approvalPo,
    adminSide,
}: Props) {
    return (
        <section id="po_btns" className="purchase_order_btns">
            <Button
                variant="contained"
                color="warning"
                disabled={disable}
                endIcon={<WatchLaterIcon />}
                onClick={handleSaveForLater}
            >
                Save For Later
            </Button>

            <Button
                variant="contained"
                color="success"
                disabled={disable}
                endIcon={<SaveIcon />}
                onClick={approvalPo ? handleValidation : handleSubmit}
            >
                {isSiteLogin ? "Save and Send" : "Create Picklist"}
            </Button>
            {approvalPo ? (
                <Button
                    variant="contained"
                    color="error"
                    disabled={disable}
                    onClick={() => setCancelDialog(true)}
                >
                    Cancel Order
                </Button>
            ) : null}
        </section>
    );
}
