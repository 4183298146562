import IndvAlert from "./IndvAlert";

export default function Alerts(props) {
    const alerts = props.alerts;

    return (
        <section className="alerts_main">
            <h1>{props.severity} alerts</h1>
            {props.alerts.length === 0 ? (
                <h4>No alerts to show</h4>
            ) : (
                <table>
                    <thead>
                        <tr>
                            <th>Sender</th>
                            <th>Site</th>
                            <th>Description</th>
                            <th>Date Sent</th>
                            <th>Action Taken</th>
                            <th>Images</th>
                            <th>Mark as read</th>
                        </tr>
                    </thead>
                    <tbody>
                        {alerts.map((alert) => {
                            return (
                                <IndvAlert
                                    alert={alert}
                                    readAlert={props.readAlert}
                                />
                            );
                        })}
                    </tbody>
                </table>
            )}
        </section>
    );
}
