import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

export default function IndvHoagiesProducts(props) {
    const product = props.item
    const index = props.index
  return (
    <tr>
      <td>{product.name}</td>
      <td>
        <input type="number" pattern="[0-9]*" min={0} onChange={(e) => props.handleQtyChange(e.target.value, index)} id={index}/>
      </td>
      <td><RemoveCircleIcon color="primary" onClick={() => props.handleRemove(product.productCode)}/></td>
    </tr>
  );
}
