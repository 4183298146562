//COMPONENTS
import { Bar } from "./graphs/Bar.tsx";
import { Line } from "./graphs/Line.tsx";
import { Pie } from "./graphs/Pie.tsx";
import { Heat } from "./graphs/Heat.tsx";
//MUI
import Card from "@mui/material/Card";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";

interface Props {
    graph: "line" | "bar" | "pie" | "hbar" | "heat" | "waste" | "header";
    series: any[];
    xAxis: string[];
    name: string;
    start?: string;
    end?: string;
    selectedSites?: any;
    categoryIds?: number[];
    money?: boolean;
    waste?: boolean;
    breakdownURL?: string;
    innerBreakdownURL?: string;
}

export function Headline({
    graph,
    xAxis,
    series,
    name,
    start,
    end,
    selectedSites,
    categoryIds,
    money,
    waste,
    breakdownURL,
    innerBreakdownURL,
}: Props): React.JSX.Element {
    return (
        <Card className="headline">
            {" "}
            <h1 className="headline_heading">{name}</h1>
            {graph === "header" ? (
                <section className="header_section">
                    <div className="header_sales">
                        <h3>
                            <span className="when">Previous</span>
                            <span className="previous">
                                {" "}
                                {money ? "£" : null}
                                {series[0].previous.value
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </span>
                        </h3>{" "}
                        <p>|</p>
                        <h3>
                            {" "}
                            <span className="when">Current</span>
                            <span className="current">
                                {" "}
                                {money ? "£" : null}
                                {series[0].current.value
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </span>
                        </h3>
                    </div>
                    <div className="header_icon">
                        {parseFloat(series[0].current.value) >
                        parseFloat(series[0].previous.value) ? (
                            <TrendingUpIcon
                                fontSize="large"
                                color={!waste ? "success" : "error"}
                                className="trend_line"
                            />
                        ) : parseFloat(series[0].current.value) ==
                          parseFloat(series[0].previous.value) ? (
                            <TrendingFlatIcon
                                fontSize="large"
                                className="trend_line"
                            />
                        ) : (
                            <TrendingDownIcon
                                fontSize="large"
                                color={!waste ? "error" : "success"}
                                className="trend_line"
                            />
                        )}
                        <p>
                            {(
                                ((series[0].current.value -
                                    series[0].previous.value) /
                                    series[0].previous.value) *
                                100
                            ).toFixed(2)}
                            %
                        </p>
                    </div>
                </section>
            ) : null}
            {graph === "bar" ? (
                <Bar
                    xAxis={xAxis}
                    series={series}
                    breakdownURL={breakdownURL}
                    graph="bar"
                    start={start}
                    end={end}
                    selectedSites={selectedSites}
                />
            ) : null}
            {graph === "hbar" ? (
                <Bar
                    xAxis={xAxis}
                    series={series}
                    breakdownURL={breakdownURL}
                    graph="hbar"
                    start={start}
                    end={end}
                    selectedSites={selectedSites}
                    innerBreakdownURL={innerBreakdownURL}
                />
            ) : null}
            {graph === "line" ? <Line xAxis={xAxis} series={series} /> : null}
            {graph === "pie" ? <Pie xAxis={xAxis} series={series} /> : null}
            {graph === "waste" ? (
                <Pie
                    xAxis={xAxis}
                    series={series}
                    waste={true}
                    start={start}
                    end={end}
                    selectedSites={selectedSites}
                    categoryIds={categoryIds}
                    breakdownURL={breakdownURL}
                    innerBreakdownURL={innerBreakdownURL}
                />
            ) : null}
            {graph === "heat" ? <Heat series={series} /> : null}
        </Card>
    );
}
