import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";

const style = {
    ["@media(max-width: 700px)"]: {
        width: "50vw",
        marginLeft: "-85%",
        marginTop: "3%",
        marginBottom: "3%"
    }
}

export default function IndvStockProduct(props) {
    const [prod,setProd]=useState({})
    const product = props.product;
    const zone = props.zone;
    const index = props.index;
    const site = props.site;

    useEffect(()=>{
        setProd(props.product)
    },[props])

    //Alerts
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");

    const handleQuantityUpdate = (val) => {
        if (val < 0 ) {
            document.getElementById("quantity-input").style.display = "none";
            document.getElementById("quantity-input").value = "";
            setMessage("Please ensure quantity is not a negative number");
            setSeverity("warning");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
                setSeverity("");
                setMessage("");
                document.getElementById(
                    "quantity-input"
                ).style = `display: flex;
                margin: 0 auto 3% auto`;
            }, 3000);
        } else {
            props.getProductQuantity(val, prod.barcode, zone);
        }
    };

    const handleDelete = (event) => {
        if (event.keyCode == 8){
            props.getProductQuantity("0", prod.barcode, zone)
        }
    }

    return (
        <div className="area_item">
            <table>
                <thead>
                    <tr>
                        <td>Product</td>
                        <td>Barcode</td>
                        <td>Quantity</td>
                        <td>Delete</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{prod.name}</td>
                        <td>{prod.barcode}</td>

                        <td>
                            <input
                                id="quantity-input"
                                type="number"
                                pattern="[0-9]*"
                                onChange={(e) => handleQuantityUpdate(e.target.value)}
                                defaultValue={prod.quantity}
                                onKeyDown={handleDelete}
                            />
                            {showAlert ? (
                                <Alert
                                    severity={severity}
                                    sx={style}
                                >
                                    {message}
                                </Alert>
                            ) : null}
                        </td>

                        <td>
                            <DeleteForeverIcon
                                onClick={() =>
                                    props.deleteFromArray(
                                        zone,
                                        prod.productCode
                                    )
                                }
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
