import { useState, useEffect } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Tick from "../animations/Tick";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
};

export default function IndvRequest(props) {
  const request = props.request;
  const [token, setToken] = useState("");
  //alerts
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showTick, setShowTick] = useState(false);
  const [reason, setReason] = useState("");
  //modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  //mobile look
  const [isMobile, setIsMobile] = useState(false);

  const config = {
    headers: { "x-auth-token": token },
  };

  useEffect(() => {
    //checks if it is a mobile for the change of style
    if (window.innerWidth < 690) {
      setIsMobile(true);
    }
    let getToken = localStorage.getItem("auth-token");
    setToken(getToken);
  }, [window.innerWidth]);

  //to approve the holidays
  const handleApproval = async (status) => {
    if (status === 3) {
      if (reason === "") {
        setMessage("Please insert a reason before sending");
        document.getElementById("modal_alert").style.display = "flex";
        setTimeout(() => {
          setMessage("");
          document.getElementById("modal_alert").style.display = "none";
        }, 3000);
      } else {
        let data = {
          status: status,
          id: request.holidayId,
          user: props.user,
          reason: reason,
        };
        axios
          .post(
            "https://hr-be.racetrackpitstop.co.uk/holiday/admin/changeStatus/new",
            data,
            config
          )
          .then((res) => {
            if (res.data === true) {
              props.rel();
              handleClose();
            } else {
              //if response is false request has not updated on DB
              setMessage("Something went wrong, please try again");
              document.getElementById("modal_alert").style.display = "flex";
              setTimeout(() => {
                setMessage("");
                document.getElementById("modal_alert").style.display = "none";
              }, 3500);
            }
          })
          .catch((err) => {
            if (err.response.data.error) {
              setMessage(err.response.data.error);
              setSeverity("error");
              setShowAlert(true);
              setTimeout(() => {
                setShowAlert(false);
                setSeverity("");
                setMessage("");
              }, 4000);
            } else {
              setMessage("Something went wrong, please try again");
              document.getElementById("modal_alert").style.display = "flex";
              setTimeout(() => {
                setMessage("");
                document.getElementById("modal_alert").style.display = "none";
              }, 3500);
            }
          });
      }
    } else {
      if (isMobile) {
        document.getElementById("aproval_btns").style.display = "none";
      } else {
        document.getElementById("approve_btn").style.display = "none";
      }
      let data = {
        status: status,
        id: request.holidayId,
        user: props.user,
        reason: reason,
      };
      await axios
        .post(
          "https://hr-be.racetrackpitstop.co.uk/holiday/admin/changeStatus/new",
          data, config
        )
        .then((res) => {
          if (res.data === true) {
            setShowTick(true);
            setTimeout(() => {
              props.rel();
              setShowTick(false);
              if (isMobile) {
                document.getElementById("aproval_btns").style.display = "flex";
              } else {
                document.getElementById("approve_btn").style.display =
                  "inline-block";
              }
            }, 3000);
          } else {
            //if response is false request has not updated on DB
            setSeverity("warning");
            setMessage("Something went wrong, please try again");
            setShowAlert(true);
            setTimeout(() => {
              setShowAlert(false);
              setSeverity("");
              setMessage("");
              if (isMobile) {
                document.getElementById("aproval_btns").style.display = "flex";
              } else {
                document.getElementById("approve_btn").style.display =
                  "inline-block";
              }
            }, 3500);
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.data.error) {
            setMessage(err.response.data.error);
            setSeverity("error");
            setShowAlert(true);
            setTimeout(() => {
              setShowAlert(false);
              setSeverity("");
              setMessage("");
            }, 4000);
          } else {
            setSeverity("error");
            setMessage("Something went wrong, please try again");
            setShowAlert(true);
            setTimeout(() => {
              setShowAlert(false);
              setSeverity("");
              setMessage("");
              if (isMobile) {
                document.getElementById("aproval_btns").style.display = "flex";
              } else {
                document.getElementById("approve_btn").style.display = "block";
              }
            }, 3500);
          }
        });
    }
  };

  return (
    <>
      {isMobile ? (
        <section className="individual_requesst">
          <h2>
            Holiday request for: <span>{request.firstName} {request.lastName}</span>
          </h2>

          <div className="main__request">
            <p>
              Start day:{" "}
              <span>
                {new Date(request.startDate).toLocaleDateString("en-GB")}
              </span>
            </p>
            <p>
              End day:{" "}
              <span>
                {new Date(request.endDate).toLocaleDateString("en-GB")}
              </span>
            </p>
            <p>
              Total days: <span>{request.daysTaken}</span>
            </p>
            <p>
              Reason:{" "}
              {request.reason != "" ? (
                <span>{request.reason}</span>
              ) : (
                <span>No reason given</span>
              )}
            </p>
          </div>
          <div className="aproval_btns" id="aproval_btns">
            <Button variant="contained" color="error" onClick={handleOpen}>
              Deny
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={() => handleApproval(2)}
            >
              Approve
            </Button>
          </div>
          {showAlert ? (
            <Alert severity={severity}>{message}</Alert>
          ) : showTick ? (
            <Tick />
          ) : (
            <></>
          )}
        </section>
      ) : (
        <tr>
          <td>{request.firstName} {request.lastName}</td>
          <td>{new Date(request.startDate).toLocaleDateString("en-GB")}</td>
          <td>{new Date(request.endDate).toLocaleDateString("en-GB")}</td>
          <td>{request.daysTaken}</td>
          <td>
            {request.reason != "" ? (
              <>{request.reason}</>
            ) : (
              <>No reason given</>
            )}
          </td>
          <td>{request.leaveType === 1 ? <>Paid</> : <>Unpaid</>}</td>
          <td>
            <Button
              variant="contained"
              color="success"
              onClick={() => handleApproval(2)}
              id="approve_btn"
            >
              Approve
            </Button>
            {showAlert ? (
              <Alert severity={severity}>{message}</Alert>
            ) : showTick ? (
              <div style={{position:"absolute",top:"50%",left:"50%"}}><Tick /></div>
            ) : (
              <></>
            )}
          </td>
          <td>
            <Button variant="contained" color="error" onClick={handleOpen}>
              Deny
            </Button>
          </td>
        </tr>
      )}

      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Reason for denial
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <p id="modal_alert">{message}</p>
            <input
              type="text"
              onChange={(e) => setReason(e.target.value)}
              style={{ marginBottom: "2%" }}
            />
            <Stack direction="row" spacing={10}>
              <Button variant="outlined" color="error" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant="outlined"
                color="success"
                onClick={() => handleApproval(3)}
              >
                Deny
              </Button>
            </Stack>
          </Typography>
        </Box>
      </Modal>
    </>
  );
}
