import { useState, useEffect } from "react";
import axios from "axios";
//MUI IMPORTS
import { Button, Alert } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import InfoIcon from "@mui/icons-material/Info";

export default function FreshWastage({ type, handleBack }) {
    //HEADERS
    const url = "https://nitropos-backend.nitrosolutions.co.uk";
    //USER DETAILS
    const [site, setSite] = useState("");
    const [userName, setUserName] = useState("StoreZone");
    //PRODUCTS/INGREDIENTS
    const [products, setProducts] = useState([]);
    const [ingredients, setIngredients] = useState([]);
    const [onProducts, setOnProducts] = useState(true);
    //SEARCH
    const [searchInput, setSearchInput] = useState("");
    //RETURN BODY
    const [wastageItems, setWastageItems] = useState([]);
    //VALIDATION
    const invalidNumberChars = ["-", "+", "e", "E", "."];
    //ALERTS
    const [alert, setAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");
    const [disable, setDisable] = useState(false);
    //DIALOG BOX
    const [open, setOpen] = useState(false);
    const [openInfo, setOpenInfo] = useState(false);

    useEffect(() => {
        //GET USER DETAILS
        var checkUser = localStorage.getItem("userDetails")
        if(checkUser){
            let user = JSON.parse(localStorage.getItem("userDetails"));
            user = user.user.user;
            setUserName(user);
        }
        let getSite = localStorage.getItem("site");
        let localSite = getSite.replace(/['"]+/g, "");
        setSite(localSite);
        //GET PRODUCTS
        axios
            .get(`${url}/claims/products/${type}`)
            .then((res) => {
                setProducts(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
        //GET INGREDIENTS
        axios
            .get(`${url}/claims/products/${type}/ingredients`)
            .then((res) => {
                setIngredients(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    //FIXED ALERT
    const handleAlert = (message, severity = "warning", duration = 4000) => {
        setDisable(true);
        setMessage(message);
        setSeverity(severity);
        setAlert(true);
        setTimeout(() => {
            setAlert(false);
            setMessage("");
            setSeverity("");
            setDisable(false);
        }, duration);
    };

    //ADD WASTAGE ITEM TO LIST
    const handleAdd = () => {
        if (searchInput === "") {
            handleAlert(`Please enter ${onProducts ? "a product" : "an item"}`);
        } else if (onProducts) {
            let selectedProduct = products.filter((d) => {
                return d.barcode === searchInput;
            });

            if (selectedProduct.length > 0) {
                setWastageItems([...wastageItems, selectedProduct[0]]);
                setSearchInput("");
            } else {
                handleAlert("A product with this barcode does not exist");
            }
        } else {
            let selectedIngredient = ingredients.filter((d) => {
                return d.barcode === searchInput;
            });
            if (selectedIngredient.length > 0) {
                setWastageItems([...wastageItems, selectedIngredient[0]]);
                setSearchInput("");
            } else {
                handleAlert("An item with this barcode does not exist");
            }
        }
    };

    //DELETE WASTAGE ITEM FROM LIST
    const handleDelete = (index) => {
        let filteredItems = wastageItems.filter((d, i) => {
            return index !== i;
        });
        setWastageItems(filteredItems);
    };

    //ADD A QUANTITY FOR WASTAGE ITEM
    const handleQuantity = (index, qty) => {
        let mappedItems = wastageItems.map((d, i) => {
            if (index === i) {
                return { ...d, wasted: qty };
            } else {
                return d;
            }
        });
        setWastageItems(mappedItems);
    };

    //ADD A REASON FOR ITEM BEING WASTED
    const handleReason = (index, reason) => {
        let mappedItems = wastageItems.map((d, i) => {
            if (index === i) {
                return { ...d, reason: reason };
            } else {
                return d;
            }
        });
        setWastageItems(mappedItems);
    };

    //VALIDATES NUMBER FIELDS
    const numberValidation = (e) => {
        if (invalidNumberChars.includes(e.key)) {
            e.preventDefault();
        }
    };

    //VALIDATES FORM
    const validate = () => {
        //Scroll to top so alert message can be viewed
        window.scrollTo(0, 0);
        //Validation Checks - Change wasted qty accordingly
        let qtyCheck = wastageItems.some((d) => {
            return !d.wasted || d.wasted === "0" ? true : false;
        });
        let reasonCheck = wastageItems.some((d) => {
            return !d.reason || d.reason === "0" ? true : false;
        });

        if (qtyCheck) {
            handleAlert(
                "All wastage items must have a quantity greater than 0"
            );
        } else if (reasonCheck) {
            handleAlert("All wastage items must have a reason");
        } else {
            setOpen(true);
        }
    };

    //SUBMIT FULL WASTAGE TABLE
    const handleSubmit = async () => {
        let headerData = {
            site: site,
            date: new Date(),
            source: type,
            staffName: userName,
            fileName: "",
        };
        //Header request
        await axios
            .post(`${url}/claims/submit/wastage`, headerData)
            .then(async (res) => {
                let wasteId = res.data.wasteId;
                if (wasteId != "") {
                    let displayArray = [];
                    //Loop through each waste item
                    for (let i = 0; i < wastageItems.length; i++) {
                        let item = wastageItems[i];
                        let ingredientWasted = item.ingredientId
                            ? item.wasted / 100
                            : null;
                        let data = {
                            name: item.name,
                            barcode: item.barcode,
                            eposId: item.eposId ? item.eposId : null,
                            ingredientId: item.ingredientId
                                ? item.ingredientId
                                : null,
                            wasted: item.ingredientId
                                ? ingredientWasted
                                : parseInt(item.wasted),
                            supplierId: item.supplierId,
                            reason: item.reason,
                            image: null,
                            fixable: null,
                            fresh: true,
                            end: "Bin",
                        };
                        axios
                            .post(`${url}/claims/submit/items/${wasteId}`, data)
                            .then((res) => {})
                            .catch(async (err) => {
                                displayArray.push(wastageItems[i]);
                                console.log(err);
                            });
                    }
                    //If items error will keep in viewable array
                    setTimeout(() => {
                        if (displayArray.length > 0) {
                            handleAlert(
                                "Some items may have submitted but not the ones below. Try again",
                                "warning",
                                7000
                            );
                            setWastageItems(displayArray);
                        } else {
                            handleAlert("Successfully submitted", "success");
                            setWastageItems([]);
                        }
                    }, 1000);
                } else {
                    handleAlert("Error - No waste ID", "error");
                }
            })
            .catch((err) => {
                handleAlert("Error - Please try again", "error");
                console.log(err);
            });
    };

    return (
        <main className="hoagies_wastage">
            <h1>{type} Wastage</h1>
            <section className="search_section">
                <div classname="toggle_btns">
                    <Button
                        className="product_toggle"
                        variant="contained"
                        onClick={() => setOnProducts(true)}
                        sx={{
                            backgroundColor: onProducts ? "#64c4e0" : "#4d4d4d",
                            opacity: onProducts ? 1 : 0.4,
                        }}
                        disabled={disable}
                    >
                        Product
                    </Button>
                    <Button
                        className="ingredients_toggle"
                        variant="contained"
                        onClick={() => setOnProducts(false)}
                        sx={{
                            backgroundColor: !onProducts
                                ? "#64c4e0"
                                : "#4d4d4d",
                            opacity: !onProducts ? 1 : 0.4,
                        }}
                        disabled={disable}
                    >
                        Ingredients
                    </Button>
                </div>
                <div className="input_search">
                    <input
                        value={searchInput}
                        list="products_ID"
                        placeholder="Search barcode/name"
                        onChange={(e) => {
                            setSearchInput(e.target.value);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                handleAdd();
                            }
                        }}
                    />
                    <datalist id="products_ID">
                        {onProducts
                            ? products.map(function (d, i) {
                                  return (
                                      <option value={d.barcode} id={i} key={i}>
                                          {String(d.name)}
                                      </option>
                                  );
                              })
                            : ingredients.map(function (d, i) {
                                  return (
                                      <option value={d.barcode} id={i} key={i}>
                                          {d.name}
                                      </option>
                                  );
                              })}
                    </datalist>
                    <Button
                        className="add_button"
                        variant="contained"
                        onClick={handleAdd}
                        disabled={disable}
                    >
                        Add
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={handleBack}
                        disabled={disable}
                    >
                        Back
                    </Button>
                </div>
            </section>
            <section className="alert_section">
                {alert ? <Alert severity={severity}>{message}</Alert> : null}
            </section>
            {wastageItems.length > 0 ? (
                <>
                    <table className="table_section">
                        <thead>
                            <tr>
                                <th>Product Name</th>
                                <th>Barcode</th>
                                <th>Supplier</th>
                                <th>Units Wasted/Damaged</th>
                                <th>Reason</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {wastageItems.map((d, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{`${d.name.substring(
                                            0,
                                            30
                                        )}...`}</td>
                                        <td>{d.barcode}</td>
                                        <td>
                                            {d.supplierName
                                                ? d.supplierName
                                                : "NONE"}
                                        </td>
                                        <td className="centered_cells">
                                            {!d.ingredientId ? (
                                                <input
                                                    max="3"
                                                    type="number"
                                                    onChange={(e) => {
                                                        handleQuantity(
                                                            i,
                                                            e.target.value
                                                        );
                                                    }}
                                                    onKeyDown={(e) =>
                                                        numberValidation(e)
                                                    }
                                                />
                                            ) : (
                                                <>
                                                    <InfoIcon
                                                        className="info_icon"
                                                        onClick={() =>
                                                            setOpenInfo(true)
                                                        }
                                                    />
                                                    <input
                                                        type="range"
                                                        list="values"
                                                        step="25"
                                                        defaultValue="0"
                                                        onChange={(e) => {
                                                            handleQuantity(
                                                                i,
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                    <datalist id="values">
                                                        <option
                                                            value="0"
                                                            label="0"
                                                        ></option>
                                                        <option
                                                            value="25"
                                                            label="0.25"
                                                        ></option>
                                                        <option
                                                            value="50"
                                                            label="0.50"
                                                        ></option>
                                                        <option
                                                            value="75"
                                                            label="0.75"
                                                        ></option>
                                                        <option
                                                            value="100"
                                                            label="1"
                                                        ></option>
                                                    </datalist>
                                                </>
                                            )}
                                        </td>
                                        <td>
                                            <select
                                                onChange={(e) => {
                                                    handleReason(
                                                        i,
                                                        e.target.value
                                                    );
                                                }}
                                            >
                                                <option value="">
                                                    Select a reason
                                                </option>
                                                <option>Damaged</option>
                                                <option>Out of Date</option>
                                                <option>
                                                    Out of Temperature
                                                </option>
                                                <option>
                                                    Return to Supplier
                                                </option>
                                            </select>
                                        </td>
                                        <td>
                                            <Button
                                                onClick={() => handleDelete(i)}
                                                color="error"
                                                variant="contained"
                                                disabled={disable}
                                            >
                                                <DeleteIcon />
                                            </Button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <div className="submit_section">
                        <Button
                            variant="contained"
                            color="success"
                            className="submit_btn"
                            onClick={validate}
                            disabled={disable}
                        >
                            Submit
                        </Button>
                    </div>
                </>
            ) : null}
            {/* INFO DIALOG BOX */}
            <Dialog open={openInfo}>
                <div className="info">
                    <DialogTitle className="info_header">
                        Wasting Ingredients Info
                    </DialogTitle>
                    <Typography
                        variant="subtitle1"
                        component="div"
                        className="info_message"
                    >
                        Ingredients should be wasted relative to their box size.
                        For example, below represents 3/4 of a box.
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        component="div"
                        className="info_message"
                    >
                        <input
                            type="range"
                            list="values"
                            step="25"
                            value="75"
                        />
                        <datalist id="values">
                            <option value="0" label="0"></option>
                            <option value="25" label="0.25"></option>
                            <option value="50" label="0.50"></option>
                            <option value="75" label="0.75"></option>
                            <option value="100" label="1"></option>
                        </datalist>
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        component="div"
                        className="info_message"
                    >
                        If you have more than one box to waste you must put each
                        box through separately.
                    </Typography>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => setOpenInfo(false)}
                    >
                        Close
                    </Button>
                </div>
            </Dialog>
            {/* SUBMISSION DIALOG BOX */}
            <Dialog open={open}>
                <div className="submission_check">
                    <DialogTitle className="submission_header">
                        Submission Check
                    </DialogTitle>
                    <Typography
                        variant="subtitle1"
                        component="div"
                        className="submission_message"
                    >
                        Are you sure you have entered all the correct
                        information before proceeding?
                    </Typography>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={() => {
                            setOpen(false);
                            handleSubmit();
                        }}
                    >
                        Yes, I'm sure
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => setOpen(false)}
                    >
                        No, go back
                    </Button>
                </div>
            </Dialog>
        </main>
    );
}
