import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import axios from "axios";
import Alert from "@mui/material/Alert";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import HolidayRole from "./HolidayRole";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  maxHeight: "80vh",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  borderRadius: "1rem",
  overflowY: "scroll",
};

const styleConfirm = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30vw",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  borderRadius: "1rem",
};

export default function IndvUser(props) {
  const [user, setUser] = useState(props.user);
  const [message, setMessage] = useState("");
  const [errorAlert, setErrorAlert] = useState(false);
  const [siteList, setSiteList] = useState();
  const [sites, setSites] = useState([])
  const [holidays, setHolidays] = useState([])

  useEffect(() => {
    axios
      .get("https://nitrous.rt-itservices.co.uk/sites/data")
      .then((res) => {
        setSiteList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
      if(props.user.holidayRoles){
        setHolidays(props.user.holidayRoles)
      }
  }, []);

  //modal functions
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const handleOpenConfirm = () => setOpenConfirm(true);
  const handleCloseConfirm = () => setOpenConfirm(false);
  const [update,setUpdate] = useState(false)

  //delete function
  const handleDelete = () => {
    document.getElementById("modal_user_btn").style.display = "none";
    axios
      .post("https://nitropos-backend.nitrosolutions.co.uk/users/delete", { id: user._id })
      .then((res) => {
        document.getElementById("modal_user_btn").style.display = "flex";
        handleCloseConfirm();
        props.rel();
      })
      .catch((err) => {
        console.log(err);
        setMessage("Something went wrong, please try again");
        setErrorAlert(true);
        setTimeout(() => {
          setMessage("");
          setErrorAlert(false);
          document.getElementById("modal_user_btn").style.display = "flex";
        }, 3500);
      });
  };

  const handleEdit = (input) => {
    document.getElementById(input).style.display = "flex";
  };

  const handleSites = (site) => {
    let inx = sites.findIndex((p) => p === site);
    if (inx === -1) {
      sites.push(site);
    } else {
      sites.splice(inx, 1);
    }
  };

  const handleHolidayRoles = (role) => {
    let inx = holidays.findIndex((p) => p === role);
    if (inx <0) {
      setHolidays(holidays => [...holidays,role] )

    } else {
      var copyArr = holidays
      copyArr.splice(inx, 1);
      setHolidays(copyArr)
    }
    setUpdate(!update)
  };

  const updateUser = (field, update) => {
    const copyUser = user;
    copyUser[field] = update;
    setUser(copyUser)
  }

  const handleSend = async () => {
    document.getElementById("edit_modal_btn").style.display = "none";
    let copyUser = user;
    if (sites.length === 0) {
      copyUser = copyUser
    } else if (sites.length > 0 && sites.length != siteList.length) {
      copyUser.site = sites
    } else if (sites.length === siteList.length) {
      copyUser.site = ["HQ"]
    }

    if (holidays.length === 0) {
      copyUser = copyUser
      console.log("NO ROLES")
    } else if (!user.holidayRoles || holidays.length > 0 && holidays.length != user.holidayRoles.length || holidays.length === props.holidayList.length) {
      copyUser.holidayRoles = holidays
      console.log("NEW ROLES")
    }

    await axios.post("https://nitropos-backend.nitrosolutions.co.uk/users/updateUser", { user: copyUser })
      .then((res) => {
        document.getElementById("edit_modal_btn").style.display = "flex";
        handleClose()
        props.rel()
      }).catch((err) => {
        console.log(err);
        setMessage("Something went wrong, please try again");
        setErrorAlert(true);
        setTimeout(() => {
          setMessage("");
          setErrorAlert(false);
          document.getElementById("edit_modal_btn").style.display = "flex";
        }, 3500);
      })
  }

  return (
    <tr>
      <th onClick={handleOpen}>{user.email}</th>
      <th onClick={handleOpen}>
        {user.pinNumber ? <>{user.pinNumber}</> : <>No warehouse User</>}
      </th>
      <th onClick={handleOpen}>{user.role}</th>
      <th onClick={handleOpen}>
        {user.site.length > 1 ? (
          <>
            {user.site.map(function (d, i) {
              return (
                <ul>
                  <li>{d}</li>
                </ul>
              );
            })}
          </>
        ) : (
          <>{user.site}</>
        )}
      </th>
      <th>
        <DeleteForeverIcon
          color="primary"
          fontSize="large"
          onClick={handleOpenConfirm}
        />
      </th>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Modify User {user.email}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className="user__modal">
              <hr style={{ width: "40%" }} />
              <div className="user_modal_row_small">
                <p>
                  Email: <span>{user.email}</span>
                </p>
                <ModeEditIcon
                  color="primary"
                  sx={{ margin: "0 2%" }}
                  onClick={() => handleEdit("email")}
                />
                <input type="email" id="email" onChange={(e) => updateUser("email", e.target.value)} />
              </div>
              <hr style={{ width: "40%" }} />
              <div className="user_modal_row_small">
                <p>
                  Role: <span>{user.role}</span>
                </p>
                <ModeEditIcon color="primary" sx={{ margin: "0 2%" }} onClick={() => handleEdit("role")} />
                <select
                  id="role"
                  onChange={(e) => updateUser("role", e.target.value)}
                >
                  <option>--- Select Role ---</option>
                  <option value="Manager">
                    Manager - used for directors or higher management
                  </option>
                  <option value="Admin">Admin - for office staff</option>
                  <option value="HR">HR - for HR staff</option>
                  <option value="Store Manager">Store Manager</option>
                  <option value="Supervisor">
                    Supervisor - used for Supervisors and TL's
                  </option>
                  <option value="Warehouse Manager">Warehouse Manager</option>
                  <option value="Food Manager">Food Manager</option>
                  <option value="V4U Manager">V4U Manager</option>
                  <option value="Developer">Developer</option>
                </select>
              </div>
              <hr style={{width:"40%"}}/>
              <div className="user_modal_row_small">
                {user.site.length > 1 ? (
                  <>
                    <p>
                      Sites:{" "}
                      {user.site.map(function (d, i) {
                        return (
                          <ul>
                            <li>{d}</li>
                          </ul>
                        );
                      })}
                    </p>
                    <ModeEditIcon color="primary" sx={{ margin: "0 2%" }} onClick={() => handleEdit("sites")} />
                    <div id="sites">
                      {siteList ? <>
                        {siteList.map(function (d, i) {
                          return (
                            <div className="form_line">
                              <input
                                type="checkbox"
                                name={d.name}
                                value={d.name}
                                onChange={(e) => handleSites(e.target.value)}
                                id={i}
                              />
                              <label for={d.name}>{d.name}</label>
                            </div>
                          );
                        })}
                      </> : (<></>)}
                    </div>
                  </>
                ) : (
                  <>
                    <p>
                      Site: <span>{user.site}</span>
                    </p>
                    <ModeEditIcon color="primary" sx={{ margin: "0 2%" }} onClick={() => handleEdit("sites_one")} />
                    <div id="sites_one">
                      {siteList ? <>
                        {siteList.map(function (d, i) {
                          return (
                            <div className="form_line">
                              <input
                                type="checkbox"
                                name={d.name}
                                value={d.name}
                                onChange={(e) => handleSites(e.target.value)}
                                id={i}
                              />
                              <label for={d.name}>{d.name}</label>
                            </div>
                          );
                        })}
                      </> : (<></>)}
                    </div>
                  </>
                )}
              </div>
              <hr style={{width:"40%"}}/>
              <div className="user_modal_row_small">
                <p style={{ marginRight: "5%" }}>Holidays:</p>
                <div id="sites">
                  {props.holidayList ? <>
                    {props.holidayList.map(function (d, i) {
                      return (
                        <div className="form_line">
                          <HolidayRole id={`role-${i}`} roleId={`role-${i}`} update={update} holidays={holidays} name={d.name} handleHolidayRoles={handleHolidayRoles} userHols={user.holidayRoles}/>
                            </div>
                          );
                        })}
                      </> : (<></>)}
                    </div>
              </div>
              <hr style={{width:"40%"}}/>
              <div className="user_modal_row" id="edit_modal_btn">
                <Button variant="outlined" color="error" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => handleSend()}
                >
                  Confirm
                </Button>
              </div>
            </div>
          </Typography>
        </Box>
      </Modal>

      <Modal
        open={openConfirm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleConfirm}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className="user__modal">
              You are about to delete {user.email}, please confirm
              <div className="user_modal_row" id="modal_user_btn">
                <Button
                  variant="outlined"
                  color="error"
                  onClick={handleCloseConfirm}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => handleDelete()}
                >
                  Confirm
                </Button>
              </div>
              {errorAlert ? <Alert severity="warning">{message}</Alert> : <></>}
            </div>
          </Typography>
        </Box>
      </Modal>
    </tr>
  );
}
