import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";
import axios from 'axios';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60rem",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
  maxHeight: "55vh",
  overflowY: "scroll",
};

export default function PendingPO(props) {
  const [invoice, setInvoice] = useState([]);
  const [user, setUser] = useState("")
  const [reload, setReload] = useState(false)
  const [message, setMessage] = useState("")
  const [errorAlert, setErrorAlert] = useState(false)

  useEffect(() => {
    let getUser = sessionStorage.getItem("user");
    setUser(getUser);
    console.log(props)
    axios.get(`https://nitropos-backend.nitrosolutions.co.uk/purchaseOrder/details/${props.id}`)
    .then((res) => {
      console.log(res.data)
      setInvoice(res.data)
    }).catch((err) => {
      console.log(err)
    })

  }, [props, reload]);

  const rel = () => {
    setReload(!reload)
  }

  const close = () => {
    props.close()
  }

  const submit = () => {
    props.close()
    //request to send the PO to the back end, close the modal, reload the next screen
  }

  const handleQtyChange = (index, newQty, id) => {
    const data = {
      invoiceIndex: index,
      newQty: newQty,
      popId: id,
    }
    axios.post("https://nitropos-backend.nitrosolutions.co.uk/purchaseOrder/quantityUpdate", data)
    .then((res) => {
      console.log(res.data)
      rel()
    }).catch((err) => {
      console.log(err)
      setMessage("Something went wrong, please try again changing the quantity")
      setErrorAlert(true)
      setTimeout(() => {
        setErrorAlert(false)
      },2500)
    })
  }

  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Invoice: {props.invoice} - for: {props.site}
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <div className="pendingpo__modal">
          {errorAlert ? (
            <Alert severity="warning">{message}</Alert>
          ):(
            <></>
          )}
        <table >
            <thead>
                <tr>
                    <th>Item</th>
                    <th>Amount Ordered (you can modify this)</th>
                </tr>
            </thead>
            <tbody>
              {invoice.map(function(d,i){
                return(
                  <tr>
                    <td>{d.name}</td>
                    <td>
                      <input type="number" placeHolder={d.orderedQty} onChange={(e) => handleQtyChange(i, e.target.value, d.popId)} />
                    </td>
                  </tr>
                )
              })}
            </tbody>
        </table>
        <Stack spacing={2} direction="row" className="stack__row">
            <Button variant="outlined" color="error" onClick={() => close()} sx={{width: "40%"}}>Cancel</Button>
            <Button variant="contained" color="success" onClick={() => submit()} sx={{width: "40%"}}>Place with Supplier</Button>
        </Stack>
        </div>
      </Typography>
    </Box>
  );
}
