//use for Warehouse + Admin parts of NitroPOS

import { Navigate } from "react-router-dom";

const WarehouseAdmin = ({ children }) => {
    let userD = localStorage.getItem("userDetails");
    let user = JSON.parse(userD);
    console.log(user);
    if (
        user.user.role !== "Manager" &&
        user.user.role !== "Admin" &&
        user.user.role !== "Warehouse Manager" &&
        user.user.role !== "Developer" &&
        user.user.role !== "V4U Manager" &&
        user.user.user !== "guna@ghsl.uk" &&
        user.user.role !== "Store Manager"
    ) {
        return <Navigate to="/not-allowed" replace />;
    }
    return children;
};

export { WarehouseAdmin };
