import { useState, useContext, useEffect } from "react";
import axios from "axios";
import "./styles/paypointSales.scss";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import Loader from "./loader/Loader";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import UserContext from "../context/userContext";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

//modal imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import IndvNotesModal from "./IndvNotesModal";

const noteStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40vw",
  bgcolor: "background.paper",
  borderRadius: "1rem",
  backgroundColor: "#fff",
  boxShadow: 24,
  p: 4,
  color: "#000",
  height: "30vh",
};

export default function FuelSales() {
  const [site, setSite] = useState("");
  const [date, setDate] = useState("");
  const [salesInfo, setSalesInfo] = useState(false);
  const [loader, setLoader] = useState(false);
  const [infoExpand, setInfoExpand] = useState(false);
  const [alert, setAlert] = useState(false);
  const [eposTotal, setEposTotal] = useState(0);
  const [eposTxt, setEposTxt] = useState([]);
  const [id, setId] = useState("");
  const [note, setNote] = useState("");
  const [index, setIndex] = useState("");
  const [notes, setNotes] = useState([]);
  const [user, setUser] = useState("")

  const { userData } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    let email = sessionStorage.getItem("user")
    setUser(email)

  }, [])

  const [openNote, setOpenNote] = useState(false);
  const handleOpenNote = () => {
    setOpenNote(true);
    document.getElementById("context-menu").style.display = "none";
  };
  const handleCloseNote = () => {
    setOpenNote(false);
    setId("");
  };

  const [openAllNote, setOpenAllNote] = useState(false);
  const handleOpenAllNote = () => {
    setOpenAllNote(true);
    document.getElementById("context-menu").style.display = "none";
  };
  const handleCloseAllNote = () => {
    setOpenAllNote(false);
    setId("");
    setIndex("");
    setNotes([]);
  };


  const handleReport = () => {
    if (site === "--Please Select a Site--" && date === "") {
      setAlert(true);
      setTimeout(() => {
        setAlert(false);
      }, 2500);
    } else if (site === "" || date === "") {
      setAlert(true);
      setTimeout(() => {
        setAlert(false);
      }, 2500);
    } else {
      let data = {
        site: site,
        date: date,
      };
      setLoader(true);
      setInfoExpand(false);
      axios
        .post(
          "https://nitropos-backend.nitrosolutions.co.uk/sales/paypointReport",
          data
        )
        .then((res) => {
          setLoader(false);
          setSalesInfo(true);
          setEposTotal(res.data.epos.total.toLocaleString("en-GB"));
          setEposTxt(res.data.epos.indv);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleInfoExpand = () => {
    if (!infoExpand) {
      setInfoExpand(true);
    } else {
      setInfoExpand(false);
    }
  };

  const sendNote = () => {
    let data = {
      note: note,
      user: user,
      id: id,
    };
    axios
      .post("https://nitropos-backend.nitrosolutions.co.uk/sales/paypointReportAddNote", data)
      .then((res) => {
        console.log(res.data);
        document.getElementById("context-menu").style.display = "none";

        let selection = document.querySelectorAll(`#paypoint tr`);
        for (let i = 0; i < selection.length; i++) {
          if (i === index) {
            selection[i].classList.add("noteAdded");
          }
        }
        handleCloseNote();
        setNote("");
        setIndex("");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRightClick = (e, id, index, notes) => {
    const xPos = e.pageX + "px";
    const yPos = e.pageY + "px";
    let menu = document.getElementById("context-menu");
    let scope = document.querySelector("body");

    scope.addEventListener("contextmenu", (e) => {
      e.preventDefault();
      menu.style = `display: inline-block; position: absolute; top: ${yPos}; left: ${xPos};`;
    });
    setId(id);
    setIndex(index);
    setNotes(notes);
  };

  const handleClick = (id, heading, matchId, index) => {
    let data = {
      id: id,
      matchId: matchId
    };
    setId("")
    let menu = document.getElementById("context-menu");
    menu.style.display = "none";

    axios.post("https://nitropos-backend.nitrosolutions.co.uk/sales/paypointReportChecked", data).then((res) => {
      console.log(res.data);
      let selection = document.querySelectorAll(`#paypoint tr`);
      for (let t = 0; t < selection.length; t++) {
        if (t === index) {
          if (!matchId) {
            selection[t].classList.add("matched");
          } else {
            selection[t].classList.remove("matched");
          }
        }
      }
    })
      .catch((err) => {
        console.log(err);
      });

    refresh()
  };

  const refresh = async () => {
    let data = {
      site: site,
      date: date,
    };
    axios.post(
      "https://nitropos-backend.nitrosolutions.co.uk/sales/paypointReport",
      data
    ).then((res) => {
      setEposTotal(res.data.epos.total.toLocaleString("en-GB"));
      setEposTxt(res.data.epos.indv);
    }).catch((err) => {
      console.log(err);
    });
  }

  return (
    <main className="paypoint">
      <section className="paypoint__main">
        <h1>Please select a site and day to see the report</h1>
        {alert ? (
          <Alert
            severity="error"
            sx={{
              width: "80vw",
              left: "10vw",
              position: "absolute",
              top: "35vh",
              zIndex: "10",
            }}
          >
            <AlertTitle>Error</AlertTitle>
            Invalid data, <strong>please select a site and a date</strong>
          </Alert>
        ) : (
          <></>
        )}
        <div className="select_row">
          <select onChange={(e) => setSite(e.target.value)}>
            <option>--Please Select a Site--</option>
            <option>Autoport</option>
            <option>Bearsden</option>
            <option>Braeside</option>
            <option value="Bridgeton">Bridgeton - (PayZone)</option>
            <option>Coatbridge</option>
            <option>Crowwood</option>
            <option>Dumbarton Rd</option>
            <option>Rutherglen</option>
            <option>Strathclyde</option>
            <option>Sauchiehall</option>
            <option>Wishaw</option>
          </select>
          <input type="date" onChange={(e) => setDate(e.target.value)} />
          <IconButton onClick={() => handleReport()}>
            <SearchIcon color="primary" fontSize="large" />
          </IconButton>
        </div>
        {loader ? (
          <div className="loader">
            <Loader />
          </div>
        ) : (
          <></>
        )}
        {salesInfo ? (
          <div className="paypoint__info">
            <div className="info__card">
              <ExpandCircleDownIcon
                className="info__card__icon"
                color="primary"
                onClick={() => handleInfoExpand()}
              />
              <div className="card__till">
                <h2>EposNOW</h2>
                <h3>£{eposTotal}</h3>
              </div>
            </div>
            {infoExpand ? (
              <div className="info__expand">
                <table>
                  <thead>
                    <tr>
                      <th>Transaction ID</th>
                      <th>Time - Date</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody id="paypoint">
                    {eposTxt.map(function (d, i) {
                      let matched = "";
                      let notes = "";
                      if (d.matchId != null) {
                        matched = " matched ";
                      }
                      if (d.noteCount != "0") {
                        notes = " noteAdded ";
                      }
                      return (
                        <tr
                          onContextMenu={(e) => {
                            handleRightClick(e, d.itemId, i, d.notes);
                          }}
                          onClick={() => handleClick(d.itemId, d.headingId, d.matchId, i)}
                          className={`${matched} ${notes}`}
                        >
                          <th>{d.transactionId}</th>
                          <th>
                            {new Date(d.transactionTime).toLocaleString()}
                          </th>
                          <th>£{parseFloat(d.unitPrice).toFixed(2)}</th>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
        <div id="context-menu">
          <ul>
            <li onClick={handleOpenNote}>Add Note</li>
            {notes && notes.length != 0 ? (<li onClick={() => handleOpenAllNote()}>See Notes</li>) : (<></>)}
          </ul>
        </div>
        <Modal
          open={openNote}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={noteStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <h2 style={{ textAlign: "center" }}>Add a Note</h2>
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <div className="force__match">
                <textarea
                  cols={50}
                  rows={6}
                  onChange={(e) => setNote(e.target.value)}
                  style={{ border: "1px solid #000" }}
                  placeholder="Type your note"
                />
                <div className="force__buttons">
                  <Button
                    variant="outlined"
                    color="success"
                    sx={{ width: "10vw" }}
                    onClick={() => sendNote()}
                  >
                    YES
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    sx={{ width: "10vw" }}
                    onClick={() => handleCloseNote()}
                  >
                    CANCEL
                  </Button>
                </div>
              </div>
            </Typography>
          </Box>
        </Modal>

        <Modal
          open={openAllNote}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          onClose={handleCloseAllNote}
        >
          <IndvNotesModal notes={notes} />
        </Modal>

      </section>
    </main>
  );
}
