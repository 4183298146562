import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import axios from 'axios';


const unmatchStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60vw",
    minHeight: "30vh",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
  }; 


export default function UnMatchModal(props){
    const [id, setId] = useState("")
    const [from, setFrom] = useState("")
    const [epos, setEpos] = useState([])
    const [prism, setPrism] = useState([])

    useEffect(() => {
        setId(props.id)
        setFrom(props.from)

        let data = {
            from: props.from,
            sqlId: props.id,
        }
        axios
        .post("https://nitropos-backend.nitrosolutions.co.uk/fuel/unMatchDetails", data)
        // .post("http://10.0.0.34:4022/fuel/unMatchDetails", data)
        .then((res) => {
            // console.log(res.data)
            setEpos(res.data.epos)
            setPrism(res.data.prism)
        }).catch((err) => {
            console.log(err)
        })
    },[props])

    return(
        <Box sx={unmatchStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          <h2 style={{ textAlign: "center" }}>Un-Matched a Transaction</h2>
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <div className="force__match">
            <p>Are you sure you want to unmatch the following transactions?</p>
            <div className="force__buttons">
                <table>
                    <thead>
                        <tr>
                            <th>EPOS Transaction ID</th>
                            <th>EPOS Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {epos.map(function(d,i){
                            return(
                                <tr>
                                    <th>{d.transactionId}</th>
                                    <th>£{parseFloat(d.unitPrice).toFixed(2)}</th>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <table>
                    <thead>
                        <tr>
                            <th>Prism Amount</th>
                            <th>Prism POS</th>

                        </tr>
                    </thead>
                    <tbody>
                        {prism.map(function(d,i){
                            return(
                                <tr>
                                    <th>£{parseFloat(d.fuelValue).toFixed(2)}</th>
                                    <th>{d.pos}</th>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>

            </div>

            <div className="force__buttons">
              <Button
                variant="outlined"
                color="success"
                sx={{ width: "10vw" }}
                onClick={() => props.match(epos, prism)}
              >
                YES
              </Button>
              <Button
                variant="contained"
                color="error"
                sx={{ width: "10vw" }}
                onClick={props.close}
              >
                CANCEL
              </Button>
            </div>
          </div>
        </Typography>
      </Box>

    )

}