import { useEffect, useState } from "react";
import "../MiscItems/miscItem.scss";
import IndvMiscItem from "./IndvMiscItem";
import axios from "axios";
import { Button, Alert } from "@mui/material";
import NitroLoader from "../../../../Fuel/loader/NitroLoader";

export default function MiscItems({ eodId, handleMiscItems, items }) {
    //Main
    const url = "https://nitropos-backend.nitrosolutions.co.uk";
    //const url = "http://10.0.0.174:4022";

    //Updated Items
    const [updatedItems, setUpdatedItems] = useState([]);
    //Alerts
    const [alert, setAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");
    const [disable, setDisable] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        console.log(updatedItems);
    }, [updatedItems]);

    //Show Alert
    const showAlert = (message, severity, duration = 3000) => {
        setDisable(true);
        setMessage(message);
        setSeverity(severity);
        setAlert(true);
        setTimeout(() => {
            setDisable(false);
            setAlert(false);
            setMessage("");
            setSeverity("");
        }, duration);
    };

    //Handle item updates
    const handleItemUpdates = (mpId, eposId, name, qty) => {
        let updatedItem = {
            mpId: mpId,
            eposId: eposId,
            actualQty: parseFloat(qty),
            name: name,
        };

        //Check if mpId already exits in updated item and remove it before replacing it
        let duplicateCheck = updatedItems.findIndex(
            (item) => item.mpId === mpId
        );
        if (duplicateCheck >= 0) {
            updatedItems.splice(duplicateCheck, 1);
        }

        setUpdatedItems([...updatedItems, updatedItem]);
    };

    //Remove item from updated list
    const handleRemoveUpdatedItem = (id) => {
        let indexToRemove = updatedItems.findIndex((item) => item.mpId === id);
        updatedItems.splice(indexToRemove, 1);
        setUpdatedItems(updatedItems);
    };

    //Confirm
    const handleConfirm = () => {
        let data = {
            eodId: eodId,
            products: updatedItems,
        };

        axios
            .post(`${url}/eos/misc-items/update`, data)
            .then((res) => {
                setShowLoader(true);
                handleMiscItems();
            })
            .catch((err) => {
                console.log(err);
                showAlert("Something went wrong, please try again", "error");
            });
    };

    return (
        <main className="non_scan_main">
            {/* MAIN TABLE */}
            {items.currentItems && items.currentItems.length > 0 ? (
                <>
                    <h1>Non-Scan Misc Items</h1>

                    <table className="non_scan_table">
                        <thead>
                            <th>Misc Product</th>
                            <th>Misc Price</th>
                            <th>Misc Quantity</th>
                            <th>Search</th>
                            <th>Actual Product</th>
                            <th>Actual Quantity</th>
                            <th>Transaction Time</th>
                            <th>Reset</th>
                        </thead>
                        <tbody>
                            {items.currentItems.map((item, i) => {
                                return (
                                    <IndvMiscItem
                                        key={i}
                                        item={item}
                                        handleItemUpdates={handleItemUpdates}
                                        updatedItems={updatedItems}
                                        handleRemove={handleRemoveUpdatedItem}
                                    />
                                );
                            })}
                        </tbody>
                    </table>
                </>
            ) : (
                <h1>No Current Misc Items</h1>
            )}
            {/* CARRIED OVER TABLE */}
            {items.carriedItems && items.carriedItems.length > 0 ? (
                <>
                    <h1>Carried over from previous Eod</h1>
                    <table className="carried_over_table">
                        <thead>
                            <th>Misc Product</th>
                            <th>Misc Price</th>
                            <th>Misc Quantity</th>
                            <th>Search</th>
                            <th>Actual Product</th>
                            <th>Actual Quantity</th>
                            <th>Transaction Time</th>
                            <th>Reset</th>
                        </thead>
                        <tbody>
                            {items.carriedItems &&
                                items.carriedItems.map((item, i) => {
                                    return (
                                        <IndvMiscItem
                                            key={i}
                                            item={item}
                                            handleItemUpdates={
                                                handleItemUpdates
                                            }
                                            updatedItems={updatedItems}
                                            handleRemove={
                                                handleRemoveUpdatedItem
                                            }
                                        />
                                    );
                                })}
                        </tbody>
                    </table>
                </>
            ) : (
                <h1>No carried over Misc items</h1>
            )}

            {!showLoader ? (
                !alert ? (
                    <Button
                        variant="outlined"
                        color="success"
                        onClick={() => handleConfirm()}
                        id="discounts__btn"
                        sx={{ margin: "2% auto" }}
                        disable={disable}
                    >
                        Next Step
                    </Button>
                ) : (
                    <Alert severity={severity}>{message}</Alert>
                )
            ) : (
                <NitroLoader />
            )}
        </main>
    );
}
