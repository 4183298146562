import { Outlet } from "react-router-dom";
import "./onboarding.scss";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { useState, useEffect, Component, useCallback } from "react";
import "../../../../node_modules/react-big-calendar/lib/css/react-big-calendar.css";
import axios from "axios";
import { getCompanies } from "../../utils/apiUtils";
import { decryptData } from "../../utils/cryptoUtils";

const localizer = momentLocalizer(moment);

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div>
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: "pre-wrap" }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
          <h3>An Alert has been sent to IT</h3>
        </div>
      );
    }
    return this.props.children;
  }
}

export default function InterviewCalendar({ managerInfo }) {
  const url = process.env.REACT_APP_API_URL
  const [interviews, setInterviews] = useState([]);
  const [companies, setCompanies] = useState([]);
  const { sites } = managerInfo;
  const [siteName, setSiteName] = useState(sites.length > 1 ? "all" : sites[0]);
  const [token, setToken] = useState("");
  const config = {
    headers: { "Content-Type": "application/json", "x-auth-token": token },
  };

  useEffect(() => {
    let getToken = localStorage.getItem("auth-token");
    setToken(getToken);

    const fetchCompanies = async () => {
      try {
        const companiesData = await getCompanies(getToken);
        setCompanies(companiesData);
      } catch (err) {
        console.log(err);
      }
    };

    fetchCompanies();
  }, []);

  useEffect(() => {
    const fetchInterviewData = async () => {
      try {
        if (companies.length > 0) {
          if (siteName === "HQ") {
            // Fetch interviews for all sites of the manager
            var allInterviewData = []
            for (let i = 0; i < companies.length; i++) {
              const res = await axios.get(
                `${url}/interview/all/${companies[i].companyId}`,
                config
              );
              allInterviewData = [...allInterviewData, res.data]
              console.log(res.data)
            }

            const allInterviews = allInterviewData.reduce(
              (interviews, interviewData) => {
                const updatedInterviews = interviewData.map((data) => {
                  let decryptName = ""
                  if (data.firstName != null) {
                    const decryptedFirstName = decryptData(data.firstName, process.env.REACT_APP_NS_ENC);
                    const decryptedLastName = decryptData(data.lastName, process.env.REACT_APP_NS_ENC);
                    decryptName=`${decryptedFirstName} ${decryptedLastName}`  
                  } else if(data.fullName != null) {
                    decryptName = decryptData(data.fullName, process.env.REACT_APP_NS_ENC);
                  }
                  
                  const startTime = moment(data.date);
                  const endTime = moment(startTime).add(30, "minutes");

                  return {
                    id: data.applicantId,
                    title: `${decryptName}`,
                    start: startTime.toDate(),
                    end: endTime.toDate(),
                  };
                });

                return interviews.concat(updatedInterviews);
              },
              []
            );

            setInterviews(allInterviews);
          } else {
            // Fetch interviews for the selected site
            const siteCompany = companies.find(
              (company) => company.siteName === siteName
            );

            if (siteCompany) {
              const companyId = siteCompany.companyId;
              const res = await axios.get(
                `${url}/interview/all/${companyId}`,
                config
              );

              const interviewData = res.data;

              const updatedInterviews = interviewData.map((data) => {
                if (data.firstName != null) {
                  const decryptedFirstName = decryptData(data.firstName, process.env.REACT_APP_NS_ENC);
                  const decryptedLastName = decryptData(data.lastName, process.env.REACT_APP_NS_ENC);

                  const startTime = moment(data.date);
                  const endTime = moment(startTime).add(30, "minutes");

                  return {
                    id: data.applicantId,
                    title: `${decryptedFirstName} ${decryptedLastName}`,
                    start: startTime.toDate(),
                    end: endTime.toDate(),
                  };
                } else if(data.fullName != null) {
                  const decryptName = decryptData(data.fullName, process.env.REACT_APP_NS_ENC);

                  const startTime = moment(data.date);
                  const endTime = moment(startTime).add(30, "minutes");

                  return {
                    id: data.applicantId,
                    title: decryptName,
                    start: startTime.toDate(),
                    end: endTime.toDate(),
                  };
                }
              });
              setInterviews(updatedInterviews);
            }
          }
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchInterviewData();
  }, [companies, siteName]);

  const handleSiteChange = (event) => {
    setSiteName(event.target.value);
  };

  const handleInterview = useCallback((interview) => {
    console.log(interview)
    window.open(`${process.env.REACT_APP_ONBOARDING_LINK}/interview/${interview.id}`, '_blank')
  })

  return (
    <main className="interview_calendar_main">
      <section className="main_interviews">
        {sites.length > 1 && (
          <div className="main_interviews_select">
            <label htmlFor="site-select">Select Site: </label>
            <select
              id="site-select"
              value={siteName}
              onChange={handleSiteChange}
            >
              {sites.map((site) => (
                <option key={site} value={site}>
                  {site}
                </option>
              ))}
              <option value="all">All Sites</option>
            </select>
          </div>
        )}
        <Calendar
          localizer={localizer}
          events={interviews}
          views={["month", "day"]}
          defaultView="day"
          startAccessor="start"
          endAccessor="end"
          style={{ height: 600, width: "90%" }}
          onSelectEvent={handleInterview}
        />
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </section>
    </main>
  );
}
