import { useEffect, useState } from "react";
import axios from "axios";
import { getSitesByUser } from "../../../utils/Api";
import "./discreps.scss";
import { IconButton, Alert } from "@mui/material";
import Loader from "../../../animations/Loader";
import SearchIcon from "@mui/icons-material/Search";
import { decryptData } from "../../../utils/cryptoUtils";
import UserDiscreps from "./UserDiscreps";

export default function ViewDiscrepsHome() {
  const url = "https://nitropos-backend.nitrosolutions.co.uk";
  //const url = "http://10.0.0.174:4022";
  const [token, setToken] = useState("");
  const [siteList, setSiteList] = useState([]);
  const [site, setSite] = useState("");
  const [siteName, setSiteName] = useState("");
  const [month, setMonth] = useState("");
  const [discrepancies, setDiscrepancies] = useState([]);
  const [userDiscrepancies, setUserDiscrepancies] = useState([]);
  const [staffName, setStaffName] = useState("");
  const [total, setTotal] = useState("");
  const [search, setSearch] = useState(false);

  //alerts
  const [loader, setLoader] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  useEffect(() => {
    let userD = localStorage.getItem("userDetails");
    let user = JSON.parse(userD);
    let getToken = localStorage.getItem("auth-token");
    setToken(getToken);

    try {
      getSitesByUser(user.user.sites)
        .then((sites) => {
          //   console.log(sites);
          if (sites.length === 1) {
            setSite(sites[0].siteName);
            setSiteName(sites[0].siteName);
          } else {
            setSiteList(sites);
          }
        })
        .catch((err) => {
          console.log(`Could not get sites for user: ${err.response.data}`);
        });
    } catch (err) {
      console.log(`Could not get sites for user: ${err.response.data}`);
    }
  }, []);

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleMonth();
    }
  };

  const handleMonth = () => {
    setDiscrepancies([]);
    setUserDiscrepancies([]);
    if (site === "" || site === "--Please select a site--" || month === "") {
      setMessage(`Site and month has to be selected, try again`);
      setSeverity("warning");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3500);
    } else if (loader === true) {
      setSearch(true);
      setTimeout(() => {
        setSearch(false);
      }, 3000);
    } else {
      setLoader(true);
      try {
        let data = {
          date: month,
          site: site,
          token: token,
        };
        axios
          .post(`${url}/discrepancies/getDiscrepancies`, data)
          .then((res) => {
            setLoader(false);
            console.log(res.data.discrepancies);
            const monthDiscreps = res.data.discrepancies;
            if (monthDiscreps.length === 0) {
              setMessage(`No discrepancies to show`);
              setSeverity("success");
              setShowAlert(true);
              setTimeout(() => {
                setShowAlert(false);
              }, 3500);
            } else {
              for (let d = 0; d < monthDiscreps.length; d++) {
                setDiscrepancies((discrepancies) => [
                  ...discrepancies,
                  {
                    eposStaffId: monthDiscreps[d].eposStaffId,
                    mainCompanyId: monthDiscreps[d].mainCompanyId,
                    name: decryptData(
                      monthDiscreps[d].name,
                      process.env.REACT_APP_EMP_KEY
                    ),
                    sum: monthDiscreps[d].totalOwed,
                    letterSent: monthDiscreps[d].letterSent,
                    totalDiscrep: monthDiscreps[d].totalDiscrep,
                    allCharged: monthDiscreps[d].allCharged,
                  },
                ]);
              }
            }
          })
          .catch((err) => {
            console.log(`Could not get discrepancies: ${err}`);
          });
      } catch (err) {
        setLoader(false);
        console.log(`Could not get discrepancies: ${err}`);
        setMessage(`Could not get discrepancies: ${err}`);
        setSeverity("error");
        showAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3500);
      }
    }
  };

  const handleDiscrepancy = (staffId, staffName, total) => {
    setStaffName("");
    setUserDiscrepancies([]);
    setTotal("");
    setStaffName(staffName);
    setTotal(total);
    try {
      let data = {
        date: month,
        staffId: staffId,
        token: token,
      };
      axios
        .post(`${url}/discrepancies/getUserDiscrepancies`, data)
        .then((res) => {
          // console.log(res.data);
          setUserDiscrepancies(res.data);
        })
        .catch((err) => {
          console.log(`Could not get the discrepancy: ${err}`);
        });
    } catch (err) {
      console.log(`Could not get the discrepancy: ${err}`);
      setMessage(`Could not get selected discrepancy: ${err}`);
      setSeverity("error");
      showAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3500);
    }
  };

  return (
    <main className="view_discreps">
      <h1>View discrepancy charges</h1>
      <div className="row_select">
        {siteName ? (
          <strong>{siteName}</strong>
        ) : (
          <select onChange={(e) => setSite(e.target.value)}>
            <option>--Please select a site--</option>
            {siteList.map((site) => {
              return <option key={site.companyId}>{site.siteName}</option>;
            })}
          </select>
        )}
        <input
          type="month"
          min="2023-01"
          onChange={(e) => setMonth(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <IconButton onClick={handleMonth} disabled={search}>
          <SearchIcon color="primary" fontSize="large" />
        </IconButton>
      </div>
      {showAlert ? (
        <Alert severity={severity} variant="filled">
          {message}
        </Alert>
      ) : loader ? (
        <Loader />
      ) : null}
      {discrepancies.length > 0 ? (
        <section className="discrepancies">
          <div className="list">
            <h4>Discrepancies</h4>
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Left To be Charged</th>
                  <th>Total Discrep</th>
                </tr>
              </thead>
              <tbody>
                {discrepancies.map((discrep) => {
                  return (
                    <tr
                      key={discrep.eposStaffId}
                      onClick={() =>
                        handleDiscrepancy(
                          discrep.eposStaffId,
                          discrep.name,
                          discrep.sum,
                          discrep.letterSent
                        )
                      }
                    >
                      <td>{discrep.name}</td>
                      <td>{discrep.sum}</td>
                      <td>{discrep.totalDiscrep}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {userDiscrepancies.length > 0 ? (
            <UserDiscreps
              discrepancies={userDiscrepancies}
              name={staffName}
              month={month}
              total={total}
            />
          ) : (
            <h1 style={{ width: "60%", textAlign: "center" }}>
              Please select a discrepancy
            </h1>
          )}
        </section>
      ) : null}
    </main>
  );
}
