import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";

const RotaSettingsTable = ({ siteId, companyid }) => {
  const url = process.env.REACT_APP_API_URL;
  const [data, setData] = useState();
  const [open, setOpen] = useState();
  const [updatedHours, setUpdatedHours] = useState(null);
  const [currentChangeModel, setCurrentChangeModel] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    console.log(companyid)
    getLabourHourData(url, siteId);
  }, [siteId]);

  const getLabourHourData = async (url, siteId) => {
    url = url + `/rota/kpi?siteId=${siteId}`;
    const res = await axios
      .get(url)
      .then((res) => res)
      .catch((e) => console.log(e));
    if (res) setData(res.data);
  };

  const handleUpdateHours = async (url) => {
    url = url + `/rota/kpi/update`;
    let body = { ...data, ["hours"]: { ...data.hours, ...updatedHours } };
    const res = await axios
      .post(url, body)
      .then((res) => {
        // setLoading(false)
        return res;
      })
      .catch((e) => console.log(e));
    if (res) {
      setLoading(false);
      setData(res.data);
    }
    // if (res) hideDailog()
  };
  const onchangeHoursInput = (e, currentTargetDay, currentTargetHour) => {
    if (parseInt(e.target.value) <= 0 && parseInt(e.target.value)< 1000) {
      alert("target hours should be between 0 to 1000");
    }
    else{ 

      setUpdatedHours({ [currentTargetDay]: parseInt(e.target.value) });
    }
  };
  const openDialog = () => {
    let currentTargetDay = Object.keys(currentChangeModel)[0];
    let currentTargetHour = currentChangeModel[currentTargetDay];

    return (
      <Dialog
        open={open}
        onClose={hideDailog}
        PaperProps={{
          component: "form",
          onSubmit: async (event) => {
            event.preventDefault();
            setLoading(true);
            handleUpdateHours(url);
            hideDailog();
          },
        }}
      >
        <DialogTitle>
          Change labour hour target for {currentTargetDay}
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            <div>
              Current Target hours are:{" "}
              <Typography variant="body1">
                {currentTargetHour}
                {currentTargetHour > 1 ? " hrs." : " hr"}
              </Typography>
            </div>
          </DialogContentText>
          <br></br>
          <TextField
            autoFocus
            required
            margin="dense"
            id="hours"
            name="hours"
            label="Labour hour"
            type="number"
            fullWidth
            variant="standard"
            onChange={e=>onchangeHoursInput(e, currentTargetDay, currentTargetHour)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={hideDailog}>Cancel</Button>
          <Button type="submit">Update</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const showDailog = (e, idx) => {
    setCurrentChangeModel(idx);
    setOpen(true);
  };

  const hideDailog = () => {
    setCurrentChangeModel(null);
    setUpdatedHours(null);
    setLoading(false);
    setOpen(false);
  };

  const headerStyle = {
    backgroundColor: "#f4f4f4",
    textAlign: "left",
    padding: "10px",
    borderBottom: "2px solid #ddd",
  };

  const cellStyle = {
    borderBottom: "2px solid #ddd",
    textAlign: "left",
  };
  const rowStyle = {
    backgroundColor: "#fff",
  };
  const altRowStyle = {
    backgroundColor: "#f9f9f9",
  };
  return (
    <div>
      {open && openDialog()}
      <table
        style={{
          width: "100%",
          fontFamily: "Arial, sans-serif",
          fontSize: "14px",
          border: "2px solid #ddd",
          borderRadius: "8px",
          overflow: "hidden",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <thead>
          <tr>
            <td style={headerStyle}></td>
            {data &&
              Object.entries(data.hours).map(([weekday, hours]) => (
                <th key={weekday} style={headerStyle}>
                  {weekday}
                </th>
              ))}
          </tr>
        </thead>
        {loading == false ? (
          <tbody>
            <tr
              style={{
                ...rowStyle,
                borderRight: "2px solid #ddd",
              }}
            >
              <tr
                style={{
                  borderRight: "2px solid #ddd",
                }}
              >
                <Typography variant="h6" gutterBottom>
                  LABOUR HOUR TARGET
                  <br></br>
                  exc. Breaks
                </Typography>
              </tr>
              {data &&
                Object.entries(data.hours).map(([weekday, hours], idx) => {
                  return (
                    <td style={cellStyle}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          // padding:10,
                          alignItems: "center",
                          borderRight: "2px solid #ddd",
                        }}
                      >
                        <p style={{ minWidth: "70%", paddingLeft: 40 }}>
                          <Typography variant="h6" gutterBottom>
                            {hours} {hours > 1 ? " hrs." : " hr"}
                          </Typography>
                        </p>

                        <Button
                          onClick={(e) => {
                            showDailog(e, { [weekday]: hours });
                          }}
                        >
                          <EditIcon />
                        </Button>
                      </div>
                    </td>
                  );
                })}
            </tr>
          </tbody>
        ) : (
          <tbody>Loading...</tbody>
        )}
      </table>
    </div>
  );
};

export default RotaSettingsTable;
