import React from "react";
import { Box, Typography, Button } from "@mui/material";

const ZoneCheckModal = (props) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: "10px",
  };

  const closeModal = () => {
    props.handleClose();
  };

  const handleSave = async() => {
    props.setIsDoubleChecked(true);
    closeModal()
    await props.save(props.zone)
  }

  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        WARNING
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        This store zone does not have any products in it. Do you want to
        continue with saving it?
      </Typography>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          marginTop: "12px",
        }}
      >
        <Button variant="contained" color="primary" onClick={handleSave}>
          YES
        </Button>

        <Button
          variant="contained"
          color="error"
          onClick={() => {
            closeModal();
            // props.setIsDoubleChecked(true);
          }}
        >
          No
        </Button>
      </div>
    </Box>
  );
};

export default ZoneCheckModal;
