import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import ManagerNav from "../ManagerNav"
import FadeLoader from "react-spinners/FadeLoader";
import SendIcon from "@mui/icons-material/Send";
import ClaimProd from "./ClaimProd";
import axios from 'axios'
import Loader from "../../Fuel/loader/Loader";
import Tick from "../../animations/Tick";
import { Box, Button, Typography } from "@mui/material";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40vw",
    maxHeight: "25vh",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
};

export default function IndvClaim() {

    const [claimData, setClaimData] = useState({})
    let [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(false)
    const [bookerRef, setBookerRef] = useState("")
    const [claimId, setClaimId] = useState("")
    const [showOptions, setShowOptions] = useState(false)
    const [removeProd, setRemoveProd] = useState({})
    const [updateType, setUpdateType] = useState("")
    const [hideButtons, setHideButtons] = useState(false)
    const navigate = useNavigate();
    const [showLoader, setShowLoader] = useState(false)
    const [showTick, setShowtick] = useState(false);

    useEffect(async () => {
        setShowLoader(true)
        var cData = localStorage.getItem("claimData")
        setClaimId(JSON.parse(cData).claimId)
        await axios.get(`https://nitropos-backend.nitrosolutions.co.uk/claims/active/details/${JSON.parse(cData).claimId}`).then((res) => {
            setClaimData(res.data)
            setShowLoader(false)
        }).catch((err) => {
            console.log(err)
        })
    }, [reload])

    const Submit = async () => {
        setLoading(true)
        await axios.post(`https://nitropos-backend.nitrosolutions.co.uk/claims/active/submit`, { claimId: claimId, bookerClaimId: bookerRef, claimData: claimData }).then((res) => {
            setBookerRef("")
            setLoading(false)
            setShowtick(true)
            setTimeout(() => {
                setShowtick(false)
                navigate(`/managerZone/claims`);
            }, 3500)
        }).catch((err) => {
            console.log(err)
        })
    }

    const UpdateProduct = (index, key, value) => {
        console.log(`UPDATE DETAILS i:${index} | key:${key} | value:${value}`)
    }

    const displayRemoval = async (product) => {
        setHideButtons(false)
        setUpdateType("")
        setRemoveProd(product)
        setShowOptions(true)
    }

    const remove = async () => {
        await axios.post("https://nitropos-backend.nitrosolutions.co.uk/claims/active/update", removeProd).then((res) => {

            if (res.data.removed == true) {
                setUpdateType(`Claim Has Now Been Removed`)
                setHideButtons(true)
                setTimeout(() => {
                    setShowOptions(false)
                    navigate(`/managerZone/claims/`);
                }, 3500)
            } else {
                setUpdateType(`Claim Has Now Been Updated`)
                setHideButtons(true)
                setReload(!reload)
                setTimeout(() => {
                    setShowOptions(false)
                }, 2000)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    return (
        <main className="claims__main">
            <ManagerNav />
            <section className="claims">
                {showOptions ?
                    <>
                        <Box sx={style}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                ARE YOU SURE YOU WOULD LIKE TO REMOVE THIS LINE ITEM?
                            </Typography>
                            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#2c53a0" }}>
                                {updateType}
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                <div className="staff_modal">
                                    {!hideButtons ?
                                        <div className="modal__row">
                                            <Button color="success" variant="outlined" onClick={() => remove()}>
                                                Yes
                                            </Button>
                                            <Button color="error" variant="contained" onClick={() => setShowOptions(false)}>
                                                No
                                            </Button>
                                        </div>
                                        : <></>}

                                </div>
                            </Typography>
                        </Box>
                    </> : <></>}
                {showLoader ? (
                    <>
                        <div className="loader">
                            <Loader />
                        </div>
                        <h1>Loading Claim...</h1>
                    </>
                ) : (
                    <>
                        <div className="head">
                            <h4>{claimData.invoiceNumber == null ? (
                                "Manual Invoice"
                            ) : (
                                `${claimData.invoiceDate} - ${claimData.invoiceNumber}`
                            )}</h4>
                            <h4>{claimData.supplierName}</h4>
                            <h4>{claimData.siteName}</h4>
                        </div>
                        <table>
                            <thead>
                                <th>'M'<br /> Code</th>
                                <th>Description</th>
                                <th>Invoice Qty</th>
                                <th>Qty To Be Credited<br />Units</th>
                                <th>Reason Code</th>
                                <th>Supporting Images</th>
                                <th>Remove Line</th>
                            </thead>
                            <tbody>
                                {claimData.products ? (
                                    claimData.products.map(function (d, i) {
                                        return (
                                            <ClaimProd displayRemoval={displayRemoval} d={d} UpdateProduct={UpdateProduct} index={i} />
                                        )
                                    }))
                                    : <></>}

                            </tbody>
                        </table>
                        <div style={{ textAlign: "center", marginTop: "50px", color: "black" }}>
                            <p>Booker Claims Reference</p>
                            <input type="number" value={bookerRef} onChange={(e) => setBookerRef(e.target.value)} />
                        </div>

                        {loading ? (
                            <div className="loadingSection" style={{ textAlign: "center", color: "black" }}>
                                <h4>Your claim is being processed, please wait</h4>
                                <FadeLoader color="#2c53a0" loading={loading} size={500} />
                            </div>
                        ) : (
                            <Button
                                variant="outlined"
                                color="success"
                                endIcon={<SendIcon sx={{ margin: "0" }} />}
                                onClick={() => Submit()}
                                className="test2"
                            >
                                Submit
                            </Button>
                        )}
                        {showTick ? (
                            <div style={{padding:"20px"}}>
                                <Tick />
                                <br />
                            </div>
                        ) : (
                            <></>
                        )}
                    </>
                )}
            </section>
        </main>
    )
}