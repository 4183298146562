import "./styles/invoices.scss";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import TrainingLogo from "./images/RaceTrack-Ordering.png";
import RTWarehouse from "./images/RaceTrack_Warehouse.png"
import BookerLogo from "./images/booker-logo.png";
import LondisLogo from "./images/Londis.webp";
import StoreNav from "./StoreNav";
import V4U from "./images/v4uLogo.webp";
import Mortons from "./images/mortons.png";
import Tubbees from "./images/tubbees-logo.png";
import Loader from "../animations/Loader";
import { Button } from "@mui/material";

export default function Invoices() {
  const [data, setData] = useState([]);
  const [site, setSite] = useState("");
  const navigate = useNavigate();
  const [supplier, setSupplier] = useState();
  const [update, setUpdate] = useState();
  const [showLoader, setShowLoader] = useState(true);
  const url = process.env.REACT_APP_DEPPLOYED;

  useEffect(async () => {
    let s = localStorage.getItem("site");
    setSite(JSON.parse(s));
    let sup = localStorage.getItem("supplier");
    setSupplier(JSON.parse(sup));

    await axios
      // .get(`${url}/edn/?site=${JSON.parse(s)}&supplier=${JSON.parse(sup)}`)
      .get(`${url}/edn/all/pending?site=${JSON.parse(s)}`)
      .then((res) => {
        setData(res.data);
        setShowLoader(false)
        var updated = res.data[res.data.length - 1];
        setUpdate(updated.invoiceDate);
      })
      .catch((err) => {
        console.log(`Could not get invoices: ${err}`);
        setShowLoader(false)
      });
  }, []);

  function getInvoice(invoice) {
    localStorage.setItem("invoice", JSON.stringify(invoice));
    navigate(`/storezone/Orders`);
  }

  return (
    <section className="invoices">
      <StoreNav site={site} />
      <div className="invoiceTable">
        <h1>Open Invoices</h1>
        <div className="invoicesHeader" >
          <Button className="manualEdn" variant="contained" color="primary" onClick={() => navigate(`/storezone/manual-invoice`)}>Manual Invoice</Button>
          <p className="update">
            Last Update: <strong>{update}</strong>
          </p>
        </div>
        {showLoader ? (
          <Loader />
        ) : (
          <table id="invoices">
            <thead>
              <tr>
                <th>Supplier</th>
                <th>Invoice Number</th>
                <th>Invoice Date</th>
                <th>Total items</th>
              </tr>
            </thead>
            <tbody>
              {data ? (
                data.map(function (d, i) {
                  var back;
                  d.started ? (back = "orange") : (back = "");
                  var cr;
                  d.type === "Credit Note" ? (cr = "CREDIT NOTE") : (cr = "");

                  return (
                    <tr
                      onClick={() => getInvoice(d.invoiceNumber)}
                      style={{ cursor: "pointer", backgroundColor: back }}
                    >
                      <td style={{ textAlign: "center" }}>
                        {d.supplier === "Booker" ? (
                          <>
                            <img src={BookerLogo} width="150px" />
                            <br />
                            {d.type === "Credit Note" ? (
                              <p
                                className="creditNote"
                                style={{
                                  textAlign: "center",
                                  margin: "auto",
                                }}
                              >
                                CREDIT NOTE
                              </p>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : d.supplier.includes("V4U") ? (
                          <>
                            <img src={V4U} width="175px" />
                            <br />
                            {d.supplier}
                          </>
                        ) : d.supplier === "Londis" ? (
                          <>
                            <img src={LondisLogo} width="175px" />
                            <br />
                          </>
                        ) : d.supplier === "Morton" ? (
                          <>
                            <br />
                            <img src={Mortons} width="175px" />
                            <br />
                          </>
                        ) : d.supplier.includes("Tubbees") ? (
                          <>
                            <img src={Tubbees} width="175px" />
                            <br />
                          </>
                        ) : d.invoiceNumber.includes("PO_") ?
                          <>
                            <img src={RTWarehouse} width="175px" />
                            <br />
                            {d.supplier}
                          </>
                          :
                          (
                            <>
                              <img src={TrainingLogo} width="175px" />
                              <br />
                              {d.supplier}
                            </>
                          )}
                      </td>
                      <td className="invoiceData" >{d.invoiceNumber}</td>
                      <td className="invoiceData" >{d.invoiceDate}</td>
                      <td className="invoiceData" >{d.productsOrdered.length}</td>
                    </tr>
                  );
                })
              ) : (
                <></>
              )}
            </tbody>
          </table>
        )}
      </div>
    </section>
  );
}
