import { Box } from "@mui/material";

const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "90vw",
    maxHeight: "100vh",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    overflowY: "scroll",
};

export default function EodImage(props) {
    return (
        <Box sx={boxStyle}>
            <img src={props.image} width="100%"/>
        </Box>
    )
}