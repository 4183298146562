import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "./modals.scss";
import { useState } from "react";
import Button from "@mui/material/Button";

const coststyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40vw",
  height: "40vh",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
};


export default function WriteModal(props){
    const [note, setNote] = useState("")

    const closeModal = () => {
        setNote("")
        props.handleClose()
    }
    

    return (
        <Box sx={coststyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Write Investigation Off
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className="staff_modal">
              <div className="modal__row">
                <div className="row__column">
                <label>Please explain why you are writing it off:</label>
                <textarea cols={50} rows={10} onChange={(e) => setNote(e.target.value)} />
                </div>
              </div>
              <div className="modal__row">
                <Button color="success" variant="outlined" onClick={() => props.handleWrite(note)}>
                  Submit
                </Button>
                <Button color="error" variant="contained" onClick={() => closeModal()}>
                  Cancel
                </Button>
              </div>
            </div>
          </Typography>
        </Box>
      );
    

}