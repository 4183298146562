import { useEffect, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import v4uLogo from '../images/v4uLogo.webp'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import axios from 'axios'
import QrReader from "react-web-qr-reader";
import Tick from '../../animations/Tick';
import Cross from '../../animations/Cross';
import '../styles/promotion.scss'

export default function VapePromotion() {

    const previewStyle = {
        height: 240,
        width: 320
      };

    const [showPass, setShowPass] = useState(false)
    const [showFail, setShowFail] = useState(false)
    const [code, setCode] = useState("")
    const [message, setMessage] = useState("")
    const [redeemed, setRedeemed] = useState(false)
    const [site, setSite] = useState("")
    const [showQr, setShowQr] = useState(true)
    const [errMsg, setErrMsg] = useState("")

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        var siteUrl = urlParams.get("site")
        if (siteUrl) {
            setSite(siteUrl)
        } else {
            console.log("Site Not Found")
        }
    }, [])

    const searchCode = async (qr) => {
        setShowQr(false)
        await axios.post(`https://nitropos-backend.nitrosolutions.co.uk/vapePromotion/search`, { code: qr }).then((res) => {
            if (!res.data.used) {
                setMessage(res.data.amount)
                if(res.data.amount=="100%"){
                    setMessage("100% OFF ELF BAR CIGALIKE")
                }else{
                    setMessage("50% OFF ANY DISPOSABLE")
                }
                setShowPass(true)
            }
        }).catch((err) => {
            setErrMsg(err.response.data)
            setShowFail(true)
            setTimeout(() => {
                setCode("")
                setShowFail(false)
            }, 3000)
        })
    }

    const clearRequest = async () => {
        setMessage("")
        setCode("")
        setShowPass(false)
        setRedeemed(false)
        setShowQr(true)
    }

    const redeemCode = async () => {
        await axios.post(`https://nitropos-backend.nitrosolutions.co.uk/vapePromotion/redeem`, { site: site, code: code }).then((res) => {
            if (res.data) {
                setRedeemed(true)
                setTimeout(() => {
                    clearRequest()
                }, 2500)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    const handleEnter = (e) => {
        if (e.keyCode === 13) {
            searchCode(code);
        }
    };

    const handleError = (error)=>{
        console.log(error)
    }

    const handleScan = async(result)=>{
        console.log(result)
        setCode(result.data)
        await searchCode(result.data)
    }

    return (
        <main className='vapePromotion'>

            <img src={v4uLogo} height="100" />

            <div className='searchBar'>
                <input placeholder="Enter Code...." type="text" value={code} onChange={(e) => { setCode(e.target.value) }} onKeyDown={handleEnter} />
                <SearchIcon fontSize="large" color="primary" onClick={() => searchCode(code)} />
            </div>

            {showQr ?
            <>
                <div className="qrScan">
                    <h3>SCAN QR CODE</h3>

                    <QrReader
                        delay={500}
                        style={previewStyle}
                        onError={handleError}
                        onScan={handleScan}
                    />
                </div>
                <div style={{position:"absolute",bottom:"20px",width:"5%",margin:"auto"}}>
                        <QrCodeScannerIcon fontSize="large" color="primary" onClick={() => setShowQr(false)} />
                    </div>
                </>
                : <>
                <div style={{position:"absolute",bottom:"20px",width:"5%",margin:"auto"}}>
                <QrCodeScannerIcon fontSize="large" color="primary" onClick={() => setShowQr(true)} />
            </div></>
            }

            {showPass ?
                <>
                    <Tick />
                    <h1>DISCOUNT - {message}</h1>

                    {redeemed ?
                        <p>CODE SUCCESSFULLY REDEEMED!<br/><br/>NOW APPLY DISCOUNT ON TILL</p>
                        :
                        <div className='optionBtns'>
                            <CheckCircleIcon color="success" className="btn" onClick={redeemCode} />
                            <CancelIcon color="error" className="btn" onClick={clearRequest} />
                        </div>
                    }

                </>
                :
                showFail ?
                    <>
                        <Cross />
                        <h1>{errMsg}</h1>
                    </>
                    :
                    <></>
            }

        </main>
    )
}