import { useEffect, useState } from "react";
import Tank from "./Tank";
import "./tanks.scss";
import axios from "axios";
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#2c53a0"),
  backgroundColor: "#2c53a0",
  color: "#64c4e0",
  '&:hover': {
    backgroundColor: "#fff",
    color: "#2c53a0"
  },
}));

export default function Tanks(props) {
  const [site, setSite] = useState("");
  const [tanks, setTanks] = useState([]);
  const [update, setUpdate] = useState(false)
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    setSite(props.site);
    axios
      .get(
        `https://nitropos-backend.nitrosolutions.co.uk/fuel/tanks?site=${props.site}`
      )
      .then((res) => {
        setTanks(res.data.tanks);

        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.site, refresh]);

  const reset = () => {
    setUpdate(!update)
    setRefresh(!refresh)
  }

  return (
    <section className="tanks">
      { }
      <h3>
        Volumes for: <span>{site}</span>
      </h3>
      <div className="allTanks">
        {tanks.map(function (d, i) {
          return <Tank reset={reset} salesDate={d.salesDate} date={d.date} site={props.site} tankId={d.tankId} tank={d.tank} grade={d.grade} stock={d.stock} capacity={d.capacity} update={update} />;
        })}
      </div>
      <ColorButton variant="outlined" color="primary" onClick={() => setUpdate(!update)} className="button">New Reading</ColorButton>
    </section>
  );
}
