import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import FormLabel from '@mui/material/FormLabel';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40vw",
  height: "60vh",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
  "@media(max-width: 600px)": {
    width: "90vw",
    maxHeight: "80vh",
  },
};

export default function NewLabels(props) {
  const [barcodes, setBarcodes] = useState([]);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    console.log(props.site)
    axios
      .post(`https://nitropos-backend.nitrosolutions.co.uk/labels/barcodes/${props.site}`)
      .then((res) => {
        console.log(res.data.barcodes)
        setBarcodes(res.data.barcodes);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleSelect = (barcode) => {
    // console.log(barcode)
    if (selected.includes(barcode)){
      let filter = selected.filter((b) => b != barcode)
      setSelected(filter)
    }else {
        setSelected(selected => ([...selected, barcode]));
    }
  };

  const close = () => {
    axios
      .post(`https://nitropos-backend.nitrosolutions.co.uk/labels/${props.site}/new/${false}`)
      .then((res) => {
        props.close();
        setSelected([])
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const add = () => {
    axios
    .post(`https://nitropos-backend.nitrosolutions.co.uk/labels/${props.site}/new/${true}`)
    .then((res) => {
        props.add(selected);
        props.close();
    })
    .catch((err) => {
      console.log(err);
    });

  }
  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        New Items detected, do you need any of these labels?
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <FormLabel component="legend">Select All Labels you need</FormLabel>
        {barcodes.map(function(d,i){
            return(
                <div style={{display: "flex", alignItems: "center", justifyContent: "flex-start"}}>
                <input type="checkbox" name={d.name} value={d.barcode} onChange={() => handleSelect(d.barcode)}/>
                <label for={d.name} style={{color: "#000"}}>{d.name}</label>
                </div>
            )
        })}
        <Stack spacing={2} direction="row" sx={{ width: "40%", margin: "2% auto" }}>
          <Button
            variant="outlined"
            color="error"
            sx={{ margin: "auto" }}
            onClick={() => close()}
          >
            Close
          </Button>
          <Button variant="contained" color="success" onClick={() => add()}>
            Add
          </Button>
        </Stack>
      </Typography>
    </Box>
  );
}
