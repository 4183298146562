import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import "../investigation.scss";
import Alert from "@mui/material/Alert";
import axios from 'axios';
import Tick from "../../../animations/Tick";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
  maxHeight: "80vh",
  overflowY: "scroll",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
};

export default function HrDiscrepModal(props) {
  const [data, setData] = useState(props.data);
  const [submitAlert, setSubmitAlert] = useState(false);
  const [warningAlert, setWarningAlert] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [user, setUser] = useState("")
  const [showTick, setShowTick] = useState(false)

  useEffect(() => {
    let userD = localStorage.getItem("userDetails");
    let user = JSON.parse(userD);
    setUser(user.user.user);
  })

  const confirmSubmit = () => {
    document.getElementById("discrep_btns").style.display = "none";
    let object = {
        user: user,
        site: props.site,
        month: props.month,
        discrepancies: data,
    }
    axios.post("https://nitropos-backend.nitrosolutions.co.uk/hr/finaliseMonth", object)
    .then((res) => {
        setShowTick(true)
        setTimeout(() => {
            setShowTick(false)
            document.getElementById("discrep_btns").style.display = "flex";
            props.rel()
            props.close()
        },3500)
    }).catch((err) => {
        console.log(err)
        setSeverity("warning")
        setMessage("Something went wrong, please try again")
        setSubmitAlert(true)
        setTimeout(() => {
            setSubmitAlert(false)
            setSeverity("")
            setMessage("")
            document.getElementById("discrep_btns").style.display = "flex";
        },3500)
    })
  }
  

  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Some discrepancies have not been marked in payroll
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <div className="confirm_dicreps_modal">
          <table>
            <thead>
              <td>Name</td>
              <td>Transaction Date</td>
              <td>Cost</td>
            </thead>
            <tbody>
              {data.map(function (d, i) {
                return (
                  <tr>
                    <td>{d.name}</td>
                    <td>
                      {new Date(d.transactionDate).toLocaleDateString("en-GB")}
                    </td>
                    <td>{d.cost}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="discreps_buttons" id="discrep_btns">
            <Button
              variant="outlined"
              color="error"
              onClick={() => props.close()}
            >
              Cancel
            </Button>
            <Button variant="contained" color="success" onClick={() => confirmSubmit()}>
              Finalise
            </Button>
          </div>
          {submitAlert ? <Alert severity={severity}>{message}</Alert> : <></>}
          {showTick ? <Tick /> : <></>}
        </div>
      </Typography>
    </Box>
  );
}
