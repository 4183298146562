import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

import { useState, useEffect } from "react";
import { Button } from "@mui/material";

import EposProductTableRow from "./EposProductTableRow"
import InventoryIcon from '@mui/icons-material/Inventory';

export default function AccordionSection({
    title,
    details,
    eposProducts,
    promotionLink,
    handleModelAlert,
    handleReload
}) {


    const [expanded, setExpanded] = useState(false);
    const [bgColour, setBgColour] = useState("#FFFFFF");
    const [checkedValue, setCheckedValue] = useState(false);

    useEffect(() => {

        setBgColour("#FFFFFF");
        setCheckedValue(false);
        setExpanded(false);

    }, [title]);

    const handleSelect = (bool) => {

        setCheckedValue(bool);
        if (!bool) {
            setBgColour("#FFFFFF")
        } else {
            setBgColour(`rgba(0, 255, 51, 0.3)`)
        }
    }

    return (

        <Accordion
            defaultExpanded={false}
            expanded={expanded}
            className="accodion_main"
            style={{
                backgroundColor: bgColour
            }}
        >
            <AccordionSummary
                expandIcon={<KeyboardDoubleArrowDownIcon
                    //ExpandMoreIcon
                    onClick={() => setExpanded(!expanded)}
                    className="expand_icon"
                />}
                style={{
                    textAlign: "center",
                    justifyContent: "space-between"
                }}
                className="accordion_summary"
            >
                <div className='accordion_summary'>
                    <p>{title}</p>

                    <div className='accordion_summary_icons'>
                        {promotionLink != null ? (
                            <Button
                                variant="contained"
                                color="primary"
                                href={promotionLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Epos Promotion
                            </Button>
                        ) :
                            <>
                                {/* success */}
                                {eposProducts != null ? (
                                    <InventoryIcon
                                        color={eposProducts.length != 1  || eposProducts == null
                                            ? "error" : "success"}
                                    />
                                ) : (
                                    <InventoryIcon
                                        color={"error"}
                                    />
                                )}
                                <Checkbox
                                    onChange={(e) => handleSelect(e.target.checked)}
                                    checked={checkedValue}
                                />

                            </>
                        }
                    </div>
                </div>
            </AccordionSummary>

                    <hr/>

            <AccordionDetails
                className="accordion_details_header"
            >
                {details.map(function (detail, i) {
                    return (
                        <p>{detail.name} : {detail.value} </p>
                    )
                })}
            </AccordionDetails>
            
            {eposProducts != null ? (
            <AccordionDetails>
                <table className="epos-prod-table">
                    <tr>
                        <th>Epos Product</th>
                        <th>Barcode</th>
                        <th>Order Code</th>
                        <th>Category</th>
                        <th>Price</th>
                        <th>Epos Link</th>
                    </tr>

                    {eposProducts.map(function (product, i) {
                        return (
                            <EposProductTableRow
                                product={product}
                                bookerBarcode={promotionLink != null ? details.filter((detail) => detail.name == "Barcode") : null}
                                handleModelAlert={handleModelAlert}
                                baseRRP={details.filter((detail) => detail.name == "RRP").pop()}
                            />
                        )
                    })}

                </table>
            </AccordionDetails>
            ) : (
                <p>NO LINKING EPOS PRODUCTS FOUND</p>
            ) }
        </Accordion>
    )


}