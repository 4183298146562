import NMOPIndiviudalStatus from "./NMOPIndividualStatus";

export default function NMOPDetails({ rel, siteName, statusList, nmop }) {
    const requests = nmop;

    return (
        <main className="driveoff_cards">
            <h3>
                <u>Open NMOPs for {siteName}</u>
            </h3>

            {requests.length > 0 ? (
                <table>
                    <thead>
                        <tr>
                            <th>Date/Time</th>
                            <th>Registration</th>
                            <th>Status</th>
                            <th>See Contract</th>
                            <th>Phone Number</th>
                            <th>Time to Drive Off</th>
                            <th>Images</th>
                            <th>Notes</th>
                            <th>Mark Paid</th>
                        </tr>
                    </thead>
                    <tbody>
                        {requests.map((request, i) => {
                            return (
                                <NMOPIndiviudalStatus
                                    request={request}
                                    rel={rel}
                                    key={i}
                                    siteName={siteName}
                                    statusList={statusList}
                                />
                            );
                        })}
                    </tbody>
                </table>
            ) : (
                <p>No NMOPs</p>
            )}
        </main>
    );
}
