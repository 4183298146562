import bp from "./images/bp.png";
import gulf from "./images/gulf.png";
import { useEffect, useState } from "react";
import axios from "axios";
import Banner from "./Banner";

export default function SitePrices() {
  const [allSites, setAllSites] = useState([]);
  useEffect(() => {
    axios
      .get("https://nitropos-backend.nitrosolutions.co.uk/fuel/allPrices")
      .then((res) => {
        setAllSites(res.data);
        //console.log(res.data)
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <main>
      <section className="sites">
        {allSites.map(function (d, i) {
          var type;
          d.type == "BP" ? (type = bp) : (type = gulf);
          var back;
          d.type == "BP" ? (back = "green") : (back = "navy");
          return (
            <Banner data={d} key={i} back={back} type={type} />
          );
        })}
      </section>
    </main>
  );
}
