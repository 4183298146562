import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import axios from "axios";
import logo from "../../../StoreZone/images/rt-logo.png";
import Alert from "@mui/material/Alert";
import { useState } from "react";

//STYLING
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "30vw",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
    "@media(max-width: 600px)": {
        width: "90vw",
        maxHeight: "70vh",
        overflowX: "scrollable",
    },
};

export default function ConvertToDriveOff({ setShowConversionModal, id, rel }) {
    const url = process.env.REACT_APP_REACT_APP_ANPR_URL;
    const [alert, setAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");

    const handleConvertToDriveOff = () => {
        let data = {
            requestId: id,
        };

        axios
            .post(`${url}/cctv-system/nmop/conversion`, data)
            .then((res) => {
                setMessage("successfully converted");
                setSeverity("success");
                setAlert(true);
                setTimeout(() => {
                    setAlert(false);
                    setMessage("");
                    setSeverity("");
                    setShowConversionModal(false);
                    rel();
                }, 2500);
            })
            .catch((err) => {
                console.log(err);
                setMessage("something went wrong, please try again");
                setSeverity("error");
                setAlert(true);
                setTimeout(() => {
                    setAlert(false);
                    setMessage("");
                    setSeverity("");
                }, 2500);
            });
    };
    return (
        <Box sx={style}>
            <Typography>
                <img src={logo} alt="logo" width="25%" />
            </Typography>
            <Typography id="modal-modal-title" variant="h6" component="h4">
                Drive Of Conversion
            </Typography>
            <Typography id="modal-modal-description">
                <p>WOULD YOU LIKE TO CONVERT THIS TO A DRIVEOFF?</p>
                {!alert ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-around",
                        }}
                    >
                        <Button
                            color="error"
                            variant="contained"
                            onClick={() => setShowConversionModal(false)}
                        >
                            NO
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleConvertToDriveOff}
                        >
                            YES
                        </Button>
                    </div>
                ) : (
                    <Alert severity={severity}>{message}</Alert>
                )}
            </Typography>
        </Box>
    );
}
