import "./eod.scss";
import { useEffect, useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Tick from "../../animations/Tick.js";
import IndvRecon from "./modal/IndvRecon";
import Discrepancy from "./modal/Discrepancy";
import DriveOffMod from "./modal/DriveOffMod";
import NitroLoader from "../../Fuel/loader/NitroLoader";
import QRCode from "react-qr-code";
import Refund from "./Refund";
import Discount from "./Discount";
import WrongDiscrep from "./modal/WrongDiscrep";
import moment from "moment";
import PaidIcon from "@mui/icons-material/Paid";
import { Tooltip } from "@mui/material";
import MiscItems from "./shiftRecon/MiscItems/MiscItems";

export default function ShiftRecon() {
    const [user, setUser] = useState("");
    const [role, setRole] = useState("");
    const [sites, setSites] = useState([]);
    const [site, setSite] = useState("");
    const [message, setMessage] = useState("");
    const [errorAlert, setErrorAlert] = useState(false);
    const [siteName, setSiteName] = useState("");
    const [showShiftGrouping, setShowShiftGrouping] = useState(false);
    const [shiftGrouping, setShiftGrouping] = useState([]);
    const [showShifts, setShowShifts] = useState(false);
    const [shiftGroupingData, setShiftGroupingData] = useState([]);
    const [shiftReconData, setShiftReconData] = useState([]);
    const [eodNotFinished, setEodNotFinished] = useState(false);
    const [shiftRecon, setShiftRecon] = useState([]);
    const [showDenominations, setShowDenominations] = useState(false);
    const [actual, setActual] = useState(0);
    const [fifty, setFifty] = useState(0);
    const [twenty, setTwenty] = useState(0);
    const [tenner, setTenner] = useState(0);
    const [fiver, setFiver] = useState(0);
    const [two, setTwo] = useState(0);
    const [pound, setPound] = useState(0);
    const [fiftyp, setFiftyp] = useState(0);
    const [twentyp, setTwentyp] = useState(0);
    const [tenp, setTenp] = useState(0);
    const [five, setFivep] = useState(0);
    const [twop, setTwop] = useState(0);
    const [onep, setOnep] = useState(0);
    const [discrepancy, setDiscrepancy] = useState(0);
    const [expected, setExpected] = useState(0);
    const [expectedEpos, setExpectedEpos] = useState(0);
    const [eodId, setEodId] = useState();
    const [showReference, setShowReference] = useState(false);
    const [reference, setReference] = useState("");
    const [payInSlip, setPayInSlip] = useState("");
    const [showDriveOff, setShowDriveOff] = useState(false);
    const [showOpenDriveOff, setShowOpenDriveOff] = useState(false);
    const [driveOffData, setDriveOffData] = useState([]);
    const [driveOff, setDriveOff] = useState("");
    const [showNotes, setShowNotes] = useState(false);
    const [note, setNote] = useState("");
    const [showTick, setShowtick] = useState(false);
    const [showRefunds, setShowRefunds] = useState(false);
    const [dates, setDates] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [refundData, setRefundData] = useState([]);
    const [showDiscounts, setShowDiscounts] = useState(false);
    const [discountsData, setDiscountsData] = useState([]);
    const [showPayouts, setShowPayouts] = useState(false);
    const [showMiscItems, setShowMiscItems] = useState(false);
    const [payoutData, setPayoutData] = useState([]);
    const [lotteryData, setLotteryData] = useState([]);
    const [showKeyFuels, setShowKeyFuels] = useState(false);
    const [keyfuelsData, setKeyfuelsData] = useState();
    const [keyfuelActual, setKeyFuelActual] = useState("");
    const [supplierData, setSupplierData] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [couponSum, setCouponSum] = useState(0);
    const [couponBarcode, setCouponBarcode] = useState("");
    const [couponBarcodes, setCouponBarcodes] = useState([]);
    const [open, setOpen] = useState(false);
    const [autoSave, setAutoSave] = useState(false);
    const [showEodOverview, setShowEodOverview] = useState(false);
    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");
    const [bagNote, setBagNote] = useState("");
    const [driveoffs, setDriveoffs] = useState([]);
    const [driveNotes, setDriveNotes] = useState([]);
    const [miscItems, setMiscItems] = useState([]);
    const url = "https://nitropos-backend.nitrosolutions.co.uk";
    //const url = "http://10.0.0.174:4022";

    const handleOpen = () => {
        let total =
            fifty +
            twenty +
            tenner +
            fiver +
            two +
            pound +
            fiftyp +
            twentyp +
            tenp +
            five +
            twop +
            onep;
        console.log(total);
        setActual(total);
        //if the discrepancy between shift recon and expected is not 0
        if (total.toFixed(2) - expected != 0) {
            handleOpenWrongDiscrep();
        } else {
            setDiscrepancy(total - expectedEpos);
            setOpen(true);
        }
    };
    const handleClose = () => setOpen(false);
    const [openWrongDiscrep, setOpenWrongDiscrep] = useState(false);
    const closeWrongDiscrep = () => setOpenWrongDiscrep(false);
    const handleOpenWrongDiscrep = () => setOpenWrongDiscrep(true);

    const [openDriveOff, setOpenDriveOff] = useState(false);
    const handleOpenDrive = () => setOpenDriveOff(true);
    const handleCloseDrive = () => setOpenDriveOff(false);

    useEffect(async () => {
        setShiftGrouping([]);
        let userD = localStorage.getItem("userDetails");
        let user = JSON.parse(userD);
        setUser(user.user.user);
        setRole(user.user.role);
        await axios
            .post(`${url}/eposSQL/getSitesByName`, { sites: user.user.sites })
            .then(async (res0) => {
                //managers with only one site
                if (res0.data.length === 1) {
                    setSite(res0.data[0].eposId);
                    setSiteName(res0.data[0].siteName);
                    await axios
                        .get(
                            `${url}/eos/awaitingEOD/${res0.data[0].eposId}` // `http://10.0.0.34:4022/eos/awaitingEOD/${res0.data[0].eposId}`
                        )
                        .then(async (res) => {
                            if (res.data.eod) {
                                setEodId(res.data.eod.endOfDayId);
                                var data = {};
                                await axios
                                    .get(
                                        `${url}/eos/getEodShifts/${res.data.eod.endOfDayId}`
                                    )
                                    .then(async (resp) => {
                                        setShiftGrouping(resp.data);
                                        data.firstDate = resp.data[0];
                                        data.lastDate =
                                            resp.data[resp.data.length - 1];
                                        data.site = res0.data[0].eposId;
                                    })
                                    .catch((err) => {
                                        console.log(err);
                                    });
                                setStart(
                                    new Date(
                                        data.firstDate.startTime
                                    ).toLocaleString()
                                );
                                setEnd(
                                    new Date(
                                        data.lastDate.endTime
                                    ).toLocaleString()
                                );
                                setShowEodOverview(true);
                                setAutoSave(true);
                                setShowLoader(true);
                                //if the day has been started and not finished
                                switch (res.data.eod.eodStatusId) {
                                    case 2:
                                        await axios
                                            .get(
                                                `${url}/eos/getDenomCount/${res.data.eod.endOfDayId}`
                                            )
                                            .then(async (res1) => {
                                                setExpectedEpos(
                                                    res1.data.eposAmount
                                                );
                                                setExpected(
                                                    res1.data.actualSum
                                                );
                                                setAutoSave(false);
                                                setShowLoader(false);
                                                setShowDenominations(true);
                                            })
                                            .catch((err) => {
                                                console.log(err);
                                                setMessage(
                                                    "Something went wrong, please try again"
                                                );
                                                setErrorAlert(true);
                                                setTimeout(() => {
                                                    setErrorAlert(false);
                                                }, 3000);
                                            });
                                        break;
                                    case 3:
                                        await axios
                                            .get(
                                                `${url}/eos/denominationData/${res.data.eod.endOfDayId}`
                                            )
                                            .then(async (res3) => {
                                                setActual(res3.data.actual);
                                                setDiscrepancy(
                                                    res3.data.discrepancy
                                                );
                                                setAutoSave(false);
                                                setShowLoader(false);
                                                setShowReference(true);
                                            })
                                            .catch((err) => {
                                                console.log(err);
                                                setMessage(
                                                    "Something went wrong, please try again"
                                                );
                                                setErrorAlert(true);
                                                setTimeout(() => {
                                                    setErrorAlert(false);
                                                }, 3000);
                                            });
                                        break;
                                    case 4:
                                        await axios
                                            .post(`${url}/eos/refunds`, {
                                                id: res.data.eod.endOfDayId,
                                            })
                                            .then(async (res2) => {
                                                setRefundData(res2.data);
                                                setShowRefunds(true);
                                                setAutoSave(false);
                                                setShowLoader(false);
                                            })
                                            .catch((err) => {
                                                console.log(err);
                                            });
                                        break;
                                    case 5:
                                        await axios
                                            .post(`${url}/eos/discounts`, {
                                                id: res.data.eod.endOfDayId,
                                            })
                                            .then(async (res5) => {
                                                setAutoSave(false);
                                                setShowLoader(false);
                                                setDiscountsData(res5.data);
                                                setShowDiscounts(true);
                                            })
                                            .catch((err) => {
                                                console.log(err);
                                            });
                                        break;
                                    case 6:
                                        await axios
                                            .get(
                                                `${url}/eos/misc-items/${res.data.eod.endOfDayId}`
                                            )
                                            .then(async (res) => {
                                                setMiscItems(res.data);
                                                setShowLoader(false);
                                                setShowMiscItems(true);
                                                setShowDiscounts(false);
                                            })
                                            .catch((err) => {
                                                console.log(err);
                                                setShowLoader(false);
                                            });
                                        break;
                                    case 7:
                                        await axios
                                            .post(`${url}/eos/lottery`, {
                                                id: res.data.eod.endOfDayId,
                                            })
                                            .then(async (res6) => {
                                                setPayoutData(
                                                    res6.data.payouts
                                                );
                                                setSupplierData(
                                                    res6.data.supplier
                                                );
                                                setCouponSum(res6.data.coupon);
                                                setAutoSave(false);
                                                setShowLoader(false);
                                                setShowPayouts(true);
                                            })
                                            .catch((err) => {
                                                setShowLoader(false);
                                            });
                                        break;
                                    case 8:
                                        let data = { siteId: site };
                                        axios
                                            .post(
                                                `${url}/eos/all-driveoff`,
                                                data
                                            )
                                            .then((res) => {
                                                setDriveoffs(res.data);
                                                // setAutoSave(false);
                                                // setShowLoader(false);
                                                // setShowDriveOff(true);
                                            })
                                            .catch((err) => {
                                                console.log(
                                                    `Could not get the drive offs: ${err}`
                                                );
                                            });
                                        setAutoSave(false);
                                        setShowLoader(false);
                                        setShowDriveOff(true);

                                        break;
                                    case 9:
                                        axios
                                            .post(`${url}/eos/keyfuels`, {
                                                id: res.data.eod.endOfDayId,
                                            })
                                            .then((res8) => {
                                                setAutoSave(false);
                                                setShowLoader(false);
                                                setKeyfuelsData(res8.data);
                                                setShowKeyFuels(true);
                                            })
                                            .catch((err) => {
                                                setMessage(
                                                    "Something went wrong, please try again"
                                                );
                                                setErrorAlert(true);
                                                console.log(err);
                                                setTimeout(() => {
                                                    setMessage("");
                                                    setErrorAlert(false);
                                                }, 3000);
                                            });
                                        break;
                                    case 10:
                                        setAutoSave(false);
                                        setShowLoader(false);
                                        setShowNotes(true);
                                        break;
                                    case 11:
                                        setAutoSave(false);
                                        setShowLoader(false);
                                        setShowNotes(true);
                                        break;
                                    default:
                                        setAutoSave(false);
                                        setShowLoader(false);
                                        setShiftRecon(res.data.data);
                                        setEodNotFinished(true);
                                        setShowShifts(true);
                                        let shifts = res.data.data;
                                        //sorts by shifts date
                                        shifts.sort(
                                            (a, b) =>
                                                new Date(a.startTime) -
                                                new Date(b.startTime)
                                        );
                                        setDates(shifts);
                                }
                                // console.log(res.data.data);
                            } else {
                                //compile the shifts
                                setShiftGroupingData(res.data);
                                setShowShiftGrouping(true);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } else {
                    setSites(res0.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        axios
            .get(`${url}/managerZone/suppliers`)
            .then((res) => {
                setSuppliers(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    //next functions handle the denominations to not allow for wrong values
    const handleFifty = (value) => {
        let item = document.getElementById("fifty");
        if (value.length >= 2) {
            let multiple = value % 50;
            if (multiple == 0) {
                if (item.classList.contains("shake")) {
                    item.classList.remove("shake");
                }
                setFifty(parseInt(value));
            } else {
                item.classList.add("shake");
            }
        } else if (value == 0) {
            setFifty(parseInt(value));
        }
    };

    const handleTwenty = (value) => {
        let item = document.getElementById("twenty");
        if (value.length >= 2) {
            let multiple = value % 20;
            if (multiple == 0) {
                if (item.classList.contains("shake")) {
                    item.classList.remove("shake");
                }
                setTwenty(parseInt(value));
            } else {
                item.classList.add("shake");
            }
        } else if (value == 0) {
            setTwenty(parseInt(value));
        } else if (value == 0) {
            setTwenty(parseInt(value));
        }
    };

    const handleTenner = (value) => {
        let item = document.getElementById("tenner");
        if (value.length >= 2) {
            let multiple = value % 10;
            if (multiple == 0) {
                if (item.classList.contains("shake")) {
                    item.classList.remove("shake");
                }
                setTenner(parseInt(value));
            } else {
                item.classList.add("shake");
            }
        } else if (value == 0) {
            setTenner(parseInt(value));
        }
    };

    const handleFiver = (value) => {
        let item = document.getElementById("fiver");
        let multiple = value % 5;
        if (multiple == 0) {
            if (item.classList.contains("shake")) {
                item.classList.remove("shake");
            }
            setFiver(parseInt(value));
        } else {
            item.classList.add("shake");
        }
    };

    const handleTwoPounds = (value) => {
        let item = document.getElementById("two__pounds");
        let multiple = value % 2;
        if (multiple == 0) {
            if (item.classList.contains("shake")) {
                item.classList.remove("shake");
            }
            setTwo(parseInt(value));
        } else {
            item.classList.add("shake");
        }
    };

    const handlePound = (value) => {
        let item = document.getElementById("pounds");
        let multiple = value % 1;
        if (multiple == 0) {
            if (item.classList.contains("shake")) {
                item.classList.remove("shake");
            }
            setPound(parseInt(value));
        } else {
            item.classList.add("shake");
        }
    };

    const handleFiftyP = (value) => {
        let item = document.getElementById("fifty__p");
        let multiple = value % 0.5;
        if (multiple == 0) {
            if (item.classList.contains("shake")) {
                item.classList.remove("shake");
            }
            setFiftyp(parseFloat(value));
        } else {
            item.classList.add("shake");
        }
    };

    const handleTwentyP = (value) => {
        let item = document.getElementById("twenty__p");
        let multiple = (value % 0.2).toFixed(2);
        console.log(multiple);
        if (multiple == 0.0 || multiple == 0.2) {
            if (item.classList.contains("shake")) {
                item.classList.remove("shake");
            }
            setTwentyp(parseFloat(value));
        } else {
            item.classList.add("shake");
            console.log("bad");
        }
    };

    const handleTenP = (value) => {
        let item = document.getElementById("ten__p");
        let multiple = (value % 0.1).toFixed(2);
        if (multiple == 0.0 || multiple == 0.1) {
            if (item.classList.contains("shake")) {
                item.classList.remove("shake");
            }
            setTenp(parseFloat(value));
        } else {
            item.classList.add("shake");
        }
    };

    const handleFiveP = (value) => {
        let item = document.getElementById("five__p");
        let multiple = (value % 0.05).toFixed(2);
        if (multiple == 0.0 || multiple == 0.05) {
            if (item.classList.contains("shake")) {
                item.classList.remove("shake");
            }
            setFivep(parseFloat(value));
        } else {
            item.classList.add("shake");
        }
    };

    const handleTwoP = (value) => {
        let item = document.getElementById("two__p");
        let multiple = (value % 0.02).toFixed(2);
        if (multiple == 0.0 || multiple == 0.02) {
            if (item.classList.contains("shake")) {
                item.classList.remove("shake");
            }
            setTwop(parseFloat(value));
        } else {
            item.classList.add("shake");
        }
    };

    const handleOneP = (value) => {
        let item = document.getElementById("one__p");
        let multiple = (value % 0.01).toFixed(2);
        if (multiple == 0.0 || multiple == 0.01) {
            if (item.classList.contains("shake")) {
                item.classList.remove("shake");
            }
            setOnep(parseFloat(value));
        } else {
            item.classList.add("shake");
        }
    };

    //checks the dates for the selected site
    const handleSite = async (site) => {
        setShiftGrouping([]);
        setSite(site);
        await axios
            .get(`${url}/eos/awaitingEOD/${site}`)
            .then(async (res) => {
                if (res.data.eod) {
                    setEodId(res.data.eod.endOfDayId);
                    var data = {};
                    await axios
                        .get(
                            `${url}/eos/getEodShifts/${res.data.eod.endOfDayId}`
                        )
                        .then(async (resp) => {
                            setShiftGrouping(resp.data);
                            data.firstDate = resp.data[0];
                            data.lastDate = resp.data[resp.data.length - 1];
                            data.site = site;
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                    setStart(
                        new Date(data.firstDate.startTime).toLocaleString()
                    );
                    setEnd(new Date(data.lastDate.endTime).toLocaleString());
                    setShowEodOverview(true);
                    setAutoSave(true);
                    setShowLoader(true);
                    //if the day has been started and not finished
                    switch (res.data.eod.eodStatusId) {
                        case 2:
                            await axios
                                .get(
                                    `${url}/eos/getDenomCount/${res.data.eod.endOfDayId}`
                                )
                                .then(async (res1) => {
                                    setExpectedEpos(res1.data.eposAmount);
                                    setExpected(res1.data.actualSum);
                                    setAutoSave(false);
                                    setShowLoader(false);
                                    setShowDenominations(true);
                                })
                                .catch((err) => {
                                    console.log(err);
                                    setMessage(
                                        "Something went wrong, please try again"
                                    );
                                    setErrorAlert(true);
                                    setTimeout(() => {
                                        setErrorAlert(false);
                                        document.getElementById(
                                            "discrep_btn"
                                        ).style.display = "flex";
                                    }, 3000);
                                });
                            break;
                        case 3:
                            await axios
                                .get(
                                    `${url}/eos/denominationData/${res.data.eod.endOfDayId}`
                                )
                                .then(async (res3) => {
                                    setActual(res3.data.actual);
                                    setDiscrepancy(res3.data.discrepancy);
                                    setAutoSave(false);
                                    setShowLoader(false);
                                    setShowReference(true);
                                })
                                .catch((err) => {
                                    console.log(err);
                                    setMessage(
                                        "Something went wrong, please try again"
                                    );
                                    setErrorAlert(true);
                                    setTimeout(() => {
                                        setErrorAlert(false);
                                    }, 3000);
                                });
                            break;
                        case 4:
                            await axios
                                .post(`${url}/eos/refunds`, {
                                    id: res.data.eod.endOfDayId,
                                })
                                .then(async (res2) => {
                                    setRefundData(res2.data);
                                    setShowRefunds(true);
                                    setAutoSave(false);
                                    setShowLoader(false);
                                })
                                .catch((err) => {
                                    console.log(err);
                                });
                            break;
                        case 5:
                            await axios
                                .post(`${url}/eos/discounts`, {
                                    id: res.data.eod.endOfDayId,
                                })
                                .then(async (res) => {
                                    console.log(res.data);
                                    setAutoSave(false);
                                    setShowLoader(false);
                                    setDiscountsData(res.data);
                                    setShowDiscounts(true);
                                })
                                .catch((err) => {
                                    console.log(err);
                                });
                            break;
                        case 6:
                            await axios
                                .get(
                                    `${url}/eos/misc-items/${res.data.eod.endOfDayId}`
                                )
                                .then(async (res) => {
                                    setMiscItems(res.data);
                                    setShowLoader(false);
                                    setShowMiscItems(true);
                                    setShowDiscounts(false);
                                })
                                .catch((err) => {
                                    console.log(err);
                                    setShowLoader(false);
                                });
                            break;
                        case 7:
                            await axios
                                .post(`${url}/eos/lottery`, {
                                    id: res.data.eod.endOfDayId,
                                })
                                .then(async (res) => {
                                    setPayoutData(res.data.payouts);
                                    setSupplierData(res.data.supplier);
                                    setCouponSum(res.data.coupon);
                                    setAutoSave(false);
                                    setShowLoader(false);
                                    setShowPayouts(true);
                                })
                                .catch((err) => {
                                    setShowLoader(false);
                                });
                            break;
                        case 8:
                            let data = { siteId: site };
                            await axios
                                .post(`${url}/eos/all-driveoff`, data)
                                .then((res) => {
                                    console.log(res.data);
                                    setDriveoffs(res.data);
                                    // setAutoSave(false);
                                    // setShowLoader(false);
                                    // setShowDriveOff(true);
                                })
                                .catch((err) => {
                                    console.log(
                                        `Could not get the drive offs: ${err}`
                                    );
                                });
                            setAutoSave(false);
                            setShowLoader(false);
                            setShowDriveOff(true);

                            break;
                        case 9:
                            axios
                                .post(`${url}/eos/keyfuels`, {
                                    id: res.data.eod.endOfDayId,
                                })
                                .then((res4) => {
                                    setAutoSave(false);
                                    setShowLoader(false);
                                    setKeyfuelsData(res4.data);
                                    setShowKeyFuels(true);
                                })
                                .catch((err) => {
                                    setMessage(
                                        "Something went wrong, please try again"
                                    );
                                    setErrorAlert(true);
                                    console.log(err);
                                    setTimeout(() => {
                                        setMessage("");
                                        setErrorAlert(false);
                                    }, 3000);
                                });
                            break;
                        case 10:
                            setAutoSave(false);
                            setShowLoader(false);
                            setShowNotes(true);
                            break;
                        case 11:
                            setAutoSave(false);
                            setShowLoader(false);
                            setShowNotes(true);
                            break;
                        default:
                            setAutoSave(false);
                            setShowLoader(false);
                            setShiftRecon(res.data.data);
                            setEodNotFinished(true);
                            setShowShifts(true);
                            let shifts = res.data.data;
                            //sorts by shifts date
                            shifts.sort(
                                (a, b) =>
                                    new Date(a.startTime) -
                                    new Date(b.startTime)
                            );
                            setDates(shifts);
                    }
                    // console.log(res.data.data);
                } else {
                    //compile the shifts
                    setShiftGroupingData(res.data);
                    setShowShiftGrouping(true);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    //selects and groups all shifts for reconciliation
    const handleShiftGrouping = (data, index) => {
        let tableChange = document.querySelectorAll("#shift_grouping_table tr");
        //if they have clicked by mistake ungroups
        if (tableChange[index].classList.contains("selected__row__shift")) {
            tableChange[index].classList.remove("selected__row__shift");
            //remove from the shiftgrouping
            let selected = shiftGrouping.indexOf(data);
            shiftGrouping.splice(selected, 1);
        } else {
            tableChange[index].classList.add("selected__row__shift");
            setShiftGrouping((shiftGrouping) => [...shiftGrouping, data]);
        }
    };

    //sends the array of shift grouping
    const handleShowShifts = () => {
        document.getElementById("group_btn").style.display = "none";
        if (shiftGrouping.length === 0) {
            setMessage("You need to select some shifts");
            setErrorAlert(true);
            setTimeout(() => {
                document.getElementById("group_btn").style.display = "flex";
                setMessage("");
                setErrorAlert(false);
            }, 2500);
        } else {
            const data = {
                user: user,
                eosIds: shiftGrouping,
            };
            axios
                .post(`${url}/eos/assignEOD`, data)
                .then((res) => {
                    axios
                        .get(`${url}/eos/shiftsWithEOD/${res.data.endOfDayId}`)
                        .then((res) => {
                            document.getElementById("group_btn").style.display =
                                "inline";
                            setShiftRecon(res.data);
                            setShowShiftGrouping(false);
                            setShowShifts(true);
                        })
                        .catch((err) => {
                            console.log(err);
                            setMessage("An error occurred, please try again");
                            setErrorAlert(true);
                            setTimeout(() => {
                                setErrorAlert(false);
                                document.getElementById(
                                    "group_btn"
                                ).style.display = "inline";
                            });
                        });
                })
                .catch((err) => {
                    document.getElementById("group_btn").style.display =
                        "inline";
                    console.log(err);
                });
        }
    };

    //manages an array with all individual shift data onchange would create one per keyboard
    //so checks if the id already exists on the array
    const handleShiftReconIndv = (id, value) => {
        let checkExists = shiftReconData.findIndex((a) => a.id == id);
        if (checkExists >= 0) {
            shiftReconData[checkExists].actual = value;
        } else {
            const newItem = {
                id: id,
                actual: value,
            };
            setShiftReconData((shiftReconData) => [...shiftReconData, newItem]);
        }
    };

    //sends shift recon data
    const handleShiftRecon = async () => {
        // console.log(eodId);
        document.getElementById("discrep_btn").style.display = "none";

        var missing = false;
        console.log(shiftRecon.length);
        console.log(shiftReconData.length);

        //checks if the actual has been inputed
        if (shiftRecon.length != shiftReconData.length) {
            missing = true;

            setMessage("Shift Actual cannot be empty");
            setErrorAlert(true);
            setTimeout(() => {
                setErrorAlert(false);
                setMessage("");
                document.getElementById("discrep_btn").style.display = "flex";
            }, 3000);
        }

        if (!missing) {
            await axios
                .post(`${url}/eos/submitEOSTenders`, {
                    shifts: shiftReconData,
                    user: user,
                })
                .then((res) => {
                    setEodId(res.data.eodId);
                    axios
                        .get(`${url}/eos/getDenomCount/${res.data.eodId}`)
                        .then((res) => {
                            setExpectedEpos(res.data.eposAmount);
                            setExpected(res.data.actualSum);
                            document.getElementById(
                                "discrep_btn"
                            ).style.display = "inline";
                            setShowShifts(false);
                            setShowDenominations(true);
                        })
                        .catch((err) => {
                            console.log(err);
                            setMessage(
                                "Something went wrong, please try again"
                            );
                            setErrorAlert(true);
                            setTimeout(() => {
                                setErrorAlert(false);
                                document.getElementById(
                                    "discrep_btn"
                                ).style.display = "inline";
                            }, 3000);
                        });
                })
                .catch((err) => {
                    console.log(err);
                    setMessage("Something went wrong, please try again");
                    setErrorAlert(true);
                    setTimeout(() => {
                        setErrorAlert(false);
                        document.getElementById("discrep_btn").style.display =
                            "inline";
                    }, 3000);
                });
        }
    };

    const acceptDiscrepancy = async () => {
        const data = {
            eodId: eodId,
            denominations: {
                2: fifty,
                3: twenty,
                4: tenner,
                5: fiver,
                6: two,
                7: pound,
                8: fiftyp,
                9: twentyp,
                10: tenp,
                11: five,
                12: twop,
                13: onep,
            },
        };
        await axios
            .post(`${url}/eos/safeCount`, data)
            .then((res) => {
                handleClose();
                setShowDenominations(false);
                setShowReference(true);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    //sends bag refference and denominations
    const handleReference = () => {
        document.getElementById("ref__btn").style.display = "none";

        const data = {
            eodId: eodId,
            actual: actual,
            discrepancy: discrepancy,
            bagId: reference,
            bagNote: bagNote,
            payInSlip: payInSlip
        };

        if (reference === "") {
            setMessage("Please insert cash bag reference");
            setErrorAlert(true);
            setTimeout(() => {
                setErrorAlert(false);
                setMessage("");
                document.getElementById("ref__btn").style.display = "flex";
            }, 3000);
        } else if (payInSlip == "") {
            setMessage("Please insert Pay In Slip reference");
            setErrorAlert(true);
            setTimeout(() => {
                setErrorAlert(false);
                setMessage("");
                document.getElementById("ref__btn").style.display = "flex";
            }, 3000);
        } else {
            setShowLoader(true);
            axios
                .post(`${url}/eos/addBag`, data)
                .then((res) => {
                    //send the first and last date to get the refunds
                    let data = {};
                    if (eodNotFinished) {
                        data = {
                            firstDate: dates[0],
                            lastDate: dates[dates.length - 1],
                            site: site,
                        };
                    } else {
                        shiftGrouping.sort(
                            (a, b) =>
                                new Date(a.startTime) - new Date(b.startTime)
                        );
                        data = {
                            firstDate: shiftGrouping[0],
                            lastDate: shiftGrouping[shiftGrouping.length - 1],
                            site: site,
                        };
                    }
                    axios
                        .post(`${url}/eos/refunds`, { id: eodId })
                        .then((res) => {
                            setShowLoader(false);
                            document.getElementById("ref__btn").style.display =
                                "inline";
                            console.log(res.data);
                            setRefundData(res.data);
                            setShowReference(false);
                            setShowRefunds(true);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                })
                .catch((err) => {
                    console.log(err);
                    setShowLoader(false);
                    document.getElementById("ref__btn").style.display =
                        "inline";
                });
        }
    };

    //if the reason of the refund changes it updates it
    const handleRefundsData = (value, index) => {
        refundData[index].reasonName = value;
    };

    //adds a note to refunds
    const hadleRefundNote = (index, note) => {
        refundData[index].note = note;
    };

    const handleRefunds = () => {
        document.getElementById("refunds__btn").style.display = "none";
        if (refundData.length === 0) {
            setShowLoader(true);
            let data = {
                eodId: eodId,
                array: refundData,
                user: user,
            };
            axios
                .post(`${url}/eos/refunds/submit`, data)
                .then((res) => {
                    setNote("");
                    let details = {};
                    if (eodNotFinished) {
                        details = {
                            firstDate: dates[0],
                            lastDate: dates[dates.length - 1],
                            site: site,
                        };
                    } else {
                        shiftGrouping.sort(
                            (a, b) =>
                                new Date(a.startTime) - new Date(b.startTime)
                        );
                        details = {
                            firstDate: shiftGrouping[0],
                            lastDate: shiftGrouping[shiftGrouping.length - 1],
                            site: site,
                        };
                    }
                    axios
                        .post(`${url}/eos/discounts`, { id: eodId })
                        .then((res) => {
                            setShowLoader(false);
                            document.getElementById(
                                "refunds__btn"
                            ).style.display = "inline";
                            console.log(res.data);
                            setDiscountsData(res.data);
                            setShowRefunds(false);
                            setShowDiscounts(true);
                        })
                        .catch((err) => {
                            console.log(err);
                            document.getElementById(
                                "refunds__btn"
                            ).style.display = "inline";
                        });
                })
                .catch((err) => {
                    console.log(err);
                    document.getElementById("refunds__btn").style.display =
                        "inline";
                });
        } else {
            var hasNotes = refundData.filter((r) => r.note && r.note != "");
            if (hasNotes.length !== refundData.length) {
                setMessage("Please insert a note per refund");
                setErrorAlert(true);
                setTimeout(() => {
                    setErrorAlert(false);
                    setMessage("");
                    document.getElementById("refunds__btn").style.display =
                        "flex";
                }, 3000);
            } else {
                setShowLoader(true);
                let data = {
                    eodId: eodId,
                    array: refundData,
                    user: user,
                };
                axios
                    .post(`${url}/eos/refunds/submit`, data)
                    .then((res) => {
                        console.log(res.data);
                        setNote("");
                        let details = {};
                        if (eodNotFinished) {
                            details = {
                                firstDate: dates[0],
                                lastDate: dates[dates.length - 1],
                                site: site,
                            };
                        } else {
                            shiftGrouping.sort(
                                (a, b) =>
                                    new Date(a.startTime) -
                                    new Date(b.startTime)
                            );
                            details = {
                                firstDate: shiftGrouping[0],
                                lastDate:
                                    shiftGrouping[shiftGrouping.length - 1],
                                site: site,
                            };
                        }
                        axios
                            .post(`${url}/eos/discounts`, { id: eodId })
                            .then((res) => {
                                setShowLoader(false);
                                document.getElementById(
                                    "refunds__btn"
                                ).style.display = "inline";

                                setDiscountsData(res.data);
                                setShowRefunds(false);
                                setShowDiscounts(true);
                            })
                            .catch((err) => {
                                console.log(err);
                                document.getElementById(
                                    "refunds__btn"
                                ).style.display = "inline";
                            });
                    })
                    .catch((err) => {
                        console.log(err);
                        document.getElementById("refunds__btn").style.display =
                            "inline";
                    });
            }
        }
    };

    //adds a note for a discount
    const handleDiscountData = (index, val, reason) => {
        if (reason) {
            discountsData[index].reasonName = val;
        } else {
            discountsData[index].note = val;
        }
    };

    //sends the discounts data to the db
    const handleDiscounts = () => {
        document.getElementById("discounts__btn").style.display = "none";
        if (discountsData.length === 0) {
            setShowLoader(true);
            let data = {
                eodId: eodId,
                array: discountsData,
                user: user,
            };

            axios
                .post(`${url}/eos/discounts/submit`, data)
                .then((res) => {
                    setNote("");
                    let details = {};
                    if (eodNotFinished) {
                        details = {
                            firstDate: dates[0],
                            lastDate: dates[dates.length - 1],
                            site: site,
                        };
                    } else {
                        shiftGrouping.sort(
                            (a, b) =>
                                new Date(a.startTime) - new Date(b.startTime)
                        );
                        details = {
                            firstDate: shiftGrouping[0],
                            lastDate: shiftGrouping[shiftGrouping.length - 1],
                            site: site,
                        };
                    }

                    //Change to Misc Item page and populate items if any
                    axios
                        .get(`${url}/eos/misc-items/${eodId}`)
                        .then(async (res) => {
                            setMiscItems(res.data);
                            setShowLoader(false);
                            setShowMiscItems(true);
                            setShowDiscounts(false);
                        })
                        .catch((err) => {
                            console.log(err);
                            setShowLoader(false);
                        });
                })
                .catch((err) => {
                    console.log(err);
                    setShowLoader(false);
                    document.getElementById("discounts__btn").style.display =
                        "inline";
                });
        } else {
            //forces the note
            var hasNotes = discountsData.filter((r) => r.note && r.note != "");
            if (hasNotes.length !== discountsData.length) {
                setMessage("Please insert a note for each discount");
                setErrorAlert(true);
                setTimeout(() => {
                    setErrorAlert(false);
                    setMessage("");
                    document.getElementById("discounts__btn").style.display =
                        "flex";
                }, 3000);
            } else {
                setShowLoader(true);
                let data = {
                    eodId: eodId,
                    array: discountsData,
                    user: user,
                };
                console.log(data);
                axios
                    .post(`${url}/eos/discounts/submit`, data)
                    .then((res) => {
                        setNote("");
                        let details = {};
                        if (eodNotFinished) {
                            details = {
                                firstDate: dates[0],
                                lastDate: dates[dates.length - 1],
                                site: site,
                            };
                        } else {
                            shiftGrouping.sort(
                                (a, b) =>
                                    new Date(a.startTime) -
                                    new Date(b.startTime)
                            );
                            details = {
                                firstDate: shiftGrouping[0],
                                lastDate:
                                    shiftGrouping[shiftGrouping.length - 1],
                                site: site,
                            };
                        }
                        //Change to Misc Item page and populate items if any
                        axios
                            .get(`${url}/eos/misc-items/${eodId}`)
                            .then(async (res) => {
                                setMiscItems(res.data);
                                setShowLoader(false);
                                setShowMiscItems(true);
                                setShowDiscounts(false);
                            })
                            .catch((err) => {
                                console.log(err);
                                setShowLoader(false);
                            });
                    })
                    .catch((err) => {
                        console.log(err);
                        setShowLoader(false);
                        document.getElementById(
                            "discounts__btn"
                        ).style.display = "inline";
                    });
            }
        }
    };

    //Changes page to Payouts & page and gets data
    const handleMiscItems = () => {
        axios
            .post(`${url}/eos/lottery`, { id: eodId })
            .then((res) => {
                setPayoutData(res.data.payouts);
                setSupplierData(res.data.supplier);
                setCouponSum(res.data.coupon);

                setShowMiscItems(false);
                setShowPayouts(true);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    //adds the actual amount to the supplier
    const handleSupplierActual = (amount, index) => {
        let suppliers = supplierData;
        suppliers[index].actual = amount;
        setSupplierData(suppliers);
    };

    //adds the supplier to the supplierData
    const handleSupplier = (sup, index) => {
        let suppliers = supplierData;
        suppliers[index].supplier = sup;
        setSupplierData(suppliers);
    };

    //adds the amount to the payoutData
    const handlePayOutInput = (amount, index) => {
        let payouts = payoutData;
        payouts[index].actual = amount;
        setPayoutData(payouts);
    };

    //sends the payouts
    const handlePayouts = async () => {
        var data = {
            eodId: eodId,
            payouts: payoutData,
            suppliers: supplierData,
        };

        if (couponSum > 0) {
            await axios
                .post(`${url}/eos/images`, {
                    eodId: parseInt(eodId),
                    images: couponBarcodes,
                })
                .then(async (res) => {
                    console.log(res.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        await axios
            .post(`${url}/eos/lottery/submit`, data)
            .then(async (res) => {
                //sites with no fuel skip the next step
                if (
                    site == "24469" ||
                    site == "24580" ||
                    site == "24897" ||
                    site == "24974" ||
                    site == "24470" ||
                    site == "25251"
                ) {
                    //update twice
                    await axios.get(`${url}/eos/updateStatus/${eodId}`);
                    await axios.get(`${url}/eos/updateStatus/${eodId}`);
                    setShowPayouts(false);
                    setShowNotes(true);
                } else {
                    let data = { siteId: site };
                    axios
                        .post(`${url}/eos/all-driveoff`, data)
                        .then((res) => {
                            console.log(res.data);
                            setDriveoffs(res.data);
                            setShowPayouts(false);
                            setShowDriveOff(true);
                        })
                        .catch((err) => {
                            console.log(`Could not get the drive offs: ${err}`);
                        });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleDriveOff = (driveOff) => {
        //if a payout has been paid
        if (driveOff === "Yes") {
            axios
                .get(
                    `https://nitrocam-back.nitrosolutions.co.uk/cctv-system/submissions/details/site/${site}?both=true&eod=true`
                )
                .then((res) => {
                    setShowOpenDriveOff(true);
                    setDriveOffData(res.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            handleOpenDrive();
        }
    };

    const handleNoDrive = async () => {
        await axios.get(`${url}/eos/updateStatus/${eodId}`);
        handleCloseDrive();
        //checks keyfuel expected data
        axios
            .post(`${url}/eos/keyfuels`, {
                id: eodId,
            })
            .then((res) => {
                console.log(res.data);
                setKeyfuelsData(res.data);
                setShowDriveOff(false);
                setShowKeyFuels(true);
            })
            .catch((err) => {
                setMessage("Something went wrong, please try again");
                setErrorAlert(true);
                console.log(err);
                setTimeout(() => {
                    setMessage("");
                    setErrorAlert(false);
                }, 3000);
            });
    };

    //sends the driveoff data to db
    const handleSendDriveOff = async () => {
        document.getElementById("driveoff_btn").style.display = "none";
        await axios.get(`${url}/eos/updateStatus/${eodId}`);
        if (driveoffs.length === 0) {
            //No driveoffs to deal with
            // gets key fuels data
            axios
                .post(`${url}/eos/keyfuels`, { id: eodId })
                .then((res) => {
                    setKeyfuelsData(res.data);
                    setShowDriveOff(false);
                    setShowKeyFuels(true);
                })
                .catch((err) => {
                    setMessage("Something went wrong, please try again");
                    setErrorAlert(true);
                    console.log(err);
                    setTimeout(() => {
                        setErrorAlert(false);
                        document.getElementById("driveoff_btn").style.display =
                            "inline";
                    }, 3000);
                });
        } else {
            let change = true;
            for (let d = 0; d < driveoffs.length; d++) {
                if (driveoffs[d].note === "" || !driveoffs[d].note) {
                    change = false;
                    setMessage("All driveoffs need a note to show an action");
                    setErrorAlert(true);
                    setTimeout(() => {
                        setErrorAlert(false);
                        document.getElementById("driveoff_btn").style.display =
                            "inline";
                    }, 3000);
                } else {
                    console.log("yeah");
                    document.getElementById("driveoff_btn").style.display =
                        "inline";
                    // if they have been marked as paid
                    if (driveoffs[d].statusId === 6) {
                        let data = {
                            requestId: driveoffs[d].requestId,
                            status: 6,
                            user: user,
                        };
                        axios
                            .post(
                                `${process.env.REACT_APP_ANPR_URL}/cctv-system/submission/update/status`,
                                data
                            )
                            .then((res) => {
                                console.log("status changed");
                            })
                            .catch((err) => {
                                console.log(
                                    `Could not change the status: ${err}`
                                );
                                setMessage(
                                    "Something went wrong, please try again"
                                );
                                setErrorAlert(true);
                                setTimeout(() => {
                                    setErrorAlert(false);
                                    document.getElementById(
                                        "driveoff_btn"
                                    ).style.display = "inline";
                                }, 3500);
                            });
                    } else {
                        let data = {
                            requestId: driveoffs[d].requestId,
                            note: driveoffs[d].note,
                            user: user,
                        };
                        axios
                            .post(
                                `${process.env.REACT_APP_ANPR_URL}/cctv-system/add-note`,
                                data
                            )
                            .then((res) => {
                                console.log("note added");
                            })
                            .catch((err) => {
                                console.log(err);
                                setMessage(
                                    "Could not add note, please try again"
                                );
                                setErrorAlert(true);
                                setTimeout(() => {
                                    setErrorAlert(false);
                                    document.getElementById(
                                        "driveoff_btn"
                                    ).style.display = "inline";
                                }, 3500);
                            });
                    }
                }
            }
            if (change) {
                let data = {
                    driveoffs: driveoffs,
                    user: user,
                };
                console.log(data);
                axios
                    .post(`${url}/eos/email-alert`, data)
                    .then((res) => {
                        console.log("email sent");
                        document.getElementById("driveoff_btn").style.display =
                            "inline";
                        axios
                            .post(`${url}/eos/keyfuels`, { id: eodId })
                            .then((res) => {
                                setKeyfuelsData(res.data);
                                setShowDriveOff(false);
                                setShowKeyFuels(true);
                            })
                            .catch((err) => {
                                setMessage(
                                    "Something went wrong, please try again"
                                );
                                setErrorAlert(true);
                                console.log(err);
                                setTimeout(() => {
                                    setMessage("");
                                    setErrorAlert(false);
                                    document.getElementById(
                                        "driveoff_btn"
                                    ).style.display = "inline";
                                }, 3000);
                            });
                    })
                    .catch((err) => {
                        console.log(`Could not send the email: ${err}`);
                        setMessage("Something went wrong, please try again");
                        setErrorAlert(true);
                        console.log(err);
                        setTimeout(() => {
                            setMessage("");
                            setErrorAlert(false);
                            document.getElementById(
                                "driveoff_btn"
                            ).style.display = "inline";
                        }, 3000);
                    });
            }
        }
    };

    const handleMarkPaid = (index) => {
        driveoffs[index].statusId = 6;
        console.log(driveoffs[index]);
    };

    const handleKeyFuels = () => {
        document.getElementById("keyfuels_btn").style.display = "none";
        let data = {
            id: eodId,
            keyfuelActual: keyfuelActual,
            note: note,
            user: user,
        };
        axios
            .post(`${url}/eos/keyfuels/submit`, data)
            .then((res) => {
                console.log(res.data);
                document.getElementById("keyfuels_btn").style.display =
                    "inline";
                setNote("");
                setShowKeyFuels(false);
                setShowNotes(true);
            })
            .catch((err) => {
                console.log(err);
                setMessage("Something went wrong, please try again");
                setErrorAlert(true);
                setTimeout(() => {
                    setMessage("");
                    setErrorAlert(false);
                    document.getElementById("keyfuels_btn").style.display =
                        "inline";
                }, 3500);
            });
    };

    const handleFinalise = () => {
        document.getElementById("fin_btn").style.display = "none";
        //checks if the user has inserted a note
        axios
            .get(`${url}/eos/images/verify/${eodId}`)
            .then((res) => {
                if (res.data === true) {
                    if (note === "") {
                        axios
                            .post(`${url}/eos/markEODasDone/${eodId}`, {
                                user: user,
                            })
                            .then((res) => {
                                setShowNotes(false);
                                setShowtick(true);
                                setShiftGrouping([]);
                                setShowEodOverview(false);
                                setTimeout(() => {
                                    setShowtick(false);
                                    window.location.reload();
                                }, 4000);
                                document.getElementById(
                                    "eod_selector"
                                ).selectedIndex = "0";
                            })
                            .catch((err) => {
                                console.log(err);
                                setMessage(
                                    "Something went wrong, please try again"
                                );
                                setErrorAlert(true);
                                setTimeout(() => {
                                    setMessage("");
                                    setErrorAlert(false);
                                    document.getElementById(
                                        "fin_btn"
                                    ).style.display = "inline";
                                });
                            });
                    } else {
                        let data = {
                            note: note,
                            user: user,
                            eodId: eodId,
                        };
                        console.log(data);
                        axios
                            .post(`${url}/eos/addNote`, data)
                            .then((res) => {
                                setShowNotes(false);
                                setShowtick(true);
                                setShiftGrouping([]);
                                setShowEodOverview(false);
                                setTimeout(() => {
                                    setShowtick(false);
                                    window.location.reload();
                                }, 4000);
                                document.getElementById(
                                    "eod_selector"
                                ).selectedIndex = "0";
                            })
                            .catch((err) => {
                                console.log(err);
                                setMessage(
                                    "Something went wrong, please try again"
                                );
                                setErrorAlert(true);
                                setTimeout(() => {
                                    setMessage("");
                                    setErrorAlert(false);
                                    document.getElementById(
                                        "fin_btn"
                                    ).style.display = "inline";
                                }, 2500);
                            });
                    }
                } else {
                    setMessage(
                        "Please scan the QR Code and add the needed images to your EOD"
                    );
                    setErrorAlert(true);
                    setTimeout(() => {
                        setMessage("");
                        setErrorAlert(false);
                        document.getElementById("fin_btn").style.display =
                            "inline";
                    }, 4000);
                }
            })
            .catch((err) => {
                console.log(err);
                setMessage("Something went wrong, please try again");
                setErrorAlert(true);
                setTimeout(() => {
                    setMessage("");
                    setErrorAlert(false);
                    document.getElementById("fin_btn").style.display = "inline";
                }, 2500);
            });
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            addCoupon();
        }
    };

    const addCoupon = () => {
        setCouponBarcodes((oldArray) => [
            ...oldArray,
            { type: "NEWSPAPER COUPONS", image: couponBarcode },
        ]);
        setCouponBarcode("");
    };

    const managersAreUseless = async () => {
        //axios call with eodId on then do the first call
        await axios
            .get(`${url}/eos/resetEOD/${eodId}`)
            .then((res) => {
                window.location.reload();
            })
            .catch((err) => {
                console.log(err);
                setMessage("Something went wrong, please try again");
                setErrorAlert(true);
                setTimeout(() => {
                    setMessage("");
                    setErrorAlert(false);
                    document.getElementById("fin_btn").style.display = "inline";
                }, 2500);
            });
    };

    const handleDriveOffNotes = (driveNote, index) => {
        driveoffs[index].note = driveNote;
    };

    return (
        <main className="manager__eod">
            <h1>End Of Day</h1>
            <section className="eod" id="eod_selector">
                {sites.length > 1 ? (
                    <select onChange={(e) => handleSite(e.target.value)}>
                        <option>--Select Site--</option>
                        {sites.map(function (d, i) {
                            return (
                                <option value={d.eposId}>{d.siteName}</option>
                            );
                        })}
                    </select>
                ) : (
                    <h2>
                        End Of Day For:{" "}
                        <span style={{ color: "#2c53a0" }}>{siteName}</span>
                    </h2>
                )}
                {showEodOverview ? (
                    <h3 style={{ textAlign: "center" }}>
                        EOD ID: {eodId} | {start} - {end}
                    </h3>
                ) : (
                    <></>
                )}
                {autoSave && showLoader ? <NitroLoader /> : <></>}
                {showShiftGrouping ? (
                    <section className="eod__values">
                        <table>
                            <thead>
                                <tr>
                                    <th>Site</th>
                                    <th>Till</th>
                                    <th>Start Of Shift</th>
                                    <th>End Of Shift</th>
                                </tr>
                            </thead>
                            <tbody id="shift_grouping_table">
                                {shiftGroupingData.length > 0 &&
                                    shiftGroupingData.map(function (d, i) {
                                        return (
                                            <tr
                                                onClick={() =>
                                                    handleShiftGrouping(d, i)
                                                }
                                            >
                                                <td>{d.siteName}</td>
                                                <td>{d.device}</td>
                                                <td>
                                                    {new Date(
                                                        d.startTime
                                                    ).toUTCString()}
                                                </td>
                                                <td>
                                                    {new Date(
                                                        d.endTime
                                                    ).toUTCString()}
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                        {errorAlert ? (
                            <Alert
                                severity="warning"
                                sx={{ width: "60%", margin: "auto" }}
                            >
                                {message}
                            </Alert>
                        ) : (
                            <></>
                        )}
                        <Button
                            color="success"
                            variant="outlined"
                            onClick={() => handleShowShifts()}
                            id="group_btn"
                            className="step__btn"
                        >
                            Next Step
                        </Button>
                    </section>
                ) : showShifts ? (
                    <section className="eod__values">
                        {eodNotFinished ? (
                            <h3
                                style={{
                                    color: "#D94B18",
                                    textTransform: "uppercase",
                                    fontSize: "2rem",
                                }}
                            >
                                This day has not been finished
                            </h3>
                        ) : (
                            <></>
                        )}
                        <table>
                            <thead>
                                <tr>
                                    <th>Till</th>
                                    <th>Staff</th>
                                    <th>Date</th>
                                    <th>Expected</th>
                                    <th>Actual</th>
                                    <th>Discrepancy</th>
                                </tr>
                            </thead>
                            <tbody>
                                {shiftRecon.map(function (d, i) {
                                    return (
                                        <IndvRecon
                                            data={d}
                                            handleShiftReconIndv={
                                                handleShiftReconIndv
                                            }
                                        />
                                    );
                                })}
                            </tbody>
                        </table>
                        <Button
                            color="success"
                            variant="outlined"
                            sx={{ margin: "2% auto" }}
                            id="discrep_btn"
                            onClick={() => handleShiftRecon()}
                        >
                            Next Step
                        </Button>
                        {errorAlert ? (
                            <Alert
                                severity="warning"
                                sx={{ width: "60%", margin: "auto" }}
                            >
                                {message}
                            </Alert>
                        ) : (
                            <></>
                        )}
                    </section>
                ) : showDenominations ? (
                    <section className="eod__values">
                        <h1>Count your money</h1>
                        <h1 style={{ margin: "0 auto" }}>
                            Please insert denomination values
                        </h1>
                        <h3 style={{ margin: "0 auto" }}>
                            Expected cash on EposNOW{" "}
                            <span>£{expectedEpos}</span>
                        </h3>
                        <h3 style={{ margin: "0 auto" }}>
                            Actual cash from shift reconciliation{" "}
                            <span>£{expected}</span>
                        </h3>
                        <h3 style={{ margin: "0 auto" }}>
                            Discrepancy{" "}
                            <span>
                                £
                                {parseFloat(expectedEpos - expected).toFixed(2)}
                            </span>
                        </h3>
                        <section className="denominations">
                            <div className="denomination__row">
                                <label id="fifty">£50.00</label>
                                <input
                                    type="number"
                                    min={0}
                                    onChange={(e) =>
                                        handleFifty(e.target.value)
                                    }
                                />
                            </div>
                            <div className="denomination__row">
                                <label id="twenty">£20.00</label>
                                <input
                                    type="number"
                                    min={0}
                                    onChange={(e) =>
                                        handleTwenty(e.target.value)
                                    }
                                />
                            </div>
                            <div className="denomination__row">
                                <label id="tenner">£10.00</label>
                                <input
                                    type="number"
                                    min={0}
                                    onChange={(e) =>
                                        handleTenner(e.target.value)
                                    }
                                />
                            </div>
                            <div className="denomination__row">
                                <label id="fiver">£5.00</label>
                                <input
                                    type="number"
                                    min={0}
                                    onChange={(e) =>
                                        handleFiver(e.target.value)
                                    }
                                />
                            </div>
                            <div className="denomination__row">
                                <label id="two__pounds">£2.00</label>
                                <input
                                    type="number"
                                    min={0}
                                    onChange={(e) =>
                                        handleTwoPounds(e.target.value)
                                    }
                                />
                            </div>
                            <div className="denomination__row">
                                <label id="pounds">£1.00</label>
                                <input
                                    type="number"
                                    min={0}
                                    onChange={(e) =>
                                        handlePound(e.target.value)
                                    }
                                />
                            </div>
                            <div className="denomination__row">
                                <label id="fifty__p">0.50p</label>
                                <input
                                    type="number"
                                    min={0}
                                    onChange={(e) =>
                                        handleFiftyP(e.target.value)
                                    }
                                />
                            </div>
                            <div className="denomination__row">
                                <label id="twenty__p">0.20p</label>
                                <input
                                    type="number"
                                    min={0}
                                    onChange={(e) =>
                                        handleTwentyP(e.target.value)
                                    }
                                />
                            </div>
                            <div className="denomination__row">
                                <label id="ten__p">0.10p</label>
                                <input
                                    type="number"
                                    min={0}
                                    onChange={(e) => handleTenP(e.target.value)}
                                />
                            </div>
                            <div className="denomination__row">
                                <label id="five__p">0.05p</label>
                                <input
                                    type="number"
                                    min={0}
                                    onChange={(e) =>
                                        handleFiveP(e.target.value)
                                    }
                                />
                            </div>
                            <div className="denomination__row">
                                <label id="two__p">0.02p</label>
                                <input
                                    type="number"
                                    min={0}
                                    onChange={(e) => handleTwoP(e.target.value)}
                                />
                            </div>
                            <div className="denomination__row">
                                <label id="one__p">0.01p</label>
                                <input
                                    type="number"
                                    min={0}
                                    onChange={(e) => handleOneP(e.target.value)}
                                />
                            </div>
                        </section>
                        <Button
                            variant="outlined"
                            endIcon={<SendIcon sx={{ margin: "0" }} />}
                            color="success"
                            sx={{ margin: "1% 0" }}
                            onClick={handleOpen}
                        >
                            Next Step
                        </Button>
                    </section>
                ) : showReference ? (
                    <section className="eod__values">
                        <h4>Insert the cash bag reference</h4>
                        <input
                            type="text"
                            onChange={(e) => setReference(e.target.value)}
                        />
                        <h4>Insert the Pay in Slip reference</h4>
                        <input
                            type="text"
                            onChange={(e) => setPayInSlip(e.target.value)}
                        />
                        <h4>
                            If the bag has a different amount, please insert the
                            right amount here:
                        </h4>
                        <input
                            type="number"
                            pattern="[0-9]*"
                            onChange={(e) => setBagNote(e.target.value)}
                            className="bagnote_input"
                        />
                        <Button
                            variant="outlined"
                            color="success"
                            sx={{ margin: "1% 0" }}
                            onClick={() => handleReference()}
                            id="ref__btn"
                        >
                            Next Step
                        </Button>
                        {showLoader ? <NitroLoader /> : <></>}
                        {errorAlert ? (
                            <Alert
                                severity="warning"
                                sx={{ width: "60%", margin: "1% auto" }}
                            >
                                {message}
                            </Alert>
                        ) : (
                            <></>
                        )}
                    </section>
                ) : showRefunds ? (
                    <section className="eod__values">
                        <h4>Confirm Refunds and reasons</h4>
                        {refundData.length >= 1 ? (
                            <>
                                <table>
                                    <thead>
                                        <th>Product</th>
                                        <th>Price</th>
                                        <th>Value</th>
                                        <th>Reason</th>
                                        <th>Note</th>
                                    </thead>
                                    <tbody>
                                        {refundData.map(function (d, i) {
                                            return (
                                                <tr>
                                                    <Refund
                                                        key={i}
                                                        uniqueId={i}
                                                        refundDat={d}
                                                        handleRefundsData={
                                                            handleRefundsData
                                                        }
                                                        hadleRefundNote={
                                                            hadleRefundNote
                                                        }
                                                    />
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </>
                        ) : (
                            <h1>No Refunds were made on this day</h1>
                        )}
                        <Button
                            variant="outlined"
                            color="success"
                            onClick={() => handleRefunds()}
                            id="refunds__btn"
                            sx={{ margin: "2% auto" }}
                        >
                            Next Step
                        </Button>
                        {showLoader ? <NitroLoader /> : <></>}
                        {errorAlert ? (
                            <Alert
                                severity="warning"
                                sx={{ width: "60%", margin: "1% auto" }}
                            >
                                {message}
                            </Alert>
                        ) : (
                            <></>
                        )}
                    </section>
                ) : showDiscounts ? (
                    <section className="eod__values">
                        <h4>Confirm Discounts and reasons</h4>
                        {discountsData.length >= 1 ? (
                            <>
                                <table>
                                    <thead>
                                        <th>Product</th>
                                        <th>Price</th>
                                        <th>Discount</th>
                                        <th>Reason</th>
                                        <th>Note</th>
                                    </thead>
                                    <tbody>
                                        {discountsData.map(function (d, i) {
                                            return (
                                                <tr>
                                                    <Discount
                                                        key={i}
                                                        uniqueId={i}
                                                        discountDat={d}
                                                        handleDiscountData={
                                                            handleDiscountData
                                                        }
                                                    />
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </>
                        ) : (
                            <h1>No discounts were made on this day</h1>
                        )}
                        <Button
                            variant="outlined"
                            color="success"
                            onClick={() => handleDiscounts()}
                            id="discounts__btn"
                            sx={{ margin: "2% auto" }}
                        >
                            Next Step
                        </Button>
                        {errorAlert ? (
                            <Alert
                                severity="warning"
                                sx={{ width: "60%", margin: "1% auto" }}
                            >
                                {message}
                            </Alert>
                        ) : (
                            <></>
                        )}
                        {showLoader ? <NitroLoader /> : <></>}
                    </section>
                ) : showMiscItems ? (
                    <section className="eod__values">
                        <MiscItems
                            handleMiscItems={handleMiscItems}
                            eodId={eodId}
                            items={miscItems}
                        />
                    </section>
                ) : showPayouts ? (
                    <section className="eod__values">
                        <h4>Confirm Payouts & Lottery</h4>
                        {payoutData.length >= 1 ? (
                            <table>
                                <thead>
                                    <th>Type</th>
                                    <th>Expected</th>
                                    <th>Actual</th>
                                </thead>
                                <tbody>
                                    {payoutData.map(function (d, i) {
                                        return (
                                            <tr>
                                                <td>{d.type}</td>
                                                <td>£{d.amount}</td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        onChange={(e) =>
                                                            handlePayOutInput(
                                                                e.target.value,
                                                                i
                                                            )
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        ) : (
                            <h1>No Payout data</h1>
                        )}
                        {supplierData.length >= 1 ? (
                            <>
                                <h4>Confirm Supplier Payouts</h4>
                                <table>
                                    <thead>
                                        <th>Type</th>
                                        <th>Expected</th>
                                        <th>Actual</th>
                                        <th>Supplier</th>
                                    </thead>
                                    <tbody>
                                        {supplierData.map(function (d, i) {
                                            return (
                                                <tr>
                                                    <td>SUPPLIER</td>
                                                    <td>£{d.expected}</td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            onChange={(e) =>
                                                                handleSupplierActual(
                                                                    e.target
                                                                        .value,
                                                                    i
                                                                )
                                                            }
                                                        />
                                                    </td>
                                                    <td>
                                                        <select
                                                            onChange={(e) =>
                                                                handleSupplier(
                                                                    e.target
                                                                        .value,
                                                                    i
                                                                )
                                                            }
                                                        >
                                                            <option>
                                                                --Select
                                                                Supplier--
                                                            </option>
                                                            {suppliers.map(
                                                                function (
                                                                    sup,
                                                                    index
                                                                ) {
                                                                    return (
                                                                        <option
                                                                            value={
                                                                                sup.Id
                                                                            }
                                                                        >
                                                                            {
                                                                                sup.Name
                                                                            }
                                                                        </option>
                                                                    );
                                                                }
                                                            )}
                                                        </select>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </>
                        ) : (
                            <h1>No Supplier payouts</h1>
                        )}
                        {couponSum > 0 ? (
                            <>
                                <h4>Confirm Coupons - £{couponSum}</h4>
                                <label>
                                    Please Add The Barcode For Any Coupons That
                                    You Have
                                </label>
                                <input
                                    type="number"
                                    value={couponBarcode}
                                    onChange={(e) => {
                                        setCouponBarcode(e.target.value);
                                    }}
                                    onKeyDown={handleKeyDown}
                                    pattern="[0-9]*"
                                />
                                <button
                                    onClick={() => {
                                        addCoupon();
                                    }}
                                >
                                    Add
                                </button>
                                <br />
                                {couponBarcodes.length >= 1 ? (
                                    <div className="show__images">
                                        <table>
                                            <thead>
                                                <th>Payout Type</th>
                                                <th>Barcode</th>
                                            </thead>
                                            <tbody>
                                                {couponBarcodes.map(function (
                                                    d,
                                                    i
                                                ) {
                                                    return (
                                                        <tr>
                                                            <td>
                                                                NEWSPAPER COUPON
                                                            </td>
                                                            <td>
                                                                <p>{d.image}</p>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </>
                        ) : (
                            <h1>No Coupons Taken</h1>
                        )}
                        <Button
                            variant="outlined"
                            color="success"
                            onClick={() => handlePayouts()}
                            id="discounts__btn"
                            sx={{ margin: "2% auto" }}
                        >
                            Next Step
                        </Button>
                    </section>
                ) : showDriveOff ? (
                    <section className="eod__values">
                        {driveoffs.length > 0 ? (
                            <>
                                <h4>
                                    The following drive off require attention,
                                    please leave a note
                                </h4>
                                <table>
                                    <thead>
                                        <th>Registration</th>
                                        <th>Submitted on</th>
                                        <th>Crime Reference</th>
                                        <th>Note added</th>
                                        <th>Mark as paid</th>
                                    </thead>
                                    <tbody>
                                        {driveoffs.map((drive, idx) => {
                                            let iconColor = "";
                                            if (drive.statusId === 6) {
                                                iconColor = "success";
                                            } else {
                                                iconColor = "primary";
                                            }
                                            return (
                                                <tr>
                                                    <td>{drive.plate}</td>
                                                    <td>
                                                        {new Date(
                                                            drive.submissionDate
                                                        ).toLocaleDateString(
                                                            "en-GB"
                                                        )}
                                                    </td>
                                                    <td>
                                                        {drive.crimeReference !=
                                                            null ? (
                                                            drive.crimeReference
                                                        ) : (
                                                            <>CALL POLICE</>
                                                        )}
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            onChange={(e) =>
                                                                handleDriveOffNotes(
                                                                    e.target
                                                                        .value,
                                                                    idx
                                                                )
                                                            }
                                                        />
                                                    </td>
                                                    <td
                                                        onClick={() =>
                                                            handleMarkPaid(idx)
                                                        }
                                                    >
                                                        <Tooltip title="Mark as paid">
                                                            <PaidIcon
                                                                color={
                                                                    iconColor
                                                                }
                                                            />
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </>
                        ) : (
                            <h4>No Drive off that require attention</h4>
                        )}
                        {errorAlert ? (
                            <Alert
                                severity="warning"
                                sx={{ width: "60%", margin: " 2% auto" }}
                            >
                                {message}
                            </Alert>
                        ) : null}
                        <Button
                            color="success"
                            variant="outlined"
                            id="driveoff_btn"
                            onClick={() => handleSendDriveOff()}
                            sx={{ margin: "2% auto" }}
                        >
                            Next Step
                        </Button>
                    </section>
                ) : showKeyFuels ? (
                    <section className="eod__values">
                        <h1>Please input your keyfuel actual sales</h1>
                        <table>
                            <thead>
                                <th>Expected</th>
                                <th>Actual</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>{keyfuelsData}</th>
                                    <th>
                                        <input
                                            type="number"
                                            onChange={(e) =>
                                                setKeyFuelActual(e.target.value)
                                            }
                                        />
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                        <textarea
                            cols={50}
                            rows={5}
                            placeholder="Insert any notes..."
                            onChange={(e) => setNote(e.target.value)}
                        />
                        {errorAlert ? (
                            <Alert
                                severity="warning"
                                sx={{ width: "60%", margin: "auto" }}
                            >
                                {message}
                            </Alert>
                        ) : (
                            <></>
                        )}
                        <Button
                            color="success"
                            variant="outlined"
                            onClick={() => handleKeyFuels()}
                            sx={{ margin: "2% auto" }}
                            id="keyfuels_btn"
                        >
                            Next Step
                        </Button>
                    </section>
                ) : showNotes ? (
                    <section className="eod__values">
                        <h1>Leave any notes</h1>
                        <textarea
                            cols={50}
                            rows={5}
                            placeholder="Insert any notes..."
                            onChange={(e) => setNote(e.target.value)}
                        />
                        <QRCode
                            value={`https://nitropos.co.uk/managerzone/eod/images?id=${eodId}`}
                            size={256}
                            style={{ padding: "10px" }}
                        />
                        <button
                            variant="contained"
                            color="sucess"
                            id="fin_btn"
                            onClick={() => handleFinalise()}
                        >
                            Finalise day
                        </button>
                        {errorAlert ? (
                            <Alert
                                severity="warning"
                                sx={{ width: "60%", margin: "auto" }}
                            >
                                {message}
                            </Alert>
                        ) : (
                            <></>
                        )}
                    </section>
                ) : showTick ? (
                    <Tick />
                ) : (
                    <></>
                )}
            </section>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Discrepancy
                    close={handleClose}
                    acceptDiscrepancy={acceptDiscrepancy}
                    actual={actual}
                    discrepancy={discrepancy}
                    expected={expectedEpos}
                />
            </Modal>
            <Modal
                open={openDriveOff}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <DriveOffMod
                    close={handleCloseDrive}
                    handleNoDrive={handleNoDrive}
                />
            </Modal>
            <Modal
                open={openWrongDiscrep}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <WrongDiscrep
                    close={closeWrongDiscrep}
                    actual={actual}
                    expected={expected}
                    managersAreUseless={managersAreUseless}
                />
            </Modal>
        </main>
    );
}
