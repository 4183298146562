import { useState, useEffect } from "react";
import ListIcon from "@mui/icons-material/List";
import UpdateCrimeRef from "./modals/UpdateCrimeRef";
import Modal from "@mui/material/Modal";
import VehicleDetails from "./modals/VehicleDetails";
import PolicePickup from "./modals/PolicePickup";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import NotesModal from "../../../devZone/cctv/modals/NotesModal";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import SpeakerNotesOffIcon from "@mui/icons-material/SpeakerNotesOff";
import EditIcon from "@mui/icons-material/Edit";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import PhotosModal from "../../../devZone/cctv/modals/PhotosModal";
import StatusModal from "../cctv/modals/StatusModal";
import { Alert, Tooltip, Button } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import Personal from "./modals/Personal";
import CurrencyPoundIcon from "@mui/icons-material/CurrencyPound";
import MarkPaid from "./modals/MarkPaid";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
};

export default function IndividualStatus({ request, rel, statusList }) {
    const [showStatusChangeModal, setShowStatusChangeModal] = useState(false);
    const [showUpdateCrimeRefModal, setShowUpdateCrimeRefModal] =
        useState(false);
    const [showVehicleDetailModal, setShowVehicleDetailModal] = useState(false);
    const [showPolicePickupModal, setShowPolicePickupModal] = useState(false);
    const [showNotesModal, setShowNotesModal] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [currentStatus, setCurrentStatus] = useState("");
    const [showImagesModal, setShowImagesModal] = useState(false);
    const [clipAlert, setClipAlert] = useState(false);
    const [showPaidModal, setShowPaidModal] = useState(false);

    useEffect(() => {
        for (let i = 0; i < statusList.length; i++) {
            if (statusList[i].statusId === request.statusId) {
                setCurrentStatus(statusList[i].name);
            }
        }
    }, [rel]);

    return (
        <>
            {clipAlert ? (
                <Alert severity="info" sx={style}>
                    Copied to clipboard
                </Alert>
            ) : null}
            <tr>
                <th>
                    {new Date(request.date).toLocaleDateString("en-GB")} -
                    {request.time}
                </th>
                <th>{request.staffName}</th>
                <th>{request.pump}</th>
                <th
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowVehicleDetailModal(true)}
                >
                    {request.plate}
                </th>
                <th>£{request.amount}</th>
                <th>{request.litres}</th>
                <th>
                    {request.email === null ? (
                        <>N/A</>
                    ) : (
                        <Tooltip
                            title="Click to see personal details"
                            sx={{ cursor: "pointer" }}
                        >
                            <InfoIcon
                                color="primary"
                                onClick={() => setShowDetails(true)}
                            />
                        </Tooltip>
                    )}
                </th>

                <th
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                    onClick={() => {
                        setShowStatusChangeModal(true);
                    }}
                >
                    <ListIcon
                        color="primary"
                        style={{ marginRight: "10px", cursor: "pointer" }}
                    />
                    {currentStatus}
                </th>
                <th>
                    {request.crimeReference ? (
                        <EditIcon
                            onClick={() => setShowUpdateCrimeRefModal(true)}
                            style={{ cursor: "pointer" }}
                            color="primary"
                        />
                    ) : (
                        <EditIcon
                            onClick={() => setShowUpdateCrimeRefModal(true)}
                            style={{ cursor: "pointer" }}
                            color="error"
                        />
                    )}
                </th>
                <th>
                    <LocalPoliceIcon
                        onClick={() => setShowPolicePickupModal(true)}
                        style={{ cursor: "pointer" }}
                        color="primary"
                    />
                </th>
                <th>
                    {!request.images.length < 1 ? (
                        <CameraAltIcon
                            color="primary"
                            onClick={() => setShowImagesModal(true)}
                        />
                    ) : (
                        <CameraAltIcon />
                    )}
                </th>
                <th>
                    {request.notes.length > 0 ? (
                        <SpeakerNotesIcon
                            color="primary"
                            style={{ cursor: "pointer" }}
                            onClick={() => setShowNotesModal(true)}
                        />
                    ) : (
                        <SpeakerNotesOffIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => setShowNotesModal(true)}
                        />
                    )}
                </th>
                <th>
                    <CurrencyPoundIcon
                        color="primary"
                        onClick={() => setShowPaidModal(true)}
                        style={{ cursor: "pointer" }}
                    />
                </th>
            </tr>
            <Modal
                aria-labelledby="Status Change Modal"
                aria-describedby="Status Change Modal"
                open={showStatusChangeModal}
            >
                <StatusModal
                    handleCloseStatus={setShowStatusChangeModal}
                    statusList={statusList}
                    currentStatus={currentStatus}
                    reqId={request.requestId}
                    rel={rel}
                    statusId={request.statusId}
                />
            </Modal>
            <Modal
                aria-labelledby="Crime Reference Modal"
                aria-describedby="Crime Reference Modal"
                open={showUpdateCrimeRefModal}
            >
                <UpdateCrimeRef
                    setShowUpdateCrimeRefModal={setShowUpdateCrimeRefModal}
                    registration={request.plate}
                    id={request.requestId}
                    crimeRef={request.crimeReference}
                    rel={rel}
                    request={request}
                />
            </Modal>
            <Modal
                aria-labelledby="Vehicles Details Modal"
                aria-describedby="Vehicles Details Modal"
                open={showVehicleDetailModal}
            >
                <VehicleDetails
                    setShowVehicleDetailModal={setShowVehicleDetailModal}
                    registration={request.plate}
                    id={request.requestId}
                />
            </Modal>
            <Modal
                aria-labelledby="Police Pickup Modal"
                aria-describedby="Police Pickup Modal"
                open={showPolicePickupModal}
            >
                <PolicePickup
                    setShowPolicePickupModal={setShowPolicePickupModal}
                    id={request.requestId}
                    rel={rel}
                    officer={request.officerName}
                />
            </Modal>
            <Modal
                aria-labelledby="Notes Modal"
                aria-describedby="Notes Modal"
                open={showNotesModal}
            >
                <NotesModal
                    handleCloseNotes={setShowNotesModal}
                    allNotes={request.notes}
                    id={request.requestId}
                    rel={rel}
                    requestType={request.requestType}
                />
            </Modal>
            <Modal
                aria-labelledby="Images Modal"
                aria-describedby="Images Modal"
                open={showImagesModal}
            >
                <PhotosModal
                    images={request.images}
                    setShowImagesModal={setShowImagesModal}
                />
            </Modal>
            <Modal
                aria-labelledby="Personal Details Modal"
                aria-describedby="Personal Details Modal"
                open={showDetails}
            >
                <Personal details={request} setShowDetails={setShowDetails} />
            </Modal>
            <Modal
                aria-labelledby="Mark Paid Modal"
                aria-describedby="Mark Paid Modal"
                open={showPaidModal}
            >
                <MarkPaid
                    setShowPaidModal={setShowPaidModal}
                    rel={rel}
                    requestId={request.requestId}
                />
            </Modal>
        </>
    );
}
