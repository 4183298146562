import bpUltimate from "./images/bpUltimate.png";
import gulfEndurance from "./images/gulfEndurance.png";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import BrightnessHighIcon from '@mui/icons-material/BrightnessHigh';
import axios from "axios"
import Alert from '@mui/material/Alert';
import '../App.scss'

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function Banner(props) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [unleaded, setUnleaded] = useState(props.data.unleaded);
  const [diesel, setDiesel] = useState(props.data.diesel);
  const [ultimateUnleaded, setUltimateUnleaded] = useState(props.data.ultimateUnleaded);
  const [ultimateDiesel, setUltimateDiesel] = useState(props.data.ultimateDiesel);
  const [successAlert, setSuccessAlert] = useState(false)
  const [failedAlert, setFailedAlert] = useState(false)
  const [showSiteList, setShowSiteList] = useState(false)
  const [autoport, setAutoport] = useState(false)
  const [bearsden, setBearsden] = useState(false)
  const [braeside, setBraeside] = useState(false)
  const [bridgeton, setBridgeton] = useState(false)
  const [crowwood, setCrowwood] = useState(false)
  const [rutherglen, setRutherglen] = useState(false)

  useEffect(() => {
    console.log(props.data)

  }, []);

  function closeModal() {
    handleClose();
  }

  function send() {
    if (unleaded == "" || diesel == "" || ultimateUnleaded == "" || ultimateDiesel == "") {
      alert("All Fields Must Be Filled!")
    } else {
      const data = {
        site: props.data.site,
        unleaded: unleaded,
        diesel: diesel,
        ultimateDiesel: ultimateDiesel,
        ultimateUnleaded: ultimateUnleaded
      }
      axios.post("https://nitropos-backend.nitrosolutions.co.uk/fuel/prices/update", data)
        .then((res) => {
          closeModal()
          setTimeout(() => {
            setSuccessAlert(true)
          }, 2500)
        }).catch((err) => {
          console.log(err)
          closeModal()
          setTimeout(() => {
            setFailedAlert(true)
          }, 2500)
        })
    }
  }

  async function bpUpdate() {
    if (unleaded == "" || diesel == "" || ultimateUnleaded == "" || ultimateDiesel == "") {
      alert("All Fields Must Be Filled!")
    } else {
      var data = {
        unleaded: unleaded,
        diesel: diesel,
        ultimateDiesel: ultimateDiesel,
        ultimateUnleaded: ultimateUnleaded
      }
      axios.post("https://nitropos-backend.nitrosolutions.co.uk/fuel/prices/update/bp", data)
        .then((res) => {
          closeModal()
          setTimeout(() => {
            setSuccessAlert(true)
          }, 2500)
          window.location.reload()
        }).catch((err) => {
          console.log(err)
          closeModal()
          setTimeout(() => {
            setFailedAlert(true)
          }, 2500)
        })
    }
  }

  async function showBulk() {
    closeModal()
    setShowSiteList(true)
  }

  async function bulkUpdate() {
    if (unleaded == "" || diesel == "" || ultimateUnleaded == "" || ultimateDiesel == "") {
      alert("All Fields Must Be Filled!")
    } else {
      var data = {
        sites: [],
        unleaded: unleaded,
        diesel: diesel,
        ultimateDiesel: ultimateDiesel,
        ultimateUnleaded: ultimateUnleaded
      };
      if (autoport) {
        data.sites.push("Autoport")
      }
      if (bearsden) {
        data.sites.push("Bearsden")
      }
      if (braeside) {
        data.sites.push("Braeside")
      }
      if (bridgeton) {
        data.sites.push("Bridgeton")
      }
      if (crowwood) {
        data.sites.push("Crowwood")
      }
      if (rutherglen) {
        data.sites.push("Rutherglen")
      }
      axios.post("https://nitropos-backend.nitrosolutions.co.uk/fuel/prices/update/bulk", data)
        .then((res) => {
          closeModal()
          setTimeout(() => {
            setSuccessAlert(true)
          }, 2500)
          window.location.reload()
        }).catch((err) => {
          console.log(err)
          closeModal()
          setTimeout(() => {
            setFailedAlert(true)
          }, 2500)
        })
    }
  }

  return (
    <div
      key={props.key}
      style={{
        backgroundColor: props.back,
      }}
      className="banner"
      onClick={handleOpen}
    >
      {successAlert ? (
        <Alert severity="success" sx={{ width: "90vw", position: "fixed", top: "16.5vh", zIndex: "10" }} variant="filled">Price Change sent succesfully</Alert>
      ) : (
        <></>
      )}
      {failedAlert ? (
        <Alert severity="error" sx={{ width: "90vw", position: "fixed", top: "16.5vh", zIndex: "10" }} variant="filled">Could not change price change, please try again</Alert>
      ) : (
        <></>
      )}
      <img src={props.type} width="100%" />
      <p className="bpSite">{props.data.site}</p>
      {props.data.fuelTypes.map(function (d, i) {
        return (
          <div className="indvPrice">
            <p className="fuelType">{d.type.toUpperCase()}</p>
            <p className="bpBoard">{d.price}</p>
          </div>
        )

      })
      }
      <br />
      {props.data.type === "BP" ? (
        <img src={bpUltimate} width="100%" className="bottom" />
      ) : (
        <>
          <img
            src={gulfEndurance}
            height="100px"
            width="100%"
            className="bottom"
          />
        </>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onBackdropClick={() => closeModal()}
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h4" component="h2">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignContent: "center",
              }}
            >
              {props.data.site}
              <CloseIcon
                fontSize="large"
                color="error"
                onClick={() => closeModal()}
                sx={{ cursor: "pointer" }}
              />
            </div>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Please Input the Price change
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label style={{ color: "#000" }}>Unleaded:</label>
              <input
                type="number"
                onChange={(e) => setUnleaded(e.target.value)}
                style={{ borderRadius: ".5rem" }}
                placeholder={unleaded}
              />
              <label style={{ color: "#000" }}>Ultimate Unleaded:</label>
              <input
                type="number"
                onChange={(e) => setUltimateUnleaded(e.target.value)}
                style={{ borderRadius: ".5rem" }}
                placeholder={ultimateUnleaded}
              />
              <label style={{ color: "#000" }}>Diesel:</label>
              <input
                type="number"
                onChange={(e) => setDiesel(e.target.value)}
                style={{ borderRadius: ".5rem" }}
                placeholder={diesel}
              />
              <label style={{ color: "#000" }}>Ultimate Diesel:</label>
              <input
                type="number"
                onChange={(e) => setUltimateDiesel(e.target.value)}
                style={{ borderRadius: ".5rem" }}
                placeholder={ultimateDiesel}
              />
              <Button
                variant="outlined"
                color="primary"
                endIcon={<LocalGasStationIcon />}
                sx={{ marginTop: "5%" }}
                onClick={() => send()}
              >
                Send
              </Button>
              {props.data.type === "BP" ? (<Button
                variant="outlined"
                color="primary"
                endIcon={<BrightnessHighIcon />}
                sx={{ marginTop: "5%" }}
                onClick={() => bpUpdate()}
              >
                BP
              </Button>) : <></>}
              <Button
                variant="outlined"
                color="primary"
                endIcon={<DoneAllIcon />}
                sx={{ marginTop: "5%" }}
                onClick={() => showBulk()}
              >
                Bulk
              </Button>
              {showSiteList ?
                <><hr />
                  <div>
                    <label>Autoport</label>
                    <input type="checkbox" name="Autoport" onChange={() => { setAutoport(!autoport) }} checked={autoport} style={{ marginLeft: "10px", marginRight: "26px" }} />
                    <label>Bearsden</label>
                    <input type="checkbox" name="Bearsden" onChange={() => { setBearsden(!bearsden) }} checked={bearsden} style={{ marginRight: "26px" }} />
                    <label>Braeside</label>
                    <input type="checkbox" name="Braeside" onChange={() => { setBraeside(!braeside) }} checked={braeside} style={{ marginRight: "26px" }} />
                    <br />
                    <label>Bridgeton</label>
                    <input type="checkbox" name="Bridgeton" onChange={() => { setBridgeton(!bridgeton) }} checked={bridgeton} style={{ marginRight: "17px" }} />
                    <label>Crowwood</label>
                    <input type="checkbox" name="Crowwood" onChange={() => { setCrowwood(!crowwood) }} checked={crowwood} style={{ marginRight: "17px" }} />
                    <label>Rutherglen</label>
                    <input type="checkbox" name="Rutherglen" onChange={() => { setRutherglen(!rutherglen) }} checked={rutherglen} style={{ marginRight: "10px" }} />
                  </div>
                  <Button
                    variant="outlined"
                    color="primary"
                    endIcon={<DoneAllIcon />}
                    sx={{ marginTop: "5%" }}
                    onClick={() => bulkUpdate()}
                  >
                    Confirm Bulk Edit
                  </Button>
                </>
                :
                <></>}
            </div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
