import "./claims.scss";
import axios from "axios";
import { useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import Loader from "../../Fuel/loader/Loader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40vw",
  maxHeight: "60vh",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
  overflowY: "scroll"
};

export default function ClaimProductsSelect(props) {
  const [productList, setProductList] = useState([])
  const [claimProducts, setClaimProducts] = useState([])
  const [sites, setSites] = useState([])
  const [site, setSite] = useState("")
  const [reload, setReload] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const [supplierId, setSupplierID] = useState(0);
  const [open, setOpen] = useState(false)
  const [invoiceList, setInvoiceList] = useState([])
  const [invoiceNo, setInvoiceNo] = useState(0)
  const [message, setMessage] = useState("")
  const [showMessage, setShowMessage] = useState(false)

  useEffect(async () => {
    let userD = localStorage.getItem("userDetails");
    let user = JSON.parse(userD);
    setShowLoader(true)
    var finalSites = [];
    await axios.get("https://nitrous.rt-itservices.co.uk/sites/data").then((res) => {
      finalSites = res.data;
    }).catch((err) => {
      console.log(err);
    });

    if (!user.user.sites.includes("HQ")) {
      for (let s = 0; s < finalSites.length; s++) {
        if (!user.user.sites.includes(finalSites[s].name)) {
          finalSites.splice(s, 1)
        }
      }
    }

    setSites(finalSites)

    setSupplierID(props.supplierId)

    setShowLoader(false)

  }, [reload]);

  const changeProduct = async (data, index) => {
    const idx = claimProducts.findIndex((p) => p.awaitingId == data.awaitingId)
    if (idx >= 0) {
      var newData = claimProducts.filter((f) => f.awaitingId != data.awaitingId)
      setClaimProducts(newData)
    } else {
      var supplierCheck = claimProducts.filter((f) => f.supplierName != data.supplierName)
      if (supplierCheck.length > 0) {
        alert("MULTIPLE SUPPLIERS CANNOT BE ADDED TO THE SAME CLAIM!")
      } else {
        setClaimProducts(claimProducts => [...claimProducts, data]);
      }
    }
  }

  const getClaimsAndProducts = async (siteId) => {
    if (siteId == "" || siteId == "") {
      alert("PLEASE SELECT A SITE!")
    } else {
      setSite(siteId)
      setShowLoader(true)
      setTimeout(async () => {
        await axios.get(`https://nitropos-backend.nitrosolutions.co.uk/claims/awaitingProducts/${siteId}/${supplierId}`).then((res) => {
          var data1 = [];
          var cloneSheeps = Array.from(res.data);
          data1 = Array.from(cloneSheeps)
          setProductList(data1)
          setShowLoader(false)
          console.log(res.data)
        }).catch((err) => {
          console.log(err)
        })
      }, 3000)
    }
  }

  const checkClaim = async () => {
    if (claimProducts.length == 0) {
      alert("YOU MUST SELECT PRODUCTS TO CLAIM!")
    } else {
      var data = { siteId: site, products: claimProducts }
      setShowLoader(true)
      await axios.post(`https://nitropos-backend.nitrosolutions.co.uk/claims/awaitingProducts/check`, data).then((res) => {
        setShowLoader(false)
        if (res.data.length == 0) {
          console.log("Manager Create Invoice")
          submitClaim()
        } else if (res.data.length == 1) {
          submitClaim(res.data[0].invoiceNumber)
          console.log("Auto Assign Invoice Number")
        } else {
          console.log(`Manager Picks One Invoice From Available ${res.data.length}`)
          setInvoiceList(res.data)
          setOpen(true)
        }
      }).catch((err) => {
        console.log(err)
      })
    }
  }

  const submitClaim = async (invNo) => {
    var number;
    if(invNo){
      number = invNo
    }else{
      number = invoiceNo
    }
    setShowLoader(true)
    await axios.post("https://nitropos-backend.nitrosolutions.co.uk/claims/awaitingProducts/submit", { siteId: site, products: claimProducts, invoiceNo: number }).then((res) => {
      console.log(res.data)
      setClaimProducts([])
      setShowLoader(false)
      setOpen(false)
      setMessage(`Your NitroPOS Claim ID is ${res.data}.`)
      setShowMessage(true)
      rel(site)
      setTimeout(() => {
        setShowMessage(false)
        setMessage("")
      }, 3500)
    }).catch((err) => {
      console.log(err)
    })
  }

  const rel = (siteId) => {
    getClaimsAndProducts(siteId)
    setReload(!reload)
  }

  return (
    <main className="claims__main">
      <select value={site} onChange={(e) => { getClaimsAndProducts(e.target.value) }}>
        <option>--- SELECT SITE ---</option>
        {sites.map(function (d, i) {
          return (
            <option value={d.epos}>{d.name}</option>
          )
        })}
      </select>
      <div style={{ textAlign: "center" }} className="claims">
        <h1>Products Awaiting To Be Claimed</h1>
        <table style={{ marginBottom: "2%", maxHeight: "55vh", overflowY: "scroll" }}>
          <thead>
            <th>Name</th>
            <th>Barcode</th>
            <th>Order Code</th>
            <th>Supplier</th>
          </thead>
          <tbody>
            {productList.length > 0 ? (
              productList.map(function (d, i) {
                var bg;
                var idx; idx = claimProducts.findIndex((f) => f.awaitingId == d.awaitingId);
                idx < 0 ? bg = "" : bg = "lightblue";

                return (
                  <tr key={i} style={{ backgroundColor: bg }} onClick={() => { changeProduct(d, i) }}>
                    <td>{d.name}</td>
                    <td>{d.barcode}</td>
                    <td>{d.orderCode}</td>
                    <td>{d.supplierName}</td>
                  </tr>
                )
              })
            ) : (
              <tr>
                <td colSpan={4}>No Products To Claim</td>
              </tr>
            )}
          </tbody>
        </table>

        {open ?
          <>
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Available Invoices
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <div className="staff_modal">
                  <div className="modal__row">
                    <div className="row__column">
                      <table>
                        <thead>
                          <th>Invoice Number</th>
                          <th>Invoice Date</th>
                          <th>Submitted Date</th>
                          <th>Staff</th>
                          <th>Note</th>
                        </thead>
                        <tbody>
                          {invoiceList.map(function (d, i) {
                            var bg; d.invoiceNumber == invoiceNo ? bg = "lightblue" : bg = ""
                            return (
                              <tr key={i} style={{ backgroundColor: bg }} onClick={() => { setInvoiceNo(d.invoiceNumber) }}>
                                <td>{d.invoiceNumber}</td>
                                <td>{d.invoiceDate}</td>
                                <td>{new Date(d.submittedDate).toLocaleString()}</td>
                                <td>{d.staffName}</td>
                                <td>{d.note}</td>
                              </tr>
                            )
                          })}

                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="modal__row">
                    <Button color="success" variant="outlined" onClick={() => submitClaim()}>
                      Submit
                    </Button>
                    <Button color="error" variant="contained" onClick={() => setOpen(false)}>
                      Cancel
                    </Button>
                  </div>
                </div>
              </Typography>
            </Box>
          </> : <></>}
        {showLoader ? (
          <div className="loader">
            <Loader />
          </div>
        ) : showMessage ? (
          <h2 style={{ color: "#000" }}>{message}</h2>
        )
          : (
            <><Button
              variant="contained"
              color="success"
              sx={{ width: "25%", margin: "auto" }}
              onClick={() => checkClaim()}
            >
              NEW CLAIM
            </Button></>
          )}
      </div>
    </main>
  );
}
