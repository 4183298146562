import { useState, useEffect } from "react";
import axios from "axios";
import { decryptData, encryptData } from "../../../utils/cryptoUtils";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";

export default function ManagersEmail() {
  const [pendingAlerts, setPendingAlerts] = useState([]);
  const [current, setCurrent] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [errorAlert, setErrorAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [reload, setReload] = useState(false);
  const [token, setToken] = useState("");
  const [userName,setUserName] = useState("")
  const config = {
    headers: { "Content-Type": "application/json", "x-auth-token": token },
  };

  useEffect(() => {
    let getToken = localStorage.getItem("auth-token")
    setToken(getToken);

    let userD = localStorage.getItem("userDetails");
    let userData = JSON.parse(userD)
    setUserName(userData.user.user)

    let localConfig = {
      headers: { "Content-Type": "application/json", "x-auth-token": getToken },
    };
  

    axios
      .get(`${process.env.REACT_APP_API_URL}/onBoard/pending/managers`, localConfig)
      .then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].firstName = decryptData(
            res.data[i].firstName,
            process.env.REACT_APP_EMP_KEY
          );
          res.data[i].lastName = decryptData(
            res.data[i].lastName,
            process.env.REACT_APP_EMP_KEY
          );
        }
        setPendingAlerts(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reload]);

  const approveUser = async () => {
    document.getElementById("confirm_btn").style.display = "none";
    if (email == "" || password == "") {
      setMessage("Please ensure that all the fields are filled!");
      setErrorAlert(true);
      setTimeout(() => {
        setMessage("");
        setErrorAlert(false);
        document.getElementById("confirm_btn").style.display = "flex";
      }, 2500);
    } else {
      var emailValid = email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      if (emailValid) {
        var encEmail = encryptData(email, process.env.REACT_APP_EMP_KEY);
        var encPassword = encryptData(password, process.env.REACT_APP_EMP_KEY);
        await axios
          .post(`${process.env.REACT_APP_API_URL}/onBoard/work-email/confirm`, {
            employeeDetailsId: current.employeeDetailsId,
            email: encEmail,
            password: encPassword,
            user:userName
          }, config)
          .then((resp) => {
            setEmail("");
            setPassword("");
            setSuccessAlert(true);
            setTimeout(() => {
              setMessage("");
              setReload(!reload);
              setSuccessAlert(false);
              document.getElementById("confirm_btn").style.display = "flex";
              setCurrent("");
            }, 2500);
          })
          .catch((err) => {
            console.log(err);
            setMessage("Something went wrong, please try again");
            setErrorAlert(true);
            setTimeout(() => {
              setMessage("");
              setErrorAlert(false);
              document.getElementById("confirm_btn").style.display = "flex";
            }, 2500);
          });
      } else {
        setMessage("Please enter a vaild email address!");
        setErrorAlert(true);
        setTimeout(() => {
          setMessage("");
          setErrorAlert(false);
          document.getElementById("confirm_btn").style.display = "flex";
        }, 2500);
      }
    }
  };

  return (
    <section className="managers__email">
      <h1>Manager emails</h1>
      <div className="manager_wraper">
        <div className="new_manager_table">
          <table>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Company</th>
                <th>Position</th>
              </tr>
            </thead>
            <tbody>
              {pendingAlerts.length < 1 ? (
                <tr>
                  <td colSpan={4}>No Pending Account Alerts</td>
                </tr>
              ) : (
                pendingAlerts.map((manager) => {
                  return (
                    <tr
                      key={manager.employeeDetailsId}
                      onClick={() => setCurrent(manager)}
                    >
                      <td>{manager.firstName}</td>
                      <td>{manager.lastName}</td>
                      <td>{manager.companyName}</td>
                      <td>{manager.roleName}</td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>

        <div className="new_manager_form">
          <h2>Form</h2>
          <div className="form">
            {current.firstName ? (
              <>
                <p>
                  <b>Staff Name:</b> {current.firstName} {current.lastName}
                </p>
                <p>
                  <b>Site:</b> {current.companyName}
                </p>
                <p>
                  <b>Position:</b> {current.roleName}
                </p>
                <hr style={{ width: "100%" }} />
                <form autoComplete="new-password">
                <div>
                  <span>
                    <b>Email: </b>
                  </span>
                  <input
                    type="text"
                    placeholder="New email..."
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    autoComplete="off"
                  />
                </div>
                <br />
                <div>
                  <span>
                    <b>Password: </b>
                  </span>
                  <input
                    type="password"
                    placeholder="New password..."
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    autoComplete="new-password"
                  />
                </div>
                </form>
                <br />
                <Button
                  id="confirm_btn"
                  variant="contained"
                  color="success"
                  onClick={() => approveUser()}
                  style={{ margin: "0 auto" }}
                >
                  Confirm
                </Button>
                {errorAlert ? (
                  <Alert severity="warning">{message}</Alert>
                ) : (
                  <></>
                )}
                {successAlert ? (
                  <Alert severity="success">Request has been submitted!</Alert>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <h2>Select Employee From Table</h2>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
