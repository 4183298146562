import PrintIndvDay from "./PrintIndvDay";
import Logo from "../Tubbees_Logo.webp";
import RTLogo from "../RT_Logo.png";
import FoodLogo from "../subway.png";

export default function PrintIndvWeek({
    staffMember,
    siteName,
    fullDates,
    currentDate,
    rotaId,
    reloadData,
    confirmedDates,
}) {
    return (
        <tr
            className="employee_row"
            id={`employee-row-${staffMember.employeeDetailsId}`}
        >
            <td colSpan={1} className="name_cell" style={{ position: "relative" }} >
                <div style={{display:"flex"}}>
                    {staffMember.icon == "TB" ? (
                        <img src={Logo} style={{ height: "30px", marginLeft: "20%" }} />
                    ) : staffMember.icon == "FD" ? (
                        <img src={FoodLogo} style={{ height: "30px", marginLeft: "20%" }} />
                    ) : staffMember.icon == "MG" ? (
                        <img src={RTLogo} style={{ border: "2px solid red", borderRadius: "50%", height: "30px", marginLeft: "20%" }} />
                    ) : (
                        <img src={RTLogo} style={{ height: "30px", marginLeft: "20%" }} />
                    )}
                    <p style={{ marginLeft: "30%" }}>{staffMember.firstName.trim()}&nbsp;
                        {staffMember.lastName.trim()}</p>
                </div>
            </td>
            {fullDates.map(function (day) {
                let shifts = staffMember.worked.filter((x) => x.date === day);

                //Sorts shifts in order of start time
                shifts.sort((a, b) =>
                    a.startTime > b.startTime
                        ? 1
                        : b.startTime > a.startTime
                            ? -1
                            : 0
                );

                return (
                    <PrintIndvDay
                        shifts={shifts}
                        currentDate={currentDate}
                        date={day}
                        rotaId={rotaId}
                        siteName={siteName}
                        employee={staffMember}
                        reloadData={reloadData}
                        confirmedDates={confirmedDates}
                    />
                );
            })}{" "}
        </tr>
    );
}
