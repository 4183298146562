import React from "react";

export default function ErrorNotice(props) {
    return (
        <>
        <br/>
        <div className="error-notice">
            <span>{props.message}</span>
        </div>
        </>
    );
}