import { getCompanies } from "../../utils/Api";
import WareNav from "../WareNav";
import "./invoiceCheck.scss";
import { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import axios from "axios";
import Loader from "../../animations/Loader";
import IndvInvoice from "./IndvInvoice";

export default function InvoiceCheck() {
    const [siteList, setSiteList] = useState([]);
    const url = "https://nitropos-backend.nitrosolutions.co.uk";
    //   const url = "http://10.0.0.157:4022";
    const [invoices, setInvoices] = useState([]);
    //alerts
    const [showLoader, setShowLoader] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [severity, setSeverity] = useState("");
    const [message, setMessage] = useState("");
    const [liquidInvoices, setLiquidInvoices] = useState([]);

    useEffect(() => {
        let getToken = localStorage.getItem("auth-token");
        getCompanies(getToken)
            .then((companies) => {
                let sites = companies;
                for (let s = 0; s < sites.length; s++) {
                    // will not show HO and RTMS
                    if (sites[s].companyId === 1 || sites[s].companyId === 11) {
                        sites.splice(s, 1);
                    }
                }
                // console.log(sites);
                setSiteList(sites);
            })
            .catch((err) => {
                console.log(`Could not get sites: ${err}`);
            });
    }, []);

    const handleSite = (store) => {
        setInvoices([]);
        setShowLoader(true);
        if (store == "--Please select a site--") {
            setMessage("Please select a valid site");
            setSeverity("warning");
            setShowLoader(false);
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
            }, 3000);
        } else {
            const data = {
                site: store,
            };
            axios
                .post(`${url}/edn/invoice-checker`, data)
                .then((res) => {
                    if (res.status === 500) {
                        setMessage("There was an error getting the invoices");
                        setSeverity("error");
                        setShowLoader(false);
                        setShowAlert(true);
                        setTimeout(() => {
                            setShowAlert(false);
                        }, 3000);
                    } else {
                        // console.log(res.data);
                        setShowLoader(false);
                        setInvoices(res.data);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setMessage("There was an error getting the invoices");
                    setSeverity("error");
                    setShowLoader(false);
                    setShowAlert(true);
                    setTimeout(() => {
                        setShowAlert(false);
                    }, 3000);
                });

            axios
                .post(`${url}/edn/invoice-checker/liquids`, data)
                .then((res) => {
                    if (res.status === 500) {
                        setMessage("There was an error getting the invoices");
                        setSeverity("error");
                        setShowLoader(false);
                        setShowAlert(true);
                        setTimeout(() => {
                            setShowAlert(false);
                        }, 3000);
                    } else {
                        // console.log(res.data);
                        setShowLoader(false);
                        setLiquidInvoices(res.data);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setMessage("There was an error getting the invoices");
                    setSeverity("error");
                    setShowLoader(false);
                    setShowAlert(true);
                    setTimeout(() => {
                        setShowAlert(false);
                    }, 3000);
                });
        }
    };

    return (
        <>
            <WareNav />{" "}
            <main className="main">
                <section className="checker_main">
                    <h1>Invoice checker</h1>
                    <section className="site_select">
                        <p>Select a site to see last 4 invoices:</p>
                        <select onChange={(e) => handleSite(e.target.value)}>
                            <option>--Please select a site--</option>
                            {siteList.map((site) => {
                                return (
                                    <option
                                        key={site.companyId}
                                        value={site.siteName}
                                    >
                                        {site.siteName}
                                    </option>
                                );
                            })}
                        </select>
                    </section>
                    {showAlert ? (
                        <Alert severity={severity} sx={{ marginTop: "2%" }}>
                            {message}
                        </Alert>
                    ) : showLoader ? (
                        <Loader />
                    ) : null}
                    {invoices.length > 0 ? (
                        <>
                            <h1>DISPOSABLES</h1>
                            {invoices.map((invoice) => {
                                return <IndvInvoice invoice={invoice} />;
                            })}
                        </>
                    ) : null}
                    {liquidInvoices.length > 0 ? (
                        <>
                            <h1>V4U E-Liquids & Kits</h1>
                            {liquidInvoices.map((invoice) => {
                                return <IndvInvoice invoice={invoice} />;
                            })}
                        </>
                    ) : null}
                </section>
            </main>
        </>
    );
}
