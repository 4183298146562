//use for holiday routes

import { Navigate, replace, useLocation, useNavigate } from "react-router-dom";

const SeniorManagementProtect = ({ children }) => {
  let userD = localStorage.getItem("userDetails");
  const location = useLocation();
  if (!userD) {
    window.location.replace(`/?redirect=${location.pathname}`);
  }
  let user = JSON.parse(userD);

  if (user && user.user.seniorManagement !== true) {
    return <Navigate to="/not-allowed" replace />;
  }
  return children;
};

export { SeniorManagementProtect };
