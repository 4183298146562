import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import "./styles/fuelSales.scss";

const ignoreStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
  height: "30vh",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
};

export default function IgnoreModal(props) {
  const [ignore, setIgnore] = useState(false);

  useEffect(() => {
    console.log("modal open");
    setIgnore(props.ignored);
  }, [props]);

  return (
    <Box sx={ignoreStyle}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        <h2 style={{ textAlign: "center" }}>
          {ignore ? <>Ignore</> : <>Un-Ignore</>}a Transaction
        </h2>
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <div className="force__match">
          <p>Are you sure you want to change the status of this transaction?</p>
          <div className="force__buttons">
            <Button
              variant="outlined"
              color="success"
              sx={{ width: "10vw" }}
              onClick={() => props.handleIgnore()}
            >
              YES
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{ width: "10vw" }}
              onClick={props.handleCloseIgnore()}
            >
              CANCEL
            </Button>
          </div>
        </div>
      </Typography>
    </Box>
  );
}
