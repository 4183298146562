import { useState, useEffect } from "react";
import moment from "moment"
import axios from 'axios'
import './posExplorer.scss'
import ExploreOffIcon from '@mui/icons-material/ExploreOff';

export default function SafeDrops(props) {
    const [dropData, setDropData] = useState([])
    const url = "https://nitropos-backend.nitrosolutions.co.uk";
    useEffect(() => {
        if (props.selectedShift) {
            axios.get(`${url}/posExplorer/safe-drops/shift/${props.selectedShift}`).then((resp) => {
                setDropData(resp.data)
            }).catch((err) => {
                console.log(err)
            })

        }
    }, [props.selectedShift])

    return (
        <div>
            <div id="searchBar">
                <div><h2 style={{ color: "#ffff", marginBottom:"-7%" }}>SAFE DROPS</h2></div>
            </div>

            {dropData.length > 0 ?
                <div style={{ maxHeight: "52vh", overflow: "auto", paddingBottom: "2%" }}>
                    {dropData.map(function (d, id) {
                        return (
                            <><br />
                                <div style={{ backgroundColor: "#ffff", border: "1px solid navy", borderRadius: "10px", width: "75%", margin: "0 auto" }}>
                                    <h2>£{parseFloat(d.total).toFixed(2)} - {moment(d.datetime).format("HH:mm:ss DD/MM/YYYY")}</h2>
                                </div>
                            </>
                        )
                    })}
                </div>
                :
                <div style={{textAlign:"center", marginTop:"15%", color:"#000"}}>
                    <ExploreOffIcon fontSize="large" color="primary" />
                    <p>No Safe Drops Could Be Found For This Shift</p>
                </div>
            }


        </div>
    )
}