import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
  maxHeight: "75vh",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
};

export default function PaymentsModal(props) {
  const charges = props.charges;

  return (
    <Box sx={style}>
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        sx={{ textAlign: "center" }}
      >
        Payments made for discrepancy
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>Amount</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>User</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Payroll Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {charges.map((charge, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{charge.amount}</TableCell>
                  <TableCell>{charge.createdBy}</TableCell>
                  <TableCell>
                    {new Date(charge.payrollDate).toLocaleDateString("en-GB")}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Typography>
    </Box>
  );
}
