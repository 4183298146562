import { useState, useEffect } from "react";
import test from "../imageTest/test.jpeg";
import "../invoices.scss";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Details from "./indvInvoice/Details";
import Notes from "./indvInvoice/Notes";
import History from "./indvInvoice/History";
import axios from 'axios';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

export default function IndvInvoice(props) {
  const [invoice, setInvoice] = useState({});
  const [imageTest, setImageTest] = useState("");
  const [value, setValue] = useState(0);
  const [reload, setReload] = useState(false);
  const [details, setDetails] = useState([])


  useEffect(() => {
    console.log(props.invoice);

    let user = sessionStorage.getItem("user");

    axios.get(`https://nitropos-backend.nitrosolutions.co.uk/purchaseOrder/open/${props.invoice}`)
    .then((res) => {
      console.log(res.data)
      setDetails(res.data.headingDetails)
    }).catch((err) => {
      console.log(err)
    })

  }, []);

  const rel = () => {
    setReload(!reload);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <section className="indv__invoice">
      <section className="ind__row">
        <button onClick={() => alert("Previous")}>Prev</button>
        <button onClick={() => alert("Next")}>Next</button>
      </section>
      <section className="main__invoice">
        <img src={test} alt="Invoice Image" width="50%" />
        <div className="invoice__tabs">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Details" {...a11yProps(0)} />
                <Tab label="Notes" {...a11yProps(1)} />
                <Tab label="History" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <Details rel={rel} details={details}/>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Notes rel={rel}/>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <History />
            </TabPanel>
          </Box>
        </div>
      </section>
    </section>
  );
}
