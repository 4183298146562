import { useState } from 'react'

export default function Discount(props) {
    const [discountReason, setDiscountReason] = useState(props.discountDat.reasonName)

    const changeReason = (e) => {
        setDiscountReason(e)
        props.handleDiscountData(props.uniqueId, e, "reason")
    }

    return (
        <>
            <td>{props.discountDat.productName}</td>
            <td>{parseFloat(props.discountDat.unitPrice).toFixed(2)}</td>
            <td>{parseFloat(props.discountDat.discountedValue).toFixed(2)}</td>
            <td>
                <select
                    value={discountReason}
                    onChange={(e) =>
                        changeReason(e.target.value, props.uniqueId)
                    }
                >
                    <option value="Wastage">Wastage</option>
                    <option value="Damaged Product">
                        Damaged Product
                    </option>
                    <option value="Manager approved">
                        Manager Approved
                    </option>
                    <option value="General">General</option>
                    <option value="MYSTERY BAG">Mystery Bag</option>
                    <option value="PRISM SALE">Prism Sale</option>
                </select>
            </td>
            <td>
                <input type="text" placeholder="Insert Note" onChange={(e) => props.handleDiscountData(props.uniqueId, e.target.value)
                } style={{ width: "90%" }} />
            </td>
        </>
    )
}