import Chart from "react-apexcharts";

interface Props {
    series: any[];
}

export function Heat({ series }: Props) {
    const graphInfo: any = {
        series: series,
        options: {
            chart: {
                height: 350,
                type: "heatmap",
            },
            dataLabels: {
                enabled: false,
            },
            colors: ["#008FFB"],
            title: {
                text: "HeatMap Chart (Single color)",
            },
        },
    };

    return (
        <Chart
            options={graphInfo.options}
            series={graphInfo.series}
            type="heatmap"
        />
    );
}
