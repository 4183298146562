//React
import { useEffect, useState } from "react";
import moment from "moment";
//MUI
import { Box } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

//Modal Style
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export default function SuspendedModal({ selectedSite }) {
    const [siteInfo, setSiteInfo] = useState({});
    const [transactions, setTransactions] = useState([]);
    useEffect(() => {
        setSiteInfo(selectedSite);
        setTransactions(selectedSite.transactions);
    }, [selectedSite]);
    return (
        <Box sx={style} className="suspend_modal">
            <h2>{selectedSite.siteName.toUpperCase()}</h2>
            <div className="header_info">
                <h2>
                    Active: <span>{selectedSite.activeSuspended}</span>
                </h2>
                <h2>
                    Resolved: <span>{selectedSite.resolvedSuspended}</span>
                </h2>
                <h2>
                    Suspended Value: <span>{selectedSite.totalSuspended}</span>
                </h2>
            </div>
            <section className="transaction_accordians">
                {/* //TODO: MAP THROUGH SUSPENDED SALES */}
                {transactions.length > 0 ? (
                    transactions.map((transaction, i) => {
                        return (
                            <Accordion
                                key={i}
                                className="transaction_accordian"
                                sx={
                                    !transaction.unSuspendedId && {
                                        backgroundColor: "#d5fc8b",
                                    }
                                }
                            >
                                <AccordionSummary
                                    expandIcon={
                                        transaction.unSuspendedId ? (
                                            <ExpandMoreIcon />
                                        ) : null
                                    }
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                    className="transaction_heading"
                                >
                                    {moment(transaction.startTime).format(
                                        "DD-MM-YY HH:mm"
                                    )}{" "}
                                    - <span>{transaction.totalAmount}</span>
                                </AccordionSummary>

                                {transaction.unSuspendedId && (
                                    <AccordionDetails>
                                        This was paid on&nbsp;
                                        <b>
                                            {
                                                transaction
                                                    .unSuspendedTransaction
                                                    .startTime
                                            }
                                        </b>
                                    </AccordionDetails>
                                )}
                            </Accordion>
                        );
                    })
                ) : (
                    <h2 className="no_transactions">
                        No transaction data to display
                    </h2>
                )}
            </section>
        </Box>
    );
}
