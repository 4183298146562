import "../epos.scss";
import { useState, useContext, useEffect } from "react";
import UserContext from "../../../context/userContext";
import { useNavigate, Navigate } from "react-router-dom";
import ManagerNav from "../../ManagerNav";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Cross from "../../../animations/Cross";
import Tick from "../../../animations/Tick";
import WareNav from "../../../warehouse/WareNav";
import Products from "./Products";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40vw",
    height: "80vh",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
    overflowY: "scroll",
};

export default function Duplicate() {
    const [role, setRole] = useState("");
    const [user, setUser] = useState("");
    const [value, setValue] = useState(0);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [hideButton, setHideButton] = useState(true);

    useEffect(() => {
        let userD = localStorage.getItem("userDetails");
        let user = JSON.parse(userD);
        setUser(user.user.user);
        setRole(user.user.role);
    }, []);

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    return (
        <>
            {role === "V4U Manager" ? <WareNav /> : <ManagerNav />}{" "}
            <main className="add__epos__item main">
                <section className="epos__item">
                    <h1>Duplicate Products On Epos Now</h1>
                    <Box sx={{ width: "100%", marginLeft: "1%" }}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label="basic tabs example"
                                variant="scrollable"
                                scrollButtons
                                allowScrollButtonsMobile
                            >
                                <Tab label="Barcodes" {...a11yProps(0)} />
                                <Tab label="Order Codes" {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <Products type={"barcode"} />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <Products type={"orderCode"} />
                        </TabPanel>
                    </Box>

                    {success ? <Tick /> : error ? <Cross /> : <></>}
                </section>
            </main>
        </>
    );
}
