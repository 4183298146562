import { useState } from "react";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Box from "@mui/material/Box";
import { Button, Alert } from "@mui/material";

//STYLING
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60vw",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
    "@media(max-width: 600px)": {
        width: "90vw",
        maxHeight: "70vh",
        overflowX: "scrollable",
    },
};

export default function PolicePickup({
    setShowPolicePickupModal,
    id,
    officerName,
    rel,
}) {
    const url = process.env.REACT_APP_ANPR_URL;
    const [policeName, setPoliceName] = useState("");
    const [message, setMessage] = useState("");
    const [alert, setAlert] = useState("");
    const [severity, setSeverity] = useState("");

    const handleSubmit = () => {
        let data = {
            name: policeName,
            requestId: id,
        };

        if (policeName === "") {
            setMessage("Please provide the officers name");
            setSeverity("warning");
            setAlert(true);
            setTimeout(() => {
                setAlert(false);
                setSeverity("");
                setMessage("");
            }, 3000);
        } else {
            axios
                .post(`${url}/cctv-system/submission/update/collection`, data)
                .then((res) => {
                    setMessage("Successfully submitted");
                    setSeverity("success");
                    setAlert(true);
                    setTimeout(() => {
                        setAlert(false);
                        setSeverity("");
                        setMessage("");
                        setShowPolicePickupModal(false);
                    }, 3000);
                    rel();
                })
                .catch((err) => {
                    console.log(err);
                    setMessage("Something went wrong, please try again");
                    setSeverity("error");
                    setAlert(true);
                    setTimeout(() => {
                        setAlert(false);
                        setSeverity("");
                        setMessage("");
                    }, 3000);
                });
        }
    };

    return (
        <Box sx={style} className="all_modal">
            <Typography id="status_header" variant="h6" component="h2">
                <h3 style={{ textAlign: "center", width: "100%" }}>
                    Police Pickup
                </h3>
            </Typography>
            {!officerName ? (
                <>
                    <Typography
                        id="status_header"
                        variant="h6"
                        component="h6"
                        sx={{ textAlign: "center" }}
                    >
                        If the police have picked this CCTV footage up from the
                        store please provide the officers name.
                    </Typography>

                    <label>Officer name:</label>
                    <br />
                    <input
                        type="text"
                        onChange={(e) => setPoliceName(e.target.value)}
                    />
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "10px",
                        }}
                    >
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => setShowPolicePickupModal(false)}
                        >
                            close
                        </Button>
                        {!alert ? (
                            <Button variant="contained" onClick={handleSubmit}>
                                submit
                            </Button>
                        ) : (
                            <Alert severity={severity}>{message}</Alert>
                        )}
                    </div>
                </>
            ) : (
                <Typography
                    id="status_header"
                    variant="h6"
                    component="h6"
                    sx={{ textAlign: "center" }}
                >
                    The footage for this drive off has been picked up by Officer{" "}
                    {officerName}.
                </Typography>
            )}
        </Box>
    );
}
