import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useRef, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import QrCode2Icon from "@mui/icons-material/QrCode2";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35vw",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
    "@media(max-width: 600px)": {
        width: "90vw",
        maxHeight: "70vh",
        overflowX: "scrollable",
    },
};

export default function QRPrint({ qrCode, setOpenQR, request }) {
    const componentRef = useRef();

    //Handle QR printing
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: "@page { size: '150mm 50mm' }",
    });

    useEffect(() => {
        console.log(request);
    }, []);
    return (
        <Box sx={style} className="all_modals">
            <Typography
                ref={componentRef}
                id="modal-modal-title"
                variant="h6"
                component="h2"
            >
                <img src={qrCode} />
                <p>ID: {request.requestId}</p>
                <h4>{request.site}</h4>
                <p>
                    {request.reason} {request.plate && `- ${request.plate}`}
                </p>
                <p>{new Date(request.date).toLocaleDateString("en-GB")}</p>
                <p>{request.time}</p>
            </Typography>
            <section
                style={{ display: "flex", justifyContent: "space-around" }}
            >
                <Button
                    variant="contained"
                    color="error"
                    onClick={() => setOpenQR(false)}
                >
                    Close
                </Button>
                <Button
                    endIcon={<QrCode2Icon />}
                    variant="contained"
                    color="warning"
                    onClick={handlePrint}
                >
                    Print
                </Button>
            </section>
        </Box>
    );
}
