import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LoginV4U from "./LoginV4U";
import OrderCheck from "./OrderCheck";

export default function AutoOrder() {
  const [reload, setReload] = useState(false);
  const [showLogin, setShowLogin] = useState(true);
  const { id } = useParams();
  const [user, setUser] = useState("");

  useEffect(() => {
    let userD = localStorage.getItem("userDetails");
    if (userD != "") {
    let user = JSON.parse(userD);
      setUser(user.user.user);
      setShowLogin(false);
    }
  }, [reload]);

  const rel = () => {
    setReload(!reload);
  };

  return (
    <main>
      {showLogin ? <LoginV4U rel={rel} /> : <OrderCheck user={user} id={id} />}
    </main>
  );
}
