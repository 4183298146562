import { useState, useEffect } from "react";
import axios from "axios";
import "../hr.scss";
import moment from "moment";
import DonutSmallIcon from "@mui/icons-material/DonutSmall";
import Loader from "../../animations/Loader";

export default function HrDash() {
  const [monthShow, setMonthShow] = useState("");
  const [sitesData, setSitesData] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const url = "https://nitropos-backend.nitrosolutions.co.uk";

  useEffect(() => {
    let today = moment().format("D");
    let month;
    //checks the day of the month to show on the dashboard
    if (parseInt(today) < 15) {
      month = moment().subtract(1, "month");
      month = moment(month).format("YYYY-MM");
    } else {
      month = moment().format("YYYY-MM");
    }
    setMonthShow(moment(month).format("MM-YYYY"));
    let data = {
      month: month,
      site: "HQ",
    };
    axios
      .post(`${url}/hr/checkMonthFinalise`, data)
      .then((res) => {
        setSitesData(res.data);
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <main className="hr__dash">
      <h1>Showing data for: {monthShow}</h1>
      {showLoader ? (
        <Loader />
      ) : (
        <section className="hr_dash_main">
          {sitesData && sitesData.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th colSpan={2}>Site</th>
                  <th>EOD</th>
                  <th>Manager Investigations</th>
                  <th>Admin Investigations</th>
                  <th>Fuel Reconciliation</th>
                </tr>
              </thead>
              <tbody>
                {sitesData.map(function (d, i) {
                  if (
                    d.siteName === "Head Office" ||
                    d.siteName === "Hillington"
                  ) {
                    return <></>;
                  }
                  let eod, store, admin, fuel;
                  let today = moment().format("D");
                  if (d.complete === true) {
                    eod = "green";
                    store = "green";
                    admin = "green";
                    fuel = "green";
                  } else if (
                    parseInt(today) >= 3 &&
                    parseInt(today) < 5 &&
                    d.discrepancies != 0 &&
                    d.complete === false
                  ) {
                    store = "orange";
                    admin = "orange";
                    if (d.fuelDates.length >= 4) {
                      fuel = "red";
                    } else if (d.fuelDates.length === 0) {
                      fuel = "green";
                    } else {
                      fuel = "orange";
                    }
                  } else if (
                    parseInt(today) >= 3 &&
                    parseInt(today) < 5 &&
                    d.shiftsAwaiting != 0 &&
                    d.complete === false
                  ) {
                    eod = "orange";
                  } else if (
                    parseInt(today) >= 5 &&
                    parseInt(today) < 15 &&
                    d.shiftsAwaiting != 0 &&
                    d.complete === false
                  ) {
                    eod = "red";
                  } else if (
                    parseInt(today) >= 5 &&
                    parseInt(today) < 15 &&
                    d.discrepancies != 0 &&
                    d.complete === false
                  ) {
                    store = "red";
                    admin = "red";
                    if (d.fuelDates.length > 2) {
                      fuel = "red";
                    } else if (d.fuelDates.length === 0) {
                      fuel = "green";
                    } else {
                      fuel = "orange";
                    }
                  } else if (d.shiftsAwaiting === 0) {
                    eod = "green";
                    store = "green";
                    if (d.fuelDates.length === 0) {
                      fuel = "green";
                    } else {
                      fuel = "orange";
                    }
                    if (d.storeInvestigations === 0){
                      store = "green"
                    } else if (d.storeInvestigations > 0 && d.storeInvestigations < 5){
                      store="orange"
                    } else{
                      store = "red"
                    }
                  } else {
                    eod = "orange";
                    store = "orange";
                    admin = "orange";
                    if (d.fuelDates.length > 1 && d.fuelDates.length < 6) {
                      fuel = "orange";
                    } else if (
                      d.fuelDates.length === 1 ||
                      d.fuelDates.length === 0
                    ) {
                      fuel = "green";
                    } else {
                      fuel = "red";
                    }
                    if (d.discrepancies === 0) {
                      admin = "green";
                    } else if (d.discrepancies > 5) {
                      admin = "red";
                    } else {
                      admin = "orange";
                    }
                  }
                  return (
                    <tr>
                      <td colSpan={2}>{d.siteName}</td>
                      <td style={{ backgroundColor: eod, color: "#fff" }}>
                        {d.shiftsAwaiting}
                      </td>
                      <td style={{ backgroundColor: store, color: "#fff" }}>
                        {d.storeInvestigations}
                      </td>
                      <td style={{ backgroundColor: admin, color: "#fff" }}>
                        {d.discrepancies}
                      </td>
                      {d.siteName === "Autoport" ||
                      d.siteName === "Braeside" ||
                      d.siteName === "Bearsden" ||
                      d.siteName === "Bridgeton" ||
                      d.siteName === "Rutherglen" ? (
                        <td style={{ backgroundColor: fuel, color: "#fff" }}>
                          {d.fuelDates.length}
                        </td>
                      ) : (
                        <td>N/A</td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <h1>No data to show</h1>
          )}
        </section>
      )}
    </main>
  );
}
