import "./styles/Orders.scss";
import Button from "@mui/material/Button";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import SendIcon from "@mui/icons-material/Send";
import UserContext from "../context/userContext";
import { useNavigate } from "react-router-dom";
import FadeLoader from "react-spinners/FadeLoader";
import BookerLogo from "./images/booker-logo.png";
import LondisLogo from "./images/Londis.webp"
import StoreNav from "./StoreNav";
import ClaimProduct from "./ClaimProduct";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "20vw",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
    "@media(max-width: 600px)": {
        width: "90vw",
        maxHeight: "40vh",
    },
    "@media(min-width: 601px) and (max-width: 900px)": {
        width: "45vw",
        maxHeight: "40vh",
    },
};

export default function Claim() {
    const [showImage, setShowImage] = useState(false);
    const [quantity, setQuantity] = useState([]);
    const [invoice, setInvoice] = useState("");
    const [site, setSite] = useState("");
    const [barcode, setBarcode] = useState([]);
    const [pack, setPack] = useState([]);
    const [cases, setCases] = useState([]);
    const [price, setPrice] = useState([]);
    const [itemName, setItemName] = useState([]);
    const [qtyOrdered, setQtyOrdered] = useState([]);
    const [data, setData] = useState([]);
    const [photo, setPhoto] = useState([]);
    const [sku, setSku] = useState([]);
    const [arrayPosition, setArrayPosition] = useState(0);
    const [showOrder, setShowOrder] = useState(true);
    const [showSumary, setShowSumary] = useState(false);
    const { userData } = useContext(UserContext);
    const navigate = useNavigate();
    let [loading, setLoading] = useState(false);
    const [enteredVal, setEnteredVal] = useState("");
    const [store, setStore] = useState("");
    const [count, setCount] = useState(0);
    const [products, setProducts] = useState([])

    useEffect(async () => {
        let i = localStorage.getItem("claim");
        await axios.get(`https://nitropos-backend.nitrosolutions.co.uk/claims/active/invoice/${JSON.parse(i)}`).then((res) => {
            console.log(res.data);
            console.log("--------------------")
            console.log(res.data.products)
            setData(res.data);
            setStore(res.data.site);
            var items = [];
            var url = [];
            var ordered = [];
            var prices = [];
            var barcodes = [];
            var caseQty = [];
            var packQty = [];
            var quantity = [];
            var itemSku = [];
            setProducts(res.data.products)
            if (res.data.products) {
                for (let i = 0; i < res.data.products.length; i++) {
                    items.push(res.data.products[i].Name);
                    url.push(res.data.products[i].image);
                    ordered.push(res.data.products[i].qtyOrdered);
                    prices.push(res.data.products[i].price);
                    caseQty.push(res.data.products[i].caseQty);
                    packQty.push(res.data.products[i].packQty);
                    quantity.push(res.data.products[i].qtyReceived);
                    itemSku.push(res.data.products[i].Sku);
                    barcodes.push(res.data.products[i].Barcode);
                }
                setEnteredVal(res.data.products[arrayPosition].qtyReceived);
            }

            setPrice(prices);
            setItemName(items);
            setQtyOrdered(ordered);
            setPhoto(url);
            setCases(caseQty);
            setPack(packQty);
            setQuantity(quantity);
            setSku(itemSku);
            setBarcode(barcodes);
        })
            .catch((err) => {
                console.log(err);
            });

        setInvoice(i);
    }, []);

    function handleManagerClick() {
        setCount(count + 1);
        if (count === 4) {
            setShowOrder(false);
            setShowSumary(true);
        }
    }

    const Submit = () => {
        console.log("SUBMIT")
    }

    return (
        <section className="orderContent">
            <StoreNav site={store} />
            {showOrder ? (
                <div>
                    <>
                        <div className="head">
                            <h4
                                onClick={() => handleManagerClick()}
                                style={{ userSelect: "none" }}
                            >
                                {data.invoiceNumber}
                            </h4>
                            <h4>{data.site}</h4>
                        </div>
                        <div style={{ textAlign: "center" }}>
                            {data.supplier === "Booker" ? (
                                <img src={BookerLogo} width="200px" />
                            ) : (
                                <>
                                    <img src={LondisLogo} width="200px" />
                                </>
                            )}
                        </div>
                    </>
                    <table>
                        <tbody>
                            <tr>
                                <th>Description</th>
                                <th>Invoice Qty</th>
                                <th>Unit Qty To Be Credited</th>
                                <th>Reason Code</th>
                                <th>Sub Reason Code</th>
                                <th>Comments</th>
                                <th>Supporting Images</th>
                                <th>Clear Line</th>
                            </tr>
                            {products ? (
                                products.map(function (d, i) {
                                    return (
                                        <ClaimProduct product={d} i={i}/>
                                    )
                                }))
                                : <></>}

                        </tbody>
                    </table>
                    <Button
                        variant="outlined"
                        color="success"
                        endIcon={<SendIcon sx={{ margin: "0" }} />}
                        onClick={() => Submit()}
                        style={{ width: "20%", margin: "auto" }}
                    >
                        Submit
                    </Button>
                </div>
            ) : (
                <></>
            )}
            {loading ? (
                <div className="loadingSection">
                    <h4>Your claim is being processed, please wait</h4>
                    <FadeLoader color="#2c53a0" loading={loading} size={500} />
                </div>
            ) : (
                <></>
            )}
        </section>
    )
}