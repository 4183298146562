import { useState, useEffect } from "react";
//MUI
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Card from "@mui/material/Card";

//INTERFACES
interface Props {
    wasteData: any;
}

//MODAL STYLING
const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 900,
    bgcolor: "background.paper",
    border: "2px solid #2c53a0",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
    height: "60%",
};

export default function Waste({ wasteData }: Props) {
    return (
        <Box sx={style} className="dashboard_waste_modal">
            <Typography variant="h4" component="h2" className="header">
                {wasteData.name} WASTAGE
            </Typography>
            <Typography variant="h6" component="h2" className="header_cost">
                <p>
                    TOTAL ITEMS: <span>{wasteData.totalWasted}</span>
                </p>
                <p>
                    TOTAL COST: <span>£{wasteData.totalCost.toFixed(2)}</span>
                </p>
            </Typography>

            {wasteData.products.length > 0 &&
                wasteData.products.map((product: any, i: number) => {
                    return (
                        <Accordion className="accordian">
                            <AccordionSummary
                                expandIcon={
                                    <ExpandMoreIcon sx={{ color: "#fff" }} />
                                }
                                aria-controls="panel1-content"
                                id="panel1-header"
                                className="accordian_header"
                            >
                                <Typography variant="h5" component="h2">
                                    {product.name}
                                </Typography>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    className="item_right"
                                >
                                    ITEMS: {product.total} | COST: £
                                    {parseFloat(product.totalCost).toFixed(2)}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails className="accordian_main">
                                {product.breakdown.map((p: any, i2: number) => {
                                    return (
                                        <Card className="breakdown_card">
                                            <p className="reason">{p.reason}</p>
                                            {p.sites.length > 0
                                                ? p.sites.map(
                                                      (s: any, i3: number) => {
                                                          return (
                                                              <div className="site_row">
                                                                  <p>
                                                                      {
                                                                          s.siteName
                                                                      }
                                                                  </p>
                                                                  <p>{s.qty}</p>
                                                              </div>
                                                          );
                                                      }
                                                  )
                                                : "N/A"}
                                        </Card>
                                    );
                                })}
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
        </Box>
    );
}
