import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60vw",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
    maxHeight: "70vh",
    overflowY: "scroll",
    "@media(max-width: 690px)": {
        width: "90vw",
        maxHeight: "90vh",
        p: 4,
    },
};

export default function WasteAlert({
    setOpenWasteModal,
    handleSubmit,
    alert,
    severity,
    message,
}) {
    return (
        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h4">
                <h2 style={{ color: "red" }}>Wastage Alert!</h2>
            </Typography>
            <Typography
                id="modal-modal-description"
                sx={{ display: "flex", flexDirection: "column", gap: "8px" }}
            >
                <Alert severity="error">
                    Some of the products you are collecting are either over or
                    under the expected amount to be collected, please make sure
                    this is correct.
                </Alert>
                <Alert severity="warning">
                    If an actual amount is <b>over</b> the expected amount,
                    please inform the store manager to log any excess waste for
                    that product
                </Alert>
                <Alert severity="warning">
                    If an actual amount is <b>under</b> the expected amount,
                    please make sure this is correct
                </Alert>
            </Typography>
            <Typography id="modal-modal-title" variant="h6" component="h4">
                <h2>
                    If you are happy you have completed these steps click to
                    finalise waste collection or close to check quantities
                </h2>
            </Typography>
            <Typography
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "15px",
                }}
            >
                <Button
                    variant="contained"
                    color="warning"
                    onClick={() => setOpenWasteModal(false)}
                >
                    close
                </Button>
                {!alert ? (
                    <Button
                        color="success"
                        variant="contained"
                        onClick={() => handleSubmit()}
                    >
                        Final Submit
                    </Button>
                ) : (
                    <Alert severity={severity}>{message}</Alert>
                )}
            </Typography>
        </Box>
    );
}
