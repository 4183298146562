import React, { useState, useEffect } from "react";
import "../styles/navbar.scss";
import Logo from "../../images/logo.png";
import LogoutIcon from "@mui/icons-material/Logout";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import { useNavigate, Link } from "react-router-dom";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import axios from "axios";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import PostAddIcon from "@mui/icons-material/PostAdd";
import FeaturedVideoIcon from "@mui/icons-material/FeaturedVideo";
import PlaylistAddCheckCircleIcon from "@mui/icons-material/PlaylistAddCheckCircle";
import TerminalIcon from "@mui/icons-material/Terminal";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
// menu
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";

export default function WareNav(props) {
    const [role, setRole] = useState("");
    const [user, setUser] = useState("");
    const [requests, setRequests] = useState(0);
    const [token, setToken] = useState("");
    const [poAuth, setPoAuth] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        let userToken = localStorage.getItem("auth-token");
        setToken(userToken);
        let userD = localStorage.getItem("userDetails");
        let user = JSON.parse(userD);
        setUser(user.user.user);
        setPoAuth(user.user.poAdmin);
        setRole(user.user.role);
        if (user.user.role != "Warehouse Packer") {
            let getToken = localStorage.getItem("auth-token");
            if (
                getToken === "" ||
                getToken === null ||
                getToken === undefined
            ) {
                Logout();
            }
            //checks holidays requests
            axios
                .post(
                    "https://hr-be.racetrackpitstop.co.uk/holiday/admin/pending/new",
                    {
                        user: user.user.user,
                    },
                    { headers: { "x-auth-token": getToken } }
                )
                .then((res) => {
                    if (res.data.length > 0) {
                        setRequests(res.data.length);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, []);

    const Logout = () => {
        localStorage.setItem("userDetails", "");
        navigate("/");
    };
    return (
        <nav id="navbar">
            <img
                src={Logo}
                alt="logo"
                onClick={() => {
                    navigate("/warehouse/v4u-disposables");
                }}
            />
            <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                    <React.Fragment>
                        <MenuOpenIcon
                            {...bindTrigger(popupState)}
                            fontSize="large"
                        />
                        <Menu {...bindMenu(popupState)}>
                            {role === "Manager" ||
                            role === "Developer" ||
                            user ===
                                "craig.mcclenaghan@racetrackpitstop.co.uk" ? (
                                <>
                                    <MenuItem onClick={() => navigate("/home")}>
                                        <span className="mobile__icons">
                                            <AdminPanelSettingsIcon />
                                            Admin Area
                                        </span>
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() =>
                                            navigate(
                                                "/warehouse/invoice-checker"
                                            )
                                        }
                                    >
                                        <span className="mobile__icons">
                                            <PlaylistAddCheckCircleIcon />
                                            Invoice Checker
                                        </span>
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() =>
                                            navigate("/warehouse/manual-order")
                                        }
                                    >
                                        <span className="mobile__icons">
                                            <NoteAddIcon />
                                            Manual Order
                                        </span>
                                    </MenuItem>
                                </>
                            ) : null}
                            {role === "Developer" ||
                            role === "Manager" ||
                            user ===
                                "craig.mcclenaghan@racetrackpitstop.co.uk" ||
                            user === "development@nitrosolutions.co.uk" ? (
                                <MenuItem
                                    onClick={() =>
                                        navigate("/staff-management")
                                    }
                                >
                                    <span className="mobile__icons">
                                        <Diversity3Icon />
                                        Staff Management
                                    </span>
                                </MenuItem>
                            ) : null}

                            {role === "V4U Manager" ? (
                                <>
                                    <MenuItem
                                        onClick={() =>
                                            navigate(
                                                "/managerZone/epos-products"
                                            )
                                        }
                                    >
                                        <span className="mobile__icons">
                                            <ProductionQuantityLimitsIcon />
                                            EPOS Products
                                        </span>
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() =>
                                            navigate(
                                                "/managerZone/add-epos-item"
                                            )
                                        }
                                    >
                                        <span className="mobile__icons">
                                            <PostAddIcon />
                                            Add EPOS Item
                                        </span>
                                    </MenuItem>
                                </>
                            ) : null}
                            {role === "Warehouse Manager" ? (
                                <MenuItem
                                    onClick={() =>
                                        navigate("/warehouse/epos-products")
                                    }
                                >
                                    <span className="mobile__icons">
                                        <AddShoppingCartIcon />
                                        EPOS Products
                                    </span>
                                </MenuItem>
                            ) : null}
                            {role !== "Warehouse Packer" ? (
                                <>
                                    <MenuItem
                                        onClick={() =>
                                            navigate("/warehouse/tiktok")
                                        }
                                    >
                                        <span className="mobile__icons">
                                            <FeaturedVideoIcon />
                                            tiktok
                                        </span>
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() =>
                                            navigate(
                                                "/warehouse/disposable-script"
                                            )
                                        }
                                    >
                                        <span className="mobile__icons">
                                            <TerminalIcon />
                                            Script
                                        </span>
                                    </MenuItem>
                                    {/* //! REMOVE BELWO AND UNCOMMENT UNDERNEATH WHEN NEW PICKLISTS LIVE */}
                                    {/* <MenuItem
                                        onClick={() =>
                                            navigate("/warehouse/picklist")
                                        }
                                    >
                                        <span className="mobile__icons">
                                            <ShoppingBasketIcon />
                                            Pick
                                        </span>
                                    </MenuItem> */}
                                    <Link
                                        to={`https://warehouse.nitropos.co.uk/?token=${token}&user=${user}&role=${role}`}
                                        style={{
                                            color: "inherit",
                                            textDecoration: "inherit",
                                        }}
                                    >
                                        <MenuItem>
                                            <span className="mobile__icons">
                                                <ShoppingBasketIcon />
                                                Pick
                                            </span>
                                        </MenuItem>
                                    </Link>
                                    <MenuItem
                                        onClick={() =>
                                            navigate("/purchase-order-admin")
                                        }
                                        disabled={!poAuth}
                                    >
                                        <span className="mobile__icons">
                                            <FormatListBulletedIcon /> PO Admin{" "}
                                            {poAuth ? null : (
                                                <span
                                                    style={{
                                                        fontSize: "0.8rem",
                                                        color: "red",
                                                    }}
                                                >
                                                    (No Access)
                                                </span>
                                            )}
                                        </span>
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => navigate("/cctv-status")}
                                    >
                                        <span className="mobile__icons">
                                            <QrCodeScannerIcon />
                                            CCTV Status Change
                                        </span>
                                    </MenuItem>
                                </>
                            ) : null}
                            <MenuItem onClick={() => Logout()}>
                                <span className="mobile__icons">
                                    <LogoutIcon />
                                    Logout
                                </span>
                            </MenuItem>
                        </Menu>
                    </React.Fragment>
                )}
            </PopupState>
        </nav>
    );
}
