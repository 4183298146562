import { useEffect, useState } from "react";
import axios from "axios";
import Nitro from "../../images/logo.png";
import "./bakes.scss";
import SiteBakes from "./SiteBakes";

export default function BakeCheck() {
  const [data, setData] = useState([]);
  const [reload, setReload] = useState(false)

  useEffect(() => {
    axios
      .get("https://nitropos-backend.nitrosolutions.co.uk/tubbees/order/review")
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reload]);

  const rel = () => {
    setReload(!reload)
  }


  return (
    <main className="bake__check">
      <img src={Nitro} />
      {data && data.length > 0 ? (
        <section className="bakes__orders">
          <h1>Please check the following bake orders</h1>
          {data.map(function (d, i) {
            return (
                <SiteBakes data={d} rel={rel}/>
            );
          })}
        </section>
      ) : (
        <h1>No orders to check</h1>
      )}
    </main>
  );
}
