import { useState } from "react";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Box from "@mui/material/Box";
import { Button, Alert } from "@mui/material";

//STYLING
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40vw",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
    "@media(max-width: 600px)": {
        width: "90vw",
        maxHeight: "70vh",
        overflowX: "scrollable",
    },
};

export default function MarkPaid({ setShowPaidModal, rel, requestId }) {
    const url = process.env.REACT_APP_ANPR_URL;
    // const url = "http://10.0.0.13:4024";
    const [staff, setStaff] = useState("");

    //Alert
    const [message, setMessage] = useState("");
    const [alert, setAlert] = useState("");
    const [severity, setSeverity] = useState("");

    const handlePaid = () => {
        //Request ID is 6 for the paid status
        let data = {
            requestId: requestId,
            status: 6,
            user: staff,
        };

        if (staff === "") {
            setMessage("Please enter your name");
            setSeverity("warning");
            setAlert(true);
            setTimeout(() => {
                setAlert(false);
                setMessage("");
                setSeverity("");
            }, 3000);
        } else {
            axios
                .post(`${url}/cctv-system/submission/update/status`, data)
                .then((res) => {
                    setSeverity("success");
                    setMessage("Marked as paid");
                    setAlert(true);
                    setTimeout(() => {
                        setAlert(false);
                        setSeverity("");
                        setMessage("");
                        setShowPaidModal(false);
                        rel();
                    }, 3500);
                })
                .catch((err) => {
                    console.log(err);
                    setSeverity("error");
                    setMessage("Something went wrong, please try again");
                    setAlert(true);
                    setTimeout(() => {
                        setAlert(false);
                        setSeverity("");
                        setMessage("");
                    }, 3500);
                });
        }
    };

    return (
        <Box sx={style} className="all_modal">
            <Typography id="status_header" variant="h6" component="h2">
                <h3 style={{ textAlign: "center", width: "100%" }}>
                    Mark Drive/off/ NMOP as paid
                </h3>
            </Typography>
            <Typography id="status_header">
                <h3 style={{ textAlign: "center", width: "100%" }}>
                    If the driver had returned to pay a drive off or NMOP please
                    mark as paid below.
                </h3>
                <div>
                    <label>Your Name:</label>
                    <br />
                    <input
                        type="text"
                        onChange={(e) => setStaff(e.target.value)}
                        value={staff}
                    />
                </div>
            </Typography>
            {!alert ? (
                <Typography
                    id="status_header"
                    variant="h6"
                    component="h2"
                    sx={{ display: "flex", justifyContent: "space-between" }}
                >
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => setShowPaidModal(false)}
                    >
                        close
                    </Button>
                    <Button
                        onClick={handlePaid}
                        variant="contained"
                        color="primary"
                    >
                        Mark Paid
                    </Button>
                </Typography>
            ) : (
                <Alert severity={severity}>{message}</Alert>
            )}
        </Box>
    );
}
