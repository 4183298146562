import Chart from "react-apexcharts";

interface Props {
    series: any[];
    xAxis: string[];
}

export function Line({ series, xAxis }: Props) {
    const graphInfo: any = {
        series: series,
        options: {
            chart: {
                type: "line",
                zoom: {
                    enabled: false,
                },
            },
            dataLabels: {
                enabled: false,
            },
            grid: {
                row: {
                    colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                    opacity: 0.5,
                },
            },
            xaxis: {
                categories: xAxis,
            },
        },
    };

    return (
        <Chart
            options={graphInfo.options}
            series={graphInfo.series}
            type="line"
            height={350}
        />
    );
}
