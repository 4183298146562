import "./bearsden.scss";
import logo from './images/bpLogo.webp'
import ultimate from './images/ultimate.webp'
import axios from 'axios'
import { useEffect, useState } from 'react'

export default function Bearsden() {
  const [data, setData] = useState("")

  useEffect(() => {
    axios.get("https://nitropos-backend.nitrosolutions.co.uk/fuel/screen?site=Bearsden").then((res) => {
      setData(res.data)
    }).catch((err) => {
      console.log(err)
    })
    setInterval(() => {
      window.location.reload();
    }, 60000)
  }, [])

  return (
    <section className="screen">
      <div className="column">
        <h1>Regular Unleaded</h1>
        <div className="bpSite">{data.unleaded}</div>
        <h1>Regular Diesel</h1>
        <div className="bpSite">{data.diesel}</div>
      </div>
      <div className="column">
        <div className="section">
          <img src={logo} alt="BP Logo" />
        </div>
        <div className="section" >
          <img src={ultimate} alt="BP Ultimate" />
        </div>
        <div className="section"></div>
        <div className="section"></div>
        <div className="section"></div>
      </div>
    </section>
  );
}
