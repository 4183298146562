import Footer from "../../Footer";
import V4U from "../images/v4uLogo.webp";
import TubbeesLogo from "../images/tubbees-logo.png";
import Booker from "../images/booker-logo.png";
import Londis from "../images/Londis.webp";
import Mortons from "../images/mortons.png";
import RTOrdering from "../images/RaceTrack-Ordering.png";
import RTLogo from "../images/rt-logo.png";
import SnusDaddyLogo from "../images/snud_daddy-logo.png";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/storeZone.scss";
import Alert from "@mui/material/Alert";
import axios from "axios";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import StoreNav from "../StoreNav";

export default function StoreHome() {
    const [store, setStore] = useState("");
    const navigate = useNavigate();
    const [booker, setBooker] = useState("");
    const [londisInvoice, setLondisInvoice] = useState("");
    const [mortonInvoice, setMortonInvoice] = useState("");
    const [rtOrdering, setRtOrdering] = useState("");
    const [vaped4u, setVaped4u] = useState(false);
    const [tubbees, setTubbees] = useState(false);
    const [rt, setRt] = useState(false);
    const [v4u, setV4u] = useState("");
    const [showClaims, setShowClaims] = useState(false);
    const [bookerClaims, setBookerClaims] = useState(0);
    const [londisClaims, setLondisClaims] = useState(0);
    const [tubs, setTubs] = useState("");

    useEffect(async () => {
        let s = localStorage.getItem("site");

        setStore(JSON.parse(s));
        // console.log(JSON.parse(s))

        await axios
            .get(
                `https://nitropos-backend.nitrosolutions.co.uk/edn/invoice/count?site=${JSON.parse(
                    s
                )}`
            )

            .then((res) => {
                console.log(res.data);
                setBooker(res.data.booker);
                setLondisInvoice(res.data.londis);
                setRtOrdering(res.data.racetrack);
                setVaped4u(res.data.vaped4uLabel);
                setTubbees(res.data.tubbeesLabel);
                setRt(res.data.racetrackLabel);
                setV4u(res.data.v4u);
                setMortonInvoice(res.data.morton);
                setTubs(res.data.tubbees);
            })
            .catch((err) => {
                console.log(err);
            });

        //check labels for alert
        axios
            .post(
                `https://nitropos-backend.nitrosolutions.co.uk/labels/${JSON.parse(
                    s
                )}/new/status`
            )
            .then((res) => {
                // console.log(res.data)
                if (res.data === true) {
                    document
                        .getElementById("rt__labels")
                        .classList.add("alert__labels");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    function navigateInvoice(supplier) {
        localStorage.setItem("supplier", JSON.stringify(supplier));
        navigate(`/storezone/invoices`);
    }

    function navigateHistory(supplier) {
        localStorage.setItem("supplier", JSON.stringify(supplier));
        navigate(`/storezone/history`);
    }
    function navigateClaims(supplier) {
        localStorage.setItem("supplier", JSON.stringify(supplier));
        navigate(`/storezone/claims`);
    }

    return (
        <div className="StaffHome">
            {/* <StoreNav site={store} /> */}
            <h1>
                Welcome To The <span>{store}</span> Store Zone
            </h1>
            <div className="columns">
                <div className="column">
                    <h2>Label Generator</h2>
                    <div className="row">
                        <div
                            className="img"
                            onClick={() => {
                                navigate(`/vaped4u?site=${store}`);
                            }}
                        >
                            <span>
                                {vaped4u ? (
                                    <PublishedWithChangesIcon
                                        color="warning"
                                        fontSize="small"
                                    />
                                ) : (
                                    <PublishedWithChangesIcon
                                        sx={{ color: "transparent" }}
                                        fontSize="small"
                                    />
                                )}
                            </span>
                            <img src={V4U} />
                        </div>
                        <div
                            className="img"
                            onClick={() => {
                                navigate(`/tubbees?site=${store}`);
                            }}
                        >
                            <span>
                                {tubbees ? (
                                    <PublishedWithChangesIcon
                                        color="warning"
                                        fontSize="small"
                                    />
                                ) : (
                                    <PublishedWithChangesIcon
                                        sx={{ color: "transparent" }}
                                        fontSize="small"
                                    />
                                )}
                            </span>
                            <img src={TubbeesLogo} />
                        </div>
                        <div
                            className="img "
                            id="rt__labels"
                            onClick={() => {
                                navigate(`/racetrack?site=${store}`);
                            }}
                        >
                            <span>
                                {rt ? (
                                    <PublishedWithChangesIcon
                                        color="warning"
                                        fontSize="small"
                                    />
                                ) : (
                                    <PublishedWithChangesIcon
                                        sx={{ color: "transparent" }}
                                        fontSize="small"
                                    />
                                )}
                            </span>
                            <img src={RTLogo} />
                        </div>
                        <div
                            className="img "
                            id="snus__labels"
                            onClick={() => {
                                navigate(`/snusdaddy?site=${store}`);
                            }}
                        >
                            <img
                                style={{ marginTop: "-15%" }}
                                src={SnusDaddyLogo}
                            />
                        </div>
                    </div>
                </div>
                {/* <div className="column">
                    <h2>Invoices</h2>
                    <div className="row">
                        <div
                            className="img"
                            onClick={() => navigateInvoice("Booker")}
                        >
                            <span>{booker}</span>
                            <img src={Booker} />
                        </div>
                        <div
                            className="img"
                            onClick={() => navigateInvoice("Londis")}
                        >
                            <span>{londisInvoice}</span>
                            <img src={Londis} />
                        </div>
                        <div
                            className="img"
                            onClick={() => navigateInvoice("Mortons")}
                        >
                            <span>{mortonInvoice}</span>
                            <img src={Mortons} />
                        </div>
                    </div>
                    <div className="row">
                        <div
                            className="img"
                            onClick={() => navigateInvoice("RTOrdering")}
                        >
                            <span>{rtOrdering}</span>
                            <img src={RTOrdering} />
                        </div>
                        <div
                            className="img"
                            onClick={() => navigateInvoice("V4U")}
                        >
                            <span>{v4u}</span>
                            <img src={V4U} />
                        </div>
                        <div
                            className="img"
                            onClick={() => navigateInvoice("Tubbees Bakes")}
                        >
                            <span>{tubs}</span>
                            <img src={TubbeesLogo} />
                        </div>

                        <div
                            id="img"
                            onClick={() =>
                                navigate("/storezone/manual-invoice")
                            }
                        >
                            <p>Manual Invoice</p>
                        </div>
                    </div>
                </div>
                <div className="column">
                    <h2>History</h2>
                    <div className="row">
                        <div
                            className="img"
                            onClick={() => navigateHistory("Booker")}
                        >
                            <img src={Booker} />
                        </div>
                        <div
                            className="img"
                            onClick={() => navigateHistory("Londis")}
                        >
                            <img src={Londis} style={{ marginTop: "5%" }} />
                        </div>
                        <div
                            className="img"
                            onClick={() => navigateHistory("RTOrdering")}
                        >
                            <img src={RTOrdering} style={{ marginTop: "5%" }} />
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    );
}
