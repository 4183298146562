// Handles the styling for the Name of the product

import React, { useState, useEffect } from "react";

export default function Name(props) {
  const [size, setSize] = useState(0.7);


  return <div className="align-self-center" style={{ fontSize: `${size}em` }}>{props.name}</div>;
}
