import { useState } from "react";
import { dataUriToBuffer } from "data-uri-to-buffer";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import Alert from "@mui/material/Alert";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import {
    DialogTitle,
    DialogContentText,
    DialogContent,
    DialogActions,
} from "@mui/material";
import moment from "moment";

export default function DriveOffForm({ site, siteName, rel }) {
    const url = process.env.REACT_APP_ANPR_URL;
    // const url = "http://10.0.0.13:4024";
    const [registration, setRegistration] = useState("");
    const [date, setDate] = useState("");
    const [time, setTime] = useState("");
    const [pump, setPump] = useState("");
    const [fuelType, setFuelType] = useState("");
    const [amount, setAmount] = useState("");
    const [litres, setLitres] = useState("");
    const [staff, setStaff] = useState("");
    const [crimeRef, setCrimeRef] = useState("");
    const [note, setNote] = useState("");
    const [message, setMessage] = useState("");
    const [alert, setAlert] = useState(false);
    const [severity, setSeverity] = useState("");
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    const [disable, setDisable] = useState(false);
    const [image, setImage] = useState("");
    const [images, setImages] = useState([]);
    const [imageAlert, setImageAlert] = useState(false);

    const handleValidation = () => {
        if (
            registration === "" ||
            date === "" ||
            time === "" ||
            pump === "" ||
            fuelType === "" ||
            fuelType === "Please select" ||
            amount === "" ||
            litres === "" ||
            staff === ""
        ) {
            setMessage("Please complete all required fields and try again");
            setSeverity("warning");
            setAlert(true);
            setTimeout(() => {
                setAlert(false);
                setSeverity("");
                setMessage("");
            }, 3000);
        } else if (pump < 1 || pump > 8) {
            setMessage("Please complete all required fields and try again");
            setSeverity("warning");
            setAlert(true);
            setTimeout(() => {
                setAlert(false);
                setSeverity("");
                setMessage("");
            }, 3000);
        } else if (crimeRef === "") {
            setOpen(true);
        } else {
            handleSubmitDriveOff();
        }
    };
    const addImage = () => {
        if (image === "") {
            document.getElementById("image-btn").style.display = "none";
            setMessage("Please upload an image first");
            setSeverity("warning");
            setImageAlert(true);
            setTimeout(() => {
                setImageAlert(false);
                setSeverity("");
                setMessage("");
                document.getElementById("image-btn").style.display = "flex";
            }, 2500);
        } else {
            const img = new FileReader();
            img.readAsDataURL(image);
            img.onload = () => {
                if (images.includes(img.result)) {
                    document.getElementById("image-btn").style.display = "none";
                    setMessage("This image has already been added");
                    setSeverity("warning");
                    setImageAlert(true);
                    setTimeout(() => {
                        setImageAlert(false);
                        setMessage("");
                        setSeverity("");
                        document.getElementById("image-btn").style.display =
                            "flex";
                    }, 2500);
                } else {
                    setImages([...images, img.result]);
                }
            };
            setImage("");
            document.getElementById("image-upload").value = null;
        }
    };

    const removeImage = (image) => {
        const filteredArray = images.filter((img) => img !== image);
        setImages(filteredArray);
    };

    const formatCheck = (check) => {
        let formatArr = [];

        for (let i = 0; i < check.length; i++) {
            let fileData = check[i].split(",");
            let type = fileData[0].replace("data:", "");
            type = type.replace(";base64", "");
            let imgFormat = type.replace("image/", "");
            formatArr.push(imgFormat);
        }

        return formatArr;
    };

    const handleSubmitDriveOff = () => {
        setOpen(false);
        setDisable(true);
        let imageFormats = formatCheck(images);
        let data = {
            staffName: staff,
            siteId: site,
            site: siteName,
            date: date,
            time: time,
            requestType: 1,
            reason: "drive off",
            crimeReference: crimeRef,
            items: "",
            description: "",
            person: "",
            note: note,
            images: imageFormats,
            plate: registration,
            pump: pump,
            pumpType: fuelType,
            amount: amount,
            litres: litres,
        };

        axios
            .post(`${url}/cctv-system/submit`, data)
            .then((res) => {
                let s3Array = res.data.s3Links;

                for (let i = 0; i < s3Array.length; i++) {
                    let buffer = dataUriToBuffer(images[i]);

                    axios
                        .put(s3Array[i].url, buffer.buffer)
                        .then((res) => {})
                        .catch((err) => {
                            console.log(err);
                        });
                }
                setMessage("Drive Off Request successful");
                setSeverity("success");
                setAlert(true);
                setTimeout(() => {
                    setAlert(false);
                    setSeverity("");
                    setMessage("");
                    setRegistration("");
                    setDate("");
                    setTime("");
                    setPump("");
                    setFuelType("Please select");
                    setAmount("");
                    setLitres("");
                    setStaff("");
                    setCrimeRef("");
                    setNote("");
                    setDisable(false);
                    setImage("");
                    setImages([]);
                    rel();
                }, 3000);
            })
            .catch((err) => {
                console.log(err);
                setMessage("Something went wrong. Please try again");
                setSeverity("error");
                setAlert(true);
                setTimeout(() => {
                    setAlert(false);
                    setSeverity("");
                    setMessage("");
                }, 3000);
            });
    };

    const handlePump = (e) => {
        if (parseFloat(e) < 1 || parseFloat(e) > 8) {
            document.getElementById("pump_alert").style.display =
                "inline-block";
            document.getElementById("pump_label").style.display = "none";
            setTimeout(() => {
                document.getElementById("pump_alert").style.display = "none";
                document.getElementById("pump_label").style.display =
                    "inline-block";
                setPump("");
            }, 2500);
        } else {
            setPump(e);
        }
    };

    const handleFields = (e, alert, label, setter) => {
        if (parseFloat(e) < 0) {
            document.getElementById(alert).style.display = "inline-block";
            document.getElementById(label).style.display = "none";
            setTimeout(() => {
                document.getElementById(alert).style.display = "none";
                document.getElementById(label).style.display = "inline-block";
                setter("");
            }, 2500);
        } else {
            setter(e);
        }
    };

    const imageCheck = (img) => {
        let extension = img.name.split(".").pop().toLowerCase();
        if (
            extension != "png" &&
            extension != "tiff" &&
            extension != "jfif" &&
            extension != "bmp" &&
            extension != "jpg" &&
            extension != "gif" &&
            extension != "svg" &&
            extension != "svgz" &&
            extension != "webp" &&
            extension != "ico" &&
            extension != "xbm" &&
            extension != "dib" &&
            extension != "pjp" &&
            extension != "apng" &&
            extension != "pjpeg" &&
            extension != "avif" &&
            extension != "jpeg"
        ) {
            setMessage("Image is not a valid format. (pdf, docx, doc, txt");
            setSeverity("warning");
            setImageAlert(true);
            setTimeout(() => {
                setImageAlert(false);
                setMessage("");
                setSeverity("");
            }, 3000);
        } else {
            setImage(img);
        }
    };

    return (
        <main className="driveoff_form">
            <Dialog onclose={handleClose} open={open}>
                <DialogTitle sx={{ color: "#FF0000" }}>
                    IMPORTANT - CRIME REFERENCE NUMBER
                </DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ color: "#000" }}>
                        You have not provided a crime reference number. Please
                        confirm you will call the police to get a crime
                        reference number and update this request
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="error">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmitDriveOff}>Accept</Button>
                </DialogActions>
            </Dialog>

            <label>
                Car Registration Number <span>*</span>
            </label>
            <input
                type="text"
                onChange={(e) => setRegistration(e.target.value)}
                value={registration}
            />
            <label>
                On what date did this happen? <span>*</span>
            </label>
            <input
                type="date"
                onChange={(e) => setDate(e.target.value)}
                value={date}
                max={moment().format("YYYY-MM-DD")}
            />
            <label>
                At what time did this happen? <span>*</span>
            </label>
            <input
                type="time"
                onChange={(e) => setTime(e.target.value)}
                value={time}
            />
            <label id="pump_label">
                At what pump number did this happen? <span>*</span>
            </label>
            <label id="pump_alert">Please enter between 1-8</label>
            <input
                type="number"
                pattern="[0-9]*"
                onChange={(e) => handlePump(e.target.value)}
                value={pump}
            />
            <label>
                Select the fuel type. <span>*</span>
            </label>
            <select onChange={(e) => setFuelType(e.target.value)}>
                <option>Please select</option>
                <option>Diesel</option>
                <option>Unleaded</option>
            </select>
            <label id="amount_label">
                How much did it cost? (£) <span>*</span>
            </label>
            <label id="amount_alert">Please enter a positvie amount</label>
            <input
                type="number"
                pattern="[0-9]*"
                onChange={(e) =>
                    handleFields(
                        e.target.value,
                        "amount_alert",
                        "amount_label",
                        setAmount
                    )
                }
                value={amount}
            />
            <label id="litres_label">
                How many litres were taken? <span>*</span>{" "}
            </label>
            <label id="litres_alert">Please enter a postive amount</label>
            <input
                type="number"
                pattern="[0-9]*"
                onChange={(e) => {
                    handleFields(
                        e.target.value,
                        "litres_alert",
                        "litres_label",
                        setLitres
                    );
                }}
                value={litres}
            />
            <label>
                What is your full name? <span>*</span>
            </label>
            <input
                type="text"
                onChange={(e) => setStaff(e.target.value)}
                value={staff}
                maxLength="40"
            />
            <label>If you have a Crime reference, please enter it here.</label>
            <input
                type="text"
                onChange={(e) => setCrimeRef(e.target.value)}
                value={crimeRef}
                maxLength="20"
            />
            <label>Upload any images (Optional)</label>
            <input
                type="file"
                accept="image/*"
                onChange={(e) => {
                    imageCheck(e.target.files[0]);
                }}
            />
            <div className="image__uploads">
                {images.length > 0 ? (
                    images.map((image) => {
                        return (
                            <div className="image_container">
                                <img src={image} />
                                <Button
                                    color="error"
                                    onClick={() => removeImage(image)}
                                >
                                    Remove
                                </Button>
                            </div>
                        );
                    })
                ) : (
                    <></>
                )}
            </div>
            {imageAlert ? <Alert severity={severity}>{message}</Alert> : <></>}
            <Button id="image-btn" variant="outlined" onClick={addImage}>
                Add Image
            </Button>
            <label>Any notes?</label>
            <textarea
                rows="4"
                onChange={(e) => setNote(e.target.value)}
                maxLength="500"
                value={note}
            />
            {!alert ? (
                <Button
                    variant="contained"
                    color="success"
                    onClick={handleValidation}
                    endIcon={<SendIcon />}
                    disabled={disable}
                >
                    Submit
                </Button>
            ) : (
                <Alert severity={severity}>{message}</Alert>
            )}
        </main>
    );
}
