import { useState, useEffect } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";
import CarCrashIcon from "@mui/icons-material/CarCrash";
import ConvertToDriveOff from "./modals/ConvertToDriveOff";
import Modal from "@mui/material/Modal";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import SpeakerNotesOffIcon from "@mui/icons-material/SpeakerNotesOff";
import NotesModal from "./modals/NotesModal";
import VehicleDetails from "../../StoreZone/storeManagement/driveoff/modals/VehicleDetails";
import Contract from "../../StoreZone/storeManagement/driveoff/modals/Contract";
import ListIcon from "@mui/icons-material/List";
import StatusModal from "./modals/StatusModal";
import Images from "../../StoreZone/storeManagement/driveoff/modals/Images";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import SdStorageIcon from "@mui/icons-material/SdStorage";
import Tooltip from "@mui/material/Tooltip";
import StorageModal from "./modals/StorageModal";
import axios from "axios";

export default function NMOPIndiviudal({ request, rel, statusList }) {
    const url = process.env.REACT_APP_ANPR_URL;
    const [status, setStatus] = useState("");
    const [showConversionModal, setShowConversionModal] = useState(false);
    const [showNotesModal, setShowNotesModal] = useState(false);
    const [showVehicleDetailModal, setShowVehicleDetailModal] = useState(false);
    const [showContractModal, setShowContractModal] = useState(false);
    const [showStatusModal, setShowStatusModal] = useState(false);
    const [showImagesModal, setShowImagesModal] = useState(false);
    const [showStorageModal, setShowStorageModal] = useState(false);
    const [photosArr, setPhotosArr] = useState([]);
    const [count, setCount] = useState(0);

    useEffect(() => {
        for (let i = 0; i < statusList.length; i++) {
            if (statusList[i].statusId === request.statusId) {
                setStatus(statusList[i].name);
            }
        }

        const photoArr = [
            request.driverImg,
            request.itemLeftImg,
            request.signatureImg,
        ];
        setPhotosArr(photoArr);
    }, [rel]);

    useEffect(() => {
        //Changes driveoff countdown in realtime
        setTimeout(() => {
            setCount(count + 1);
            handleTimeToDriveOff(request.submissionDate);
        }, 1000);
        if (handleTimeToDriveOff(request.submissionDate).includes("-")) {
            axios
                .post(`${url}/cctv-system/nmop/conversion`, {
                    requestId: request.requestId,
                })
                .then((res) => {
                    rel();
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [count]);

    const handleTimeToDriveOff = (submitted) => {
        //Get End date (3 days from submitted day)
        let endDate = moment(submitted).add(3, "d");
        //Current Date Time
        let currDate = moment();
        //Duration between the date Times
        let duration = moment.duration(endDate.diff(currDate));

        return (
            duration._data.days +
            "d " +
            duration._data.hours +
            "h " +
            duration._data.minutes +
            "m " +
            duration._data.seconds +
            "s"
        );
    };

    return (
        <>
            <tr>
                <th>{request.site}</th>
                <th>
                    {new Date(request.date).toLocaleDateString("en-GB")}-
                    {request.time}
                </th>
                <th>{request.pump}</th>
                <th
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowVehicleDetailModal(true)}
                >
                    {request.plate}
                </th>
                <th
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        border: "none",
                        marginTop: "2%",
                    }}
                >
                    <ListIcon
                        color="primary"
                        onClick={() => setShowStatusModal(true)}
                        style={{ cursor: "pointer" }}
                    />
                    {status}
                </th>
                <th>
                    <VisibilityIcon
                        color="primary"
                        style={{ cursor: "pointer" }}
                        onClick={() => setShowContractModal(true)}
                    />
                </th>
                <th>{request.phone}</th>
                <th>{handleTimeToDriveOff(request.submissionDate)}</th>
                <th>
                    {" "}
                    {!request.storageLocation ? (
                        <SdStorageIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                setShowStorageModal(true);
                            }}
                        />
                    ) : (
                        <Tooltip title="Click to copy">
                            <SdStorageIcon
                                style={{ cursor: "pointer" }}
                                color="success"
                                onClick={() => {
                                    navigator.clipboard.writeText(
                                        request.storageLocation
                                    );
                                }}
                            />
                        </Tooltip>
                    )}
                </th>
                <th>
                    {" "}
                    {photosArr && photosArr.length > 0 ? (
                        <CameraAltIcon
                            style={{ cursor: "pointer" }}
                            color="primary"
                            onClick={(e) => setShowImagesModal(true)}
                        />
                    ) : (
                        <CameraAltIcon />
                    )}
                </th>
                <th>
                    <CarCrashIcon
                        onClick={() => setShowConversionModal(true)}
                        color="warning"
                        style={{ cursor: "pointer" }}
                    />
                </th>
                <th>
                    {request.notes.length > 0 ? (
                        <SpeakerNotesIcon
                            color="primary"
                            style={{ cursor: "pointer" }}
                            onClick={() => setShowNotesModal(true)}
                        />
                    ) : (
                        <SpeakerNotesOffIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => setShowNotesModal(true)}
                        />
                    )}
                </th>
            </tr>
            <Modal
                aria-labelledby="Notes Modal"
                aria-describedby="Notes Modal"
                open={showNotesModal}
            >
                <NotesModal
                    handleCloseNotes={setShowNotesModal}
                    allNotes={request.notes}
                    id={request.requestId}
                    rel={rel}
                    isDev={true}
                    requestType={request.requestType}
                />
            </Modal>
            <Modal
                aria-labelledby="Conversion Modal"
                aria-describedby="Conversion Modal"
                open={showConversionModal}
            >
                <ConvertToDriveOff
                    rel={rel}
                    id={request.requestId}
                    setShowConversionModal={setShowConversionModal}
                />
            </Modal>
            <Modal
                aria-labelledby="Vehicles Details Modal"
                aria-describedby="Vehicles Details Modal"
                open={showVehicleDetailModal}
            >
                <VehicleDetails
                    setShowVehicleDetailModal={setShowVehicleDetailModal}
                    registration={request.plate}
                    id={request.requestId}
                />
            </Modal>
            <Modal
                aria-labelledby="NMOP Contract Modal"
                aria-describedby="NMOP Contract Modal"
                open={showContractModal}
            >
                <Contract
                    setShowContractModal={setShowContractModal}
                    request={request}
                    siteName={request.site}
                />
            </Modal>
            <Modal open={showStatusModal}>
                <StatusModal
                    handleCloseStatus={setShowStatusModal}
                    currentStatus={status}
                    statusList={statusList}
                    reqId={request.requestId}
                    rel={rel}
                    isDev={true}
                />
            </Modal>
            <Modal
                aria-labelledby="Images Modal"
                aria-describedby="Images Modal"
                open={showImagesModal}
            >
                <Images
                    driver={request.driverImg}
                    item={request.itemLeftImg}
                    sig={request.signatureImg}
                    setShowImagesModal={setShowImagesModal}
                />
            </Modal>
            <Modal
                aria-labelledby="Storage Modal"
                aria-describedby="Storage Modal"
                open={showStorageModal}
            >
                <StorageModal
                    handleCloseStorage={setShowStorageModal}
                    cStatus={request.statusId}
                    id={request.requestId}
                    rel={rel}
                    request={request}
                />
            </Modal>
        </>
    );
}
