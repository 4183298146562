import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Tick from "../../animations/Tick";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Modal from "@mui/material/Modal";
import AllergenModal from "./AllergenModal";
import { getIngredients } from "../hoagiesUtils/Api";

export default function ModifyProduct(props) {
  const url = "https://nitropos-backend.nitrosolutions.co.uk";

  const productList = props.products;
  const categoryList = props.cats;
  const allergenList = props.allergens;
  const [showAddChild, setShowAddChild] = useState(false);
  const [user, setUser] = useState("");
  const [ingCat, setIngCat] = useState([]);
  const [prodPack, setProdPack] = useState(false);
  const [allergens, setAllergens] = useState([]);
  const [reload, setReload] = useState(false);
  const [makeMaster, setMakeMaster] = useState(false);
  //alerts
  const [childAlert, setChildAlert] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showTick, setShowTick] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  //modify products
  const [showProduct, setShowProduct] = useState(false);
  const [product, setProduct] = useState({});
  const [children, setChildren] = useState([]);
  const [addedChildren, setAddedChildren] = useState([]);
  const [days, setDays] = useState("");
  const [hours, setHours] = useState("");
  const [minutes, setMinutes] = useState("");

  //modal functions
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    let userD = localStorage.getItem("userDetails");
    let user = JSON.parse(userD);
    setUser(user.user.user);

    getIngredients().then((ingredients) => {
      setIngCat(ingredients);
    });
  }, [reload]);

  const rel = () => {
    setReload(!reload);
  };

  const handleProduct = async (product) => {
    if (product === "--Select One--") {
      setShowProduct(false);
    } else {
      setShowProduct(true);
      productList.forEach((prod) => {
        if (prod.name.trim() === product.trim()) {
          console.log(prod);
          setProduct(prod);
          setChildren(prod.children);
          setAllergens(prod.allergens);
          if (prod.isChild) {
            setChildAlert(true);
            setProdPack(true);
            if (prod.allergens) {
              let allergens = prod.allergens;
              console.log(allergens);
            }
          } else {
            setChildAlert(false);
            setProdPack(false);
          }
        }
      });
    }
  };

  const handleEdit = (toEdit) => {
    document.getElementById(toEdit).style.display = "flex";
  };

  const handleCatEdit = (id) => {
    console.log(id);
    categoryList.forEach((cat) => {
      if (id == cat.eposId) {
        product.categoryId = id;
        product.categoryName = cat.name;
        console.log(product);
      }
    });
  };

  //changes cost price
  const handleCostChange = (newCost) => {
    if (parseFloat(newCost) <= 0) {
      document.getElementById("modify_prod_btn").style.display = "none";
      setSeverity("warning");
      setMessage("Cost price cannot be less than zero");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(true);
        setSeverity("");
        setMessage("");
        document.getElementById("modify_prod_btn").style.display = "flex";
      }, 2500);
    } else {
      product.costPrice = newCost;
    }
  };

  //handles changing sale price
  const handleSalePrice = (newSale) => {
    if (parseFloat(newSale) <= 0) {
      document.getElementById("modify_prod_btn").style.display = "none";
      setSeverity("warning");
      setMessage("Sale price cannot be less than zero");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(true);
        setSeverity("");
        setMessage("");
        document.getElementById("modify_prod_btn").style.display = "flex";
      }, 2500);
    } else {
      product.salePrice = newSale;
    }
  };

  //deletes a child from the original
  const handleDeleteChild = (i) => {
    let child = children.filter((val, index) => index !== i);
    setChildren(child);
  };

  //handles deletions of a child when making a new master
  const handleDeleteNewMaster = (i) => {
    let child = addedChildren.filter((val, index) => index !== i);
    setAddedChildren(child);
  };

  //changes the quantity of a child
  const handleChildQty = (newAmount, index) => {
    if (parseInt(newAmount) <= 0) {
      document.getElementById("modify_prod_btn").style.display = "none";
      setSeverity("warning");
      setMessage(
        "Quantity cannot be zero, if you want to remove the Item press the deltion button"
      );
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(true);
        setSeverity("");
        setMessage("");
        document.getElementById("modify_prod_btn").style.display = "flex";
      }, 3000);
    } else {
      children[index].amount = newAmount;
    }
  };

  //changes the qty when makins a product a master product
  const handleNewChildQty = (amount, index) => {
    if (parseInt(amount) <= 0) {
      document.getElementById("modify_prod_btn").style.display = "none";
      setSeverity("warning");
      setMessage(
        "Quantity cannot be zero, if you want to remove the Item press the deltion button"
      );
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(true);
        setSeverity("");
        setMessage("");
        document.getElementById("modify_prod_btn").style.display = "flex";
      }, 3000);
    } else {
      addedChildren[index].amount = amount;
    }
  };

  //changes the pack quantity of a child
  const handleVolumeSale = (newVolume) => {
    if (parseInt(newVolume) <= 0) {
      document.getElementById("modify_prod_btn").style.display = "none";
      setSeverity("warning");
      setMessage(
        "Quantity cannot be zero, if you want to remove the Item press the deltion button"
      );
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(true);
        setSeverity("");
        setMessage("");
        document.getElementById("modify_prod_btn").style.display = "flex";
      }, 3000);
    } else {
      product.volumeOfSale = newVolume;
    }
  };

  //changes the ingredients
  const handleIngredients = (ingredients) => {
    product.ingredientDescription = ingredients;
  };

  //changes the description
  const handleDescription = (description) => {
    product.satoDesc = description;
  };

  //shows to add a new child to an item
  const handleShowChildAdd = () => {
    setShowAddChild(!showAddChild);
  };

  //search of product on the datalist
  const searchProduct = (item) => {
    if (item.length > 3) {
      ingCat.forEach((prod) => {
        if (prod.eposId == item) {
          setChildren([
            ...children,
            { name: prod.name, productCode: prod.eposId },
          ]);
        }
      });
    }
  };

  //if making the product a master product
  const handleMakeMaster = (ingredient) => {
    if (ingredient.length > 3) {
      ingCat.forEach((prod) => {
        if (prod.eposId == ingredient) {
          console.log(prod);
          setAddedChildren([
            ...addedChildren,
            { name: prod.name, productCode: ingredient },
          ]);
        }
      });
    }
  };

  //handles the allergens
  const handleModifyProdAllergen = (allergen) => {
    let checkProduct = document.getElementById(allergen);
    let productAllergens = [];

    if (product.allergens) {
      productAllergens = product.allergens;
      let findAllergen = productAllergens.findIndex(
        (a) => a.allergenId == allergen
      );
      console.log(findAllergen);
      if (findAllergen === -1) {
        productAllergens.push({ allergenId: parseInt(allergen) });
        product.allergens = productAllergens;
        rel();
      } else {
        productAllergens.splice(findAllergen, 1);
        product.allergens = productAllergens;
        if ((checkProduct.checked = true)) {
          checkProduct.checked = false;
        }
        rel();
      }
    } else {
      productAllergens.push({ allergenId: parseInt(allergen) });
      product.allergens = productAllergens;
      rel();
    }
  };

  //make product master
  const handleClickMaster = () => {
    setMakeMaster(!makeMaster);
  };

  //modifies the product
  const handleModify = async () => {
    document.getElementById("modify_prod_btn").style.display = "none";
    let data = {};
    let time = "";
    if (product.isParent) {
      let checkQuantities = true;
      if (children) {
        children.forEach((child) => {
          if (!child.quantity && child.quantity == "0") {
            setSeverity("warning");
            setMessage("Quantities cannot be zero");
            setShowAlert(true);
            checkQuantities = false;
            setTimeout(() => {
              setShowAlert(false);
              setSeverity("");
              setMessage("");
              document.getElementById("modify_prod_btn").style.display = "flex";
            }, 3000);
          }
        });
      }
      if ((checkQuantities = true)) {
        product.children = children;

        if (days != "0" && days !== "") {
          time += `${days}d`;
        }
        if (hours != "0" && hours !== "") {
          time += `${hours}H`;
        }
        if (minutes != "0" && minutes !== "") {
          time += `${minutes}M`;
        }

        product.useByOffSet = time;
        data = {
          product: product,
          user: user,
        };
        axios
          .post(`${url}/hoagies/products/edit`, data)
          .then((res) => {
            setShowTick(true);
            setChildren([])
            setTimeout(() => {
              setShowTick(false);
              setProduct({});
              setShowProduct(false);
              document.getElementById("modify_prod_btn").style.display = "flex";
            }, 2500);
            rel();
          })
          .catch((err) => {
            console.log(err);
            setSeverity("error");
            setMessage("Something went wrong, please try again");
            setShowAlert(true);
            setTimeout(() => {
              setShowAlert(false);
              setSeverity("");
              setMessage("");
              document.getElementById("modify_prod_btn").style.display = "flex";
            }, 3500);
          });
      }
    } else {
      let addedQtyCheck = true;
      if (addedChildren.length > 0) {
        addedChildren.forEach((child) => {
          if (!child.quantity && child.quantity == "0") {
            setSeverity("warning");
            setMessage("Quantities cannot be zero");
            setShowAlert(true);
            addedQtyCheck = false;
            setTimeout(() => {
              setShowAlert(false);
              setSeverity("");
              setMessage("");
              document.getElementById("modify_prod_btn").style.display = "flex";
            }, 3000);
          }
        });
      }
      if ((addedQtyCheck = true)) {
        product.children = addedChildren;
        product.isParent = true;

        if (days != "0" && days !== "") {
          time += `${days}d`;
        }
        if (hours != "0" && hours !== "") {
          time += `${hours}H`;
        }
        if (minutes != "0" && minutes !== "") {
          time += `${minutes}M`;
        }

        product.useByOffSet = time;
      }
      data = {
        product: product,
        user: user,
      };
      await axios
        .post(`${url}/hoagies/products/edit`, data)
        .then((res) => {
          setShowTick(true);
          setAddedChildren([])
          setTimeout(() => {
            setShowTick(false);
            setProduct({});
            setShowProduct(false);
            document.getElementById("modify_prod_btn").style.display = "flex";
            rel();
          }, 2500);
        })
        .catch((err) => {
          console.log(err);
          setSeverity("error");
          setMessage("Something went wrong, please try again");
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
            setSeverity("");
            setMessage("");
            setChildAlert(false);
            document.getElementById("modify_prod_btn").style.display = "flex";
          }, 3500);
        });
    }
  };

  return (
    <section className="add_prod_form">
      {childAlert ? (
        <Alert severity="warning" sx={{ margin: "1% 0 2% 12vw" }}>
          Altering this product will alter other products that contain it
        </Alert>
      ) : (
        <></>
      )}
      <label className="modify__select">Select a product to modify it</label>
      <select
        onChange={(e) => handleProduct(e.target.value)}
        className="modify__select"
      >
        <option>--Select One--</option>
        {productList.map(function (product, idx) {
          return <option>{product.name}</option>;
        })}
      </select>
      {showProduct ? (
        <div className="modify__product">
          <div className="modify_row">
            <p>
              Name: <span>{product.name}</span>
            </p>
          </div>
          <div className="modify_row">
            <p>
              Category: <span>{product.categoryName}</span>
            </p>
            <EditIcon color="primary" onClick={() => handleEdit("category")} />
            <select
              onChange={(e) => handleCatEdit(e.target.value)}
              id="category"
            >
              <option>--Select new category--</option>
              {categoryList.map(function (cat, idx) {
                return <option value={cat.eposId}>{cat.name}</option>;
              })}
            </select>
          </div>
          <div className="modify_row">
            <p>
              Cost: <span>{product.costPrice}</span>
            </p>
            <EditIcon color="primary" onClick={() => handleEdit("cost")} />
            <input
              type="number"
              pattern="[0-9]*"
              id="cost"
              onChange={(e) => handleCostChange(e.target.value)}
            />
          </div>
          <div className="modify_row">
            <p>
              Sale Price: <span>{product.salePrice}</span>
            </p>
            <EditIcon color="primary" onClick={() => handleEdit("price")} />
            <input
              type="number"
              pattern="[0-9]*"
              id="price"
              onChange={(e) => handleSalePrice(e.target.value)}
            />
          </div>
          {prodPack ? (
            <div className="modify_row">
              <p>
                Pack Size: <span>{product.volumeOfSale}</span>
              </p>
              <EditIcon
                color="primary"
                onClick={() => handleEdit("volumeOfSale")}
              />
              <input
                type="number"
                pattern="[0-9]*"
                id="volumeOfSale"
                onChange={(e) => handleVolumeSale(e.target.value)}
              />
            </div>
          ) : (
            <>
              <div className="modify_row">
                <p>
                  Ingredients: <span>{product.ingredientDescription}</span>
                </p>
                <EditIcon
                  color="primary"
                  onClick={() => handleEdit("ingredients")}
                />
                <textarea
                  cols={50}
                  rows={10}
                  id="ingredients"
                  onChange={(e) => handleIngredients(e.target.value)}
                  placeholder={product.ingredientDescription}
                />
              </div>
              <div className="modify_row">
                <p>
                  Description: <span>{product.satoDesc}</span>
                </p>
                <EditIcon
                  color="primary"
                  onClick={() => handleEdit("description")}
                />
                <textarea
                  cols={40}
                  rows={5}
                  id="description"
                  onChange={(e) => handleDescription(e.target.value)}
                  placeholder={product.satoDesc}
                />
              </div>

              <div className="modify_row">
                <p>
                  Use By: <span>{product.useByOffSet}</span>
                </p>
                <EditIcon color="primary" onClick={() => handleEdit("useBy")} />
                <div id="useBy">
                  <div className="useby__column">
                    <label>Days</label>
                    <select onChange={(e) => setDays(e.target.value)}>
                      <option>Select Days</option>
                      <option>0</option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                    </select>
                  </div>
                  <div className="useby__column">
                    <label>Hours</label>
                    <select onChange={(e) => setHours(e.target.value)}>
                      <option>Select Hours</option>
                      <option>0</option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                      <option>7</option>
                      <option>8</option>
                      <option>9</option>
                      <option>10</option>
                      <option>11</option>
                      <option>12</option>
                      <option>13</option>
                      <option>14</option>
                      <option>15</option>
                      <option>16</option>
                      <option>17</option>
                      <option>18</option>
                      <option>19</option>
                      <option>20</option>
                      <option>21</option>
                      <option>22</option>
                      <option>23</option>
                    </select>
                  </div>
                  <div className="useby__column">
                    <label>Minutes</label>
                    <select onChange={(e) => setMinutes(e.target.value)}>
                      <option>Select Minutes</option>
                      <option>0</option>
                      <option>5</option>
                      <option>10</option>
                      <option>15</option>
                      <option>20</option>
                      <option>25</option>
                      <option>30</option>
                      <option>35</option>
                      <option>40</option>
                      <option>45</option>
                      <option>50</option>
                      <option>55</option>
                    </select>
                  </div>
                </div>
              </div>
            </>
          )}

          {product.isParent === true ? (
            <>
              <table>
                <thead>
                  <th>Name</th>
                  <th>Quantity</th>
                  <th>Remove</th>
                </thead>
                <tbody>
                  {children ? (
                    children.map(function (child, idx) {
                      return (
                        <tr>
                          <td>{child.name}</td>
                          <td>
                            <input
                              value={child.amount}
                              type="number"
                              pattern="[0-9]*"
                              onChange={(e) =>
                                handleChildQty(e.target.value, idx)
                              }
                            />
                          </td>
                          <td>
                            <RemoveCircleIcon
                              color="primary"
                              onClick={() => handleDeleteChild(idx)}
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
              {!showAddChild ? (
                <Button
                  variant="outlined"
                  color="success"
                  sx={{ margin: "1% auto" }}
                  onClick={() => handleShowChildAdd()}
                >
                  Add Child
                </Button>
              ) : (
                <>
                  <Button
                    variant="outlined"
                    color="error"
                    sx={{ margin: "1% auto" }}
                    onClick={() => handleShowChildAdd()}
                  >
                    cancel
                  </Button>
                  <input
                    list="products"
                    onChange={(e) => searchProduct(e.target.value)}
                    placeholder="Select Product"
                    id="search_input"
                  />
                  <datalist id="products">
                    {ingCat.map(function (product, idx) {
                      return (
                        <option value={product.eposId}>{product.name}</option>
                      );
                    })}
                  </datalist>
                </>
              )}
            </>
          ) : (
            <>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClickMaster}
                sx={{ margin: "2% auto" }}
              >
                Make Master
              </Button>
              {makeMaster ? (
                <>
                  <input
                    list="products"
                    onChange={(e) => handleMakeMaster(e.target.value)}
                    placeholder="Select Product"
                    id="search_input"
                    style={{ marginBottom: "2%" }}
                  />
                  <datalist id="products">
                    {ingCat.map(function (product, idx) {
                      return (
                        <option value={product.eposId}>{product.name}</option>
                      );
                    })}
                  </datalist>
                  {addedChildren && addedChildren.length > 0 ? (
                    <table style={{ marginBottom: "2%" }}>
                      <thead>
                        <th>Name</th>
                        <th>Quantity</th>
                        <th>Remove</th>
                      </thead>
                      <tbody>
                        {addedChildren.map(function (child, idx) {
                          return (
                            <tr>
                              <td>{child.name}</td>
                              <td>
                                <input
                                  value={child.amount}
                                  type="number"
                                  pattern="[0-9]*"
                                  onChange={(e) =>
                                    handleNewChildQty(e.target.value, idx)
                                  }
                                />
                              </td>
                              <td>
                                <RemoveCircleIcon
                                  color="primary"
                                  onClick={() => handleDeleteNewMaster(idx)}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  ) : null}
                  <div className="modify_row">
                    <div className="row__column">
                      <label>Days</label>
                      <select onChange={(e) => setDays(e.target.value)}>
                        <option>Select Days</option>
                        <option>0</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                      </select>
                    </div>
                    <div className="row__column">
                      <label>Hours</label>
                      <select onChange={(e) => setHours(e.target.value)}>
                        <option>Select Hours</option>
                        <option>0</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        <option>6</option>
                        <option>7</option>
                        <option>8</option>
                        <option>9</option>
                        <option>10</option>
                        <option>11</option>
                        <option>12</option>
                        <option>13</option>
                        <option>14</option>
                        <option>15</option>
                        <option>16</option>
                        <option>17</option>
                        <option>18</option>
                        <option>19</option>
                        <option>20</option>
                        <option>21</option>
                        <option>22</option>
                        <option>23</option>
                      </select>
                    </div>
                    <div className="row__column">
                      <label>Minutes</label>
                      <select onChange={(e) => setMinutes(e.target.value)}>
                        <option>Select Minutes</option>
                        <option>0</option>
                        <option>5</option>
                        <option>10</option>
                        <option>15</option>
                        <option>20</option>
                        <option>25</option>
                        <option>30</option>
                        <option>35</option>
                        <option>40</option>
                        <option>45</option>
                        <option>50</option>
                        <option>55</option>
                      </select>
                    </div>
                  </div>
                </>
              ) : null}
            </>
          )}
          {product.isParent === true ? (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleOpen()}
            >
              See Allergens
            </Button>
          ) : (
            <div className="allergens__selection">
              {allergenList.map(function (allergen, idx) {
                let test = false;
                if (product.allergens) {
                  let childAllergens = product.allergens;
                  childAllergens.forEach((childAll) => {
                    if (childAll.allergenId == allergen.allergenId) {
                      test = true;
                    }
                  });
                }
                return (
                  <div className="allergen__row">
                    <input
                      type="checkbox"
                      value={allergen.allergenId}
                      name={allergen.name}
                      onChange={(e) => handleModifyProdAllergen(e.target.value)}
                      id={allergen.allergenId}
                      className="checkboxes_alergens"
                      checked={test}
                    />
                    <label for={allergen.name}>{allergen.name}</label>
                  </div>
                );
              })}
            </div>
          )}
          <Button
            variant="contained"
            color="success"
            id="modify_prod_btn"
            sx={{ marginTop: "2%" }}
            onClick={() => handleModify()}
          >
            Modify
          </Button>
          {showAlert ? (
            <Alert severity={severity} sx={{ marginTop: "2%" }}>
              {message}
            </Alert>
          ) : showTick ? (
            <Tick />
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <AllergenModal
          allergens={props.allergens}
          children={children}
          productAllergens={allergens}
          product={product}
        />
      </Modal>
    </section>
  );
}
