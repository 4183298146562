import Chart from "react-apexcharts";
import Modal from "@mui/material/Modal";
import axios from "axios";
import { useState } from "react";
import Waste from "../Waste";

//INTERFACES
interface Props {
    series: any[];
    xAxis: string[];
    waste?: boolean;
    start?: string;
    end?: string;
    selectedSites?: any;
    categoryIds?: number[];
    breakdownURL?: string;
    innerBreakdownURL?: string;
}

export function Pie({
    xAxis,
    series,
    waste,
    start,
    end,
    selectedSites,
    categoryIds,
    breakdownURL,
    innerBreakdownURL,
}: Props) {
    const url = process.env.REACT_APP_DEPPLOYED;
    // const url = "http://10.0.0.34:4022";
    const [wasteData, setWasteData] = useState([]);
    const [openWaste, setOpenWaste] = useState(false);
    const graphInfo: any = {
        series: series[0].data,
        options: {
            chart: {
                type: "pie",
                events: {
                    dataPointSelection: (
                        event: any,
                        chartContext: any,
                        config: any
                    ) => {
                        if (waste && breakdownURL) {
                            wasteBreakdown(
                                config.w.config.labels[config.dataPointIndex]
                            );
                        }
                    },
                },
            },
            labels: xAxis,
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: "bottom",
                        },
                    },
                },
            ],
            colors: [
                "#1abc9c",
                "#2ecc71",
                "#3498db",
                "#9b59b6",
                "#34495e",
                "#16a085",
                "#27ae60",
                "#2980b9",
                "#8e44ad",
                "#2c3e50",
                "#f1c40f",
                "#e67e22",
                "#e74c3c",
                "#ecf0f1",
                "#95a5a6",
                "#f39c12",
                "#d35400",
                "#c0392b",
                "#bdc3c7",
                "#7f8c8d",
            ],
        },
    };

    //Opens the waste modal showing breakdown of chosen category
    const wasteBreakdown = async (cat: string) => {
        let foundIndex = xAxis.indexOf(cat);
        let catId = categoryIds && categoryIds[foundIndex];

        let data = {
            siteId: selectedSites,
            start: start,
            end: end,
            categoryId: catId,
        };

        await axios
            .post(`${url}/dashboard/nitro/${breakdownURL}`, data)
            .then((res) => {
                setWasteData(res.data);
            })
            .catch((err) => {
                console.log(err);
            });

        setOpenWaste(true);
    };

    return (
        <>
            <Chart
                options={graphInfo.options}
                series={graphInfo.series}
                type="pie"
                height={waste ? "400" : "200"}
            />
            <Modal open={openWaste} onClose={() => setOpenWaste(false)}>
                <Waste wasteData={wasteData} />
            </Modal>
        </>
    );
}
