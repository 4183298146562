import { useState, useEffect } from "react";
//STYLES
import "./overview.scss";
// UTILS
import { getSites } from "../utils/Api.js";
//PACKAGES
import axios from "axios";
import moment from "moment";
//COMPONENTS
import Loader from "../animations/Loader.js";
import { Headline } from "./widgets/Headline.tsx";
//MUI
import { Button } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import SquareIcon from "@mui/icons-material/Square";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import Card from "@mui/material/Card";

//INTERFACES
interface Site {
    name: string;
    epos: number;
    companyName: string;
}

export default function Overview() {
    //MAIN STATE
    const url = process.env.REACT_APP_DEPPLOYED;
    // const url = "http://10.0.0.34:4022";
    const [dashboardData, setDashboardData] = useState([]);
    const [visible, setVisible] = useState(false);
    //SITES
    const [sites, setSites] = useState<Site[]>([]);
    const [fixedSites, setFixedSites] = useState<string[]>([]);
    const [fixedSiteIds, setFixedSiteIds] = useState<number[]>([]);
    const [selectedSites, setSelectedSites] = useState<number[]>(() => []);
    const [showCurrent, setShowCurrent] = useState(false);
    //TIMES
    const [fixedTime, setFixedTime] = useState("weekly");
    const [fixedStart, setFixedStart] = useState("");
    const [fixedEnd, setFixedEnd] = useState("");
    const [overallFixedStart, setOverallFixedStart] = useState(
        moment().subtract(1, "week").format("YYYY-MM-DD")
    );
    const [overallFixedEnd, setOverallFixedEnd] = useState(
        moment().format("YYYY-MM-DD")
    );
    const [selectedRange, setSelectedRange] = useState("weekly");
    const [startRange, setStartRange] = useState("");
    const [endRange, setEndRange] = useState("");
    const [previousStart, setPreviousStart] = useState("");
    const [previousEnd, setPreviousEnd] = useState("");
    const [timeRanges, setTimeRanges] = useState<string[]>([
        "weekly",
        "monthly",
        "annually",
        "range",
    ]);
    //LOADING
    const [loader, setLoader] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);
    const [disableSearch, setDisableSearch] = useState(false);

    //BRING IN SITES & RUN ALL SITES ON DASHBOARD
    useEffect(() => {
        const getAllSites = async () => {
            const allSites = await getSites();
            setSites(allSites);
            let allS: number[] = [];
            let allSNames: string[] = [];
            for (let i = 0; i < allSites.length; i++) {
                if (allSites[i].epos != 24195) {
                    allS.push(allSites[i].epos);
                    allSNames.push(allSites[i].name.slice(0, 4));
                }
            }
            setFixedSiteIds(allS);
            setFixedSites(allSNames);
            setSelectedSites(allS);
            setLoader(true);
            setDisableBtn(true);
            getDashBoardData(allS, allSNames);
        };
        getAllSites();
    }, []);

    //VALIDATION FOR NO RANGE TIME SET BY USER
    useEffect(() => {
        if (selectedRange === "range") {
            if (startRange !== "" && endRange !== "") {
                setDisableBtn(false);
            } else {
                setDisableBtn(true);
            }
        }
    }, [startRange, endRange]);

    //ON SITE MULTI-TOGGLE WILL ADD SITES TO ARRAY
    const handleSiteSelect = (
        event: React.MouseEvent<HTMLElement>,
        newFormats: number[]
    ) => {
        setVisible(false);
        setDisableBtn(false);
        if (
            newFormats.length === 0 ||
            (selectedRange === "range" &&
                (startRange === "" || endRange === ""))
        ) {
            setDisableBtn(true);
        }
        setSelectedSites(newFormats);
    };

    //ON TIME EXCLUSIVE-TOGGLE WILL SET TIMEFRAME
    const handleTimeFrameSelect = (
        event: React.MouseEvent<HTMLElement>,
        newFormats: string
    ) => {
        setDisableBtn(false);
        if (newFormats != null) {
            if (newFormats === "range" || selectedSites.length === 0) {
                setDisableBtn(true);
            }
            setStartRange("");
            setEndRange("");

            setSelectedRange(newFormats);
        }
    };

    //LOAD DASHBOARD WITH SELECTED TIME AND SITE PARAMETERS
    const getDashBoardData = async (s: number[], sn: string[] | null) => {
        //Stop interactions during page load
        setVisible(false);
        setDisableSearch(true);
        setShowCurrent(false);
        setLoader(true);
        setDisableBtn(true);

        //Sets new fixed sites
        if (sn === null) {
            let siteNames: string[] = [];
            for (let i = 0; i < sites.length; i++) {
                for (let j = 0; j < s.length; j++) {
                    if (sites[i].epos === s[j]) {
                        siteNames.push(sites[i].name.slice(0, 4));
                    }
                }
            }

            setFixedSites(siteNames);
        } else {
            setFixedSites(sn);
            setFixedSiteIds(s);
        }

        //Set Start & End Times
        let startTime = "";
        let endTime = "";
        switch (selectedRange) {
            case "weekly":
                startTime = moment().subtract(1, "week").format("YYYY-MM-DD");
                endTime = moment().format("YYYY-MM-DD");
                setOverallFixedStart(startTime);
                setOverallFixedEnd(endTime);
                break;
            case "monthly":
                startTime = moment().subtract(1, "month").format("YYYY-MM-DD");
                endTime = moment().format("YYYY-MM-DD");
                setOverallFixedStart(startTime);
                setOverallFixedEnd(endTime);
                break;
            case "annually":
                startTime = moment().subtract(1, "year").format("YYYY-MM-DD");
                endTime = moment().format("YYYY-MM-DD");
                setOverallFixedStart(startTime);
                setOverallFixedEnd(endTime);
                break;
            case "range":
                startTime = moment(startRange).format("YYYY-MM-DD");
                endTime = moment(endRange).format("YYYY-MM-DD");
                setOverallFixedStart(startTime);
                setOverallFixedEnd(endTime);
                break;
            default:
                startTime = moment().subtract(1, "week").format("YYYY-MM-DD");
                endTime = moment().format("YYYY-MM-DD");
                setOverallFixedStart(startTime);
                setOverallFixedEnd(endTime);
        }

        //Req body
        let data = {
            start: startTime,
            end: endTime,
            sites: s,
        };

        //Dashboard Info Request
        axios
            .post(`${url}/dashboard/nitro`, data)
            .then((res) => {
                //Sets the previous Time range for current selection info
                let dateDiff = moment(endTime, "YYYY-MM-DD").diff(
                    startTime,
                    "days"
                );
                dateDiff += 1;
                const previousStartDate = moment(startTime)
                    .subtract(dateDiff, "days")
                    .format("YYYY-MM-DD");
                const previousEndDate = moment(endTime)
                    .subtract(dateDiff, "days")
                    .format("YYYY-MM-DD");

                //Set data for dashboard
                setDashboardData(res.data);
                setLoader(false);
                setDisableBtn(false);
                setDisableSearch(false);
                setPreviousStart(previousStartDate);
                setPreviousEnd(previousEndDate);
                setFixedTime(selectedRange);
                setFixedStart(startRange);
                setFixedEnd(endRange);
                setShowCurrent(true);
                setVisible(true);
            })
            .catch((err) => {
                console.log(err);
                setLoader(false);
                setDisableBtn(false);
                setDisableSearch(false);
            });
    };

    return (
        <Box sx={{ flexGrow: 1, width: "80%" }}>
            <Grid container spacing={2} className="overview">
                <Button
                    className="load_btn"
                    variant="contained"
                    onClick={() => {
                        getDashBoardData(selectedSites, null);
                    }}
                    disabled={disableBtn}
                    color="success"
                    endIcon={<FindInPageIcon />}
                >
                    Lookup
                </Button>
                <Grid xs={12} sx={{ display: "flex" }} className="date_range">
                    <ToggleButtonGroup
                        value={selectedRange}
                        onChange={handleTimeFrameSelect}
                        exclusive
                        sx={{
                            marginBottom: "5px",
                            marginRight: "10px",
                        }}
                        color="primary"
                        disabled={disableSearch}
                    >
                        {timeRanges.map((range, i) => {
                            return (
                                <ToggleButton key={i} value={range}>
                                    {range}
                                </ToggleButton>
                            );
                        })}
                    </ToggleButtonGroup>
                    {selectedRange === "range" ? (
                        <section className="range_inputs">
                            <input
                                type="date"
                                value={startRange}
                                onChange={(e) => {
                                    setStartRange(e.target.value);
                                }}
                                max={
                                    endRange
                                        ? endRange
                                        : moment().format("YYYY-MM-DD")
                                }
                                style={
                                    startRange === ""
                                        ? { border: "2px solid red" }
                                        : { border: "2px solid green" }
                                }
                            />
                            <input
                                type="date"
                                value={endRange}
                                onChange={(e) => {
                                    setEndRange(e.target.value);
                                }}
                                min={startRange}
                                max={moment().format("YYYY-MM-DD")}
                                style={
                                    endRange === ""
                                        ? { border: "2px solid red" }
                                        : { border: "2px solid green" }
                                }
                            />
                        </section>
                    ) : null}
                </Grid>
                <Grid xs={12} className="site_select">
                    <ToggleButtonGroup
                        value={selectedSites}
                        onChange={handleSiteSelect}
                        color="primary"
                        disabled={disableSearch}
                    >
                        {sites &&
                            sites.map((site, i) => {
                                //Removing Head Office
                                if (site.epos !== 24195) {
                                    return (
                                        <ToggleButton key={i} value={site.epos}>
                                            {site.name.slice(0, 4)}
                                        </ToggleButton>
                                    );
                                }
                            })}
                    </ToggleButtonGroup>
                    {showCurrent ? (
                        visible ? (
                            <Card className="currently_selected">
                                <p className="cs">Current selection</p>
                                <div className="current_time">
                                    <SquareIcon
                                        sx={{ color: "rgb(0,227,150,0.85)" }}
                                    />
                                    {fixedTime === "weekly" ? (
                                        <p>
                                            {moment()
                                                .subtract(1, "week")
                                                .format("YYYY-MM-DD")}{" "}
                                            to {moment().format("YYYY-MM-DD")}
                                        </p>
                                    ) : fixedTime === "monthly" ? (
                                        <p>
                                            {moment()
                                                .subtract(1, "month")
                                                .format("YYYY-MM-DD")}{" "}
                                            to {moment().format("YYYY-MM-DD")}
                                        </p>
                                    ) : fixedTime === "annually" ? (
                                        <p>
                                            {moment()
                                                .subtract(1, "year")
                                                .format("YYYY-MM-DD")}{" "}
                                            to {moment().format("YYYY-MM-DD")}
                                        </p>
                                    ) : fixedTime === "range" ? (
                                        <p>
                                            {moment(fixedStart).format(
                                                "YYYY-MM-DD"
                                            )}{" "}
                                            to{" "}
                                            {moment(fixedEnd).format(
                                                "YYYY-MM-DD"
                                            )}
                                        </p>
                                    ) : null}
                                </div>
                                <div className="previous_time">
                                    <SquareIcon
                                        sx={{
                                            color: "rgba(0, 143, 251, 0.85)",
                                        }}
                                    />

                                    <p>
                                        {previousStart} to {previousEnd}
                                    </p>
                                </div>
                                <div className="current_sites">
                                    {fixedSites.map((site, i) => {
                                        return (
                                            <p key={i}>
                                                {site.toUpperCase()}&nbsp;
                                            </p>
                                        );
                                    })}
                                </div>
                            </Card>
                        ) : null
                    ) : null}
                </Grid>

                {!loader ? (
                    visible ? (
                        dashboardData &&
                        dashboardData.map((d: any, i: number) => {
                            return (
                                <Grid
                                    sx={{ padding: "5px" }}
                                    key={i}
                                    xs={d.xs}
                                    md={d.md}
                                >
                                    <Headline
                                        graph={d.chart}
                                        name={d.name}
                                        xAxis={d.x}
                                        series={d.series}
                                        start={overallFixedStart}
                                        end={overallFixedEnd}
                                        selectedSites={selectedSites}
                                        categoryIds={d.categoryIds}
                                        money={d.money}
                                        waste={d.waste}
                                        breakdownURL={d.breakdown}
                                    />
                                </Grid>
                            );
                        })
                    ) : null
                ) : (
                    <Loader />
                )}
            </Grid>
        </Box>
    );
}
