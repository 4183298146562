import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useState, useEffect, useContext } from "react";
import Button from "@mui/material/Button";

export default function HeadingDetails({
    name,
    deals,
    handleDealSelected,
    complete
}) {
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        setExpanded(false);
    }, []);

    return (

        <Accordion
            expanded={expanded}
            id={name}
        >
            <AccordionSummary
                defaultExpanded={false}
                expandIcon={<ExpandMoreIcon />}
                className={`accordion_heading_summary ${complete == true ? "heading_complete" : null}`}
                onClick={() => setExpanded(!expanded)}
                style={{
                    textAlign: "center",
                }}
            >
                {name} {" - "} {`Deals:${deals.length}`}
            </AccordionSummary>
            {deals.map(function (d, i) {

                const confirmed = d.confirmedPromotionId != null ? true : false;
                const eposUrl = `https://www.eposnowhq.com/Pages/BackOffice/PromotionDetails.aspx?PromotionID=${d.confirmedPromotionId}`

                return (
                    <div
                        id={`accordion_heading_details-${d.dealId}`}
                        className={`accordion_heading_details ${confirmed ? "deal_confirmed" : ""}`}
                        onClick={() => handleDealSelected(d.dealId)}
                        style={{
                            backgroundColor: confirmed ? "rgba(50, 168, 82, 0.25)" : "white"
                        }}
                    >
                        <div className="accordion_heading_details_heading">
                            <p>PRODS :{d.DealProducts.length}</p>
                            <p><b>{d.consumerDeal}</b></p>

                        </div>
                        {confirmed ? (
                            <Button
                                variant="contained"
                                color="success"
                                href={eposUrl}
                                target="_blank"
                                rel="noreferrer"
                            >
                                EPOS PROMOTION
                            </Button>
                        ) : (
                            null
                        )}
                    </div>
                )
            })}

            <Button
                variant="contained"
                color="success"
                onClick={() => setExpanded(!expanded)}
            >
                Close
            </Button>


        </Accordion>
    )


}