import "./claims.scss";
import axios from "axios";
import { useState, useEffect } from "react";
import LoginTest from "../../LoginTest";
import { useNavigate } from "react-router-dom";

export default function Claims() {
  const [role, setRole] = useState("");
  const navigate = useNavigate();
  const [claims, setClaims] = useState([])
  const [sites, setSites] = useState([])
  const [showLoader, setShowLoader] = useState(false)

  useEffect(async () => {
    let userD = localStorage.getItem("userDetails");
    let user = JSON.parse(userD);
    setRole(user.user.role);
    setShowLoader(true)
    var finalSites = [];
    await axios.get("https://nitrous.rt-itservices.co.uk/sites/data").then((res) => {
      finalSites = res.data;
    }).catch((err) => {
      console.log(err);
    });

    if (!user.user.sites.includes("HQ")) {
      for (let s = 0; s < finalSites.length; s++) {
        if (!user.user.sites.includes(finalSites[s].name)) {
          finalSites.splice(s, 1)
        }
      }
    }
    setSites(finalSites)
    if (finalSites.length == 1) {
      await getActiveClaims(finalSites[0].epos)
    }
    setShowLoader(false)
  }, []);

  const getActiveClaims = async (siteId) => {
    await axios
      .get(`https://nitropos-backend.nitrosolutions.co.uk/claims/active/all/${siteId}`)
      .then((res) => {
        setClaims(res.data)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const seeClaim = async (data) => {
    localStorage.setItem("claimData", JSON.stringify(data));
    navigate(`/managerZone/claims/individual`);
  }

  const getClaimsAndProducts = async (siteId) => {
    if (siteId == "" || siteId == "") {
      alert("PLEASE SELECT A SITE!")
    } else {
      setShowLoader(true)
      await getActiveClaims(siteId)
    }
  }

  return (
    <>
      {role === "Manager" ||
        role === "Store Manager" ||
        role === "Developer" ? (
        <main className="claims__main">
          {sites.length > 1 ?
            <>
              <select onChange={(e) => { getClaimsAndProducts(e.target.value) }}>
                <option>--- SELECT SITE ---</option>
                {sites.map(function (d, i) {
                  return (
                    <option value={d.epos}>{d.name}</option>
                  )
                })}
              </select>
            </>
            : <></>}
          <section className="claims" style={{ display: "flex", justifyContent: "space-evenly" }}>
            <div>
              <h1>Claims awaiting to be processed</h1>
              <table>
                <thead>
                  <th>ID</th>
                  <th>Date</th>
                  <th>Supplier</th>
                  <th>Amount of Items</th>
                  <th>Source</th>
                </thead>
                <tbody>
                  {claims.length > 0 ? (
                    claims.map(function (d, i) {
                      return (
                        <tr onClick={() => { seeClaim(d)}}>
                          <td>{d.claimId}</td>
                          <td>{new Date(d.createdDate).toLocaleDateString("en-GB")}</td>
                          <td>{d.name}</td>
                          <td>{d.totalProducts}</td>
                          <td>{d.sourceType}</td>
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan={5}>No Claims To Process</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>



          </section>
        </main>
      ) : (
        <LoginTest />
      )}
    </>
  );
}
