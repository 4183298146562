import './invoices.scss';
import { useState, useEffect } from 'react';
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Tick from '../animations/Tick';
import SendIcon from "@mui/icons-material/Send";
import axios from 'axios';


export default function Clyde() {
    const [user, setUser] = useState("")
    const [image, setImage] = useState("")
    const [date, setDate] = useState("")
    const [message, setMessage] = useState("")
    const [errorAlert, setErrorAlert] = useState(false)
    const [invoice, setInvoice] = useState("")
    const [showTick, setShowTick] = useState(false);
    const [total, setTotal] = useState(0)
    const [imgLabel, setImglabel] = useState("Please take a photo of the invoice")
    const [images, setImages] = useState([])


    useEffect(() => {
        let user = sessionStorage.getItem("user");
        setUser(user);
    }, [])

    const handleImage = (img) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(img);
        fileReader.onload = () => {
            //setImage(fileReader.result);
            setImages(oldArray => [...oldArray, fileReader.result]);
            setImglabel("Add Another Image if Required.")
        };
    };

    const submit = () => {
        if (invoice === "" || date === "" || images.length == 0 || total === 0) {
            setMessage("All fields must be filled in")
            setErrorAlert(true)
            setTimeout(() => {
                setErrorAlert(false)
            }, 3000)
        } else {
            document.getElementById("clyde_btn").style.display = "none";
            let data = {
                staffName: user,
                invoiceNumber: invoice,
                invoiceDate: date,
                total: total,
                images: images,
            }
            axios.post("https://nitropos-backend.nitrosolutions.co.uk/edn/clyde/submit", data)
                .then((res) => {
                    setShowTick(true)
                    setTimeout(() => {
                        setShowTick(false)
                        document.getElementById("clyde_btn").style.display = "inline-block";
                        setDate("")
                        setInvoice("")
                        setImage("")
                        setImages([])
                        setTotal(0)
                        setImglabel("Please take a photo of the invoice")
                    }, 2500)
                }).catch((err) => {
                    document.getElementById("clyde_btn").style.display = "inline-block";
                    setMessage("Something went wrong, please try again")
                    setErrorAlert(true)
                    setTimeout(() => {
                        setErrorAlert(false)
                    }, 3000)
                })
        }
    }


    return (
        <main className="clyde__po">
            <h1>Add a new clyde invoice</h1>
            <section className="clyde__form">
                <label>Date:</label>
                <input type="date" value={date} onChange={(e) => setDate(e.target.value)} />
                <label>Invoice Number:</label>
                <input type="text" value={invoice} onChange={(e) => setInvoice(e.target.value)} />
                <label>Invoice Total:</label>
                <input type="number" value={total} onChange={(e) => setTotal(e.target.value)} />
                <label>{imgLabel}</label>
                <input
                    type="file"
                    accepts="image/*"
                    value={image}
                    onChange={(e) => {
                        handleImage(e.target.files[0]);
                    }}
                />
                {/* {image ? <img src={image} alt="Invoice Image" /> : <></>} */}
                {images.map(function (d, i) {
                    return (
                        <img src={d} alt="Invoice Image" width="500px" />
                    )
                })}
                {errorAlert ? <Alert severity="error">{message}</Alert> : <></>}
                <Button
                    variant="outlined"
                    color="success"
                    endIcon={<SendIcon sx={{ marginTop: "0" }} />}
                    id="clyde_btn"
                    onClick={() => submit()}
                >
                    Send
                </Button>
                {showTick ? <Tick /> : <></>}
            </section>
        </main>
    )

}