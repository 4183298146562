import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "20vw",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
  maxHeight: "70vh",
  overflowY: "scroll",
  "@media(max-width: 690px)": {
    width: "90vw",
    maxHeight: "90vh",
  },
};
function ConfirmPrintRotaModel({ setShowPrintRota, url }) {

  const [link,setLink] = useState("")

  useEffect(()=>{
    let printTxt = document.URL + "/" + url;
    console.log(document.URL)
    if(!document.URL.includes("staff-management")){
      printTxt = document.URL + "/staff-management/" + url
    }
    setLink(printTxt)
  },[url])

  return (
    <>
      <Box sx={style} className="edit_shift_modal">
        <Typography id="modal-modal-title" variant="h4" component="h2">
          Print Rota
        </Typography>
        <p>This rota is not published yet. Are you sure you want to print?</p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "3%",
          }}
        >
          <Button
            variant="contained"
            color="error"
            onClick={() => setShowPrintRota(false)}
            disable={true}
          >
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            id="addNote-btn"
            onClick={() => {
              window.open(
                link,
                "_blank",
                "rel=noopener noreferrer"
              );
              setShowPrintRota(false);
            }}
          >
            Print
          </Button>
        </div>
      </Box>
    </>
  );
}

export default ConfirmPrintRotaModel;
