// If you are in doubt of what level of protected route you should use, please ask.

import "./App.css";
import React, { useState, useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  Link,
  useNavigate,
} from "react-router-dom";
import axios from "axios";
import Home from "./components/HomePage";
import StoreZone from "./components/StoreZone/StoreZone";
import RaceTrack from "./components/StoreZone/RaceTrack";
import Vaped4U from "./components/StoreZone/Vaped4U";
import Tubbees from "./components/StoreZone/Tubbees";
import SnusDaddy from "./components/StoreZone/SnusDaddy";
import Order from "./components/StoreZone/invoices/Order";
import Invoices from "./components/StoreZone/invoices/Invoices";
import UserContext from "./components/context/userContext";
import LoginTest from "./components/LoginTest";
import FuelHome from "./components/Fuel/FuelHome";
import Bearsden from "./components/Fuel/bearsden/Bearsden";
import PasswordReset from "./components/PasswordReset";
import ManagerHome from "./components/managerZone/ManagerHome";
import History from "./components/StoreZone/History";
import RepairSystem from "./components/StoreZone/storeManagement/repair/RepairSystem";
import AddEposItem from "./components/managerZone/epos/AddEposItem";
import EposProducts from "./components/managerZone/epos/products/EposProducts";
import Duplicate from "./components/managerZone/epos/duplicates/Duplicate";
import ReportsHome from "./components/reports/ReportsHome";
import DiscrepancyHome from "./components/discrepancies/DiscrepancyHome";
import DevHome from "./components/devZone/DevHome";
import FailedTransactions from "./components/devZone/FailedTransaction";
import ITsystem from "./components/StoreZone/storeManagement/IT_support/ITsystem";
import NotFound from "./components/noFound/NotFound";
import ManualInvoice from "./components/StoreZone/ManualInvoice";
import InvoicesHome from "./components/invoices/InvoicesHome";
import WastageReportHome from "./components/managerZone/wastageReports/WastageReportHome";
import EodHome from "./components/managerZone/eod/EodHome";
import Images from "./components/managerZone/eod/PayoutImages";
import V4uDisposables from "./components/warehouse/v4u/V4uDisposables";
import TikTokForm from "./components/warehouse/tiktok/TiktokContainer";
import Picklist from "./components/warehouse/v4u/Picklist";
import ReviewOrder from "./components/warehouse/v4u/ReviewOrder";
import Claims from "./components/StoreZone/Claims";
import Claim from "./components/StoreZone/Claim";
import ManagerClaimsHome from "./components/managerZone/claims/ClaimHome";
import WastageForm from "./components/StoreZone/productManagement/WastageForm";
import ManagerClaims from "./components/managerZone/claims/Claims";
import EodDash from "./components/devZone/dashboard/EodDash";
import IndvClaim from "./components/managerZone/claims/IndvClaim";
import BakeCheck from "./components/bakes/BakeCheck";
import UsersHome from "./components/devZone/promos/users/UsersHome";
import Hr from "./components/HR/Hr";
import PickOrder from "./components/warehouse/v4u/PickOrder";
import FtgHome from "./components/FTG/FtgHome";
import Sato from "./components/FTG/Sato";
import HolidaysHome from "./components/holidays/HolidaysHome";
import ProductTags from "./components/StoreZone/productManagement/ProductTags";
import Gs1Barcodes from "./components/devZone/Gs1Barcodes";
import HoagiesToEpos from "./components/StoreZone/productManagement/hoagies/HoagiesToEpos";
import HoagiesStockHome from "./components/managerZone/stockTake/HoagiesStockHome";
// import BankAccountChanges from "./components/accounts/BankAccountChange";
import ProductDrawer from "./components/StoreZone/productManagement/ProductDrawer";
import VapePromotion from "./components/StoreZone/V4U_Promo/VapePromotion";
import VapePromoDash from "./components/StoreZone/V4U_Promo/VapePromoDash";
import StaffDrawer from "./components/StoreZone/staffManagement/staffDrawer";
import StoreDrawer from "./components/StoreZone/storeManagement/StoreDrawer";
import CCTVHome from "./components/devZone/cctv/CCTVHome";
import Reporting from "./components/FTG/reports/Reporting";
import MainDashHome from "./components/main_dashboard/MainDashHome";
import { AdminProtected } from "./components/protected-routes/Admin";
import { DevProtected } from "./components/protected-routes/Devs";
import NotAllowed from "./components/protected-routes/NotAllowed";
import { WarehouseProtect } from "./components/protected-routes/WarehouseProtect";
import { FtgProtect } from "./components/protected-routes/FtgProtect";
import { HoProtect } from "./components/protected-routes/HoProtect";
import { HrProtect } from "./components/protected-routes/HrProtect";
import { HolProttect } from "./components/protected-routes/HolProttect";
import { StoreProtect } from "./components/protected-routes/StoreProtect";
import { StoreManagerProtect } from "./components/protected-routes/StoreManagerProtect";
import { WarehouseAdmin } from "./components/protected-routes/WareHouseAdmin";
import SubwayHome from "./components/FTG/subway/SubwayHome";
import InvoiceCheck from "./components/warehouse/invoice/InvoiceCheck";
import RunDispos from "./components/warehouse/invoice/RunDispos";
import EposUserHome from "./components/admin/eposUsers/EposUserHome";
import { CctvProtect } from "./components/protected-routes/Cctv";
import CctvStatus from "./components/admin/cctv/CctvStatus";
import AutoOrder from "./components/warehouse/liquids/AutoOrder";
import AutoCollection from "./components/admin/wastage/AutoCollection";
import CreateAlert from "./components/admin/storeAlerts/CreateAlert";
import PackersLogin from "./components/warehouse/packers/PackersLogin";
import NoCost from "./components/managerZone/epos/NoCost";
import NoSupplier from "./components/managerZone/epos/NoSupplier";
import PosExplorerHome from "./components/managerZone/posExplorer/PosExplorerHome";
import Footer from "./components/Footer";
import PromotionsHome from "./components/devZone/BookerPromotions/PromotionsHome";
import Promotions from "./components/devZone/BookerPromotions/Promotions";
import ManualOrder from "./components/warehouse/invoice/ManualOrder";
import PurchaseOrderAdmin from "./components/purchase_orders/PurchaseOrderAdmin";
import PurchaseOrderApproval from "./components/purchase_orders/PurchaseOrderApproval";
import PrintRotaHome from "./components/holidays/rotas/print_rotas/PrintRotaHome";
import RefundsHome from "./components/admin/refunds/RefundsHome";
import ReviewRota from "./components/holidays/rotas/review_rotas/review_rota";
import { SeniorManagementProtect } from "./components/protected-routes/SeniorManagementProtect";
function App() {
  let userD = localStorage.getItem("userDetails");
  let Usertoken = localStorage.getItem("auth-token");
  const [userData, setUserData] = useState({
    token: Usertoken && Usertoken,
    user: userD && JSON.parse(userD).user,
  });
  const [token, setToken] = useState("");
  const [alarmCheck, setAlarmCheck] = useState([]);
  const [checkScripts, setCheckScripts] = useState(0);

  const handleServerError = () => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    fetch("https://nitropos-backend.nitrosolutions.co.uk/fuel/error", {
      method: "GET",
      headers,
    })
      .then((res) => res.json())
      .then((data) => console.log(data))
      .catch((error) => {
        console.log(error);
        throw "Custom error triggered, server error";
      });
  };
  const checkLoggedIn = async () => {
    let token = localStorage.getItem("auth-token");
    if (token === null) {
      localStorage.setItem("auth-token", "");
      token = "";
    } else {
      var tokenRes = {};
      await axios
        .post(
          "https://nitropos-backend.nitrosolutions.co.uk/users/tokenValid",
          null,
          { headers: { "x-auth-token": token } }
        )
        .then((res) => {
          tokenRes.data = res.data;
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
      if (tokenRes.data) {
        const userRes = await axios.get(
          "https://nitropos-backend.nitrosolutions.co.uk/users/",
          {
            headers: { "x-auth-token": token },
          }
        );
        setUserData({
          token,
          user: userRes.data,
        });
      }
    }
  };
  useEffect(() => {
    // checkLoggedIn();
    // await axios
    //   .get("https://nitropos-backend.nitrosolutions.co.uk/booker/alarmCheck")
    //   .then((res) => {
    //     // console.log(res.data);
    //     setAlarmCheck(res.data);
    //     setCheckScripts(res.data.length);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     handleServerError();
    //   });
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <UserContext.Provider value={{ userData, setUserData }}>
          <Routes>
            {/* NON PROTECTED ROUTES */}
            <Route path="/" element={<LoginTest />} />
            <Route path="*" element={<NotFound />} />
            <Route path="not-allowed" element={<NotAllowed />} />
            <Route path="reset" element={<PasswordReset />} />
            <Route path="eod-dashboard-ho" element={<EodDash />} />
            <Route path="managerZone/eod/images" element={<Images />} />
            {/* END OF NON PROTECTED ROUTES */}

            {/* ADMIN ZONE */}
            <Route
              exact
              path="home"
              element={
                <HoProtect>
                  <Home number={checkScripts} />
                </HoProtect>
              }
            />
            <Route
              path="reports"
              element={
                <HoProtect>
                  <ReportsHome />
                </HoProtect>
              }
            />
            <Route
              path="discrepancy-reports"
              element={
                <HoProtect>
                  <DiscrepancyHome />
                </HoProtect>
              }
            />
            {/* <Route
                            path="bank-accounts"
                            element={
                                <HoProtect>
                                    <BankAccountChanges />
                                </HoProtect>
                            }
                        /> */}
            <Route
              path="fuel"
              element={
                <HoProtect>
                  <FuelHome />
                </HoProtect>
              }
            />
            <Route
              path="create-alerts"
              element={
                <HoProtect>
                  <CreateAlert />
                </HoProtect>
              }
            />

            <Route
              path="hr-area"
              element={
                <HrProtect>
                  <Hr />
                </HrProtect>
              }
            />
            <Route
              path="staff-management"
              element={
                <HolProttect>
                  <HolidaysHome />
                </HolProttect>
              }
            />
            <Route
              path="staff-management/print-rota/:rotaId/:siteId/:weekStart"
              element={
                <HolProttect>
                  <PrintRotaHome />
                </HolProttect>
              }
            />

            <Route
              path="staff-management/review-rota/:from?/:to?"
              element={
                <SeniorManagementProtect>
                  <ReviewRota />
                </SeniorManagementProtect>
              }
            />

            {/* <Route path="/fuel/bp/bearsden" element={<Bearsden />} /> not currently in use*/}
            <Route path="purchase-orders" element={<InvoicesHome />} />
            <Route
              path="business-dashboard"
              element={
                <AdminProtected>
                  <MainDashHome />
                </AdminProtected>
              }
            />

            <Route
              path="purchase-order-admin"
              element={
                <WarehouseAdmin>
                  <PurchaseOrderAdmin />
                </WarehouseAdmin>
              }
            />
            <Route
              path="purchase-order-admin/:id"
              element={
                <WarehouseAdmin>
                  <PurchaseOrderApproval />
                </WarehouseAdmin>
              }
            />
            <Route
              path="epos-users"
              element={
                <AdminProtected>
                  <EposUserHome />
                </AdminProtected>
              }
            />
            <Route
              path="refund-reports"
              element={
                <AdminProtected>
                  <RefundsHome />
                </AdminProtected>
              }
            />
            <Route
              path="cctv-status"
              element={
                <CctvProtect>
                  <CctvStatus />
                </CctvProtect>
              }
            />
            {/* END OF THE ADMIN AREA */}

            {/* STORE ZONE */}
            <Route path="storezone" element={<StoreZone />} />
            <Route path="storezone/home" element={<ProductDrawer />} />
            <Route path="storezone/Orders" element={<Order />} />
            <Route
              path="storezone/manual-invoice"
              element={<ManualInvoice />}
            />
            <Route path="storezone/invoices" element={<Invoices />} />
            <Route path="storezone/ProductTags" element={<ProductTags />} />
            {/* <Route path="storezone/VapePromotion" element={<VapePromotion />} />
            <Route
              path="storezone/VapePromotion/dashboard"
              element={<VapePromoDash />}
            /> */}
            <Route path="storezone/history" element={<History />} />
            <Route path="storezone/maintenance" element={<RepairSystem />} />
            <Route path="storezone/it-support" element={<ITsystem />} />
            <Route path="storezone/claims" element={<Claims />} />
            <Route path="storezone/Claim" element={<Claim />} />
            <Route path="storezone/wastage" element={<WastageForm />} />
            <Route
              path="storezone/hoagies-products"
              element={<HoagiesToEpos />}
            />
            <Route
              path="storezone/productManagement"
              element={<ProductDrawer />}
            />
            <Route path="storezone/staffManagement" element={<StaffDrawer />} />
            <Route path="storezone/storeManagement" element={<StoreDrawer />} />
            <Route path="RaceTrack" element={<RaceTrack />} />
            <Route path="Vaped4U" element={<Vaped4U />} />
            <Route path="Tubbees" element={<Tubbees />} />
            <Route path="snusdaddy" element={<SnusDaddy />} />
            {/* END OF STORE ZONE */}

            {/* MANAGER ZONE */}
            <Route
              path="managerZone"
              element={
                <StoreProtect>
                  <ManagerHome />
                </StoreProtect>
              }
            />
            <Route
              path="managerZone/add-epos-item"
              element={
                <StoreProtect>
                  <AddEposItem />
                </StoreProtect>
              }
            />
            <Route
              path="managerZone/epos-products"
              element={
                <StoreProtect>
                  <EposProducts />
                </StoreProtect>
              }
            />
            <Route
              path="managerZone/duplicates"
              element={
                <StoreProtect>
                  <Duplicate />
                </StoreProtect>
              }
            />
            <Route
              path="managerZone/no-supplier"
              element={
                <StoreProtect>
                  <NoSupplier />
                </StoreProtect>
              }
            />
            <Route
              path="managerZone/no-cost"
              element={
                <StoreProtect>
                  <NoCost />
                </StoreProtect>
              }
            />
            <Route
              path="managerZone/eod"
              element={
                <StoreManagerProtect>
                  <EodHome />
                </StoreManagerProtect>
              }
            />
            <Route
              path="managerZone/claims"
              element={
                <StoreManagerProtect>
                  <ManagerClaimsHome />
                </StoreManagerProtect>
              }
            />
            <Route
              path="managerZone/wastageReport"
              element={
                <StoreManagerProtect>
                  <WastageReportHome />
                </StoreManagerProtect>
              }
            />
            <Route
              path="managerZone/claims/individual"
              element={
                <StoreManagerProtect>
                  <IndvClaim />
                </StoreManagerProtect>
              }
            />
            <Route
              path="managerZone/hoagies-stock"
              element={
                <StoreProtect>
                  <HoagiesStockHome />
                </StoreProtect>
              }
            />

            <Route
              path="managerZone/pos-explorer"
              element={
                <StoreProtect>
                  <PosExplorerHome />
                </StoreProtect>
              }
            />
            {/* END OF MANAGER ZONE */}

            {/* DEV ZONE */}
            <Route
              path="dev-zone"
              element={
                <DevProtected>
                  {" "}
                  <DevHome />
                </DevProtected>
              }
            />
            <Route
              path="dev-zone/failedTransactions"
              element={
                <DevProtected>
                  <FailedTransactions />
                </DevProtected>
              }
            />
            <Route
              path="dev-zone/gs1"
              element={
                <DevProtected>
                  <Gs1Barcodes />
                </DevProtected>
              }
            />
            <Route
              path="dev-zone/users"
              element={
                <DevProtected>
                  <UsersHome />
                </DevProtected>
              }
            />
            <Route
              path="dev-zone/cctv"
              element={
                <AdminProtected>
                  <CCTVHome />
                </AdminProtected>
              }
            />

            <Route
              path="dev-zone/promotions"
              element={
                <DevProtected>
                  <PromotionsHome />
                </DevProtected>
              }
            />
            <Route
              path="dev-zone/promos/:id"
              element={
                <DevProtected>
                  <Promotions />
                </DevProtected>
              }
            />
            {/* END DEV ZONE */}

            {/* WAREHOUSE ZONE */}
            <Route
              path="warehouse/tiktok"
              element={
                <WarehouseProtect>
                  <TikTokForm />
                </WarehouseProtect>
              }
            />
            <Route
              path="warehouse/v4u-disposables"
              element={<V4uDisposables />}
            />
            <Route
              path="warehouse/picklist"
              element={
                <WarehouseProtect>
                  <Picklist />
                </WarehouseProtect>
              }
            />
            <Route
              path="warehouse/epos-products"
              element={
                <WarehouseProtect>
                  <EposProducts />
                </WarehouseProtect>
              }
            />
            <Route
              path="warehouse/order"
              element={
                <WarehouseProtect>
                  <PickOrder />
                </WarehouseProtect>
              }
            />
            <Route
              path="review-automatic-order"
              element={
                <WarehouseProtect>
                  <ReviewOrder />
                </WarehouseProtect>
              }
            />
            <Route
              path="warehouse/invoice-checker"
              element={
                <WarehouseProtect>
                  <InvoiceCheck />
                </WarehouseProtect>
              }
            />
            <Route
              path="warehouse/disposable-script"
              element={
                <WarehouseProtect>
                  <RunDispos />
                </WarehouseProtect>
              }
            />
            <Route
              path="warehouse/auto-order-check/:id"
              element={<AutoOrder />}
            />
            <Route path="warehouse/manual-order" element={<ManualOrder />} />
            <Route path="warehouse/packers" element={<PackersLogin />} />
            {/* END WAREHOUSE ZONE */}

            {/* FOOD TO GO AREA */}
            <Route path="bakes-check" element={<BakeCheck />} />
            <Route
              path="food-to-go"
              element={
                <FtgProtect>
                  <FtgHome />
                </FtgProtect>
              }
            />
            <Route
              path="food-to-go/sato"
              element={
                <FtgProtect>
                  <Sato />
                </FtgProtect>
              }
            />
            <Route
              path="food-to-go/reports"
              element={
                <FtgProtect>
                  <Reporting />
                </FtgProtect>
              }
            />
            <Route
              path="food-to-go/subway"
              element={
                <FtgProtect>
                  <SubwayHome />
                </FtgProtect>
              }
            />
            {/* END OF FOOD TO GO AREA */}
          </Routes>
          <Footer />
        </UserContext.Provider>
      </BrowserRouter>
    </div>
  );
}

export default App;
