import "../products.scss";
import { Button } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

export default function FoundProducts({ products, setSelectedProduct }) {
    return (
        <table className="found_products">
            <thead>
                <tr>
                    <th></th>
                    <th>Name</th>
                    <th className="cost_price_cell">Barcode</th>
                    <th className="cost_price_cell">Cost Price (£)</th>
                </tr>
            </thead>
            <tbody>
                {products.map(function (product, i) {
                    let productBarcodes = product.barcode;
                    if (productBarcodes === null) {
                        productBarcodes = "No Barcodes";
                    } else if (productBarcodes.includes(",")) {
                        productBarcodes = productBarcodes.split(",");
                        productBarcodes = productBarcodes[0] + "...";
                    }

                    return (
                        <tr key={i}>
                            <td>
                                <Button
                                    variant="contained"
                                    onClick={() =>
                                        setSelectedProduct(product.eposId)
                                    }
                                    startIcon={<VisibilityIcon />}
                                >
                                    View
                                </Button>
                            </td>
                            <td>{product.name}</td>
                            <td className="cost_price_cell">
                                {productBarcodes}
                            </td>
                            <td className="cost_price_cell">
                                £{parseFloat(product.costPrice).toFixed(2)}
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}
