import { useState, useEffect, useMemo } from "react";
import "./cctv.scss";
import CCTVFormInput from "./CCTVFormInput";
import CCTVDetails from "./CCTVDetails";
import axios from "axios";
import { getAllStores } from "../../../utils/Api";

const CCTVForm = () => {
    const url = process.env.REACT_APP_ANPR_URL;
    const [site, setSite] = useState("");
    const [siteName, setSiteName] = useState("");
    const [requests, setRequests] = useState([]);
    const [reload, setReload] = useState(false);
    const [statusList, setStatusList] = useState([]);

    useMemo(async () => {
        // TODO: ADD THIS TO UTILS
        axios
            .get(`${url}/cctv-system/statuses`)
            .then((res) => {
                setStatusList(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(async () => {
        let getSite = localStorage.getItem("site");
        let localSite = JSON.parse(getSite);
        setSiteName(localSite);
        let siteEposId;
        getAllStores()
            .then((data) => {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].siteName == localSite) {
                        siteEposId = data[i].eposId;
                        setSite(siteEposId);
                        axios
                            .get(
                                `${url}/cctv-system/submissions/details/site/${siteEposId}`
                            )
                            .then((res) => {
                                setRequests(res.data);
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [reload]);

    const rel = () => {
        setReload(!reload);
    };

    return (
        <main className="cctv_container">
            <section className="cctv">
                <h1>CCTV Requests</h1>
                <section className="cctv_forms">
                    <CCTVFormInput site={site} rel={rel} />
                    <CCTVDetails
                        site={site}
                        siteName={siteName}
                        rel={rel}
                        statusList={statusList}
                    />
                </section>
            </section>
        </main>
    );
};

export default CCTVForm;
