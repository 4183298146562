import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "./modals.scss";
import { useState } from "react";
import Button from "@mui/material/Button";

const coststyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  height: "25vw",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
};

export default function CostModal(props) {
    const [cost, setCost] = useState("")
    const [note, setNote] = useState("")

  const closeModal = () => {
    setCost("");
    props.handleClose();
  };

  return (
    <Box sx={coststyle}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Current Cost for this discrepancy: £{props.cost}
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <div className="staff_modal">
          <div className="modal__row">
            <label>New Cost:</label>
            <input type="number" onChange={(e) => setCost(e.target.value)} />
          </div>
          <div className="modal__row">
            <label>New Note:</label>
            <textarea  cols={40} rows={5} onChange={(e) => setNote(e.target.value)} />
          </div>
          <div className="modal__row">
            <Button
              color="success"
              variant="outlined"
              onClick={() => props.handleCost(cost, note)}
            >
              Submit
            </Button>
            <Button
              color="error"
              variant="contained"
              onClick={() => closeModal()}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Typography>
    </Box>
  );
}
