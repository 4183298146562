import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "./modals.scss";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Alert from "@mui/material/Alert";
import axios from "axios";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { getCompanies, getEmployeesByCompany } from "../../utils/Api";
import { decryptData } from "../../utils/cryptoUtils";
import { getEmployeeDetailsById } from "../../utils/apiUtils";

const discrepancystyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
  minHeight: "15vw",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
  overflowY: "hidden",
};

export default function Discrepancy(props) {
  const [staffName, setStaffName] = useState("");
  const [cost, setCost] = useState(0);
  const [overalCost, setOveralCost] = useState(0);
  const [discrepancy, setDiscrepancy] = useState([]);
  const [discAlert, setDiscAlert] = useState(false);
  const [staffNameList, setStaffNameList] = useState([]);
  const [manualStaffName, setManualStaffName] = useState([]);
  const [message, setMessage] = useState("");
  const [showErr, setShowErr] = useState(false);
  const [token, setToken] = useState("");

  useEffect(() => {
    let getToken = localStorage.getItem("auth-token");
    setToken(getToken);
    if (props.cost < 0) {
      setShowErr(true);
    } else {
      getCompanies(getToken)
        .then((companies) => {
          for (let i = 0; i < companies.length; i++) {
            if (companies[i].siteName === props.site) {
              getEmployeesByCompany(companies[i].companyId, getToken)
                .then((employees) => {
                  //decrypts the data and adds it to an array
                  for (let e = 0; e < employees.length; e++) {
                    setManualStaffName((manualStaffName) => [
                      ...manualStaffName,
                      {
                        name: decryptData(
                          employees[e].firstName,
                          process.env.REACT_APP_EMP_KEY
                        ),
                        lastName: decryptData(
                          employees[e].lastName,
                          process.env.REACT_APP_EMP_KEY
                        ),
                        employeeDetailsId: employees[e].employeeDetailsId,
                      },
                    ]);
                  }
                })
                .catch((err) => {
                  console.log(`Could not get employees: ${err}`);
                });
            }
          }
        })
        .catch((err) => {
          console.log(`Could not get the companies: ${err}`);
        });
      setShowErr(false);
      setOveralCost(props.cost);
    }
  }, [props]);

  const closeModal = () => {
    setStaffName("");
    setCost(0);
    setDiscrepancy([]);
    props.handleClose();
    setCost("");
  };

  const handleDiscArray = async () => {
    if (parseFloat(cost) > parseFloat(overalCost)) {
      setMessage("You cannot charge more than the discrepancy");
      setDiscAlert(true);
      setTimeout(() => {
        setDiscAlert(false);
      }, 3000);
    } else if (parseFloat(cost) < 0) {
      setMessage("You cannot charge negative values");
      setDiscAlert(true);
      setTimeout(() => {
        setDiscAlert(false);
      }, 3000);
    } else if (parseFloat(overalCost) >= parseFloat(cost)) {
      await getEmployeeDetailsById(staffName, token)
        .then((employee) => {
          discrepancy.push({ staffName: staffName, cost: cost, eposSite: employee.eposCompany });
          setOveralCost(parseFloat((overalCost - cost).toFixed(2)));
          setStaffName("");
          setCost("");
        })
        .catch((err) => {
          console.log(`Could not get employee details: ${err}`);
        });
    }
  };

  const handleRemove = (i, amount) => {
    var newArray = discrepancy.filter((val, index) => index !== i);
    setDiscrepancy(newArray);
    setOveralCost((parseFloat(overalCost) + parseFloat(amount)).toFixed(2));
  };

  return (
    <Box sx={discrepancystyle}>
      {showErr ? (
        <>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Cannot Mark As A Discrepancy As The Value Is Less Than £0
          </Typography>
          <div className="staff_modal">
            <div className="modal__row">
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, color: "black" }}
              >
                Please Go Back And Write Off This Investigation
              </Typography>
            </div>
            <div className="modal__row">
              <Button
                color="error"
                variant="contained"
                onClick={() => closeModal()}
              >
                Close
              </Button>
            </div>
          </div>
        </>
      ) : (
        <>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Mark As Discrepancy - Cost Left: £{overalCost}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {discAlert ? <Alert severity="error">{message}</Alert> : <></>}
            <div className="staff_modal">
              <div className="modal__row">
                <div className="row__column">
                  <label>Name of Staff:</label>
                  <select onChange={(e) => setStaffName(e.target.value)}>
                    <option>--Please Select Staff--</option>
                    {staffNameList.length > 0
                      ? staffNameList.map(function (d, i) {
                          return (
                            <option value={d.eposStaffId} key={d.eposStaffId}>
                              {d.name} - {d.siteName}
                            </option>
                          );
                        })
                      : manualStaffName.map((staff, i) => {
                          return (
                            <option key={i} value={staff.employeeDetailsId}>
                              {staff.name} {staff.lastName}
                            </option>
                          );
                        })}
                  </select>
                </div>
                <div className="row__column">
                  <label>Cost:</label>
                  <input
                    type="number"
                    onChange={(e) => setCost(e.target.value)}
                    value={cost}
                    min="0"
                  />
                </div>
                <AddCircleIcon
                  color="primary"
                  fontSize="large"
                  onClick={() => handleDiscArray()}
                />
              </div>
              {discrepancy.length > 0 ? (
                <table>
                  <thead>
                    <tr>
                      <th>Staff Name</th>
                      <th>Cost</th>
                      <th>Remove</th>
                    </tr>
                  </thead>
                  <tbody>
                    {discrepancy.map(function (d, i) {
                      return (
                        <tr>
                          <th>{d.staffName}</th>
                          <th>{d.cost}</th>
                          <th>
                            <RemoveCircleOutlineIcon
                              color="primary"
                              onClick={() => handleRemove(i, d.cost)}
                            />
                          </th>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <></>
              )}
              <div className="modal__row">
                {overalCost === 0 ? (
                  <Button
                    color="success"
                    variant="outlined"
                    onClick={() => props.handleDiscrepancy(discrepancy)}
                  >
                    Submit
                  </Button>
                ) : (
                  <></>
                )}
                <Button
                  color="error"
                  variant="contained"
                  onClick={() => closeModal()}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Typography>
        </>
      )}
    </Box>
  );
}
