//use for store managers and supervisors only routes

import { Navigate } from "react-router-dom";

const StoreProtect = ({children}) => {
    let userD = localStorage.getItem("userDetails");
    let user = JSON.parse(userD);
    if (
        user.user.role !== "Developer" &&
        user.user.role !== "Manager" &&
        user.user.role !== "Supervisor" &&
        user.user.role !== "Store Manager" &&
        user.user.role !== "Food Manager"
        ) {
        return <Navigate to="/not-allowed" replace/>
    }  
    return children
}

export {StoreProtect}