import { useState } from "react";
import axios from "axios";
import { Button } from "@mui/material";

export default function LocalAccounts() {
//   const url = "http://10.0.0.157:4022";
  const url = "https://nitropos-backend.nitrosolutions.co.uk";
  const [month, setMonth] = useState("");
  const [report, setReport] = useState({});

  const handleReport = () => {
    axios
      .get(`${url}/driveoffs/localAccount/${month}`)
      .then((res) => {
        setReport(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <main className="global__report">
      <h1>Select Month to see report</h1>
      <input type="month" onChange={(e) => setMonth(e.target.value)} />
      <Button
        variant="outlined"
        onClick={handleReport}
        sx={{ margin: "2% auto" }}
      >
        Get report
      </Button>

      {Object.keys(report).length > 0 ? (
        <section className="local_account">
          <div className="report_row">
            <label>Total transactions: </label>
            <strong>{report.transactions}</strong>
          </div>
          <div className="report_row">
            <label>Total amount: </label>
            <strong>£{report.value}</strong>
          </div>
        </section>
      ) : null}
    </main>
  );
}
