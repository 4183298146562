import { IconButton } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ImageIcon from "@mui/icons-material/Image";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import { useState } from "react";
import ImagesModal from "../../admin/storeAlerts/ImagesModal";
import { Modal } from "@mui/material";

export default function IndvAlert(props) {
    const [openImages, setOpenImages] = useState(false);
    const [actionTaken, setActionTaken] = useState("");
    const alert = props.alert;

    const read = () => {
        props.readAlert(alert.alertId, actionTaken);
    };

    return (
        <tr key={alert.alertId}>
            <td>{alert.name}</td>
            <td>{alert.siteName}</td>
            <td>{alert.description}</td>

            <td>{new Date(alert.dateAdded).toLocaleDateString("en-GB")}</td>
            <td>
                <input
                    type="text"
                    onChange={(e) => setActionTaken(e.target.value)}
                    style={{
                        borderRadius: "5px",
                        width: "80%",
                        border: "1px solid #2c53a0",
                    }}
                />
            </td>
            <td>
                {alert.images && alert.images.length > 0 ? (
                    <ImageIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => setOpenImages(true)}
                        color="primary"
                    />
                ) : (
                    <ImageNotSupportedIcon style={{ cursor: "pointer" }} />
                )}
            </td>
            <td>
                <IconButton onClick={read}>
                    <CheckIcon color="success" />
                </IconButton>
            </td>
            <Modal open={openImages}>
                <ImagesModal
                    handleClose={setOpenImages}
                    images={alert.images}
                />
            </Modal>
        </tr>
    );
}
