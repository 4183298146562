import {useState, useEffect} from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function ChildModal(props) {
    const [child, setChild] = useState(props.child)
    const [allergenList, setAllergenList] = useState(props.allergenList)
    const [childAllergens, setChildAllergens] = useState([])

    useEffect(() => {
        if (props.child.allergens){
            setChildAllergens(props.child.allergens)
            console.log(props.child.allergens)
        }
    },[])

  return (
    <tr>
      <td>{child.name}</td>
      {allergenList.map(function(allergen,idx){
        let exist = false
        if (childAllergens.length > 0){
            childAllergens.forEach(child => {
                if (child.allergenId === allergen.allergenId){
                    exist = true
                }
            });
        }
        return(
            <td>{exist ? <CheckCircleIcon color="success"/> : <></>}</td>
        )
      })}
    </tr>
  );
}
