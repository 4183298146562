import StoreNav from "./StoreNav";
import { useEffect, useState } from "react";
import "./styles/manual.scss";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import axios from "axios";
import Alert from "@mui/material/Alert";
import Tick from "../animations/Tick";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

export default function ManualInvoice(props) {
  const [site, setSite] = useState("");
  const [supplier, setSupplier] = useState("");
  const [form, setForm] = useState(false);
  const [image, setImage] = useState("");
  const [staff, setStaff] = useState("");
  const [errorAlert, setErrorAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [showTick, setShowTick] = useState(false);
  const [products, setProducts] = useState([]);
  const [edn, setEdn] = useState([]);
  const [imgLabel, setImglabel] = useState(
    "Please take a photo of the invoice"
  );
  const [images, setImages] = useState([]);
  const [invoiceAmount, setInvoiceAmount] = useState(0);

  useEffect(() => {
    let s = localStorage.getItem("site");
    console.log(s)
    setSite(JSON.parse(s));
  }, []);

  const handleSupplier = (sup) => {
    console.log(sup)
    console.log(supplier)
    console.log(form)
    setEdn([]);
    setSupplier(sup);
    if (supplier == "" && form) {
      setProducts([]);
    } else if (sup != "Subway" || sup === "Fife Creamery") {
      if (sup != "Other/Paid Out") {
        axios
          .get(
            `https://nitropos-backend.nitrosolutions.co.uk/purchaseOrder/searchProduct/${sup}`
          )
          .then((res) => {
            setProducts(res.data);
            setForm(true);
          })
          .catch((err) => {
            console.log(err);
          });
      }else {
        setForm(true);
      }
    } else if (sup === "Subway" || sup === "Fife Creamery") {
      setForm(true);
    }

    console.log(edn);
  };

  const handleImage = (img) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(img);
    fileReader.onload = () => {
      setImages((oldArray) => [...oldArray, fileReader.result]);
      setImglabel("Add Another Image if Required.");
    };
  };

  const submit = () => {


    if (images.length === 0 || staff === "" || supplier === "") {
      setMessage("Please Take a photo and insert your name");
      setErrorAlert(true);
      setTimeout(() => {
        setErrorAlert(false);
      }, 2500);
    } else if (
      edn.length === 0 &&
      supplier != "Subway" &&
      supplier != "Fife Creamery" &&
      supplier != "DC7" &&
      supplier != "Air Serv" &&
      supplier != "Warburtons" &&
      supplier != "Riverside" &&
      supplier != "Lottery" &&
      supplier != "Rich Sauces" &&
      supplier != "Other/Paid Out"
    ) {
      setMessage("Please insert the products and quantity received");
      setErrorAlert(true);
      setTimeout(() => {
        setErrorAlert(false);
      }, 2500);
    } else if (
      edn.length >= 1 &&
      supplier != "Subway" &&
      supplier != "Fife Creamery" &&
      supplier != "DC7" &&
      supplier != "Air Serv" &&
      supplier != "Warburtons" &&
      supplier != "Riverside" &&
      supplier != "Lottery" &&
      supplier != "Rich Sauces"
    ) {
      const zeroValue = edn.filter((product) => product.rceived === 0);
      if (zeroValue.length != 0) {
        setMessage("Quantities cannot be zero");
        setErrorAlert(true);
        setTimeout(() => {
          setErrorAlert(false);
        }, 2500);
      } else {
        var manualEposAdjust = false;
        if (
          supplier == "Cumbernauld Cakes" ||
          supplier == "DSL" ||
          supplier == "Petro Shops" ||
          supplier == "DCI Wholesale" ||
          supplier == "Stuarts Bakery"
        ) {
          manualEposAdjust = true;
        }
        document.getElementById("manBtn").style.display = "none";
        let data = {
          site: site,
          staffName: staff,
          images: images,
          supplier: supplier,
          edn: edn,
          manualEposAdjust: manualEposAdjust,
        };
        console.log("Trying to send");

        axios
          .post(
            "http://10.0.0.34:4022/edn/manual/submit",
            data
          )
          .then((res) => {
            console.log(res.data);
            setShowTick(true);
            setTimeout(() => {
              setShowTick(false);
              document.getElementById("manBtn").style.display = "inline-block";
              setForm(!form);
              setSupplier("");
              setEdn([]);
              setStaff("");
              setImages([]);
              setImage("");
            }, 3000);
          })
          .catch((err) => {
            document.getElementById("manBtn").style.display = "inline-block";
            setMessage("Something went wrong, please try again");
            setErrorAlert(true);
            setTimeout(() => {
              setErrorAlert(false);
            }, 3000);
          });
      }
    } else if (
      edn.length === 0 &&
      (supplier === "Subway" ||
        supplier === "Fife Creamery" ||
        supplier === "DC7" ||
        supplier === "Air Serv" ||
        supplier === "Warburtons" ||
        supplier === "Riverside" ||
        supplier === "Lottery" ||
        supplier === "Rich Sauces" ||
        supplier === "Other/Paid Out")
    ) {
      console.log(supplier);
      console.log(invoiceAmount);

      if (supplier === "Other/Paid Out" && invoiceAmount == 0) {
        setMessage("Ammount cannot be empty/0");
        setErrorAlert(true);
        setTimeout(() => {
          setErrorAlert(false);
        }, 2500);
      } else {

        document.getElementById("manBtn").style.display = "none";
        let data = {
          site: site,
          staffName: staff,
          images: images,
          supplier: supplier,
          amount: supplier === "Other/Paid Out" ? invoiceAmount : null,
        };

        console.log("SENDING HERE", data)
        axios
          .post(
            "http://10.0.0.34:4022/edn/manual/submit",
            data
          )
          .then((res) => {
            setShowTick(true);
            setInvoiceAmount(0);
            setTimeout(() => {
              setShowTick(false);
              document.getElementById("manBtn").style.display = "inline-block";
              setForm(!form);
              setSupplier("");
              setStaff("");
              setImages([]);
              setImage("");
            }, 3000);
          })
          .catch((err) => {
            document.getElementById("manBtn").style.display = "inline-block";
            setMessage("Something went wrong, please try again");
            setErrorAlert(true);
            setTimeout(() => {
              setErrorAlert(false);
            }, 3000);
          });
      }


    }
  };

  const searchProduct = (item) => {
    if (item.length > 3) {
      products.forEach((product) => {
        if (product.barcode == item) {
          setEdn((edn) => [
            ...edn,
            {
              name: product.name,
              barcode: product.barcode,
              eposId: product.eposId,
              qtyReceived: 0,
              qtyOrdered: 0,
              orderCode: product.orderCode,
              price: product.salePrice,
              image: "",
              caseQty: 1,
              packQty: 1,
            },
          ]);
          document.getElementById("search_input").value = "";
        }
      });
    }
  };

  const inputReceived = (index, amount) => {
    for (let p = 0; p < edn.length; p++) {
      if (p === index) {
        edn[p].qtyReceived = amount;
        edn[p].qtyOrdered = amount;
      }
    }
  };

  const handleRemove = (i) => {
    const removeItem = edn.filter((val, index) => index !== i);
    setEdn(removeItem);
  };

  return (
    <section className="manual__invoice">
      <StoreNav />
      <section className="manual__suppliers">
        <h1>Please select a Supplier for a manual invoice</h1>
        <div className="sup__row">
          <button
            className="supplier__btn"
            onClick={() => handleSupplier("Cumbernauld Cakes")}
          >
            Cumbernauld Cakes
          </button>
          <button
            className="supplier__btn"
            onClick={() => handleSupplier("DCI Wholesale")}
          >
            DCI Wholesale
          </button>
          <button
            className="supplier__btn"
            onClick={() => handleSupplier("DSL")}
          >
            DSL
          </button>
          <button
            className="supplier__btn"
            onClick={() => handleSupplier("Petro Shops")}
          >
            Petro Shops
          </button>
          <button
            className="supplier__btn"
            onClick={() => handleSupplier("Fife Creamery")}
          >
            Fife Creamery
          </button>
          <button
            className="supplier__btn"
            onClick={() => handleSupplier("Subway")}
          >
            Subway
          </button>
          {/* <button
            className="supplier__btn"
            onClick={() => handleSupplier("DC7")}
          >
            DC7
          </button> */}
          <button
            className="supplier__btn"
            onClick={() => handleSupplier("Air Serv")}
          >
            Air Serv
          </button>
          <button
            className="supplier__btn"
            onClick={() => handleSupplier("Warburtons")}
          >
            Warburtons
          </button>
          <button
            className="supplier__btn"
            onClick={() => handleSupplier("Riverside")}
          >
            Riverside
          </button>
          <button
            className="supplier__btn"
            onClick={() => handleSupplier("Lottery")}
          >
            Lottery
          </button>

          {/* <button
            className="supplier__btn"
            onClick={() => handleSupplier("Stuarts Bakery")}
          >
            Stuart's Bakery
          </button> */}

          <button
            className="supplier__btn"
            onClick={() => handleSupplier("Rich Sauces")}
          >
            Rich Sauces
          </button>

          <button
            className="supplier__btn"
            onClick={() => handleSupplier("Other/Paid Out")}
          >
            Other/Paid Out
          </button>
        </div>
        {form ? (
          <div className="manual__form">
            <h3 className="supplier_title">{supplier}</h3>
            {supplier != "Subway" &&
              supplier != "DC7" &&
              supplier != "Air Serv" &&
              supplier != "Warburtons" &&
              supplier != "Riverside" &&
              supplier != "Lottery" &&
              supplier != "Rich Sauces" &&
              supplier != "Other/Paid Out" ? (
              <>
                {supplier != "Fife Creamery" ? (
                  <>
                    <h4>Select or enter barcode of items you have received</h4>
                    <input
                      list="products_ID"
                      onChange={(e) => searchProduct(e.target.value)}
                      placeholder="Select Product"
                      id="search_input"
                    />
                    <datalist id="products_ID">
                      {products.map(function (d, i) {
                        return <option value={d.barcode}>{d.name}</option>;
                      })}
                    </datalist>
                    {edn.length >= 1 ? (
                      <table>
                        <thead>
                          <tr>
                            <th>Epos ID</th>
                            <th>Barcode</th>
                            <th>Product Name</th>
                            <th>Received</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {edn.map(function (d, i) {
                            return (
                              <tr>
                                <th>{d.eposId}</th>
                                <th>{d.barcode}</th>
                                <th>{d.name}</th>
                                <th>
                                  <input
                                    type="number"
                                    onChange={(e) =>
                                      inputReceived(i, e.target.value)
                                    }
                                    placeholder={d.received}
                                    min="0"
                                  />
                                </th>
                                <th>
                                  <RemoveCircleOutlineIcon
                                    fontSize="small"
                                    color="primary"
                                    onClick={() => handleRemove(i)}
                                    sx={{
                                      margin: "0",
                                    }}
                                  />
                                </th>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
            {supplier === "Subway" ||
              supplier === "Fife Creamery" ||
              supplier === "DC7" ||
              supplier === "Air Serv" ||
              supplier === "Warburtons" ||
              supplier === "Riverside" ||
              supplier === "Lottery" ||
              supplier === "Rich Sauces" ? (
              <h4>Upload invoice image and enter your name</h4>
            ) : null}
            <label>{imgLabel}</label>
            <input
              type="file"
              accepts="image/*"
              onChange={(e) => {
                handleImage(e.target.files[0]);
              }}
            />
            {supplier === "Other/Paid Out" ? (
              <>
                <label>Amount:</label>
                <input type="number" max={100} min={0} onChange={(e) => setInvoiceAmount(e.target.value)} />
              </>
            ) : (<></>)}

            <label>Your name:</label>
            <input type="text" onChange={(e) => setStaff(e.target.value)} />
            {/* {image ? <img src={image} alt="Invoice Image" /> : <></>} */}
            {images.map(function (d, i) {
              return (
                <>
                  <img src={d} alt="Invoice Image" width="500px" />
                  <Button
                    variant="text"
                    id="remBtn"
                    color="error"
                    onClick={() => {
                      const filteredImages = images.filter((image) => {
                        return image !== d;
                      });
                      setImages(filteredImages);
                    }}
                  >
                    Remove Image
                  </Button>
                </>
              );
            })}
            {errorAlert ? <Alert severity="error">{message}</Alert> : <></>}
            <Button
              variant="outlined"
              color="success"
              endIcon={<SendIcon sx={{ margin: "0" }} />}
              id="manBtn"
              onClick={() => submit()}
            >
              Send
            </Button>
            {showTick ? <Tick /> : <></>}
          </div>
        ) : (
          <></>
        )}
      </section>
    </section>
  );
}
