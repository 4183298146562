import { useState, useEffect } from "react";
import Loader from "../../../animations/Loader";
import axios from "axios";
import "./dupe.scss";
import ProductDetails from "../products/tabs/ProductDetails";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "75vw",
    maxHeight: "55vh",
    bgcolor: "#e4ffff",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
    overflowY: "scroll",
    "@media(max-width: 600px)": {
        width: "90vw",
        maxHeight: "70vh",
    },
};

export default function Products(props) {
    const [type, setType] = useState(props.type);
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [product, setProduct] = useState("");
    const [categoryList, setCategoryList] = useState([]);
    const [supplierList, setSupplierList] = useState([]);
    const [reload, setReload] = useState(true);

    const [showDetails, setShowDetails] = useState(false);

    const handleOpenDetails = () => setShowDetails(true);
    const handleCloseDetails = () => setShowDetails(false);

    useEffect(() => {
        setType(props.type);
        axios
            .get(
                `https://nitropos-backend.nitrosolutions.co.uk/eposProducts/duplicates/${props.type}`
            )
            .then((res) => {
                setLoading(false);
                setItems(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
        axios
            .get(
                "https://nitropos-backend.nitrosolutions.co.uk/eposSQL/getSuppliers"
            )
            .then((res) => {
                setSupplierList(res.data);
            })
            .catch((err) => {
                console.log(err);
            });

        axios
            .get(
                "https://nitropos-backend.nitrosolutions.co.uk/eposSQL/getCategories"
            )
            .then((res) => {
                setCategoryList(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [reload]);

    const rel = () => {
        handleCloseDetails();
        setReload(!reload);
    };

    const selectProduct = (product) => {
        setProduct(product);
        handleOpenDetails();
    };
    return (
        <section id="dupeTable">
            <h1>Duplicate {type}</h1>
            {loading ? (
                <Loader />
            ) : (
                <>
                    {items.length > 0 ? (
                        <table>
                            <thead>
                                <th>{type.toUpperCase()}</th>
                                <th>Items</th>
                            </thead>
                            <tbody>
                                {items.map(function (d, i) {
                                    return (
                                        <tr key={i}>
                                            <td>{d[type]}</td>
                                            <td>
                                                {d.products.map(function (
                                                    p,
                                                    i
                                                ) {
                                                    return (
                                                        <p
                                                            onClick={() =>
                                                                selectProduct(p)
                                                            }
                                                        >
                                                            {p.name}{" "}
                                                            <EditIcon
                                                                fontSize="small"
                                                                sx={{
                                                                    cursor: "pointer",
                                                                }}
                                                            />
                                                        </p>
                                                    );
                                                })}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    ) : (
                        <h2>No Duplicates Found</h2>
                    )}
                </>
            )}
            <Modal
                open={showDetails}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                onClose={handleCloseDetails}
            >
                <Box sx={style}>
                    <ProductDetails
                        product={product}
                        rel={rel}
                        categories={categoryList}
                        suppliers={supplierList}
                    />
                </Box>
            </Modal>
        </section>
    );
}
