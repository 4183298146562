import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { IconButton } from "@mui/material";

export default function MobileItem(props) {
  const product = props.product;


  return (
    <section className="mobile_item" key={product.barcode}>
      <h3>{product.name}</h3>
      <h3>Barcode: {product.barcode}</h3>
      <strong>
        Supplier:{" "}
        <span>
          {product.supplierName == undefined
            ? "No Supplier"
            : product.supplierName}
        </span>
      </strong>
      <strong>
        Fresh: <span>{product.fresh ? <>Yes</> : <>No</>}</span>
      </strong>
      <select onChange={(e) => props.handleReason(e.target.value, props.i)}>
        <option>--Select a reason--</option>
        <option>Damaged</option>
        <option>Out of Date</option>
        <option>Out of Temperature</option>
        <option>Return to supplier</option>
      </select>
      <input
        type="number"
        onChange={(e) => props.handleWastedUnits(e.target.value, props.i)}
        placeholder={product.wasted}
        min={0}
        pattern="[0-9]"
        id="units_input"
      />
      <IconButton onClick={() => props.handleRemoveWastage(props.i)}>
        <DeleteForeverIcon color="primary" fontSize="large"/>
      </IconButton>
    </section>
  );
}
