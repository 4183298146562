import { useEffect, useState } from "react";
import { Button, Alert } from "@mui/material";

export default function ConfirmDiscrep(props) {
  const shifts = props.groupedShifts;
  const [bagId, setBagId] = useState("");
  const [expected, setExpected] = useState(0);
  const [actual, setActual] = useState(0);
  const [difference, setDifference] = useState(0);
  //alerts
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  useEffect(() => {
    let actual = 0;
    let expected = 0;
    for (let i = 0; i < props.groupedShifts.length; i++){
        actual = actual + parseFloat(props.groupedShifts[i].actual)
        expected = expected + parseFloat(props.groupedShifts[i].value)
    }
    setExpected(expected)
    setActual(actual)
    setDifference(actual - expected)
  }, []);

  const handleNext = () => {
    console.log(bagId)
    if(bagId === ""){
        setMessage("Please insert the bag ID")
        setSeverity("warning")
        setShowAlert(true)
        setTimeout(() => {
            setShowAlert(false)
        },3000)
    } else{
        props.handleBag(actual, difference, bagId)
    }
  }
  return (
    <section className="subway_section">
      <h4>Please confirm the total amount and bag number</h4>
      <table>
        <thead>
          <tr>
            <th>Expected</th>
            <th>Actual</th>
            <th>Difference</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{expected}</td>
            <td>{actual}</td>
            <td>{(difference).toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
      <section className="row">
        <strong>Please insert the bag number:</strong>
        <input type="text" onChange={(e) => setBagId(e.target.value)} />
      </section>
      {showAlert ? (
        <Alert severity={severity} sx={{ width: "60%", margin: "1% auto" }}>
          {message}
        </Alert>
      ) : (
        <Button variant="outlined" color="success" onClick={handleNext}>
          Next Step
        </Button>
      )}
    </section>
  );
}
