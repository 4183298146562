import { useState } from "react";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import Loader from "../../../Fuel/loader/Loader";
import Alert from "@mui/material/Alert";
import axios from 'axios'

export default function ITform(props) {
  const [name, setName] = useState("");
  const [issue, setIssue] = useState("");
  const [date, setDate] = useState("");
  const [type, setType] = useState("");
  const [make, setMake] = useState("");
  const [priority, setPriority] = useState("");
  const [showSendButton, setShowSendButton] = useState(true);
  const [message, setMessage] = useState("");
  const [completionAlert, setCompletionAlert] = useState(false);
  const [sentAlert, setSentAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [issueAlert, setIssueAlert] = useState(false);
  const [assetTag, setAssetTag] = useState("")



  const submit = async() => {
    if (issue.length < 25) {
        setMessage("Please expand your description of the issue")
      setIssueAlert(true);
      setTimeout(() => {
        setIssueAlert(false);
      }, 2000);
    } else if (
      name === "" ||
      issue === "" ||
      type === "--Select One--" ||
      make === "" ||
      priority === "--Select One--" ||
      assetTag === ""
    ) {
      setMessage("All fields must be filled, please try again.");
      setCompletionAlert(true);
      setTimeout(() => {
        setCompletionAlert(false);
      }, 2500);
    } else {
      setShowSendButton(false);
      let data = {
        site: props.site,
        name: name,
        issue: issue,
        date: date,
        type: type,
        make: make,
        priority: priority,
        assetTag: assetTag,
      };
      await axios
        .post("https://nitrous.rt-itservices.co.uk/itSupport", data)
        .then((res) => {
            setMessage("Form sent to IT")
          setSentAlert(true);
          setTimeout(() => {
            setSentAlert(false);
          }, 2000);
          setShowSendButton(true);
          setDate("")
          setName("")
          setType("")
          setMake("")
          setAssetTag("")
          setIssue("")
          setPriority("")
          props.rel()
        })
        .catch((err) => {
          console.log(err);
          setMessage("Could not send form, please try again")
          setErrorAlert(true);
          setTimeout(() => {
            setErrorAlert(false);
          }, 2000);
          setShowSendButton(true);
        });
    }
  };

  return (
    <main className="it__cards">
      {completionAlert ? (
        <Alert
          variant="filled"
          severity="warning"
          sx={{
            width: "90vw",
            margin: "auto",
            position: "absolute",
            left: "5vw",
            top: "70vh",
          }}
        >
          {message}
        </Alert>
      ) : (
        <></>
      )}
      {sentAlert ? (
        <Alert
          variant="filled"
          severity="success"
          sx={{
            width: "20vw",
            margin: "auto",
          }}
        >
          {message}
        </Alert>
      ) : (
        <></>
      )}
      {errorAlert ? (
        <Alert
          variant="filled"
          severity="error"
          sx={{
            width: "20vw",
            margin: "auto",
          }}
        >
          {message}
        </Alert>
      ) : (
        <></>
      )}
      {issueAlert ? (
        <Alert
          variant="filled"
          severity="warning"
          sx={{
            width: "50vw",
            margin: "auto",
            position: "absolute",
            top: "60vh",
            left: "20vw",
            zIndex: "10",
          }}
        >
          {message}
        </Alert>
      ) : (
        <></>
      )}

      <label>
        Date<span>*</span>
      </label>
      <input type="date" onChange={(e) => setDate(e.target.value)} />
      <label>
        Your name<span>*</span>
      </label>
      <input type="text" onChange={(e) => setName(e.target.value)} required />
      <label>
        Please Select between Hardware or Software <span>*</span>
      </label>
      <select onChange={(e) => setType(e.target.value)}>
        <option>--Select One--</option>
        <option>Software</option>
        <option>Hardware</option>
      </select>
      {type === "Software" ? (
        <label>
          Software Name <span>*</span>
        </label>
      ) : type === "Hardware" ? (
        <label>
          Hardware Type <span>*</span>
        </label>
      ) : (
        <label>
          Machine Type<span>*</span>
        </label>
      )}
      <input type="text" onChange={(e) => setMake(e.target.value)} required />
      <label>Asset Tag/Serial Number<span>*</span></label>
      <input type="text" onChange={(e)=> setAssetTag(e.target.value)} />
      <label>
        Description<span>*</span>
      </label>
      <textarea
        type="text"
        onChange={(e) => setIssue(e.target.value)}
        cols="40"
        rows="5"
        required
      />
      <br />
      <label>
        Priority <span>*</span>
      </label>
      <select onChange={(e) => setPriority(e.target.value)}>
        <option>--Select One--</option>
        <option>Critical (Site Down)</option>
        <option>High</option>
        <option>Medium</option>
        <option>Low</option>
      </select>
      {showSendButton ? (
        <Button variant="contained" endIcon={<SendIcon sx={{margin: "0"}}/>} color="primary" onClick={() => submit()}>
          Send
        </Button>
      ) : (
        <Loader />
      )}
    </main>
  );
}
