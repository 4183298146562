import { useEffect, useState } from "react";
import axios from "axios";
import "./styles/pickOrder.scss";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import RedoIcon from "@mui/icons-material/Redo";
import UndoIcon from "@mui/icons-material/Undo";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate, Navigate } from "react-router-dom";
import WareNav from "../WareNav";
import LoginTest from "../../LoginTest";
import { Stack } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95vw",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  borderRadius: "1rem",
};

export default function PickOrder() {
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");
  const [errorAlert, setErrorAlert] = useState(false);
  const [user, setUser] = useState("");
  const [role, setRole] = useState("");
  const [confirmBoxes, setConfirmBoxes] = useState(false);
  const [sumary, setSumary] = useState(false);
  const [order, setOrder] = useState(true);
  const [boxes, setBoxes] = useState(0);
  const navigate = useNavigate();
  const url = "https://nitropos-backend.nitrosolutions.co.uk";

  //arrays for individual items
  const [ordered, setOrdered] = useState([]);
  const [picked, setPicked] = useState([]);
  const [barcodes, setBarcodes] = useState([]);
  const [names, setNames] = useState([]);
  const [enteredVal, setEnteredVal] = useState("");
  const [site, setSite] = useState("");
  const [arrayPosition, setArrayPosition] = useState(0);
  const [list, setList] = useState(0);
  const [type, setType] = useState("");

  //modal functions
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openDouble, setOpenDouble] = useState(false);
  const handleOpenDouble = () => setOpenDouble(true);
  const handleCloseDouble = () => setOpenDouble(false);

  useEffect(() => {
    //gets the id from local storage and calls BE for picklist
    let id = localStorage.getItem("id");
    let pickType = localStorage.getItem("type");
    setType(pickType);

    let userD = localStorage.getItem("userDetails");
    let user = JSON.parse(userD);
    setUser(user.user.user);
    setRole(user.user.role);

    axios
      .get(`${url}/edn/v4u/picklist/${id}/${pickType}`)
      //.get(`${url}/edn/v4u/picklist/${id}/sql`)
      .then((res) => {
        setData(res.data);
        setSite(res.data.site);
        setList(res.data.productsOrdered.length);

        let ordered = [];
        let barcodes = [];
        let names = [];

        for (let i = 0; i < res.data.productsOrdered.length; i++) {
          ordered.push(res.data.productsOrdered[i].qtyOrdered);
          barcodes.push(res.data.productsOrdered[i].Barcode);
          names.push(res.data.productsOrdered[i].Name);
        }

        setOrdered(ordered);
        setBarcodes(barcodes);
        setNames(names);
        setEnteredVal(res.data.productsOrdered[arrayPosition].qtyReceived);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

 

  //adds the quantity picked to a local array
  const handleQty = (qty) => {
    let copyArray = picked;
    if (qty == "") {
      qty = 0;
      if (copyArray[arrayPosition]) {
        copyArray[arrayPosition] = 0;
        setPicked(copyArray);
      }
    } else if (parseInt(copyArray[arrayPosition]) >= 0) {
      copyArray[arrayPosition] = parseInt(qty);
      setPicked(copyArray);
    } else {
      picked.push(parseInt(qty));
    }
  };

  //checks if the amount picked is different to the amount ordered
  const next = () => {
    if (picked[arrayPosition] != ordered[arrayPosition]) {
      handleOpen();
    } else {
      nextConfirmed();
    }
  };

  //moves to the next item
  const nextConfirmed = () => {
    if (open) {
      setOpen(false);
    }
    setArrayPosition(arrayPosition + 1);
    document.getElementById("input_order").value = "";
  };

  const boxesConfirm = () => {
    console.log(boxes);
    if (boxes === 0) {
      setMessage("Please confirm the number of boxes");
      setErrorAlert(true);
      setTimeout(() => {
        setErrorAlert(false);
        setMessage("");
      }, 3000);
    } else {
      setConfirmBoxes(false);
      setSumary(true);
    }
  };

  const handleBack = () => {
    if (arrayPosition > 0) {
      setArrayPosition(arrayPosition - 1);
    } else {
      //alerts you are on the first item of the list
      document.getElementById("buttons_order").style.display = "none";
      setMessage("You are on the first item of the list");
      setErrorAlert(true);
      setTimeout(() => {
        setErrorAlert(false);
        setMessage("");
        document.getElementById("buttons_order").style.display = "flex";
      }, 3000);
    }
  };

  const confirmPick = () => {
    for (let i = 0; i < data.productsOrdered.length; i++) {
      for (let s = 0; s < picked.length; s++) {
        if (s == i) {
          data.productsOrdered[i].picked = picked[s];
        }
      }
    }
    data.pickedBy = user;
    setOrder(false);
    if (type == "V4U") {
      setConfirmBoxes(true);
    } else {
      setSumary(true);
    }
  };

  const handleFinalise = () => {
    document.getElementById("finalise_btn").style.display = "none";
    data.boxes = boxes;
    axios
      //.post(`${url}/edn/picklist/complete/sql`, data)
      .post(`${url}/edn/picklist/complete`, data)
      .then((res) => {
        document.getElementById("finalise_btn").style.display = "flex";
        navigate("/warehouse/picklist");
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status == 400) {
          handleOpenDouble();
        } else {
          setMessage("Something went wrong, please try again");
          setErrorAlert(true);
          setTimeout(() => {
            setMessage("");
            setErrorAlert(false);
            document.getElementById("finalise_btn").style.display = "flex";
          }, 3500);
        }
      });
  };

  const confirmSubmit = () => {
    data.newCopy = true;

    axios
      //.post(`${url}/edn/picklist/complete/sql`, data)
      .post(`${url}/edn/picklist/complete`, data)
      .then((res) => {
        document.getElementById("finalise_btn").style.display = "flex";
        navigate("/warehouse/picklist");
      })
      .catch((err) => {
        console.log(err);
        setMessage("Something went wrong, please try again");
        setErrorAlert(true);
        setTimeout(() => {
          setMessage("");
          setErrorAlert(false);
          document.getElementById("finalise_btn").style.display = "flex";
        }, 3500);
      });
  };

  const cancelSubmit = () => {
    handleCloseDouble();
    navigate("/warehouse/picklist");
  };

  return (
    <main className="pick__order">
      <WareNav />
      <h1>
        {type} Pick List for {site}
      </h1>
      {order ? (
        <>
          {data.productsOrdered ? (
            <div className="order__box">
              <h4 style={{ marginTop: "4%" }}>
                {arrayPosition + 1}/{data.productsOrdered.length}
              </h4>
            </div>
          ) : null}
          <section className="order__product">
            <h5>{names[arrayPosition]}</h5>
            <h6>
              Quantity Ordered: <span>{ordered[arrayPosition]}</span>
            </h6>
            <div className="product__row">
              <h6>Quantity picked:</h6>
              <input
                type="number"
                min={0}
                pattern="[0-9]*"
                onChange={(e) => handleQty(e.target.value)}
                id="input_order"
              />
            </div>
          </section>
          <section className="order__buttons" id="buttons_order">
            <Button
              variant="outlined"
              color="warning"
              startIcon={<UndoIcon />}
              onClick={() => handleBack()}
            >
              Back
            </Button>
            {arrayPosition + 1 === list ? (
              <Button
                variant="contained"
                color="success"
                endIcon={<CheckCircleIcon />}
                onClick={() => confirmPick()}
              >
                Confirm
              </Button>
            ) : (
              <Button
                variant="outlined"
                color="success"
                endIcon={<RedoIcon />}
                onClick={() => next()}
              >
                Next
              </Button>
            )}
          </section>
          {errorAlert ? <Alert severity="warning">{message}</Alert> : null}
        </>
      ) : confirmBoxes ? (
        <section className="box_confirm">
          <label>Please confirm the number of boxes for this order</label>
          <input
            type="number"
            pattern="[0-9]*"
            onChange={(e) => setBoxes(parseInt(e.target.value))}
          />
          {errorAlert ? (
            <Alert severity="warning">{message}</Alert>
          ) : (
            <Button variant="outlined" color="success" onClick={boxesConfirm}>
              Continue
            </Button>
          )}
        </section>
      ) : sumary ? (
        <section className="sumary_order">
          <table>
            <thead>
              <th>Product</th>
              <th>Ordered</th>
              <th>Picked</th>
            </thead>
            <tbody>
              {data.productsOrdered.map(function (d, i) {
                return (
                  <tr>
                    <th>{d.Name}</th>
                    <th>{d.qtyOrdered}</th>
                    <th>{d.picked}</th>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {type == "V4U" ? (
            <p style={{ color: "#000", margin: "1% auto" }}>
              Boxes: <strong>{boxes}</strong>
            </p>
          ) : (
            <></>
          )}

          {errorAlert ? <Alert severity="warning">{message}</Alert> : null}
          <Button
            variant="contained"
            color="success"
            onClick={() => handleFinalise()}
            id="finalise_btn"
          >
            Finalise
          </Button>
        </section>
      ) : null}
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            You are about to pick a different quantity than was ordered
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className="modal__order">
              <p>
                Ordered: <span>{ordered[arrayPosition]}</span>
              </p>
              <p>
                Picked: <span>{picked[arrayPosition]}</span>
              </p>
              <div className="modal__btns">
                <Button variant="outlined" color="error" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  color="success"
                  onClick={() => nextConfirmed()}
                >
                  Continue
                </Button>
              </div>
            </div>
          </Typography>
        </Box>
      </Modal>

      <Modal
        open={openDouble}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            An order already exists for this site, please confirm you want to
            submit another one
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Stack
              direction="row"
              spacing={12}
              alignItems="center"
              justifyContent="center"
            >
              <Button variant="outlined" color="error" onClick={cancelSubmit}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={confirmSubmit}
              >
                Confirm
              </Button>
            </Stack>
          </Typography>
        </Box>
      </Modal>
    </main>
  );
}
