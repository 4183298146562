//use for holiday routes

import { Navigate, replace, useLocation, useNavigate } from "react-router-dom";

const HolProttect = ({ children }) => {
  let userD = localStorage.getItem("userDetails");
  const location = useLocation();
  if (!userD) {
    window.location.replace(`/?redirect=${location.pathname}`);
  }
  let user = JSON.parse(userD);

  if (
    user &&
    user.user.role !== "Developer" &&
    user.user.user !== "vikas@ghsl.uk" &&
    user.user.user !== "shamly@ghsl.uk" &&
    user.user.user !== "hrithik@ghsl.uk" &&
    user.user.user !== "guna@ghsl.uk" &&
    user.user.role !== "Store Manager" &&
    user.user.role !== "Warehouse Manager" &&
    user.user.role !== "Food Manager" &&
    user.user.role !== "Supervisor" &&
    !user.sites.includes("HQ") &&
    user.user.role !== "Manager"
  ) {
    return <Navigate to="/not-allowed" replace />;
  }
  return children;
};

export { HolProttect };
