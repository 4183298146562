import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import Tick from "../../../../animations/Tick";
import Loader from "../../../../animations/Loader";
import axios from "axios";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50vw",
    maxHeight: "75vh",
    bgcolor: "background.paper",
    border: "none",
    overflowY: "auto",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 2,
    "@media(max-width: 1000px)": {
        width: "95vw",
    },
};

export default function CheckItems(props) {
    const url = "https://nitropos-backend.nitrosolutions.co.uk";
    //const url = 'http://10.0.0.34:4022'

    const [wastedItems, setWastedItems] = useState(props.wastedItems);
    const [site, setSite] = useState("");
    const [sumary, setSumary] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [user, setUser] = useState("");
    const [showTick, setShowTick] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    //   const [staffOptions, setStaffOptions] = useState(false)
    //alert
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [img, setImg] = useState(null);

    useEffect(() => {
        let getSite = localStorage.getItem("site");
        let localSite = getSite.replace(/['"]+/g, "");
        setSite(localSite);
    }, [wastedItems]);

    const updateStatus = (type, val) => {
        let updatedItems = wastedItems.map((item, i) => {
            if (i != currentIndex) {
                return item;
            } else {
                return { ...item, [type]: val };
            }
        });
        setWastedItems(updatedItems);
    };

    const next = () => {
        console.log(wastedItems[currentIndex].fixable);
        if (wastedItems[currentIndex].fixable === null) {
            document.getElementById("next_btn").style.display = "none";
            setSeverity("warning");
            setMessage("Please select if item is fixable");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
                document.getElementById("next_btn").style.display = "flex";
            }, 2500);
        } else if (
            wastedItems[currentIndex].fresh === null &&
            wastedItems[currentIndex].fixable === undefined
        ) {
            document.getElementById("next_btn").style.display = "none";
            setSeverity("warning");
            setMessage("Please select if item is fresh");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
                document.getElementById("next_btn").style.display = "flex";
            }, 2500);
        } else {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const submitForm = async () => {
        document.getElementById("submit_waste").style.display = "none";
        if (user === "") {
            setMessage("Please insert your name");
            setSeverity("warning");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
                document.getElementById("submit_waste").style.display = "flex";
            }, 2500);
        } else {
            setShowLoader(true);
            let wastageId = "";

            let data = {
                site: site,
                date: new Date(),
                source: props.type,
                staffName: user,
                fileName: img.name,
            };

            await axios
                .post(`${url}/claims/submit/wastage`, data)
                .then(async (res) => {
                    wastageId = res.data.wasteId;
                    if (res.data.imgLink != "") {
                        await axios
                            .put(res.data.imgLink, img)
                            .then((res) => {
                                console.log("WASTE IMAGE UPLOADED");
                            })
                            .catch((err) => {});
                    }
                })
                .catch((err) => {
                    console.log(`Could not submit: ${err}`);
                    setMessage("Could not submit");
                    setSeverity("error");
                    setShowLoader(false);
                    setShowAlert(true);
                    setTimeout(() => {
                        setShowAlert(false);
                        document.getElementById("submit_waste").style.display =
                            "flex";
                    }, 2500);
                });

            if (wastageId != "") {
                for (let i = 0; i < wastedItems.length; i++) {
                    let item = {
                        eposId: wastedItems[i].eposId,
                        name: wastedItems[i].name,
                        barcode: wastedItems[i].barcode,
                        wasted: wastedItems[i].wasted,
                        reason: wastedItems[i].reason,
                        fixable: wastedItems[i].fixable,
                        fresh: wastedItems[i].fresh,
                    };

                    await axios
                        .post(`${url}/claims/submit/items/${wastageId}`, item)
                        .then((resp) => {
                            console.log("Added -", wastedItems[i].name);
                        })
                        .catch((err) => {
                            console.log(
                                `Could not submit item ${wastedItems[i].name}: ${err}`
                            );
                            setMessage("Could not submit");
                            setSeverity("error");
                            setShowLoader(false);
                            setShowAlert(true);
                            setTimeout(() => {
                                setShowAlert(false);
                                document.getElementById(
                                    "submit_waste"
                                ).style.display = "flex";
                            }, 2500);
                        });
                }
                setShowLoader(false);
                setShowTick(true);
                setTimeout(() => {
                    window.location.reload();
                }, 2500);
            }
        }
    };

    const handleImg = (file) => {
        let extension = file.name.split(".").pop();

        if (extension != "exe") {
            setImg(file);
        }
    };

    return (
        <Box sx={style}>
            {!sumary ? (
                <section className="summary_modal">
                    <h3 className="modal_title">
                        {wastedItems[currentIndex].name}
                    </h3>
                    <p>Is this item fixable/sellable?</p>
                    {wastedItems[currentIndex].fixable === null ? (
                        <div className="fixable_button">
                            <Button
                                variant="contained"
                                color="success"
                                onClick={() => updateStatus("fixable", true)}
                            >
                                Yes
                            </Button>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={() => updateStatus("fixable", false)}
                            >
                                No
                            </Button>
                        </div>
                    ) : (
                        <h5>
                            {wastedItems[currentIndex].fixable ? "Yes" : "No"}
                        </h5>
                    )}
                    {wastedItems[currentIndex].fixable === false ? (
                        <>
                            <p>Is this item fresh?</p>
                            {wastedItems[currentIndex].fresh === undefined ||
                            wastedItems[currentIndex].fresh === null ? (
                                <div className="fixable_button">
                                    <Button
                                        variant="contained"
                                        color="success"
                                        onClick={() =>
                                            updateStatus("fresh", true)
                                        }
                                    >
                                        Yes
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        onClick={() =>
                                            updateStatus("fresh", false)
                                        }
                                    >
                                        No
                                    </Button>
                                </div>
                            ) : (
                                <h5>
                                    {wastedItems[currentIndex].fresh
                                        ? "Yes"
                                        : "No"}
                                </h5>
                            )}
                            {wastedItems[currentIndex].fresh === false ? (
                                <p>
                                    Please place this item in the in-store
                                    collection
                                </p>
                            ) : null}
                        </>
                    ) : null}
                    {currentIndex != wastedItems.length - 1 ? (
                        <Button
                            variant="contained"
                            color="success"
                            id="next_btn"
                            onClick={next}
                            disabled={
                                !(
                                    wastedItems[currentIndex].fixable == true ||
                                    wastedItems[currentIndex].fresh != null
                                )
                            }
                        >
                            Next Item
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            color="success"
                            id="next_btn"
                            onClick={() => setSumary(true)}
                            disabled={
                                !(
                                    wastedItems[currentIndex].fixable == true ||
                                    wastedItems[currentIndex].fresh != null
                                )
                            }
                        >
                            See Sumary
                        </Button>
                    )}
                    {showAlert ? (
                        <Alert severity={severity} sx={{ marginTop: "2%" }}>
                            {message}
                        </Alert>
                    ) : null}
                    <Button
                        variant="contained"
                        color="error"
                        id="cancel_btn"
                        onClick={props.handleClose}
                    >
                        Cancel
                    </Button>
                </section>
            ) : (
                <section className="summary_modal">
                    <h4 className="modal_title">Summary</h4>
                    <table>
                        <thead>
                            <tr>
                                <th>Product</th>
                                <th>Units wasted</th>
                                <th>Reason</th>
                                <th>Resellable</th>
                                <th>Fresh</th>
                            </tr>
                        </thead>
                        <tbody>
                            {wastedItems.map((product, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{product.name}</td>
                                        <td>{product.wasted}</td>
                                        <td>{product.reason}</td>
                                        <td>
                                            {product.fixable ? "Yes" : "No"}
                                        </td>
                                        <td>{product.fresh ? "Yes" : "No"}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>

                    <p>Wastage Image:</p>
                    <input
                        type="file"
                        id="image-upload"
                        accept="image/*"
                        onChange={(e) => handleImg(e.target.files[0])}
                        capture="environment"
                    />

                    <p>Your name:</p>
                    <input
                        type="text"
                        placeholder="Enter your name"
                        onChange={(e) => setUser(e.target.value)}
                        className="name_field"
                    />

                    <Button
                        variant="contained"
                        disabled={user == "" || img == null}
                        id="submit_waste"
                        onClick={submitForm}
                        color="success"
                    >
                        Submit form
                    </Button>
                    <Button
                        onClick={() => props.handleClose()}
                        className="close_modal_btn"
                        color="error"
                        variant="contained"
                    >
                        Close
                    </Button>
                    {showAlert ? (
                        <Alert severity={severity} sx={{ marginTop: "2%" }}>
                            {message}
                        </Alert>
                    ) : showTick ? (
                        <Tick />
                    ) : showLoader ? (
                        <Loader />
                    ) : null}
                </section>
            )}
        </Box>
    );
}
