import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
import { decryptData } from "../../../utils/cryptoUtils";
import { addTimes } from "../../../utils/add_two_times";
import ShiftChip from "../review_rotas/components/ShiftChip";
import AlertTitle from "@mui/material/AlertTitle";
const HR_URL = process.env.REACT_APP_API_URL;

function SingleRotaModel({
  setSingleRotaModel,
  singleRota,
  user,
  load_review_rotas,
}) {
  const [loading, setLoading] = useState(false);
  const [confirmPublish, setConfirmPublish] = useState(false);
  const [error, setError] = useState(false);
  const [staffData, setStaffData] = useState([])
  const [totalData, setTotalData] = useState(null)
  const [dates, setDates] = useState([])
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80vw",
    height: "90vh",
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
    overflowY: "scroll",
    overflowX: "hidden",
  };

  let { endDate, startDate, siteName, rotaId } = singleRota;

  var enumerateDaysBetweenDates = function (startDate, endDate) {
    var dates = [{ date: moment(startDate).format("YYYY-MM-DD"), total: 0, excBreak: 0 }];

    var currDate = moment(startDate).startOf('day');
    var lastDate = moment(endDate).startOf('day');

    while (currDate.add(1, 'days').diff(lastDate) <= 0) {
      dates.push({ date: currDate.clone().format("YYYY-MM-DD"), total: 0, excBreak: 0 });
    }

    return dates;
  };

  useEffect(() => {

    let dateArr = enumerateDaysBetweenDates(startDate, endDate)

    let staffing = singleRota.staff
    let weekTotal = 0;
    let weekTotalExcBr = 0;
    for (let s = 0; s < staffing.length; s++) {
      let totalMilliSeconds = 0;
      let _exclBreaks = 0;
      for (let h = 0; h < staffing[s].shifts.length; h++) {
        if (staffing[s].shifts[h].shiftId) {
          let sameDate = staffing[s].shifts.filter(
            (d, i) => d.date === staffing[s].shifts[h].date
          );

          if (staffing[s].shifts[h].noShow) {
            continue;
          }

          if (sameDate.length > 1) {
            let hasLeave = sameDate.filter(
              (d, i) => d.leaveType != null
            );

            if (hasLeave.length === 0) {
              var idx = sameDate.findIndex(
                (d, i) =>
                  d.shiftId === staffing[s].shifts[h].shiftId
              );
              totalMilliSeconds +=
                moment
                  .duration(sameDate[idx].breakTime)
                  .asMilliseconds() +
                moment
                  .duration(sameDate[idx].hoursWorked)
                  .asMilliseconds();

              _exclBreaks += moment
                .duration(sameDate[idx].hoursWorked)
                .asMilliseconds();

              var dateIdx = dateArr.findIndex((d) => d.date == moment(sameDate[idx].startTime).format("YYYY-MM-DD"))
              if (dateIdx > -1) {
                dateArr[dateIdx].excBreak = dateArr[dateIdx].excBreak + moment.duration(sameDate[idx].hoursWorked).asMilliseconds();
                dateArr[dateIdx].total = dateArr[dateIdx].total +
                  (moment.duration(sameDate[idx].breakTime).asMilliseconds() + moment.duration(sameDate[idx].hoursWorked).asMilliseconds())
              }
            }
          } else {
            totalMilliSeconds +=
              moment
                .duration(staffing[s].shifts[h].breakTime)
                .asMilliseconds() +
              moment
                .duration(staffing[s].shifts[h].hoursWorked)
                .asMilliseconds();

            _exclBreaks += moment
              .duration(staffing[s].shifts[h].hoursWorked)
              .asMilliseconds();

            var dateIdx = dateArr.findIndex((d) => d.date == moment(staffing[s].shifts[h].startTime).format("YYYY-MM-DD"))
            if (dateIdx > -1) {
              dateArr[dateIdx].excBreak = dateArr[dateIdx].excBreak + moment.duration(staffing[s].shifts[h].hoursWorked).asMilliseconds();
              dateArr[dateIdx].total = dateArr[dateIdx].total +
                (moment.duration(staffing[s].shifts[h].breakTime).asMilliseconds() + moment.duration(staffing[s].shifts[h].hoursWorked).asMilliseconds())
            }
          }
        }
      }
      let numOfMins = moment.duration(totalMilliSeconds).asMinutes();
      let _hours = numOfMins / 60;
      let rhours = Math.floor(_hours);
      let minutes = (_hours - rhours) * 60;
      let rminutes = Math.round(minutes);

      let numOfMinsEx = moment.duration(_exclBreaks).asMinutes();
      let _hoursEx = numOfMinsEx / 60;
      let rhoursEx = Math.floor(_hoursEx);
      let minutesEx = (_hoursEx - rhoursEx) * 60;
      let rminutesEx = Math.round(minutesEx);

      staffing[s].totalHours = `Total: ${rhours}hr(s) ${rminutes}min(s)`
      staffing[s].workedHours = `Excl Breaks: ${rhoursEx}hr(s) ${rminutesEx}min(s)`
      weekTotal += numOfMins;
      weekTotalExcBr += numOfMinsEx;
    }

    let _hours2 = weekTotal / 60;
    let rhours2 = Math.floor(_hours2);
    let minutes2 = (_hours2 - rhours2) * 60;
    let rminutes2 = Math.round(minutes2);

    let _hoursEx2 = weekTotalExcBr / 60;
    let rhoursEx2 = Math.floor(_hoursEx2);
    let minutesEx2 = (_hoursEx2 - rhoursEx2) * 60;
    let rminutesEx2 = Math.round(minutesEx2);

    setTotalData(
      {
        overallTotal: `Week Total: ${rhours2}hr(s) ${rminutes2}min(s)`,
        excBreakTotal: `Excl. Breaks: ${rhoursEx2}hr(s) ${rminutesEx2}min(s)`
      }
    )

    let targets = []
    axios.get(`${HR_URL}/rota/kpi?siteId=${singleRota.eposId}`).then((resp)=>{
      targets = Object.values(resp.data.hours)
      console.log(targets)
      for (let d = 0; d < dateArr.length; d++) {
        let numOfMins3 = moment.duration(dateArr[d].total).asMinutes();
        let _hours3 = numOfMins3 / 60;
        let rhours3 = Math.floor(_hours3);
        let minutes3 = (_hours3 - rhours3) * 60;
        let rminutes3 = Math.round(minutes3);
  
        let numOfMinsEx3 = moment.duration(dateArr[d].excBreak).asMinutes();
        let _hoursEx3 = numOfMinsEx3 / 60;
        let rhoursEx3 = Math.floor(_hoursEx3);
        let minutesEx3 = (_hoursEx3 - rhoursEx3) * 60;
        let rminutesEx3 = Math.round(minutesEx3);
  
        dateArr[d].total = `${rhours3}hr(s) ${rminutes3}min(s)`
        dateArr[d].excBreak = `${rhoursEx3}hr(s) ${rminutesEx3}min(s)`
        dateArr[d].target = targets[d];
        dateArr[d].targetMet = rhours3-targets[d];
      }
      setDates(dateArr)
      console.log(dateArr)
    }).catch((err)=>{
      console.log(err)
    })

    
    setStaffData(staffing)
  }, [])

  const publish = async () => {
    let data = {
      user: user,
      rotaId: rotaId,
      reviewed: true,
      published: true,
    };
    setLoading(true);
    try {
      await axios.post(`${HR_URL}/rota/publish`, data);
      setSingleRotaModel(false);
      load_review_rotas();
    } catch (error) {
      console.log(error);
      setError(error.message);
    }
    setLoading(false);
  };
  const republish = async () => {
    let data = {
      user: user,
      rotaId: rotaId,
      reviewed: true,
      published: true,
    };
    setLoading(true);
    try {
      await axios.post(`${HR_URL}/rota/republish`, data);
      setSingleRotaModel(false);
      load_review_rotas();
    } catch (error) {
      console.log(error);
      setError(error.message);
    }
    setLoading(false);
  };

  return (
    <>
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h4"
          marginBottom={2}
          component="h2"
        >
          {siteName} Rota
        </Typography>

        {error && (
          <Alert severity="error" sx={{ marginBottom: "20px" }}>
            <AlertTitle>Error</AlertTitle>
            {error}
          </Alert>
        )}

        {!confirmPublish ? (
          <div>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
                    >
                      Full Name
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        verticalAlign: "center",
                      }}
                      rowSpan={2}
                    >
                      Saturday
                    </TableCell>
                    <TableCell align="center" rowSpan={2}>
                      Sunday
                    </TableCell>
                    <TableCell align="center" rowSpan={2}>
                      Monday
                    </TableCell>
                    <TableCell align="center" rowSpan={2}>
                      Tuesday
                    </TableCell>
                    <TableCell align="center" rowSpan={2}>
                      Wednesday
                    </TableCell>
                    <TableCell align="center" rowSpan={2}>
                      Thursday
                    </TableCell>
                    <TableCell align="center" rowSpan={2}>
                      Friday
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {singleRota &&
                    staffData?.map((row, index) => (
                      <TableRow
                        key={row.employeeDetailsId}
                        sx={{
                          "& td > div:not(:first-child)": {
                            marginTop: "13px",
                          },
                        }}
                      >
                        <TableCell align="center">
                          <b>{decryptData(
                            row.firstName,
                            process.env.REACT_APP_EMP_KEY
                          )} {" "}
                            {decryptData(
                              row.lastName,
                              process.env.REACT_APP_EMP_KEY
                            )}</b><br />
                          {row.totalHours}<br />
                          {row.workedHours}
                        </TableCell>
                        <TableCell align="center" style={{ borderLeft: "1px solid #f4f4f4" }}>
                          {row.shifts.map((shift, index) =>
                            moment(shift.startTime).format("dddd") ===
                              "Saturday" ? (
                              <ShiftChip shift={shift} key={index} />
                            ) : (
                              ""
                            )
                          )}
                        </TableCell>
                        <TableCell align="center" style={{ borderLeft: "1px solid #f4f4f4" }}>
                          {row.shifts.map((shift, index) =>
                            moment(shift.startTime).format("dddd") ===
                              "Sunday" ? (
                              <ShiftChip shift={shift} key={index} />
                            ) : (
                              ""
                            )
                          )}
                        </TableCell>
                        <TableCell align="center" style={{ borderLeft: "1px solid #f4f4f4" }}>
                          {row.shifts.map((shift, index) =>
                            moment(shift.startTime).format("dddd") ===
                              "Monday" ? (
                              <ShiftChip shift={shift} key={index} />
                            ) : (
                              ""
                            )
                          )}
                        </TableCell>
                        <TableCell align="center" style={{ borderLeft: "1px solid #f4f4f4" }}>
                          {row.shifts.map((shift, index) =>
                            moment(shift.startTime).format("dddd") ===
                              "Tuesday" ? (
                              <ShiftChip shift={shift} key={index} />
                            ) : (
                              ""
                            )
                          )}
                        </TableCell>
                        <TableCell align="center" style={{ borderLeft: "1px solid #f4f4f4" }}>
                          {row.shifts.map((shift, index) =>
                            moment(shift.startTime).format("dddd") ===
                              "Wednesday" ? (
                              <ShiftChip shift={shift} key={index} />
                            ) : (
                              ""
                            )
                          )}
                        </TableCell>
                        <TableCell align="center" style={{ borderLeft: "1px solid #f4f4f4" }}>
                          {row.shifts.map((shift, index) =>
                            moment(shift.startTime).format("dddd") ===
                              "Thursday" ? (
                              <ShiftChip shift={shift} key={index} />
                            ) : (
                              ""
                            )
                          )}
                        </TableCell>
                        <TableCell align="center" style={{ borderLeft: "1px solid #f4f4f4" }}>
                          {row.shifts.map((shift, index) =>
                            moment(shift.startTime).format("dddd") ===
                              "Friday" ? (
                              <ShiftChip shift={shift} key={index} />
                            ) : (
                              ""
                            )
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  {totalData ?
                    <>
                      <TableRow style={{"borderTop":"3px solid black"}}>
                        <TableCell align="center" >
                          <span>{totalData.overallTotal}</span>
                        </TableCell>
                        {dates.map(function (d, i) {
                          return (
                            <TableCell align="center" style={{ borderLeft: "1px solid #f4f4f4" }}>
                              <span>{d.total}</span>
                            </TableCell>
                          )
                        })}
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" >
                          <span>{totalData.excBreakTotal}</span>
                        </TableCell>
                        {dates.map(function (d, i) {
                          return (
                            <TableCell align="center" style={{ borderLeft: "1px solid #f4f4f4" }}>
                              <span>{d.excBreak}</span>
                            </TableCell>
                          )
                        })}
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" >
                          <span>± Target</span>
                        </TableCell>
                        {dates.map(function (d, i) {
                          return (
                            <TableCell align="center" style={{ borderLeft: "1px solid #f4f4f4" }}>
                              <span style={d.targetMet<0?{color:"green"}:{color:"red"}}>{d.targetMet} hrs</span><br/>
                              <span>(Target {d.target})</span>
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    </>
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          <div>
            <Typography
              id="modal-modal-title"
              variant="body1"
              marginBottom={5}
              component="h2"
            >
              You are about to{" "}
              {singleRota.published ? "republish " : "publish "}
              this rota. All employees on the rota will receive an email with
              their shifts, so please ensure that all entered shifts are
              accurate before proceeding. If you are happy to proceed click
              confirm.
            </Typography>
          </div>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "3%",
          }}
        >
          <Button
            variant="contained"
            color="error"
            onClick={() => setSingleRotaModel(false)}
          >
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            id="addNote-btn"
            loading={loading}
            onClick={() => {
              console.log(singleRota);

              !confirmPublish
                ? setConfirmPublish(true)
                : singleRota.published
                  ? republish()
                  : publish();
            }}
          >
            {confirmPublish
              ? "Confirm"
              : singleRota.published
                ? "Republish"
                : "Publish"}
          </Button>
        </div>
      </Box>
    </>
  );
}

export default SingleRotaModel;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",
  border: "none",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
  maxHeight: "80vh",
  overflowY: "scroll",
  "@media(max-width: 690px)": {
    width: "90vw",
    maxHeight: "90vh",
  },
};
