import { useEffect, useState } from "react";
import IndvDate from "./IndvDate";
import RemoveEmployee from "./modal/RemoveEmployee";
import Modal from "@mui/material/Modal";
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Logo from "./Tubbees_Logo.webp";
import RTLogo from "./RT_Logo.png";
import FoodLogo from "./subway.png";
import NitroLogo from "./NITRO_SOLUTIONS.png"

export default function IndvWeek({
    staffMember,
    site,
    siteName,
    fullDates,
    currentDate,
    rotaId,
    reloadData,
    confirmedDates,
}) {
    //Worked Hours
    const [workedHours, setWorkedHours] = useState(null);
    const [exclBreaks, setExclBreaks] = useState(null);

    //Additional Employee
    const [fromOtherSite, setFromOtherSite] = useState(false);
    const [removeEmployeeModal, setRemoveEmployeeModal] = useState(false);

    //toggle row collapse
    const [collapseRow, setCollapseRow] = useState(false);

    useEffect(() => {
        if (staffMember.eposId != site && (staffMember.eposId != 0 || staffMember.eposId != 1)) {
            setFromOtherSite(true);
        }

        let totalMilliSeconds = 0;
        let _exclBreaks = 0;

        for (let h = 0; h < staffMember.worked.length; h++) {
            if (staffMember.worked[h].shiftId) {
                let sameDate = staffMember.worked.filter(
                    (d, i) => d.date === staffMember.worked[h].date
                );

                if (staffMember.worked[h].noShow) {
                    continue;
                }

                if (sameDate.length > 1) {
                    let hasLeave = sameDate.filter(
                        (d, i) => d.leaveType != null
                    );

                    if (hasLeave.length === 0) {
                        var idx = sameDate.findIndex(
                            (d, i) =>
                                d.shiftId === staffMember.worked[h].shiftId
                        );
                        totalMilliSeconds +=
                            moment
                                .duration(sameDate[idx].breakTime)
                                .asMilliseconds() +
                            moment
                                .duration(sameDate[idx].hoursWorked)
                                .asMilliseconds();

                        _exclBreaks += moment
                            .duration(sameDate[idx].hoursWorked)
                            .asMilliseconds();
                    }
                } else {
                    totalMilliSeconds +=
                        moment
                            .duration(staffMember.worked[h].breakTime)
                            .asMilliseconds() +
                        moment
                            .duration(staffMember.worked[h].hoursWorked)
                            .asMilliseconds();

                    _exclBreaks += moment
                        .duration(staffMember.worked[h].hoursWorked)
                        .asMilliseconds();
                }
            }
        }

        let numOfMins = moment.duration(totalMilliSeconds).asMinutes();
        let _hours = numOfMins / 60;
        let rhours = Math.floor(_hours);
        let minutes = (_hours - rhours) * 60;
        let rminutes = Math.round(minutes);

        let numOfMinsEx = moment.duration(_exclBreaks).asMinutes();
        let _hoursEx = numOfMinsEx / 60;
        let rhoursEx = Math.floor(_hoursEx);
        let minutesEx = (_hoursEx - rhoursEx) * 60;
        let rminutesEx = Math.round(minutesEx);

        setWorkedHours(`${rhours}hr(s) ${rminutes}min(s)`);
        setExclBreaks(`${rhoursEx}hr(s) ${rminutesEx}min(s)`);
    }, [staffMember]);

    return (
        <>
            <tr
                className="employee_row"
                id={`employee-row-${staffMember.employeeDetailsId}`}
            >
                {!collapseRow ? (
                    <>
                        <td
                            colSpan={2}
                            className="name_cell"
                            style={{ position: "relative" }}
                        >
                            <VisibilityIcon
                                onClick={() => setCollapseRow(true)}
                                style={{
                                    position: "absolute",
                                    left: "5px",
                                    cursor: "pointer",
                                }}
                            />
                            {staffMember.icon == "TB" ? (
                                <img
                                    src={Logo}
                                    style={{
                                        width: "40px",
                                        position: "absolute",
                                        right: "5px",
                                    }}
                                />
                            ) : staffMember.icon == "FD" ? (
                                <img
                                    src={FoodLogo}
                                    style={{
                                        width: "40px",
                                        position: "absolute",
                                        right: "5px",
                                    }}
                                />
                            ) : staffMember.icon == "MG" ? (
                                <img
                                    src={RTLogo}
                                    style={{
                                        border: "2px solid red",
                                        borderRadius: "50%",
                                        width: "40px",
                                        position: "absolute",
                                        right: "5px",
                                    }}
                                />
                            ) : staffMember.icon == "NS" ?
                                <img src={NitroLogo}
                                    style={{
                                        width: "40px",
                                        position: "absolute",
                                        right: "5px",
                                    }} />
                                : (
                                    <img
                                        src={RTLogo}
                                        style={{
                                            width: "40px",
                                            position: "absolute",
                                            right: "5px",
                                        }}
                                    />
                                )}
                            <>
                                {!fromOtherSite || site == 24753 || site == 2 ? (
                                    <p className="staff_name">
                                        {staffMember.firstName.trim()}&nbsp;
                                        {staffMember.lastName.trim()}
                                    </p>
                                ) : (
                                    <p
                                        className="staff_name"
                                        onClick={() =>
                                            setRemoveEmployeeModal(true)
                                        }
                                        style={{ cursor: "pointer" }}
                                    >
                                        <u style={{ color: "#126e04" }}>
                                            {staffMember.firstName}{" "}
                                            {staffMember.lastName}
                                        </u>
                                    </p>
                                )}
                            </>
                            <p className="staff_position">
                                {staffMember.position}
                            </p>

                            <p
                                className="hours_worked"
                                style={{ color: "#4a4a4f" }}
                            >
                                {workedHours != "0hr(s) 0min(s)" ? (
                                    <b>
                                        {workedHours} |{" "}
                                        <span>Excl Breaks: {exclBreaks}</span>
                                    </b>
                                ) : null}
                            </p>
                        </td>
                        {fullDates.map(function (day) {
                            let shifts = staffMember.worked.filter(
                                (x) => x.date === day
                            );

                            //Sorts shifts in order of start time
                            shifts.sort((a, b) =>
                                a.startTime > b.startTime
                                    ? 1
                                    : b.startTime > a.startTime
                                        ? -1
                                        : 0
                            );

                            return (
                                <IndvDate
                                    shifts={shifts}
                                    currentDate={currentDate}
                                    date={day}
                                    rotaId={rotaId}
                                    siteName={siteName}
                                    employee={staffMember}
                                    reloadData={reloadData}
                                    confirmedDates={confirmedDates}
                                />
                            );
                        })}{" "}
                    </>
                ) : (
                    <>
                        <td
                            style={{
                                display: "flex",
                            }}
                        >
                            <VisibilityOffIcon
                                color="error"
                                onClick={() => setCollapseRow(false)}
                                style={{
                                    display: "flex",
                                    marginLeft: "5px",
                                    marginRight: "10px",
                                    cursor: "pointer",
                                }}
                            />
                            <p
                                className="staff_name"
                                style={{
                                    margin: 0,
                                    padding: 0,
                                    fontWeight: 600,
                                }}
                            >
                                {staffMember.firstName.trim()}&nbsp;
                                {staffMember.lastName.trim()}
                            </p>
                        </td>
                        <td></td>
                    </>
                )}

                {/* MODALS */}
                <Modal
                    open={removeEmployeeModal}
                    aria-labelledby="Remove Employee Modal"
                    aria-describedby="Remove Employee Modal"
                >
                    <RemoveEmployee
                        siteName={siteName}
                        setRemoveEmployeeModal={setRemoveEmployeeModal}
                        firstName={staffMember.firstName}
                        lastName={staffMember.lastName}
                        shifts={staffMember.worked}
                        reloadData={reloadData}
                        employee={staffMember}
                        rotaId={rotaId}
                    />
                </Modal>
            </tr>
        </>
    );
}
