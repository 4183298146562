import { useState } from "react";
import SpeakerNotesOffIcon from "@mui/icons-material/SpeakerNotesOff";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import Modal from "@mui/material/Modal";
import NotesModal from "./Modals/NotesModal";
import PaymentModal from "./Modals/PaymentModal";

export default function IndvDiscrep(props) {
  const discrepancy = props.discrepancy;
  const charges = props.discrepancy.previousCharges;
  //   modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openPay, setOpenPay] = useState(false);
  const handleOpenPay = () => setOpenPay(true);
  const handleClosePay = () => setOpenPay(false);


  return (
    <>
      <tr key={discrepancy.eposStaffId}>
        <td>{discrepancy.reason}</td>
        <th>
          {discrepancy.investigationNotes.length > 0 ? (
            <SpeakerNotesIcon
              color="primary"
              onClick={handleOpen}
              sx={{ cursor: "pointer" }}
            />
          ) : (
            <SpeakerNotesOffIcon />
          )}
        </th>
        <td>{discrepancy.total}</td>
        {parseFloat(discrepancy.amountPaid) > 0 ? (
          <td onClick={handleOpenPay} style={{ cursor: "pointer" }}>
            {discrepancy.amountPaid}
          </td>
        ) : (
          <td>{discrepancy.amountPaid}</td>
        )}
        <td>{discrepancy.totalLeft}</td>
        {discrepancy.currentCharge ? (
          <td>£{discrepancy.currentCharge.amount}</td>
        ) : (
          <td>0</td>
        )}
        <td>
          {new Date(discrepancy.transactionDate).toLocaleDateString("en-GB")}
        </td>
      </tr>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <NotesModal notes={discrepancy.investigationNotes} />
      </Modal>
      <Modal
        open={openPay}
        onClose={handleClosePay}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <PaymentModal charges={charges} />
      </Modal>
    </>
  );
}
