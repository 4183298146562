import { useEffect, useState, Component } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import PeopleIcon from "@mui/icons-material/People";
import AddUser from "../../../AddUser";
import Users from "./Users";
import DevNav from "../../nav/DevNav";
import { Navigate } from "react-router-dom";
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import ErrorBoundary from '../../../error/ErrorBoundary'
import ManagersEmail from "./ManagersEmails";
import axios from "axios"

const drawerWidth = "12vw";

export default function UsersHome(props) {
  const [role, setRole] = useState("");
  const [user, setUser] = useState("");
  const [addUser, setAddUser] = useState(true);
  const [users, setUsers] = useState(false);
  const [value, setValue] = useState(1)
  const [pendingAlerts, setPendingAlerts] = useState(0)

  useEffect(() => {
    let userD = localStorage.getItem("userDetails");
    let user = JSON.parse(userD);
    setUser(user.user.user);
    setRole(user.user.role);
    let getToken = localStorage.getItem("auth-token");
    const config = {
      headers: { "Content-Type": "application/json", "x-auth-token": getToken },
    };
  

    axios.get(`${process.env.REACT_APP_API_URL}/onBoard/pending/managers`, config)
            .then((res) => {
                setPendingAlerts(res.data.length)
            }).catch((err) => {
                console.log(err)
            })
  }, []);

  const handleChange = (key) => {
    setValue(key + 1)
  };

  return (
    <main className="users__dev">
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, padding: "0" }}
        >
          <Toolbar sx={{ width: "100vw", backgroundColor: "#2c53a0" }}>
            <DevNav  />
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
            },
            ["@media(max-width: 600px)"]: {
              width: "10vw",
            },
          }}
        >
          <Toolbar />
          <Box sx={{ overflow: "auto", marginTop: "12vh" }}>
            <List>
              {["Add User", "Users", "Manager emails"].map((text, index) => (
                <ListItem
                  key={text}
                  disablePadding
                  onClick={() => handleChange(index)}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      {index === 0 ? (
                        <GroupAddIcon sx={{ margin: "0" }} />
                      ) : index === 1 ? (
                        <PeopleIcon sx={{ margin: "0" }} />
                      ) : index === 2 ? (
                        <>
                        {pendingAlerts>0?
                        <>
                        <span style={{ zIndex:"0",position: "absolute",right: "5%", backgroundColor: "red", color: "#fff", borderRadius: "50%", width: "1.2vw", height: "1.2vw", textAlign: "center", }}>{pendingAlerts}</span>
                        </>:<></>}
                        <MarkEmailUnreadIcon />
                        </>
                      ) : (
                        null
                      )}
                    </ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: "10vh" }}>
          <Toolbar />
          {value === 1 ? (
            <ErrorBoundary>
              <AddUser />
            </ErrorBoundary>
          ) : value === 2 ? (
            <ErrorBoundary>
              <Users />
            </ErrorBoundary>
          ) : value === 3 ? (
            <ErrorBoundary>
              <ManagersEmail />
            </ErrorBoundary>
          ): null}
        </Box>
      </Box>
    </main>
  );
}
