import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";
//Components
import Loader from "../../../animations/Loader";
//MUI
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import { Alert } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
//Utils
import { numberValidation } from "../../../utils/Validation";
//styling
import "./product-checker.scss";

export default function ProductChecker() {
    const url = process.env.REACT_APP_DEPPLOYED;
    // const url = "http://10.0.0.174:4022";
    const [siteId, setSiteId] = useState(null);
    const [siteName, setSiteName] = useState("");
    const [searchedBarcode, setSearchedBarcode] = useState(null);
    const [foundProducts, setFoundProducts] = useState([]);
    //Alerts & Loader
    const [alert, setAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [severity, setSeverity] = useState("");
    const [disable, setDisable] = useState(false);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        //Get Site Id & name from localstorage and set in state
        let _siteId = localStorage.getItem("siteID");
        let _siteName = localStorage.getItem("site").replaceAll('"', "");
        setSiteId(_siteId);
        setSiteName(_siteName);
    }, []);

    const handleAlert = (message, severity, duration = 4000) => {
        setAlertMessage(message);
        setSeverity(severity);
        setAlert(true);
        setTimeout(() => {
            setAlert(false);
            setAlertMessage("");
            setSeverity("");
        }, duration);
    };

    const handleSearchProduct = async () => {
        setFoundProducts([]);
        setDisable(true);
        setLoader(true);
        await axios
            .get(`${url}/eposProducts/check/${siteId}/${searchedBarcode}`)
            .then((res) => {
                let _productArray = res.data;
                if (_productArray.length === 0) {
                    handleAlert("No Product(s) Not Found", "error");
                } else if (_productArray.length > 1) {
                    handleAlert("Multiple Products Found", "success");
                    setFoundProducts(_productArray);
                } else {
                    handleAlert("Product Found", "success");
                    setFoundProducts(_productArray);
                }
                setDisable(false);
                setLoader(false);
            })
            .catch((err) => {
                console.log(err);
                handleAlert(err.message, "error");
                setDisable(false);
                setLoader(false);
            });
    };

    return (
        <main className="product-checker-page">
            <section className="product-checker-scan-section">
                <div className="input-section">
                    <label>Scan Product Barcode</label>
                    <input
                        type="number"
                        placeholder="Scan Barcode"
                        onChange={(e) => setSearchedBarcode(e.target.value)}
                        autoFocus={true}
                        onKeyDown={(e) => numberValidation(e)}
                        min="0"
                    />
                </div>
                <div className="input-section">
                    <Button
                        variant="contained"
                        disabled={disable || !searchedBarcode}
                        onClick={handleSearchProduct}
                    >
                        <SearchIcon />
                    </Button>
                </div>
            </section>
            <section className="alert-section">
                {alert ? (
                    <Alert severity={severity}>{alertMessage}</Alert>
                ) : null}
            </section>
            {!loader ? (
                foundProducts.length > 0 ? (
                    <section className="product-checker-table-container">
                        {foundProducts.map((product, i) => {
                            return (
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        className="product-name"
                                    >
                                        {product.name}
                                    </AccordionSummary>
                                    <table className="product-checker-table">
                                        <tbody>
                                            <tr>
                                                <th>Barcode</th>
                                                <td>{product.barcode}</td>
                                            </tr>
                                            <tr>
                                                <th>Sale Price</th>
                                                <td>
                                                    £
                                                    {parseFloat(
                                                        product.salePrice
                                                    ).toFixed(2)}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Cost Price</th>
                                                <td>
                                                    £
                                                    {parseFloat(
                                                        product.costPrice
                                                    ).toFixed(2)}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Current Stock</th>
                                                <td>{product.currentStock}</td>
                                            </tr>
                                            <tr>
                                                <th>On Order</th>
                                                <td>{product.onOrder}</td>
                                            </tr>
                                            <tr>
                                                <th>Margin</th>
                                                <td>{product.margin}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    {product?.wastage?.length > 0 ? (
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th
                                                        colSpan={3}
                                                        className="wastage-heading"
                                                    >
                                                        Wastage
                                                    </th>
                                                </tr>
                                                <tr>
                                                    {" "}
                                                    <th>Qty</th>
                                                    <th>Reason</th>
                                                    <th>Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {product.wastage.map(
                                                    (wasteItem, i2) => {
                                                        return (
                                                            <tr key={i2}>
                                                                <td>
                                                                    {
                                                                        wasteItem.amount
                                                                    }
                                                                </td>
                                                                {""}
                                                                <td>
                                                                    {
                                                                        wasteItem.reason
                                                                    }
                                                                </td>{" "}
                                                                <td>
                                                                    {moment(
                                                                        wasteItem.submittedTime
                                                                    ).format(
                                                                        "DD/MM/YY - HH:mm"
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                )}
                                            </tbody>
                                        </table>
                                    ) : null}{" "}
                                    <div className="btn-section">
                                        <Link
                                            to={`https://www.eposnowhq.com/products/${product.eposId}/edit`}
                                            target="_blank"
                                        >
                                            <Button variant="contained">
                                                Go to Epos
                                            </Button>
                                        </Link>
                                        <Link
                                            to={`https://www.nitropos.co.uk/racetrack?site=${siteName}&barcodes=${product.barcode}`}
                                        >
                                            <Button
                                                variant="contained"
                                                color="warning"
                                            >
                                                Go to SEL
                                            </Button>{" "}
                                        </Link>
                                        {/* //! Button for Mark product as needing a stock check for when required */}
                                        {/* <Button
                                            variant="contained"
                                            color="success"
                                        >
                                            Mark to Stock Check - TBD
                                        </Button>{" "} */}
                                    </div>
                                </Accordion>
                            );
                        })}
                    </section>
                ) : null
            ) : (
                <Loader />
            )}
        </main>
    );
}
