import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "45vw",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
    maxHeight: "70vh",
};

export default function PrintModal({
    rotaId,
    handleClosePrint,
    setPublishModal,
    currentDate,
    site,
}) {
    return (
        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Would you like to go to print view to print this rota?
            </Typography>

            <Typography
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    gap: "10px",
                    marginTop: "10px",
                }}
            >
                <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                        handleClosePrint(false);
                        setPublishModal(false);
                    }}
                    style={{ width: "100%" }}
                >
                    No
                </Button>
                <Link
                    to={`print-rota/${rotaId}/${site}/${currentDate}`}
                    target="_blank"
                    style={{ width: "100%" }}
                >
                    <Button
                        variant="contained"
                        color="success"
                        onClick={() => {
                            handleClosePrint(false);
                            setPublishModal(false);
                        }}
                        style={{ width: "100%" }}
                    >
                        Yes
                    </Button>
                </Link>
            </Typography>
        </Box>
    );
}
