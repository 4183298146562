import "./rota.scss";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import Rota from "./Rota";
import moment from "moment";
import { decryptData } from "./cryptoUtils";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { getEmployeesByCompany } from "../../utils/Api";
import { Button, Alert } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import PublishIcon from "@mui/icons-material/Publish";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import PrintIcon from "@mui/icons-material/Print";
import Modal from "@mui/material/Modal";
import NotesModal from "./modal/NotesModal";
import ReactToPrint from "react-to-print";
import { getCompanies } from "../../utils/Api";
import SquareIcon from "@mui/icons-material/Square";
import StrollerIcon from "@mui/icons-material/Stroller";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import ModeNightIcon from "@mui/icons-material/ModeNight";
import Loader from "../../animations/Loader";
import Publish from "./modal/Publish";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Box from "@mui/material/Box";
import { Link, useNavigate } from "react-router-dom";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import ResetRota from "./modal/ResetRota";
import ConfirmPrintRotaModel from "./modal/ConfirmPrintRotaModel";
import { addTimes } from "../../utils/add_two_times";
import ContractedHoursUsersModel from "./modal/ContractedHoursUsersModel";

export default function RotaHome({ managerInfo }) {
  const navigate = useNavigate();
  //Main - Admin
  const url = "https://nitropos-backend.nitrosolutions.co.uk";
  const hrUrl = process.env.REACT_APP_API_URL;
  // const hrUrl = "http://10.0.0.174:4030";
  const [token, setToken] = useState("");
  const config = {
    headers: { "Content-Type": "application/json", "x-auth-token": token },
  };
  const componentRef = useRef();
  const myRefname = useRef(null);

  //User
  const [user, setUser] = useState("");

  //Sites
  const [managedSites, setManagedSites] = useState([]);
  const [siteName, setSiteName] = useState("");
  const [siteId, setSiteId] = useState("");
  const [oneSiteManager, setOneSiteManager] = useState(false);
  const [addEmployeeSites, setAddEmployeeSites] = useState([]);

  //Dates
  const [currentDate, setCurrentDay] = useState(moment().format());
  const [firstWeekDay, setFirstWeekDay] = useState("");
  const [previousWeek, setPreviousWeek] = useState(false);
  const [isCurrentWeek, setIsCurrentWeek] = useState(false);
  const [shortDates, setShortDates] = useState([]);
  const [fullDates, setFullDates] = useState([]);

  //Rota
  const [rotaId, setRotaId] = useState("");
  const [rotaNotes, setRotaNotes] = useState([]);
  const [published, setPublished] = useState(false);
  const [UnderReview, setUnderReview] = useState(false);
  const [confirmedDates, setConfirmedDates] = useState({});

  //Staff
  const [staffList, setStaffList] = useState([]);
  const [tempStaffList, setTempStaffList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [addEmployeeId, setAddEmployeeId] = useState("");
  const [lastEmployeeId, setLastEmployeeId] = useState(null);
  const [overOrLessContractedHoursStaff, setOverOrLessContractedHoursStaff] =
    useState([]);
  //Filters
  const [brands, setBrands] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState("");

  //Alerts
  const [employeeAlert, setEmployeeAlert] = useState(false);
  const [employeeMessage, setEmployeeMessage] = useState("");
  const [employeeSeverity, setEmployeeSeverity] = useState("");
  const [loader, setLoader] = useState(false);

  //Modals
  const [noteModal, setNoteModal] = useState(false);
  const [publishModal, setPublishModal] = useState(false);
  const [resetRota, setResetRota] = useState(false);
  const [ShowPrintRota, setShowPrintRota] = useState(false);
  const [ShowContractedHours, setShowContractedHours] = useState(false);
  const [targetHours, setTargetHours] = useState(null);

  //ON PAGE RENDER / RELOAD
  useEffect(() => {
    const InitialLoadFunction = async () => {
      //updates moment to get first day of the week Saturday
      //first week of the year must contained 1st of January
      moment.updateLocale("en-UK", {
        week: {
          dow: 6,
          doy: 12,
        },
      });
      setStaffList([]);
      setTempStaffList([]);
      //If only one site manager will set this to their site ID
      let oneSite = "";
      let siteID = "";
      //Set auth token
      let getToken = localStorage.getItem("auth-token");
      setToken(getToken);

      //Get user save to state
      let userD = localStorage.getItem("userDetails");
      let user = JSON.parse(userD);
      setUser(user.user.user);

      //Get Sites specific for logged in manager
      //If manager with one site sets single siteName and siteID
      //If manager with multiple site sets array of sites which can be selected
      await axios
        .post(`${url}/eposSQL/getSitesByName`, {
          sites: managerInfo && managerInfo.sites,
        })
        .then((res) => {
          if (res.data.length === 1) {
            oneSite = res.data[0].eposId;
            siteID = res.data[0].eposId;
            setSiteId(res.data[0].eposId);
            setSiteName(res.data[0].siteName);
            setOneSiteManager(true);
          } else {
            var sitesArr = res.data;
            sitesArr.push({ siteName: "RTMS", eposId: 1 });
            sitesArr.push({ siteName: "Speed Queen", eposId: 0 });
            sitesArr.push({ siteName: "Nitro Solutions", eposId: 2 });
            setManagedSites(sitesArr);
          }
        })
        .catch((err) => {
          console.log(err);
        });

      //Set Dates
      let weekShortDates = [];
      let weekFullDates = [];
      for (let w = 0; w <= 6; w++) {
        if (w === 0) {
          setFirstWeekDay(moment().weekday(0).format("DD-MM-YYYY"));
        }
        weekShortDates.push(moment().weekday(w).format("dddd - Do"));
        weekFullDates.push(moment().weekday(w).format("YYYY-MM-DD"));
      }

      setShortDates(weekShortDates);
      setFullDates(weekFullDates);

      //Set site list for adding employees
      let allSites = await getCompanies(getToken);
      allSites = allSites.filter((item) => item.eposId != siteId);
      setAddEmployeeSites(allSites);

      // Bring in Rota and Staff Data for one site manage or if siteId already selected
      if (oneSite || siteId) {
        await axios
          .post(
            `${hrUrl}/rota/employees/week`,
            {
              siteId: siteId ? siteId : siteID,
              start: weekFullDates[0],
              end: weekFullDates[6],
              user: user,
              nitroPos: true,
            },
            {
              headers: {
                "Content-Type": "application/json",
                "x-auth-token": getToken,
              },
            }
          )
          .then(async (res) => {
            setPublished(res.data.rota.published);
            setUnderReview(res.data.rota.reviewed);
            setRotaId(res.data.rota.rotaId);
            setRotaNotes(res.data.rota.notes);
            setConfirmedDates(res.data.rota.confirmedDates);
            setTargetHours(res.data.targetHours);
            //To find out if the week is the current week
            for (let i = 0; i < weekFullDates.length; i++) {
              let findDate = weekFullDates.some(
                (day) => day === moment().format("YYYY-MM-DD")
              );
              findDate ? setIsCurrentWeek(true) : setIsCurrentWeek(false);
            }
            const decryptEmployees = await res.data.staff.map((employee) => ({
              ...employee,
              firstName: decryptData(
                employee.firstName,
                process.env.REACT_APP_EMP_KEY
              ),
              lastName: decryptData(
                employee.lastName,
                process.env.REACT_APP_EMP_KEY
              ),
              position: employee.position,
              worked: employee.worked,
            }));
            let sorted1 = decryptEmployees.sort(
              (a, b) =>
                a.priority - b.priority ||
                a.roleId - b.roleId ||
                a.firstName.localeCompare(b.firstName)
            );
            setStaffList(sorted1);
            setTempStaffList(sorted1);

            let brandsArr = [];
            let rolesArr = [];
            for (let i = 0; i < res.data.staff.length; i++) {
              if (!brandsArr.includes(res.data.staff[i].name)) {
                brandsArr.push(res.data.staff[i].name);
              }
              if (!rolesArr.includes(res.data.staff[i].position)) {
                rolesArr.push(res.data.staff[i].position);
              }
            }
            setBrands(brandsArr);
            setRoles(rolesArr);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    };
    InitialLoadFunction();
  }, []);

  //Reload Data
  const reloadData = async (employeeId) => {
    //updates moment to get first day of the week Saturday
    //first week of the year must contained 1st of January
    moment.updateLocale("en-UK", {
      week: {
        dow: 6,
        doy: 12,
      },
    });

    setStaffList([]);
    setTempStaffList([]);
    await axios
      .post(
        `${hrUrl}/rota/employees/week`,
        {
          siteId: siteId,
          start: fullDates[0],
          end: fullDates[6],
          user: user,
          nitroPos: true,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
          },
        }
      )
      .then(async (res) => {
        setPublished(res.data.rota.published);
        setUnderReview(res.data.rota.reviewed);

        setRotaId(res.data.rota.rotaId);
        setRotaNotes(res.data.rota.notes);
        setConfirmedDates(res.data.rota.confirmedDates);
        //To find out if the week is the current week
        for (let i = 0; i < fullDates.length; i++) {
          let findDate = fullDates.some(
            (day) => day === moment().format("YYYY-MM-DD")
          );
          findDate ? setIsCurrentWeek(true) : setIsCurrentWeek(false);
        }
        const decryptEmployees = await res.data.staff.map((employee) => ({
          ...employee,
          firstName: decryptData(
            employee.firstName,
            process.env.REACT_APP_EMP_KEY
          ),
          lastName: decryptData(
            employee.lastName,
            process.env.REACT_APP_EMP_KEY
          ),
          position: employee.position,
          worked: employee.worked,
        }));
        let sorted1 = decryptEmployees.sort(
          (a, b) =>
            a.priority - b.priority ||
            a.roleId - b.roleId ||
            a.firstName.localeCompare(b.firstName)
        );
        setStaffList(sorted1);
        setTempStaffList(sorted1);

        let brandsArr = [];
        let rolesArr = [];
        for (let i = 0; i < res.data.staff.length; i++) {
          if (!brandsArr.includes(res.data.staff[i].name)) {
            brandsArr.push(res.data.staff[i].name);
          }
          if (!rolesArr.includes(res.data.staff[i].position)) {
            rolesArr.push(res.data.staff[i].position);
          }
        }
        setBrands(brandsArr);
        setRoles(rolesArr);
        if (selectedBrand !== "") {
          filterStaff("name", selectedBrand, sorted1);
        }
        if (employeeId) {
          let scrollHere = `employee-row-${employeeId}`;
          document.getElementById(scrollHere).scrollIntoView({
            block: "center",
            behavior: "smooth",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //Show Alert for adding employee to rota
  const showAddEmployeeAlert = (message, severity) => {
    setEmployeeMessage(message);
    setEmployeeSeverity(severity);
    setEmployeeAlert(true);
    setTimeout(() => {
      setEmployeeAlert(false);
      setEmployeeMessage("");
      setEmployeeSeverity("");
    }, 3000);
  };

  //Select a site for rota to appear
  const handleSiteSelection = async (id) => {
    // updates moment to get first day of the week Saturday
    // first week of the year must contained 1st of January
    moment.updateLocale("en-UK", {
      week: {
        dow: 6,
        doy: 12,
      },
    });

    setLoader(true);
    setStaffList([]);
    setTempStaffList([]);
    setPreviousWeek(false);
    setEmployeeList([]);
    setCurrentDay(moment().format());
    setSiteId(id);
    setTargetHours(null);
    for (let ms = 0; ms < managedSites.length; ms++) {
      if (managedSites[ms].eposId == id) {
        setSiteName(managedSites[ms].siteName);
      }
    }

    // Set Dates
    let weekShortDates = [];
    let weekFullDates = [];

    for (let w = 0; w <= 6; w++) {
      if (w === 0) {
        setFirstWeekDay(moment().weekday(0).format("DD-MM-YYYY"));
      }
      weekShortDates.push(moment().weekday(w).format("dddd - Do"));
      weekFullDates.push(moment().weekday(w).format("YYYY-MM-DD"));
    }

    setShortDates(weekShortDates);
    setFullDates(weekFullDates);

    await axios
      .post(
        `${hrUrl}/rota/employees/week`,
        {
          siteId: id,
          start: weekFullDates[0],
          end: weekFullDates[6],
          user: user,
          nitroPos: true,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
          },
        }
      )
      .then(async (res) => {
        setPublished(res.data.rota.published);
        setUnderReview(res.data.rota.reviewed);

        setRotaId(res.data.rota.rotaId);
        setRotaNotes(res.data.rota.notes);
        setConfirmedDates(res.data.rota.confirmedDates);
        setTargetHours(res.data.targetHours);

        //To find out if the week is the current week
        for (let i = 0; i < weekFullDates.length; i++) {
          let findDate = weekFullDates.some(
            (day) => day === moment().format("YYYY-MM-DD")
          );
          findDate ? setIsCurrentWeek(true) : setIsCurrentWeek(false);
        }
        const decryptEmployees = await res.data.staff.map((employee) => ({
          ...employee,
          firstName: decryptData(
            employee.firstName,
            process.env.REACT_APP_EMP_KEY
          ),
          lastName: decryptData(
            employee.lastName,
            process.env.REACT_APP_EMP_KEY
          ),
          position: employee.position,
          worked: employee.worked,
        }));

        // setStaffList(decryptEmployees);
        let sorted1 = decryptEmployees.sort(
          (a, b) =>
            a.priority - b.priority ||
            a.roleId - b.roleId ||
            a.firstName.localeCompare(b.firstName)
        );
        setStaffList(sorted1);
        setTempStaffList(sorted1);

        let brandsArr = [];
        let rolesArr = [];
        for (let i = 0; i < res.data.staff.length; i++) {
          if (!brandsArr.includes(res.data.staff[i].name)) {
            brandsArr.push(res.data.staff[i].name);
          }
          if (!rolesArr.includes(res.data.staff[i].position)) {
            rolesArr.push(res.data.staff[i].position);
          }
        }
        setBrands(brandsArr);
        setRoles(rolesArr);

        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  //React Print functions
  const handleClick = () => {
    myRefname.current.click();
  };
  //If an employee Print Rota
  const handlePrintClick = () => {
    //checks if rota is Published or not
    //show confirm dialog if rota is not published
    if (published) {
      window.open(
        `staff-management/print-rota/${rotaId}/${siteId}/${currentDate}`,
        "_blank",
        "rel=noopener noreferrer"
      );
    } else {
      setShowPrintRota(true);
    }
  };
  const linkToPrint = () => {
    return (
      <Button
        variant="contained"
        color="warning"
        ref={myRefname}
        style={{ display: "none" }}
      >
        Print PDF
      </Button>
    );
  };

  //If adding an employee select their core site
  const handleSelectSite = async (siteId, e) => {
    if (siteId) {
      let allEmployees = await getEmployeesByCompany(siteId, token);
      setEmployeeList(allEmployees);
    } else {
      setEmployeeList([]);
      setAddEmployeeId("");
    }
  };

  //Select an employee from the previously selected site
  const handleAddEmployee = () => {
    let employeeExists = false;

    let data = {
      siteId: siteId,
      employeeDetailsId: addEmployeeId,
      start: moment(firstWeekDay, "DD-MM-YYYY").format("YYYY-MM-DD"),
      end: moment(firstWeekDay, "DD-MM-YYYY").add(6, "d").format("YYYY-MM-DD"),
    };

    for (let s = 0; s < staffList.length; s++) {
      if (staffList[s].employeeDetailsId === Number(addEmployeeId)) {
        employeeExists = true;
      }
    }

    if (addEmployeeId === "") {
      showAddEmployeeAlert("Please select an employee", "warning");
    } else if (employeeExists) {
      showAddEmployeeAlert("Employee already added", "warning");
    } else {
      axios
        .post(`${hrUrl}/rota/employee/add `, data)
        .then(async (res) => {
          showAddEmployeeAlert("Employee added to rota", "success");
          setAddEmployeeId("");
          setEmployeeList([]);
          reloadData();
        })
        .catch((err) => {
          console.log(err);
          showAddEmployeeAlert("Couldn't add employee", "error");
        });
    }
  };

  //Go to previous rota week
  const handlePrevWeek = () => {
    //updates moment to get first day of the week Saturday
    //first week of the year must contained 1st of January
    moment.updateLocale("en-UK", {
      week: {
        dow: 6,
        doy: 12,
      },
    });
    setStaffList([]);
    setTempStaffList([]);
    setEmployeeList([]);

    setTargetHours(null);
    let prevWeek = moment(currentDate).subtract(1, "week").format();
    setCurrentDay(prevWeek);
    let week = [];
    let beWeek = [];

    for (let w = 0; w <= 6; w++) {
      if (w === 0) {
        setFirstWeekDay(moment(prevWeek).weekday(w).format("DD-MM-YYYY"));
      }
      week.push(moment(prevWeek).weekday(w).format("dddd - Do"));
      beWeek.push(moment(prevWeek).weekday(w).format("YYYY-MM-DD"));
    }

    axios
      .post(
        `${hrUrl}/rota/employees/week`,
        {
          siteId: siteId,
          start: beWeek[0],
          end: beWeek[6],
          user: user,
          nitroPos: true,
        },
        config
      )
      .then((res) => {
        setPublished(res.data.rota.published);
        setUnderReview(res.data.rota.reviewed);
        setRotaId(res.data.rota.rotaId);
        setRotaNotes(res.data.rota.notes);
        setConfirmedDates(res.data.rota.confirmedDates);
        setTargetHours(res.data.targetHours);
        //To find out if this is a week in the past
        moment().diff(moment(res.data.rota.startDate).format(), "days") > 6
          ? setPreviousWeek(true)
          : setPreviousWeek(false);

        //To find out if the week is the current week
        for (let i = 0; i < beWeek.length; i++) {
          let findDate = beWeek.some(
            (day) => day === moment().format("YYYY-MM-DD")
          );

          findDate ? setIsCurrentWeek(true) : setIsCurrentWeek(false);
        }

        const decryptEmployees = res.data.staff.map((employee) => ({
          ...employee,
          firstName: decryptData(
            employee.firstName,
            process.env.REACT_APP_EMP_KEY
          ),
          lastName: decryptData(
            employee.lastName,
            process.env.REACT_APP_EMP_KEY
          ),
          position: employee.position,
          worked: employee.worked,
        }));

        let sorted1 = decryptEmployees.sort(
          (a, b) =>
            a.priority - b.priority ||
            a.roleId - b.roleId ||
            a.firstName.localeCompare(b.firstName)
        );
        setStaffList(sorted1);
        setTempStaffList(sorted1);

        let brandsArr = [];
        let rolesArr = [];
        for (let i = 0; i < res.data.staff.length; i++) {
          if (!brandsArr.includes(res.data.staff[i].name)) {
            brandsArr.push(res.data.staff[i].name);
          }
          if (!rolesArr.includes(res.data.staff[i].position)) {
            rolesArr.push(res.data.staff[i].position);
          }
        }
        setBrands(brandsArr);
        setRoles(rolesArr);
      })
      .catch((err) => {
        console.log(err);
      });

    setShortDates(week);
    setFullDates(beWeek);
  };

  //Go to next rota week
  const handleNextWeek = () => {
    //updates moment to get first day of the week Saturday
    //first week of the year must contained 1st of January
    moment.updateLocale("en-UK", {
      week: {
        dow: 6,
        doy: 12,
      },
    });
    setStaffList([]);
    setTempStaffList([]);
    setEmployeeList([]);

    setTargetHours(null);
    let nextWeek = moment(currentDate).add(1, "week").format();
    setCurrentDay(nextWeek);
    let week = [];
    let beWeek = [];
    for (let w = 0; w <= 6; w++) {
      if (w === 0) {
        setFirstWeekDay(moment(nextWeek).weekday(0).format("DD-MM-YYYY"));
      }

      week.push(moment(nextWeek).weekday(w).format("dddd - Do"));
      beWeek.push(moment(nextWeek).weekday(w).format("YYYY-MM-DD"));
    }
    axios
      .post(
        `${hrUrl}/rota/employees/week`,
        {
          siteId: siteId,
          start: beWeek[0],
          end: beWeek[6],
          user: user,
          nitroPos: true,
        },
        config
      )
      .then((res) => {
        //To find out if this is a week in the past
        moment().diff(moment(res.data.rota.startDate).format(), "days") > 6
          ? setPreviousWeek(true)
          : setPreviousWeek(false);

        //To find out if the week is the current week
        for (let i = 0; i < beWeek.length; i++) {
          let findDate = beWeek.some(
            (day) => day === moment().format("YYYY-MM-DD")
          );

          findDate ? setIsCurrentWeek(true) : setIsCurrentWeek(false);
        }

        setPublished(res.data.rota.published);
        setUnderReview(res.data.rota.reviewed);
        setRotaId(res.data.rota.rotaId);
        setRotaNotes(res.data.rota.notes);
        setConfirmedDates(res.data.rota.confirmedDates);
        setTargetHours(res.data.targetHours);
        const decryptEmployees = res.data.staff.map((employee) => ({
          ...employee,
          firstName: decryptData(
            employee.firstName,
            process.env.REACT_APP_EMP_KEY
          ),
          lastName: decryptData(
            employee.lastName,
            process.env.REACT_APP_EMP_KEY
          ),
          position: employee.position,
          worked: employee.worked,
        }));

        let sorted1 = decryptEmployees.sort(
          (a, b) =>
            a.priority - b.priority ||
            a.roleId - b.roleId ||
            a.firstName.localeCompare(b.firstName)
        );
        setStaffList(sorted1);
        setTempStaffList(sorted1);

        let brandsArr = [];
        let rolesArr = [];
        for (let i = 0; i < res.data.staff.length; i++) {
          if (!brandsArr.includes(res.data.staff[i].name)) {
            brandsArr.push(res.data.staff[i].name);
          }
          if (!rolesArr.includes(res.data.staff[i].position)) {
            rolesArr.push(res.data.staff[i].position);
          }
        }
        setBrands(brandsArr);
        setRoles(rolesArr);
      })
      .catch((err) => {
        console.log(err);
      });

    setShortDates(week);
    setFullDates(beWeek);
  };

  const sortName = async (field) => {
    if (field == "") {
      setTempStaffList([]);
      setTimeout(() => {
        reloadData();
      }, 500);
    } else {
      var sorted = tempStaffList;
      let val;
      let type;
      let split = field.split(" ");
      type = split[1];
      if (field.includes("Name")) {
        val = "firstName";
      } else {
        val = "position";
      }

      if (type == "ASC") {
        sorted = tempStaffList.sort((a, b) => a[val].localeCompare(b[val]));
      } else {
        sorted = tempStaffList.sort((a, b) => b[val].localeCompare(a[val]));
      }
      setTempStaffList([]);
      setTimeout(() => {
        setTempStaffList(sorted);
      }, 500);
    }
  };

  // check if a user is getting more then contracted hours
  const checkContractedHours = () => {
    let over_or_less_contracted_hours_staff = [];
    tempStaffList?.forEach((staff) => {
      let total_hours_Worked = "00:00:00";

      staff?.worked?.forEach((worked) => {
        total_hours_Worked = addTimes(
          total_hours_Worked,
          worked?.hoursWorked || "00:00:00"
        );
      });

      let total_hours_Worked_array = total_hours_Worked.split(":");
      if (
        parseInt(staff.contractedHours) !==
          parseInt(total_hours_Worked_array[0]) ||
        (parseInt(staff.contractedHours) ===
          parseInt(total_hours_Worked_array[0]) &&
          parseInt(total_hours_Worked_array[1]) > 0)
      ) {
        staff.TotalHoursWorked = total_hours_Worked;
        over_or_less_contracted_hours_staff = [
          ...over_or_less_contracted_hours_staff,
          staff,
        ];
      }
    });

    if (over_or_less_contracted_hours_staff.length > 0) {
      setOverOrLessContractedHoursStaff(over_or_less_contracted_hours_staff);
      return true;
    } else {
      return false;
    }
  };

  const filterStaff = (type, val, saveData = []) => {
    if (val == "") {
      setTempStaffList([]);
      setTimeout(() => {
        reloadData();
      }, 500);
    } else {
      if (saveData.length === 0) {
        var filtered = staffList.filter((i) => i[type] == val);
        setTempStaffList([]);
        setTimeout(() => {
          setTempStaffList(filtered);
        }, 500);
      } else {
        var filtered = saveData.filter((i) => i[type] == val);
        setTempStaffList([]);
        setTimeout(() => {
          setTempStaffList(filtered);
        }, 500);
      }
    }
  };

  return (
    <>
      <section
        className="staff__management rota_mobile_toggle"
        ref={componentRef}
      >
        {!loader ? (
          <>
            <div className="top_bar">
              <div className="top_bar_left">
                {siteId ? (
                  <Button
                    onClick={handlePrevWeek}
                    variant="contained"
                    sx={{
                      borderRadius: "6px",
                      marginRight: "10px",
                      width: "64px",
                      minWidth: "none",
                      height: "50px",
                    }}
                  >
                    <KeyboardArrowLeftIcon fontSize="small" />
                  </Button>
                ) : null}
                {!oneSiteManager ? (
                  <select onChange={(e) => handleSiteSelection(e.target.value)}>
                    <option>--Select Site--</option>
                    {!!managedSites &&
                      managedSites.map(function (site, index) {
                        return (
                          <option key={index} value={site.eposId}>
                            {site.siteName}
                          </option>
                        );
                      })}
                  </select>
                ) : null}
                {siteId ? (
                  <h2>
                    <span>{siteName}</span> Rota | W/C:{" "}
                    <span>{firstWeekDay}</span>
                  </h2>
                ) : null}
              </div>
              {siteId ? (
                <>
                  <div className="filter_section">
                    <select onChange={(e) => sortName(e.target.value)}>
                      <option value="">SORT BY:</option>
                      <option>Name ASC</option>
                      <option>Name DESC</option>
                      <option>Role ASC</option>
                      <option>Role DESC</option>
                    </select>
                    <select
                      onChange={(e) => {
                        filterStaff("name", e.target.value);
                        setSelectedBrand(e.target.value);
                      }}
                    >
                      <option value="">FILTER BRAND:</option>
                      {brands.map(function (d, i) {
                        return <option>{d}</option>;
                      })}
                    </select>
                    <select
                      onChange={(e) => filterStaff("position", e.target.value)}
                    >
                      <option value="">FILTER ROLE:</option>
                      {roles.map(function (d, i) {
                        return <option>{d}</option>;
                      })}
                    </select>
                  </div>
                  <div className="top_bar_right">
                    {!previousWeek && !isCurrentWeek ? (
                      !published && !UnderReview ? (
                        <Button
                          variant="contained"
                          color="success"
                          onClick={() =>
                            checkContractedHours()
                              ? setShowContractedHours(true)
                              : setPublishModal(true)
                          }
                          endIcon={<PublishIcon />}
                          className="pulse-button"
                        >
                          Send for review
                        </Button>
                      ) : (
                        <Button
                          disable
                          variant="contained"
                          color="error"
                          endIcon={<PublishIcon />}
                          onClick={() =>
                            checkContractedHours()
                              ? setShowContractedHours(true)
                              : setPublishModal(true)
                          }
                          className="republish_btn"
                        >
                          Resend for review
                        </Button>
                      )
                    ) : null}

                    <Box
                      sx={{
                        position: "relative",
                        justifyContent: "center",
                      }}
                    >
                      <SpeedDial
                        ariaLabel="SpeedDial basic example"
                        sx={{
                          top: 0,
                          right: 0,
                          bottom: 0,
                          position: "absolute",
                        }}
                        icon={<HelpOutlineIcon />}
                        direction={"left"}
                      >
                        <SpeedDialAction
                          sx={{ marginTop: "10px" }}
                          key={"Holiday paid"}
                          icon={
                            <BeachAccessIcon color="success" fontSize="small" />
                          }
                          tooltipTitle={"Holiday paid"}
                        />
                        <SpeedDialAction
                          sx={{ marginTop: "10px" }}
                          key={"Holiday Unpaid"}
                          icon={
                            <BeachAccessIcon
                              color="secondary"
                              fontSize="small"
                            />
                          }
                          tooltipTitle={"Holiday Unpaid"}
                        />
                        <SpeedDialAction
                          sx={{ marginTop: "10px" }}
                          key={"Holiday Pending Approval"}
                          icon={
                            <PauseCircleFilledIcon
                              color="warning"
                              fontSize="small"
                            />
                          }
                          tooltipTitle={"Holiday Pending Approval"}
                        />
                        <SpeedDialAction
                          sx={{ marginTop: "10px" }}
                          key={"NightShift"}
                          icon={
                            <ModeNightIcon color="warning" fontSize="small" />
                          }
                          tooltipTitle={"NightShift"}
                        />
                        <SpeedDialAction
                          sx={{ marginTop: "10px" }}
                          key={"Sick"}
                          icon={<VaccinesIcon color="error" fontSize="small" />}
                          tooltipTitle={"Sick"}
                        />
                        <SpeedDialAction
                          sx={{ marginTop: "10px" }}
                          key={"Mat/Pat"}
                          icon={
                            <StrollerIcon color="primary" fontSize="small" />
                          }
                          tooltipTitle={"Mat/Pat"}
                        />
                        <SpeedDialAction
                          sx={{ marginTop: "10px" }}
                          key={"AWOL"}
                          icon={
                            <PsychologyAltIcon
                              color="warning"
                              fontSize="small"
                            />
                          }
                          tooltipTitle={"AWOL"}
                        />
                        <SpeedDialAction
                          sx={{ marginTop: "10px" }}
                          key={"other"}
                          icon={
                            <NewReleasesIcon color="primary" fontSize="small" />
                          }
                          tooltipTitle={"other"}
                        />
                        <SpeedDialAction
                          sx={{ marginTop: "10px" }}
                          key={"Unpublished"}
                          icon={
                            <SquareIcon
                              sx={{
                                color: "#dfe6f0",
                                borderRadius: "50px",
                              }}
                            />
                          }
                          tooltipTitle={"Unpublished"}
                        />
                        <SpeedDialAction
                          sx={{ marginTop: "10px" }}
                          key={"Published"}
                          icon={
                            <SquareIcon
                              sx={{
                                borderRadius: "50px",
                                color: "#64c4e0",
                              }}
                            />
                          }
                          tooltipTitle={"Published"}
                        />
                        <SpeedDialAction
                          sx={{ marginTop: "10px" }}
                          key={"Confirmed"}
                          icon={
                            <SquareIcon
                              sx={{
                                borderRadius: "50px",
                                color: "#41f2b1",
                              }}
                            />
                          }
                          tooltipTitle={"Confirmed"}
                        />

                        <SpeedDialAction
                          sx={{ marginTop: "10px" }}
                          key={"Other Site"}
                          icon={
                            <SquareIcon
                              sx={{
                                borderRadius: "50px",
                                color: "#f2f277",
                              }}
                            />
                          }
                          tooltipTitle={"Other Site"}
                        />
                        <SpeedDialAction
                          sx={{ marginTop: "10px" }}
                          key={"No Show"}
                          icon={
                            <SquareIcon
                              sx={{
                                borderRadius: "50px",
                                color: "#f44b48",
                              }}
                            />
                          }
                          tooltipTitle={"No Show"}
                        />
                      </SpeedDial>
                    </Box>

                    {/* RESET BUTTON - FOR FUTURE ROTAS ONLY */}
                    {!previousWeek && !isCurrentWeek ? (
                      <Button
                        variant="contained"
                        color="error"
                        title="Reset Current Rota"
                        onClick={() => setResetRota(true)}
                      >
                        <RestartAltIcon />
                      </Button>
                    ) : null}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setNoteModal(true);
                      }}
                    >
                      <SpeakerNotesIcon
                        className={
                          rotaNotes && rotaNotes.length > 0
                            ? "rota_note_icon"
                            : ""
                        }
                      ></SpeakerNotesIcon>
                    </Button>

                    {/* print rota button */}
                    <Button
                      variant="contained"
                      color="warning"
                      onClick={() => {
                        handlePrintClick();
                      }}
                    >
                      <PrintIcon />
                    </Button>

                    {/* Next week button  */}
                    <Button onClick={handleNextWeek} variant="contained">
                      <KeyboardArrowRightIcon />
                    </Button>
                  </div>
                </>
              ) : null}
            </div>

            {siteId ? (
              <>
                <div className="arrow_key_bar"></div>
                <div ref={componentRef}>
                  <Rota
                    user={user}
                    site={siteId}
                    siteName={siteName}
                    rotaId={rotaId}
                    currentDate={currentDate}
                    fullDates={fullDates}
                    staffList={tempStaffList}
                    published={published}
                    shortDates={shortDates}
                    reloadData={reloadData}
                    confirmedDates={confirmedDates}
                    lastEmployeeId={lastEmployeeId}
                    printable={false}
                    targetHours={targetHours}
                  />
                </div>
              </>
            ) : null}
            {siteId ? (
              <div className="bottom_bar">
                {!previousWeek ? (
                  <div className="mid_bar_left">
                    {!employeeAlert ? (
                      <section className="add_employee">
                        <select
                          onChange={(e) => {
                            handleSelectSite(e.target.value, e);
                          }}
                          id="site_select"
                        >
                          <option value="">Add Employee from other site</option>
                          {addEmployeeSites &&
                            addEmployeeSites.map((site, i) => {
                              return (
                                <option key={i} value={site.companyId}>
                                  {site.siteName}
                                </option>
                              );
                            })}
                        </select>

                        {employeeList.length > 0 ? (
                          <>
                            <select
                              onChange={(e) => setAddEmployeeId(e.target.value)}
                            >
                              <option value="">Add Employee</option>
                              {addEmployeeSites &&
                                employeeList.map((employee, i) => {
                                  let firstName = decryptData(
                                    employee.firstName,
                                    process.env.REACT_APP_EMP_KEY
                                  );
                                  let lastName = decryptData(
                                    employee.lastName,
                                    process.env.REACT_APP_EMP_KEY
                                  );
                                  return (
                                    <option
                                      key={i}
                                      value={employee.employeeDetailsId}
                                    >
                                      {firstName} {lastName}
                                    </option>
                                  );
                                })}
                              {}
                            </select>

                            <Button variant="contained" color="primary">
                              <AddIcon onClick={handleAddEmployee} />
                            </Button>
                          </>
                        ) : null}
                      </section>
                    ) : (
                      <Alert id="employeeId_alert" severity={employeeSeverity}>
                        {employeeMessage}
                      </Alert>
                    )}
                  </div>
                ) : null}
                <div className="mid_bar_right"> </div>
              </div>
            ) : null}

            {/* MODALS */}
            <Modal
              open={noteModal}
              aria-labelledby="Note Modal"
              aria-describedby="Note Modal"
            >
              <NotesModal
                rotaId={rotaId}
                rotaNotes={rotaNotes}
                setNoteModal={setNoteModal}
                reloadData={reloadData}
              />
            </Modal>
            <Modal
              open={ShowPrintRota}
              aria-labelledby="Print Modal"
              aria-describedby="Print Modal"
            >
              <ConfirmPrintRotaModel
                setShowPrintRota={setShowPrintRota}
                url={`print-rota/${rotaId}/${siteId}/${currentDate}`}
              />
            </Modal>
            <Modal
              open={ShowContractedHours}
              aria-labelledby="Over Contracted Hours Modal"
              aria-describedby="Over Contracted Hours Modal"
            >
              <ContractedHoursUsersModel
                overOrLessContractedHoursStaff={overOrLessContractedHoursStaff}
                setShowContractedHours={setShowContractedHours}
                setPublishModal={setPublishModal}
              />
            </Modal>
            <Modal
              open={publishModal}
              aria-labelledby="Publish Modal"
              aria-describedby="Publish Modal"
            >
              <Publish
                setPublishModal={setPublishModal}
                UnderReview={UnderReview}
                user={user}
                rotaId={rotaId}
                published={published}
                reloadData={reloadData}
                staffList={staffList}
                currentDate={currentDate}
                site={siteId}
              />
            </Modal>
            <Modal
              open={resetRota}
              aria-labelledby="Reset Rota Modal"
              aria-describedby="Reset Rota Modal"
            >
              <ResetRota
                staffList={staffList}
                handleClose={setResetRota}
                rotaId={rotaId}
                user={user}
                reloadData={reloadData}
              />
            </Modal>
          </>
        ) : (
          <Loader />
        )}
      </section>
      <h1 className="mobile_message">
        Not available on smaller screens, please view on desktop.{" "}
      </h1>
    </>
  );
}
