import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import WastageCollection from "./WastageCollection";
import WastageLogin from "./WastageLogin";

export default function AutoCollection() {
    const [reload, setReload] = useState(false);
    const [showLogin, setShowLogin] = useState(true);
    const { id } = useParams();

    useEffect(() => {
        let userD = localStorage.getItem("userDetails");
        if (userD !== null) {
            setShowLogin(false);
        }
    }, [reload]);

    const rel = () => {
        setReload(!reload);
    };

    return (
        <main>
            {showLogin ? (
                <WastageLogin rel={rel} />
            ) : (
                <WastageCollection id={id} />
            )}
        </main>
    );
}
