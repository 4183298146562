import {useState, useEffect} from 'react'
import axios from 'axios'
import moment from 'moment'

export default function Details(props) {
    const [details, setDetails] = useState([])
    const [suppliers, setSuppliers] = useState([])
    const [date, setDate] = useState("")
    const [dueDate, setDueDate] = useState("")


    useEffect(() => {
        console.log(props.details)
        setDetails(props.details)
        setDate(moment(props.details.dateCreated).format("YYYY-MM-DD"))
        // console.log(moment(props.detail.dateCreated).add(2,"weeks"))
        // if (props.details.supplierId === 54 || props.details.supplierId === 55 || props.details.supplierId === 76 || props.details.supplierId === 81 || props.details.supplierId === 105 || props.details.supplierId === 122 || props.details.supplierId === 125 || props.details.supplierId === 134 || props.details.supplierId === 144 || props.details.supplierId === 159 || props.details.supplierId) {
        //     setDueDate(moment(props.details.dateCreated).add(2, "weeks"))
        //     console.log(moment(props.details.dateCreated).format("YYYY-MM-DD"))
        // }


        axios
        .get(
          "https://nitropos-backend.nitrosolutions.co.uk/purchaseOrder/supplier"
        )
        .then((res) => {
          setSuppliers(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, [props]);
  

    const handleChange = (change) => {
        //for every change send it to the DB
        //then props.rel()
    }

    return(
        <section className="indvInvoice__details">
            <div className="inv_details_row">
                <p>Invoice Number</p>
                <input type="text" value={props.details.invoiceNumber} />
            </div>
            <div className="inv_details_row">
                <p>Invoice Date</p>
                <input type="date" value={date}/>
            </div>
            <div className="inv_details_row">
                <p>Supplier</p>
                <select id="suppliers__invoice" value={props.details.supplierId}>
                    {suppliers.map(function(d,i){
                        return(
                            <option value={d.supplierId}>{d.name}</option>
                        )
                    })}
                </select>
            </div>
            <div className="inv_details_row">
                <p>Status</p>
                <input type="text" value="To Be Paid" />
            </div>
            <div className="inv_details_row">
                <p>Site</p>
                <input type="text" value="Autoport" />
            </div>
            <div className="inv_details_row">
                <p>Payment Due</p>
                <input type="date" />
            </div>
            <div className="inv_details_row">
                <p>Amount</p>
                <input type="text" value={props.details.totalCost} />
            </div>
        </section>
    )
}