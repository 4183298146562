import { useEffect, useState } from "react";
import "../App.scss";
import axios from "axios";
import bp from "./images/bp.png";
import { WeatherWidget } from "@daniel-szulc/react-weather-widget";
import CurrencyPoundIcon from "@mui/icons-material/CurrencyPound";
import ReactApexChart from "react-apexcharts";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import PriceHistory from "./PriceHistory";

export default function FuelOverview() {
  const [bpPrices, setBpPrices] = useState();
  const [dieselData, setDieselData] = useState([]);
  const [ultimateDieselData, setUltimateDieselData] = useState([]);
  const [unleadedData, setUnleadedData] = useState([]);
  const [ultimateUnleadedData, setUltimateUnleadedData] = useState([]);
  const [dieselData2, setDieselData2] = useState("");
  const [ultimateDieselData2, setUltimateDieselData2] = useState("");
  const [unleadedData2, setUnleadedData2] = useState("");
  const [ultimateUnleadedData2, setUltimateUnleadedData2] = useState("");
  const [graph, setGraph] = useState()
  const [dieselDataGraph, setDieselDataGraph] = useState([]);
  const [ultimateDieselDataGraph, setUltimateDieselDataGraph] = useState([]);
  const [unleadedDataGraph, setUnleadedDataGraph] = useState([]);
  const [ultimateUnleadedDataGraph, setUltimateUnleadedDataGraph] = useState([]);
  const [dateChange, setDateChange] = useState([]);
  const [showVat, setShowVat] = useState(false)

  useEffect(() => {
    axios
      .get("https://nitropos-backend.nitrosolutions.co.uk/fuel/bp/latest")
      .then((res) => {
        // console.log(res.data)
        setBpPrices(res.data);
        setDieselData2(res.data.diesel.toFixed(2));
        setUltimateDieselData2(res.data.ultimateDiesel.toFixed(2));
        setUnleadedData2(res.data.unleaded.toFixed(2));
        setUltimateUnleadedData2(res.data.ultimateUnleaded.toFixed(2));
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get("https://nitropos-backend.nitrosolutions.co.uk/fuel/bp/graph")
      .then((res) => {
        // console.log(res.data);
        setDateChange(res.data.dates);
        setGraph(res.data)
        setDieselDataGraph(res.data.diesel);
        setUltimateDieselDataGraph(res.data.ultimateDiesel);
        setUnleadedDataGraph(res.data.unleaded);
        setUltimateUnleadedDataGraph(res.data.ultimateUnleaded);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  let petrolPrices = {
    series: [
      {
        name: "Diesel",
        data: dieselDataGraph,
      },
      {
        name: "Ultimate Diesel",
        data: ultimateDieselDataGraph,
      },
      {
        name: "Unleaded",
        data: unleadedDataGraph,
      },
      {
        name: "Ultimate Unleaded",
        data: ultimateUnleadedDataGraph,
      },
    ],
    options: {
      responsive: [
        {
          breakpoint: 500,
          options: {
            chart: {
              width: 330,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      chart: {
        height: 350,
        type: "line",
        background: "#fff",
        dropShadow: {
          enabled: false,
          top: 0,
          left: 3,
          blur: 8,
          color: "#000",
          opacity: 0.24,
        },
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [5, 7, 5],
        curve: "straight",
        dashArray: [0, 8, 5],
      },
      title: {
        text: "Fuel Price",
        align: "left",
      },
      legend: {
        tooltipHoverFormatter: function (val, opts) {
          return (
            val +
            " - " +
            opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
            ""
          );
        },
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      xaxis: {
        categories: dateChange,
      },
      tooltip: {
        y: [
          {
            title: {
              formatter: function (val) {
                return val;
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val;
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val;
              },
            },
          },
        ],
      },
      grid: {
        borderColor: "#f1f1f1",
      },
    },
  };

  const updateVat = async () => {
    if (showVat) {
      setDieselData2(bpPrices.diesel.toFixed(2));
      setUltimateDieselData2(bpPrices.ultimateDiesel.toFixed(2));
      setUnleadedData2(bpPrices.unleaded.toFixed(2));
      setUltimateUnleadedData2(bpPrices.ultimateUnleaded.toFixed(2));
      setDieselDataGraph(graph.diesel)
      setUltimateDieselDataGraph(graph.ultimateDiesel)
      setUnleadedDataGraph(graph.unleaded)
      setUltimateUnleadedDataGraph(graph.ultimateUnleaded)
    } else {
      setDieselData2(((parseFloat(bpPrices.diesel) + (parseFloat(bpPrices.diesel) * 0.2))).toFixed(2));
      setUltimateDieselData2(((parseFloat(bpPrices.ultimateDiesel) + (parseFloat(bpPrices.ultimateDiesel) * 0.2))).toFixed(2));
      setUnleadedData2(((parseFloat(bpPrices.unleaded) + (parseFloat(bpPrices.unleaded) * 0.2))).toFixed(2));
      setUltimateUnleadedData2(((parseFloat(bpPrices.ultimateUnleaded) + (parseFloat(bpPrices.ultimateUnleaded) * 0.2))).toFixed(2));
      var d1 = [];
      for (let d = 0; d < dieselDataGraph.length; d++) {
        d1.push(((parseFloat(dieselDataGraph[d]) + (parseFloat(dieselDataGraph[d]) * 0.2))).toFixed(2))
      }
      setDieselDataGraph(d1)
      var ud1 = [];
      for (let ud = 0; ud < ultimateDieselDataGraph.length; ud++) {
        ud1.push(((parseFloat(ultimateDieselDataGraph[ud]) + (parseFloat(ultimateDieselDataGraph[ud]) * 0.2))).toFixed(2))
      }
      setUltimateDieselDataGraph(ud1)
      var u1 = [];
      for (let u = 0; u < unleadedDataGraph.length; u++) {
        u1.push(((parseFloat(unleadedDataGraph[u]) + (parseFloat(unleadedDataGraph[u]) * 0.2))).toFixed(2))
      }
      setUnleadedDataGraph(u1)
      var uu1 = [];
      for (let uu = 0; uu < ultimateUnleadedDataGraph.length; uu++) {
        uu1.push(((parseFloat(ultimateUnleadedDataGraph[uu]) + (parseFloat(ultimateUnleadedDataGraph[uu]) * 0.2))).toFixed(2))
      }
      setUltimateUnleadedDataGraph(uu1)
    }
    setShowVat(!showVat)
  }

  return (
    <main>
      <div className="home">
        <div className="topBar">
        <PriceHistory />
          {bpPrices ? (
            <div className="rightSide"><br/>
              <section className="current">
                <div className="prices">
                  <img src={bp} alt="bp logo" />
                  <b>Price update: {bpPrices.date}</b>
                  <div style={{ marginLeft: "3%", color: "navy" }}>
                    <FormGroup>
                      <FormControlLabel control={<Switch checked={showVat} onChange={updateVat} />} label="VAT" />
                    </FormGroup>
                  </div>
                </div>
                <div className="prices">
                  <div className="latest">
                    <p>Diesel</p>
                    <p>
                      <CurrencyPoundIcon sx={{ color: "#000" }} />
                      {dieselData2}
                    </p>
                  </div>
                  <div className="latest">
                    <p>Ultimate Diesel</p>
                    <p>
                      <CurrencyPoundIcon sx={{ color: "#000" }} />
                      {ultimateDieselData2}
                    </p>
                  </div>
                  <div className="latest">
                    <p style={{ color: "#03A64A" }}>Unleaded</p>
                    <p style={{ color: "#03A64A" }}>
                      <CurrencyPoundIcon sx={{ color: "#03A64A" }} />
                      {unleadedData2}
                    </p>
                  </div>
                  <div className="latest">
                    <p style={{ color: "#03A64A" }}>Ultimate Unleaded</p>
                    <p style={{ color: "#03A64A" }}>
                      <CurrencyPoundIcon sx={{ color: "#03A64A" }} />
                      {ultimateUnleadedData2}
                    </p>
                  </div>
                </div>
              </section>
              <div className="wrapper">
                <ReactApexChart
                  options={petrolPrices.options}
                  series={petrolPrices.series}
                  type="line"
                  height={350}
                  className="chart"
                  style={{margin: "0"}}
                />
              </div>
            </div>
          ) : (
            <div className="rightSide">
              <section className="current">
                <div className="prices">
                  <img src={bp} alt="bp logo" />
                  <b>Price update: </b>
                </div>
                <div className="prices">
                  <div className="latest">
                    <p>Diesel</p>
                    <p>
                      <CurrencyPoundIcon sx={{ color: "#000" }} />
                    </p>
                  </div>
                  <div className="latest">
                    <p>Ultimate Diesel</p>
                    <p>
                      <CurrencyPoundIcon sx={{ color: "#000" }} />
                    </p>
                  </div>
                  <div className="latest">
                    <p style={{ color: "#03A64A" }}>Unleaded</p>
                    <p>
                      <CurrencyPoundIcon sx={{ color: "#03A64A" }} />
                    </p>
                  </div>
                  <div className="latest">
                    <p style={{ color: "#03A64A" }}>Ultimate Unleaded</p>
                    <p>
                      <CurrencyPoundIcon sx={{ color: "#03A64A" }} />
                    </p>
                  </div>
                </div>
              </section>
              <div className="wrapper">
                <ReactApexChart
                  options={petrolPrices.options}
                  series={petrolPrices.series}
                  type="line"
                  height={350}
                  className="chart"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </main>
  );
}
