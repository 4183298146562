// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes flip {
  from, 90% {
    transform: rotate(0);
  }
  to {
    transform: rotate(180deg);
  }
}
.loading_icon {
  animation: flip 3s ease-in-out infinite;
  color: #2c53a0;
  margin: 1% auto;
}`, "",{"version":3,"sources":["webpack://./src/components/animations/tinyLoader.scss"],"names":[],"mappings":"AAAA;EACC;IACC,oBAAA;EACA;EACD;IACC,yBAAA;EACA;AACF;AAEA;EACI,uCAAA;EACA,cAAA;EACH,eAAA;AAAD","sourcesContent":["@keyframes flip {\n\tfrom, 90% {\n\t\ttransform: rotate(0);\n\t}\n\tto {\n\t\ttransform: rotate(180deg);\n\t}\n}\n\n.loading_icon{\n    animation: flip 3s ease-in-out infinite;\n    color: #2c53a0;\n\tmargin: 1% auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
