import "./tanks.scss";
import { useState, useEffect } from "react";
import { Gauge } from "smart-webcomponents-react/gauge";
import "smart-webcomponents-react/source/styles/smart.default.css";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import axios from 'axios'

export default function Tank(props) {
  const [tankNo, setTankNo] = useState("");
  const [tankId, setTankId] = useState("");
  const [stock, setStock] = useState(0);
  const [grade, setGrade] = useState("");
  const [capacity, setCapacity] = useState(0);
  const [update, setUpdate] = useState(false);
  const [lastReading, setLastReading] = useState("")
  const [lastSalesReading, setLastSalesReading] = useState("")
  const [fillColour,setFillColour]=useState("white")
  const [textColour,setTextColour]=useState("#64c4e0")

  const [salesBtn, setSalesBtn] = useState(true);
  const [deliveryBtn, setDeliveryBtn] = useState(true);
  const [liveBtn, setLiveBtn] = useState(true);

  const [showSales, setShowSales] = useState(false);
  const [showDelivery, setShowDelivery] = useState(false);
  const [showLive, setShowLive] = useState(false);
  const [staffInput, setStaffInput] = useState(false);

  const [sales, setSales] = useState(0);
  const [delivery, setDelivery] = useState(0);
  const [live, setLive] = useState(0);
  const [volume, setVolume] = useState(0)

  const [staff, setStaff] = useState("");
  const [alert, setAlert] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false)
  const [showError,setShowError] =useState(false)

  useEffect(() => {
    setTankId(props.tankId)
    setTankNo(props.tank);
    setStock(props.stock);
    setGrade(props.grade);
    setCapacity(props.capacity);
    setUpdate(props.update);
    setLastReading(props.date);
    setLastSalesReading(props.salesDate);
    if(props.stock<1000){
      setFillColour("red")
      setTextColour("red")
    }else{
      setFillColour("rgba(0,0,0,0)")
      setTextColour("#2c53a0")
    }
  }, [props.tankId, props.update, props.stock]);

  function handleSales() {
    if (showSales === false) {
      setShowSales(true);
      setDeliveryBtn(false);
      setLiveBtn(false);
      setStaffInput(true);
    } else {
      setShowSales(false);
      setDeliveryBtn(true);
      setLiveBtn(true);
      setStaffInput(false);
    }
  }
  function handleDelivery() {
    if (showDelivery === false) {
      setShowDelivery(true);
      setSalesBtn(false);
      setLiveBtn(false);
      setStaffInput(true);
    } else {
      setShowDelivery(false);
      setSalesBtn(true);
      setLiveBtn(true);
      setStaffInput(false);
    }
  }
  function handleLive() {
    if (showLive === false) {
      setShowLive(true);
      setSalesBtn(false);
      setDeliveryBtn(false);
      setStaffInput(true);
    } else {
      setShowLive(false);
      setSalesBtn(true);
      setDeliveryBtn(true);
      setStaffInput(false);
    }
  }

  function Submit() {
    if (staff === "") {
      setAlert(true)
      setTimeout(() => {
        setAlert(false)
      }, 2500)
    } else {
      var type;
      if (showLive) {
        type = "Manual"
      } else if (showDelivery) {
        type = "Delivery"
      } else if (showSales) {
        type = "Sales"
      }
      var data = {
        type: type,
        volume: volume,
        staff: staff,
        tankId: tankId
      }
      axios.post("https://nitropos-backend.nitrosolutions.co.uk/fuel/update", data).then((res) => {
        if (res.data) {
          setShowDelivery(false)
          setShowLive(false)
          setShowSales(false)
          setStaffInput(false)
          setSalesBtn(true)
          setDeliveryBtn(true)
          setLiveBtn(true)
          setShowSuccess(true)
          setTimeout(() => {
            setShowSuccess(false)
          }, 2500)
          // window.location.href=`/?site=${props.site}`;
          props.reset();
        }else{
          setShowError(true)
          setTimeout(() => {
            setShowError(false)
          }, 3000)
        }
      }).catch((err) => {
        console.log("Error")
      })
    }
  }

  return (
    <div className="indvTank">
      {alert ? (
        <Alert variant="filled" severity="warning" className="alert">
          Name must be entered
        </Alert>
      ) : showSuccess ? (
        <Alert variant="filled" severity="success">
          Fuel Levels Successfully Updated!
        </Alert>
      ) : showError ? (
        <Alert variant="filled" severity="error">
          Fuel Levels Cannot Fall Below 0 Or Exceed {capacity} litres!
        </Alert>
      ) : <></>}
      <h4>
        Tank: <span>{tankNo}</span>
      </h4>
      <Gauge
        // ref={}
        id="gauge"
        analogDisplayType="fill"
        digitalDisplay
        startAngle={-30}
        endAngle={210}
        min="0"
        max={capacity}
        value={stock}
        style={{ width: "15vw",border:`1.4px solid ${fillColour}`,borderRadius:"50%",fill:textColour}}
        disabled
      />
      <h6>
        Capacity: <span>{capacity}</span>
      </h6>
      <h6>
        Volume: <span style={{color:textColour}}>{stock}</span>
      </h6>
      <h4>
        Grade: <span>{grade}</span>
      </h4>
      {update ? (
        <div className="update">
          {salesBtn ? (
            <>
              <Button
                onClick={() => handleSales()}
                color="primary"
                variant="outlined"
                className="btn"
              >
                Input Sales
              </Button>
              {showSales ? (
                <>
                  <label>Sales:</label>
                  <input
                    type="number"
                    onChange={(e) => setVolume(e.target.value)}
                  />
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
          {deliveryBtn ? (
            <>
              <Button
                onClick={() => handleDelivery()}
                color="primary"
                variant="outlined"
                className="btn"
              >
                Input a Delivery
              </Button>
              {showDelivery ? (
                <>
                  <label>Delivery:</label>
                  <input
                    type="number"
                    onChange={(e) => setVolume(e.target.value)}
                  />
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
          {liveBtn ? (
            <>
              <Button
                onClick={() => handleLive()}
                color="primary"
                variant="outlined"
                className="btn"
              >
                Input a Live Reading
              </Button>
              {showLive ? (
                <>
                  <label>Live Reading:</label>
                  <input
                    type="number"
                    onChange={(e) => setVolume(e.target.value)}
                  />
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
          {staffInput ? (
            <>
              <label>
                Name <span>*</span>:
              </label>
              <input type="text" onChange={(e) => setStaff(e.target.value)} />
            </>
          ) : (
            <></>
          )}
          <p style={{ textAlign: "center" }}><b>Last Update: {new Date(lastReading).toLocaleString()}</b></p>
          {/* <p style={{ textAlign: "center" }}><b>Last Sales Reading: {lastSalesReading}</b></p> */}
          <p style={{ textAlign: "center" }}><b>Last Sales Reading: {new Date(lastSalesReading).toLocaleString()}</b></p>
          <Button onClick={() => Submit()} color="success" variant="contained">
            Submit
          </Button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
