import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";

let style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    bgcolor: "background.paper",
    border: `none`,
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
};

export default function HolidayModal(props) {

    const [holidayData, setHolidayData] = useState({})

    useEffect(() => {
        let getToken = localStorage.getItem("auth-token");
        axios.get(`${process.env.REACT_APP_API_URL}/holiday/admin/id/${props.holidayId}/new`, { headers: { "x-auth-token": getToken } }).then((res) => {
            setHolidayData(res.data)
        }).catch((err) => {
            console.log(err)
        })
    }, [props])

    return (
        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Holiday Details
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <p><b>Days Taken</b>: {holidayData.daysTaken}</p>
                <p><b>Reason</b>: {holidayData.reason}</p>
                <p><b>Date(s)</b>: {moment(holidayData.startDate).format("DD/MM/YYYY")} - {moment(holidayData.endDate).format("DD/MM/YYYY")}</p>
                <p><b>Submission Date</b>: {moment(holidayData.submissionDate).format("DD/MM/YYYY HH:mm")}</p>
                <p><b>Approved By</b>: {holidayData.updatedBy}</p>
                <p><b>Approved Date</b>: {moment(holidayData.updatedDate).format("DD/MM/YYYY HH:mm")}</p>
            </Typography>
        </Box>
    )
}