import "./styles/navbar.scss";

export default function Footer() {
    return (
        <footer
            style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
            }}
        >
            <p style={{ color: "#fff" }}>
                Developed by{" "}
                <a href="https://nitrosolutions.co.uk" target="_blank">
                    Nitro Solutions
                </a>
            </p>
            <p>v{process.env.REACT_APP_NITROPOS_VERSION}</p>
        </footer>
    );
}
