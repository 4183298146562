import { useState, useEffect } from "react";
import axios from "axios";
import WareNav from "../WareNav";
import "./v4u.scss";
import { useNavigate, Navigate } from "react-router-dom";

export default function Picklist() {
  const [role, setRole] = useState("");
  const [user, setUser] = useState("");
  const [picklists, setPicklists] = useState([]);
  const [showPicklist, setShowPicklist] = useState(false);
  const [reload, setReload] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let userD = localStorage.getItem("userDetails");
    let user = JSON.parse(userD);
    setUser(user.user.user);
    setRole(user.user.role);
    axios
      .get("https://nitropos-backend.nitrosolutions.co.uk/edn/v4u/picklist/all")
      .then((res) => {
        setPicklists(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reload]);

  const viewList = async (id, type) => {
    localStorage.setItem("id", id);
    localStorage.setItem("type", type);
    navigate("/warehouse/order");
  };

  return (
    <main className="v4u__ordering">
      <main className="v4u__main">
        <WareNav />
        <section className="ordering__main">
          <h1>PICKLIST</h1>
          {!showPicklist ? (
            <div className="ordering__row">
              {picklists.length > 0 ? (
                <div className="order__table">
                  <h4>Current Outstanding Orders</h4>
                  <table>
                    <thead>
                      <th>Site</th>
                      <th>Supplier</th>
                      <th>Order Placed</th>
                      <th>Items</th>
                    </thead>
                    <tbody>
                      {picklists.map(function (d, i) {
                        return (
                          <tr
                            onClick={() => viewList(d.id, d.customerNumber)}
                            style={{ cursor: "pointer" }}
                          >
                            <td>{d.site}</td>
                            <td>{d.supplier}</td>
                            <td>{d.orderDate}</td>
                            <td>{d.productTotal}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <h4>WELL DONE! YOU HAVE PICKED ALL THE ORDERS!</h4>
              )}
            </div>
          ) : null}
        </section>
      </main>
    </main>
  );
}