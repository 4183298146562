import "./dashboard.scss";
import axios from "axios";
import { useState, useEffect } from "react";

export default function EodDash() {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get("https://nitropos-backend.nitrosolutions.co.uk/eos/dashboard")
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    setInterval(() => {
        window.location.reload();
    }, 60000)
  }, []);

  return (
    <main className="main__dashboard">
      <section className="cards__dash">
        {data.length > 0 ? (
          data.map(function (d, i) {
            var color = "green__box"
            if (i===1 || i === 5 || i === 9){
                color = "yellow__box"
            }
            if (i === 2 || i === 6){
                color = "blue__box"
            }
            if (i === 3 || i === 7){
                color = "white__box"
            }

            return (
              <div className="card__outer">
                <div className={`card__dash ${color}`}>
                  <p className="card__title">{d.siteName}</p>
                  <p className="card__content">Shifts to reconcile: {d.shiftsLeftToRecon}</p>
                  <p className="card__content">Last EOD: {new Date(d.lastEOD.timeCreated).toLocaleDateString("en-GB")}</p>
                  <p className="card__content">
                    Last EOD by: {d.lastEOD.user}
                  </p>
                  <p className="card__content ">
                    Bags to collect: {d.bankBags[0].amount} - {d.bankBags[0].total}
                  </p>
                  <p className="card__content">
                    Bags in transit: {d.bankBags[1].amount} - {d.bankBags[1].total}
                  </p>
                  <p className="card__content">
                    Awaiting confirm in bank: {d.bankBags[2].amount} - {d.bankBags[2].total}
                  </p>
                  <p className="card__content">
                    Manager open investigations: {d.storeInvestigations}
                  </p>
                  <p className="card__content">Admin open investigations: {d.adminInvestigations}</p>
                </div>
              </div>
            );
          })
        ) : (
          <></>
        )}
      </section>
    </main>
  );
}
