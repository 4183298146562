import { useState, useEffect } from "react";
// import axios from 'axios'
import Button from "@mui/material/Button";

export default function Notes(props) {
  const [notes, setNotes] = useState([]);
  const [newNote, setNewNote] = useState("");

  useEffect(() => {
    // setNotes(props.notes)
  }, [props]);

  const addNewNote = () => {
    //axios send note
    //then props.rel()
  }

  return (
    <section className="indvInvoice__details">
      <textarea
        cols={60}
        rows={10}
        onChange={(e) => setNewNote(e.target.value)}
      />
      <Button color="success" variant="contained" onClick={() => addNewNote()}>
        Add Note
      </Button>

      <table>
        <thead>
            <tr>
                <th>Date</th>
                <th>Note</th>
                <th>User</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>20/01/2023</td>
                <td>Awaiting Credit note from supplier not all received</td>
                <td>carlos.echanove@nitrosolutions.co.uk</td>
            </tr>
            <tr>
                <td>25/01/2023</td>
                <td>Credit note received, total adjusted</td>
                <td>alan@nitrosolutions.co.uk</td>
            </tr>
        </tbody>
      </table>
    </section>
  );
}
