import { Chip } from "@mui/material";
import moment from "moment";
import React from "react";
import { addTimes } from "../../../../utils/add_two_times";

function ShiftChip({ shift }) {
  return (
    <Chip
      sx={{
        height: "auto",
        padding: "8px",
      }}
      label={
        <span style={{ margin: 0 }}>
          <span style={{ fontSize: "16px" }}>
            {moment(shift.startTime).format("HH:mm") +
              " - " +
              moment(shift.endTime).format("HH:mm")}
          </span>
          <span style={{ display: "block" }}>
            (
            {moment(
              shift.hoursWorked, // addTimes(shift.hoursWorked, shift.breakTime),
              "HH:mm:ss"
            ).format("hh:mm")}{" "}
            hours)
          </span>
        </span>
      }
      color="default"
    />
  );
}

export default ShiftChip;
