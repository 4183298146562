import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import "./styles/login.scss";
import Logo from "../images/logo.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import axios from "axios";
import UserContext from "./context/userContext";
import ErrorNotice from "./ErrorNotice";
import StorefrontIcon from "@mui/icons-material/Storefront";
import WarehouseIcon from "@mui/icons-material/Warehouse";
// import SensorOccupiedIcon from "@mui/icons-material/SensorOccupied";

export default function LoginTest(props) {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState();
  const { userData, setUserData } = useContext(UserContext);
  const navigate = useNavigate();
  const [attempts, setAttempts] = useState(0);
  const [page, setPage] = useState(false);
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get("redirect");

  useEffect(() => {
    // console.log(document.URL.includes("/"));
    setPage(document.URL);

    let preLoaded = sessionStorage.getItem("first-load");
    if (preLoaded == null) {
      sessionStorage.setItem("first-load", true);
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
      console.log("Cache cleared");
    }
  }, []);

  //function to call forgotten password
  const forgottenPassword = () => {
    // console.log(email);

    //If no email display error
    if (email == null) {
      setError("Please insert Email adress");
    } else {
      axios
        .post(
          //sending user to /Forgot with Email info
          "https://nitropos-backend.nitrosolutions.co.uk/users/forgot",
          { email: email }
        )
        .then((res) => {
          //alert to update user
          alert("An email will be sent shortly with your credentials!");
          window.location.reload();
        })
        //Error catch
        .catch((err2) => {
          console.log(err2);
        });
    }
  };

  const submit = useCallback(async (e) => {
    e.preventDefault();

    try {
      const loginUser = { email, password };
      const loginRes = await axios.post(
        "https://nitropos-backend.nitrosolutions.co.uk/users/login",
        loginUser
      );

      setUserData({
        token: loginRes.data.token,
        user: loginRes.data.user,
        sites: loginRes.data.user.sites,
        role: loginRes.data.role,
        poAdmin: loginRes.data.poAdmin,
      });
      localStorage.setItem("auth-token", loginRes.data.token);
      let user = {
        user: loginRes.data.user,
        sites: loginRes.data.user.sites,
        role: loginRes.data.role,
        poAdmin: loginRes.data.poAdmin,
      };
      localStorage.setItem("userDetails", JSON.stringify(user));

      if (redirect) {
        navigate(redirect, { replace: true });
        return;
      }

      if (
        loginRes.data.user.role === "Manager" ||
        loginRes.data.user.role === "Admin" ||
        loginRes.data.user.role === "HR"
      ) {
        setTimeout(() => {
          navigate("/home");
        }, 500);
      } else if (
        loginRes.data.user.role === "Store Manager" ||
        loginRes.data.user.role === "Supervisor"
      ) {
        setTimeout(() => {
          navigate("/managerZone");
        }, 500);
      } else if (loginRes.data.user.role === "Developer") {
        setTimeout(() => {
          navigate("/dev-zone");
        }, 500);
      } else if (
        loginRes.data.user.role === "Warehouse Manager" ||
        loginRes.data.user.role === "V4U Manager"
      ) {
        setTimeout(() => {
          navigate("/warehouse/v4u-disposables");
        }, 500);
      } else if (loginRes.data.user.role === "Food Manager") {
        setTimeout(() => {
          navigate("/food-to-go");
        }, 500);
      }
    } catch (err) {
      console.log(err);
      err.response.data.msg && setError(err.response.data.msg);
      if (err.response.data.msg == "Invalid credentials.") {
        var attempt = attempts + 1;
        setAttempts(attempt);
        if (attempt >= 3) {
          //Calling Forgot Password
          forgottenPassword();
        } else {
          console.log(attempt);
        }
      }
    }
  });

  const storezonehandler = () => {
    window.location.replace("https://nitropos.co.uk/storezone");
  };

  return (
    <div className="login">
      <img src={Logo} />
      <h1>Please login</h1>
      {error && (
        <ErrorNotice message={error} clearError={() => setError(undefined)} />
      )}
      <form onSubmit={submit}>
        <input
          type="text"
          placeholder="Username"
          onChange={(e) => setEmail(e.target.value)}
        />
        <br />
        <input
          type="password"
          placeholder="Password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <br />
        <input type="submit" value="Login" />
      </form>
      <div className="row">
        {/* <button onClick={() => storezonehandler()}> */}
        <button onClick={() => navigate("/storezone")}>
          <StorefrontIcon fontSize="small" sx={{ marginTop: "0" }} /> Go to
          Store Zone
        </button>
      </div>
      <div className="row">
        <button onClick={() => navigate("/warehouse/packers")}>
          <WarehouseIcon fontSize="small" sx={{ marginTop: "0" }} /> Go to
          Packers
        </button>
      </div>

      <button type="button" onClick={forgottenPassword}>
        Forgotten Password
      </button>
    </div>
  );
}
