import NavBar from "../NavBar";
import "./hr.scss";
import { useState, useEffect, Component } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CurrencyPoundIcon from "@mui/icons-material/CurrencyPound";
import Investigation from "./investigations/Investigation";
import LineAxisIcon from "@mui/icons-material/LineAxis";
import HrDash from "./dash/HrDash";
import { Navigate } from "react-router-dom";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import ManHol from "./holidays/ManHol";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import HolCalendar from "./holidays/calendar/HolCalendar";

const drawerWidth = "12vw";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div>
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: "pre-wrap" }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
          <h3>An Alert has been sent to IT</h3>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default function Hr(props) {
  const [role, setRole] = useState("");
  const [user, setUser] = useState("");
  const [value, setValue] = useState(1);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    let userD = localStorage.getItem("userDetails");
    let user = JSON.parse(userD);
    setUser(user.user.user);
    setRole(user.user.role);

    if (window.innerWidth < 690) {
      setIsMobile(true);
    }
  }, [window.innerWidth]);

  const handleChange = (index) => {
    setValue(index + 1);
  };

  return (
    <main className="hr__home">
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, padding: "0" }}
        >
          <Toolbar sx={{ width: "100vw", backgroundColor: "#2c53a0" }}>
            <NavBar />
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
            },
            ["@media(max-width: 600px)"]: {
              width: "10vw",
            },
          }}
        >
          <Toolbar />
          <Box sx={{ overflow: "hidden", marginTop: "12vh" }}>
            <List>
              {[
                "Dashboard",
                "Discrepancy Report",
                "Manual Leave",
                "Holiday Calendar",
              ].map((text, index) => (
                <ListItem
                  key={text}
                  disablePadding
                  onClick={() => handleChange(index)}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      {index === 0 ? (
                        <LineAxisIcon />
                      ) : index === 1 ? (
                        <CurrencyPoundIcon />
                      ) : index === 2 ? (
                        <BeachAccessIcon />
                      ) : index === 3 ? (
                        <CalendarMonthIcon />
                      ) : (
                        <></>
                      )}
                    </ListItemIcon>
                    {!isMobile ? <ListItemText primary={text} /> : <></>}
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: "10vh" }}>
          <Toolbar />
          {value === 1 ? (
            <ErrorBoundary>
              <HrDash />
            </ErrorBoundary>
          ) : value === 2 ? (
            <ErrorBoundary>
              <Investigation />
            </ErrorBoundary>
          ) : value === 3 ? (
            <ErrorBoundary>
              <ManHol />
            </ErrorBoundary>
          ) : value === 4 ? (
            <ErrorBoundary>
              <HolCalendar />
            </ErrorBoundary>
          ) : (
            <h1>You don't have permissions for this</h1>
          )}
        </Box>
      </Box>
    </main>
  );
}
