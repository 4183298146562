import "./epos.scss";
import { useState, useContext, useEffect } from "react";
import UserContext from "../../context/userContext";
import { useNavigate } from "react-router-dom";
import ManagerNav from "../ManagerNav";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Cross from "../../animations/Cross";
import Tick from "../../animations/Tick";
import WareNav from "../../warehouse/WareNav";
import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40vw",
    height: "80vh",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
    overflowY: "scroll",
};

export default function AddEposItem(props) {
    const { userData } = useContext(UserContext);
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [sites, setSites] = useState([]);
    const [addItem, setAddItem] = useState(false);
    const [barcode, setBarcode] = useState("");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [category, setCategory] = useState("");
    const [supplier, setSupplier] = useState("");
    const [additionalSupplier, setAdditionalSupplier] = useState("");
    const [productCode, setProductCode] = useState("");
    const [costPrice, setCostPrice] = useState("");
    const [rrp, setRrp] = useState("");
    const [tax, setTax] = useState("");
    const [site, setSite] = useState("");
    const [stockLevel, setStockLevel] = useState(0);
    const [margin, setMargin] = useState(0);
    const [role, setRole] = useState("");
    const [user, setUser] = useState("");
    const [showStockOption, setShowStockOption] = useState(true);

    const [existAlert, setExistAlert] = useState(false);

    const [itemName, setItemName] = useState("");

    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [hideButton, setHideButton] = useState(true);

    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        let userD = localStorage.getItem("userDetails");
        let user = JSON.parse(userD);
        setUser(user.user.user);
        setRole(user.user.role);

        let data = {
            sites: userData.user.sites,
        };
        axios
            .post(
                "https://nitropos-backend.nitrosolutions.co.uk/eposSQL/getSitesByName",
                data
            )
            .then((res) => {
                setSites(res.data);
            })
            .catch((err) => {
                console.log(err);
            });

        axios
            .get(
                "https://nitropos-backend.nitrosolutions.co.uk/managerZone/categories"
            )
            .then((res) => {
                setCategories(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
        axios
            .get(
                "https://nitropos-backend.nitrosolutions.co.uk/managerZone/suppliers"
            )
            .then((res) => {
                setSuppliers(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            handleSearch();
        }
    };

    const handleSearch = async () => {
        setExistAlert(false);
        axios
            .get(
                `https://nitropos-backend.nitrosolutions.co.uk/managerZone/barcode/${barcode}`
            )
            .then((res) => {
                if (res.data.barcodes.length > 0) {
                    setItemName(res.data.barcodes[0].Name);
                    setExistAlert(true);
                } else {
                    setAddItem(true);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const addToEpos = async (e) => {
        e.preventDefault();

        if (name === "") {
            document.getElementById("name").classList.add("shake");
            setTimeout(() => {
                document.getElementById("category").classList.remove("shake");
            }, 2500);
        } else if (category === "" || category === "--Select a Category") {
            document.getElementById("category").classList.add("shake");
            setTimeout(() => {
                document.getElementById("category").classList.remove("shake");
            }, 2500);
        } else if (supplier === "" || supplier === "--Select a Supplier--") {
            document.getElementById("supplier").classList.add("shake");
            setTimeout(() => {
                document.getElementById("supplier").classList.remove("shake");
            }, 2500);
        } else if (tax === "" || tax === "--Select Tax--") {
            document.getElementById("tax").classList.add("shake");
            setTimeout(() => {
                document.getElementById("tax").classList.remove("shake");
            }, 2500);
        } else if (rrp === "" || rrp === "0") {
            document.getElementById("rrp").classList.add("shake");
            setTimeout(() => {
                document.getElementById("rrp").classList.remove("shake");
            }, 2500);
        } else if (
            showStockOption &&
            (site === "" || site === "--Please Select a Site--")
        ) {
            document.getElementById("site").classList.add("shake");
            setTimeout(() => {
                document.getElementById("site").classList.remove("shake");
            }, 2500);
        } else if (
            showStockOption &&
            (stockLevel === "" ||
                stockLevel === "0" ||
                parseInt(stockLevel) < 0)
        ) {
            document.getElementById("stock").style.display = "block";
            setTimeout(() => {
                document.getElementById("stock").style.display = "none";
            }, 2500);
        } else if (
            (supplier === "152170" || supplier === "187730") &&
            productCode === ""
        ) {
            document.getElementById("order__code").style.display = "block";
            setTimeout(() => {
                document.getElementById("order__code").style.display = "none";
            }, 2500);
        } else {
            setHideButton(false);
            var newItem = {
                name: name,
                description: name,
                category: category,
                supplier: supplier,
                additionalSupplier: additionalSupplier,
                barcode: barcode,
                productCode: productCode,
                costPrice: costPrice,
                salePrice: rrp,
                tax: tax,
                site: site,
                stockLevel: stockLevel,
                user: userData.user.user,
                hasStock: showStockOption,
            };
            axios
                .post(
                    "https://nitropos-backend.nitrosolutions.co.uk/managerZone/newProduct",
                    newItem
                )
                .then((res) => {
                    let clearTextFields =
                        document.querySelectorAll("input[type=text]");
                    let clearSelectFields = document.querySelectorAll("select");
                    setAddItem(false);
                    setSuccess(true);
                    setBarcode("");
                    for (let i = 0; (i = clearTextFields.length); i++) {
                        clearTextFields[i].value = "";
                    }
                    for (let s = 0; (s = clearSelectFields.length); s++) {
                        clearSelectFields[s].selectedIndex = 0;
                    }
                    setTimeout(() => {
                        setSuccess(false);
                        setHideButton(true);
                    }, 2500);
                })
                .catch((err) => {
                    console.log(err);
                    setAddItem(false);
                    setError(true);
                    setTimeout(() => {
                        setError(false);
                        setHideButton(true);
                        setAddItem(true);
                    }, 2500);
                });
        }
    };

    const handleNoBarcode = () => {
        if (name === "") {
            document.getElementById("name").classList.add("shake");
            setTimeout(() => {
                document.getElementById("category").classList.remove("shake");
            }, 2500);
        } else if (category === "" || category === "--Select a Category--") {
            document.getElementById("category").classList.add("shake");
            setTimeout(() => {
                document.getElementById("category").classList.remove("shake");
            }, 2500);
        } else if (supplier === "" || supplier === "--Select a Supplier--") {
            document.getElementById("supplier").classList.add("shake");
            setTimeout(() => {
                document.getElementById("supplier").classList.remove("shake");
            }, 2500);
        } else if (tax === "" || tax === "--Select Tax--") {
            document.getElementById("tax").classList.add("shake");
            setTimeout(() => {
                document.getElementById("tax").classList.remove("shake");
            }, 2500);
        } else if (rrp === "" || rrp === "0") {
            document.getElementById("rrp").classList.add("shake");
            setTimeout(() => {
                document.getElementById("rrp").classList.remove("shake");
            }, 2500);
        } else if (
            showStockOption &&
            (site === "" || site === "--Please Select a Site--")
        ) {
            document.getElementById("site").classList.add("shake");
            setTimeout(() => {
                document.getElementById("site").classList.remove("shake");
            }, 2500);
        } else if (
            showStockOption &&
            (stockLevel === "" ||
                stockLevel === "0" ||
                parseInt(stockLevel) < 0)
        ) {
            document.getElementById("stock").style.display = "block";
            setTimeout(() => {
                document.getElementById("stock").style.display = "none";
            }, 2500);
        } else if (productCode === "") {
            document.getElementById("order__code").style.display = "block";
            setTimeout(() => {
                document.getElementById("order__code").style.display = "none";
            }, 2500);
        } else {
            document.getElementById("sent__button").style.display = "none";
            let data = {
                name: name,
                description: name,
                category: category,
                supplier: supplier,
                orderCode: productCode,
                cost: costPrice,
                tax: tax,
                price: rrp,
                site: site,
                stock: stockLevel,
                user: userData.user.user,
                hasStock: showStockOption,
            };

            axios
                .post(
                    "https://nitropos-backend.nitrosolutions.co.uk/managerZone/newProductNoBarcode",
                    data
                )
                .then((res) => {
                    document.getElementById("sent__button").style.display =
                        "block";
                    handleClose();
                    setName("");
                    setDescription("");
                    setSupplier("");
                    setProductCode("");
                    setCostPrice("");
                    setTax("");
                    setRrp("");
                    setSite("");
                    setStockLevel("");
                })
                .catch((err) => {
                    document.getElementById("sent__button").style.display =
                        "block";
                    console.log(err);
                });
        }
    };

    const calculateMargin = (rrp) => {
        let margin = "";
        setRrp(rrp);
        if (costPrice === "") {
            setMargin(0);
        } else {
            if (!tax.includes("20")) {
                margin = (((rrp - costPrice) / rrp) * 100).toFixed(2);
            } else {
                var costTax =
                    parseFloat(costPrice) * 0.2 + parseFloat(costPrice);
                margin = (((rrp - costTax) / rrp) * 100).toFixed(2);
            }
            setMargin(margin);
        }
    };

    const checkIfVapeCat = async (val) => {
        console.log(val);
        setCategory(val);
        if (
            val == "1399995" ||
            val == "1384590" ||
            val == "1384589" ||
            val == "1399996" ||
            val == "1399994" ||
            val == "1405998" ||
            val == "1347350"
        ) {
            setShowStockOption(false);
            console.log("HIDE");
        } else {
            setShowStockOption(true);
        }
    };

    const checkIfVapeSup = async (val) => {
        setSupplier(val);
        if (val == "187278") {
            setShowStockOption(false);
        } else {
            setShowStockOption(true);
        }
    };

    const checkIfVapeAddSup = async (val) => {
        setAdditionalSupplier(val);
        if (val == "187278") {
            setShowStockOption(false);
        } else {
            setShowStockOption(true);
        }
    };

    return (
        <main className="add__epos__item">
            {role === "V4U Manager" ? <WareNav /> : <ManagerNav />}
            <section className="epos__item">
                <h1>EPOSNOW - Add Product</h1>
                <Button
                    onClick={() => navigate("/managerZone/epos-products")}
                    color="primary"
                    variant="contained"
                    className="back_btn"
                    startIcon={<ArrowBackIcon />}
                >
                    Back
                </Button>
                <div className="epos__search__sql">
                    <input
                        type="number"
                        placeholder="Search by barcode"
                        onKeyDown={(e) => handleKeyDown(e)}
                        onChange={(e) => setBarcode(e.target.value)}
                    />
                    <Button
                        color="primary"
                        onClick={() => handleSearch()}
                        className="search_btn"
                        variant="contained"
                    >
                        <SearchIcon />
                    </Button>
                </div>
                <div className="epos__no__barcode">
                    <label>Product has no Barcode</label>
                    <AddCircleIcon
                        color="primary"
                        fontSize="large"
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleOpen()}
                    />
                </div>

                {existAlert ? (
                    <Alert
                        severity="warning"
                        sx={{
                            width: "80vw",
                            margin: "auto",
                            marginTop: "2%",
                            cursor: "pointer",
                        }}
                        onClick={() =>
                            navigate(
                                `/managerZone/epos-products?barcode=${barcode}`
                            )
                        }
                    >
                        <AlertTitle>Warning</AlertTitle>
                        This Barcode already exists for item —{" "}
                        <strong>{itemName}</strong> - Click me to Edit
                    </Alert>
                ) : (
                    <></>
                )}
                {addItem ? (
                    <form onSubmit={addToEpos} id="form">
                        <label id="name">
                            Product Name <span>*</span>
                        </label>
                        <input
                            type="text"
                            onChange={(e) => setName(e.target.value)}
                            maxLength="40"
                        />
                        <label>
                            Product Description <span>*</span>
                        </label>
                        <input
                            type="text"
                            onChange={(e) => setDescription(e.target.value)}
                            value={name}
                            maxLength="100"
                        />
                        <label id="category">
                            Category <span>*</span>
                        </label>
                        <select
                            onChange={(e) => checkIfVapeCat(e.target.value)}
                        >
                            <option>--Select a Category--</option>
                            {categories.map(function (d, i) {
                                return <option value={d.Id}>{d.Name}</option>;
                            })}
                        </select>
                        <label id="supplier">
                            Supplier <span>*</span>
                        </label>
                        <select
                            onChange={(e) => checkIfVapeSup(e.target.value)}
                        >
                            <option>--Select a Supplier--</option>
                            {suppliers.map(function (d, i) {
                                return <option value={d.Id}>{d.Name}</option>;
                            })}
                        </select>
                        <label>Additional Supplier</label>
                        <select
                            onChange={(e) => checkIfVapeAddSup(e.target.value)}
                        >
                            <option>--Select an additional Supplier--</option>
                            {suppliers.map(function (d, i) {
                                return <option value={d.Id}>{d.Name}</option>;
                            })}
                        </select>
                        <label>
                            Barcode <span>*</span>
                        </label>
                        <input type="text" value={barcode} />
                        <label>Product Order Code</label>
                        <strong id="order__code">
                            Booker and Londis order code must be entered
                        </strong>
                        <input
                            type="text"
                            onChange={(e) => setProductCode(e.target.value)}
                        />
                        <label id="cost">Cost Price</label>
                        <input
                            type="text"
                            onChange={(e) => setCostPrice(e.target.value)}
                        />
                        <label id="tax">
                            Tax <span>*</span>
                        </label>
                        <select onChange={(e) => setTax(e.target.value)}>
                            <option>--Select Tax--</option>
                            <option value="0% NO VAT">0%</option>
                            <option value="5%">5%</option>
                            <option value="20VAT">20%</option>
                        </select>
                        <label id="rrp">
                            Sale Price <span>*</span>
                        </label>
                        <input
                            type="text"
                            onChange={(e) => calculateMargin(e.target.value)}
                        />
                        <label>
                            Margin: <span>{margin}%</span>
                        </label>
                        <hr style={{ width: "100%", color: "#4d4d4d" }} />
                        {showStockOption ? (
                            <>
                                <label id="site">
                                    Site <span>*</span>
                                </label>
                                <select
                                    onChange={(e) => setSite(e.target.value)}
                                >
                                    <option>--Please Select a Site--</option>
                                    {sites.map(function (d, i) {
                                        return (
                                            <option value={d.eposId}>
                                                {d.siteName}
                                            </option>
                                        );
                                    })}
                                </select>
                                <label>
                                    Stock Level <span>*</span>
                                </label>
                                <strong id="stock">
                                    Stock level must be entered
                                </strong>
                                <input
                                    type="text"
                                    onChange={(e) =>
                                        setStockLevel(e.target.value)
                                    }
                                />
                            </>
                        ) : (
                            <></>
                        )}

                        {hideButton ? (
                            <input type="submit" value="Add Item" />
                        ) : (
                            <strong>Please Wait</strong>
                        )}
                    </form>
                ) : (
                    <></>
                )}
                {success ? <Tick /> : error ? <Cross /> : <></>}
                <Modal
                    open={open}
                    aria-labelledby="Single Barcode Modal"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                        >
                            Add a product that has no barcode
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            <div className="modal">
                                <label id="name">
                                    Product Name <span>*</span>
                                </label>
                                <input
                                    type="text"
                                    onChange={(e) => setName(e.target.value)}
                                    maxLength="40"
                                />
                                <label>
                                    Product Description <span>*</span>
                                </label>
                                <input
                                    type="text"
                                    onChange={(e) =>
                                        setDescription(e.target.value)
                                    }
                                    value={name}
                                    maxLength="100"
                                />
                                <label id="category">
                                    Category <span>*</span>
                                </label>
                                <select
                                    onChange={(e) =>
                                        checkIfVapeCat(e.target.value)
                                    }
                                >
                                    <option>--Select a Category--</option>
                                    {categories.map(function (d, i) {
                                        return (
                                            <option value={d.Id}>
                                                {d.Name}
                                            </option>
                                        );
                                    })}
                                </select>
                                <label id="supplier">
                                    Supplier <span>*</span>
                                </label>
                                <select
                                    onChange={(e) =>
                                        checkIfVapeSup(e.target.value)
                                    }
                                >
                                    <option>--Select a Supplier--</option>
                                    {suppliers.map(function (d, i) {
                                        return (
                                            <option value={d.Id}>
                                                {d.Name}
                                            </option>
                                        );
                                    })}
                                </select>
                                <label>Product Order Code</label>
                                <input
                                    type="text"
                                    onChange={(e) =>
                                        setProductCode(e.target.value)
                                    }
                                />
                                <label id="cost">Cost Price</label>
                                <input
                                    type="text"
                                    onChange={(e) =>
                                        setCostPrice(e.target.value)
                                    }
                                />
                                <label id="tax">
                                    Tax <span>*</span>
                                </label>
                                <select
                                    onChange={(e) => setTax(e.target.value)}
                                >
                                    <option>--Select Tax--</option>
                                    <option value="0% NO VAT">0%</option>
                                    <option value="20VAT">20%</option>
                                </select>
                                <label id="rrp">
                                    Sale Price <span>*</span>
                                </label>
                                <input
                                    type="text"
                                    onChange={(e) =>
                                        calculateMargin(e.target.value)
                                    }
                                />
                                <label>
                                    Margin: <span>{margin}%</span>
                                </label>
                                <hr
                                    style={{ width: "100%", color: "#4d4d4d" }}
                                />
                                {showStockOption ? (
                                    <>
                                        <label id="site">
                                            Site <span>*</span>
                                        </label>
                                        <select
                                            onChange={(e) =>
                                                setSite(e.target.value)
                                            }
                                        >
                                            <option>
                                                --Please Select a Site--
                                            </option>
                                            {sites.map(function (d, i) {
                                                return (
                                                    <option value={d.eposId}>
                                                        {d.siteName}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                        <label>
                                            Stock Level <span>*</span>
                                        </label>
                                        <strong id="stock">
                                            Stock level must be entered
                                        </strong>
                                        <input
                                            type="text"
                                            onChange={(e) =>
                                                setStockLevel(e.target.value)
                                            }
                                        />
                                    </>
                                ) : (
                                    <></>
                                )}
                                <div className="modal__row">
                                    <Button
                                        color="success"
                                        variant="outlined"
                                        onClick={() => handleNoBarcode()}
                                        id="sent__button"
                                    >
                                        Add
                                    </Button>
                                    <Button
                                        color="error"
                                        variant="contained"
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        </Typography>
                    </Box>
                </Modal>
            </section>
        </main>
    );
}
