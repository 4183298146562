import { useEffect, useState } from "react";
import axios from "axios";
import { getCompanies } from "../../utils/Api";
import WareNav from "../WareNav";
import moment from "moment";
import { Alert } from "@mui/material";
import { Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import NitroLoader from "../../Fuel/loader/NitroLoader";
import "./manual.scss";

export default function ManualOrder() {
    const [productType, setProductType] = useState("");
    const [siteId, setSiteId] = useState("");
    const [siteName, setSiteName] = useState("");
    const [user, setUser] = useState("");
    const [siteList, setSiteList] = useState([]);
    const [products, setProducts] = useState([]);
    const [order, setOrder] = useState([]);
    const [disableButton, setDisableButton] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [alert, setAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");
    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {
        let userD = localStorage.getItem("userDetails");
        let user = JSON.parse(userD);
        setUser(user.user.user);
        let getToken = localStorage.getItem("auth-token");
        getCompanies(getToken)
            .then((companies) => {
                let sites = companies;
                for (let s = 0; s < sites.length; s++) {
                    if (sites[s].companyId === 1 || sites[s].companyId === 11) {
                        sites.splice(s, 1);
                    }
                }
                setSiteList(sites);
            })
            .catch((err) => {
                console.log(`Could not get sites: ${err}`);
            });
    }, []);

    const selectSite = async (id) => {
        setSiteId(id);
        var idx = siteList.findIndex((s) => s.eposId == id);
        setSiteName(siteList[idx].siteName);
    };

    const selectType = async (type) => {
        if (siteId == "") {
            setMessage("Site must be selected first");
            setSeverity("error");
            setAlert(true);
            setTimeout(() => {
                setAlert(false);
                setMessage("");
                setSeverity("");
            }, 4000);
        } else {
            setProductType(type);
        }
    };

    const searchProducts = async () => {
        setShowLoader(true);
        await axios
            .get(
                `${
                    process.env.REACT_APP_DEPPLOYED
                }/eposProducts/vapes/${productType.toLowerCase()}/${siteId}?search=${searchValue}`
            )
            .then((resp) => {
                setProducts(resp.data);
                console.log(resp.data);
                setShowLoader(false);
            })
            .catch((err) => {
                console.log(err);
                setShowLoader(false);
            });
        setSearchValue("");
    };

    const handleEnter = (e) => {
        if (e.keyCode === 13) {
            searchProducts();
        }
    };

    const updateOrder = async (idx, eposId, val) => {
        console.log(idx, eposId, val);
        if (parseInt(val) > 0) {
            var orderIdx = order.findIndex((o) => o.eposId == eposId);
            console.log(orderIdx);
            if (orderIdx > -1) {
                const updatedProduct = {
                    ...order[orderIdx],
                    toOrder: parseInt(val),
                };
                var newOrder = [
                    ...order.slice(0, orderIdx),
                    updatedProduct,
                    ...order.slice(orderIdx + 1),
                ];
                setOrder(newOrder);
            } else {
                var orderItem = products[idx];
                orderItem.toOrder = parseInt(val);
                setOrder((order) => [...order, orderItem]);
            }
        } else {
            var orderIdx = order.findIndex((o) => o.eposId == eposId);
            if (orderIdx > -1) {
                setOrder([
                    ...order.slice(0, orderIdx),
                    ...order.slice(orderIdx + 1),
                ]);
            }
        }
    };

    const finaliseOrder = async () => {
        setProducts([]);
        setDisableButton(true);
        setShowLoader(true);
        let url = "order/reviewed";
        let data = {
            customerNumber: "V4U",
            source: "manual",
            products: order,
            user: user,
            siteId: siteId,
            id: `v4u-man-${moment().unix()}`,
        };

        if (productType == "Disposables") {
            url = "disposables/order";
        }

        await axios
            .post(`${process.env.REACT_APP_DEPPLOYED}/edn/v4u/${url}`, data)
            .then((res) => {
                setMessage("Order submission successful");
                setSeverity("success");
                setAlert(true);
                setTimeout(() => {
                    setAlert(false);
                    setShowLoader(false);
                    setMessage("");
                    setSeverity("");
                    window.location.reload();
                }, 4000);
            })
            .catch((err) => {
                setMessage("Something went wrong - Please try again");
                setSeverity("error");
                setAlert(true);
                setTimeout(() => {
                    setAlert(false);
                    setMessage("");
                    setSeverity("");
                    setDisableButton(false);
                    setShowLoader(false);
                }, 4000);
            });
    };

    return (
        <>
            <WareNav />
            <main className="manualOrder main">
                <h1>Manual Order</h1>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "70%",
                    }}
                >
                    {siteName == "" ? (
                        <select onChange={(e) => selectSite(e.target.value)}>
                            <option>--- SELECT SITE ---</option>
                            {siteList.map(function (d, i) {
                                return (
                                    <option value={d.eposId}>
                                        {d.siteName}
                                    </option>
                                );
                            })}
                        </select>
                    ) : (
                        <h2>{siteName}</h2>
                    )}

                    {productType == "" ? (
                        <>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => selectType("Disposables")}
                            >
                                DISPOSABLES & SNUS
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => selectType("E-Liquids & Kits")}
                            >
                                E-LIQUIDS & KITS
                            </Button>
                        </>
                    ) : (
                        <>
                            <h2>{productType}</h2>
                            <input
                                type="text"
                                placeholder="Enter product name or barcode..."
                                onKeyDown={handleEnter}
                                onChange={(e) => setSearchValue(e.target.value)}
                                value={searchValue}
                            />
                            <Button
                                variant="contained"
                                color="success"
                                onClick={searchProducts}
                            >
                                <SearchIcon />
                            </Button>
                        </>
                    )}
                </div>

                {alert ? (
                    <Alert severity={severity} sx={{ marginTop: "2%" }}>
                        {message}
                    </Alert>
                ) : null}
                {showLoader ? <NitroLoader /> : null}
                {products.length > 0 ? (
                    <>
                        <h2>Found {products.length} Products</h2>
                        <table style={{ color: "black" }}>
                            <thead>
                                <tr>
                                    <td>Name</td>
                                    <td>Sales</td>
                                    <td>Store</td>
                                    <td>Warehouse</td>
                                    <td>Order</td>
                                </tr>
                            </thead>
                            <tbody>
                                {products.map(function (d, i) {
                                    var pName = products[i].name.split(" ");
                                    var newBrand;
                                    products[i + 1]
                                        ? pName[0] !=
                                          products[i + 1].name.split(" ")[0]
                                            ? (newBrand = true)
                                            : (newBrand = false)
                                        : (newBrand = false);
                                    var order;
                                    products[i].toOrder
                                        ? (order = products[i].toOrder)
                                        : (order = 0);
                                    return (
                                        <>
                                            <tr>
                                                <td>{d.name}</td>
                                                <td>{d.sales}</td>
                                                <td>{d.stock}</td>
                                                <td>{d.warehouse}</td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        placeholder="0"
                                                        onChange={(e) =>
                                                            updateOrder(
                                                                i,
                                                                d.eposId,
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                            {newBrand ? (
                                                <tr
                                                    style={{
                                                        backgroundColor: "navy",
                                                        padding: "10px",
                                                    }}
                                                >
                                                    <td
                                                        style={{
                                                            backgroundColor:
                                                                "navy",
                                                            padding: "10px",
                                                        }}
                                                    ></td>
                                                    <td
                                                        style={{
                                                            backgroundColor:
                                                                "navy",
                                                            padding: "10px",
                                                        }}
                                                    ></td>
                                                    <td
                                                        style={{
                                                            backgroundColor:
                                                                "navy",
                                                            padding: "10px",
                                                        }}
                                                    ></td>
                                                    <td
                                                        style={{
                                                            backgroundColor:
                                                                "navy",
                                                            padding: "10px",
                                                        }}
                                                    ></td>
                                                    <td
                                                        style={{
                                                            backgroundColor:
                                                                "navy",
                                                            padding: "10px",
                                                        }}
                                                    ></td>
                                                </tr>
                                            ) : null}
                                        </>
                                    );
                                })}
                            </tbody>
                        </table>
                    </>
                ) : null}

                {order.length > 0 ? (
                    <>
                        <h2>Your Order</h2>
                        <table style={{ color: "black" }}>
                            <thead>
                                <tr>
                                    <td>Name</td>
                                    <td>Sales</td>
                                    <td>Store</td>
                                    <td>Warehouse</td>
                                    <td>Order</td>
                                </tr>
                            </thead>
                            <tbody>
                                {order.map(function (d, i) {
                                    return (
                                        <tr>
                                            <td>{d.name}</td>
                                            <td>{d.sales}</td>
                                            <td>{d.stock}</td>
                                            <td>{d.warehouse}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    placeholder={d.toOrder}
                                                    onChange={(e) =>
                                                        updateOrder(
                                                            i,
                                                            d.eposId,
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <Button
                            id="submit_btn"
                            variant="contained"
                            color="success"
                            onClick={finaliseOrder}
                            disabled={disableButton}
                        >
                            Submit
                        </Button>
                        <br />
                    </>
                ) : null}
            </main>
        </>
    );
}
