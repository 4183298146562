import './discrepancies.scss';
import {useState, useEffect} from 'react';

export default function List (props) {
    const [data, setData] = useState([])

    useEffect(() => {
        setData(props.data)
    },[props])

    return(
        <main className="discrepancy__list">
            <table>
                <thead>
                    <th>Date</th>
                    <th>Till</th>
                    <th>Cost</th>
                </thead>
                <tbody>
                {data.length > 0 ? (
                data.map(function(d,i){
                    return(
                        <tr onClick={() => props.handleInvestigation(d.investigationId)}>
                            <td>{new Date(d.transactionDate).toLocaleDateString()}</td>
                            <td>{d.deviceName}</td>
                            <td>£{d.cost}</td>
                        </tr>
                    )
                })
            ):(
                <tr>
                    <td colSpan={3}>No Discrepancies to show</td>
                </tr>
            )}
                </tbody>
            </table>
        </main>
    )
}