import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Tick from "../../animations/Tick";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import axios from "axios";
import { getIngredients, getUnitTypes } from "../hoagiesUtils/Api";

export default function AddProduct(props) {
  const url = "https://nitropos-backend.nitrosolutions.co.uk";

  const categoryList = props.cats;
  const allergenList = props.allergens;
  const [showAlert, setShowAlert] = useState(false);
  const [showTick, setShowTick] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [user, setUser] = useState("");
  const [made, setMade] = useState(false);
  const [ingredient, setIngredient] = useState(false);
  const [ingCat, setIngCat] = useState([]);
  const [showIngredients, setShowIngredients] = useState(false);
  const [unitTypes, setUnitTypes] = useState([]);
  const [barcode, setBarcode] = useState("");
  //new product
  const [name, setName] = useState("");
  const [price, setPrice] = useState(0);
  const [cat, setCat] = useState("");
  const [ingredients, setIngredients] = useState([]);
  const [packQty, setPackQty] = useState(0);
  const [ingUnit, setIngUnit] = useState("");
  const [allergens, setAllergens] = useState([]);
  const [ingredientList, setIngredientList] = useState("");
  const [useBy, setUseBy] = useState("");
  const [days, setDays] = useState("");
  const [hours, setHours] = useState("");
  const [minutes, setMinutes] = useState("");
  const [cost, setCost] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    let userD = localStorage.getItem("userDetails");
    let user = JSON.parse(userD);
    setUser(user.user.user);

    getIngredients()
    .then((data) => {
      setIngCat(data);
    })
  }, []);

  const handleAddProduct = async () => {
    document.getElementById("add_prod_btn").style.display = "none";
    if (
      name === "" &&
      price === "" &&
      cost === "" &&
      cat === "" &&
      cat === "--Select One--"
    ) {
      setSeverity("warning");
      setMessage("Please insert all obligatory fields");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        setSeverity("");
        setMessage("");
        document.getElementById("add_prod_btn").style.display = "flex";
      }, 3000);
    } else if (
      !ingredient &&
      (days === "" ||
        days === "Select Days" ||
        minutes === "" ||
        minutes === "Select Minutes" ||
        hours === "" ||
        hours === "Select Hours")
    ) {
      setSeverity("warning");
      setMessage("Days, minutes and hours must be selected even if it is 0");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        setSeverity("");
        setMessage("");
        document.getElementById("add_prod_btn").style.display = "flex";
      }, 3000);
    } else if (
      ingredient &&
      (ingUnit == "" || ingUnit == "-- Select Unit --")
    ) {
      setSeverity("warning");
      setMessage("You Must Select A Unit Type Before Submission!");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        setSeverity("");
        setMessage("");
        document.getElementById("add_prod_btn").style.display = "flex";
      }, 3000);
    } else {
      let data = {};
      if (cat == 1404248) {
        data = {
          name: name,
          salePrice: price,
          costPrice: cost,
          category: cat,
          volume: packQty,
          unit: ingUnit,
          user: user,
          allergens: allergens,
          barcode: barcode,
        };
      } else {
        let time = "";

        if (days != "0" && days !== "") {
          time += `${days}d`;
        }
        if (hours != "0" && hours !== "") {
          time += `${hours}H`;
        }
        if (minutes != "0" && minutes !== "") {
          time += `${minutes}M`;
        }
        data = {
          name: name,
          salePrice: price,
          costPrice: cost,
          category: cat,
          ingredients: ingredients,
          allergens: allergens,
          ingredientList: ingredientList,
          description: description,
          useByOffSet: time,
          user: user,
        };
      }
      await axios
        .post(`${url}/hoagies/products/add`, data)

        .then((res) => {
          setShowTick(true);
          props.rel();
          setTimeout(() => {
            setShowTick(false);
            document.getElementById("categories").selectedIndex = "0";
            setName("");
            setPrice("");
            setIngredients([]);
            setAllergens([]);
            setPackQty(0);
            setIngredientList("");
            setDescription("");
            if (cat == 1404248) {
              if (allergens.length > 0) {
                allergens.forEach((allergen) => {
                  document.getElementById(allergen - 1).click();
                });
              }
            } else {
              let boxes = document.getElementsByClassName(
                "checkboxes_alergens"
              );
              boxes.forEach((box) => {
                if (box.disabled === true) {
                  box.toggleAttribute("disabled");
                }
              });
            }
            document.getElementById("add_prod_btn").style.display = "flex";
          }, 3000);
        })
        .catch((err) => {
          setSeverity("error");
          setMessage("Something went wrong, please try again");
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
            setSeverity("");
            setMessage("");
            document.getElementById("add_prod_btn").style.display = "flex";
          }, 3000);
        });
    }
  };

  //deletes ingredients from the list
  const handleDeleteIngredient = (i) => {
    let removedItem = ingredients.filter((val, index) => index !== i);
    setIngredients(removedItem);
  };

  //search of product on the datalist
  const searchProduct = (item) => {
    if (item.length > 3) {
      ingCat.forEach((product) => {
        if (product.eposId == item) {
          setIngredients([
            ...ingredients,
            { name: product.name, productCode: product.eposId },
          ]);
          if (product.hasAllergens === true) {
            let prodArray = product.allergens;
            prodArray.forEach((allergen) => {
              let findAllergen = allergens.findIndex(
                (a) => a === allergen.allergenId
              );
              if (findAllergen === -1) {
                console.log(`adding allergen ${allergen.allergenId}`);
                allergens.push(allergen.allergenId);
                document
                  .getElementById(allergen.name)
                  .setAttribute("disabled", true);
              }
            });
          }
        }
      });
    }
  };

  //handles category changes
  const handleCat = (cat) => {
    setCat(cat);
    if (cat == 1404248) {
      axios.get("https://backend.hoagies.co.uk/food/epos/products/unitTypes").then((res) => {
        setUnitTypes(res.data)
      }).catch((err) => {
        console.log(err)
      })
      if (made) {
        setMade(false);
      }
      setIngredient(true);
    } else if (cat === "--Select One--") {
      setMade(false);
      setIngredient(false);
      setCat("");
      setShowIngredients(false);
    } else {
      if (ingredient) {
        setIngredient(false);
      }
      setMade(true);
      setShowIngredients(true);
    }
  };

  //adds the quantity
  const handleQuantity = (amount, index) => {
    if (parseInt(amount) < 0) {
      document.getElementById("add_prod_btn").style.display = "none";
      setSeverity("warning");
      setMessage("Amount cannot be smaller than zero");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        setSeverity("");
        setMessage("");
        document.getElementById("add_prod_btn").style.display = "flex";
      }, 3000);
    } else {
      ingredients[index].quantity = amount;
    }
  };

  //adds allergens to array
  const handleAllergens = (allergen) => {
    let findAllergen = allergens.findIndex((a) => a === allergen);
    if (findAllergen === -1) {
      setAllergens([...allergens, allergen]);
    } else {
      allergens.splice(findAllergen, 1);
    }
  };

  const handleModifyProdAllergen = (allergen) => {
    let findAllergen = allergens.findIndex((a) => a == allergen);
    if (findAllergen === -1) {
      setAllergens([...allergens, allergen]);
    } else {
      allergens.splice(findAllergen, 1);
    }
  };

  return (
    <section className="add_prod_form">
      <div className="form__wrapper">
        <div className="form">
          <label>
            Category <span>*</span>
          </label>
          <select onChange={(e) => handleCat(e.target.value)} id="categories">
            <option>--Select One--</option>
            {categoryList.map(function (category, idx) {
              return <option value={category.eposId}>{category.name}</option>;
            })}
          </select>
          <label>
            Name <span>*</span>
          </label>
          <input
            type="text"
            maxLength="40"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
          {!ingredient ? (
            <>
              <label>
                Price <span>*</span>
              </label>
              <input
                type="number"
                pattern="[0-9]*"
                onChange={(e) => setPrice(e.target.value)}
                value={price}
              />
            </>
          ) : (
            <></>
          )}

          <label>
            Cost Price <span>*</span>
          </label>
          <input
            type="number"
            pattern="[0-9]*"
            onChange={(e) => setCost(e.target.value)}
            value={cost}
          />
          {made ? (
            <>
              <label>
                Use By: <span>*</span>
              </label>
              <div className="form_row">
                <div className="row__column">
                  <label>Days</label>
                  <select onChange={(e) => setDays(e.target.value)}>
                    <option>Select Days</option>
                    <option>0</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                  </select>
                </div>
                <div className="row__column">
                  <label>Hours</label>
                  <select onChange={(e) => setHours(e.target.value)}>
                    <option>Select Hours</option>
                    <option>0</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                    <option>11</option>
                    <option>12</option>
                    <option>13</option>
                    <option>14</option>
                    <option>15</option>
                    <option>16</option>
                    <option>17</option>
                    <option>18</option>
                    <option>19</option>
                    <option>20</option>
                    <option>21</option>
                    <option>22</option>
                    <option>23</option>
                  </select>
                </div>
                <div className="row__column">
                  <label>Minutes</label>
                  <select onChange={(e) => setMinutes(e.target.value)}>
                    <option>Select Minutes</option>
                    <option>0</option>
                    <option>5</option>
                    <option>10</option>
                    <option>15</option>
                    <option>20</option>
                    <option>25</option>
                    <option>30</option>
                    <option>35</option>
                    <option>40</option>
                    <option>45</option>
                    <option>50</option>
                    <option>55</option>
                  </select>
                </div>
              </div>
              <label>Made of:</label>
              <div className="form_row">
                <input
                  list="products"
                  onChange={(e) => searchProduct(e.target.value)}
                  placeholder="Select Product"
                  id="search_input"
                />
                <datalist id="products">
                  {ingCat.map(function (product, idx) {
                    return (
                      <option value={product.eposId}>{product.name}</option>
                    );
                  })}
                </datalist>
              </div>
              <label>Select allergens:</label>
              <div className="allergens__selection">
                {allergenList.map(function (allergen, idx) {
                  return (
                    <div className="allergen__row">
                      <input
                        type="checkbox"
                        value={allergen.allergenId}
                        name={allergen.name}
                        onChange={(e) =>
                          handleModifyProdAllergen(e.target.value)
                        }
                        id={allergen.name}
                        className="checkboxes_alergens"
                      />
                      <label for={allergen.name}>{allergen.name}</label>
                    </div>
                  );
                })}
              </div>
            </>
          ) : ingredient ? (
            <>
              <label>
                Barcode: <span>*</span>
              </label>
              <input
                type="text"
                value={barcode}
                onChange={(e) => setBarcode(e.target.value)}
              />
              <label>
                Amount: <span>*</span>
              </label>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  width: "50%",
                }}
              >
                <input
                  type="number"
                  pattern="[0-9]*"
                  onChange={(e) => setPackQty(e.target.value)}
                />
                <select onChange={(e) => setIngUnit(e.target.value)}>
                  <option>-- Select Units --</option>
                  {unitTypes.map(function (d, i) {
                    return <option value={d.typeId}>{d.name}</option>;
                  })}
                </select>
              </div>

              <label>Select allergens:</label>
              <div className="allergens__selection">
                {allergenList.map(function (allergen, idx) {
                  return (
                    <div className="allergen__row">
                      <input
                        type="checkbox"
                        value={allergen.allergenId}
                        name={allergen.name}
                        onChange={(e) => handleAllergens(e.target.value)}
                        id={idx}
                      />
                      <label for={allergen.name}>{allergen.name}</label>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <></>
          )}
          {ingredients.length > 0 ? (
            <>
              <table>
                <thead>
                  <th>Name</th>
                  <th>Product Code</th>
                  <th>Quantity</th>
                  <th>Delete</th>
                </thead>
                <tbody>
                  {ingredients.map(function (ingredient, idx) {
                    return (
                      <tr>
                        <td>{ingredient.name}</td>
                        <td>{ingredient.productCode}</td>
                        <td>
                          <input
                            type="number"
                            onChange={(e) =>
                              handleQuantity(e.target.value, idx)
                            }
                            pattern="[0-9]*"
                          />
                        </td>
                        <td onClick={() => handleDeleteIngredient(idx)}>
                          <RemoveCircleOutlineIcon />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          ) : (
            <></>
          )}
        </div>
        {showIngredients && !ingredient ? (
          <div className="form">
            <label>Ingredients:</label>
            <textarea
              cols={50}
              rows={10}
              onChange={(e) => setIngredientList(e.target.value)}
            />
            <label>
              Description <span>*</span>
            </label>
            <textarea
              cols={40}
              rows={5}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      <Button
        variant="contained"
        color="success"
        id="add_prod_btn"
        onClick={() => handleAddProduct()}
      >
        Add
      </Button>
      {showAlert ? (
        <Alert severity={severity} sx={{ marginTop: "2%" }}>
          {message}
        </Alert>
      ) : (
        <></>
      )}
      {showTick ? <Tick /> : <></>}
    </section>
  );
}
