import VehicleDetails from "../modals/VehicleDetails";
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import SpeakerNotesOffIcon from "@mui/icons-material/SpeakerNotesOff";
import Contract from "../modals/Contract";
import moment from "moment";
import ListIcon from "@mui/icons-material/List";
import StatusChange from "../modals/StatusChange";
import NotesModal from "../../../../devZone/cctv/modals/NotesModal";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import Images from "../modals/Images";
import MarkPaid from "../modals/MarkPaid";
import CurrencyPoundIcon from "@mui/icons-material/CurrencyPound";

export default function NMOPIndiviudalStatus({
    request,
    siteName,
    rel,
    statusList,
}) {
    const [showVehicleDetailModal, setShowVehicleDetailModal] = useState(false);
    const [showContractModal, setShowContractModal] = useState(false);
    const [showStatusChangeModal, setShowStatusChangeModal] = useState(false);
    const [fullDate, setFullDate] = useState("");
    const [showNotesModal, setShowNotesModal] = useState(false);
    const [showImagesModal, setShowImagesModal] = useState(false);
    const [count, setCount] = useState(0);
    const [currentStatus, setCurrentStatus] = useState("");
    const [showPaidModal, setShowPaidModal] = useState(false);

    useEffect(() => {
        //Formats the date & time
        setFullDate(request.submissionDate);

        for (let i = 0; i < statusList.length; i++) {
            if (statusList[i].statusId === request.statusId) {
                setCurrentStatus(statusList[i].name);
            }
        }
    }, [rel]);

    useEffect(() => {
        // Change drive off countdown in realtime
        setTimeout(() => {
            setCount(count + 1);
            handleTimeToDriveOff(request.submissionDate);
        }, 1000);
    }, [count]);

    const handleTimeToDriveOff = (submitted) => {
        //Get End date (3 days from submitted day)
        let endDate = moment(submitted).add(3, "d");
        //Current Date Time
        let currDate = moment();
        //Duration between the date Times
        let duration = moment.duration(endDate.diff(currDate));

        return (
            duration._data.days +
            "d " +
            duration._data.hours +
            "h " +
            duration._data.minutes +
            "m " +
            duration._data.seconds +
            "s"
        );
    };

    return (
        <>
            <tr className="nmop_row">
                <th>
                    {new Date(request.date).toLocaleDateString("en-GB")} -
                    {request.time}
                </th>
                <th
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowVehicleDetailModal(true)}
                >
                    {request.plate}
                </th>
                <th
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                    onClick={() => {
                        setShowStatusChangeModal(true);
                    }}
                >
                    {" "}
                    <ListIcon
                        color="primary"
                        style={{ marginRight: "10px", cursor: "pointer" }}
                    />
                    {currentStatus}
                </th>
                <th>
                    <VisibilityIcon
                        onClick={() => setShowContractModal(true)}
                        color="primary"
                        style={{ cursor: "pointer" }}
                    />
                </th>
                <th>{request.phone}</th>
                <th>{handleTimeToDriveOff(fullDate)}</th>
                <th>
                    <CameraAltIcon
                        style={{ cursor: "pointer" }}
                        color="primary"
                        onClick={() => setShowImagesModal(true)}
                    />
                </th>
                <th>
                    {request.notes.length > 0 ? (
                        <SpeakerNotesIcon
                            color="primary"
                            style={{ cursor: "pointer" }}
                            onClick={() => setShowNotesModal(true)}
                        />
                    ) : (
                        <SpeakerNotesOffIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => setShowNotesModal(true)}
                        />
                    )}
                </th>
                <th>
                    <CurrencyPoundIcon
                        color="primary"
                        onClick={() => setShowPaidModal(true)}
                        style={{ cursor: "pointer" }}
                    />
                </th>
            </tr>
            {/* MODALS */}
            <Modal
                aria-labelledby="Vehicle Details Modal"
                aria-describedby="Vehicle Details Modal"
                open={showVehicleDetailModal}
            >
                <VehicleDetails
                    setShowVehicleDetailModal={setShowVehicleDetailModal}
                />
            </Modal>
            <Modal
                aria-labelledby="NMOP Contract Modal"
                aria-describedby="NMOP Contract Modal"
                open={showContractModal}
            >
                <Contract
                    setShowContractModal={setShowContractModal}
                    siteName={siteName}
                    request={request}
                />
            </Modal>

            <Modal
                aria-labelledby="Status Change Modal"
                aria-describedby="Status Change Modal"
                open={showStatusChangeModal}
            >
                <StatusChange
                    setShowStatusChangeModal={setShowStatusChangeModal}
                    cStatus={request.statusId}
                    id={request.requestId}
                    rel={rel}
                />
            </Modal>
            <Modal
                aria-labelledby="Notes Modal"
                aria-describedby="Notes Modal"
                open={showNotesModal}
            >
                <NotesModal
                    handleCloseNotes={setShowNotesModal}
                    allNotes={request.notes}
                    id={request.requestId}
                    rel={rel}
                    requestType={request.requestType}
                />
            </Modal>
            <Modal
                aria-labelledby="Images Modal"
                aria-describedby="Images Modal"
                open={showImagesModal}
            >
                <Images
                    driver={request.driverImg}
                    item={request.itemLeftImg}
                    sig={request.signatureImg}
                    setShowImagesModal={setShowImagesModal}
                />
            </Modal>
            <Modal
                aria-labelledby="Mark Paid Modal"
                aria-describedby="Mark Paid Modal"
                open={showPaidModal}
            >
                <MarkPaid
                    setShowPaidModal={setShowPaidModal}
                    rel={rel}
                    requestId={request.requestId}
                />
            </Modal>
        </>
    );
}
