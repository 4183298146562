import './loader.scss'

export default function Loader(){
    return(
        <section className="around">
            <div className="sphere">
            <div className="border"></div>
            </div>
            
        </section>
    )
}