import { useState, useEffect } from "react";
import DriveOffIndividual from "./DriveOffIndividual";
import axios from "axios";
import { Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Loader from "../../animations/Loader";

export default function DriveoffRequests({ statusList }) {
    const url = process.env.REACT_APP_ANPR_URL;
    const [requests, setRequests] = useState([]);
    const [fuelData, setFuelData] = useState([]);
    const [reload, setReload] = useState(false);
    const [sorted, setSorted] = useState(false);
    const [disable, setDisable] = useState(false);
    const [plate, setPlate] = useState("");
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        setLoader(true);
        axios
            .get(
                `${url}/cctv-system/submissions/details/allActive?driveoff=true`
            )
            .then((res) => {
                setRequests(res.data.cctv);
                setFuelData(res.data.fuelData);
                setLoader(false);
            })
            .catch((err) => {
                console.log(err);
                setLoader(false);
            });
    }, [reload]);

    const rel = () => {
        setReload(!reload);
    };

    //Filters
    const sortSite = () => {
        setSorted(!sorted);
        let siteData = requests.sort((a, b) =>
            a.siteName.localeCompare(b.siteName)
        );
        setRequests(siteData);
    };

    const sortDate = () => {
        setSorted(!sorted);
        let dateData = requests.sort(
            (a, b) => new Date(a.date) - new Date(b.date)
        );
        setRequests(dateData);
    };

    const sortStatus = () => {
        setSorted(!sorted);
        let statusData = requests.sort((a, b) => {
            return a.statusId - b.statusId;
        });

        setRequests(statusData);
    };

    const search = () => {
        if (plate == "") {
            rel();
        } else {
            axios
                .get(`${url}/cctv-system/submission/search?search=${plate}`)
                .then((res) => {
                    setDisable(true);
                    setTimeout(() => {
                        setDisable(false);
                    });
                    setRequests(res.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    return (
        <main className="cctv__requests">
            <h1 className="cctv-header">Drive Off - Dev Area</h1>

            <div className="drive-off-search">
                <input
                    type="text"
                    focus
                    onChange={(e) => setPlate(e.target.value)}
                    placeholder="Search Plate or Ref"
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            search();
                        }
                    }}
                />
                <Button
                    color="primary"
                    variant="contained"
                    disabled={disable}
                    onClick={search}
                    disableElevation
                >
                    <SearchIcon />
                </Button>{" "}
                <p className="notif_sub_level">Pending: {fuelData.length}</p>
            </div>

            {!loader ? (
                requests?.length > 0 ? (
                    <section className="cctv-dev-table-container">
                        <table className="cctv__table">
                            <thead>
                                <tr>
                                    <th
                                        className="filter_heading"
                                        onClick={sortSite}
                                    >
                                        Site
                                    </th>
                                    <th
                                        className="filter_heading"
                                        onClick={sortDate}
                                    >
                                        Date
                                    </th>
                                    <th>Pump</th>
                                    <th>Registration</th>
                                    <th>Amount</th>

                                    <th>Staff</th>
                                    <th
                                        className="filter_heading"
                                        onClick={sortStatus}
                                    >
                                        Status
                                    </th>
                                    <th>Crime Ref</th>
                                    <th>Storage</th>
                                    <th>Photos</th>
                                    <th>Notes</th>
                                    <th>QR</th>
                                    <th>CCTV Driveoff Requests</th>
                                    <th>Investigation</th>
                                </tr>
                            </thead>
                            <tbody>
                                {requests && requests.length > 0 ? (
                                    requests.map((request, i) => {
                                        let currentStatus = "";
                                        for (
                                            let i = 0;
                                            i < statusList.length;
                                            i++
                                        ) {
                                            if (
                                                statusList[i].statusId ===
                                                request.statusId
                                            ) {
                                                currentStatus =
                                                    statusList[i].name;
                                            }
                                        }
                                        return (
                                            <DriveOffIndividual
                                                request={request}
                                                currentStatus={currentStatus}
                                                statusList={statusList}
                                                rel={rel}
                                                key={i}
                                                fuelData={fuelData}
                                            />
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <th colSpan={14}>No open requests</th>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </section>
                ) : (
                    <h2>No Drive Off Requests</h2>
                )
            ) : (
                <Loader />
            )}
        </main>
    );
}
