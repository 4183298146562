import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Box from "@mui/material/Box";
import { Button, Alert } from "@mui/material";

//STYLING
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60vw",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
    "@media(max-width: 600px)": {
        width: "90vw",
        maxHeight: "70vh",
        overflowX: "scrollable",
    },
};

export default function StatusChange({
    setShowStatusChangeModal,
    cStatus,
    id,
    rel,
}) {
    const url = process.env.REACT_APP_ANPR_URL;
    const [staff, setStaff] = useState("");
    const [status, setStatus] = useState("");
    const [message, setMessage] = useState("");
    const [alert, setAlert] = useState(false);
    const [severity, setSeverity] = useState("");
    const [statusList, setStatusList] = useState([]);
    const [currentStatus, setCurrentStatus] = useState("");

    useEffect(() => {
        axios
            .get(`${url}/cctv-system/statuses`)
            .then((res) => {
                let statuses = res.data;
                setStatusList(res.data);

                for (let i = 0; i < statuses.length; i++) {
                    if (cStatus === statuses[i].statusId) {
                        setCurrentStatus(statuses[i].name);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [rel]);

    const handleStatusUpdate = () => {
        let statusId = "";

        for (let i = 0; i < statusList.length; i++) {
            if (statusList[i].name === status) {
                statusId = statusList[i].statusId;
            }
        }
        let data = {
            requestId: id,
            status: statusId,
            user: staff,
        };

        if (status === "") {
            setSeverity("warning");
            setMessage("Please select a status");
            setAlert(true);
            setTimeout(() => {
                setAlert(false);
                setSeverity("");
                setMessage("");
            }, 3500);
        } else if (staff === "") {
            setSeverity("warning");
            setMessage("Please provide your name");
            setAlert(true);
            setTimeout(() => {
                setAlert(false);
                setSeverity("");
                setMessage("");
            }, 3500);
        } else {
            axios
                .post(`${url}/cctv-system/submission/update/status`, data)
                .then((res) => {
                    setSeverity("success");
                    setMessage("Status successfully updated");
                    setAlert(true);
                    setTimeout(() => {
                        setAlert(false);
                        setSeverity("");
                        setMessage("");
                        rel();
                        setShowStatusChangeModal(false);
                    }, 3500);
                })
                .catch((err) => {
                    console.log(err);
                    setSeverity("error");
                    setMessage("Something went wrong, please try again");
                    setAlert(true);
                    setTimeout(() => {
                        setAlert(false);
                        setSeverity("");
                        setMessage("");
                    }, 3500);
                });
        }
    };

    return (
        <Box sx={style} className="all_modal">
            <Typography id="status_header" variant="h6" component="h2">
                <h3 style={{ textAlign: "center", width: "100%" }}>
                    Current Status:{" "}
                    <span style={{ color: "#FF0000" }}>{currentStatus}</span>
                </h3>
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <div className="status_modal">
                    <div>
                        <label>Not Required</label>

                        <input
                            name="radAnswer"
                            type="radio"
                            value="Not Required"
                            onChange={(e) => {
                                setStatus(e.target.value);
                            }}
                        />
                    </div>
                    <hr />
                    <div>
                        <label>Paid</label>
                        <input
                            name="radAnswer"
                            type="radio"
                            value="Paid"
                            onChange={(e) => {
                                setStatus(e.target.value);
                            }}
                        />
                    </div>
                    <hr />
                    <div>
                        <label>Your Name:</label>
                        <input
                            type="text"
                            onChange={(e) => setStaff(e.target.value)}
                            value={staff}
                        />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "3%",
                        }}
                    >
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => setShowStatusChangeModal(false)}
                        >
                            Close
                        </Button>
                        {!alert ? (
                            <Button
                                variant="contained"
                                color="primary"
                                className="button"
                                id="status-btn"
                                onClick={() => handleStatusUpdate()}
                            >
                                Send
                            </Button>
                        ) : (
                            <Alert severity={severity}>{message}</Alert>
                        )}
                    </div>
                </div>
            </Typography>
        </Box>
    );
}
