import {useState} from 'react';

export default function ReportCard(props) {
  const [site, setSite] = useState(props.site)

  return (
    <section className="report__card">
      <strong>{site.siteName}</strong>
      <p>Transactions: <span>{site.transactions}</span></p>
      <p>Total Sales: <span>£{parseFloat(site.sales).toFixed(2)}</span></p>
    </section>
  );
}
