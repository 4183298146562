import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Button from '@mui/material/Button';
import axios from 'axios';


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40vw",
    height: "20vh",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
};

export default function PromotionConfirm({
    handleClose,
    dealId,
    user,
    url
}) {

    const [promotionId, setPromotionId] = useState(null);

    const completeDeal = async () => {

        if (promotionId != "" || promotionId != null) {


            let data = {
                dealId: dealId,
                user: user,
                promotionId: promotionId
            }


            await axios.post(`${url}/promotions/deal/confirmed`, data)
                .then((res) => {
                    handleClose();
                    setPromotionId(null);
                }).catch((err) => {
                    console.log(err)
                })
        }
    }

    return (
        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Please insert the Promotion ID
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <Stack spacing={2} alignItems="center">
                    <input type="number" onChange={(e) => setPromotionId(e.target.value)} />
                    <Stack direction="row" spacing={2}>

                        <Button variant="outlined" color="error" onClick={() => handleClose()}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="success" onClick={() => completeDeal()}>
                            Confirm
                        </Button>
                    </Stack>
                </Stack>
            </Typography>
        </Box>
    );
}
