import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import {useState, useEffect} from 'react'
import "../eod.scss";
import Button from "@mui/material/Button";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40vw",
    minHeight: "20vw",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
};
  
export default function Discrepancy(props){

return(
    <Box sx={style}>
    <Typography id="modal-modal-title" variant="h6" component="h2">
      Please confirm
    </Typography>
    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
      <div className="disc__modal">
        <h2>Expected: £{props.expected}</h2>
        <h2>Actual: £{props.actual.toFixed(2)}</h2>
        {props.discrepancy < 0 || props.discrepancy > 0 ? (
          <h1>
            Discrepancy:£
            <span style={{ color: "#D90D1E" }}>
              {props.discrepancy.toFixed(2)}
            </span>
          </h1>
        ) : (
          <h2>
            Discrepancy:£
            <span style={{ color: "#71A621" }}>
              {props.discrepancy.toFixed(2)}
            </span>
          </h2>
        )}
        <Stack
          direction="row"
          spacing={2}
          sx={{ width: "25%", margin: "auto" }}
        >
          <Button variant="outlined" color="error" onClick={()=>props.close()}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={() => props.acceptDiscrepancy()}
          >
            Send
          </Button>
        </Stack>
      </div>
    </Typography>
  </Box>

)
}