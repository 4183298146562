import { useState, useEffect, useContext } from "react";
import "./styles/home.scss";
import NavBar from "./NavBar";
import Footer from "./Footer";
import axios from "axios";
import UserContext from "./context/userContext";
import { useNavigate, Navigate } from "react-router-dom";
import LinkIcon from "@mui/icons-material/Link";
import { WeatherWidget } from "@daniel-szulc/react-weather-widget";
import LoginTest from "./LoginTest";

export default function Home(props) {
  const navigate = useNavigate();
  const { userData } = useContext(UserContext);
  const [lastItems, setLastItems] = useState([]);
  const [user, setUser] = useState("");
  const [hour, setHour] = useState(0);
  const [role, setRole] = useState("");

  useEffect(() => {
    let userD = localStorage.getItem("userDetails");
    if(userD!==null){
      let user = JSON.parse(userD);
    setRole(user.user.role);
    if (
      user.user.role !== "Developer" &&
      user.user.role !== "Manager" &&
      user.user.role !== "Admin" &&
      user.user.role !== "HR"
    ) {
      return <Navigate to="/" />;
    } else {
      axios
        .get("https://nitropos-backend.nitrosolutions.co.uk/booker/log")
        .then((res) => {
          setLastItems(res.data[2]);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    var username = "";
    var split = user.user.user.split("@");
    username = split[0].replace(".", " ");
    setUser(username);
    
    let today = new Date();
    let hours = today.getHours();
    setHour(hours);
    }
    
  }, [window.innerWidth]);

  return (
    <>
      {
        userData.user ?
          <section className="home">
            <NavBar />
            <div className="dashContent">
              <div className="welcome__bar">
                {hour < 12 ? (
                  <b>Good Morning</b>
                ) : hour > 12 && hour < 17 ? (
                  <b>Good Afternoon</b>
                ) : hour > 17 && hour < 21 ? (
                  <b>Good Evening</b>
                ) : (
                  <b>Hello</b>
                )}
                <b>{user}</b>
              </div>
              <div className="topBar">
                <div className="weather">
                  <WeatherWidget
                    // autoLocate="gps"
                    location="Glasgow"
                    tempUnit="C"
                    windSpeedUnit="mph"
                    lang="en"
                  />
                </div>
                <section className="rightSect">
                  <div className="cardTable">
                    <h2>Latest Items added</h2>
                    <table>
                      <thead>
                        <tr style={{ backgroundColor: "#2c53a0" }}>
                          <th style={{ color: "#fff" }}>Item Code</th>
                          <th style={{ color: "#fff" }}>Item</th>
                          <th style={{ color: "#fff" }}>Barcode</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lastItems ? (
                          lastItems.map(function (d, i) {
                            return (
                              <tr>
                                <td>
                                  <a
                                    href={`https://www.booker.co.uk/products/product-list?keywords=${d.OrderCode}`}
                                    target="_blank"
                                    style={{
                                      cursor: "pointer",
                                      display: "flex",
                                      justifyContent: "center",
                                      flexWrap: "wrap",
                                      color: "#000",
                                      width: "100%",
                                    }}
                                  >
                                    <LinkIcon
                                      sx={{ color: "#4d4d4d", marginTop: "0" }}
                                      fontSize="small"
                                    />
                                    {d.OrderCode}
                                  </a>
                                </td>
                                <td>{d.name}</td>
                                <td style={{ textAlign: "center" }}>{d.barcode}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </table>
                  </div>
                </section>
              </div>
            </div>
          
          </section>
          : <><LoginTest/></>
      }
    </>
  );
}
