import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { IconButton } from "@mui/material";

export default function IndvInvoice(props) {
  const invoice = props.invoice;
  const [showInvoice, setShowInvoice] = useState(false);

  const handleInvoice = () => {
    setShowInvoice(!showInvoice)
  }

  return (
    <section className="invoice_header">
      <div className="header">
        <h2>
          Invoice date: <span>{invoice.invoiceDate}</span>
        </h2>
        <h2>
          Invoice Number: <span>{invoice.invoiceNumber}</span>
        </h2>
      </div>
      <IconButton className="icon" onClick={handleInvoice}>
        <KeyboardArrowDownIcon color="primary" />
      </IconButton>
      {showInvoice ? (
        <section className="invoice">
          <table>
            <thead>
              <tr>
                <th>Product Name</th>
                <th>Qty Ordered</th>
                <th>Qty Received</th>
                <th>Qty Picked</th>
                <th>Deducted from warehouse</th>
                <th>Added to store stock</th>
              </tr>
            </thead>
            <tbody>
                {invoice.productsOrdered.map((product) => {
                var rowCol;product.qtyReceived!==product.picked?rowCol="orange":rowCol="";
                    return(
                        <tr style={{backgroundColor:rowCol}} key={product.Barcode}>
                            <td>{product.Name}</td>
                            <td>{product.qtyOrdered}</td>
                            <td>{product.qtyReceived}</td>
                            <td>{product.picked}</td>
                            <td>{product.warehouseDeducted ? <>YES</> : <>NO</>}</td>
                            <td>{product.eposAdjusted ? <>YES</> : <>NO</>}</td>
                        </tr>
                    )
                })}
                <tr>
                    <td colSpan={6}>Note: <strong>{invoice.note}</strong></td>
                </tr>
                <tr>
                    <td colSpan={6}>Processed by: <strong>{invoice.staffName}</strong></td>
                </tr>
            </tbody>
          </table>
        </section>
      ) : null}
    </section>
  );
}
