// Displays all of the labels

import React, { useState, useEffect } from "react";
import styles from "./styles/Main.module.css";
import Label from "./Label";

export default function Labels(props) {
    const [labels, setLabels] = useState([]);

    useEffect(() => {
        setLabels([]); // Set the labels to an empty array so that the array doesn't balloon with duplicates

        // For every result, make a label
        props.results.forEach((result, i) => {
            setLabels((labels) => [
                <Label
                    clicked={props.clicked}
                    barcode={result.Barcode}
                    price={result.SalePrice}
                    name={result.Name}
                    item={result}
                    key={Math.random()}
                    id={props.results.length - i}
                />,
                ...labels,
            ]);
        });
    }, [props.results]);

    return (
        <div className="col-8 offset-2 mb-4">
            <div id="capture" className={`${styles.labelArea}`}>
                <div className="shown"></div>
                {/* {labels.slice(0, 28)} */}
                {labels}
            </div>
        </div>
    );
}
