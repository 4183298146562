import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
  maxHeight: "70vh",
  overflowY: "scroll",
  "@media(max-width: 690px)": {
    width: "90vw",
    maxHeight: "90vh",
  },
};

const NotesModal = ({ rotaNotes, rotaId, setNoteModal, reloadData }) => {
  const hrUrl = process.env.REACT_APP_API_URL;
  // const hrUrl = "http://10.0.0.174:4030";
  const [note, setNote] = useState("");
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [user, setUser] = useState("");
  const [disable, setDisable] = useState(false);

  //TODO: SET CURRENT USER FROM LOCALSTORAGE
  useEffect(() => {
    let currentUser = JSON.parse(localStorage.getItem("userDetails"));
    setUser(currentUser.user.user);
    console.log(rotaNotes);
  }, []);

  const showAlert = (message, severity) => {
    setDisable(true);
    setMessage(message);
    setSeverity(severity);
    setAlert(true);
    setTimeout(() => {
      setDisable(false);
      setAlert(false);
      setMessage("message");
      setSeverity("severity");
    }, 3000);
  };

  const addNote = async () => {
    if (note === "") {
      showAlert("Please Insert a note", "warning");
    } else {
      let data = {
        rotaId: rotaId,
        user: user,
        note: note,
      };
      console.log(data);
      await axios
        .post(`${hrUrl}/rota/notes/add`, data)
        .then((res) => {
          showAlert("Notes Successfully saved", "success");
          setNote("");
          reloadData();
        })
        .catch((err) => {
          console.log(err);
          showAlert("Could not save note - Please try again", "error");
        });
    }
  };

  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        NOTES
      </Typography>

      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <table className="modal__tables notes_table" style={{ width: "100%" }}>
          <thead style={{ backgroundColor: "#2c53a0", color: "#fff" }}>
            <tr>
              <th>Note</th>
              <th>Added by</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {rotaNotes && rotaNotes.length > 0 ? (
              rotaNotes.map((note, i) => {
                return (
                  <tr className="row" key={i}>
                    <th>{note.note}</th>
                    <th>{note.user}</th>
                    <th>{note.dateAdded}</th>
                  </tr>
                );
              })
            ) : (
              <tr>
                <th colspan={3} style={{ textAlign: "center" }}>
                  No notes
                </th>
              </tr>
            )}
          </tbody>
        </table>
        <hr />
        <div>
          <h4>
            <u>Add note</u>
          </h4>
          <label>Note:</label>
          <input
            style={{
              width: "100%",
              padding: "5px",
              margin: "10px",
              borderRadius: "8px",
              border: "1px solid #2c53a0",
            }}
            type="text"
            placeholder="Enter Note..."
            onChange={(e) => setNote(e.target.value)}
            value={note}
          />
          <br />
          {alert ? (
            <Alert severity={severity}>{message}</Alert>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "3%",
              }}
            >
              <Button
                variant="contained"
                color="error"
                onClick={() => setNoteModal(false)}
                disable={disable}
              >
                Close
              </Button>
              <Button
                variant="contained"
                color="primary"
                id="addNote-btn"
                onClick={() => addNote()}
                disable={disable}
              >
                Add Note
              </Button>
            </div>
          )}
        </div>
      </Typography>
    </Box>
  );
};

export default NotesModal;
