import { useState, useEffect } from "react";
import axios from "axios";
//MUI
import Box from "@mui/material/Box";
import { Headline } from "./Headline";

//INTERFACES
interface Props {
    reportCategory: string;
    breakdownURL?: string;
    start?: string;
    end?: string;
    selectedSites?: any;
    innerBreakdownURL?: string;
}

//MODAL STYLING
const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 900,
    bgcolor: "background.paper",
    border: "2px solid #2c53a0",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
    height: "60%",
};

export default function ReportBreakdown({
    reportCategory,
    breakdownURL,
    start,
    end,
    selectedSites,
}: Props) {
    const url = process.env.REACT_APP_DEPPLOYED;
    // const url = "http://10.0.0.34:4022";
    const [breakdownData, setBreakdownData] = useState([]);
    const [innerBreakdownURL, setInnerBreakdownURL] = useState('');

    useEffect(() => {
        let data = {
            siteId: selectedSites,
            start: start,
            end: end,
            categoryId: reportCategory,
        };
        axios
            .post(`${url}/dashboard/nitro/${breakdownURL}`, data)
            .then((res) => {
                console.log(res.data);
                setBreakdownData(res.data);
                if (res.data.breakdown) {
                    setInnerBreakdownURL(res.data.breakdown);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);
    return (
        <Box sx={style}>
            {breakdownData
                ? breakdownData.map((d: any, i: number) => {
                      return (
                          <Headline
                              graph={d.chart}
                              name={d.name}
                              xAxis={d.x}
                              series={d.series}
                              money={d.money}
                              waste={d.waste}
                              innerBreakdownURL={innerBreakdownURL}
                          />
                      );
                  })
                : null}
        </Box>
    );
}
