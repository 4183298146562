import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};
const StatusModal = ({
    statusList,
    currentStatus,
    reqId,
    rel,
    handleCloseStatus,
    isDev,
    currentStatusId,
    siteName,
    siteId,
}) => {
    const url = process.env.REACT_APP_ANPR_URL;
    // const url = "http://10.0.0.157:4024";
    const [status, setStatus] = useState(currentStatusId);
    const [staff, setStaff] = useState("");
    const [alert, setAlert] = useState(false);
    const [severity, setSeverity] = useState("");
    const [message, setMessage] = useState("");
    const [user, setUser] = useState("");

    useEffect(() => {
        console.log(statusList);
        let userD = localStorage.getItem("userDetails");
        let currentUser = JSON.parse(userD);
        setUser(currentUser.user.user);
    }, []);

    const handleStatusChange = async () => {
        if (status === "") {
            setSeverity("warning");
            setMessage("Please select a status");
            setAlert(true);
            setTimeout(() => {
                setAlert(false);
                setSeverity("");
                setMessage("");
            }, 3500);
        } else if (!isDev && staff === "") {
            setSeverity("warning");
            setMessage("Please provide your name");
            setAlert(true);
            setTimeout(() => {
                setAlert(false);
                setSeverity("");
                setMessage("");
            }, 3500);
        } else {
            let data = {
                requestId: reqId,
                status: status,
                user: isDev ? user : staff,
                siteName: siteName,
                siteId: siteId,
            };

            await axios
                .post(`${url}/cctv-system/submission/update/status`, data)
                .then((res) => {
                    console.log("success!");
                    setSeverity("success");
                    setMessage("Status successfully updated");
                    setAlert(true);
                    setTimeout(() => {
                        setAlert(false);
                        setSeverity("");
                        setMessage("");
                        rel();
                        handleCloseStatus(false);
                    }, 3500);
                    handleCloseStatus(false);
                })
                .catch((err) => {
                    console.log(err);
                    setSeverity("error");
                    setMessage("Something went wrong, please try again");
                    setAlert(true);
                    setTimeout(() => {
                        setAlert(false);
                        setSeverity("");
                        setMessage("");
                    }, 3500);
                });
        }
    };
    return (
        <Box sx={style} className="cctv-status-modal">
            <h1>Status Change</h1>
            <p>The current status of this request is</p>
            <p className="existing-status">{currentStatus}</p>
            <p>
                {" "}
                Select a new one below and submit to change the status of this
                request.
            </p>
            <select value={status} onChange={(e) => setStatus(e.target.value)}>
                {statusList?.length > 0
                    ? statusList.map((s, i) => {
                          return (
                              <option key={i} value={s.statusId}>
                                  {s.name}
                              </option>
                          );
                      })
                    : null}
            </select>
            {!isDev ? (
                <>
                    <label>Please provide your name:</label>
                    <input
                        type="text"
                        onChange={(e) => setStaff(e.target.value)}
                        value={staff}
                        style={{
                            borderRadius: "5px",
                            width: "100%",
                            height: "4vh",
                            marginBottom: "1vh",
                        }}
                    />
                </>
            ) : null}
            {alert ? <Alert severity={severity}>{message}</Alert> : <></>}
            <div className="btn-section">
                <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleCloseStatus(false)}
                >
                    Close
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    className="button"
                    id="status-btn"
                    onClick={handleStatusChange}
                >
                    Send
                </Button>
            </div>
        </Box>
    );
};

export default StatusModal;
