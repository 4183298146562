//use for Warehouse parts of NitroPOS

import { Navigate } from "react-router-dom";

const WarehouseProtect = ({children}) => {
    let userD = localStorage.getItem("userDetails");
    let user = JSON.parse(userD);
    console.log(user)
    if (
        user.user.role !== "Warehouse Manager" &&
        user.user.role !== "Developer" && 
        user.user.role !== "V4U Manager" &&
        user.user.role !== "Warehouse Packer" &&
        user.user.role !== "Warehouse Assistant" &&
        user.user.user !== "guna@ghsl.uk"
      ) {
        return <Navigate to="/not-allowed" replace/>
    }  
    return children
}

export {WarehouseProtect}