import { useState, useEffect } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import axios from 'axios';

export default function ParentModal(props) {
  const [allergenList, setAllergenList] = useState(props.allergenList);
  const [parent, setParent] = useState(props.product);
  const [parentAllergens, setParentAllergens] = useState(
    props.product.allergens
  );
  const [childAllergens, setChildAllergens] = useState(props.childAllergens);
  const [reload, setReload] = useState(false);

  useEffect(() => {
  }, [reload]);

  const rel = () => {
    setReload(!reload);
  };

  const handleAllergen = async (allergen, remove) => {
    if (remove === "remove") {
      let isChild = false;
      childAllergens.forEach((child) => {
        if (child == allergen) {
          props.handleAlert(
            "warning",
            "This allergen comes from a child and cannot be removed"
          );
          isChild = true;
        }
      });
      if (!isChild) {
        let removeAllergen = parentAllergens.findIndex((a) => a === allergen);
        console.log(removeAllergen);
        if (removeAllergen === -1) {
          parentAllergens.splice(removeAllergen, 1);
          await updateAllergen();
        }
      }
    } else {
      parentAllergens.push({ allergenId: allergen, new: true });
      await updateAllergen();
    }
  };

  const updateAllergen = () => {
    let data = {
      eposId: parent.eposId,
      allergens: parentAllergens,
    };
    axios.post("https://backend.hoagies.co.uk/food/epos/product/allergen", data)
    .then((res) => {
        props.handleAlert(
            "success",
            "Alergens have been updated"
        );
        rel();
    }).catch((err) => {
        props.handleAlert(
            "error",
            "Something went wrong, please try again"
        );
    })
  };

  return (
    <tr>
      <td>{parent.name}</td>
      {allergenList.map(function (allergen, idx) {
        let exist = false;
        if (parentAllergens.length > 0) {
          parentAllergens.forEach((parent) => {
            if (parent.allergenId === allergen.allergenId) {
              exist = true;
            }
          });
        }
        return (
          <td>
            {exist ? (
              <CheckCircleIcon
                color="success"
                onClick={() => handleAllergen(allergen.allergenId, "remove")}
              />
            ) : (
              <AddCircleOutlineIcon
                color="primary"
                onClick={() => handleAllergen(allergen.allergenId, "add")}
              />
            )}
          </td>
        );
      })}
    </tr>
  );
}
