import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";
import axios from 'axios';
import Stack from '@mui/material/Stack';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60rem",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
  maxHeight: "70vh",
  overflowY: "scroll",
};

export default function ClosedPO(props) {
  const [invoice, setInvoice] = useState([]);

  useEffect(() => {
    //setInvoice to props
  }, []);


  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Invoice: number for: site
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <div className="pendingpo__modal">
        <table >
            <thead>
                <tr>
                    <th>Item</th>
                    <th>Amount Ordered</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Milk</td>
                    <td>
                        2
                    </td>
                </tr>
                <tr>
                    <td>Cheese</td>
                    <td>
                        5
                    </td>
                </tr>

            </tbody>
        </table>
        <strong>Total: £60.00</strong>
        </div>
      </Typography>
    </Box>
  );
}
