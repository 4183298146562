import { useState, useEffect } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CloseIcon from "@mui/icons-material/Close";

export default function WastageIndv({ row, handleQuantityCollected }) {
    const [QuantityCollected, setQuantityCollected] = useState(row.quantity);
    const [barcode, setBarcode] = useState("");

    useEffect(() => {
        //If multiple barcodes shows one, if none shows no barcode
        if (row.barcode === null) {
            setBarcode("No Barcode");
        } else {
            if (row.barcode.includes(",")) {
                const splitBarcode = row.barcode.split(",");
                for (let i = 0; i < splitBarcode.length; i++) {
                    if (splitBarcode[splitBarcode.length - (i + 1)] !== "") {
                        setBarcode(splitBarcode[splitBarcode.length - (i + 1)]);
                        return;
                    }
                }

                setBarcode(splitBarcode[splitBarcode.length - 1]);
            } else {
                setBarcode(row.barcode);
            }
        }
    }, []);

    return (
        <tr>
            <th>{row.name}</th>
            <th>{barcode}</th>
            <th className="table_right_border">{row.quantity}</th>
            <th className="table_right_border">
                <input
                    onChange={(e) => {
                        setQuantityCollected(e.target.value);
                        handleQuantityCollected(row.eposId, e.target.value);
                    }}
                    type="number"
                    pattern="[0-9]*"
                    id="collected_input"
                    min="0"
                    value={QuantityCollected}
                />
            </th>
            <th>
                {row.returnToSupplier ? (
                    <CheckBoxIcon color="success" />
                ) : (
                    <CloseIcon color="error" />
                )}
            </th>
        </tr>
    );
}
