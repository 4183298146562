import "./discrepancies.scss";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Modal from "@mui/material/Modal";
import UserContext from "../context/userContext";
import ClearIcon from "@mui/icons-material/Clear";
import Button from "@mui/material/Button";
import Discrepancy from "./modals/Discrepancy";
import DoneIcon from "@mui/icons-material/Done";
import WriteModal from "./modals/WriteModal";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Tooltip from "@mui/material/Tooltip";
import Notes from "./modals/Notes";
import AddNote from "./modals/AddNote";
import Transaction from "./modals/Transaction";
import EditIcon from "@mui/icons-material/Edit";
import CostModal from "./modals/CostModal";
import Alert from "@mui/material/Alert";
import SummarizeIcon from "@mui/icons-material/Summarize";
import EodModal from "./modals/EodModal";
import { decryptData, encryptData } from "../utils/cryptoUtils";

export default function Details(props) {
  const { userData } = useContext(UserContext);
  const [details, setDetails] = useState([]);
  const [reload, setReload] = useState(false);
  const [cost, setCost] = useState(null);
  const [notes, setNotes] = useState([]);
  const [epos, setEpos] = useState([]);
  const [prism, setPrism] = useState([]);
  const [txtExtend, setTxtExtend] = useState(false);
  const [message, setMessage] = useState("");
  const [userAlert, setUserAlert] = useState(false);
  const [storeCharge, setStoreCharge] = useState("");
  const [investigationId, setInvestigationId] = useState(0);
  const url="https://nitropos-backend.nitrosolutions.co.uk"
  // const url = "http://10.0.0.174:4022";

  const [openDiscrepancy, setOpenDiscrepancy] = useState(false);
  const handleOpenDiscrepancy = () => setOpenDiscrepancy(true);
  const handleCloseDiscrepancy = () => setOpenDiscrepancy(false);

  const [openWrite, setOpenWrite] = useState(false);
  const handleOpenWrite = () => setOpenWrite(true);
  const handleCloseWrite = () => setOpenWrite(false);

  const [openAddNote, setOpenAddNote] = useState(false);
  const handleOpenAddNote = () => setOpenAddNote(true);
  const handleCloseAddNote = () => setOpenAddNote(false);

  const [openCost, setOpenCost] = useState(false);
  const handleOpenCost = () => setOpenCost(true);
  const handleCloseCost = () => setOpenCost(false);

  const [openEod, setOpenEod] = useState(false);
  const handleCloseEod = () => setOpenEod(false);
  const handleOpenEod = () => {
    setInvestigationId(props.discrepancy.investigationId);
    setOpenEod(true);
  };

  useEffect(() => {
    setDetails(props.discrepancy);
    setCost(parseFloat(props.discrepancy.cost).toFixed(2));

    let data = {
      id: props.discrepancy.investigationId,
    };
    axios
      .post(
        `${url}/discrepancies/${props.discrepancy.sourceName}/getSingleInvestigations`,
        data
      )
      .then((res) => {
        let localArray = []; //holds notes locally
        if (res.data.epos.length > 0) {
          setEpos(res.data.epos);
          for (let e = 0; e < res.data.epos.length; e++) {
            if (res.data.epos[e].notes.length > 0) {
              for (let n = 0; n < res.data.epos[e].notes.length; n++) {
                localArray.push({
                  source: "EposNow",
                  dateTime: res.data.epos[e].notes[n].dateTimeAdded,
                  user: res.data.epos[e].notes[n].userId,
                  note: res.data.epos[e].notes[n].note,
                });
              }
            }
            //decrypts a name coming from a manual discrepancy
            if (res.data.epos[e].decrypt){
              res.data.epos[e].staffId = decryptData(res.data.epos[e].staffId, process.env.REACT_APP_EMP_KEY)
            }
          }
        } else {
          setEpos([]);
        }
        if (res.data.prism.length > 0) {
          setPrism(res.data.prism);
          for (let p = 0; p < res.data.prism.length; p++) {
            if (res.data.prism[p].notes.length > 0) {
              for (let a = 0; a < res.data.prism[p].notes.length; a++) {
                localArray.push({
                  source: "Prism",
                  dateTime: res.data.prism[p].notes[a].dateTimeAdded,
                  user: res.data.prism[p].notes[a].userId,
                  note: res.data.prism[p].notes[a].note,
                });
              }
            }
          }
        } else {
          setPrism([]);
        }
        if (res.data.investigationNotes) {
          for (let i = 0; i < res.data.investigationNotes.length; i++) {
            localArray.push({
              source: "Investigation",
              dateTime: res.data.investigationNotes[i].dateTimeAdded,
              user: res.data.investigationNotes[i].userId,
              note: res.data.investigationNotes[i].note,
            });
            if (res.data.investigationNotes[i].note.includes("CHARGED")) {
              setStoreCharge(res.data.investigationNotes[i].note);
            }
          }
        }
        setNotes(localArray);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props, reload]);

  const rel = () => {
    setReload(!reload);
  };

  const handleDiscrepancy = (array) => {
    let data = {
      user: userData.user.user,
      staffArray: array,
      id: props.discrepancy.investigationId,
      cost: cost,
      transactionDate: props.discrepancy.transactionDate,
    };
    console.log(data)
     axios
      .post(`${url}/discrepancies/createDiscrepancy`, data)
      .then((res) => {
        handleCloseDiscrepancy();
        props.rel();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleWrite = (note) => {
    let data = {
      user: userData.user.user,
      note: note,
      id: props.discrepancy.investigationId,
    };
    axios
      .post(`${url}/discrepancies/writeOffInvestigation`, data)
      .then((res) => {
        handleCloseWrite();
        props.rel();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleTxtExtend = () => {
    if (txtExtend) {
      setTxtExtend(false);
    } else {
      setTxtExtend(true);
    }
  };

  const handleNote = (note) => {
    let data = {
      user: userData.user.user,
      note: note,
      id: props.discrepancy.investigationId,
    };
    axios
      .post(`${url}/discrepancies/addInvestigationNote`, data)
      .then((res) => {
        handleCloseAddNote();
      })
      .catch((err) => {
        console.log(err);
      });
    rel();
  };

  const handleCost = (newCost, newNote) => {
    let data = {
      id: props.discrepancy.investigationId,
      user: userData.user.user,
      newCost: newCost,
      newNote: newNote,
    };
    axios
      .post(`${url}/discrepancies/updateInvestigationPrice`, data)
      .then((res) => {
        handleCloseCost();
        details.cost = newCost;
        props.rel();
        rel();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <main className="discrepancy__details">
      <section className="details__row">
        {userAlert ? <Alert severity="error">{message}</Alert> : <></>}
        <div className="row__box">
          <h3>Value</h3>
          <p>£{cost}</p>
          <div className="box__icon">
            <EditIcon color="primary" onClick={handleOpenCost} />
          </div>
          <Modal
            open={openCost}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <CostModal
              handleCost={handleCost}
              handleClose={handleCloseCost}
              cost={cost}
            />
          </Modal>
        </div>
        <div className="row__box">
          <h3>See Transaction</h3>
          <ExpandMoreIcon
            color="primary"
            fontSize="large"
            className="details__icon"
            onClick={() => handleTxtExtend()}
          />
        </div>
        {details.sourceId === 2 ? (
          <div className="row__box">
            <h3>{storeCharge}</h3>
          </div>
        ) : (
          <></>
        )}
        <div className="row__box">
          <div className="box__buttons">
            <Button
              variant="outlined"
              color="error"
              endIcon={<DoneIcon />}
              sx={{ width: "100%", margin: "auto", marginBottom: "3%" }}
              onClick={() => handleOpenDiscrepancy()}
            >
              Discrepancy
            </Button>
            <Button
              variant="outlined"
              color="success"
              endIcon={<ClearIcon />}
              sx={{ width: "100%", margin: "auto" }}
              onClick={() => handleOpenWrite()}
            >
              Write off
            </Button>
          </div>
          <Modal
            open={openDiscrepancy}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Discrepancy
              handleDiscrepancy={handleDiscrepancy}
              handleClose={handleCloseDiscrepancy}
              cost={cost}
              site={props.site}
              source={props.discrepancy.sourceName}
            />
          </Modal>

          <Modal
            open={openWrite}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <WriteModal
              handleWrite={handleWrite}
              handleClose={handleCloseWrite}
            />
          </Modal>
        </div>
      </section>
      {txtExtend ? (
        <section className="details__system" id="transaction__details">
          <h2>Transaction Details</h2>
          <div className="details__extend">
            <Transaction details={details} epos={epos} prism={prism} />
          </div>
        </section>
      ) : (
        <></>
      )}
      <section className="details__system">
        <Notes notes={notes} />
        <div className="notes__icon">
          <Tooltip title="Add Note">
            <AddCircleIcon
              color="primary"
              fontSize="large"
              onClick={handleOpenAddNote}
            />
          </Tooltip>
        </div>
        <Modal
          open={openAddNote}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <AddNote handleNote={handleNote} handleClose={handleCloseAddNote} />
        </Modal>
      </section>
      {props.discrepancy ? (
        <>
          {props.discrepancy.sourceId == 2 ? (
            <>
              <Button
                variant="contained"
                color="primary"
                endIcon={<SummarizeIcon />}
                sx={{ width: "25%", margin: "auto" }}
                onClick={() => handleOpenEod()}
              >
                EOD REPORT
              </Button>
              <Modal
                open={openEod}
                onBackdropClick={() => {
                  handleCloseEod();
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <EodModal
                  investigationId={investigationId}
                  handleClose={handleCloseEod}
                />
              </Modal>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </main>
  );
}
