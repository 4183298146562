import { useEffect, useState } from "react";
import axios from "axios"
import { useNavigate } from "react-router-dom";

export default function ClaimProd(props) {
    const [data, setData] = useState(props.d)
    const [index, setIndex] = useState();

    useEffect(() => {
        setData(props.d)
        setIndex(props.index);
    }, [props])

    const removeProduct = async () => {
        var product = { eposId: data.eposId, cpId: data.cpId, originId: data.originId, claimHeadingId: data.claimHeadingId, qty: data.claimQuantity }
        props.displayRemoval(product)
    }

    return (
        <>
        <tr style={{ textAlign: "center" }}>
            <td>{data.eposId}</td>
            <td><p>{data.name}</p></td>
            <td>
                <p>{data.stockData.invoiceCaseQty}</p>
            </td>
            {/* <td><input type="number" min={0} placeHolder={data.claimQuantity}
                onChange={(e) => { props.UpdateProduct(index, "claimQuantity", e.target.value); }} /></td> */}
            <td><p>{data.claimQuantity}</p></td>
            <td><p>{data.reason}</p></td>
            <td>
                {data.images ?
                    <a download={`${data.name}-${index}`} href={data.images} title="ClaimImg">
                        <img src={data.images} width="200px" />
                    </a>
                    :
                    <>N/A</>
                }
            </td>
            <td onClick={() => { removeProduct() }}>❌</td>
        </tr>
        </>
    )
}