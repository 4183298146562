import IndividualStatus from "./IndividualStatus";

export default function DriveOffDetails({
    siteName,
    statusList,
    driveoff,
    rel,
}) {
    const requests = driveoff;

    return (
        <main className="driveoff_cards">
            <h3>
                <u>Open Drive Offs for {siteName}</u>
            </h3>
            {requests.length > 0 ? (
                <table>
                    <thead>
                        <tr>
                            <th>Date/Time</th>
                            <th>Staff</th>
                            <th>Pump</th>
                            <th>Reg</th>
                            <th>Amount (£)</th>
                            <th>Litres</th>
                            <th>Personal Details</th>
                            <th>Status</th>
                            <th>Crime Ref</th>
                            <th>Police Pickup</th>
                            <th>Photos</th>
                            <th>Notes</th>
                            <th>Mark Paid</th>
                        </tr>
                    </thead>
                    <tbody>
                        {requests.map((request, i) => {
                            return (
                                <IndividualStatus
                                    key={i}
                                    request={request}
                                    rel={rel}
                                    statusList={statusList}
                                />
                            );
                        })}
                    </tbody>
                </table>
            ) : (
                <p>No Drive offs</p>
            )}
        </main>
    );
}
