import { Navigate } from "react-router-dom";
import ManagerNav from "../ManagerNav";
import { useState, useEffect, useRef } from "react";
import "./stocktake.scss";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AppsOutageIcon from "@mui/icons-material/AppsOutage";
import axios from "axios";
import Loader from "../../animations/Loader";
import StockCount from "./tabs/StockCount";
import StockResult from "./tabs/StockResult";
import FtgNav from '../../FTG/FtgNav';

function refreshMessages() {
  const getRandomInt = (max) => Math.floor(Math.random() * Math.floor(max));

  return Array.from(new Array(50)).map(
    () => messageExamples[getRandomInt(messageExamples.length)]
  );
}

export default function HoagiesStockHome() {
  const [role, setRole] = useState("");
  const [user, setUser] = useState("");
  const [site, setSite] = useState("");
  const [siteName, setSiteName] = useState("");
  const [siteList, setSiteList] = useState([]);
  const [showStockTake, setShowStockTake] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [reload, setReload] = useState(false)
  //bottom nav bar
  const [value, setValue] = useState(0);
  const ref = useRef(null);
  const [messages, setMessages] = useState(() => refreshMessages());

  useEffect(async () => {
    let userD = localStorage.getItem("userDetails");
    let user = JSON.parse(userD);
    setUser(user.user.user);
    setRole(user.user.role);

    if (
      user.user.role !== "Developer" &&
      user.user.role !== "Store Manager" &&
      user.user.role !== "Food Manager"
    ) {
      return <Navigate to="/" />;
    } else {
      //checks the sites for each user
      await axios
        .post(
          "https://nitropos-backend.nitrosolutions.co.uk/eposSQL/getSitesByName",
          { sites: user.user.sites }
        )
        .then((res) => {
          //if user has only one site
          if (res.data.length === 1) {
            setSite(res.data[0].eposId);
            setSiteName(res.data[0].siteName);
            // setShowLoader(true)
            //Call to get zones and items in zone
            // setShowLoader(false) on then
            setShowStockTake(true);
          } else {
            setSiteList(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    ref.current.ownerDocument.body.scrollTop = 0;
    setMessages(refreshMessages());
  }, [value, setMessages]);

  //handles site, will have to be changed for the save options
  const handleSiteSelect = (siteID) => {
    // setShowLoader(true)
    setSite(siteID);
    siteList.forEach((site) => {
      if (site.eposId == siteID) {
        setSiteName(site.siteName);
      }
    });
    //Call to get zones and items in zone
    // setShowLoader(false) on then
    setReload(!reload)
    setShowStockTake(true);
  };



  return (
    <main>
      {role === "Store Manager" ? (
      <ManagerNav />

      ): (
        <FtgNav />
      )}
      <section className="stocktake_home">
        {siteList.length > 1 ? (
          <>
            <h1>Hoagies Stocktake</h1>
            <select onChange={(e) => handleSiteSelect(e.target.value)}>
              <option>--Select site for stock take--</option>
              {siteList.map(function (site, idx) {
                return <option value={site.eposId}>{site.siteName}</option>;
              })}
            </select>
          </>
        ) : (
          <h1>Hoagies Stocktake: {siteName}</h1>
        )}

        {showLoader ? (
          <Loader />
        ) : showStockTake ? (
          <>
            <h1>Viewing: {siteName}</h1>
            <Box sx={{ pb: 7 }} ref={ref}>
              <CssBaseline />
              <List>
                {value === 0 ? (
                  <StockCount site={site} reload={reload}/>
                ) : value === 1 ? (
                  <StockResult />
                ) : (
                  <></>
                )}
              </List>
              <Paper
                sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
                elevation={3}
              >
                <BottomNavigation
                  showLabels
                  value={value}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                >
                  <BottomNavigationAction
                    label="Stock Count"
                    icon={<AccountTreeIcon site={site}/>}
                  />
                  <BottomNavigationAction
                    label="Last Result"
                    icon={<AppsOutageIcon />}
                  />
                </BottomNavigation>
              </Paper>
            </Box>
          </>
        ) : (
          <></>
        )}
      </section>
    </main>
  );
}

const messageExamples = [
  {
    primary: "Brunch this week?",
    secondary:
      "I'll be in the neighbourhood this week. Let's grab a bite to eat",
    person: "/static/images/avatar/5.jpg",
  },
  {
    primary: "Birthday Gift",
    secondary: `Do you have a suggestion for a good present for John on his work
        anniversary. I am really confused & would love your thoughts on it.`,
    person: "/static/images/avatar/1.jpg",
  },
];
