import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { Alert } from "@mui/material";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};
export default function ArchiveProductModal({
    setOpenArchive,
    stock,
    rel,
    productId,
    user,
    search,
}) {
    //Alert
    const [alert, setAlert] = useState(false);
    const [disable, setDisable] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");

    const handleAlert = (message, severity, close) => {
        setMessage(message);
        setSeverity(severity);
        setAlert(true);
        setTimeout(() => {
            setDisable(false);
            setAlert(false);
            setMessage("");
            setSeverity("");
            if (close) {
                setOpenArchive(false);
                rel();
                search();
            }
        }, 4000);
    };

    const handleArchiveProduct = () => {
        setDisable(true);
        let reqBody = {
            id: productId,
            user: user.user,
        };
        console.log(reqBody);
        axios
            .post(
                `${process.env.REACT_APP_DEPPLOYED}/eposProducts/archive/confirm`,
                reqBody
            )
            .then(async (res) => {
                handleAlert("Successfully Archived", "success", true);
            })
            .catch((err) => {
                console.log(err);
                handleAlert(
                    "Did not archive product - try again",
                    "error",
                    false
                );
            });
    };

    return (
        <Box sx={style} className="archive-product-modal">
            <h2>Archive Product</h2>
            {stock.length === 0 ? (
                <p>
                    This product does not have any stock at any store so can be
                    archived pressing the archive button. Please ensure you are
                    confident you wish to archive this product before
                    proceeding.
                </p>
            ) : (
                <p>
                    This product has the stock levels shown below and therefore
                    can not be archived until the stock is handled.
                </p>
            )}
            {stock.length > 0 ? (
                <div className="stock-table-container">
                    <table className="stock-table">
                        <thead>
                            <tr>
                                <th>Site</th>
                                <th>Stock</th>
                            </tr>
                        </thead>
                        <tbody>
                            {stock.map((s, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{s.siteName}</td>
                                        <td>{s.currentStock}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            ) : null}
            <section className="alert-section">
                {alert ? <Alert severity={severity}>{message}</Alert> : null}
            </section>
            <section className="btn-section">
                {stock.length === 0 ? (
                    <Button
                        variant="contained"
                        color="success"
                        onClick={handleArchiveProduct}
                        disabled={disable}
                    >
                        Archive
                    </Button>
                ) : null}
                <Button
                    variant="contained"
                    color="error"
                    onClick={() => setOpenArchive(false)}
                    disabled={disable}
                >
                    Close
                </Button>
            </section>
        </Box>
    );
}
