import "./alerts.scss";
import Chart from "react-apexcharts";
import { useState } from "react";
// tabs
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DonutLargeIcon from "@mui/icons-material/DonutLarge";
import Alerts from "./Alerts";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AlertPanel(props) {
  const high = props.alerts.high;
  const medium = props.alerts.medium;
  const low = props.alerts.low;
  // tabs
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let chartOptions = {
    series: [
      props.alerts.high.length,
      props.alerts.medium.length,
      props.alerts.low.length,
    ],
    chart: {
      type: "donut",
    },
    labels: [
      `High - ${props.alerts.high.length}`,
      `Medium - ${props.alerts.medium.length}`,
      `Low - ${props.alerts.low.length}`,
    ],
    dataLabels: {
        enabled: false,
    },
    colors: ["rgb(218,17,17)", "rgb(255, 159, 64)", "rgb(25, 197, 10)"],
    responsive: [
      {
        breakpoint: 690,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
  return (
    <main className="alert_panel_main">
      {high.length === 0 && medium.length === 0 && low.length === 0 ? (
        <section className="chart_card">
          <h4>No Alerts to show</h4>
        </section>
      ) : (
        <>
          <section className="chart_card">
            <h4>Alert Sumary</h4>
            <hr />
            <Chart
              options={chartOptions}
              series={chartOptions.series}
              type="donut"
              width="400"
            />
          </section>
          <section className="alert_tabs">
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  centered
                >
                  <Tab
                    label="High"
                    {...a11yProps(0)}
                    icon={<DonutLargeIcon sx={{ color: "rgb(218,17,17)" }} />}
                  />
                  <Tab
                    label="Medium"
                    {...a11yProps(1)}
                    icon={
                      <DonutLargeIcon sx={{ color: "rgb(255, 159, 64)" }} />
                    }
                  />
                  <Tab
                    label="Low"
                    {...a11yProps(2)}
                    icon={<DonutLargeIcon sx={{ color: "rgb(25, 197, 10)" }} />}
                  />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <Alerts
                  severity="HIGH"
                  alerts={high}
                  readAlert={props.readAlert}
                />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Alerts
                  severity="MEDIUM"
                  alerts={medium}
                  readAlert={props.readAlert}
                />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <Alerts
                  severity="LOW"
                  alerts={low}
                  readAlert={props.readAlert}
                />
              </CustomTabPanel>
            </Box>
          </section>
        </>
      )}
    </main>
  );
}
