import React, { useState, useEffect, useRef } from 'react';
import v4uLogo from '../images/v4uLogo.webp'
import axios from 'axios'
import Chart from "react-apexcharts";
import '../styles/promotion.scss'
import Loader from "../../animations/Loader";
import WareNav from '../../warehouse/WareNav';
import moment from 'moment';

export default function VapePromoDash() {
    const [data, setData] = useState({})
    const [reload, setReload] = useState(false)
    const [siteData, setSiteData] = useState([])
    const [siteIndex, setSiteIndex] = useState(0)
    const [user, setUser] = useState("")
    const [dates, setDates] = useState([])
    const [signups, setSignups] = useState([])
    const [fullDiscount, setFullDiscount] = useState([])
    const [halfDiscount, setHalfDiscount] = useState([])

    const chartOptions2 = {
        chart: {
            width: 400,
            type: "bar",
        },
        labels: ["Sign-Ups", "Both Codes", "Overall Redemptions"],
    };


    const changeSite = () => {
        if (data.storeSplit.length > 0) {
            var idx = 0;
            if (siteIndex == 0 && data.storeSplit.length > 1) {
                setSiteIndex(siteIndex + 1)
                idx = siteIndex + 1
            } else if (siteIndex > 0 && siteIndex < data.storeSplit.length - 1) {
                setSiteIndex(siteIndex + 1)
                idx = siteIndex + 1
            } else {
                setSiteIndex(0)
                idx = 0
            }
            axios.get(`https://nitropos-backend.nitrosolutions.co.uk/vapePromotion/reporting/${data.storeSplit[idx].name}`).then((res) => {
                setSiteData(res.data)
                console.log(data.storeSplit[siteIndex].name)
                console.log(res.data.todayRedemptions)
                setDates(res.data.dailyFigures.map(a => moment(a.date,"YYYY-MM-DD").format("DD-MM-YYYY")))
                console.log(res.data.dailyFigures.map(a => a.date))
                setSignups(res.data.dailyFigures.map(a => a.signups))
                console.log(res.data.dailyFigures.map(a => a.signups))
                setFullDiscount(res.data.dailyFigures.map(a => a.full))
                console.log(res.data.dailyFigures.map(a => a.full))
                setHalfDiscount(res.data.dailyFigures.map(a => a.half))
                console.log(res.data.dailyFigures.map(a => a.half))
            }).catch((err) => {
                console.log(err)
            })
        }
    }

    useEffect(() => {
        let userD = localStorage.getItem("userDetails");
        let user = JSON.parse(userD);
        console.log(user.user.role)
        setUser(user.user.role);

        axios.get("https://nitropos-backend.nitrosolutions.co.uk/vapePromotion/reporting").then((res) => {
            setData(res.data)
        }).catch((err) => {
            console.log(err)
        })

    }, [reload])


    function useInterval(callback, delay) {
        const savedCallback = useRef();

        // Remember the latest callback.
        useEffect(() => {
            savedCallback.current = callback;
        }, [callback]);

        // Set up the interval.
        useEffect(() => {
            function tick() {
                savedCallback.current();
            }
            if (delay !== null) {
                let id = setInterval(tick, delay);
                return () => clearInterval(id);
            }
        }, [delay]);
    }

    useInterval(() => {
        changeSite();
    }, 5000);

    var chartOptions = {
        
        chart: {
            type: 'bar',
            height: 350
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
            },
        },
        // dataLabels: {
        //     enabled: false
        // },
        // stroke: {
        //     show: true,
        //     width: 2,
        //     colors: ['transparent']
        // },
        xaxis: {
            categories: dates,
        },
        yaxis: {
            min: 0,
            tickAmount: 1,
          },
        fill: {
            opacity: 1
        }
    };


    return (
        <main className="dashboard">
            {user === "Manager" || user === "Developer" ? (
                <WareNav />
            ) : null}
            <img src={v4uLogo} height="100" />
            <div className='parent'>

                <h1>Promotions Dashboard</h1>
                {data.signUpComparison ?
                    <>
                        <div className="child">
                            <div class="row">
                                <table>
                                    <thead><td>Weekly</td><td>Sign-Ups</td></thead>
                                    <tbody>
                                        <tr><td>Last Week</td><td>This Week</td></tr>
                                        <tr><td>{data.signUpComparison.lastWeek}</td><td>{data.signUpComparison.thisWeek}</td></tr>
                                    </tbody>
                                </table>
                                <table>
                                    <thead><td>Weekly</td><td>Redemptions</td></thead>
                                    <tbody>
                                        <tr><td>Last Week</td><td>This Week</td></tr>
                                        <tr><td>{data.redemptionComparison.lastWeek}</td><td>{data.redemptionComparison.thisWeek}</td></tr>
                                    </tbody>
                                </table>

                            </div>

                            <table>
                                <thead><td>Site</td><td>Redemptions</td></thead>
                                <tbody>
                                    {data.storeSplit.map(function (d, i) {
                                        return (
                                            <tr key={i}><td>{d.name}</td><td>{d.redeemed}</td></tr>
                                        )
                                    })}
                                </tbody>
                            </table>


                            <div style={{ marginLeft: "-12.5%", marginTop: "10%", width: "125.5%", height: "325px", border: "1px solid black" }}>
                                {siteData.totalRedemptions ?
                                    <>
                                        <h2 style={{ width: "100%", color: "#fff", marginTop: "0", padding: "3%", backgroundColor: "#2c53a0" }}>{data.storeSplit[siteIndex].name}</h2>
                                        <div style={{ display: 'flex', marginBottom: "45%" }}>
                                            <Chart
                                                options={{ labels: [siteData.discountSplit[0].name, siteData.discountSplit[1].name] }}
                                                series={[siteData.discountSplit[0].redeemed, siteData.discountSplit[1].redeemed]}
                                                type="pie"
                                                width="320"
                                            />
                                            <div style={{ marginLeft: "10%", width: "200px" }}>
                                                <h3 style={{ color: "black" }}>Total Redemptions - {siteData.totalRedemptions}</h3>
                                                <table style={{ marginLeft: "10%", width: "150px" }}>
                                                    <thead><td>Last Week</td><td>This Week</td></thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{siteData.redemptionComparison.lastWeek}</td>
                                                            <td>{siteData.redemptionComparison.thisWeek}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div>
                                                
                                            <Chart
                                                options={chartOptions}
                                                series= {[{ name: 'Sign-Ups', data: signups }, { name: '100% Discount', data: fullDiscount }, { name: '50% Discount', data: halfDiscount }]}
                                                type="bar"
                                                width="320"
                                            />
                                            </div>
                                        </div>
                                    </> : <><h2>LOADING SITE DATA</h2><Loader /></>}
                            </div>
                            <br/>
                            <br/>
                        </div>

                        <div className="child">
                            <div className="time">
                                <h3>Signup To Redemption Time</h3>
                                <p>{data.formattedTime}</p>
                            </div>
                            <Chart
                                options={{ labels: [data.discountSplit[0].name, data.discountSplit[1].name] }}
                                series={[data.discountSplit[0].redeemed, data.discountSplit[1].redeemed]}
                                type="pie"
                                width="400"
                            />
                            <Chart
                                options={chartOptions2}
                                series={[{ data: [data.totalSignups, data.bothCodes, data.totalRedemptions] }]}
                                type="bar"
                                width="400"
                            />
                        </div>
                    </>
                    : <></>}

            </div>
        </main>
    )
}