import { useEffect, useState } from "react";
import CCTVIndividual from "./CCTVIndividual";
import axios from "axios";

export default function CCTVRequests({ statusList }) {
    const url = process.env.REACT_APP_ANPR_URL;
    const [requests, setRequests] = useState([]);
    const [reload, setReload] = useState(false);
    const [sorted, setSorted] = useState(false);

    useEffect(() => {
        //GET ALL CCTV REQUESTS
        axios
            .get(`${url}/cctv-system/submissions/details/allActive`)
            .then((res) => {
                setRequests(res.data.cctv);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [reload]);

    const rel = () => {
        setReload(!reload);
    };

    //Filters
    const sortSite = () => {
        setSorted(!sorted);
        let siteData = requests.sort((a, b) =>
            a.siteName.localeCompare(b.siteName)
        );
        setRequests(siteData);
    };

    const sortDate = () => {
        setSorted(!sorted);
        let dateData = requests.sort(
            (a, b) => new Date(a.date) - new Date(b.date)
        );
        setRequests(dateData);
    };

    const sortStatus = () => {
        setSorted(!sorted);
        let statusData = requests.sort((a, b) => {
            return a.statusId - b.statusId;
        });

        setRequests(statusData);
    };

    return (
        <main className="cctv__requests">
            <h3>Open CCTV requests</h3>
            <table className="cctv__table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th className="filter_heading" onClick={sortDate}>
                            Date
                        </th>
                        <th className="filter_heading" onClick={sortSite}>
                            Site
                        </th>
                        <th>Reason</th>
                        <th className="filter_heading" onClick={sortStatus}>
                            Status
                        </th>
                        <th>Storage</th>
                        <th>Notes</th>
                        <th>QR</th>
                    </tr>
                </thead>
                <tbody>
                    {requests.length > 0 ? (
                        requests.map((request, i) => {
                            return (
                                <CCTVIndividual
                                    request={request}
                                    statusList={statusList}
                                    rel={rel}
                                />
                            );
                        })
                    ) : (
                        <tr>
                            <th colSpan={9}>No open requests</th>
                        </tr>
                    )}
                </tbody>
            </table>
        </main>
    );
}
