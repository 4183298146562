// Displays the label for the product searched

import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import styles from "./styles/Main.module.css";
import Name from "./Name";
import logo from '../../../images/vaped4u-logo.png'
import { useBarcode } from 'react-barcodes';

export default function Label(props) {

  const [barcode, setBarcode] = useState(props.barcode)
  const [height, setHeight] = useState(0);
  const ref = useRef(null)

  useEffect(() => {
    setBarcode(barcode.split(',')[0])
  }, [props.barcode])

  useLayoutEffect(() => {
    setHeight(ref.current.clientHeight);
  }, [])

  const { inputRef } = useBarcode(
    { value: barcode, options: { background: 'transparent', } },
    { format: "EAN13" });

  return (
    <div class={(Number.isInteger(props.id / 28)) ? 'html2pdf__page-break' : props.id}>

<div
                className={`${styles.label}`}
                ref={ref}
                onClick={() => props.clicked(props.item)}
                style={{ width: "25%", marginBottom: "12px" }}
            >
                <div
                    style={{
                        textAlign: "center",
                        margin: "0 auto",
                        backgroundColor: "#78c7cc",
                        height: "38px",
                        borderRadius: "8px",
                        alignContent: "center",
                        containerType: "inline-size",
                        fontSize: "1rem",
                    }}
                >
                    <span
                        style={{
                            fontSize: "7cqmin",
                            fontWeight: "bold",
                            color: "#000",
                            wordBreak: "break-all",
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            padding: "0 3px",
                        }}
                    >
                        {props.name.toUpperCase()}
                    </span>
                </div>
                <div
                    style={{
                        marginTop: "-3px",
                        textAlign: "center",
                        borderBottomLeftRadius: "8px",
                        borderBottomRightRadius: "8px",
                        borderBottom: "1px dashed #eeeeee",
                        borderLeft: "1px dashed #eeeeee",
                        borderRight: "1px dashed #eeeeee",
                    }}
                >
                    <span
                        style={{
                            color: "#000",
                            fontWeight: "bolder",
                            fontSize: "2.8rem",
                        }}
                    >
                        £{props.price.toFixed(2)}
                    </span>
                    <br />
                    <div style={{ marginTop: "-7px", marginBottom: "-8px" }}>
                        {height > 14 ? (
                            <img ref={inputRef} height="39" width="100" />
                        ) : (
                            <img ref={inputRef} height="50" width="100" />
                        )}
                    </div>
                </div>
            </div>
    </div>

  );
}
