import { useState } from "react";
import { Button, Alert } from "@mui/material";

export default function SubShiftGrouping(props) {
  const shifts = props.openShifts;
  const [shiftGrouping, setShiftGrouping] = useState([]);
  //alerts
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  //selects and groups all shifts for reconciliation
  const handleGrouping = (data, index) => {
    let tableChange = document.querySelectorAll("#grouping_table tr");
    //if they have clicked by mistake ungroups
    if (tableChange[index].classList.contains("selected")) {
      tableChange[index].classList.remove("selected");
      //remove from the shiftgrouping
      let selected = shiftGrouping.indexOf(data);
      shiftGrouping.splice(selected, 1);
    } else {
      tableChange[index].classList.add("selected");
      setShiftGrouping((shiftGrouping) => [...shiftGrouping, data]);
    }
  };

  const handleNext = () => {
    if (shiftGrouping.length < 1) {
        setMessage("Please select at least 1 shift");
        setSeverity("warning");
        setShowAlert(true)
        setTimeout(() => {
            setShowAlert(false)
        }, [])
    } else{
        props.handleGroupedShifts(shiftGrouping)
    }
  };

  return (
    <section className="subway_section">
      <h4>Please select the shifts you want to group:</h4>
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Expected</th>
          </tr>
        </thead>
        <tbody id="grouping_table">
          {shifts && shifts.length > 0 ? (
            shifts.map((shift, index) => {
              return (
                <tr onClick={() => handleGrouping(shift, index)}>
                  <td>{new Date(shift.date).toLocaleDateString("en-GB")}</td>
                  <td>£{shift.value}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={2}>No Shifts to show</td>
            </tr>
          )}
        </tbody>
      </table>
      {showAlert ? (
        <Alert severity={severity} sx={{ width: "60%", margin: "1% auto" }}>
          {message}
        </Alert>
      ) : (
        <Button variant="outlined" color="success" onClick={handleNext}>
          Next Step
        </Button>
      )}
    </section>
  );
}
