import { useState } from "react";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Box from "@mui/material/Box";
import { Button, Alert } from "@mui/material";

//STYLING
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50vw",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
    "@media(max-width: 600px)": {
        width: "90vw",
        maxHeight: "70vh",
        overflowX: "scrollable",
    },
};

export default function UpdateCrimeRef({
    registration,
    setShowUpdateCrimeRefModal,
    crimeRef,
    rel,
    request,
}) {
    const url = process.env.REACT_APP_ANPR_URL;
    const [newCrimeRef, setNewCrimeRef] = useState("");
    const [alert, setAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");

    const handleUpdateCrimeRef = () => {
        let data = { ...request, crimeReference: newCrimeRef };
        // request.crimeReference = newCrimeRef;

        if (newCrimeRef === "") {
            setMessage("Please input new crime reference");
            setSeverity("warning");
            setAlert(true);
            setTimeout(() => {
                setAlert(false);
                setSeverity("");
                setMessage("");
            }, 3000);
        } else {
            axios
                .post(`${url}/cctv-system/submission/update/details`, data)
                .then((res) => {
                    setMessage("Crime reference successfully updated");
                    setSeverity("success");
                    setAlert(true);
                    setTimeout(() => {
                        setAlert(false);
                        setSeverity("");
                        setMessage("");
                        setShowUpdateCrimeRefModal(false);
                    }, 3000);
                    rel();
                })
                .catch((err) => {
                    console.log(err);
                    setMessage("Something went wrong. Please try again");
                    setSeverity("error");
                    setAlert(true);
                    setTimeout(() => {
                        setAlert(false);
                        setSeverity("");
                        setMessage("");
                    }, 3000);
                });
        }
    };
    return (
        <Box sx={style} className="all_modal">
            <Typography id="transition-modal-title" variant="h6" component="h2">
                Update Crime Reference
            </Typography>
            {crimeRef ? (
                <Typography variant="h6" component="h2">
                    This drive off has the current crime reference -{" "}
                    <span style={{ color: "#FF0000" }}>
                        <u>{crimeRef}</u>
                    </span>
                </Typography>
            ) : null}
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                <label>Registration Number</label>
                <br />
                <b style={{ textDecoration: "underline" }}>{registration}</b>
                <br />
                <label>Enter new Crime Ref Number</label>
                <br />
                <input
                    type="text"
                    onChange={(e) => setNewCrimeRef(e.target.value)}
                />
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "3%",
                    }}
                >
                    <Button
                        color="error"
                        variant="contained"
                        onClick={() => setShowUpdateCrimeRefModal(false)}
                    >
                        Close
                    </Button>
                    {!alert ? (
                        <Button
                            variant="contained"
                            onClick={() => handleUpdateCrimeRef()}
                        >
                            Send
                        </Button>
                    ) : (
                        <Alert severity={severity}>{message}</Alert>
                    )}
                </div>
            </Typography>
        </Box>
    );
}
