import "./discrepancies.scss";
import {useState, useEffect} from 'react';
import axios from 'axios'
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Alert from '@mui/material/Alert';

export default function ManualDiscrep() {
    const [user, setUser] = useState("")
    const [role, setRole] = useState("")
    const [site, setSite] = useState("")
    const [siteName, setSiteName] = useState("")
    const [sites, setSites] = useState([])
    const [form, setForm] = useState(false)
    const [reason, setReason] = useState("")
    const [amount, setAmount] = useState("")
    const [note, setNote] = useState("")
    const [errorAlert, setErrorAlert] = useState(false)
    const [message, setMessage] = useState("")
    const [date, setDate] = useState("")

    useEffect(async () => {
        let userD = localStorage.getItem("userDetails");
        let user = JSON.parse(userD);
        setUser(user.user.user);
        setRole(user.user.role);
    
        await axios
        .post("https://nitropos-backend.nitrosolutions.co.uk/eposSQL/getSitesByName",
        { sites: user.user.sites })
        .then((res) => {
            if (res.data.length === 1) {
                setSite(res.data[0].eposId);
                setSiteName(res.data[0].siteName);
                setForm(true)
            } else{
               setSites(res.data)
            }
        })
    },[])

    const handleSite = (site) => {
        setSiteName(site)
        setForm(true)
    }

    const handleForm = () => {
        document.getElementById("form__btn").style.display = "none"
        if (reason === "" || reason === "--Select--" || amount === "" || note === "" || date === ""){
            setMessage("All fields must be filled in")
            setErrorAlert(true)
            setTimeout(() => {
                setMessage("")
                setErrorAlert(false)  
                document.getElementById("form__btn").style.display = "flex"
            },3500)
        } else if (parseInt(amount) < 0){
            setMessage("Amount cannot be negative")
            setErrorAlert(true)
            setTimeout(() => {
                setMessage("")
                setErrorAlert(false)  
                document.getElementById("form__btn").style.display = "flex"
            },3500)
        } else{
            let data = {
                reason: reason,
                site: siteName,
                cost: parseInt(amount),
                note: note,
                incidentDate: date,
                user: user,
            }

            axios.post("https://nitropos-backend.nitrosolutions.co.uk/discrepancies/createManualInvestigation", data)
            .then((res) => {
                if (sites.length > 1){
                    setForm(false)
                    document.getElementById("form__btn").style.display = "flex"
                    let inputs = document.querySelectorAll("select");
                    for (let i = 0; i < inputs.length; i++) {
                      inputs[i].selectedIndex = 0;
                    }
                    setAmount("")
                    setNote("")
                    setDate("")       
                } else{
                    document.getElementById("form__btn").style.display = "flex"
                    let inputs = document.querySelectorAll("select");
                    for (let i = 0; i < inputs.length; i++) {
                      inputs[i].selectedIndex = 0;
                    }
                    setAmount("")
                    setNote("")
                    setDate("")       
                }
            }).catch((err) => {
                console.log(err)
                setMessage("Something went wrong, please try again")
                setErrorAlert(true)
                setTimeout(() => {
                    setMessage("")
                    setErrorAlert(false)  
                    document.getElementById("form__btn").style.display = "flex"
                },3500)
    
            })
        }
    }


  return (
    <main className="manual__discrepancy">
      <h1>Manual Discrepancy</h1>
        {sites.length > 1 ? (
            <select onChange={(e) => handleSite(e.target.value)}>
                <option>--Select Site--</option>
                {sites.map(function(d,i){
                    return(
                        <option>{d.siteName}</option>
                    )
                })}
            </select>
        ):(
            <h2>Manual Discrepancy for <span>{siteName}</span></h2>
        )}

        {form ? (
            <section className="manual__form">
                <div className="form__row">
                    <label>Reason:</label>
                    <select onChange={(e) => setReason(e.target.value)}>
                        <option>--Select--</option>
                        <option>Paypoint</option>
                        <option>Lottery</option>
                        <option>Fake Note</option>
                        <option>Stock Issue</option>
                    </select>
                </div>
                <div className="form__row">
                    <label>Amount:</label>
                    <input type="number" onChange={(e) => setAmount(e.target.value)} value={amount}/>
                </div>                
                <div className="form__row">
                    <label>Date:</label>
                    <input type="date" onChange={(e) => setDate(e.target.value)} value={date}/>
                </div>
                <div className="form__row">
                    <label>Notes:</label>
                    <textarea cols={35} rows={5} onChange={(e) => setNote(e.target.value)} value={note}/>
                </div>
                <Button variant="contained" color="success" endIcon={<SendIcon />} id="form__btn" onClick={() => handleForm()}>Send</Button>
                {errorAlert ? <Alert severity="warning">{message}</Alert>:<></>}
            </section>
        ):null}
    
    </main>
  );
}
