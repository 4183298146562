import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

//STYLING
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60vw",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
    maxHeight: "70vh",
    overflowY: "scroll",
    "@media(max-width: 690px)": {
        width: "90vw",
        maxHeight: "90vh",
        p: 2,
    },
};

export default function DVLAMatch({
    carDetails,
    matchedDetails,
    noDetailsMatched,
}) {
    return (
        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h4" component="h2">
                Car Details from DVLA
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <ul>
                    <li>
                        DVLA Make:
                        <span className="dvlaDetails">{carDetails.make}</span>
                    </li>
                    <li>
                        DVLA Color:
                        <span className="dvlaDetails">{carDetails.colour}</span>
                    </li>
                    <li>
                        DVLA Fuel:
                        <span className="dvlaDetails">
                            {carDetails.fuelType}
                        </span>
                    </li>
                </ul>
                {carDetails.colour === "WHITE" ? (
                    <DirectionsCarIcon
                        sx={{
                            color: carDetails.colour,
                            fontSize: 75,
                            marginLeft: "40%;",
                            border: "1px black solid",
                            borderRadius: "5px",
                            background: "black",
                        }}
                    />
                ) : (
                    <DirectionsCarIcon
                        sx={{
                            color: carDetails.colour,
                            fontSize: 75,
                            marginLeft: "40%;",
                        }}
                    />
                )}

                <Stack spacing={2}>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={() => matchedDetails()}
                    >
                        Details Match Car on Forecourt
                    </Button>
                    <Button
                        variant="contained"
                        color="warning"
                        onClick={() => noDetailsMatched()}
                    >
                        Details don't Match Car on Forecourt
                    </Button>
                </Stack>
            </Typography>
        </Box>
    );
}
