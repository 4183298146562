import { useState, useEffect } from "react";
import "./discrepancies.scss";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import axios from 'axios'

export default function Investigation(props) {
  const [discrepancy, setDiscrepancy] = useState({});
  const [note, setNote] = useState("");
  const [user, setUser] = useState("");
  const [message, setMessage] = useState("");
  const [showErroralert, setShowErrorAlert] = useState(false);

  useEffect(() => {
    setDiscrepancy(props.discrepancy);
    let userD = localStorage.getItem("userDetails");
    let user = JSON.parse(userD);
    setUser(user.user.user);
  }, [props]);

  const handleDiscrep = (reason) => {
    document.getElementById("row__buttons").style.display = "none";
    if (note === "") {
      setMessage("Please insert a note")
      setShowErrorAlert(true)
      setTimeout(() => {
        setMessage("")
        setShowErrorAlert(false)
        document.getElementById("row__buttons").style.display = "flex";
      }, 2500)
    } else {
      let data = {
        user: user,
        id: discrepancy.investigationId,
        note: note,
        reason: reason,
      }
      axios.post(`https://nitropos-backend.nitrosolutions.co.uk/discrepancies/finishStoreDiscrepancy`, data)
        .then((res) => {
          console.log(res.data)
          document.getElementById("row__buttons").style.display = "flex";
          setNote("")
          setDiscrepancy({})
          props.rel()
        }).catch((err) => {
          console.log(err)
          setMessage("Something went wrong, please try again")
          setShowErrorAlert(true)
          setTimeout(() => {
            setMessage("")
            setShowErrorAlert(false)
            document.getElementById("row__buttons").style.display = "flex";
          }, 3000)
        })
    }
  };

  return (
    <main className="discrepancy__side">
      {discrepancy ? (
        <section className="main__disc__side">
          <section className="side__row">
            <div className="side__box">
              <strong>Cost</strong>
              <p>£{discrepancy.cost}</p>
            </div>
            <div className="side__box">
              <strong>Transaction Date</strong>
              <p>
                {new Date(discrepancy.transactionDate).toLocaleDateString()}
              </p>
            </div>
            <div className="side__box">
              <strong>Till User</strong>
              <p>{discrepancy.closedBy}</p>
            </div>
          </section>
          <div className="side__reason">
            <strong>Reason</strong>
            <p>{discrepancy.reason}</p>
          </div>
          <textarea
            value={note}
            onChange={(e) => setNote(e.target.value)}
            cols={40}
            rows={5}
            placeholder="Include notes of your investigation..."
          />
          <section
            className="side__row"
            style={{ marginTop: "1%" }}
            id="row__buttons"
          >
            <Button
              variant="outlined"
              color="success"
              onClick={() => handleDiscrep("NOT TO BE CHARGED")}
            >
              Not to be Charged
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={() => handleDiscrep("TO BE CHARGED")}
            >
              To Be Charged
            </Button>
          </section>
          {showErroralert ? <Alert severity="warning">{message}</Alert> : <></>}
        </section>
      ) : (
        <h1>Click on a discrepancy to start investigation</h1>
      )}
    </main>
  );
}
