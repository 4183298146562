import { useEffect,useState,useContext } from "react"
import axios from "axios"
import DevNav from "./nav/DevNav"
import LoginTest from "../LoginTest"
import userContext from "../context/userContext";

export default function Gs1Barcodes(props) {

    const [barcode,setBarcode] = useState("")
    const [itemNo,setItemNo] = useState(0)
    const [rel,setRel]=useState(false)
    const { userData } = useContext(userContext);

    useEffect(()=>{
        axios.get("https://backend.hoagies.co.uk/food/epos/barcode/latest").then((res)=>{
            setItemNo(res.data)
        }).catch((err)=>{
            console.log(err)
        })
    },[rel])

    const addBarcode = async () => {
        await axios.post("https://backend.hoagies.co.uk/food/epos/barcode/add",{barcode:barcode}).then((res)=>{
            console.log(res.data)
            alert("Barcode Added")
            setBarcode("")
            setRel(!rel)
        }).catch((err)=>{
            console.log(err)
        })
    }

    return ( 
        <>{userData.user && userData.user.role === "Developer" ? (
                <section className="devZone">
                    <DevNav  />
                    <section className="devzone__main">
                        <h2><u>Add New GS1 Barcode</u></h2>
                        <h3 style={{color:"#4d4d4d"}}>Next Name: <span style={{color:"#2c53a0"}}>Hoagies NitroPOS Item {itemNo}</span></h3>
                        <input type="text" placeholder="Enter Barcode....." value={barcode} onChange={(e)=>setBarcode(e.target.value)}/>
                        <button style={{width:"15%", padding:"20px",marginTop:"2%",backgroundColor:"#2c53a0",color:"white", border:"2px solid #4d4d4d", borderRadius:"15px"}} onClick={() => addBarcode()}>Add Barcode</button>
                    </section>
                </section>
            ) : (
                <LoginTest />
            )}
        </>
    );
}