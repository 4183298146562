import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "./styles/fuelSales.scss";
import { useState, useEffect } from "react";

const noteStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40vw",
  bgcolor: "background.paper",
  borderRadius: "1rem",
  backgroundColor: "#fff",
  boxShadow: 24,
  p: 4,
  color: "#000",
  height: "40vh",
  overflowY: "scroll",
};

export default function IndvNotes(props) {
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    setNotes(props.notes);
    console.log(props.notes);
  });

  return (
    <Box sx={noteStyle}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        <h2 style={{ textAlign: "center" }}>Read Notes</h2>
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <div className="force__match">
          <table>
            <thead>
              <tr>
                <th>Added On</th>
                <th>Note</th>
                <th>Added By</th>
              </tr>
            </thead>
            <tbody>
            {notes.length > 0 ? (
            notes.map(function (d, i) {
              return (
                <tr>
                  <th>{new Date(d.dateTimeAdded).toLocaleString()}</th>
                  <th>{d.note}</th>
                  <th>{d.userId}</th>
                </tr>
              );
            })
          ) : (
            <p>No Notes Added</p>
          )}

            </tbody>
          </table>
        </div>
      </Typography>
    </Box>
  );
}
