import { useContext, useState, useEffect } from "react";
import axios from "axios";
import "./discrepancies.scss";
import UserContext from "../context/userContext";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import LoginTest from "../LoginTest";
import SearchIcon from '@mui/icons-material/Search';
import { IconButton } from "@mui/material";
import ListSide from "./ListSide";
import Details from "./Details";
import { getAllStores } from "../utils/Api";

export default function Discrepancies() {
  const { userData } = useContext(UserContext);
  const [site, setSite] = useState("");
  const [month, setMonth] = useState("")
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState(false);
  const [user, setUser] = useState("");
  const [siteList, setSiteList] = useState([]);
  const [discrepData, setDiscrepData] = useState([])
  const [showDiscrepancies, setShowDiscrepancies] = useState(false)
  const [reload, setReload] = useState(false)
  const [discrepancy, setDiscrepancy] = useState({})
  const [storeCount, setStoreCount] = useState(0)
  const url = "https://nitropos-backend.nitrosolutions.co.uk";
  // const url = "http://10.0.0.174:4022";

  useEffect(() => {
    let role = localStorage.getItem("role");
    setUser(role);

    getAllStores()
    .then((stores) => {
      setSiteList(stores)
    }).catch((err) => {
      console.log(`couldn't get stores: ${err}`)
    })

  }, [reload]);

  const rel = () => {
    setReload(!reload)
    compileDiscrepancies()
  }

  const compileDiscrepancies = () => {
    if (month === "" || site === "" || site === "--Please Select a Site--"){
      setMessage ("Please select a site and a month")
      setAlert(true);
      setTimeout(() => {
        setAlert(false);
      }, 2500);
    }
    else {
      let data = {
        site: site,
        date: month,
      };
      axios
        .post(`${url}/discrepancies/getInvestigations`, data)
        .then((res) => {
          if (res.data.sqlRows.length === 0) {
            setMessage(
              `No discrepancies to show on ${month}`
            );
            setAlert(true);
            setTimeout(() => {
              setAlert(false);
            }, 3500);
          }
          else {
            setDiscrepData(res.data.sqlRows)
            setStoreCount(res.data.storeAwating)
            setShowDiscrepancies(true)
          }
        })
        .catch((err) => {
          setMessage("Error, Please try again");
          setAlert(true);
          setTimeout(() => {
            setAlert(false);
          }, 2500);
        });

    }
  };

  const handleDiscrepancy = (id) => {
    setDiscrepancy(id)
  }

  return (
        <main className="discrepancies" >
          <section className="discrepancies__main">
            <h1>Discrepancies Investigation</h1>
            {alert ? (
              <Alert
                severity="error"
                sx={{
                  width: "80vw",
                  left: "15vw",
                  position: "absolute",
                  top: "22vh",
                  zIndex: "10",
                }}
              >
                <AlertTitle>Error</AlertTitle>
                {message}
              </Alert>
            ) : (
              <></>
            )}

            <div className="discrepancy__row">
              <select onChange={(e) => setSite(e.target.value)}>
                <option>--Please Select a Site--</option>
                {siteList.map(function (d, i) {
                  return <option>{d.siteName}</option>;
                })}
              </select>
              <input
                type="month"
                min="2023-01"
                onChange={(e) => setMonth(e.target.value)}
              />
              <IconButton onClick={() => compileDiscrepancies()}>
                <SearchIcon color="primary" fontSize="large"/>
              </IconButton>
            </div>
            {showDiscrepancies ? (
              <section className="show__discrepancies">
                <ListSide rel={rel} handleDiscrepancy={handleDiscrepancy} discrepData={discrepData} storeCount={storeCount}/>
                <Details rel={rel} discrepancy={discrepancy} handleDiscrepancy={handleDiscrepancy} site={site}/>
              </section>
            ):(
              <></>
            )}
          </section>
        </main>
  );
}
