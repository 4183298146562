// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./font/digital-7.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'digital-clock-font';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

.bpBoard {
    font-family: 'digital-clock-font';
    background-color: black;
    color: white;
    font-size: 3rem;
}
.bpSite{
    font-family: 'digital-clock-font';
    background-color: black;
    color: white;
    text-align: center;
    margin-top: -4px;
    margin-bottom: 15px;
    font-size: 1.7rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Fuel/test.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,4CAAgC;AACpC;;AAEA;IACI,iCAAiC;IACjC,uBAAuB;IACvB,YAAY;IACZ,eAAe;AACnB;AACA;IACI,iCAAiC;IACjC,uBAAuB;IACvB,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,iBAAiB;AACrB","sourcesContent":["@font-face {\n    font-family: 'digital-clock-font';\n    src: url('./font/digital-7.ttf');\n}\n\n.bpBoard {\n    font-family: 'digital-clock-font';\n    background-color: black;\n    color: white;\n    font-size: 3rem;\n}\n.bpSite{\n    font-family: 'digital-clock-font';\n    background-color: black;\n    color: white;\n    text-align: center;\n    margin-top: -4px;\n    margin-bottom: 15px;\n    font-size: 1.7rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
