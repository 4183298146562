import { useEffect, useState } from "react";
import axios from "axios";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import HideImageIcon from "@mui/icons-material/HideImage";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import Modal from "@mui/material/Modal";
import OpenImage from "./modals/OpenImage";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import moment from "moment/moment";

export default function Open(props) {
  const [user, setUser] = useState("");
  const [reload, setReload] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [sorted, setSorted] = useState(false)

  const [openImage, setOpenImage] = useState(false);
  const handleOpenImage = () => setOpenImage(true);
  const handleCloseImage = () => setOpenImage(false);

  const [changed, setChanged] = useState(false)
  const [tobeSaved, setTobeSaved] = useState({})


  useEffect(() => {
    let getUser = sessionStorage.getItem("user");
    setUser(getUser);

    axios
      .get(`https://nitropos-backend.nitrosolutions.co.uk/purchaseOrder/open`)
      .then((res) => {
        console.log(res.data);
        setInvoices(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reload, props, changed]);

  const rel = () => {
    setReload(!reload);
  };

  const sortDate = () => {
    setSorted(!sorted)
    let dateData = invoices.sort((a,b) => new Date(a.dateCreated)-new Date(b.dateCreated))
    setInvoices(dateData)
  }

  const sortSite = () => {
    setSorted(!sorted)
    let siteData =  invoices.sort((a,b)=>a.siteName.localeCompare(b.siteName))
    setInvoices(siteData)
  }

  const sortSupplier = () => {
    setSorted(!sorted)
    let supplierData =  invoices.sort((a,b)=>a.supplierName.localeCompare(b.supplierName))
    setInvoices(supplierData)
  }

  const handleChangeAmount = (changed, index, item) => {

    if (changed === "" || changed === 0){
      let tableChanged = document.querySelectorAll('#rows')
      tableChanged[index].classList.remove("needing__saved")
    } else{
      let changedItem = item
      changedItem.totalCost = changed
      //changes colour of icon
      let tableChanged = document.querySelectorAll('#rows')
      tableChanged[index].classList.add("needing__saved")
  
      setTobeSaved(changedItem)
    }
    

  }

  const handleSave = () => {
    //axios request to save Amount or PO Number
  }

  return (
    <main className="open__po">
      <h1>Open PO's</h1>
      {invoices.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>
                Status
              </th>
              <th>
                Site
                <span>
                  <ArrowDropDownIcon
                    sx={{ margin: "0" }}
                    color="primary"
                    fontSize="small"
                    onClick={() => sortSite()}
                  />
                </span>
              </th>
              <th>
                Date
                <span>
                  <ArrowDropDownIcon
                    sx={{ margin: "0" }}
                    color="primary"
                    fontSize="small"
                    onClick={() => sortDate()}
                  />
                </span>
              </th>
              <th>
                Supplier
                <span>
                  <ArrowDropDownIcon
                    sx={{ margin: "0" }}
                    color="primary"
                    fontSize="small"
                    onClick={() => sortSupplier()}
                  />
                </span>
              </th>
              <th>
                Amount
              </th>
              <th>
                PO Number
              </th>
              <th>Image</th>
              <th>Save</th>
            </tr>
          </thead>
          <tbody>
            {invoices.map(function (d, i) {
              let status = ""
              let warning = ""

              let time = moment().diff(new Date(d.dateCreated), "days")
              if ((d.supplierName === "Booker" || d.supplierName === "Londis") && time > 1){
                warning = "alert"
              }
              if (d.orderPlaced === true && d.delivered === false){
                status = "Placed with Supplier"
              } else if (d.delivered === true && d.complete === false){
                status = "Awaiting on Items"
              }

              return (
                <tr className={`${warning}` } id="rows">
                  <td onClick={() => props.handle(d.headingId)}>{status}</td>
                  <td onClick={() => props.handle(d.headingId)}>{d.siteName}</td>
                  <td onClick={() => props.handle(d.headingId)}>{new Date(d.dateCreated).toLocaleDateString("en-GB")}</td>
                  <td onClick={() => props.handle(d.headingId)}>{d.supplierName}</td>
                  <td >
                    <input
                      type="number"
                      placeholder={parseFloat(d.totalCost).toLocaleString(
                        "en-GB"
                      )}
                      min={0}
                      onChange={(e) => handleChangeAmount( e.target.value, i, d)}
                      // onChange={(e) => console.log(e.target.value)}
                    />
                  </td>
                  <td onClick={() => props.handle(d.headingId)}>
                    <input type="number" placeholder={d.invoiceNumber} />
                  </td>
                  <td>
                    {d.image ? (
                      <ImageSearchIcon
                        sx={{ margin: "0", cursor: "pointer" }}
                        // onClick={handleOpenImage}
                      />
                    ) : (
                      <HideImageIcon sx={{ margin: "0" }} />
                    )}
                  </td>
                  <td>
                    <SaveAltIcon className="saved__icon" fontSize="medium" sx={{margin: "0"}} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <h1>No Open invoices</h1>
      )}
      <Modal
        open={openImage}
        onClose={handleCloseImage}
        aria-labelledby="Invoice Image Modal"
        aria-describedby="modal-modal-description"
      >
        <OpenImage />
      </Modal>
    </main>
  );
}
