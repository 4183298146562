import "../styles/navbar.scss";
import Logo from "../../images/logo.png";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import GppGoodIcon from "@mui/icons-material/GppGood";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import InventoryIcon from "@mui/icons-material/Inventory";
import { useState, useEffect, Fragment } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import BreakfastDiningIcon from "@mui/icons-material/BreakfastDining";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";

export default function FtgNav() {
  const navigate = useNavigate();
  const [role, setRole] = useState("");
  const [user, setUser] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    if (windowWidth < 690) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [windowWidth]);

  useEffect(() => {
    let userD = localStorage.getItem("userDetails");
    let user = JSON.parse(userD);
    setUser(user.user.user);
    setRole(user.user.role);
  }, []);

  const Logout = () => {
    localStorage.setItem("auth-token", "");
    localStorage.setItem("role", "");
    localStorage.setItem("userDetails", "");
    navigate("/");
  };

  return (
    <nav id="navbar">
      <img
        src={Logo}
        alt="logo"
        onClick={() => {
          navigate("/food-to-go");
        }}
      />
      {!isMobile ? (
        <div className="icons">
          {role === "Developer" || user === "vikas@ghsl.uk" ? (
            <p onClick={() => navigate("/home")}>
              <AdminPanelSettingsIcon />
              <span>Admin Zone</span>
            </p>
          ) : (
            <></>
          )}
          <p onClick={() => navigate("/food-to-go")}>
            <ProductionQuantityLimitsIcon
              sx={{ color: "#fff" }}
              fontSize="medium"
            />
            <span>Products</span>
          </p>
          <p onClick={() => navigate("/food-to-go/subway")}>
            <BreakfastDiningIcon sx={{ color: "#fff" }} fontSize="medium" />
            <span>Subway EOD</span>
          </p>
          <p onClick={() => navigate("/food-to-go/sato")}>
            <ReceiptLongIcon sx={{ color: "#fff" }} fontSize="medium" />
            <span>Sato Label</span>
          </p>
          <p onClick={() => navigate("/staff-management")}>
            <Diversity3Icon sx={{ color: "#fff" }} fontsize="medium" />
            <span>Staff Management</span>
          </p>
          <p onClick={() => navigate("/managerZone/hoagies-stock")}>
            <InventoryIcon sx={{ color: "#fff" }} fontSize="medium" />
            <span>Stock take</span>
          </p>
          <p onClick={() => navigate("/food-to-go/reports")}>
            <AssessmentIcon sx={{ color: "#fff" }} fontSize="medium" />
            <span>Reporting</span>
          </p>
          <p onClick={() => navigate("/cctv-status")}>
            <QrCodeScannerIcon sx={{ color: "#fff" }} fontSize="medium" />
            <span>CCTV Status Change</span>
          </p>
          <p onClick={() => Logout()}>
            <LogoutIcon sx={{ color: "#fff" }} fontSize="medium" />
            <span>Logout</span>
          </p>
        </div>
      ) : (
        <PopupState variant="popover" popupId="demo-popup-menu">
          {(popupState) => (
            <Fragment>
              <MenuOpenIcon
                {...bindTrigger(popupState)}
                fontSize="large"
                sx={{ color: "#fff" }}
              />
              <Menu {...bindMenu(popupState)}>
                {role === "Developer" || user === "vikas@ghsl.uk" ? (
                  <MenuItem onClick={() => navigate("/home")}>
                    <span className="mobile__icons">
                      <AdminPanelSettingsIcon />
                      Admin Zone
                    </span>
                  </MenuItem>
                ) : (
                  <></>
                )}

                <MenuItem onClick={() => navigate("/food-to-go")}>
                  <span className="mobile__icons">
                    <ProductionQuantityLimitsIcon />
                    Products
                  </span>
                </MenuItem>
                <MenuItem onClick={() => navigate("/food-to-go/subway")}>
                  <BreakfastDiningIcon
                    sx={{ color: "#fff" }}
                    fontSize="medium"
                  />
                  <span>Subway EOD</span>
                </MenuItem>

                <MenuItem onClick={() => navigate("/food-to-go/sato")}>
                  <span className="mobile__icons">
                    <ReceiptLongIcon />
                    Sato Label
                  </span>
                </MenuItem>
                <MenuItem onClick={() => navigate("/staff-management")}>
                  <Diversity3Icon sx={{ color: "#fff" }} fontsize="medium" />
                  <span>Staff Management</span>
                </MenuItem>
                <MenuItem
                  onClick={() => navigate("/managerZone/hoagies-stock")}
                >
                  <span className="mobile__icons">
                    <InventoryIcon />
                    Stock take
                  </span>
                </MenuItem>

                <MenuItem onClick={() => navigate("/food-to-go/reports")}>
                  <span className="mobile__icons">
                    <AssessmentIcon />
                    Reporting
                  </span>
                </MenuItem>
                <MenuItem onClick={() => navigate("/cctv-status")}>
                  <span className="mobile__icons">
                    <QrCodeScannerIcon />
                    CCTV Status Change
                  </span>
                </MenuItem>

                <MenuItem onClick={() => Logout()}>
                  <span className="mobile__icons">
                    <LogoutIcon />
                    Logout
                  </span>
                </MenuItem>
              </Menu>
            </Fragment>
          )}
        </PopupState>
      )}
    </nav>
  );
}
