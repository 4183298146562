import React from "react";
import "./rota.scss";
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { getCompanies } from "../../utils/Api";
import { decryptData } from "./cryptoUtils";
import RotaSettingsTable from "./RotaSettingsTable";

import Typography from "@mui/material/Typography";

const RotaSettings = ({ managerInfo }) => {
  const navigate = useNavigate();
  //Main - Admin
  const url = "https://nitropos-backend.nitrosolutions.co.uk";
  const hrUrl = process.env.REACT_APP_API_URL;
  // const hrUrl = "http://10.0.0.174:4030";
  const [token, setToken] = useState("");
  const config = {
    headers: { "Content-Type": "application/json", "x-auth-token": token },
  };
  //User
  const [user, setUser] = useState("");

  //Sites
  const [managedSites, setManagedSites] = useState([]);
  const [siteName, setSiteName] = useState("");
  const [siteId, setSiteId] = useState("");
  const [comapnyid, setCompanyid] = useState(null);
  const [oneSiteManager, setOneSiteManager] = useState(false);
  const [addEmployeeSites, setAddEmployeeSites] = useState([]);

  //Dates
  const [currentDate, setCurrentDay] = useState(moment().format());
  const [firstWeekDay, setFirstWeekDay] = useState("");
  const [previousWeek, setPreviousWeek] = useState(false);
  const [isCurrentWeek, setIsCurrentWeek] = useState(false);
  const [shortDates, setShortDates] = useState([]);
  const [fullDates, setFullDates] = useState([]);


  //Alerts
  const [loader, setLoader] = useState(false);

  useEffect(async () => {
    let oneSite = "";
    let siteID = "";
    //Set auth token
    let getToken = localStorage.getItem("auth-token");
    setToken(getToken);

    //Get user save to state
    let userD = localStorage.getItem("userDetails");
    let user = JSON.parse(userD);
    setUser(user.user.user);

    //Get Sites specific for logged in manager
    //If manager with one site sets single siteName and siteID
    //If manager with multiple site sets array of sites which can be selected
    await axios
      .post(`${url}/eposSQL/getSitesByName`, {
        sites: managerInfo && managerInfo.sites,
      })
      .then((res) => {
        if (res.data.length === 1) {
          oneSite = res.data[0].eposId;
          siteID = res.data[0].eposId;
          setSiteId(res.data[0].eposId);
          setSiteName(res.data[0].siteName);
          setOneSiteManager(true);
        } else {
          var sitesArr = res.data;
          sitesArr.push({ siteName: "RTMS", eposId: 1 });
          sitesArr.push({ siteName: "Speed Queen", eposId: 0 });
          sitesArr.push({ siteName: "Nitro Solutions", eposId: 2 });
          setManagedSites(sitesArr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    //Set site list for adding employees
    let allSites = await getCompanies(getToken);
    allSites = allSites.filter((item) => item.eposId != siteId);
    setAddEmployeeSites(allSites);
    // Bring in Rota and Staff Data for one site manage or if siteId already selected
    
  }, []);

  const handleSiteSelection = async (id) => {
    moment.updateLocale("en-UK", {
      week: {
        dow: 6,
        doy: 12,
      },
    });

    setLoader(true);

    setSiteId(id);
    console.log(managedSites)
    for (let ms = 0; ms < managedSites.length; ms++) {
      if (managedSites[ms].eposId == id) {
        // console.log('comming here', managedSites[ms]?.siteName, addEmployeeSites[ms]?.companyId)
        setSiteName(managedSites[ms]?.siteName);
        setCompanyid(addEmployeeSites[ms]?.companyId);
        if(id==2){
          setCompanyid(17)
        }
      }
    }

  };
  return (
    <div
      className="table-container"
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-around",
        alignItems: "center",
      }}
    >
      <div style={{ fontSize: 30, fontWeight: "bold" }}>Rota settings</div>
      {!oneSiteManager ? (
        <select onChange={(e) => handleSiteSelection(e.target.value, e)} 
        style={{
          borderRadius: '8px',
          padding: '10px',
          fontFamily: 'Arial, sans-serif',
          fontSize: '14px',
          border: '3px solid black',
          width:200
          // outline: 'none'
      }}>
          <option>--Select Site--</option>
          {!!managedSites &&
            managedSites.map(function (site, index) {
              return (
                <option key={index} value={site.eposId}>
                  {site.siteName}
                </option>
              );
            })}
        </select>
      ) : null}
      {siteId ? (
        <h2>
          <span>{siteName}</span> Rota
        </h2>
      ) : null}
      <div
        style={{
          display: "flex",
          width: "100%",
          height: 200,
        }}
      >
        {siteId && comapnyid&& <RotaSettingsTable siteId={siteId} companyid={comapnyid} />}
      </div>
    </div>
  );
};

export default RotaSettings;
