import { useState, useEffect } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import IndProduct from "./IndvProduct";
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert';
import axios from 'axios';

export default function SiteBakes(props) {
  const [data, setData] = useState(props.data);
  const [showProducts, setShowProducts] = useState(false);
  const [message, setMessage] = useState("")
  const [errorAlert, setErrorAlert] = useState(false)
  const [submitAlert, setSubmitAlert] = useState(false)

  useEffect(() => {
    setData(props.data)
  },[props])


  const handleProducts = () => {
    setShowProducts(!showProducts);
  };

  const handleChange = (amount, index) => {
    if (parseInt(amount) < 0){
        setMessage("You cannot order negative quantities")
        setErrorAlert(true)
        setTimeout(() => {
            setMessage("")
            setErrorAlert(false)
        },3500)
    } else{
        data.products[index].amountNeeded = parseInt(amount)
    }
  }

  const handleConfirm = () => {
    document.getElementById("confirm_btn").style.display = "none"
    axios
    .post("https://nitropos-backend.nitrosolutions.co.uk/tubbees/order/site/confirm", data)
    .then((res) => {
        document.getElementById("confirm_btn").style.display = "inline"
        setShowProducts(!showProducts)
        props.rel()
    }).catch((err) => {
        setMessage("Something went wrong, please try again")
        setSubmitAlert(true)
        setTimeout(() => {
            setMessage("")
            setSubmitAlert(false)
            document.getElementById("confirm_btn").style.display = "inline"
        },3500)
    })
  }

  return (
    <div className="site__overview">
      <h2 onClick={() => handleProducts()}>
        Site: {data.siteName} - Products: {data.products.length}
        <span>
          <ArrowDropDownIcon fontSize="medium" className="icon__animation" />
        </span>
      </h2>
      {showProducts ? (
        <>
        {errorAlert ? <Alert severity="warning">{message}</Alert>:<></>}
        <table>
          <thead>
            <th>Product</th>
            <th>Stock</th>
            <th>Sales last 7 days</th>
            <th>To Order</th>
            <th>Change</th>
          </thead>
          <tbody>
            {data.products.map(function (d, i) {
                let negative = ""
                if (parseInt(d.stock) < 0){
                    negative = "negative"
                }
              return <IndProduct product={d} index={i} handleChange={handleChange} negative={negative}/>;
            })}
          </tbody>
        </table>
            {submitAlert ? <Alert severity="error">{message}</Alert>: <></>}
        <Button variant="outlined" color="success" onClick={() => handleConfirm()} id="confirm_btn">Confirm</Button>
        </>
      ) : (
        <></>
      )}

    </div>
  );
}
