import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Logo from "../../../images/logo.png";
import ErrorNotice from "../../ErrorNotice";
import { useNavigate } from "react-router-dom";
import "../../styles/login.scss";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

export default function LoginV4U(props) {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState();
  const [attempts, setAttempts] = useState(0);
  const url = "https://nitropos-backend.nitrosolutions.co.uk";
  const navigate = useNavigate();
  const [type, setType] = useState("password");

  const submit = useCallback(async (e) => {
    e.preventDefault();

    try {
      const loginUser = { email, password };
      const loginRes = await axios.post(`${url}/users/login`, loginUser);
      localStorage.setItem("auth-token", loginRes.data.token);
      let user = {
        user: loginRes.data.user,
        sites: loginRes.data.user.sites,
        role: loginRes.data.role,
      };
      localStorage.setItem("userDetails", JSON.stringify(user));
      if (
        loginRes.data.user.role === "Warehouse Manager" ||
        loginRes.data.user.role === "V4U Manager" ||
        loginRes.data.user.role === "Developer"
      ) {
        props.rel();
      } else {
        navigate("/");
      }
    } catch (err) {
      console.log(err);
      err.response.data.msg && setError(err.response.data.msg);
      if (err.response.data.msg == "Invalid credentials.") {
        var attempt = attempts + 1;
        setAttempts(attempt);
        if (attempt >= 3) {
          //Calling Forgot Password
          forgottenPassword();
        } else {
          console.log(attempt);
        }
      }
    }
  });

  const forgottenPassword = () => {
    // console.log(email);

    //If no email display error
    if (email == null) {
      setError("Please insert Email adress");
    } else {
      axios
        .post(
          //sending user to /Forgot with Email info
          `${url}/users/forgot`,
          { email: email }
        )
        .then((res) => {
          //alert to update user
          alert("An email will be sent shortly with your credentials!");
          navigate("/");
        })
        //Error catch
        .catch((err2) => {
          console.log(err2);
        });
    }
  };

  const togglePass = () => {
    let password = document.getElementById("password_input")
    let type = password.getAttribute('type') === 'password' ? 'text' : 'password';
    password.setAttribute('type', type);
    setType(type)
  }

  return (
    <div className="login">
      <img src={Logo} />
      <h1>Please login to aprove the order</h1>
      {error && (
        <ErrorNotice message={error} clearError={() => setError(undefined)} />
      )}
      <form onSubmit={submit}>
        <input
          type="text"
          placeholder="Username"
          onChange={(e) => setEmail(e.target.value)}
        />
        <br />
        <div className="password_field">
          {type === "password" ? (
            <VisibilityOffIcon className="icon" color="primary" onClick={togglePass}/>
          ) : (
            <VisibilityIcon className="icon" color="primary" onClick={togglePass}/>
          )}
          <input
            type="password"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
            id="password_input"

          />
        </div>
        <br />
        <input type="submit" value="Login" />
      </form>
    </div>
  );
}
