import { useState, useEffect } from "react";
import "./subway.scss";
import { Button, Alert } from "@mui/material";
import axios from "axios";
import Tick from "../../../animations/Tick";
import SubShiftGrouping from "./steps/SubShiftGrouping";
import ConfirmVals from "./steps/ConfirmVals";
import ConfirmDiscrep from "./steps/ConfirmDiscrep";
import imageCompression from "browser-image-compression";
import { getSubwaySites } from "../../../utils/Api";

export default function SubwayHome() {
//   const url = "http://10.0.0.157:4022";
  const url="https://nitropos-backend.nitrosolutions.co.uk"

  const [subwaySites, setSubwaySites] = useState([]);
  const [site, setSite] = useState("");
  const [siteName, setSiteName] = useState("");
  const [user, setUser] = useState("");
  //alerts
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [stepCount, setStepCount] = useState(1);
  const [showTick, setShowTick] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  //EOD data
  const [openShifts, setOpenShifts] = useState([]);
  const [groupedShifts, setGroupedShifts] = useState([]);
  const [actual, setActual] = useState(0);
  const [difference, setDifference] = useState(0);
  const [bagId, setBagId] = useState("");
  const [image, setImage] = useState("");

  useEffect(() => {
    let userD = localStorage.getItem("userDetails");
    let user = JSON.parse(userD);
    setUser(user.user.user);

    getSubwaySites()
    .then((sites) => {
      setSubwaySites(sites);
    }).catch((err) => {
      console.log(`Could not get subway locations: ${err}`);
    })
  }, []);

  const handleSite = (selectedSite) => {
    setSite("");
    setSiteName("");
    setStepCount(1);
    if (selectedSite === "--Please select one--") {
      document.getElementById("next_btn").style.display = "none";
      setMessage("Please select a site");
      setSeverity("warning");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        document.getElementById("next_btn").style.display = "flex";
      });
    } else {
      setSite(selectedSite);
      for (let i = 0; i < subwaySites.length; i++) {
        if (selectedSite == subwaySites[i].subLocationId) {
          setSiteName(subwaySites[i].siteName);
        }
      }
    }
  };

  const handleNext = async () => {
    await axios
      .get(`${url}/eos/subway/banked/${site}`)
      .then((res) => {
        setOpenShifts(res.data);
        setStepCount(2);
      })
      .catch((err) => {
        console.log(`could not get shifts for site: ${site} - ${err}`);
      });
  };

  const handleGroupedShifts = (shifts) => {
    setGroupedShifts(shifts);
    setStepCount(3);
  };

  const handleValues = (shifts) => {
    setGroupedShifts(shifts);
    setStepCount(4);
  };

  const handleBag = (actual, diff, bag) => {
    setBagId(bag);
    setActual(actual);
    setDifference(diff);
    setStepCount(5);
  };

  const addImage = async (img) => {
    //compression of the image for upload
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 400,
    };
    const compressedFile = await imageCompression(img, options);
    const fileReader = new FileReader();
    fileReader.readAsDataURL(compressedFile);
    fileReader.onload = () => {
      setImage(fileReader.result);
    };
  };

  const confirmEod = () => {
    let data = {
      actual: actual,
      discrepancy: parseFloat(difference).toFixed(2),
      bagId: bagId,
      shifts: groupedShifts,
      image: image,
      user: user,
    };
    document.getElementById("confirm_eod").style.display = "none";
    axios
      .post(`${url}/eos/subway/eod/complete`, data)
      .then((res) => {
        if (res.status === 200) {
          setShowTick(true);
          setTimeout(() => {
            document.getElementById("confirm_eod").style.display = "flex";
            setStepCount(1);
            setSite("");
            setSiteName("");
            setShowTick(false)
          }, 3500);
        }
      })
      .catch((err) => {
        console.log(`Could not save EOD: ${err}`);
        setMessage("Something went wrong, please try again");
        setSeverity("error");
        setErrorAlert(true);
        setTimeout(() => {
          setErrorAlert(false);
          document.getElementById("confirm_eod").style.display = "flex";
          let selects = document.querySelectorAll("select")
          for (let i = 0; i < selects.length; i++){
            selects[i].selectedIndex = 0;
          }
        }, 3500);
      });
  };

  return (
    <section className="subway_eods">
      <h1>subway EOD</h1>
      <div className="eod_row">
        <label>Please select site:</label>
        <select onChange={(e) => handleSite(e.target.value)}>
          <option>--Please select one--</option>
          {subwaySites && subwaySites.length > 0
            ? subwaySites.map((site) => {
                return (
                  <option key={site.id} value={site.subLocationId}>
                    {site.siteName}
                  </option>
                );
              })
            : null}
        </select>
      </div>
      {siteName ? <h1>EOD for: {siteName}</h1> : <h1>Select a site</h1>}
      <hr />
      {showAlert ? (
        <Alert severity={severity} sx={{ width: "60%", margin: "1% auto" }}>
          {message}
        </Alert>
      ) : null}
      {stepCount === 1 ? (
        <Button
          variant="outlined"
          color="success"
          id="next_btn"
          sx={{ margin: "2% auto" }}
          disabled={site === ""}
          onClick={handleNext}
        >
          Next Step
        </Button>
      ) : stepCount === 2 ? (
        <SubShiftGrouping
          openShifts={openShifts}
          handleGroupedShifts={handleGroupedShifts}
        />
      ) : stepCount === 3 ? (
        <ConfirmVals
          groupedShifts={groupedShifts}
          handleValues={handleValues}
        />
      ) : stepCount === 4 ? (
        <ConfirmDiscrep groupedShifts={groupedShifts} handleBag={handleBag} />
      ) : stepCount === 5 ? (
        <section className="subway_section">
          <h4>
            Please add an screenshot of your Subway EOD
          </h4>
          <input
            type="file"
            accept="image/*"
            capture
            onChange={(e) => {
              addImage(e.target.files[0]);
            }}
          />
          <Button
            variant="contained"
            color="success"
            onClick={confirmEod}
            sx={{ margin: "1% auto" }}
            id="confirm_eod"
          >
            Confirm EOD
          </Button>
        </section>
      ) : null}
      {showTick ? (
        <Tick />
      ) : errorAlert ? (
        <Alert severity={severity} sx={{ width: "60%", margin: "1% auto" }}>
          {message}
        </Alert>
      ) : null}
    </section>
  );
}

