import { useState } from "react";
import moment from "moment";
//MUI
import { Modal, Button } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import EditIcon from "@mui/icons-material/Edit";
//Components
import CCTVAdminInfoModal from "./CCTVAdminInfoModal";
import CCTVAdminStatusModal from "./CCTVAdminStatusModal";
//Interfaces
interface Props {
    user: any;
    request: any;
    statuses: any;
    reloadRequestData: () => void;
}

export default function CCTVAdminRow({
    user,
    request,
    statuses,
    reloadRequestData,
}: Props) {
    const [openInfo, setOpenInfo] = useState<boolean>(false);
    const [openStatus, setOpenStatus] = useState<boolean>(false);

    return (
        <>
            <tr className={request.unactionIn7Days ? "not-actioned" : ""}>
                <td>{request.site}</td>
                <td>{request.staffName}</td>
                <td>
                    {moment(request.date).format("DD/MM/YY")} - {request.time}
                </td>
                <td>{request.typeName}</td>
                <td className="status-cell">
                    {request.statusName}
                    <Button color="success" onClick={() => setOpenStatus(true)}>
                        <EditIcon />
                    </Button>
                </td>
                <td>
                    {request.requestType === 2
                        ? request.reason
                        : `${request.plate} (£${request.amount})`}
                </td>
                <td>
                    {request.crimeReference ? (
                        request.crimeReference
                    ) : (
                        <DoDisturbIcon fontSize="large" color="error" />
                    )}
                </td>
                <td>
                    <InfoIcon
                        fontSize="large"
                        onClick={() => setOpenInfo(true)}
                        color="primary"
                        className="info-icon"
                    />
                </td>
            </tr>
            <Modal open={openInfo}>
                <CCTVAdminInfoModal
                    setOpenInfo={setOpenInfo}
                    request={request}
                    user={user}
                    reloadRequestData={reloadRequestData}
                />
            </Modal>
            <Modal open={openStatus}>
                <CCTVAdminStatusModal
                    setOpenStatus={setOpenStatus}
                    request={request}
                    statuses={statuses}
                    reloadRequestData={reloadRequestData}
                    user={user}
                />
            </Modal>
        </>
    );
}
