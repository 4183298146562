import { useState, useEffect } from "react";
import { getSubwaySites } from "../utils/Api";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, Alert } from "@mui/material";
import axios from "axios";
import Loader from "../animations/Loader";
import ImageIcon from "@mui/icons-material/Image";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";

export default function SubwayReport() {
  // const url = "http://10.0.0.34:4022";
  const url="https://nitropos-backend.nitrosolutions.co.uk"
  const [subwaySites, setSubwaySites] = useState([]);
  const [selectedSite, setSelectedSite] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [showData, setShowData] = useState(false);
  const [eodData, setEodData] = useState([]);
  //alerts
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  useEffect(() => {
    getSubwaySites()
      .then((sites) => {
        setSubwaySites(sites);
      })
      .catch((err) => {
        console.log(`Could not get sites: ${err}`);
      });
  }, []);

  const searchEod = () => {
    setShowData(false);
    setShowLoader(true);
    const data = {
      subwayLocationId: selectedSite,
      startDate: startDate,
      endDate: endDate,
    };
    axios
      .post(`${url}/eos/subway/reporting/date`, data)
      .then((res) => {
        console.log(res.data);
        setEodData(res.data);
        setShowLoader(false);
        setShowData(true);
      })
      .catch((err) => {
        console.log(`Could not get EOD's: ${err.response.data}`);
        setShowLoader(false);
        setMessage("There was an error getting the data, please try again");
        setSeverity("warning");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      });
  };

  const handleShowImage = (base64) => {
    let image = new Image();
    image.src = base64;
    let w = window.open("", "_blank");
    w.document.write(image.outerHTML);
  };

  return (
    <section className="global__report">
      <h1>Subway End Of Days</h1>
      <section className="report__row">
        <select onChange={(e) => setSelectedSite(e.target.value)}>
          <option>--Please select one--</option>
          {subwaySites && subwaySites.length > 0
            ? subwaySites.map((site) => {
                return (
                  <option key={site.id} value={site.id}>
                    {site.siteName}
                  </option>
                );
              })
            : null}
        </select>
        <input type="date" onChange={(e) => setStartDate(e.target.value)} />
        <input type="date" onChange={(e) => setEndDate(e.target.value)} />
        <IconButton onClick={searchEod}>
          <SearchIcon fontSize="large" color="primary" />
        </IconButton>
      </section>
      {showLoader ? (
        <Loader />
      ) : showAlert ? (
        <Alert severity={severity} sx={{ width: "60%", margin: "1% auto" }}>
          {message}
        </Alert>
      ) : null}
      {showData ? (
        eodData && eodData.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>Bag Id</th>
                <th>Expected from Subway</th>
                <th>Actual from Manager</th>
                <th>Difference</th>
                <th>User</th>
                <th>Image</th>
              </tr>
            </thead>
            <tbody>
              {eodData.map((eod, index) => {
                return (
                  <tr key={index}>
                    <td>{eod.bagId}</td>
                    <td>£{eod.expected}</td>
                    <td>£{parseFloat(eod.actual).toFixed(2)}</td>
                    <td>£{parseFloat(eod.discrepancy).toFixed(2)}</td>
                    <td>{eod.user}</td>
                    <td>
                      {eod.image != "" ? (
                        <ImageIcon
                          color="primary"
                          sx={{ cursor: "pointer" }}
                          onClick={() => handleShowImage(eod.image)}
                        />
                      ) : (
                        <ImageNotSupportedIcon />
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <h2>No data to show</h2>
        )
      ) : null}
    </section>
  );
}
