import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://0892e3e583544098a8203610648c8929@sentry.rt-itservices.co.uk/20",
  integrations: [new Integrations.BrowserTracing(),
    // new Sentry.Replay({
    //   // Additional SDK configuration goes in here, for example:
    //   maskAllText: false,
    //   blockAllMedia: true,
    // }),

  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
