import "../rota.scss";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import moment from "moment";
import { decryptData } from "../cryptoUtils";
import { Button, Typography } from "@mui/material";
import ReactToPrint from "react-to-print";
import { getCompanies } from "../../../utils/Api";
import PrintRota from "./PrintRota";
import { useParams } from "react-router-dom";

export default function PrintRotaHome() {
  const { rotaId } = useParams();
  const { siteId } = useParams();
  const { weekStart } = useParams();
  //Main - Admin
  const hrUrl = process.env.REACT_APP_API_URL;
  // const hrUrl = "http://10.0.0.174:4030";
  const [token, setToken] = useState("");
  const componentRef = useRef();
  const myRefname = useRef(null);
  //User
  const [user, setUser] = useState("");
  //Sites
  const [siteName, setSiteName] = useState("");
  //Dates
  const [currentDate, setCurrentDay] = useState(moment().format());
  const [firstWeekDay, setFirstWeekDay] = useState("");
  const [shortDates, setShortDates] = useState([]);
  const [fullDates, setFullDates] = useState([]);
  const [published, setPublished] = useState(false);
  const [confirmedDates, setConfirmedDates] = useState({});
  //Staff
  const [staffList, setStaffList] = useState([]);
  const [lastEmployeeId, setLastEmployeeId] = useState(null);
  const [hideHours, setHideHours] = useState(false);

  //ON PAGE RENDER / RELOAD
  useEffect(() => {
    const initialLoad = async () => {
      //updates moment to get first day of the week Saturday
      //first week of the year must contained 1st of January
      moment.updateLocale("en-UK", {
        week: {
          dow: 6,
          doy: 12,
        },
      });
      setStaffList([]);

      //Set auth token
      let getToken = localStorage.getItem("auth-token");
      setToken(getToken);

      let userD = localStorage.getItem("userDetails");
      let user = JSON.parse(userD);
      setUser(user.user.user);

      //Set Dates
      let weekShortDates = [];
      let weekFullDates = [];
      for (let w = 0; w <= 6; w++) {
        if (w === 0) {
          setFirstWeekDay(moment(weekStart).weekday(w).format("DD-MM-YYYY"));
        }
        weekShortDates.push(moment(weekStart).weekday(w).format("dddd - Do"));
        weekFullDates.push(moment(weekStart).weekday(w).format("YYYY-MM-DD"));
      }

      setShortDates(weekShortDates);
      setFullDates(weekFullDates);

      await axios
        .post(
          `${hrUrl}/rota/employees/week`,
          {
            rotaId: rotaId,
            user: user,
            siteId: siteId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "x-auth-token": getToken,
            },
          }
        )
        .then(async (res) => {
          //Set site list for adding employees
          let allSites = await getCompanies(getToken);
          allSites = [
            ...allSites,
            { siteName: "Speed Queen", companyId: 16 },
            { siteName: "Nitro Solutions", companyId: 17 },
          ];
          allSites = allSites.filter(
            (item) => res.data.rota.companyId === item.companyId
          );
          console.log(allSites);
          setSiteName(allSites[0].siteName);

          const decryptEmployees = await res.data.staff.map((employee) => ({
            ...employee,
            firstName: decryptData(
              employee.firstName,
              process.env.REACT_APP_EMP_KEY
            ),
            lastName: decryptData(
              employee.lastName,
              process.env.REACT_APP_EMP_KEY
            ),
            position: employee.position,
            worked: employee.worked,
          }));

          setStaffList(decryptEmployees);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    initialLoad();
  }, []);

  //React Print functions
  const handleClick = () => {
    myRefname.current.click();

    axios
      .post(
        `${hrUrl}/rota/add-log`,
        {
          rotaId: rotaId,
          user: user,
          log: "Printing Rota",
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
          },
        }
      )
      .then(async (res) => {})
      .catch((err) => {
        console.log(err);
      });
  };
  const linkToPrint = () => {
    return (
      <Button
        variant="contained"
        color="warning"
        ref={myRefname}
        style={{ display: "none" }}
      >
        Print PDF
      </Button>
    );
  };

  return (
    <section ref={componentRef} className="printable_rota">
      <div className="header_section">
        {" "}
        <Button
          variant="contained"
          color="warning"
          onClick={() => handleClick()}
        >
          PRINT
        </Button>
        <h2>
          <span>{siteName}</span> W/C: <span>{firstWeekDay}</span>{" "}
          <span style={{ fontSize: "0.8rem" }}>
            If (U) is displayed then the shift has not been published
          </span>
        </h2>
      </div>

      <PrintRota
        user={user}
        site={siteId}
        siteName={siteName}
        rotaId={rotaId}
        currentDate={currentDate}
        fullDates={fullDates}
        staffList={staffList}
        published={published}
        shortDates={shortDates}
        confirmedDates={confirmedDates}
        lastEmployeeId={lastEmployeeId}
        hideHours={hideHours}
        printable={false}
      />
      <ReactToPrint
        documentTitle={`${siteName}_Rota_WC_${firstWeekDay}`}
        trigger={linkToPrint}
        content={() => componentRef.current}
      />
    </section>
  );
}
