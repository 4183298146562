import { useState, useEffect } from "react";
import axios from "axios";
import "./eod.scss";
import { Button, Modal } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import IndvBanking from "./modal/IndvBanking";
import HistorySearchModal from "./modal/HistorySearchModal";;

export default function BankingHistory() {
    const [data, setData] = useState([]);
    const [updateData, setUpdateData] = useState([]);
    const [err, setErr] = useState(false);
    const [user, setUser] = useState("");
    const [openBankedHistory, setOpenBankedHistory] = useState(false);
    const [bankedHistorical, setBankedHistorical] = useState([]);
    const [message, setMessage] = useState("");
    const [dateRange, setDateRange] = useState("Last 30 Days");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const url = "https://nitropos-backend.nitrosolutions.co.uk";
    // const url = "http://10.0.0.34:4022";

    useEffect(() => {
        let email = sessionStorage.getItem("user");
        setUser(email);
        axios
            .get(`${url}/eos/history`)
            .then((res) => {
                setData(res.data);
                console.log(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
        setMessage(`Loading Historical Data For`);
        axios
            .post(
                `${url}/eos/safeDrops/history`,
                { custom: false }
            )
            .then((res) => {
                if (res.data.length == 0) {
                    setMessage("No Results Were Returned For");
                } else {
                    setBankedHistorical(res.data);
                }
            })
            .catch((err) => {
                setMessage("No Results Were Returned For");
                console.log(err);
            });
    }, []);

    const test = (val, id) => {
        var findIdx = updateData.findIndex((i) => i == id);
        if (findIdx < 0) {
            setUpdateData((updateData) => [...updateData, id]);
        } else {
            var filtArr = updateData.filter((i) => i != id);
            setUpdateData(filtArr);
        }
    };

    const confirm = async () => {
        if (updateData.length > 0) {
            var count = 0;
            for (let a = 0; a < updateData.length; a++) {
                var data = { id: updateData[a], status: 4, user: user };
                await axios
                    .post(
                        `${url}/eos/statuses`,
                        data
                    )
                    .then((res) => {
                        count++;
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
            if (count == updateData.length) {
                window.location.href = "/managerZone/eod";
            }
        } else {
            setErr("Cannot Confirm Without Any Selected Bank Drops!");
            setTimeout(() => {
                setErr("");
            }, 2000);
        }
    };

    const handleClose = () => {
        setOpenBankedHistory(false);
        setStartDate("");
        setEndDate("");
    };

    const searchDates = async () => {
        setBankedHistorical([]);
        setDateRange(`${startDate} - ${endDate}`);
        setMessage(`Loading Historical Data For`);
        await axios
            .post(
                `${url}/eos/safeDrops/history`,
                { custom: true, startDate: startDate, endDate: endDate }
            )
            .then((res) => {
                setBankedHistorical(res.data);
                if (res.data.length == 0) {
                    setMessage("No Results Were Returned For");
                }
            })
            .catch((err) => {
                setMessage("Something went wrong, please try again");
                console.log(err);
            });
    };

    return (
        <section className="manager__banking">
            <h1>History</h1>
            <Button
                variant="contained"
                color="primary"
                sx={{ position: "absolute", marginTop: "1.5%", right: "2%" }}
                onClick={() => setOpenBankedHistory(!openBankedHistory)}
            >
                🕐
            </Button>
            <table>
                <thead>
                    <tr>
                        <th>Site</th>
                        <th>EOD</th>
                        <th>Amount</th>
                        <th>Reference</th>
                        <th>Pay In Slip</th>
                        <th>Deposited By</th>
                        <th>Date</th>
                        <th>Confirmed</th>
                    </tr>
                </thead>
                <tbody>
                    {data.length > 0 ? (
                        <>
                            {data.map(function (d, i) {
                                let amount;
                                if (
                                    d.bagNote &&
                                    d.bagNote != "" &&
                                    d.bagNote != "0"
                                ) {
                                    amount = d.bagNote;
                                } else {
                                    amount = d.amount;
                                }
                                return (
                                    <tr>
                                        <td>{d.siteName}</td>
                                        <td>
                                            {new Date(
                                                d.startTime
                                            ).toLocaleDateString()}{" "}
                                            -{" "}
                                            {new Date(
                                                d.endTime
                                            ).toLocaleDateString()}
                                        </td>
                                        <td>£{amount}</td>
                                        <td>{d.bagId}</td>
                                        <td>{d.payInSlip}</td>
                                        <td>{d.user}</td>
                                        <td>
                                            {new Date(
                                                d.dateTime
                                            ).toDateString()}
                                        </td>
                                        <td>
                                            <input
                                                style={{ marginLeft: "-30%" }}
                                                type="checkbox"
                                                onClick={(e) => {
                                                    test(
                                                        e.target.value,
                                                        d.eodId
                                                    );
                                                }}
                                            />
                                        </td>
                                    </tr>
                                );
                            })}
                        </>
                    ) : (
                        <tr>
                            <td colSpan={7}>NO DATA TO SHOW</td>
                        </tr>
                    )}
                </tbody>
            </table>
            <h3>{err}</h3>
            <Button
                variant="contained"
                color="success"
                sx={{ width: "10vw" }}
                onClick={() => confirm()}
            >
                CONFIRM
            </Button>

            <Modal open={openBankedHistory} onClose={handleClose}>
                <HistorySearchModal
                    dateRange={dateRange}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    searchDates={searchDates}
                    bankedHistorical={bankedHistorical}
                    message={message}
                />
            </Modal>
        </section>
    );
}
