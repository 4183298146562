import axios from "axios";
import { useState, useEffect } from "react";
import "../../styles/tiktok.scss";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import Alert from "@mui/material/Alert";

const OpenReturns = () => {
    const [tiktokReturns, setTiktokReturns] = useState([]);
    const [selectedReturn, setSelectedReturn] = useState([]);
    const [open, setOpen] = useState(false);
    const [updateTrackingStatus, setUpdateTrackingStatus] = useState(false);
    const [updateTrackingNumber, setUpdateTrackingNumber] = useState(false);
    const [id, setId] = useState("");
    const [updateRefundApproved, setUpdateRefundApproved] = useState(false);
    const [updateItemReturned, setUpdateItemReturned] = useState(false);
    const [updateTicketStatus, setUpdateTicketStatus] = useState(false);
    const [reload, setReload] = useState(false);
    const handleUpdateTrackingStatus = () => {
        setUpdateTrackingStatus(!updateTrackingStatus);
    };
    const handleUpdateTrackingNumber = () => {
        setUpdateTrackingNumber(!updateTrackingNumber);
    };
    const handleUpdateRefundApproved = () => {
        setUpdateRefundApproved(!updateRefundApproved);
    };
    const handleUpdateItemReturned = () => {
        setUpdateItemReturned(!updateItemReturned);
    };
    const handleUpdateTicketStatus = () => {
        setUpdateTicketStatus(!updateTicketStatus);
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    //Update Form
    const [trackingNumber, setTrackingNumber] = useState(
        selectedReturn.trackingNumber
    );
    const [trackingStatus, setTrackingStatus] = useState(
        selectedReturn.trackingStatus
    );
    const [refundApproved, setRefundApproved] = useState(
        selectedReturn.refundApproved
    );
    const [refundDate, setRefundDate] = useState(selectedReturn.refundDate);
    const [refundAmount, setRefundAmount] = useState(
        selectedReturn.refundAmount
    );

    const [ticketStatus, setTicketStatus] = useState(
        selectedReturn.ticketStatus
    );
    const [itemReturned, setItemReturned] = useState(
        selectedReturn.itemReturned
    );
    const [itemDamaged, setItemDamaged] = useState(selectedReturn.itemDamaged);

    //Alerts
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "95vw",
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        overflowY: "scroll",
        height: "90vh",
        padding: "10px",
    };

    // Get all returns
    useEffect(() => {
        axios
            .get(
                "https://nitropos-backend.nitrosolutions.co.uk/edn/tiktok/allReturns"
            )
            .then((res) => {
                setTiktokReturns(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [reload]);

    const rel = () => {
        setReload(!reload);
    };

    // Get specific return and open information modal
    const handleSelectedReturn = (tiktokReturn) => {
        setSelectedReturn(tiktokReturn.data);
        setId(tiktokReturn.id);
        handleOpen();
    };

    // Submit Update Form
    const handleUpdateReturn = (e) => {
        e.preventDefault();

        // Ensure input fields populated
        if (
            trackingNumber === "" ||
            trackingStatus === "Please select:" ||
            trackingStatus === "" ||
            ticketStatus === "" ||
            ticketStatus === "Please select:" ||
            itemReturned === "" ||
            refundApproved === "Please select:" ||
            refundApproved === "" ||
            refundDate === ""
        ) {
            setMessage("Please ensure all fields are complete.");
            setSeverity("warning");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
                setSeverity("");
                setMessage("");
                document.getElementById("update-btn").style = `display: flex;
            text-align: center`;
            }, 3000);
        } else if (
            (refundApproved && refundDate === "") ||
            (refundApproved && refundAmount === "")
        ) {
            setMessage("Please ensure date is input.");
            setSeverity("warning");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
                setSeverity("");
                setMessage("");
                document.getElementById("update-btn").style = `display: flex;
                text-align: center`;
            }, 3000);
        } else {
            // Set data to updated values if changes made, otherwise remain unchanged
            let data = {
                ...selectedReturn,
                trackingNumber: trackingNumber
                    ? trackingNumber
                    : selectedReturn.trackingNumber,
                trackingStatus: trackingStatus
                    ? trackingStatus
                    : selectedReturn.trackingStatus,
                ticketStatus: ticketStatus
                    ? ticketStatus
                    : selectedReturn.ticketStatus,
                refundDate: refundDate ? refundDate : selectedReturn.refundDate,
                refundAmount: refundAmount ? refundAmount : selectedReturn.refundAmount,
                refundApproved: refundApproved
                    ? refundApproved
                    : selectedReturn.refundApproved,
                itemReturned: itemReturned
                    ? itemReturned
                    : selectedReturn.itemReturned,
                itemDamaged: itemDamaged
                    ? itemDamaged
                    : selectedReturn.itemDamaged,
                id: id,
            };
            axios
                .post(
                    `https://nitropos-backend.nitrosolutions.co.uk/edn/tiktok/returns`,
                    {
                        data,
                    }
                )
                .then((res) => {
                    setMessage("Succesfully submitted. Status: " + res.status);
                    setSeverity("success");
                    setShowAlert(true);
                    setTimeout(() => {
                        setShowAlert(false);
                        setSeverity("");
                        setMessage("");
                        document.getElementById(
                            "update-btn"
                        ).style = `display: flex;
                            text-align: center`;
                        setUpdateTrackingStatus(false);
                        setUpdateTrackingNumber(false);
                        setUpdateRefundApproved(false);
                        setUpdateItemReturned(false);
                        setUpdateTicketStatus(false);
                        handleClose();
                        rel();
                    }, 3000);
                })
                .catch((err) => {
                    console.log(err);
                    setMessage("Unsuccessful. Error code:" + err.status);
                    setSeverity("error");
                    setShowAlert(true);
                    setTimeout(() => {
                        setShowAlert(false);
                        setSeverity("");
                        setMessage("");
                        document.getElementById(
                            "update-btn"
                        ).style = `display: flex;
                            text-align: center`;
                    }, 3000);
                });
        }
    };

    // Map through returns and enteries in table
    const returnNodes = tiktokReturns.map((tiktokReturn, index) => {
        const data = tiktokReturn.data;
        return (
            <TableRow
                key={index}
                onClick={() => {
                    handleSelectedReturn(tiktokReturn);
                }}
            >
                <TableCell data-label="Account">{data.tiktokAccount}</TableCell>
                <TableCell data-label="Order Number">
                    {data.orderNumber}
                </TableCell>
                <TableCell data-label="Tracking Number">
                    {data.trackingNumber}
                </TableCell>
                <TableCell data-label="Tracking Status">
                    {data.trackingStatus}
                </TableCell>
                <TableCell data-label="Ticket Number">
                    {data.ticketNumber}
                </TableCell>
                <TableCell data-label="Ticket Status">
                    {data.ticketStatus}
                </TableCell>
            </TableRow>
        );
    });
    return (
        <section className="tiktok__returns">
            <Typography className="title" variant="h3">
                Tiktok Returns
            </Typography>
            <hr></hr>
            {tiktokReturns.length > 0 ? (
                <TableContainer>
                    <Table className="return_list">
                        <TableHead>
                            <TableRow>
                                <TableCell>Tiktok Account</TableCell>
                                <TableCell>Order Number</TableCell>
                                <TableCell>Tracking Number</TableCell>
                                <TableCell>Tracking Status</TableCell>
                                <TableCell>Ticket Number</TableCell>
                                <TableCell>Ticket Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{returnNodes}</TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Typography className="no_returns" variant="h4">
                    No open returns
                </Typography>
            )}

            <div className="openReturns">
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={3}>
                                            <Typography
                                                variant="h4"
                                                style={{ textAlign: "center" }}
                                            >
                                                Tiktok Return
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <h4>Tiktok Account:</h4>
                                        </TableCell>
                                        <TableCell colSpan={2}>
                                            <p>
                                                {selectedReturn.tiktokAccount}
                                            </p>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <h4>Order Number: </h4>
                                        </TableCell>
                                        <TableCell colSpan={2}>
                                            {selectedReturn.orderNumber}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <h4>Tracking Number: </h4>
                                        </TableCell>
                                        <TableCell>
                                            {selectedReturn.trackingNumber}
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                variant="contained"
                                                color="warning"
                                                onClick={
                                                    handleUpdateTrackingNumber
                                                }
                                            >
                                                <EditIcon />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                    {updateTrackingNumber ? (
                                        <TableRow
                                            style={{
                                                backgroundColor:
                                                    "rgb(240, 237, 237)",
                                            }}
                                        >
                                            <TableCell>
                                                <h4>Update Tracking Number</h4>
                                            </TableCell>
                                            <TableCell colSpan={2}>
                                                <input
                                                    type="text"
                                                    id="tracking-number"
                                                    name="tracking-number"
                                                    onChange={(e) =>
                                                        setTrackingNumber(
                                                            e.target.value
                                                        )
                                                    }
                                                    defaultValue={
                                                        selectedReturn.trackingNumber
                                                    }
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ) : null}
                                    <TableRow>
                                        <TableCell>
                                            <h4>Tiktok Account: </h4>
                                        </TableCell>
                                        <TableCell colSpan={2}>
                                            {selectedReturn.tiktokAccount}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <h4>Tracking Status: </h4>
                                        </TableCell>
                                        <TableCell>
                                            {selectedReturn.trackingStatus}
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                variant="contained"
                                                color="warning"
                                                onClick={
                                                    handleUpdateTrackingStatus
                                                }
                                            >
                                                <EditIcon />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                    {updateTrackingStatus ? (
                                        <TableRow
                                            style={{
                                                backgroundColor:
                                                    "rgb(240, 237, 237)",
                                            }}
                                        >
                                            <TableCell>
                                                <h4>Update Tracking Status</h4>
                                            </TableCell>
                                            <TableCell colSpan={2}>
                                                <select
                                                    onChange={(e) =>
                                                        setTrackingStatus(
                                                            e.target.value
                                                        )
                                                    }
                                                    defaultValue={
                                                        selectedReturn.trackingStatus
                                                    }
                                                >
                                                    <option>
                                                        Please select:
                                                    </option>
                                                    <option>Returning</option>
                                                    <option>Damaged</option>
                                                    <option>
                                                        Stuck in transit
                                                    </option>
                                                </select>
                                            </TableCell>
                                        </TableRow>
                                    ) : null}
                                    <TableRow>
                                        <TableCell>
                                            <h4>Pick List Image:</h4>
                                        </TableCell>
                                        <TableCell colSpan={2}>
                                            <img
                                                src={
                                                    selectedReturn.pickListImage
                                                }
                                                width="100"
                                                height="100"
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <h4>Refund Approved: </h4>
                                        </TableCell>
                                        <TableCell>
                                            {selectedReturn.refundApproved
                                                ? "Yes"
                                                : "No"}
                                        </TableCell>
                                        <TableCell>
                                            {!selectedReturn.refundApproved ? (
                                                <Button
                                                    variant="contained"
                                                    color="warning"
                                                    onClick={
                                                        handleUpdateRefundApproved
                                                    }
                                                >
                                                    <EditIcon />
                                                </Button>
                                            ) : null}
                                        </TableCell>
                                    </TableRow>
                                    {updateRefundApproved ? (
                                        <TableRow
                                            style={{
                                                backgroundColor:
                                                    "rgb(240, 237, 237)",
                                            }}
                                        >
                                            <TableCell>
                                                <h4>Update Refund Approved</h4>
                                            </TableCell>
                                            <TableCell colSpan={2}>
                                                <select
                                                    onChange={(e) => {
                                                        if (
                                                            e.target.value ===
                                                            "Yes"
                                                        ) {
                                                            setRefundApproved(
                                                                true
                                                            );
                                                        } else if (
                                                            e.target.value ===
                                                            "No"
                                                        ) {
                                                            setRefundApproved(
                                                                false
                                                            );
                                                        } else {
                                                            setRefundApproved(
                                                                ""
                                                            );
                                                        }
                                                    }}
                                                    defaultValue={
                                                        selectedReturn.refundApproved
                                                            ? "Yes"
                                                            : "No"
                                                    }
                                                >
                                                    <option>
                                                        Please select:
                                                    </option>
                                                    <option>Yes</option>
                                                    <option>No</option>
                                                </select>
                                            </TableCell>
                                        </TableRow>
                                    ) : null}
                                    {refundApproved ? (
                                        <div>
                                            <label htmlFor="refund-date">
                                                <h4>Date of refund: </h4>
                                            </label>
                                            <input
                                                type="date"
                                                id="refund-date"
                                                name="refund-date"
                                                onChange={(e) =>
                                                    setRefundDate(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                            <label htmlFor="refund-amount">
                                                <h4>Refund Amount: </h4>
                                            </label>
                                            <input
                                                type="number"
                                                pattern="[0-9]*"
                                                id="refund-amount"
                                                name="refund-amount"
                                                onChange={(e) =>
                                                    setRefundAmount(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </div>
                                    ) : null}
                                    {selectedReturn.refundApproved ? (
                                        <>
                                            <TableRow>
                                                <TableCell>
                                                    <h4>Date of refund: </h4>
                                                </TableCell>
                                                <TableCell colSpan={2}>
                                                    {selectedReturn.refundDate}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <h4>Refund Amount: </h4>
                                                </TableCell>
                                                <TableCell colSpan={2}>
                                                    £
                                                    {
                                                        selectedReturn.refundAmount
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    ) : null}
                                    <TableRow>
                                        <TableCell>
                                            <h4>Ticket Number: </h4>
                                        </TableCell>
                                        <TableCell colSpan={2}>
                                            {selectedReturn.ticketNumber}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <h4>Ticket Status: </h4>
                                        </TableCell>
                                        <TableCell>
                                            {selectedReturn.ticketStatus}
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                variant="contained"
                                                color="warning"
                                                onClick={
                                                    handleUpdateTicketStatus
                                                }
                                            >
                                                <EditIcon />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                    {updateTicketStatus ? (
                                        <TableRow
                                            style={{
                                                backgroundColor:
                                                    "rgb(240, 237, 237)",
                                            }}
                                        >
                                            <TableCell>
                                                <h4>Update Ticket Status</h4>
                                            </TableCell>
                                            <TableCell colSpan={2}>
                                                <select
                                                    onChange={(e) =>
                                                        setTicketStatus(
                                                            e.target.value
                                                        )
                                                    }
                                                    defaultValue={
                                                        selectedReturn.ticketStatus
                                                    }
                                                >
                                                    <option>
                                                        Please select:
                                                    </option>
                                                    <option>
                                                        Reimbursement Requested
                                                    </option>
                                                    <option>
                                                        TikTok Investigating
                                                    </option>
                                                    <option>
                                                        Reimbursement Completed
                                                    </option>
                                                </select>
                                            </TableCell>
                                        </TableRow>
                                    ) : null}
                                    <TableRow>
                                        <TableCell>
                                            <h4>Item Returned: </h4>
                                        </TableCell>
                                        <TableCell>
                                            {selectedReturn.itemReturned
                                                ? "Yes"
                                                : "No"}
                                        </TableCell>
                                        {!selectedReturn.itemReturned ? (
                                            <TableCell>
                                                <Button
                                                    variant="contained"
                                                    color="warning"
                                                    onClick={
                                                        handleUpdateItemReturned
                                                    }
                                                >
                                                    <EditIcon />
                                                </Button>
                                            </TableCell>
                                        ) : null}
                                    </TableRow>
                                    {updateItemReturned ? (
                                        <TableRow
                                            style={{
                                                backgroundColor:
                                                    "rgb(240, 237, 237)",
                                            }}
                                        >
                                            <TableCell>
                                                <h4>Update Item Returned</h4>
                                            </TableCell>
                                            <TableCell colSpan={2}>
                                                <select
                                                    onChange={(e) => {
                                                        if (
                                                            e.target.value ===
                                                            "Yes"
                                                        ) {
                                                            setItemReturned(
                                                                true
                                                            );
                                                        } else if (
                                                            e.target.value ===
                                                            "No"
                                                        ) {
                                                            setItemReturned(
                                                                false
                                                            );
                                                        } else {
                                                            setItemReturned("");
                                                        }
                                                    }}
                                                    defaultValue={
                                                        selectedReturn.itemReturned
                                                            ? "Yes"
                                                            : "No"
                                                    }
                                                >
                                                    <option>
                                                        Please select:
                                                    </option>
                                                    <option>Yes</option>
                                                    <option>No</option>
                                                </select>
                                            </TableCell>
                                        </TableRow>
                                    ) : null}
                                    {itemReturned ? (
                                        <TableRow
                                            style={{
                                                backgroundColor:
                                                    "rgb(240, 237, 237)",
                                            }}
                                        >
                                            <TableCell>
                                                <h4>Any damages?</h4>
                                            </TableCell>
                                            <TableCell colSpan={2}>
                                                <select
                                                    id="any-damages"
                                                    onChange={(e) => {
                                                        if (
                                                            e.target.value ===
                                                            "Yes"
                                                        ) {
                                                            setItemDamaged(
                                                                true
                                                            );
                                                        } else if (
                                                            e.target.value ===
                                                            "No"
                                                        ) {
                                                            setItemDamaged(
                                                                false
                                                            );
                                                        } else {
                                                            setItemDamaged("");
                                                        }
                                                    }}
                                                    defaultValue={
                                                        selectedReturn.itemDamaged
                                                            ? "Yes"
                                                            : "No"
                                                    }
                                                >
                                                    <option>
                                                        Please select:
                                                    </option>
                                                    <option>Yes</option>
                                                    <option>No</option>
                                                </select>
                                            </TableCell>
                                        </TableRow>
                                    ) : null}
                                    {selectedReturn.itemReturned ? (
                                        <TableRow>
                                            <TableCell>
                                                <h4>Item Damaged: </h4>
                                            </TableCell>
                                            <TableCell colSpan={2}>
                                                {selectedReturn.itemDamaged
                                                    ? "Yes"
                                                    : "No"}
                                            </TableCell>
                                        </TableRow>
                                    ) : null}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {showAlert ? (
                            <Alert severity={severity} sx={{ marginTop: "1%" }}>
                                {message}
                            </Alert>
                        ) : (
                            <></>
                        )}
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                paddingTop: "10px",
                            }}
                        >
                            <Button
                                id="update-btn"
                                variant="contained"
                                color="warning"
                                onClick={handleUpdateReturn}
                            >
                                Update
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    handleClose();
                                }}
                            >
                                Close
                            </Button>
                        </div>
                    </Box>
                </Modal>
            </div>
        </section>
    );
};

export default OpenReturns;
