import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import axios from "axios";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "30vw",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
    maxHeight: "80vh",
    "@media(max-width: 690px)": {
        width: "90vw",
        maxHeight: "90vh",
    },
};

const StorageModal = ({ handleCloseStorage, request, rel }) => {
    const url = process.env.REACT_APP_ANPR_URL;
    // const url = "http://10.0.0.34:4024";
    const [copyAlert, setCopyAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");
    const [storageLocation, setStorageLocation] = useState("");
    const [user, setUser] = useState("");

    useEffect(() => {
        let userD = localStorage.getItem("userDetails");
        let currentUser = JSON.parse(userD);
        setUser(currentUser.user.user);
    }, []);

    const handleUpdateStorageLocation = () => {
        document.getElementById("copy-btn").style.display = "none";
        if (storageLocation === "") {
            setMessage("Please enter a storage location");
            setSeverity("warning");
            setCopyAlert(true);
            setTimeout(() => {
                setCopyAlert(false);
                setSeverity("");
                setMessage("");
                document.getElementById("copy-btn").style.display = "flex";
            }, 2500);
        } else {
            
            let data = {
                requestId: request.requestId,
                storageLocation: storageLocation,
                user: user,

            };
            data.images = data.imageKeys;

            axios
                .post(`${url}/cctv-system/submission/update/details`, data)
                .then((res) => {
                    setMessage("Storage Location copied to clipboard");
                    setSeverity("success");
                    setCopyAlert(true);
                    setTimeout(() => {
                        setCopyAlert(false);
                        setSeverity("");
                        setMessage("");
                        document.getElementById("copy-btn").style.display =
                            "flex";
                        handleCloseStorage(false);
                        rel();
                    }, 2500);
                })
                .catch((err) => {
                    console.log(err);
                    setMessage("Something went wrong, please try again.");
                    setSeverity("error");
                    setCopyAlert(true);

                    setTimeout(() => {
                        setCopyAlert(false);
                        setSeverity("");
                        setMessage("");
                        document.getElementById("copy-btn").style.display =
                            "flex";
                    }, 2500);
                });
        }
    };

    return (
        <Box sx={style} className="description__modal">
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Set Storage location
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        marginBottom: "5%",
                    }}
                >
                    <input
                        type="text"
                        value={storageLocation}
                        onChange={(e) => setStorageLocation(e.target.value)}
                        placeholder="Enter storage location"
                        style={{ padding: "2%", borderRadius: "10px" }}
                    />
                </div>
                <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                >
                    {copyAlert ? (
                        <Alert severity={severity}>{message}</Alert>
                    ) : (
                        <></>
                    )}
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                            handleCloseStorage(false);
                        }}
                    >
                        Close
                    </Button>
                    <Button
                        id="copy-btn"
                        variant="contained"
                        onClick={() => {
                            handleUpdateStorageLocation();
                        }}
                    >
                        Save
                    </Button>
                </div>
            </Typography>
        </Box>
    );
};

export default StorageModal;
