import NavBar from "../../NavBar";
import ErrorBoundary from "../../error/ErrorBoundary";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useState, useEffect } from "react";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import PeopleIcon from "@mui/icons-material/People";
import './eposUsers.scss'
import AddEposUser from "./AddEposUser";

export default function EposUserHome() {
  const drawerWidth = "12vw";
  const [value, setValue] = useState(1);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 690) {
      setIsMobile(true);
    }
  }, [window.innerWidth]);

  const handleChange = (index) => {
    setValue(index + 1);
  };

  return (
    <main className="epos__users__home">
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, padding: "0" }}
        >
          <Toolbar sx={{ width: "100vw", backgroundColor: "#2c53a0" }}>
            <NavBar />
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
            },
            ["@media(max-width: 600px)"]: {
              width: "10vw",
            },
          }}
        >
          <Toolbar />
          <Box sx={{ overflow: "hidden", marginTop: "12vh" }}>
            <List>
              {["Add User"].map((text, index) => (
                <ListItem
                  key={text}
                  disablePadding
                  onClick={() => handleChange(index)}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      {index === 0 ? (
                        <PersonAddAltIcon />
                      ) 
                      // : index === 1 ? (
                      //   <PeopleIcon />
                      // ) 
                      : null}
                    </ListItemIcon>
                    {!isMobile ? <ListItemText primary={text} /> : <></>}
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: "10vh" }}>
          <Toolbar />
          {value === 1 ? (
            <ErrorBoundary>
              <AddEposUser />
            </ErrorBoundary>
          ) 
          // : value === 2 ? (
          //   <ErrorBoundary>
          //     <h1>modify user</h1>
          //   </ErrorBoundary>
          // ) 
          : null}
        </Box>
      </Box>
    </main>
  );
}
